/**
 * Handles the creation of a reducer. Accepts a parameter of an array of
 * handlers for different action types, and calls the specific action type.
 * Minimizes the need for switch statements when creating a reducer.
 * @param  {Object} initialState The initial state of the reducer.
 * @param  {Array} handlers     An array of Action types that the reducer is
 *                              listening for.
 * @return {Object}             Returns the result of the called action, or
 *                              the initial state if a handler for that action
 *                              doesn't exist.
 */
export default function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}
