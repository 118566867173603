import types from "./types";
import { KpiAPI } from "lib/apiKpi";

const getBudgetPlannerRequest = async ({
    accountId,
    budgets = {},
    platforms = {},
    numberOfMonths,
    metric,
    metricsAverages,
}) => {
    return KpiAPI({
        url: `budgetPlanner`,
        method: "POST",
        data: { accountId, budgets, platforms, numberOfMonths, metric, metricsAverages },
        bypassCache: true,
    });
};

const getBudgetPlannerData = ({ accountId, budgets, platforms, numberOfMonths, metric, metricsAverages }) => {
    return {
        type: types.GET_BUDGET_PLANNER_DATA,
        promise: getBudgetPlannerRequest({ accountId, budgets, platforms, numberOfMonths, metric, metricsAverages }),
    };
};

export default {
    getBudgetPlannerData,
};
