import types from "./types";
import { KpiAPI } from "lib/apiKpi";
import { MorphioAPI } from "lib/api";
import moment from "moment";
import isArray from "lodash/isArray";
import { api as adwordsApi } from "lib/adwords";
import { api as shopifyApi } from "lib/shopify";
import { groupSearchTerms } from "lib/adwords/searchTerms";
import { isEmpty } from "lodash";
import { filterMetrics } from "lib/utility";

const getGoalSuggestions = ({ metrics: { conversions } = {}, date, accountId }) => {
    return (dispatch, getState) => {
        return KpiAPI({
            method: "GET",
            url: `/goals/suggestions`,
            query: {
                date,
                conversions,
                accountId,
            },
        }).then(({ data }) => {
            return data;
        });
    };
};

const getLastMonthData = ({ metric, date, accountId }) => {
    const metrics = isArray(metric) ? metric : [metric];
    return {
        type: types.LOADING_SUGGESTION,
        meta: { metrics: metrics },
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}`,
            query: {
                date: moment(date)
                    .endOf("month")
                    .format("YYYY-MM-DD"),
                metrics: JSON.stringify({ metrics: filterMetrics(metrics) }),
                compareTo: "none",
            },
        }).then(({ data }) => data),
    };
};

const getInsights = ({ accountId }) => ({
    type: types.GET_INSIGHTS,
    promise: KpiAPI({
        method: "GET",
        url: `/insights`,
        query: {
            accountId,
        },
    }),
});

export const fetchCampaignGroupsPerformance = ({ accountId, startDate, endDate, abortController }) => {
    return KpiAPI({
        method: "GET",
        url: `/accountPerformance`,
        query: {
            accountId,
            startDate,
            endDate,
        },
        abortController,
    }).then(({ data } = {}) => data || {});
};

export const getCampaignGroupsPerformance = ({ accountId, startDate, endDate, abortController }) => ({
    type: types.GET_CAMPAIGN_GROUPS_PERFORMANCE,
    promise: fetchCampaignGroupsPerformance({ accountId, startDate, endDate, abortController }),
});

const saveGoals = ({ accountId, goals, metrics, date, carryOver }) => ({
    type: types.SAVE_GOALS,
    promise: Promise.all([
        KpiAPI({
            method: "PUT",
            url: `/goals/metrics`,
            data: {
                accountId,
                metrics,
            },
        }).catch(() => null),
        KpiAPI({
            method: "PUT",
            url: `/goals/values`,
            data: {
                accountId,
                goals,
                date,
                carryOver,
            },
        })
            .then(async (data) => {
                await MorphioAPI({
                    method: "POST",
                    url: `/account/${accountId}/forecasting`,
                }).catch(() => null);
                return data;
            })
            .catch(() => null),
    ]),
});

const saveGoalValues = ({ accountId, goals, date, carryOver }) => ({
    type: types.SAVE_GOALS,
    promise: Promise.all([
        KpiAPI({
            method: "PUT",
            url: `/goals/values`,
            data: {
                accountId,
                goals,
                date,
                carryOver,
            },
        })
            .then(async (data) => {
                await MorphioAPI({
                    method: "POST",
                    url: `/account/${accountId}/forecasting`,
                }).catch(() => null);
                return data;
            })
            .catch(() => null),
    ]),
});

const saveMonthsValues = ({ accountId, monthsValues }) => {
    return {
        type: types.SAVE_GOALS,
        promise: KpiAPI({
            method: "PUT",
            url: `/goals/monthsValues`,
            data: {
                accountId,
                monthsValues,
            },
        })
            .then(async (data) => {
                await MorphioAPI({
                    method: "POST",
                    url: `/account/${accountId}/forecasting`,
                }).catch(() => ({ data: { error: true } }));
                return data;
            })
            .catch(() => ({ data: { error: true } })),
    };
};

const saveActiveGoals = ({ accountId, metrics }) => ({
    type: types.SAVE_GOALS,
    promise: KpiAPI({
        method: "PUT",
        url: `/goals/metrics`,
        data: {
            accountId,
            metrics,
        },
    }).catch(() => null),
});

const getGoalData = ({
    metrics,
    date,
    accountId,
    comparison,
    togglingComparison = false,
    abortController,
    includeCampaignData = false,
}) => {
    const start = moment(date.start).format("YYYY-MM-DD");
    const end = moment(date.end).format("YYYY-MM-DD");
    const compareToStart = moment(date.compareToStart).format("YYYY-MM-DD");
    const compareToEnd = moment(date.compareToEnd).format("YYYY-MM-DD");

    return {
        type: types.GET_GOAL_DATA,
        meta: { metrics, togglingComparison },
        promise: KpiAPI({
            method: "POST",
            url: `/account/${accountId}`,
            data: {
                date: end,
                startDate: start,
                endDate: end,
                compareToStartDate: compareToStart,
                compareToEndDate: compareToEnd,
                metrics: JSON.stringify({ metrics: filterMetrics(metrics) }),
                compareTo: comparison,
                includeCampaignData,
            },
            abortController,
        }).then(({ data }) => data),
    };
};

const getLastYearGoalData = ({ metrics, date, accountId, togglingComparison = false }) => {
    // Dont ask for today data
    const setDate =
        moment(date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
            ? moment(date)
                  .subtract(1, "days")
                  .format("YYYY-MM-DD")
            : date;
    return {
        type: types.GET_LAST_YEAR_GOAL_DATA,
        meta: { metrics, togglingComparison },
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}`,
            query: {
                date: setDate,
                metrics: JSON.stringify({ metrics: filterMetrics(metrics) }),
                compareTo: "none",
            },
        }).then(({ data }) => data),
    };
};

export const getBudget = ({ accountId, date, startDate, endDate }) => ({
    type: types.REQUEST_BUDGET,
    promise: KpiAPI({
        method: "GET",
        url: `/account/${accountId}/budget`,
        query: {
            date: moment(date).format("YYYY-MM-DD"),
            startDate,
            endDate,
        },
        bypassCache: true,
    }),
});

export const getLastMonthBudget = ({ accountId, date }) => ({
    type: types.REQUEST_LAST_MONTH_BUDGET,
    promise: KpiAPI({
        method: "GET",
        url: `/account/${accountId}/budget`,
        query: {
            date: moment(date)
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD"),
        },
    }),
});

const saveReportUi = (ui, accountId) => {
    return {
        type: types.SAVE_REPORT_UI,
        promise: MorphioAPI({
            method: "PUT",
            url: `/account/${accountId}/layout/summary`,
            data: {
                data: ui,
            },
        }).then(() => ({ ui })),
    };
};

const getReportUi = (accountId) => {
    return {
        type: types.GET_REPORT_UI,
        promise: MorphioAPI({
            method: "GET",
            url: `/account/${accountId}/layout/summary`,
        }),
    };
};

const saveKpiSummaryMetrics = ({ accountId, metrics }) => ({
    type: types.SAVE_KPI_SUMMARY_METRICS,
    promise: KpiAPI({
        method: "PUT",
        url: `/kpiSummaryMetrics`,
        data: {
            accountId,
            metrics,
        },
    }).catch(() => null),
});

const getDashboardNotes = ({ accountId }) => ({
    type: types.GET_NOTES,
    promise: KpiAPI({
        method: "GET",
        url: `/account/${accountId}/kpiSummaryNotes`,
        bypassCache: true,
    }).catch(() => null),
});

const createKpiSummaryNote = ({ accountId, userId, text, platform }) => ({
    type: types.CREATE_NOTE,
    promise: KpiAPI({
        method: "POST",
        url: `/account/${accountId}/kpiSummaryNotes`,
        data: {
            userId,
            text,
            platform,
        },
    }).catch(() => null),
});

const updateKpiSummaryNote = ({ accountId, noteId, text, platform }) => ({
    type: types.CREATE_NOTE,
    promise: KpiAPI({
        method: "PUT",
        url: `/account/${accountId}/kpiSummaryNotes/${noteId}`,
        data: {
            text,
            platform,
        },
    }).catch(() => null),
});

const deleteKpiSummaryNote = ({ accountId, noteId }) => ({
    type: types.DELETE_NOTE,
    promise: KpiAPI({
        method: "DELETE",
        url: `/account/${accountId}/kpiSummaryNotes/${noteId}`,
    }).catch(() => null),
});

const getExtraCampaignGroupsInfo = (accountId) => ({
    type: types.GET_EXTRA_CAMPAIGN_GROUPS_INFO,
    promise: KpiAPI({
        method: "GET",
        url: `/accountPerformance/campaigns`,
        query: {
            accountId,
        },
    }).then(({ data } = {}) => data || {}),
});

const updatePlatformCampaign = ({ accountId, campaignId, integrationId, userId, platform, data, connectionId }) => {
    return {
        type: types.UPDATE_PLATFORM_CAMPAIGN,
        promise: KpiAPI({
            method: "PUT",
            url: `/campaigns/${campaignId}`,
            data: {
                accountId,
                integrationId,
                userId,
                platform,
                connectionId,
                data,
            },
        }).then(({ data } = {}) => data || {}),
        meta: {
            campaignId,
            platform,
            data,
        },
    };
};

const getGA4KpiSummaryData = ({
    reportTypes = [],
    date,
    accountId,
    comparison,
    togglingComparison = false,
    abortController,
}) => {
    const start = moment(date.start).format("YYYY-MM-DD");
    const end = moment(date.end).format("YYYY-MM-DD");
    const compareToStart = moment(date.compareToStart).format("YYYY-MM-DD");
    const compareToEnd = moment(date.compareToEnd).format("YYYY-MM-DD");

    return {
        type: types.GET_KPI_SUMMARY_GA4_DATA,
        meta: { reportTypes, togglingComparison },
        promise: KpiAPI({
            method: "GET",
            url: `/analyticsV4/reports`,
            query: {
                accountId,
                date: end,
                startDate: start,
                endDate: end,
                compareToStartDate: compareToStart,
                compareToEndDate: compareToEnd,
                reportTypes: JSON.stringify([...reportTypes]),
                compareTo: comparison,
            },
            abortController,
        }).then(({ data }) => data),
    };
};

const getKpiSummaryAdwordsTopTermsData = ({ dateRange, accountId, abortController }) => {
    const currentDateRange = {
        start: moment(dateRange.start).format("YYYY-MM-DD"),
        end: moment(dateRange.end).format("YYYY-MM-DD"),
    };
    let comparedDateRange = {};
    if (dateRange.compareToStart) {
        comparedDateRange.start = moment(dateRange.compareToStart).format("YYYY-MM-DD");
    }
    if (dateRange.compareToEnd) {
        comparedDateRange.end = moment(dateRange.compareToEnd).format("YYYY-MM-DD");
    }

    return {
        type: types.GET_KPI_SUMMARY_ADWORDS_TOP_TERMS_DATA,
        meta: { accountId },
        promise: adwordsApi
            .getSearchTermsReport({
                dateRange: isEmpty(comparedDateRange) ? [currentDateRange] : [currentDateRange, comparedDateRange],
                accountId,
            })
            .then((searchTerms = []) => {
                return groupSearchTerms({ searchTerms, dateRange });
            }),
    };
};

const getShopifyTopProductsData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    const params = {
        accountId,
        abortController,
    };

    if (dateRange.length) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        params.dateRange = validatedDateRange;
    }

    return dispatch({
        type: types.GET_KPI_SUMMARY_SHOPIFY_TOP_PRODUCTS_DATA,
        promise: shopifyApi.getOrders(params),
    });
};

export default {
    saveReportUi,
    getReportUi,
    getBudget,
    getLastMonthBudget,
    getGoalData,
    getLastYearGoalData,
    saveGoals,
    getLastMonthData,
    getGoalSuggestions,
    getInsights,
    saveKpiSummaryMetrics,
    saveGoalValues,
    saveActiveGoals,
    getDashboardNotes,
    createKpiSummaryNote,
    updateKpiSummaryNote,
    deleteKpiSummaryNote,
    getExtraCampaignGroupsInfo,
    updatePlatformCampaign,
    saveMonthsValues,
    getGA4KpiSummaryData,
    getKpiSummaryAdwordsTopTermsData,
    getShopifyTopProductsData,
};
