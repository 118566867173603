import axios from "axios";
import qs from "querystring";

import { history } from "../_redux";

import { getAccessToken, getProfile } from "./auth";

const { REACT_APP_DASHBOARD_API_DOMAIN } = process.env;

const SHARED_PATHS = ["/shared", "/sharedReport"];

var api = axios.create({
    baseURL: REACT_APP_DASHBOARD_API_DOMAIN,
    timeout: 1000000,
});

const request = (api) => {
    return function({
        url,
        data,
        method,
        query,
        headers,
        file,
        bypassCache = false,
        cancelSubsequentRequests,
        isBlob = false,
        abortController,
    }) {
        const currentAbortController = abortController || new AbortController();
        const oldLocation = history.location;

        if (cancelSubsequentRequests && currentAbortController) {
            currentAbortController.abort();
        }

        const queryString = query ? `?${qs.stringify(query)}` : "";

        const formatMethod = method ? method.toLowerCase() : "get";

        const cacheBypassHeaders = bypassCache || formatMethod !== "get" ? { "x-apicache-bypass": true } : {};

        const token = getAccessToken();
        const { pathname = "", search = "" } = oldLocation || {};
        const isSharedPages = SHARED_PATHS.includes(pathname);
        const sharedLinkType = isSharedPages ? pathname.replace("/", " ") : "";
        const { code = "" } = isSharedPages ? qs.parse(search.substring(1)) || {} : {};
        const defaultAuthorization = code && isSharedPages ? `Code ${code}${sharedLinkType}` : `Bearer ${token}`;

        return api({
            method: formatMethod,
            url: url + queryString,
            data: file ? file : JSON.stringify(data),
            encoding: null,
            signal: currentAbortController.signal,
            responseType: isBlob && "blob",
            headers: {
                Authorization: defaultAuthorization,
                "content-type": "application/json",
                ...cacheBypassHeaders,
                ...headers,
            },
        })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    return Promise.resolve({ data: { isCancelledReq: true } });
                }
                throw error;
            });
    };
};

/**
 * Request Wrapper for Hawke AI API
 *
 * @param {object} data
 * @param {string} data.url - URL of request
 * @param {object} data.data - Data of request
 * @param {string} data.method - Method
 * @param {string} data.query - Request query
 * @param {array} data.headers - Additional Headers
 * @param {?} data.file - File to end up
 * @param {boolean} data.bypassCache
 * @param {bolean} data.cancelSubsequentRequests
 *
 * @return {Promise}
 */

/**
 * Request Wrapper for Hawke AI API
 *
 * @param {object} data
 * @param {string} data.url - URL of request
 * @param {object} data.data - Data of request
 * @param {string} data.method - Method
 * @param {string} data.query - Request query
 * @param {array} data.headers - Additional Headers
 * @param {?} data.file - File to end up
 * @param {boolean} data.bypassCache
 * @param {bolean} data.cancelSubsequentRequests
 *
 * @return {Promise}
 */

const MorphioAPI = request(api);

export { MorphioAPI, request, getProfile };
