import React from "react";

export default () => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Credit-Card"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="12.2-Last-Step"
                transform="translate(-1020.000000, -212.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
            >
                <g id="Modal-2" transform="translate(344.000000, 156.000000)">
                    <g id="Form" transform="translate(512.000000, 40.000000)">
                        <path
                            d="M181.5,29.3333333 C180.41082,28.2441927 178.747786,27.3214193 177.333333,26.8333333 C178.848255,25.7899349 179.833333,23.8078646 179.833333,21.8333333 C179.833333,18.6455599 177.187773,16 174,16 C170.812227,16 168.166667,18.6455599 168.166667,21.8333333 C168.166667,23.8078646 169.151706,25.7899349 170.666667,26.8333333 C169.252214,27.3214193 167.58918,28.2441927 166.5,29.3333333 C164.61125,31.2221224 164,33.3289063 164,36 L165.666667,36 C165.666667,31.3475391 169,28.5 174,28.5 C179,28.5 182.333333,31.3475391 182.333333,36 L184,36 C184,33.3289063 183.388711,31.2221224 181.5,29.3333333 Z M174,26 C171.794407,26 170,24.2056296 170,22 C170,19.7943704 171.794407,18 174,18 C176.205593,18 178,19.7943704 178,22 C178,24.2056296 176.205593,26 174,26 Z"
                            id="Mask"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
