import React, { useState, useEffect } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { ReactComponent as WarningIcon } from "assets/images/icons/Icon-Alert.svg";

export default ({ children, className, largeIcon = false, timeout }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (!timeout) {
            return;
        }
        const timeId = setTimeout(() => {
            setShow(false);
        }, timeout);

        return () => {
            clearTimeout(timeId);
        };
    }, [timeout]);

    if (!show) {
        return null;
    }

    return (
        <div className={`${style.ErrorWrapper} ${className && className}`}>
            <div className={classNames(style.alertAvatar, { [style.largeIcon]: largeIcon })}>
                <WarningIcon />
            </div>
            <p>{children}</p>
        </div>
    );
};
