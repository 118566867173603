import React from "react";

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10px"
        height="10px"
        viewBox="0 0 34 34"
    >
        <g id="💰--Edit" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g
                id="5.3-Goal-Settings-(New)"
                transform="translate(-944.000000, -1020.000000)"
            >
                <g id="Drawer-5" transform="translate(864.000000, 0.000000)">
                    <g id="Header" transform="translate(48.000000, 16.000000)">
                        <g
                            id="Options"
                            transform="translate(8.000000, 428.000000)"
                        >
                            <g
                                id="1-copy-10"
                                transform="translate(0.000000, 552.000000)"
                            >
                                <g
                                    id="Logo-Microsoft"
                                    transform="translate(24.000000, 24.000000)"
                                >
                                    <path
                                        d="M0,0 L16,0 C15.999039,5.33333333 16,10.6666667 15.999039,16 C10.6666667,16 5.33333333,16 0,16 L0,0"
                                        id="Fill-1"
                                        fill="#fff"
                                    ></path>
                                    <path
                                        d="M18.0002402,0 L33.9997598,0 C33.9997598,5.33333333 34.0007207,10.6666667 33.9987988,16 C28.6665866,15.999039 23.3334134,16 18.0012012,16 C17.9992793,10.6666667 18.0002402,5.33333333 18.0002402,0"
                                        id="Fill-3"
                                        fill="#fff"
                                    ></path>
                                    <path
                                        d="M0,18 C5.33319097,18.0019218 10.6663819,17.9990391 15.9995729,18.0019218 C16.0005339,23.3349348 15.9995729,28.666987 15.9995729,34 L0,34 L0,18"
                                        id="Fill-16"
                                        fill="#fff"
                                    ></path>
                                    <path
                                        d="M18.000961,18.0012012 C23.3333333,17.9992793 28.6666667,18.0002402 34,18.0002402 C34,23.3334935 34,28.6667467 34,34 L18,34 C18.000961,28.6667467 17.999039,23.3334935 18.000961,18.0012012"
                                        id="Fill-17"
                                        fill="#fff"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
