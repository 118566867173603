import React from "react";
import Tag from "components/Tag";
import { allPlatforms, channelFriendlyName, metricFriendlyName } from "lib/utility";
import { prepareMetricData, prepareBenchmarkData } from "./InsightCharts/utility";
import moment from "moment";
import { toCapitalize } from "features/AlertsPage/Alert/helpers";
import reactStringReplace from "react-string-replace";
import style from "./style.module.scss";

export const getPeriod = (period = []) => {
    const sorted = period.sort((a, b) => new Date(a) - new Date(b));
    const firstDate = moment(sorted[0])
        .startOf("month")
        .format("MMM D, YYYY");
    const lastDate = moment(sorted[sorted.length - 1])
        .endOf("month")
        .format("MMM D, YYYY");
    return `${firstDate} - ${lastDate}`;
};

export const getWeeklyPeriod = (period = []) => {
    const [firstDate, lastDate] = period.sort((a, b) => new Date(a) - new Date(b));
    return `${firstDate.format("MMM D, YYYY")} - ${lastDate.format("MMM D, YYYY")}`;
};

const platforms = allPlatforms.reduce(
    (cache, platform) => ({
        ...cache,
        [platform]: channelFriendlyName(platform).replace(/\s/g, "\\s"),
    }),
    {},
);

export const isDailyInsight = (insight = {}) => {
    return ["dailyInsights"].includes(insight.type);
};

export const isMonthlyInsight = (insight = {}) => {
    return ["monthlyInsights"].includes(insight.type);
};

export const prepareInsight = (insight, accountId) => {
    if (isDailyInsight(insight)) {
        const { id, insight: description, type, date, subType, chartData, platformKey, campaignId } = insight;

        return {
            id,
            date,
            description,
            type,
            subType,
            chartData,
            platformKey,
            campaignId,
            primaryPeriod: getWeeklyPeriod([moment(date).subtract(7, "days"), moment(date).subtract(1, "days")]),
            secondaryPeriod: getWeeklyPeriod([moment(date).subtract(8, "days"), moment(date).subtract(14, "days")]),
        };
    }

    const {
        id,
        platform = "",
        metric = "",
        date,
        description,
        primaryPeriod,
        ruleId,
        secondaryPeriod,
        type,
        subType,
        platformToCompare = "",
        metricToCompare = "",
        chartData,
        platformKey,
        campaignId,
    } = insight;

    const formattedTexts = {
        metricName: `${channelFriendlyName(platform)} - ${toCapitalize(metricFriendlyName(metric, platform))}`,
    };

    const metricToCompareName = `${channelFriendlyName(platformToCompare)} - ${toCapitalize(
        metricFriendlyName(metricToCompare, platformToCompare),
    )}`;

    return {
        id,
        platform,
        metric,
        date,
        description,
        primaryPeriod: getPeriod(primaryPeriod),
        ruleId,
        secondaryPeriod: getPeriod(secondaryPeriod),
        type,
        subType,
        platformToCompare,
        metricToCompare,
        ...formattedTexts,
        metricChartData: prepareMetricData({
            ...insight,
            ...formattedTexts,
            accountId,
        }),
        benchmarkChartData: prepareBenchmarkData({
            ...insight,
            metricToCompareName,
            ...formattedTexts,
            accountId,
        }),
        metricToCompareName,
        chartData,
        platformKey,
        campaignId,
    };
};

export const toTagText = (text) => {
    let newText = text;
    let tagKeyCounter = 0; // Counter for generating unique keys for tags

    // replace all dates with tags (date format: MMM DD, YYYY)
    newText = reactStringReplace(
        newText,
        /((?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2},\s\d{4})/g,
        (match) => (
            <Tag key={`date_${tagKeyCounter++}`} className={style.tag}>
                {match}
            </Tag>
        ),
    );

    // replace all cost related numbers with tags (including commas and digits and currency symbols: $ € £ )
    newText = reactStringReplace(newText, /(\$\d[\d,\.]*|\€\d[\d,\.]*|\£\d[\d,\.]*)/g, (match) => (
        <Tag key={`cost_${tagKeyCounter++}`} className={style.tag}>
            {match}
        </Tag>
    ));

    // replace all percentages with tags (including commas and digits)
    newText = reactStringReplace(newText, /(\d[\d,\.]*%)/g, (match) => (
        <Tag key={`percentage_${tagKeyCounter++}`} className={style.tag}>
            {match}
        </Tag>
    ));

    // replace all numbers with tags (including commas and digits)
    newText = reactStringReplace(newText, /(\d[\d,\.]*)/g, (match) => (
        <Tag key={`number_${tagKeyCounter++}`} className={style.tag}>
            {match}
        </Tag>
    ));

    // replace all the platform names with tags handle spaces like 'Google Ads'
    newText = reactStringReplace(newText, new RegExp(`(${Object.values(platforms).join("|")})`, "gi"), (match) => (
        <Tag key={`platform_${tagKeyCounter++}`} className={style.tag}>
            {match}
        </Tag>
    ));

    return newText;
};

export const getInsightsLink = (accountId, text) => {
    if (!text) return null;
    let platform = null;
    Object.keys(platforms).forEach((key) => {
        if (text.match(new RegExp(platforms[key], "gi"))) {
            platform = key;
            // break the loop
            return false;
        }
    });
    // get all the dates in text in `MMM DD, YYYY` format as an array and sort them
    const dates = (text.match(/((?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2},\s\d{4})/g) || []).sort(
        (a, b) => new Date(b) - new Date(a),
    );
    const [end, start, compareToEnd, compareToStart] = dates.map((date) => moment(date).format("YYYY-MM-DD"));
    return `/dashboard/${accountId}/?platform=${platform}&settings[start]=${start}&settings[end]=${end}&settings[compareToStart]=${compareToStart}&settings[compareToEnd]=${compareToEnd}`;
};
