import React from "react";

export default ({ color = "#BCCFDC" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
    >
        <path
            fill={color}
            d="M26,23 C26.5,23 27,23.5 27,24 L27,24 L27,29.010951 C27,29.5 27.5,30 28,30 L28,30 L33,30 C33.5,29.9853048 34.0012695,30.5 34,31 C33.993742,33.4648026 31.0467385,37 26.7521288,37 C23.0289979,37 20,33.9407701 20,30 C20,26.0592299 22.5052484,23 26,23 Z M30,21 C33.5913848,21 36,23.2630556 36,26.75 C36,26.8167052 35.9972488,26.9167734 35.9957444,27 C35.9867069,27.5 35.5,28 35,28 L35,28 L30,28 C29.5,28 29,27.5 29,27 L29,27 L29,22 C29,21.5 29.5,21 30,21 Z M31.1923077,23.5 C31.0962912,23.5 31,23.5852864 31,23.6785714 L31,25.8214286 C31,25.9146984 31.0962912,26 31.1923077,26 L33.3076923,26 C33.4014055,26 33.4952525,25.9123556 33.4984802,25.8214286 C33.4995143,25.7917179 33.5,25.7559661 33.5,25.7321429 C33.5,24.4868056 32.4749451,23.5 31.1923077,23.5 Z"
            transform="translate(-20 -21)"
        />
    </svg>
);
