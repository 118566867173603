import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
        <defs>
            <linearGradient id="a" x1="3.542%" x2="99.863%" y1="49.999%" y2="49.999%">
                <stop offset="0%" stopColor="#BBD1DF" />
                <stop offset="100%" stopColor="#B5CAD9" />
            </linearGradient>
        </defs>
        <g fill="none">
            <rect width="15.75" height="11.993" x=".904" y="4.007" fill="#D5E0EC" rx="1" />
            <path
                fill="#D5E0EC"
                d="M15.8482895,3.91639837 L1.69274436,3.91639837 C1.25424812,3.91639837 0.913026316,7.37244936 0.913026316,7.81901388 L8.66503759,16 L15.8482895,16 C16.2881391,15.9987768 16.6444004,15.6359603 16.6456015,15.1880175 L16.6456015,4.72820855 C16.6442989,4.28033466 16.2880714,3.9176216 15.8482895,3.91639837 Z"
            />
            <polygon fill="#A1B8C8" points="4.501 6.015 9.001 6.015 9.001 0 5.174 0" />
            <path
                fill="#D5E0EC"
                d="M13.5005075,6.01501039 L9.00050752,6.01501039 L9.00050752,0.000172285693 L12.8266917,0.000172285693 L13.5005075,6.01501039 Z M16.682062,1.140359 L16.715062,1.240359 C16.707062,1.20506488 16.691062,1.17229177 16.682062,1.140359 Z"
            />
            <path
                fill="#A1B8C8"
                d="M16.659812,1.16068871 L16.6542293,1.14018671 C16.5115658,0.474871055 15.9333327,0.000430714232 15.2646429,0 L12.8266917,0 L13.5,6.01501039 L18,6.01501039 L16.659812,1.16068871 Z"
            />
            <path
                fill="#7BABF7"
                d="M1.40203571,1.140359 L1.36903571,1.240359 C1.37703571,1.20506488 1.39303571,1.17229177 1.40203571,1.140359 Z"
            />
            <path
                fill="#D5E0EC"
                d="M1.34103383,1.16068871 L1.34661654,1.14018671 C1.48928008,0.474871055 2.06751316,0.000430714232 2.73620301,0 L5.17432331,0 L4.5,6.01501039 L0,6.01501039 L1.34103383,1.16068871 Z"
            />
            <path
                fill="#A1B8C8"
                d="M4.5081203 6.01345982C4.5081203 7.27824355 3.50132143 8.30356739 2.2593891 8.30356739 1.01745677 8.30356739.0106578947 7.27824355.0106578947 6.01345982L4.5081203 6.01345982ZM9.00558271 6.01345982C9.00558271 7.27824355 7.99878383 8.30356739 6.7568515 8.30356739 5.51491917 8.30356739 4.5081203 7.27824355 4.5081203 6.01345982L9.00558271 6.01345982ZM13.5030451 6.01345982C13.5030451 7.27824355 12.4962462 8.30356739 11.2543139 8.30356739 10.0123816 8.30356739 9.00558271 7.27824355 9.00558271 6.01345982L13.5030451 6.01345982Z"
            />
            <path
                fill="#A1B8C8"
                d="M18,6.01345982 C18,7.27824355 16.9932011,8.30356739 15.7512688,8.30356739 C14.5093365,8.30356739 13.5025376,7.27824355 13.5025376,6.01345982 L18,6.01345982 Z"
            />
            <path
                fill="#FFF"
                d="M15.7513534,12.2179845 C15.7434023,12.1089276 15.7302068,12.0093465 15.7114286,11.8821997 L13.2584211,11.8821997 C13.2584211,12.231595 13.2584211,12.6128633 13.2570677,12.962431 L14.6781203,12.962431 C14.6171471,13.2914668 14.4299361,13.5821934 14.1579135,13.7702786 C14.1579135,13.7702786 14.1579135,13.7642486 14.1568985,13.7640763 C13.9829645,13.8803828 13.7870215,13.9582653 13.5817105,13.9926994 C13.3758713,14.0310481 13.1648272,14.0301134 12.9593233,13.9899428 C12.7506504,13.9459595 12.5530837,13.8587583 12.378891,13.733754 C12.1217746,13.5480699 11.9234602,13.2900023 11.8082707,12.9912027 C11.7994737,12.9679441 11.7913534,12.9445132 11.7830639,12.9207378 L11.7830639,12.9181535 L11.7852632,12.9164307 C11.6734617,12.5822577 11.6731643,12.2198358 11.7844173,11.8854731 C11.8627793,11.6509154 11.9917458,11.4372164 12.1618421,11.260076 C12.5608703,10.8397162 13.1570695,10.6816096 13.7067293,10.8503806 C13.9174948,10.9162105 14.1100786,11.0316559 14.268891,11.1873715 L14.7483271,10.6991138 C14.8329135,10.6121095 14.9207143,10.5278618 15.0020865,10.4379287 C14.758986,10.2070005 14.4736405,10.0270465 14.162312,9.90832248 C13.5957202,9.69909581 12.9758952,9.69356351 12.4057895,9.89264448 C12.3859455,9.89953591 12.366203,9.90672022 12.3465789,9.91418019 C11.7315256,10.1496562 11.2255435,10.6114642 10.9285714,11.2083903 C10.823704,11.4196736 10.7473371,11.6444128 10.7015414,11.8765142 C10.429985,13.2508717 11.2534342,14.6036934 12.5829135,14.9673196 C13.0175188,15.0858521 13.4795301,15.0829233 13.9175188,14.9816193 C14.3154644,14.890115 14.6834222,14.6953917 14.9856767,14.4163499 C15.301015,14.1208799 15.5270301,13.7272071 15.6454511,13.3090698 C15.744461,12.954736 15.7803285,12.585202 15.7513534,12.2179845 L15.7513534,12.2179845 Z"
            />
        </g>
    </svg>
);
