import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";
import merge from "lodash/fp/merge";
import moment from "moment";

export const filters = {
    date: {
        start: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
        end: moment()
            .endOf("month")
            .format("YYYY-MM-DD"),
    },
    platform: "all",
};

export const defaultState = {
    list: {},
    filtersDismissed: filters,
    filters: filters,
    insightsPending: false,
};

export default createReducer(defaultState, {
    [types.UPDATE_INSIGHTS_FILTER]: (state, action) => {
        return merge(state, {
            ...state,
            filters: {
                [action.payload.type]: {
                    [action.payload.filter]: action.payload.value,
                },
            },
        });
    },
    [types.UPDATE_DISMISSED_INSIGHTS_FILTER]: (state, action) => {
        return merge(state, {
            ...state,
            filtersDismissed: action.payload.filters,
            filters: action.payload.filters,
        });
    },
    [types.UPDATE_INSIGHTS_FILTERS]: (state, action) => {
        return merge(state, {
            ...state,
            filters: action.payload.filters,
            filtersDismissed: action.payload.filters,
        });
    },
    [types.TOGGLE_INSIGHT_VISIBILITY]: (state, action) => {
        const id = action.payload.id;

        return merge(state, {
            ...state,
            list: {
                [id]: {
                    dismissed: {
                        value: !state.list[id]?.dismissed?.value,
                        updated: new Date().toISOString(),
                        userId: action.payload.userId,
                    },
                },
            },
        });
    },
    [types.DISMISS_INSIGHT_REQUEST]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                dismissPending: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                dismissPending: false,
            }),
        }),
    [types.GET_INSIGHTS]: (state, action) =>
        handle(state, action, {
            start: (prevState) =>
                merge(prevState, {
                    ...prevState,
                    insightsPending: true,
                }),
            success: (prevState) => ({
                ...prevState,
                list: action.payload.data,
            }),
            finish: (prevState) => ({
                ...prevState,
                insightsPending: false,
            }),
        }),
});
