import React, { useEffect } from "react";
import style from "./style.module.scss";
import sharedStyles from "../style.module.scss";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import RenderInput from "components/RenderInput";
import SubmitButton from "components/SubmitButton";
import HeroLogin from "assets/images/Illustrations/Hero-Login.svg";
import GoogleLogin from "../Google/Authorize";
import GoogleButton from "../Google/Button";
import ErrorBar from "components/AlertNotificationBar/Error";
import SuccessBar from "components/AlertNotificationBar/Success";
import { setCode, setHideAlerts } from "lib/auth";
import { BrandLogo, isOnDefaultDomain } from "components/Branding";

export default ({ error, handleSubmit, submitting, successMessage, failMessage } = {}) => {
    useEffect(() => {
        //For returning users with a code stored in their cache
        setCode(null);
        setHideAlerts(false);
    }, []);

    return (
        <div className={sharedStyles.page}>
            <div className={sharedStyles.background} />
            <div className={sharedStyles.containerWrapper}>
                <div className={sharedStyles.container}>
                    {isOnDefaultDomain() && (
                        <div className={sharedStyles.logoWrapper}>
                            <BrandLogo width={70} height={70} />
                        </div>
                    )}
                    {/* <TipCard
                        subtitle={
                            <>
                                Our login service provider is currently experiencing technical difficulties that may
                                impact your ability to login. We apologize for the inconvenience and will remove this
                                banner when the issue is fixed.
                            </>
                        }
                    /> */}
                    <div className={sharedStyles.formCard}>
                        <div className={sharedStyles.formCardHeader}>
                            <h1>Log In</h1>
                            <p>Welcome back!</p>
                        </div>
                        {successMessage && <SuccessBar>Google account linked successfully.</SuccessBar>}
                        {failMessage && <ErrorBar>{failMessage ? failMessage : "Login failed."}</ErrorBar>}
                        <GoogleLogin
                            component={({ authUrl }) => {
                                return <GoogleButton text="Log in With Google" href={authUrl} />;
                            }}
                        />
                        <form noValidate onSubmit={handleSubmit}>
                            {!submitting && error && <div className={sharedStyles.formError}>{error}</div>}
                            <div className={sharedStyles.formCardBody}>
                                <Field
                                    component={RenderInput}
                                    label="Email Address"
                                    placeholder="name@company.com"
                                    name="email"
                                    type="email"
                                    large
                                />
                                <Field
                                    component={RenderInput}
                                    label="Password"
                                    placeholder="Password"
                                    name="password"
                                    large
                                    type="password"
                                    sideText={
                                        <Link
                                            className={style.forgotPassword}
                                            to="/forgot-password"
                                            title="Forgot Password?"
                                        >
                                            Forgot Password?
                                        </Link>
                                    }
                                />
                            </div>
                            <SubmitButton
                                label={submitting ? "Processing" : "Log In"}
                                disabled={submitting}
                                className={sharedStyles.submitButton}
                            />

                            <div className={style.formCardFooter}>
                                <p>
                                    Don't have an account?{" "}
                                    <a href="https://hawke.ai/activate" title="Sign Up">
                                        Sign Up
                                    </a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={sharedStyles.heroLogin}>
                    <img alt="Hero-Login-Banner" src={HeroLogin} />
                </div>
            </div>
        </div>
    );
};
