import React from "react";
import style from "./style.module.scss";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { getAlertColor, getNotificationText } from "./utility";
import { ReactComponent as IconExclamation } from "assets/images/icons/Icon-Exclamation.svg";
import moment from "moment";

const Notification = ({ notification, history }) => {
    const {
        accountId,
        date,
        accountName,
        content: { property = "", alertType = "", expectedValue = "", value = "", ...props },
        status,
    } = notification;
    const alertColor = getAlertColor(status);

    const subContent = (
        <>
            <strong>{accountName} Alert:</strong> Your actual value for{" "}
            <strong style={{ color: alertColor }}>{property}</strong> was <strong>{value}</strong>, expected range was{" "}
            <strong>{expectedValue}</strong>.
        </>
    );

    const handleClickNotification = () => {
        history.push(`/dashboard/${accountId}/alerts`);
    };

    const dateFormatted = moment(date).format("MMM DD, YYYY");

    if (isEmpty(notification)) return <></>;
    return (
        <div className={style.mentionNotification} onClick={handleClickNotification}>
            <div className={style.mentionNotificationIcon} style={{ backgroundColor: alertColor }}>
                <IconExclamation />
            </div>
            <div className={style.mentionNotificationInner}>
                <div className={style.mentionInfo}>
                    <p className={style.mentionComment}>
                        {subContent}
                        <b>{getNotificationText(notification)}</b>
                    </p>
                </div>
                <span>{dateFormatted}</span>
            </div>
        </div>
    );
};

export default React.memo(withRouter(Notification));
