import { connect } from "react-redux";
import Component from "./index";
import { makeSelectAccountId, makeSelectIntegrationIds } from "_redux/sites/selectors";
import { change, getFormValues, reduxForm, reset } from "redux-form";
import { BUDGET_PLANNER_FORM } from "./BudgetConfigure/container";
import { budgetPlannerDataFomatter } from "./BudgetConfigure/helpers";
import { getInitValues } from "./helpers";
import {
    makeSelectBudgetPlannerAverageMetrics,
    makeSelectBudgetPlannerData,
    makeSelectBudgetPlannerLoading,
} from "./ducks/selectors";
import { isEmpty } from "lodash";
import operations from "./ducks/operations";
import { getMonthsBetween } from "./utility";
import moment from "moment";

const { getBudgetPlannerData } = operations;

const mapStateToProps = (state) => {
    const accountId = makeSelectAccountId()(state);
    const budgetPlannerData = makeSelectBudgetPlannerData()(state);
    const isLoading = makeSelectBudgetPlannerLoading()(state);
    const { initBudgets, initPlatforms } = getInitValues(budgetPlannerData);
    const { numberOfMonths = 6, metric = "roas", platforms, budgets, budgetType = "monthly", metricsAverages } =
        getFormValues(BUDGET_PLANNER_FORM)(state) || {};
    const months = getMonthsBetween(moment().add(1, "month"), moment().add(numberOfMonths, "months"));
    const initMetricsAverages = makeSelectBudgetPlannerAverageMetrics()(state);

    const cleanBudgets = Object.keys(budgets || {}).reduce((acc, key) => {
        return key === "total" ? acc : { ...acc, [key]: Math.round(parseFloat(budgets[key])) };
    }, {});

    const integrations = makeSelectIntegrationIds()(state);
    const hasIntegrations = Object.values(integrations).find((integration) => !isEmpty(integration));

    return {
        accountId,
        budgetPlannerData,
        availablePlatfoms: Object.keys(initPlatforms),
        numberOfMonths: parseInt(numberOfMonths),
        months,
        metric,
        budgetType,
        platforms,
        initPlatforms,
        budgets: cleanBudgets,
        initBudgets,
        metricsAverages,
        initMetricsAverages,
        hasIntegrations,
        isLoading,
        initialValues: {
            metric,
            numberOfMonths,
            platforms,
            budgets: cleanBudgets,
            budgetType,
            metricsAverages,
        },
    };
};
const mapDispatchToProps = (dispatch) => ({
    change: (...args) => dispatch(change(BUDGET_PLANNER_FORM, ...args)),
    getBudgetPlannerData: ({ accountId, budgets, platforms, numberOfMonths, metric }) =>
        dispatch(
            getBudgetPlannerData({
                accountId,
                budgets,
                platforms,
                numberOfMonths,
                metric,
            }),
        ),
});

const form = reduxForm({
    form: BUDGET_PLANNER_FORM,
    onSubmit: async (formData, dispatch, { accountId, months, budgetPlannerData }) => {
        const {
            budgets: budgetsForm,
            platforms: platformsForm,
            numberOfMonths,
            metric,
            budgetType,
            metricsAverages,
        } = formData;

        const { budgets, platforms } = budgetPlannerDataFomatter({
            budgetType,
            months,
            budgetPlannerData,
            budgets: budgetsForm,
            platforms: platformsForm,
        });

        dispatch(
            getBudgetPlannerData({
                accountId,
                budgets,
                platforms,
                numberOfMonths,
                metric,
                metricsAverages,
            }),
        );
        reset();
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Component));
