import React from "react";
import { requireAuth, isLoggedIn, logout } from "./auth";
import { lifecycle, branch, renderComponent } from "recompose";
import { Redirect } from "react-router";
import UserLoginContainer from "../features/Auth/Login/container";
import qs from "qs";

const protect = lifecycle({
    componentWillMount() {
        requireAuth(this.props.history, this.props.history.replace);
    },
});

const Logout = lifecycle({
    componentWillMount: logout,
})(({ location: { search } }) => (
    <Redirect
        to={{
            pathname: "/login",
            search,
        }}
    />
));

const checkIfOnLogin = branch(
    ({ history }) => history.location.pathname === "/login",
    renderComponent(UserLoginContainer),
);

const redirectIfLoggedIn = ({ location }) => {
    const { search = "" } = location || {};
    const { redirect } = qs.parse(search.substring(1));

    return <Redirect to={redirect || "/dashboard"} />;
};

const RedirectPath = branch(
    isLoggedIn,
    renderComponent(() => <Redirect to="/dashboard" />),
    renderComponent(checkIfOnLogin(() => <Redirect to="/login" />)),
)();

const RedirectLoginPath = branch(
    isLoggedIn,
    renderComponent(redirectIfLoggedIn),
    renderComponent(UserLoginContainer),
)();

export { protect, Logout, RedirectPath, RedirectLoginPath };
