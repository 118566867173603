import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    channelData: [],
    pendingChannelData: true,
    errorChannelData: null,
    eventsData: [],
    pendingEventsData: true,
    errorEventsData: null,
    landingPageData: [],
    pendingLandingPageData: true,
    errorLandingPageData: null,
    devicesData: [],
    pendingDevicesData: true,
    errorDevicesData: null,
    userAgeRangeData: [],
    pendingUserAgeRangeData: true,
    errorUserAgeRangeData: null,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.CLEAR_GA4_DATA](state, action) {
            return {
                ...state,
                ...defaultState,
            };
        },
        [types.GET_GA4_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingChannelData: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingChannelData: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    channelData: action.payload || [],
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorChannelData: action.error,
                }),
            }),
        [types.GET_GA4_EVENTS_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingEventsData: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingEventsData: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    eventsData: action.payload || [],
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorEventsData: action.error,
                }),
            }),
        [types.GET_GA4_LANDING_PAGE_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingLandingPageData: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingLandingPageData: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    landingPageData: action.payload || [],
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorLandingPageData: action.error,
                }),
            }),
        [types.GET_GA4_DEVICES_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingDevicesData: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingDevicesData: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    devicesData: action.payload || [],
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorDevicesData: action.error,
                }),
            }),
        [types.GET_GA4_USER_AGE_RANGE_DATA]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pendingUserAgeRangeData: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pendingUserAgeRangeData: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    userAgeRangeData: action.payload || [],
                }),
                failure: (prevState) => ({
                    ...prevState,
                    errorUserAgeRangeData: action.error,
                }),
            }),
    })
);
