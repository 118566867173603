import types from "./types";

const getSitesPending = ({ pending } = {}) => ({
    type: types.GET_SITES_PENDING,
    pending,
});

const getSitesFufilled = ({ payload } = {}) => ({
    type: types.GET_SITES_FUFILLED,
    payload,
});

const getSitesRejected = ({ error, payload } = {}) => ({
    type: types.GET_SITES_REJECTED,
    error,
    payload,
});

const updateAccountTagsReduxOnly = ({ accountId, tags } = {}) => ({
    type: types.UPDATE_TAGS,
    meta: {
        accountId,
        tags,
    },
});

const toggleMetricsManager = () => ({
    type: types.TOGGLE_METRICS_MANAGER,
});

const toggleExportManager = () => ({
    type: types.TOGGLE_EXPORT_MANAGER,
});

const updateSearch = ({ search }) => ({
    type: types.UPDATE_OVERVIEW_SEARCH,
    search,
});

const updateSortByDataType = ({ sortByDataType }) => ({
    type: types.UPDATE_OVERVIEW_SORT_BY_DATA_TYPE,
    sortByDataType,
});

export const updateUi = ({ overviewUi }) => ({
    type: types.UPDATE_OVERVIEW_UI,
    overviewUi,
});

export const setShowAddAccountModal = (showModal) => ({
    type: types.SET_SHOW_ADD_ACCOUNT_MODAL,
    payload: showModal,
});

const updateOverviewTagsAvailable = (tags) => ({
    type: types.UPDATE_OVERVIEW_TAGS_AVAILABLE,
    tags,
});

const updateAccountIntegrations = (accountId, integrations) => ({
    type: types.ACCOUNT_UPDATE_INTEGRATIONS,
    payload: {
        accountId,
        integrations,
    },
});

export const clearAccountData = () => ({
    type: types.CLEAR_ACCOUNT_DATA,
});

export const clearMetricsData = () => ({
    type: types.CLEAR_METRICS_DATA,
});

export default {
    getSitesPending,
    getSitesFufilled,
    getSitesRejected,
    updateAccountTagsReduxOnly,
    toggleMetricsManager,
    toggleExportManager,
    updateSearch,
    updateSortByDataType,
    updateOverviewTagsAvailable,
    setShowAddAccountModal,
    updateAccountIntegrations,
    clearAccountData,
    clearMetricsData,
};
