import React from "react";
import Select from "react-select";
import style from "./style.module.scss";
import AsyncSelect from "react-select/lib/Async";

const MultiSelector = (props) => {
    return (
        <>
            {props.label && (
                <label htmlFor={props.name} className={style.label}>
                    {props.label}
                </label>
            )}
            <Select
                {...props}
                value={props.input.value}
                onChange={(value) =>
                    props.selectValue && value
                        ? props.input.onChange(value.value)
                        : props.input.onChange(value)
                }
                options={props.options}
                className={`selectInput ${props.className} ${props.darkBorder && "darkBoarder"}`}
            />
        </>
    );
};
export default MultiSelector;

export const MultipleSelector = (props) => {
	return <MultiSelector {...props} multi />
}

export const AsyncMultiSelector = (props) => {
    return (
        <>
            {props.label && (
                <label htmlFor={props.name} className={style.label}>
                    {props.label}
                </label>
            )}
            <AsyncSelect {...props} className={`selectInput ${props.className}`} />
        </>
    );
};
