import { createSelector } from "reselect";
import { makeSelectSubscription } from "../../../../_redux/users/selectors";

export const selectPlans = (state) => state.plans;

export const makeSelectPlan = (plan) =>
    createSelector(selectPlans, (plans) => {
        return (
            {
                id: plan,
                ...plans[plan]
            } || {}
        );
    });

export const makeSelectCurrentPlan = () =>
    createSelector([selectPlans, makeSelectSubscription()], (plans, { id }) => {
        return (
            {
                id,
                ...plans[id]
            } || {}
        );
    });
