import React, { useState, useCallback, useEffect } from "react";
import style from "./style.module.scss";
import moment from "moment";
import Button from "components/Button";
import Calendar from "assets/images/Report_Icons/JSX/Icon-Calendar";
import debounce from "lodash/debounce";

export const getErrorMessage = ({ error, meta = {} }) =>
    ({
        isNotValid: true,
        isPastMaxSelected: `Date range cannot exceed ${meta.maxDaysSelected} days.`,
        isAfterToday: "Please enter a date before today.",
        isBeforeTo: "Date must follow the start date.",
        isAfterEnd: "Date must precede the end date.",
        default: "",
    }[error || "default"]);

export const checkStartValidity = ({ value, end, maxDaysSelected }) => {
    const daysAfterEnd = new Date(moment(end).subtract(maxDaysSelected - 1, "days"));
    const isAfter = moment(value).isBefore(daysAfterEnd, "day");
    const isPastMaxSelected = maxDaysSelected ? isAfter : false;
    const isAfterToday = moment().isBefore(value, "day");
    const isAfterEnd = moment(value).isAfter(end, "day");
    const isNotValid = !moment(value).isValid();

    if (isNotValid) {
        return "isNotValid";
    }

    if (isAfterEnd) {
        return "isAfterEnd";
    }

    if (isAfterToday) {
        return "isAfterToday";
    }

    if (isPastMaxSelected) {
        return "isPastMaxSelected";
    }

    return "";
};

export const checkEndValidity = ({ value, start, maxDaysSelected }) => {
    const isAfterToday = moment().isBefore(value, "day");
    const isBeforeTo = moment(value).isBefore(start, "day");
    const daysAfterEnd = new Date(moment(start).add(maxDaysSelected - 1, "days"));
    const isAfter = moment(value).isAfter(daysAfterEnd, "day");
    const isPastMaxSelected = maxDaysSelected ? isAfter : false;
    const isNotValid = !moment(value).isValid();

    if (isNotValid) {
        return "isNotValid";
    }

    if (isBeforeTo) {
        return "isBeforeTo";
    }

    if (isAfterToday) {
        return "isAfterToday";
    }

    if (isPastMaxSelected) {
        return "isPastMaxSelected";
    }

    return "";
};

export default React.memo(
    ({
        from,
        to,
        onCancel = () => null,
        onApply = () => null,
        onInputChange,
        maxDaysSelected,
        initialStart,
        initialEnd,
        className,
        isOpen = false,
        startDateReadable = "",
        endDateReadable = "",
        updateStartDate = () => null,
        updateEndDate = () => null,
        error = {},
        updateErrors = () => null,
    }) => {
        const [typing, updateTyping] = useState({ start: false, end: false });

        const daysSelected = moment(to).diff(from, "days") + 1 || 0;

        const onEndChange = useCallback(
            debounce((value) => {
                const endError = checkEndValidity({ value, start: startDateReadable, maxDaysSelected });
                const startError = checkStartValidity({ value: startDateReadable, end: value, maxDaysSelected });

                updateTyping({ start: false, end: false });

                if (endError) {
                    onInputChange({}, true);
                    return updateErrors({
                        ...error,
                        end: getErrorMessage({ error: endError, meta: { maxDaysSelected } }),
                    });
                }

                updateErrors({
                    start: startError ? getErrorMessage({ error: startError, meta: { maxDaysSelected } }) : "",
                    end: "",
                });
                onInputChange({ end: value, start: startDateReadable });
            }, 500),
            [maxDaysSelected, startDateReadable, error, onInputChange],
        );

        const onStartChange = useCallback(
            debounce((value) => {
                const startError = checkStartValidity({ value, end: endDateReadable, maxDaysSelected });
                const endError = checkEndValidity({ value: endDateReadable, start: value, maxDaysSelected });

                updateTyping({ start: false, end: false });

                if (startError) {
                    onInputChange({}, true);
                    return updateErrors({
                        ...error,
                        start: getErrorMessage({ error: startError, meta: { maxDaysSelected } }),
                    });
                }

                updateErrors({
                    end: endError ? getErrorMessage({ error: endError, meta: { maxDaysSelected } }) : "",
                    start: "",
                });
                onInputChange({ start: value, end: endDateReadable });
            }, 500),
            [maxDaysSelected, endDateReadable, error, onInputChange],
        );

        const resetInputs = useCallback(() => {
            if (from) {
                updateStartDate(moment(from).format("MMM D, YYYY"));
            }
            if (to) {
                updateEndDate(moment(to).format("MMM D, YYYY"));
            }
            if (!isOpen && (error.start || error.end)) {
                updateErrors({ start: "", end: "" });
            }
        }, [from, to, error.end, error.start, isOpen, updateStartDate, updateEndDate]);

        useEffect(() => {
            if (!isOpen && (error.start || error.end)) {
                resetInputs();
            }
        }, [isOpen, error.start, error.end, resetInputs]);

        return (
            <div className={`${style.dateSelector} ${className ? className : ""}`}>
                <div className={style.dateSelectorContent}>
                    <p>Custom Date Range</p>
                    <div className={style.inputs}>
                        <div className={`${style.dateInput} ${error.start && !typing.start && style.inputError}`}>
                            <span>Start Date</span>{" "}
                            <input
                                value={startDateReadable}
                                placeholder="Set Start Date"
                                onChange={(e) => {
                                    e.persist();
                                    updateTyping({ start: true, end: false });
                                    updateStartDate(e.target.value);
                                    onStartChange(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                onBlur={(e) => {
                                    if (moment(e.target.value).isValid()) {
                                        updateStartDate(moment(e.target.value).format("MMM D, YYYY"));
                                    }
                                }}
                            />
                        </div>
                        {error.start && !typing.start && <span className={style.errorMessage}>{error.start}</span>}
                        <div className={`${style.dateInput} ${error.end && !typing.end && style.inputError}`}>
                            <span>End Date</span>{" "}
                            <input
                                value={endDateReadable}
                                placeholder="Set End Date"
                                onChange={(e) => {
                                    e.persist();
                                    updateTyping({ start: false, end: true });
                                    updateEndDate(e.target.value);
                                    onEndChange(e.target.value);
                                }}
                                onKeyDown={(e) => e.stopPropagation()}
                                onBlur={(e) => {
                                    if (moment(e.target.value).isValid()) {
                                        updateEndDate(moment(e.target.value).format("MMM D, YYYY"));
                                    }
                                }}
                            />
                        </div>
                        {error.end && !typing.end && <span className={style.errorMessage}>{error.end}</span>}
                    </div>
                    <div className={style.selected}>
                        <Calendar />{" "}
                        <span>
                            {daysSelected} {daysSelected === 1 ? "day" : "days"} selected
                        </span>
                    </div>
                </div>
                <div className={style.optionsContainer}>
                    <Button small secondary onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button
                        small
                        disabled={
                            error.start ||
                            error.end ||
                            (startDateReadable === moment(initialStart).format("MMM D, YYYY") &&
                                endDateReadable === moment(initialEnd).format("MMM D, YYYY"))
                        }
                        onClick={() => onApply(from, to)}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        );
    },
);
