import React from "react";

export default () => (
    <svg
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="⚙️-Settings" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g
                id="5.1.1-Settings-(Option)"
                transform="translate(-732.000000, -764.000000)"
                fillRule="nonzero"
            >
                <g id="Framework-Copy-5">
                    <g
                        id="Content"
                        transform="translate(262.000000, 136.000000)"
                    >
                        <g
                            id="Optionsal"
                            transform="translate(10.000000, 457.000000)"
                        >
                            <g
                                id="Component"
                                transform="translate(0.000000, 56.000000)"
                            >
                                <g
                                    id="Connected-Copy"
                                    transform="translate(32.000000, 32.000000)"
                                >
                                    <g
                                        id="Dropdown"
                                        transform="translate(0.000000, 64.000000)"
                                    >
                                        <path
                                            d="M434.085034,23.9998722 L437.77499,20.3099161 C438.074662,20.0102437 438.074662,19.5244267 437.77499,19.2247543 C437.475318,18.9250819 436.989501,18.9250819 436.689828,19.2247543 L432.999872,22.9147103 L429.309916,19.2257771 C429.010244,18.9261047 428.524427,18.9261047 428.224754,19.2257771 C427.925082,19.5254495 427.925082,20.0112665 428.224754,20.3109389 L431.91471,24.0008949 L428.225777,27.6898282 C427.926105,27.9895006 427.926105,28.4753176 428.225777,28.77499 C428.376125,28.9243148 428.571474,29 428.767847,29 C428.964219,29 429.160591,28.9253376 429.309916,28.77499 L432.999872,25.085034 L436.689828,28.77499 C436.840176,28.9243148 437.035525,29 437.231898,29 C437.42827,29 437.624642,28.9253376 437.773967,28.77499 C438.07364,28.4753176 438.07364,27.9895006 437.773967,27.6898282 L434.085034,23.9998722 Z"
                                            id="Icon-Close-Small"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
