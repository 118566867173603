import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectAccountId, makeSelectIntegrationIds, makeSelectIsAnalyzingData } from "_redux/sites/selectors";
import moment from "moment";
import Button from "../Button";
import { setAnalyzingData } from "_redux/sites";
import { MorphioAPI } from "lib/api";
import ThreeDots from "components/Loading/ThreeDots";
import Integrations from "assets/images/Menu/JSX/Integrations";

export default () => {
    const [percentage, setPercentage] = useState(0);

    const dispatch = useDispatch();
    const integrations = useSelector((state) => makeSelectIntegrationIds()(state));
    const { active: isAnalyzingData, lastRun = null } = useSelector((state) => makeSelectIsAnalyzingData()(state));
    const accountId = useSelector((state) => makeSelectAccountId()(state));

    let hasNewIntegrations = false;
    Object.keys(integrations).forEach((platform) => {
        Object.values(integrations[platform]).forEach(({ created_at = null } = {}) => {
            if (created_at === null) return;
            if (moment(created_at).isAfter(moment().subtract(1, "day"))) {
                hasNewIntegrations = true;
            }
        });
    });

    const handleAnalyzeClick = () => {
        dispatch(setAnalyzingData(true)); // Dispatch the action to set analyzing data state
        dispatch(
            MorphioAPI({
                method: "POST",
                url: `/account/${accountId}/analyze`,
            }),
        );
    };

    useEffect(() => {
        if (isAnalyzingData) {
            const intervalId = setInterval(() => {
                const totalDuration = 7 * 60 * 1000; // 7 minutes in milliseconds
                const elapsed = moment().diff(moment(lastRun));
                const progressPercentage = Math.min(100, Math.max(1, Math.floor((elapsed / totalDuration) * 100)));
                setPercentage(progressPercentage);
            }, 5000); // 5000 milliseconds = 5 seconds

            // Cleanup the interval on component unmount or when isAnalyzingData changes
            return () => clearInterval(intervalId);
        }
    }, [isAnalyzingData, lastRun]);

    if (hasNewIntegrations) {
        if (isAnalyzingData) {
            return (
                <div className={style.container}>
                    <div className={style.icon}>
                        <div className={style.percentageCircle}>
                            {percentage > 0 ? `${percentage}%` : null}
                            <ThreeDots color="#fff" />
                        </div>
                    </div>
                    <div>
                        <h4>Analysis in Progress</h4>
                        <p>
                            Hawke.ai is analyzing your new data for new insights, possible anomalies, and more. This may
                            take a few minutes.
                        </p>
                    </div>
                </div>
            );
        }

        if (!lastRun || moment(lastRun).isBefore(moment().subtract(3, "hour"))) {
            return (
                <div className={style.container}>
                    <div className={style.icon}>
                        <Integrations />
                    </div>
                    <div>
                        <h4>New Integrations Detected</h4>
                        <p>
                            Hawke.ai has identified a new integration. Once all your integrations are connected, click
                            "Analyze Now" to generate insights from your new data.
                        </p>
                    </div>
                    <Button
                        small
                        blue
                        disabled={isAnalyzingData}
                        style={{ marginLeft: "auto" }}
                        onClick={handleAnalyzeClick}
                    >
                        Analyze Now
                    </Button>
                </div>
            );
        }
    }

    return null;
};
