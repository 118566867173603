import React from "react";
import styles from "./style.module.scss";

export default ({
    type = "submit",
    label = "Submit",
    disabled = false,
    className
} = {}) => (
    <button
        type={type}
        disabled={disabled}
        className={`${styles.submitButton} ${className}`}
    >
        <span>{label}</span>
    </button>
);
