import React, { useState } from "react";
import { ReactComponent as IconPlatform } from "assets/images/icons/Icon-Platform.svg";
import FilterIcon from "assets/images/icons/JSX/Icon-Filter";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { AccountTag } from "features/ControlPanel/AccountTags";
import { getPlatformUI } from "features/ControlPanel/LayoutSettings";
import { channelFriendlyName } from "lib/utility";
import { isEmpty } from "lodash";
import style from "../style.module.scss";
import classNames from "classnames";
import { ReactComponent as IconTag } from "assets/images/icons/Icon-Tag.svg";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import { TagSelect } from "features/ControlPanel/LayoutSettings/TagFilter";
import PlatformIcon from "components/Icon/PlatformIcon";

const FilterContainer = ({
    availableTags,
    dispatchUpdateOverviewUi,
    tags,
    platforms,
    contains,
    selectedPlatform,
    allPlatforms,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const outsideClick = useHandleOutsideClickHook(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [isOpen]);

    return (
        <div className={style.filterContainer}>
            <div className={style.filterIcon}>
                <FilterIcon />
                &nbsp;Filter Accounts
            </div>
            <div>
                <FilterDropdown
                    name="Platform"
                    className={style.filterDropdown}
                    onClickHandler={(props) => {
                        dispatchUpdateOverviewUi({ platformKey: props.value || "all" });
                    }}
                    allowSearch
                    hideLabel
                    itemSelected={selectedPlatform}
                    icon={<IconPlatform />}
                    options={allPlatforms.map((id) => {
                        return {
                            label: getPlatformUI(id),
                            value: id,
                            searchText: channelFriendlyName(id),
                        };
                    })}
                />
                {!isEmpty(selectedPlatform) && (
                    <div className={style.platformSelectedContainer}>
                        <PlatformIcon
                            platform={selectedPlatform.value}
                            backgroundColor={"#6A80A712"}
                            className={style.platformIcon}
                        />
                        <p>{channelFriendlyName(selectedPlatform.value)}</p>
                    </div>
                )}
            </div>
            <div>
                <FilterDropdown
                    name="Tags"
                    className={classNames(style.filterDropdown, style.filterDropdownTags)}
                    itemSelected={{
                        label: tags.join("+"),
                        value: tags,
                    }}
                    hideLabel
                    onClearAll={() => {
                        dispatchUpdateOverviewUi({ listTags: [], replace: true });
                    }}
                    icon={<IconTag />}
                    toggleOpen={setIsOpen}
                    isOpen={isOpen}
                    Component={
                        <>
                            {isOpen && (
                                <div className={style.tagsDropdown} ref={outsideClick}>
                                    <TagSelect
                                        availableTags={availableTags}
                                        selectedTags={tags}
                                        updateTags={(getListTags) => {
                                            dispatchUpdateOverviewUi({ listTags: getListTags(tags), replace: true });
                                        }}
                                        selectedContains={contains}
                                        updateContains={(containsValue) => dispatchUpdateOverviewUi({ containsValue })}
                                        toggleOpen={setIsOpen}
                                        isOpen={isOpen}
                                    />
                                </div>
                            )}
                        </>
                    }
                />
                <div className={style.tagsSelectedContainer}>
                    {!isEmpty(tags) &&
                        tags.map((tagId) => {
                            const { color, hexColor, name } = availableTags.find(({ id }) => id === tagId) || {};
                            return (
                                <AccountTag
                                    key={tagId}
                                    className={style.accountTag}
                                    color={color}
                                    active
                                    hexColor={hexColor}
                                    tag={name}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default FilterContainer;
