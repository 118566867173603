import types from "./types";

const setSearchTermsAdGroupFilter = (payload = {}) => ({
    type: types.SET_SEARCH_TERMS_ADGROUP_FILTER,
    payload,
});

const setSearchTermsCampaignFilter = (payload = {}) => ({
    type: types.SET_SEARCH_TERMS_CAMPAIGN_FILTER,
    payload,
});

export const resetCampaignAssetReports = () => ({ type: types.RESET_CAMPAIGN_ASSET_REPORTS });
export const resetAssetGroupReports = () => ({ type: types.RESET_ADWORDS_ASSET_GROUP_REPORTS });
export const resetAssetGroupAssets = () => ({ type: types.RESET_ADWORDS_ASSET_GROUP_ASSETS });

export default {
    setSearchTermsAdGroupFilter,
    setSearchTermsCampaignFilter,
    resetCampaignAssetReports,
    resetAssetGroupReports,
    resetAssetGroupAssets,
};
