const COMPETITOR_SEO_DATA = "COMPETITOR_SEO_DATA";
const COMPETITOR_ANOMALIES_DATA = "COMPETITOR_ANOMALIES_DATA";
const CONTENT_SUGGESTIONS_DATA = "CONTENT_SUGGESTIONS_DATA";
const CONTENT_SUGGESTIONS_REMOVE = "CONTENT_SUGGESTIONS_REMOVE";
const UPDATE_COMPETITOR_COMMENTS = "UPDATE_COMPETITOR_COMMENTS";
const COMPETITOR_DELETE_COMMENT = "COMPETITOR_DELETE_COMMENT";
const COMPETITOR_EDIT_COMMENT = "COMPETITOR_EDIT_COMMENT";
const COMPETITOR_COMMENT = "COMPETITOR_COMMENT";
const GENERATE_COMPETITORS = "GENERATE_COMPETITORS";
const ACCOUNT_SUGGEST_KEYWORDS = "ACCOUNT_SUGGEST_KEYWORDS";
const ACCOUNT_SUGGEST_COMPETITORS = "ACCOUNT_SUGGEST_COMPETITORS";
const COMPETITOR_LOCATIONS_DATA = "COMPETITOR_LOCATIONS_DATA";
const TOGGLE_SEO_ANOMALY_VISIBILITY = "TOGGLE_SEO_ANOMALY_VISIBILITY";

export default {
    COMPETITOR_SEO_DATA,
    COMPETITOR_ANOMALIES_DATA,
    CONTENT_SUGGESTIONS_DATA,
    CONTENT_SUGGESTIONS_REMOVE,
    UPDATE_COMPETITOR_COMMENTS,
    COMPETITOR_DELETE_COMMENT,
    COMPETITOR_EDIT_COMMENT,
    COMPETITOR_COMMENT,
    GENERATE_COMPETITORS,
    ACCOUNT_SUGGEST_KEYWORDS,
    ACCOUNT_SUGGEST_COMPETITORS,
    COMPETITOR_LOCATIONS_DATA,
    TOGGLE_SEO_ANOMALY_VISIBILITY,
};
