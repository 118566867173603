import { connect } from "react-redux";
import Component from "./index";
import { updateUserDowngrade, updateUserTrialDismiss, downgradeAppsumoUser } from "_redux/users";
import { actions } from "./_ducks";
import {
    makeSelectType,
    makeSelectUserId,
    makeSelectIsTrial,
    makeSelectDaysRemainingInTrial,
    makeSelectUserDowngrade,
    makeSelectUserTrialDismiss,
    makeSelectSubscriptionPlanId,
    makeSelectIsFree,
    makeSelectRole,
    makeSelectEmail,
    makeSelectUserName,
} from "_redux/users/selectors";
import { makeSelectPlanOptionsOpen, makeSelectPlanOptionsStep } from "./_ducks/selectors";
import { selectPlans, makeSelectCurrentPlan } from "features/AccountSettings/PlansAndBilling/_ducks/selectors";
import { getPlans } from "features/AccountSettings/PlansAndBilling/_ducks/operations";

const { togglePlanOptions } = actions;

const mapStateToProps = (state) => {
    const isDowngraded = makeSelectUserDowngrade()(state);
    const dismissedTrialWall = makeSelectUserTrialDismiss()(state);
    const isTrial = makeSelectIsTrial()(state);
    const isFree = makeSelectIsFree()(state);
    const planType = makeSelectSubscriptionPlanId()(state);
    const plans = selectPlans(state);
    const role = makeSelectRole()(state);
    const userType = makeSelectType()(state) || "regular";
    const currentPlan = makeSelectCurrentPlan()(state);

    return {
        isOpen: makeSelectPlanOptionsOpen()(state),
        step: makeSelectPlanOptionsStep()(state),
        userId: makeSelectUserId()(state),
        userEmail: makeSelectEmail()(state),
        userName: makeSelectUserName()(state),
        daysLeftInTrial: makeSelectDaysRemainingInTrial()(state),
        isTrial,
        isDowngraded,
        dismissedTrialWall,
        forcePlanOptions:
            !userType.includes("appSumoUser") &&
            role === "super-admin" &&
            !isDowngraded &&
            !isTrial &&
            !isFree &&
            planType === "freemium",
        plans,
        userType,
        currentPlan,
        isFreemium: planType === "freemium",
    };
};

const mapDispatchToProps = (dispatch) => ({
    downgradeToFree: (datetime, userId) => dispatch(updateUserDowngrade({ datetime, userId })),
    downgradeAppsumoUser: (userId) => dispatch(downgradeAppsumoUser({ userId })),
    dismissTrialWall: (datetime, userId) => dispatch(updateUserTrialDismiss({ datetime, userId })),
    toggleOpen: (isOpen) => dispatch(togglePlanOptions({ isOpen })),
    getPlans: () => dispatch(getPlans()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
