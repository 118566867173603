import React from "react";

export default () => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M6,6 L6,9.07388028 C6,9.58511232 5.55387666,9.99996512 5.00013221,10 C4.44627444,10 4,9.58511232 4,9.07391517 L4,6 L0.926049951,6 C0.414852798,6 0,5.55368779 0,4.99986779 C0,4.44612334 0.414852798,4 0.926049951,4 L4,4 L4,0.926049951 C4,0.414852798 4.44631221,3.39035989e-17 5.00013221,-8.09569137e-21 C5.55387666,-3.39151642e-17 6,0.414852798 6,0.926049951 L6,4 L9.07388028,4 C9.58511232,4 9.99996512,4.44612334 10,4.99986779 C10,5.55372556 9.58511232,6 9.07391517,6 L6,6 Z"
            id="path-1"
        ></path>
    </svg>
);
