import React from "react";

export default () => (
    <svg
        width="12px"
        height="12px"
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="💰--Budget"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="4.2-Budget-(Reached)"
                transform="translate(-316.000000, -606.000000)"
                fill="#FFFFFF"
            >
                <g id="Framework">
                    <g
                        id="Content"
                        transform="translate(271.000000, 136.000000)"
                    >
                        <g id="Components-/-Metrics">
                            <g
                                id="Goals"
                                transform="translate(0.000000, 96.000000)"
                            >
                                <g
                                    id="State-2"
                                    transform="translate(3.000000, 296.000000)"
                                >
                                    <g
                                        id="Ads"
                                        transform="translate(42.000000, 78.000000)"
                                    >
                                        <path
                                            d="M4.12567021,1.45362376 C4.24100861,1.13305084 4.39959892,0.837920211 4.6350815,0.598762634 C5.57701182,-0.373133053 7.1340803,-0.128887017 7.7732473,1.09234317 C8.25382399,2.01844272 8.76323528,2.92418844 9.25822928,3.84011108 C10.0848212,5.36156034 10.9210246,6.88300961 11.738005,8.40954734 C12.4252297,9.68675057 11.6803358,11.2997921 10.315498,11.5185958 C9.47929455,11.6508958 8.69595455,11.2438191 8.26343552,10.4500194 C7.53776472,9.11175469 6.80728815,7.77348995 6.08161734,6.44031367 C6.06720004,6.40978292 6.04797697,6.38434062 6.02875391,6.35889833 C5.95186164,6.29274836 5.91822127,6.19606763 5.8701636,6.10956383 C5.54817721,5.50912566 5.2165793,4.91377594 4.89459291,4.31842623 C4.68794494,3.93170334 4.47168542,3.55006891 4.26503745,3.16334602 C4.07761254,2.8173308 3.99110873,2.44078482 4.00072027,2.04388502 C4.01513757,1.84034665 4.0391664,1.63680829 4.12567021,1.45362376"
                                            id="Path"
                                        ></path>
                                        <path
                                            d="M4.06076665,1.45362376 C4.01751475,1.63680829 3.97906861,1.81999282 3.96945708,2.01335426 C3.95503978,2.44078482 4.05596089,2.83768463 4.2578031,3.20914215 C4.78643746,4.17086092 5.31507182,5.13766814 5.83890041,6.10447537 C5.88695808,6.19097918 5.92540422,6.27748298 5.97346189,6.35889833 C5.68511587,6.88809807 5.39676986,7.41220936 5.10361807,7.9414091 C4.69993365,8.67923567 4.29624923,9.4221507 3.88775904,10.1599773 C3.86853598,10.1599773 3.86373021,10.1498003 3.85892444,10.134535 C3.85411867,10.0938273 3.86853598,10.0582081 3.87814751,10.0175004 C4.07518395,9.25423155 3.91178788,8.57746649 3.41679389,7.99738215 C3.11403057,7.64627848 2.72956922,7.44782857 2.28743866,7.3816786 C1.71074663,7.2951748 1.20133533,7.45291703 0.744787477,7.83455146 C0.66308944,7.90070143 0.610226004,7.99738215 0.514110665,8.04826674 C0.494887598,8.04826674 0.485276064,8.03808983 0.480470297,8.02282445 C0.711147109,7.60048234 0.937018154,7.17814024 1.16769497,6.75579814 C2.11923682,5.00536821 3.07077867,3.25493828 4.02712628,1.50959681 C4.03673782,1.48924297 4.05115512,1.4739776 4.06076665,1.45362376"
                                            id="Path"
                                            opacity="0.7"
                                        ></path>
                                        <path
                                            d="M0.502006333,8.00211929 C0.593738556,7.92001166 0.680642767,7.83307416 0.777203001,7.75579639 C1.95040985,6.82846314 3.71263414,7.49981378 3.96851876,8.96809142 C4.03128291,9.32067125 3.99748683,9.6587615 3.89127057,9.99685174 C3.88644256,10.0258309 3.88161455,10.0499802 3.87195852,10.0789594 C3.82850642,10.1562371 3.78988232,10.2383448 3.74160221,10.3156225 C3.31190916,11.0256121 2.67943962,11.3781919 1.84902161,11.3250634 C0.897903295,11.2574454 0.149561476,10.542626 0.0192051593,9.59597331 C-0.0435589933,9.13713655 0.0481732297,8.70727895 0.284745805,8.31123037 C0.333025922,8.22429288 0.390962063,8.14701511 0.444070192,8.06007762 C0.46821025,8.04075817 0.458554227,8.00211929 0.502006333,8.00211929"
                                            id="Path"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
