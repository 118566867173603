import React from "react";
import NavigationContainer from "../Navigation/container";
import style from "./style.css";
import UserPanel from "../../UserPanel/container";
import { Route, Switch } from "react-router";

export default ({ routes, children, path, showNavigation = true }) => {
    const WrapperComponent = routes.hideTopBar ? ({ children }) => <>{children}</> : UserPanel;
    return (
        <div className={`${style.defaultState}`}>
            <WrapperComponent>
                <Switch>
                    {routes.map(({ topPanel: TopPanel, ...rest }) => (
                        <Route
                            key={rest.path}
                            exact={rest.exact ? rest.exact : false}
                            path={rest.path}
                            render={(props) => <TopPanel {...props} />}
                        />
                    ))}
                </Switch>
            </WrapperComponent>
            <div className={style.contents}>
                {showNavigation && <NavigationContainer path={path} />}
                {children}
            </div>
        </div>
    );
};
