import React from "react";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { makeSelectUserMetaData } from "_redux/users/selectors";
import DEFAULT_LOGO from "assets/images/Logo/LogoBird.svg";

const DEFAULT_BRAND_NAME = "Hawke AI";
const WHITELISTED_DOMAINS = ["app.hawke.ai", "prototype-portal.hawke.ai", "prototype-portal.morphio.ai", "localhost"];

export const BrandLogo = ({ type, width = 32, height = 32 } = {}) => {
    const { logo: userLogo } = useSelector((state) => makeSelectUserMetaData()(state));
    return <LogoCard logo={userLogo || DEFAULT_LOGO} width={width} height={height} type={type} />;
};

export const BrandColor = () => {
    const { color: userColor } = useSelector((state) => makeSelectUserMetaData()(state));
    return userColor || "#0671EE";
};

export const BrandName = () => {
    const { name: userName } = useSelector((state) => makeSelectUserMetaData()(state));
    return userName || DEFAULT_BRAND_NAME;
};

const LogoCard = ({ logo, width, height, type }) => {
    return (
        <div className={`${style.logoContainer} ${type === "grey" && style.greyLogo}`}>
            <img src={logo} alt="logo" style={{ width: `${width}px`, height: `${height}px` }} />
        </div>
    );
};

export const isOnDefaultDomain = () => {
    if (WHITELISTED_DOMAINS.includes(window.location.hostname)) {
        return true;
    }
    return false;
};
