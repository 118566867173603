import React from "react";
import qs from "querystring";
import { Redirect } from "react-router";

const { REACT_APP_DOMAIN, REACT_APP_SHOPIFY_CLIENT_ID } = process.env;

const redirectUrl = `${REACT_APP_DOMAIN}/dashboard/user/connections`;

class ShopifyConnect extends React.Component {
    csrfCode() {
        return Math.random()
            .toString(36)
            .substring(7);
    }

    buildLoginUrl = (secret, shop) => {
        const url = `https://${shop}/admin/oauth/authorize`;
        const params = qs.stringify({
            client_id: REACT_APP_SHOPIFY_CLIENT_ID,
            scope: "read_analytics,read_orders,write_orders,read_all_orders",
            redirect_uri: redirectUrl,
            state: secret
        });

        console.log(`url + '?' + params;`, url + "?" + params);

        return url + "?" + params;
    };

    render() {
        const { shop } = qs.parse(window.location.search.substr(1)) || {};

        if (shop) {
            window.location.href = this.buildLoginUrl(this.csrfCode(), shop);
            return null;
        }

        return <Redirect to="/404" />;
    }
}

export default ShopifyConnect;
