import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";
import { LoadingCircles } from "components/Loading";

export default ({ children, isLoading = false, className }) => (
    <div className={classNames(style.tableWrapper, { [className]: className })}>
        {children}
        {isLoading && (
            <div className={style.loading}>
                <LoadingCircles />
            </div>
        )}
    </div>
);
