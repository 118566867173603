import React from "react";
import style from "./style.module.scss";
import GoogleIcon from "../../../../assets/images/icons/JSX/Icon-Google";

export default ({ text, href, onClick }) => (
    <div className={style.googleButtonWrapper}>
        <a onClick={onClick} className={style.googleButton} href={href}>
            <div className={style.googleIcon}>
                <GoogleIcon />
            </div>
            <span>{text}</span>
        </a>
        <div className={style.divider}>
            <div className={style.dividingLine}></div>
            <p>or</p>
            <div className={style.dividingLine}></div>
        </div>
    </div>
);
