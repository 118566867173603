import React, { useState, useEffect } from "react";
import { LoadingCircles } from "..";
import style from "./style.module.scss";
import InfoIcon from "assets/images/Examples/Icon-DYK-Shield.svg";
import CampaignManagement from "assets/images/Examples/Graphic-CampaignManagement.png";
import DataBenchmarking from "assets/images/Examples/Graphic-DataBenchmarking.png";
import MonthlyInsights from "assets/images/Examples/Graphic-MonthlyInsights.png";
import { useSelector } from "react-redux";
import { makeSelectAccountLoading, selectAccountMetadata } from "_redux/sites/selectors";
import { toTagText } from "features/Insights/Insight/utility";

const loadingText = [
    { value: "Loading Connections...", t: 1 },
    { value: "Connecting to your platforms...", t: 8 },
    { value: "Awaiting response...", t: 500 },
];

const defaultOptions = [
    // {
    //     type: "info",
    //     value: (
    //         <span>
    //             Across HawkeAI benchmark accounts, the average ecommerce revenue was down <strong>3%</strong> in July
    //             2023 compared to 2022. For the year, revenue in January-June had dropped <strong>4%</strong>.
    //         </span>
    //     ),
    // },
    // {
    //     type: "info",
    //     value: (
    //         <span>
    //             Across HawkeAI benchmark accounts, the average total number of sessions being <strong>10%</strong> down
    //             year-to-date in 2023.
    //         </span>
    //     ),
    // },
    // {
    //     type: "info",
    //     value: (
    //         <span>
    //             Across HawkeAI benchmark accounts, media spend in July was consistent with June, with a less than{" "}
    //             <strong>1%</strong> variance. Paid social platforms took a small percentage of the mix away from paid
    //             search.
    //         </span>
    //     ),
    // },
    // {
    //     type: "info",
    //     value: (
    //         <span>
    //             Across HawkeAI benchmark accounts, CPAs on Meta and Google declined by <strong>22%</strong> and{" "}
    //             <strong>20%</strong>, respectively.
    //         </span>
    //     ),
    // },
    {
        type: "feature",
        title: "Stay on top of trends",
        image: MonthlyInsights,
        value: (
            <span>
                Do you know how your performance last month compared to industry trends? Head to{" "}
                <strong>Monthly Insights</strong> to find out!
            </span>
        ),
    },
    {
        type: "feature",
        title: "Compare your performance",
        image: DataBenchmarking,
        value: (
            <span>
                Use <strong>Data Benchmarking</strong> to compare your performance against others in your industry.
            </span>
        ),
    },
    {
        type: "feature",
        title: "Make live changes to campaigns",
        image: CampaignManagement,
        value: (
            <span>
                Use the Campaign Management tool on the <strong>Campaign Groups</strong> to make live changes to
                campaign status and budgets.
            </span>
        ),
    },
];

const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

export default () => {
    const [loadingDynamicText, setLoadingDynamicText] = useState(loadingText[0].value);
    const [selectedOption, setSelectedOption] = useState({});

    const metadata = useSelector((state) => selectAccountMetadata(state));
    const selectSiteLoading = useSelector((state) => makeSelectAccountLoading()(state));
    const { dailyInsights = [] } = metadata;

    const options =
        dailyInsights.length > 0 && !selectSiteLoading
            ? dailyInsights.map(({ insight } = {}, index) => ({
                  type: "info",
                  value: <span key={`dailyInsight-${index}`}>{insight}</span>,
              }))
            : defaultOptions;

    let index = 0;
    useEffect(() => {
        const intervalId = setInterval(() => {
            setLoadingDynamicText(loadingText[index].value);
            if (index < loadingText.length - 1) index++;
        }, loadingText[index].t * 1000);
        setSelectedOption(getRandomElement(options));

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={style.loadingWrapper}>
            <div className={style.loadingWrapperLeft}>
                <div className={style.loadingCircle}>
                    <LoadingCircles />
                </div>
                <h1>Loading your data</h1>
                <h2>{loadingDynamicText}</h2>
            </div>
            <div className={style.loadingWrapperRight}>
                {selectedOption.type === "info" ? (
                    <div>
                        <div>
                            <p>{toTagText(selectedOption.value)}</p>
                        </div>
                        <img src={InfoIcon} />
                    </div>
                ) : (
                    <div>
                        <div>
                            <h2>New Feature</h2>
                            <h1>{selectedOption.title}</h1>
                            <p>{selectedOption.value}</p>
                        </div>
                        <img src={selectedOption.image} style={{ marginRight: "-50px" }} />
                    </div>
                )}
            </div>
        </div>
    );
};
