import React from "react";
import style from "./style.module.scss";
import ExtraLabel from "features/AlertSettings/ExtraLabel";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import MaterialUITable from "components/Table/MaterialUITable";
import { BUDGET_THRESHOLD_HEAD_CELLS, getBudgetThresholdTableRows } from "./helpers";

const BudgetThresholdTable = ({ availablePlatfoms, platforms, initMetricsAverages }) => {
    const [metrics, setMetrics] = React.useState({});
    React.useEffect(() => {
        setMetrics(initMetricsAverages);
    }, [initMetricsAverages]);

    return (
        <div className={style.budgetMigrationThreshold}>
            <div className={style.mainTitle}>Platforms Expected Performance</div>
            <div className={style.thresholdSectionLabel}>
                <ExtraLabel
                    subLabel={
                        <>
                            We calculate the ROAS based on the average performance of the last 3 months. Adjust the
                            metrics below to see how it affects your budget.
                        </>
                    }
                    haveMargin
                />
            </div>

            <TableWrapper className={style.tableWrapper}>
                <MaterialUITable
                    headCells={BUDGET_THRESHOLD_HEAD_CELLS}
                    rows={getBudgetThresholdTableRows({ availablePlatfoms, platforms, metricsAverages: metrics })}
                />
            </TableWrapper>
        </div>
    );
};

export default BudgetThresholdTable;
