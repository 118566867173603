import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <circle cx="8" cy="8" r="8" fill="#A7B4CB" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M11.8903315,6.04838542 C11.7958315,5.95875 11.6624721,5.93986111 11.5507065,6.0003125 L9.840644,7.09375 L8.23608152,4.86972222 C8.17673777,4.79368056 8.09059714,4.75 8,4.75 C7.90940965,4.75 7.82328465,4.79368056 7.76392527,4.86972222 L6.15936279,7.09375 L4.44930031,6.00029514 C4.33753469,5.93986111 4.20419094,5.95873264 4.10965969,6.04836806 C4.01512844,6.13800347 3.97737844,6.28137153 4.01351907,6.41348958 L5.20101905,10.7537674 C5.24103468,10.9000347 5.3624253,11 5.5,11 L10.5,11 C10.6375659,11 10.7589721,10.9000347 10.7989721,10.7537847 L11.9864721,6.41350694 C12.0226284,6.28138889 11.9848784,6.13802083 11.8903315,6.04838542 Z"
            />
        </g>
    </svg>
);
