import moment from "moment/moment";

export const DATE_FORMAT = "YYYY-MM-DD";
/**
 *
 * @param date : {MomentInput}
 * @param format : {string}
 * @return {Date}
 */
export const formatDate = (date, format = DATE_FORMAT) => {
    if (!format) {
        format = DATE_FORMAT;
    }
    if (!date) {
        return new Date();
    }
    return moment(date, format).toDate();
};

/**
 *
 * @param date : {MomentInput}
 * @param format : {string}
 * @return {string}
 */
export const formatDateToString = (date, format = DATE_FORMAT) => {
    if (!format) {
        format = DATE_FORMAT;
    }
    if (!date) {
        return moment().format(format);
    }
    return moment(date).format(format);
};

/**
 *
 * @param date
 * @param amount
 * @param unit
 * @param format
 * @return {string}
 */
export const subtractDateAndFormat = (
    date,
    amount = 0,
    unit = "days",
    format = DATE_FORMAT
) => {
    if (!format) {
        format = DATE_FORMAT;
    }
    if (!amount) {
        amount = 0;
    }
    return moment(date)
        .subtract(amount, unit)
        .format(format);
};
