import React from "react";
import style from "./style.module.scss";
import { pure } from "recompose";
import Dialog from "material-ui/Dialog";
import { Dialog as DialogVersionFour, Grow } from "@material-ui/core";

export const CloseDialog = ({ closeActions }) => (
    <div
        className={`Close-Dialog ${style.close}`}
        onClick={() => closeActions()}
    >
        <div>
            <span>close</span>
        </div>
    </div>
);

const getClasses = ({ rootClass = "", paperClass = "" }) => ({
    root: `${style.dialogRoot} ${rootClass ? rootClass : ""}`,
    paper: `${style.paper} ${paperClass ? paperClass : ""}`
});

export const DialogVFour = pure(
    ({ children, close, classes = {}, ...props }) => (
        <DialogVersionFour
            classes={getClasses(classes)}
            TransitionComponent={Grow}
            transitionDuration={450}
            {...props}
        >
            {children}
        </DialogVersionFour>
    )
);

export default pure(
    ({ className, parentClassname, contentClassName, close, ...props }) => (
        <Dialog
            className={`${style.customDialog} ${parentClassname} ${props.open &&
                style.dialogOpen}`}
            paperClassName={`${style.dialogPaper} ${className}`}
            actionsContainerClassName={style.dialogFooter}
            bodyClassName={`${style.dialogContent} ${contentClassName &&
                contentClassName}`}
            contentClassName={style.dialogWrapper}
            overlayStyle={{ background: "rgba(68, 76, 89, 0.8)" }}
            {...props}
        />
    )
);
