import React from "react";
import style from "./style.module.scss";
import YouTube from "react-youtube";
import { mainPanelRef } from "components/MainPanel";
import ReactDOM from "react-dom";

export default ({ videoId, open, toggleModal }) => {
    const playerOpts = {
        modestbranding: 1,
        disablekb: 1,
        playerVars: { rel: 0, showinfo: 0, ecver: 2 },
    };

    if (!mainPanelRef?.current || !open) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={style.videoModal} onClick={toggleModal}>
            <div className={`Close-Large ${style.close}`} onClick={toggleModal}>
                <div>
                    <span>close</span>
                </div>
            </div>
            <div className={style.modalContent}>
                <div className={style.iframeWrapper}>
                    <YouTube
                        className={style.modalIframe}
                        videoId={videoId}
                        opts={playerOpts}
                        onReady={(event) => event.target.playVideo()}
                    />
                </div>
            </div>
        </div>,
        mainPanelRef.current,
    );
};
