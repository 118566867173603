import { createSelector } from "reselect";
import {
    makeSelectSavedGoals,
    selectAccountFacebookConversionTypes,
    makeSelectAccountId,
    makeSelectSavedKpiSummaryMetrics,
    makeSelectCampaignGroupsKpiMetrics,
    makeSelectGa4KpiMetrics,
    makeSelectBigQueryKpiMetrics,
} from "_redux/sites/selectors";
import { makeSelectCustomMetrics } from "_redux/users/selectors";
import { defaultState } from "./reducers";
import isEqual from "lodash/isEqual";
import { getPlatformMainKey, goalOpts } from "lib/utility";
import get from "lodash/get";
import { formatReportBuilderGA4Reports } from "lib/reports/analyticsV4";
import { formatShopifyProductsData } from "lib/reports/shopify";

/**
 * @param path: like the path in lodash.get() but just accept array not support a.b.c
 * @param defaultValue: default value
 * @return a selector function
 * */
const goalsSummarySelector = (path, defaultValue) => (state) => {
    return get(state, ["goalsSummary", ...path]) || defaultValue;
};

const selectGoalSummary = (state = defaultState) => state.goalsSummary || {};
export const selectViews = (state) => state.views || {};

export const selectGoalDefaults = createSelector(selectGoalSummary, ({ defaultGoals }) =>
    Object.keys(defaultGoals).map((goal) => ({ id: goal, ...defaultGoals[goal] })),
);

const selectCampaignPerformance = goalsSummarySelector(["campaignGroupsPerformance"], {});

const selectExtraCampaignGroupsInfo = goalsSummarySelector(["extraCampaignGroupsInfo"], {});

const selectExtraCampaignGroupsInfoLoading = goalsSummarySelector(["extraCampaignGroupsInfoLoading"], false);

const selectEditingCampaign = goalsSummarySelector(["editingCampaign"], {});

const selectShowEditCampaignModal = goalsSummarySelector(["showEditCampaignModal"], false);

export const getEditingCampaignInfo = ({
    editingCampaign: { campaignGroupId = "", platform = "", campaignId = 0 },
    extraCampaignGroupsInfo,
    campaignPerformance,
}) => {
    if (!campaignGroupId || !campaignId) return {};
    const extraCampaignInfo = get(extraCampaignGroupsInfo, [platform, campaignId], []);
    const campaigns = get(campaignPerformance, [campaignGroupId, "campaigns"], []);
    const { type: campaignType = "" } = campaigns.find((ele) => ele.campaignId === campaignId) || {};
    return { ...extraCampaignInfo, campaignType };
};

export const makeSelectEnhancedGoalDefaults = () =>
    createSelector(
        selectGoalSummary,
        selectAccountFacebookConversionTypes,
        makeSelectCampaignGroupsKpiMetrics(),
        makeSelectCustomMetrics(),
        makeSelectGa4KpiMetrics(),
        makeSelectBigQueryKpiMetrics(),
        (
            { defaultGoals },
            conversions = {},
            campaignGroupKpiMetrics = {},
            customMetrics = {},
            ga4Conversions = {},
            bigQueryKpiMetrics = {},
        ) => {
            let facebookConversions = Object.keys(conversions).filter((k) => conversions[k]);

            if (!facebookConversions.length) {
                facebookConversions = [
                    "offsite_conversion-fb_pixel_complete_registration",
                    "offsite_conversion-fb_pixel_purchase",
                    "onsite_conversion-purchase",
                    "lead",
                ];
            }

            return {
                ...defaultGoals,
                // GA4
                ...[
                    "analyticsV4",
                    "analyticsV4Direct",
                    "analyticsV4OrganicSearch",
                    "analyticsV4OrganicSocial",
                    "analyticsV4PaidSocial",
                    "analyticsV4Email",
                    "analyticsV4Affiliates",
                    "analyticsV4Referral",
                    "analyticsV4PaidSearch",
                    "analyticsV4Display",
                    "analyticsV4Video",
                ].reduce(
                    (cache, p) => ({
                        ...cache,
                        // analyticsV4 goals
                        ...Object.keys(ga4Conversions).reduce(
                            (cache, g) => ({
                                ...cache,
                                [`${p}-${g}`]: { active: false },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                // facebook goals
                ...facebookConversions.reduce(
                    (cache, g) => ({
                        ...cache,
                        [`facebook-${g}`]: { active: false },
                    }),
                    {},
                ),
                // custom metrics
                ...Object.keys(customMetrics).reduce(
                    (cache, metric) => ({ ...cache, [metric]: { active: false } }),
                    {},
                ),
                // campaign groups metrics
                ...campaignGroupKpiMetrics,
                // bigQuery metrics
                ...bigQueryKpiMetrics,
            };
        },
    );

export const selectAllMetrics = createSelector(
    [makeSelectSavedGoals(), makeSelectCustomMetrics(), makeSelectEnhancedGoalDefaults()],
    (metrics = {}, customMetrics = {}, enhancedMetrics = {}) => {
        const allMetrics = {
            ...enhancedMetrics,
            ...Object.keys(customMetrics).reduce((cache, key) => ({ ...cache, [key]: { active: false } }), {}),
        };

        return Object.keys(allMetrics).reduce((cache, key) => {
            const { [key]: { active } = {} } = metrics;
            return {
                ...cache,
                [key]: {
                    ...allMetrics[key],
                    active,
                },
            };
        }, {});
    },
);

const makeSelectGoalSummaryBudget = () => createSelector(selectGoalSummary, ({ budget = {} } = {}) => budget);
export const makeSelectKpiSummaryGA4Reports = () =>
    createSelector(selectGoalSummary, ({ kpiSummaryGA4Reports = {} } = {}) => kpiSummaryGA4Reports);

export const selectReportBuilderGA4Reports = createSelector(selectGoalSummary, ({ kpiSummaryGA4Reports = {} } = {}) =>
    formatReportBuilderGA4Reports(kpiSummaryGA4Reports),
);

const selectKpiSummaryGA4ReportsLoading = createSelector(
    selectGoalSummary,
    ({ kpiSummaryGA4ReportsLoading = {} } = {}) => kpiSummaryGA4ReportsLoading,
);
const makeSelectLastMonthGoalSummaryBudget = () =>
    createSelector(selectGoalSummary, ({ lastMonthBudget = {} } = {}) => lastMonthBudget);

const makeSelectGoalSummaryGoals = () => createSelector(selectGoalSummary, ({ goals } = {}) => goals);
export const makeSelectDisabledImproveBenchmark = () =>
    createSelector(selectGoalSummary, ({ disabledImproveBenchmark } = {}) => disabledImproveBenchmark);
const makeSelectGoalSummaryLastYearGoals = () =>
    createSelector(selectGoalSummary, ({ lastYearGoals } = {}) => lastYearGoals);

const makeSelectCampaignGroupsPerformance = () =>
    createSelector(selectGoalSummary, ({ campaignGroupsPerformance = {} } = {}) => campaignGroupsPerformance);
const makeSelectCampaignGroupsPerformancePending = () =>
    createSelector(
        selectGoalSummary,
        ({ campaignGroupsPerformancePending = false } = {}) => campaignGroupsPerformancePending,
    );

export const selectGoalSummaryGoalsCount = createSelector(makeSelectGoalSummaryGoals(), (goals) => {
    return Object.keys(goals).reduce(
        (cache, key) => {
            const { flag, monthlyTarget: goal, platformKey } = goals[key] || {};
            const status = goalOpts({ flag })?.result;
            const platform = getPlatformMainKey(platformKey);

            return {
                status: {
                    healthy: cache?.status?.healthy + (!!goal && status === "ok" ? 1 : 0),
                    critical: cache.status?.critical + (!!goal && (status === "issue" || status === "warning") ? 1 : 0),
                    unset: cache.status?.unset + !goal ? 1 : 0,
                },
                platform: {
                    analyticsV4: cache?.platform?.analyticsV4 + (platform === "analyticsV4" ? 1 : 0),
                    adwords: cache?.platform?.adwords + (platform === "adwords" ? 1 : 0),
                    facebook: cache?.platform?.facebook + (platform === "facebook" ? 1 : 0),
                    tiktok: cache?.platform?.tiktok + (platform === "tiktok" ? 1 : 0),
                    maropost: cache?.platform?.maropost + (platform === "maropost" ? 1 : 0),
                    klaviyo: cache?.platform?.klaviyo + (platform === "klaviyo" ? 1 : 0),
                    pinterest: cache?.platform?.pinterest + (platform === "pinterest" ? 1 : 0),
                    bing: cache?.platform?.bing + (platform === "bing" ? 1 : 0),
                    linkedin: cache?.platform?.linkedin + (platform === "linkedin" ? 1 : 0),
                    shopify: cache?.platform?.shopify + (platform === "shopify" ? 1 : 0),
                    googleBusinessProfile:
                        cache?.platform?.googleBusinessProfile + (platform === "googleBusinessProfile" ? 1 : 0),
                    custom: cache?.platform?.custom + (platform === "custom" ? 1 : 0),
                    campaignGroup: cache?.platform?.campaignGroup + (platform.startsWith("campaignGroup") ? 1 : 0),
                    organicFacebook:
                        cache?.platform?.organicFacebook + (platform.startsWith("organicFacebook") ? 1 : 0),
                    stackAdapt: cache?.platform?.stackAdapt + (platform.startsWith("stackAdapt") ? 1 : 0),
                },
            };
        },
        {
            status: {
                healthy: 0,
                critical: 0,
                unset: 0,
            },
            platform: {
                analyticsV4: 0,
                adwords: 0,
                facebook: 0,
                bing: 0,
                linkedin: 0,
                shopify: 0,
                custom: 0,
                googleBusinessProfile: 0,
                campaignGroup: 0,
                tiktok: 0,
                pinterest: 0,
                maropost: 0,
                klaviyo: 0,
                organicFacebook: 0,
                stackAdapt: 0,
            },
        },
    );
});

const makeSelectGoalSummaryBudgetLoading = () =>
    createSelector(selectGoalSummary, ({ budgetLoading } = {}) => budgetLoading);

const makeSelectGoalSummaryBudgetError = () => createSelector(selectGoalSummary, ({ budgetError } = {}) => budgetError);

export const makeSelectGoalsSelected = () => createSelector(selectGoalSummary, ({ selected }) => selected);
export const selectReportUiPending = createSelector(selectGoalSummary, ({ reportUiPending }) => reportUiPending);
export const selectGoalsPending = createSelector(selectGoalSummary, ({ goalsLoading }) => goalsLoading);
export const selectLastYearGoalsPending = createSelector(
    selectGoalSummary,
    ({ lastYearGoalsLoading }) => lastYearGoalsLoading,
);
export const selectGoalDrawerIsOpen = createSelector(selectGoalSummary, ({ goalDrawerIsOpen }) => goalDrawerIsOpen);
export const selectSaveGoalsPending = createSelector(selectGoalSummary, ({ saveGoalsPending }) => saveGoalsPending);
export const selectSaveGoalsError = createSelector(selectGoalSummary, ({ saveGoalsError }) => saveGoalsError);
export const selectGoalComparisonPending = createSelector(
    selectGoalSummary,
    ({ goalsCompareDataLoading = false }) => goalsCompareDataLoading,
);
export const selectReportUi = createSelector(
    [selectGoalSummary, makeSelectSavedKpiSummaryMetrics()],
    ({ reportUi }, metrics) => {
        const order = reportUi.order || [];
        const isNote = (key) => key.startsWith("note");
        const reOrder = order.filter((key) => isNote(key) || metrics[key]);

        return {
            ...reportUi,
            order: reOrder.length === 0 ? Object.keys(metrics) : reOrder,
        };
    },
);

export const selectPrevReportUi = createSelector(
    [selectGoalSummary, makeSelectSavedGoals()],
    ({ reportUiPrevious }, goals) => {
        return {
            ...reportUiPrevious,
            order: reportUiPrevious.order.length === 0 ? Object.keys(goals) : reportUiPrevious.order,
        };
    },
);

export const selectIsReportUiChanged = createSelector(
    [selectReportUi, selectPrevReportUi],
    (reportUi, prevReportUi) => {
        const { comparison, sort, grid, order } = reportUi;
        const { comparison: prevComparison, sort: prevSort, grid: prevGrid, order: prevOrder } = prevReportUi;

        return !isEqual(
            { comparison, sort, grid, order },
            { comparison: prevComparison, sort: prevSort, grid: prevGrid, order: prevOrder },
        );
    },
);

export const selectReportUiComparison = createSelector([selectReportUi], ({ comparison }) => {
    return comparison;
});

const makeSelectDefaultGoals = () => createSelector(selectGoalSummary, ({ defaultGoals } = {}) => defaultGoals || {});

export const selectMetricSettingsOpen = createSelector(
    selectGoalSummary,
    ({ metricSelectionOpen = false }) => metricSelectionOpen,
);

export const makeSelectDashboardNotes = () =>
    createSelector(
        selectGoalSummary,
        ({
            notes,
            notesLoading,
            notesError,
            updateCreateNoteLoading,
            updateCreateNoteError,
            selectedNoteId,
            showCreateNoteDialog,
        }) => ({
            notes,
            notesLoading,
            notesError,
            updateCreateNoteLoading,
            updateCreateNoteError,
            selectedNoteId,
            showCreateNoteDialog,
        }),
    );

export const selectKpiSummaryAdwordsTopTerms = createSelector(
    selectGoalSummary,
    ({ kpiSummaryAdwordsTopTerms = {} } = {}) => kpiSummaryAdwordsTopTerms,
);
export const selectKpiSummaryAdwordsTopTermsLoading = createSelector(
    selectGoalSummary,
    ({ kpiSummaryAdwordsTopTermsLoading } = {}) => kpiSummaryAdwordsTopTermsLoading,
);
export const selectKpiSummaryShopifyTopProducts = createSelector(
    selectGoalSummary,
    ({ kpiSummaryShopifyTopProducts = {} } = {}) => formatShopifyProductsData(kpiSummaryShopifyTopProducts),
);
export const selectKpiSummaryShopifyTopProductsLoading = createSelector(
    selectGoalSummary,
    ({ kpiSummaryShopifyTopProductsLoading } = {}) => kpiSummaryShopifyTopProductsLoading,
);

export {
    selectGoalSummary,
    makeSelectGoalSummaryBudgetError,
    makeSelectGoalSummaryBudgetLoading,
    makeSelectGoalSummaryBudget,
    makeSelectGoalSummaryGoals,
    makeSelectDefaultGoals,
    makeSelectCampaignGroupsPerformance,
    makeSelectCampaignGroupsPerformancePending,
    makeSelectGoalSummaryLastYearGoals,
    makeSelectLastMonthGoalSummaryBudget,
    selectExtraCampaignGroupsInfo,
    selectExtraCampaignGroupsInfoLoading,
    selectEditingCampaign,
    selectShowEditCampaignModal,
    selectKpiSummaryGA4ReportsLoading,
    selectCampaignPerformance,
};
