import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

const SimpleListItem = React.memo(
    ({ onClick, label, isActive = false, wrap = false, small = false, children, className, activeClass, disabled }) => {
        const classes = classNames(style.simpleListItem, {
            [style.isActive]: isActive,
            [activeClass]: isActive && activeClass,
            [style.wrap]: wrap,
            [style.small]: small,
            [style.disabled]: disabled,
            [className]: className,
        });

        return (
            <li className={classes} onClick={onClick}>
                {children || label}
            </li>
        );
    },
);

export { SimpleListItem };
