import React from "react";

export default ({ className = "", style = {} }) => (
    <svg
        className={className}
        style={style}
        fill="#6A80A7"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
    >
        <path
            d="M229.00025,22 C225.687508,22 223,24.684012 223,27.9968462 C223,31.3128481 225.687508,34 229.000222,34 C232.314771,34 235,31.3128203 235,27.9968462 C235,24.684012 232.314771,22 229.00025,22 Z M229.000188,23.5 C231.486078,23.5 233.5,25.513009 233.5,27.9976346 C233.5,30.4846152 231.486078,32.5 229.000167,32.5 C226.515631,32.5 224.5,30.484636 224.5,27.9976346 C224.5,25.513009 226.515631,23.5 229.000188,23.5 Z M228.997797,24.85 C228.756787,24.85 228.550475,24.9357401 228.378361,25.1072876 C228.206246,25.2791043 228.12,25.4857824 228.12,25.726918 C228.12,25.9685248 228.206246,26.1747991 228.378361,26.3445295 C228.55079,26.5147983 228.756724,26.6 228.997797,26.6 C229.238117,26.6 229.443548,26.5147983 229.614028,26.3445295 C229.784948,26.1748664 229.87,25.9685248 229.87,25.726918 C229.87,25.4858497 229.785074,25.2791043 229.614028,25.1072876 C229.443548,24.935942 229.238054,24.85 228.997797,24.85 Z M228.995,27.15 C229.478249,27.15 229.87,27.5417508 229.87,28.025 L229.87,30.275 C229.87,30.7582492 229.478249,31.15 228.995,31.15 C228.511751,31.15 228.12,30.7582492 228.12,30.275 L228.12,28.025 C228.12,27.5417508 228.511751,27.15 228.995,27.15 Z"
            transform="translate(-223 -22)"
        />
    </svg>
);
