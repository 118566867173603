import React from "react";

export default ({ className }) => (
    <svg
        className={className && className}
        width="16px"
        height="12px"
        viewBox="0 0 16 12"
        version="1.1"
    >
        <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline
                stroke="#ffffff"
                strokeWidth="2.5"
                points="2 7.80670363 5.33114919 10.7081653 13.9208669 2"
            ></polyline>
        </g>
    </svg>
);
