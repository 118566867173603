import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";

export const defaultState = {
    goalsData: {},
};

export default createReducer(defaultState, {
    [types.GET_GOALS_DATA]: (state, action) => {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                goalsDataPending: {
                    ...prevState.goalsDataPending,
                    ...action.meta.ruleIds.reduce((acc, ruleId) => {
                        acc[ruleId] = true;
                        return acc;
                    }, {}),
                },
            }),
            success: (prevState) => ({
                ...prevState,
                goalsData: {
                    ...prevState.goalsData,
                    ...action.meta.ruleIds.reduce((acc, ruleId) => {
                        acc[ruleId] = action.payload?.data?.alerts[0];
                        return acc;
                    }, {}),
                },
            }),
            finish: (prevState) => ({
                ...prevState,
                goalsDataPending: {
                    ...prevState.goalsDataPending,
                    ...action.meta.ruleIds.reduce((acc, ruleId) => {
                        acc[ruleId] = false;
                        return acc;
                    }, {}),
                },
            }),
        });
    },
});
