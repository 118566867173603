import { connect } from "react-redux";
import NavigationToggle from "./";

const NOT_SUPPORT_NAVIGATION_PATHS = [
    "user",
    "onboarding",
    "profile",
    "alert-settings"
];

const mapStateToProps = ({ router }) => {
    const { location: { pathname = "" } = {} } = router;
    const secondLevelPath = pathname.split("/")[2];

    return {
        showNavigation:
            !!secondLevelPath &&
            !NOT_SUPPORT_NAVIGATION_PATHS.includes(secondLevelPath),
    };
};

export default connect(mapStateToProps)(NavigationToggle);
