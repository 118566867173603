const TOGGLE_PLAN_OPTIONS = "TOGGLE_PLAN_OPTIONS";
const CLEAR_PLAN_OPTIONS = "CLEAR_PLAN_OPTIONS";
const FETCH_PROMO_DETAILS = "FETCH_PROMO_DETAILS";
const SET_PROMO_DETAILS = "SET_PROMO_DETAILS";

export default {
    TOGGLE_PLAN_OPTIONS,
    CLEAR_PLAN_OPTIONS,
    FETCH_PROMO_DETAILS,
    SET_PROMO_DETAILS,
};
