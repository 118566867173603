const OPTIMIZER_REPROJECT = "OPTIMIZER_REPROJECT";
const OPTIMIZER_FETCH = "OPTIMIZER_FETCH";
const CLEAR_FORECASTING = "CLEAR_FORECASTING";
const UPDATE_PENDING = "UPDATE_PENDING";
const UPDATE_CAMPAIGNS = "UPDATE_CAMPAIGNS";
const UPDATE_SETTINGS = "UPDATE_SETTINGS";
const OPTIMIZER_SETTINGS_FETCH = "OPTIMIZER_SETTINGS_FETCH";

export default {
    OPTIMIZER_REPROJECT,
    OPTIMIZER_FETCH,
    CLEAR_FORECASTING,
    UPDATE_PENDING,
    UPDATE_CAMPAIGNS,
    OPTIMIZER_SETTINGS_FETCH,
    UPDATE_SETTINGS,
};
