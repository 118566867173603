import React from "react";

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
    >
        <g fill="none">
            <path
                fill="#BCCFDC"
                d="M6.08065937,2.01585903 C6.2525508,1.57180617 6.48890152,1.16299559 6.83984653,0.831718062 C8.24362655,-0.514537445 10.5641609,-0.176211454 11.5167259,1.5154185 C12.2329402,2.79823789 12.9921273,4.05286344 13.7298281,5.3215859 C14.9617166,7.42907489 16.2079295,9.53656388 17.4254938,11.6511013 C18.4496803,13.4202643 17.3395481,15.6546256 15.3054995,15.9577093 C14.0592866,16.1409692 12.8918573,15.5770925 12.2472645,14.477533 C11.1657809,12.6237885 10.0771351,10.7700441 8.99565156,8.92334802 C8.97416513,8.88105727 8.94551656,8.84581498 8.91686798,8.81057269 C8.8022737,8.71894273 8.7521387,8.58502203 8.68051727,8.46519824 C8.20065369,7.63348018 7.70646582,6.80881057 7.22660225,5.98414097 C6.9186301,5.44845815 6.59633366,4.91982379 6.28836152,4.38414097 C6.00903794,3.90484581 5.88011937,3.38325991 5.89444365,2.83348018 C5.91593008,2.55154185 5.9517408,2.26960352 6.08065937,2.01585903"
            />
            <path
                fill="#D5E0EC"
                d="M6.08065937,2.01585903 C6.01620009,2.26960352 5.95890294,2.52334802 5.94457866,2.79118943 C5.92309223,3.38325991 6.07349723,3.93303965 6.37430723,4.44757709 C7.16214296,5.77973568 7.94997868,7.11894273 8.73065227,8.45814978 C8.8022737,8.57797357 8.85957084,8.69779736 8.93119227,8.81057269 C8.50146369,9.54361233 8.07173511,10.2696035 7.63484439,11.0026432 C7.03322439,12.0246696 6.43160438,13.0537445 5.82282223,14.0757709 C5.79417365,14.0757709 5.78701151,14.061674 5.77984937,14.0405286 C5.77268722,13.984141 5.79417365,13.9348018 5.80849794,13.8784141 C6.1021458,12.8211454 5.85863294,11.8837004 5.12093222,11.0801762 C4.66971721,10.5938326 4.09674577,10.3189427 3.43782862,10.2273128 C2.57837147,10.107489 1.81918431,10.3259912 1.13878073,10.8546256 C1.0170243,10.9462555 0.938240728,11.0801762 0.794997868,11.1506608 C0.766349297,11.1506608 0.752025011,11.1365639 0.744862868,11.1154185 C1.08864573,10.5303965 1.42526645,9.94537445 1.76904931,9.36035242 C3.18715362,6.93568282 4.60525792,4.51101322 6.03052437,2.09339207 C6.04484866,2.06519824 6.06633509,2.04405286 6.08065937,2.01585903"
            />
            <path
                fill="#BCCFDC"
                d="M0.77351144,11.1365639 C0.909592156,11.0167401 1.03851073,10.8898678 1.18175359,10.7770925 C2.92215433,9.42378855 5.53633651,10.4035242 5.91593008,12.5462555 C6.00903794,13.060793 5.95890294,13.554185 5.8013358,14.0475771 C5.79417365,14.0898678 5.78701151,14.1251101 5.77268722,14.1674009 C5.70822794,14.2801762 5.65093079,14.4 5.57930936,14.5127753 C4.94187864,15.5488987 4.00363791,16.0634361 2.77174932,15.9859031 C1.36080716,15.8872247 0.250675004,14.8440529 0.0572971437,13.4625551 C-0.0358107148,12.7929515 0.100270001,12.1656388 0.451215006,11.5876652 C0.522836436,11.460793 0.608782151,11.3480176 0.687565724,11.2211454 C0.723376439,11.1929515 0.709052153,11.1365639 0.77351144,11.1365639"
            />
            <path
                fill="#FABC04"
                d="M0.793614744,11.1365639 C0.757757727,11.1647577 0.757757727,11.2140969 0.695007947,11.2211454 C0.686043692,11.1718062 0.72190071,11.1436123 0.757757727,11.10837 L0.793614744,11.1365639"
            />
            <path
                fill="#BCCFDC"
                d="M5.77787604,14.1674009 C5.73787604,14.1180617 5.77787604,14.0828194 5.81787604,14.0475771 C5.82787604,14.0546256 5.84787604,14.0687225 5.85787604,14.0757709 L5.77787604,14.1674009"
            />
        </g>
    </svg>
);
