import { connect } from "react-redux";
import Navigation from "./";
import { toggleNavigation } from "../../../_redux/users";
import { createStructuredSelector } from "reselect";
import {
    makeSelectUserId,
    makeSelectNavState,
    makeSelectType,
    makeSelectRole,
    makeSelectIsTrial,
    makeSelectSubscriptionType,
    makeSelectSignUpDate,
    makeSelectUserActiveFeatures,
} from "../../../_redux/users/selectors";
import { makeSelectAccountId, makeSelectTouringState } from "../../../_redux/sites/selectors";
import { operations as ctrlPanelOperations } from "features/ControlPanel/ducks";
import { actions as planOptionActions } from "features/PlanOptions/_ducks";

const { togglePlanOptions } = planOptionActions;

const { getAccountsData } = ctrlPanelOperations;

const mapStateToProps = createStructuredSelector({
    accountId: makeSelectAccountId(),
    userId: makeSelectUserId(),
    isNavOpen: makeSelectNavState(),
    defaultOpen: makeSelectTouringState(),
    userType: makeSelectType(),
    userRole: makeSelectRole(),
    userTrial: makeSelectIsTrial(),
    subscriptionType: makeSelectSubscriptionType(),
    signUpDate: makeSelectSignUpDate(),
    userActiveFeatures: makeSelectUserActiveFeatures(),
});

const mapDispatchToProps = (dispatch) => ({
    toggleNavigation: () => dispatch(toggleNavigation()),
    getAccountsData: ({ userId, accountId }) => {
        return dispatch(getAccountsData({ userId, accountId }));
    },
    togglePlanOptions: (isOpen) => dispatch(togglePlanOptions({ isOpen })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
