//convert to decimal.js
import _ from "lodash/fp";
import moment from "moment";
import { mixpanel } from "components/Mixpanel";
import React from "react";
import { numberWithCommas } from "./metricCalculations";
import style from "components/generalStyles.module.scss";
import { toPrettyNumber } from "lib/metricCalculations";
import { TooltipDark } from "components/Tooltip";
import { store } from "_redux";
import { makeSelectCustomMetrics } from "_redux/users/selectors";
import classNames from "classnames";
import { selectViews } from "features/Dashboard/_ducks/selectors";
import { makeSelectAccountId, makeSelectCampaignGroups } from "_redux/sites/selectors";
import { selectSettingsFacebookConversionTypesOptions } from "features/Settings/_ducks/selectors";
import { bigQueryHelpers } from "lib/bigQuery";
import { searchConsoleHelpers } from "lib/searchConsole";
import { get } from "lodash";
import countryList from "react-select-country-list";
import lowerIsBetterMetrics from "./lowerIsBetterMetrics";

export const roundToSignificant = (num) => {
    if (num === 0) return 0; // Return 0 immediately if the number is 0
    const divisor = Math.pow(10, 1); // Calculate the divisor
    return Math.round(num / divisor) * divisor; // Round the number to the nearest significant figure
};

export const noop = () => {};

export const parseNumberField = (value, prev) => {
    if (!value) {
        return "0";
    }

    value = value + "";
    value = value.replace(/[$€£]/g, ""); // For copy/pasting issue

    if (!value[value.length - 1].match(/^\d*\.?\d*$/)) {
        return prev;
    }

    if (value.split(".").length > 2) {
        return prev;
    }

    if (value[value.length - 1] === ".") {
        return value;
    }

    const float = parseFloat(value.split(",").join(""));
    return numberWithCommas(float);
};

export const sum = (id) =>
    _.reduce((prev, next) => {
        return (prev += parseFloat(next[id]) || 0);
    }, 0);

export const checkNestedKeys = (array, keys, condition) => {
    return array.some((obj) => {
        if (_.isArray(keys)) {
            return keys.some((key) => {
                if (obj.hasOwnProperty(key)) {
                    return condition(obj[key]);
                }
                return false;
            });
        } else if (obj.hasOwnProperty(keys)) {
            return condition(obj[keys]);
        } else {
            return false;
        }
    });
};

export const gtZero = (number) => {
    return parseFloat(number) > 0;
};

export const grouperSum = (element) => (el, value) => {
    let obj = el;

    if (el.hasOwnProperty(element)) {
        obj[element] = parseFloat(el[element]) + parseFloat(value[element]);
    }

    return obj;
};

export const grouperAvg = (element) => (el, value, index, array) => {
    let obj = el;
    if (el.hasOwnProperty(element)) {
        obj[element] = parseFloat(el[element]) + parseFloat(value[element]);
    }
    if (index === array.length - 1) {
        obj[element] /= array.length;
    }

    return obj;
};

export const withConstructors = (constructor) => (o) => {
    const proto = {
        ...Object.getPrototypeOf(o),
        constructor,
    };

    return { ...Object.create(proto), ...o };
};

// (3n)  ~~
/**
 * Groups an array of objects by keys provided. Found keys are then handled by provided mapper
 * @param {Array<string>} groupBy array of keys keys to group by ex person.name || person
 * @param {function} mapper how to handle the found value and current value
 * @returns {function}
 * @returns {Array<object>}
 */

export const grouper = (groupBy, mapper) => (array = []) => {
    let hashTable = {};
    let partials = [];

    if (!Array.isArray(array)) {
        return [];
    }

    array.forEach((value, index) => {
        const checkAllPredicates = (el) => groupBy.every((predicate) => get(el, predicate) === get(value, predicate));

        if (checkAllPredicates(value)) {
            const hashKey = groupBy.reduce((string, predicate) => {
                return get(value, predicate) + "_" + string;
            }, "");

            if (hashTable.hasOwnProperty(hashKey)) {
                hashTable[hashKey] = mapper(hashTable[hashKey], value, index, array);
            } else {
                hashTable[hashKey] = mapper(undefined, value, index, array);
            }
        } else {
            partials.push(value);
        }
    });

    return [...partials, ...Object.keys(hashTable).map((key) => hashTable[key])];
};

export const sort = (fn) => (array) => {
    return array.sort(fn);
};

export const convertCost = (element) => ({
    ...element,
    cost: parseInt(element.cost, 10) / 1000000,
});

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getTotals = (metrics) => (result, value) => {
    if (_.isEmpty(result)) {
        return (result = value);
    }

    let obj = {};

    Object.keys(result).forEach((key) => {
        const resultKey = result[key];
        const valueKey = value[key];
        if (metrics.includes(key)) {
            obj[key] = resultKey;
        } else if (!isNumeric(valueKey) || key === "adGroupID") {
            obj[key] = _.isArray(resultKey)
                ? [...new Set([...resultKey, valueKey])]
                : [...new Set([resultKey, valueKey])];
        } else {
            const add = _.add(parseFloat(resultKey), parseFloat(valueKey));
            obj[key] = add;
        }
    });

    return obj;
};

export const groupConversionsByDay = (array) => {
    const format = _.flow(
        grouper(["day"], sum("conversions")),
        sort((a, b) => {
            return new Date(a.day) - new Date(b.day);
        }),
    );

    return _.map(format)(array);
};

export const groupRow = (metrics) => (array) => {
    return grouper(metrics, getTotals(metrics))(array);
};

export const filterDateBetween = (startDate, endDate, day = "date") =>
    _.filter((element) => moment(element[day]).isBetween(startDate, endDate, "day", "[]"));

export const mapWithReadableDate = (date) =>
    _.map((element) => ({
        ...element,
        readableDate: moment(element[date]).format("MMMM YYYY"),
    }));

export const aggregateInterger = (metric) => (element) =>
    element.reduce(
        (obj, el) => {
            return {
                [metric]: parseFloat(obj[metric]) + parseFloat(el[metric]),
            };
        },
        { [metric]: 0 },
    ) || { [metric]: 0 };

export const aggregateAndAverageInterger = (metric) => (element) => {
    const aggregate = element.reduce((int, el) => {
        return parseFloat(int) + parseFloat(el[metric]);
    }, 0);

    return { [metric]: aggregate / element.length };
};

export const createAnnualData = (startDate, endDate, key, day = "date") => {
    return (fn) => {
        const totalMonths = moment(endDate).diff(startDate, "months") + 1;

        const grouping = fn(startDate, endDate, key, day);

        return _.flow(
            _.map((element) => {
                const date = moment(startDate).add(element, "M");
                return {
                    startDate: date.date(1).format("YYYY-MM-DD"),
                    endDate: date.endOf("month").format("YYYY-MM-DD"),
                    month: date.format("MMM-YY"),
                    [key]: 0,
                };
            }),
            _.map((month) => {
                const monthlyData = grouping.find((el) => el.month === month.month);
                return {
                    ...month,
                    ...monthlyData,
                };
            }),
        )([...Array(totalMonths).keys()]);
    };
};

export function tfs(route) {
    let string = route;

    if (route.indexOf("/") === 0) {
        string = route.substr(1);
    }

    if (route[route.length - 1] === "/") {
        string = route.slice(0, -1);
    }

    return string;
}

export function trace(log) {
    return (element) => {
        console.log(log, element);
        return element;
    };
}

export const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : "");
export const toUpperCase = (text) => (text ? text.toUpperCase() : "");
export const pipeToSlash = (text) => text.split("|").join("/");
export const toCamelCase = (text) =>
    text
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase()))
        .replace(/\s+/g, "");
export const unCamelCase = (text) =>
    String(text)
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .toLowerCase();

export const getRelatedMetrics = (metric) => {
    switch (metric) {
        case "conversions":
            return ["goalCompletions", "transactions", "conversions", "platformConversions"];
        default:
            return [metric];
    }
};

export const isGaGoalCompletionName = (metric, platform) =>
    metric &&
    metric.includes("goal") &&
    metric.includes("Completions") &&
    (!platform || platform.startsWith("analytics"));

export const isFacebookConversionName = (metric, platform) =>
    metric.startsWith("facebook-") || (platform && platform.includes("facebook"));

export const isFacebookOffsiteConversion = (metric, platform) =>
    platform === "facebook" && metric.includes("offsite_conversion");

export const metricFriendlyName = (metric, platform, accountId) => {
    if (!metric) {
        return null;
    }

    // BigQuery metrics
    if (metric && bigQueryHelpers.isBigQueryMetric(metric || platform)) {
        return metric;
    }

    // custom metrics
    if ((metric && metric.startsWith("custom-")) || platform === "custom") {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const id = metric.includes("custom-") ? metric : `custom-${metric}`;
        const { [id]: { name: customName = "Undefined" } = {} } = customMetrics;
        return customName;
    }

    // GA goal completion names
    if (isGaGoalCompletionName(metric, platform)) {
        const acctId = accountId || makeSelectAccountId()(store.getState());
        const { available: { [acctId]: { googleAnalyticsAllGoals = [] } = {} } = {} } =
            selectViews(store.getState()) || {};
        const [gaolStr, analyticsId] = metric.split("_");
        const goalId = gaolStr.replace("goal", "").replace("Completions", "");
        const { name = "" } =
            googleAnalyticsAllGoals.find(({ id, analyticsViewId }) => {
                return "" + id === "" + goalId && analyticsViewId === analyticsId;
            }) || {};
        return name ? name : goalId ? `Goal ${goalId} Completions` : `Goal Completions`;
    }

    // Facebook related goals and special metrics
    if (isFacebookConversionName(metric, platform)) {
        const facebookConversions = selectSettingsFacebookConversionTypesOptions(store.getState()) || {};
        const metricKey = metric.replace("facebook-", "");
        const { name } =
            facebookConversions.find(({ dbid = "", id = "" } = {}) => [dbid, id].includes(metricKey)) || {};
        if (name) {
            return name;
        }

        if (metricKey === "clicks") return "Link Clicks";
        if (metricKey === "ctr") return "CTR (Link)";
    }

    if (platform === "searchConsole" || searchConsoleHelpers.isSearchConsoleMetric(metric)) {
        return searchConsoleHelpers.getSearchConsoleMetricName(metric);
    }

    const getMetricName = (met) => {
        switch (met) {
            case "sessionDefaultChannelGrouping":
                return "Default Channel Group";
            case "ltv":
                return "Lifetime Value";
            case "costPerClickAndView":
                return "Cost per Conversion (Click or View)";
            case "impressionShare":
                return "Search Impression Share";
            case "displayImpressionShare":
                return "Display Impression Share";
            case "searchLostIsRank":
                return "Search Lost IS (Rank)";
            case "searchLostIsBudget":
                return "Search Lost IS (Budget)";
            case "displayLostIsBudget":
                return "Display Lost IS (Budget)";
            case "displayLostIsRank":
                return "Display Lost IS (Rank)";
            case "views":
                return "Views";
            case "viewRate":
                return "View Rate";
            case "cpv":
                return "CPV";
            case "conversionsByConversionDate":
                return "Conversions (by conv. time)";
            case "conversionsValueByConversionDate":
                return "Conversion Value (by conv. time)";
            case "conversionRateByConversionDate":
                return "Conversion Rate (by conv. time)";
            case "fb_mobile_achievement_unlocked":
                return "Mobile App Feature Unlocks";
            case "fb_mobile_activate_app":
                return "Mobile App Starts ";
            case "fb_mobile_add_payment_info":
                return "Mobile App Payment Details";
            case "fb_mobile_add_to_cart":
                return "Mobile App Adds To Cart";
            case "fb_mobile_add_to_wishlist":
                return "Mobile App Adds to Wishlist";
            case "fb_mobile_complete_registration":
                return "Mobile App Registrations";
            case "fb_mobile_content_view":
                return "Mobile App Content Views";
            case "fb_mobile_initiated_checkout":
                return "Mobile App Checkouts";
            case "fb_mobile_level_achieved":
                return "Mobile App Achievements";
            case "fb_mobile_purchase":
                return "Mobile App Purchases";
            case "fb_mobile_rate":
                return "Mobile App Ratings";
            case "fb_mobile_search":
                return "Mobile App Searchs";
            case "fb_mobile_spent_credits":
                return "Mobile App Credit Spends";
            case "fb_mobile_tutorial_completion":
                return "Mobile App Tutorial Completions";
            case "other":
                return "Other Mobile App Actions";
            case "games.plays":
                return "Game Plays";
            case "offsite_conversion-fb_pixel_add_payment_info":
                return "Adds Payment Info";
            case "offsite_conversion-fb_pixel_add_to_cart":
                return "Website Adds To Cart";
            case "offsite_conversion-fb_pixel_add_to_wishlist":
                return "Adds To Wishlist";
            case "offsite_conversion-fb_pixel_complete_registration":
                return "Completed Registration";
            case "offsite_conversion-fb_pixel_initiate_checkout":
                return "Initiates Checkout";
            case "offsite_conversion-fb_pixel_lead":
                return "Leads";
            case "offsite_conversion-fb_pixel_purchase":
                return "Website Purchases";
            case "purchases":
                return "Purchases";
            case "offsite_conversion-fb_pixel_search":
                return "Searchs";
            case "offsite_conversion-fb_pixel_custom":
                return "Custom pixel events defined by the advertiser";
            case "offsite_conversion-fb_pixel_view_content":
            case "contentViews":
                return "Views Content";
            case "subscriptions":
                return "Subscriptions";
            case "subscriptionValue":
                return "Subscribe Conversion Value";
            case "costPerSubscription":
                return "Cost Per Subscription";
            case "fb_pixel_lead":
                return "Offsite Leads";
            case "costPer_fb_pixel_lead":
                return "Cost Per Offsite Lead";
            case "contacts":
                return "Contacts";
            case "onsite_conversion-flow_complete":
                return "On-Meta Workflow Completions";
            case "onsite_conversion-messaging_block":
                return "Blocked Messaging Conversations";
            case "onsite_conversion-messaging_conversation_started_7d":
                return "Messaging Conversations Started";
            case "onsite_conversion-messaging_first_reply":
                return "New Messaging Conversations";
            case "onsite_conversion-post_save":
                return "Post Saves";
            case "onsite_conversion-purchase":
                return "Meta Purchases";
            case "onsite_conversion-purchase_value":
                return "Meta Purchases Conversion Value";
            case "onsite_conversion-lead_grouped":
                return "All On-Meta Leads";
            case "leadGrouped":
                return "Meta Leads";
            case "app_install":
                return "App Installs";
            case "app_use":
                return "App Uses";
            case "checkin":
                return "Check-ins";
            case "comment":
                return "Post Comments";
            case "credit_spent":
                return "Credit Spends";
            case "landing_page_view":
                return "Landing Page Views";
            case "like":
                return "Page Likes";
            case "link_click":
                return "Link Clicks";
            case "mobile_app_install":
                return "Mobile App Installs";
            case "outbound_click":
                return "Outbound Clicks";
            case "phone_call_leads":
                return "Phone Call Leads";
            case "photo_view":
                return "Page Photo Views";
            case "post":
                return "Post Shares";
            case "post_reaction":
                return "Post Reactions";
            case "position":
                return "Avg. Position";
            case "rsvp":
                return "Event Responses";
            case "video_view":
                return "3-Second Video Views";
            case "contact_total":
                return "Contacts";
            case "contact_website":
                return "Website Contacts";
            case "contact_mobile_app":
                return "Mobile App Contacts";
            case "contact_offline":
                return "Offline Contacts";
            case "customize_product_total":
                return "Products Customized";
            case "customize_product_website":
                return "Website Products Customized";
            case "customize_product_mobile_app":
                return "Mobile App Products Customized";
            case "customize_product_offline":
                return "Offline Products Customized";
            case "donate_total":
                return "Donations";
            case "donate_website":
                return "Website Donations";
            case "donate_on_facebook":
                return "On Meta Donations";
            case "donate_mobile_app":
                return "Mobile App Donations";
            case "donate_offline":
                return "Offline Donations";
            case "find_location_total":
                return "Location Searches";
            case "find_location_website":
                return "Website Location Searches";
            case "find_location_mobile_app":
                return "Mobile App Location Searches";
            case "find_location_offline":
                return "Offline App Location Searches";
            case "schedule_total":
                return "Appointments Scheduled";
            case "schedule_website":
                return "Website Appointments Scheduled";
            case "schedule_mobile_app":
                return "Mobile App Appointments Scheduled";
            case "schedule_offline":
                return "Offline App Appointments Scheduled";
            case "start_trial_total":
                return "Trials Started";
            case "start_trial_website":
                return "Website Trials Started";
            case "start_trial_mobile_app":
                return "Mobile App Trials Started";
            case "start_trial_offline":
                return "Offline Trials Started";
            case "submit_application_total":
                return "Applications Submitted";
            case "submit_application_website":
                return "Website Applications Submitted";
            case "submit_application_mobile_app":
                return "Mobile App Applications Submitted";
            case "submit_application_offline":
                return "Offline Applications Submitted";
            case "submit_application_on_facebook":
                return "On Meta Applications Submitted";
            case "subscribe_total":
                return "Subscriptions";
            case "subscribe_website":
                return "Website Subscriptions";
            case "subscribe_mobile_app":
                return "Mobile App Subscriptions";
            case "subscribe_offline":
                return "Offline Subscriptions";
            case "recurring_subscription_payment_total":
                return "Recurring Subscription Payments";
            case "recurring_subscription_payment_website":
                return "Website Recurring Subscription Payments";
            case "recurring_subscription_payment_mobile_app":
                return "Mobile App Recurring Subscription Payments";
            case "recurring_subscription_payment_offline":
                return "Offline Recurring Subscription Payments";
            case "cancel_subscription_total":
                return "Canceled Subscriptions";
            case "cancel_subscription_website":
                return "Website Canceled Subscriptions";
            case "cancel_subscription_mobile_app":
                return "Mobile App Canceled Subscriptions";
            case "cancel_subscription_offline":
                return "Offline Canceled Subscriptions";
            case "ad_click_mobile_app":
                return "In-App Ad Clicks";
            case "ad_impression_mobile_app":
                return "In-App Ad Impressions";
            case "click_to_call_call_confirm":
                return "Call Confirmation Clicks";
            case "page_engagement":
                return "Page Engagement";
            case "post_engagement":
                return "Post Engagement";
            case "engagement":
                return "Post Engagement";
            case "lead":
                return "All offsite leads plus all On-Meta leads";
            case "leadgen_grouped":
                return "On-Meta leads coming from Messenger and Instant Forms";
            case "omni_app_install":
                return "App Installs";
            case "purchase":
                return "Purchases";
            case "omni_purchase":
                return "Omni Purchases";
            case "purchase_value":
                return "Purchase Value";
            case "omni_add_to_cart":
                return "Adds to Cart";
            case "omni_complete_registration":
                return "Registrations Completed";
            case "omni_view_content":
                return "Content Views";
            case "omni_search":
                return "Searches";
            case "omni_initiated_checkout":
                return "Checkouts Initiated";
            case "omni_achievement_unlocked":
                return "Achievements Unlocked";
            case "omni_activate_app":
                return "App Activations";
            case "omni_level_achieved":
                return "Levels Achieved";
            case "omni_rate":
                return "Ratings Submitted";
            case "omni_spend_credits":
                return "Credit Spends";
            case "omni_tutorial_completion":
                return "Tutorials Completed";
            case "omni_custom":
                return "Custom Events";
            case "fb_pixel_purchase":
                return "Website Purchases";
            case "fb_pixel_add_to_cart":
                return "Website Adds To Cart";
            case "fb_pixel_initiate_checkout":
                return "Initiate Checkout";
            case "fb_pixel_purchase_value":
                return "Website Purchases Conversion Value";
            case "purchaseValue":
                return "Purchase Conversion Value";
            case "landingPageViews":
                return "Landing Page Views";
            case "fb_pixel_add_to_cart_value":
                return "Website Adds To Cart Value";
            case "fb_pixel_initiate_checkout_value":
                return "Initiate Checkout Value";
            case "fb_pixel_purchase_percent":
                return "% Website Purchase";
            case "fb_pixel_add_to_cart_percent":
                return "% Webiste Adds To Cart";
            case "conversionValue":
                return `Conversion Value`;
            case "viewThroughConv":
                return "View-Through Conversions";
            case "avgOrderValue":
                return "Average Order Value";
            case "orders":
                return "Orders";
            case "items":
                return "Items";
            case "newCustomerOrders":
                return "New Customer Orders";
            case "newCustomerGMV":
                return "New Customer GMV";
            case "newCustomerAOV":
                return "New Customer AOV";
            case "returningCustomerOrders":
                return "Returning Customer Orders";
            case "returningCustomerGMV":
                return "Returning Customer GMV";
            case "returningCustomerAOV":
                return "Returning Customer AOV";
            case "gmv":
                return "Gross Merchandise Value (GMV)";
            case "aov":
                return "Average Order Value (AOV)";
            case "averageSalePrice":
                return "Average Sale Price";
            case "itemsPerTransaction":
                return "Items Per Transaction";
            case "grossSales":
                return "Gross Sales";
            case "discounts":
                return "Discounts";
            case "shipping":
                return "Shipping";
            case "taxes":
                return "Tax";
            case "returns":
                return "Returns";
            case "netSales":
                return "Net Sales";
            case "totalSales":
                return "Total Sales";
            case "avgTimeOnSite":
                return `Average Time On Site`;
            case "avgTransactionValue":
                return `Average Order Value`;
            case "revenue":
                return `Revenue`;
            case "clicks":
                return `Ad Clicks`;
            case "clicksAll":
                return `Clicks (All)`;
            case "cpcAll":
                return `CPC (All)`;
            case "ctrAll":
                return `CTR (All)`;
            case "cpl":
                return `CPA`;
            case "cplPaid":
                return `CPA - Paid`;
            case "oneClickLeads":
                return "Leads";
            case "cpm":
                return `CPM`;
            case "ctr":
                return `CTR`;
            case "cpc":
                return `CPC`;
            case "cpa":
                return `CPA`;
            case "newUsers":
                return `New Users`;
            case "bounceRate":
                return `Bounce Rate`;
            case "roi":
                return `ROI`;
            case "roas":
                return `ROAS`;
            case "roasByTime":
                return `ROAS (By Time)`;
            case "websiteRoas":
                return `Website ROAS`;
            case "metaRoas":
                return `Meta ROAS`;
            case "impressions":
                return `Impressions`;
            case "conversions":
                return `Conversions`;
            case "cvr":
            case "conversionRate":
                return `Conversion Rate`;
            case "cost":
                return `Media Spend`;
            case "costFacebook":
                return `Meta Ads Spend`;
            case "costAdwords":
                return `Google Ads Spend`;
            case "costBing":
                return `Microsoft Ads Spend`;
            case "costLinkedin":
                return `LinkedIn Ads Spend`;
            case "avgPosition":
                return `Average Position`;
            case "goalConversionRateAll":
                return `Goal Conversion Rate`;
            case "platformCpl":
                return `CPA`;
            case "platformConversions":
                return `Platform Conversions`;
            case "seo":
                return `SEO`;
            case "searchRank":
                return `Search Ranking`;
            case "competitorSearchRank":
                return `Competitor Search Ranking`;
            case "itemRevenue":
                return "Sales Revenue";
            case "itemQuantity":
                return "Items Sold";
            case "adwordsBudget":
                return "Google Ads";
            case "facebookBudget":
                return "Meta Ads";
            case "bingBudget":
                return "Microsoft Advertising";
            case "linkedinBudget":
                return "LinkedIn Ads";
            case "goalCompletions":
                return "Goal Completions";
            case "goalCompletionRate":
                return "Goal Completion Rate";
            case "transactionRate":
                return "Transaction Rate";
            case "adwords-cost":
                return "Google Ads - Media Spend";
            case "adwords-impressions":
                return "Google Ads - Impressions";
            case "adwords-clicks":
                return "Google Ads - Ad Clicks";
            case "adwords-ctr":
                return "Google Ads - CTR";
            case "adwords-conversions":
                return "Google Ads - Conversions";
            case "adwords-cpl":
                return "Google Ads - CPA";
            case "adwords-cpc":
                return "Google Ads - CPC";
            case "adwords-conversionRate":
                return "Google Ads - Conversion Rate";
            case "adwords-cpm":
                return "Google Ads - CPM";
            case "adwords-conversionValue":
                return "Google Ads - Conversion Value";
            case "adwords-viewThroughConv":
                return "Google Ads - View-Through Conversions";
            case "adwords-impressionShare":
                return "Google Ads - Search Impression Share";
            case "ADD_TO_CART":
                return "Add to cart";
            case "BEGIN_CHECKOUT":
                return "Begin checkout";
            case "BOOK_APPOINTMENT":
                return "Book appointment";
            case "CONTACT":
                return "Contact";
            case "DOWNLOAD":
                return "Download";
            case "ENGAGEMENT":
                return "Engagement";
            case "GET_DIRECTIONS":
                return "Get directions";
            case "IMPORTED_LEAD":
                return "Imported Lead";
            case "LEAD":
                return "Search Lead";
            case "OUTBOUND_CLICK":
                return "Outbound click";
            case "PAGE_VIEW":
                return "Page view";
            case "PHONE_CALL_LEAD":
                return "Phone call leads";
            case "PURCHASE":
                return "Purchase";
            case "REQUEST_QUOTE":
                return "Request quote";
            case "SIGNUP":
                return "Sign-up";
            case "STORE_SALE":
                return "Store Sale";
            case "STORE_VISIT":
                return "Store Visit";
            case "SUBMIT_LEAD_FORM":
                return "Submit lead form";
            case "SUBSCRIBE_PAID":
                return "Subscribe";
            case "interactions":
                return "Interactions";
            case "interactionRate":
                return "Interaction Rate";
            case "engagements":
                return "Engagements";
            case "engagementRate":
                return "Engagement Rate";
            case "videoViewRate":
                return "Video View Rate";
            case "engagedSessions":
                return "Engaged Sessions";
            case "screenPageViewsPerSession":
                return "Views per Session";
            case "avgEngagementTime":
                return "Average Engagement Time";
            case "avgEngagementTimePerSession":
                return "Average Engagement Time Per Session";
            case "addToCarts":
                return "Add to carts";
            case "searchTerm":
                return "Search Term";
            case "adGroup":
                return "Ad Group";
            case "phoneCalls":
                return "Phone Calls";
            case "phoneImpressions":
                return "Phone Impressions";
            case "phoneThroughRate":
                return "Phone Through Rate";
            case "campaignAssetType":
            case "assetGroupAssetType":
                return "Asset Type";
            case "campaignAssetSource":
            case "assetGroupAssetSource":
                return "Source";
            case "assetGroupStatus":
            case "assetGroupAssetStatus":
                return "Status";
            case "assetGroupStrength":
                return "Ad Strength";
            case "assetGroupAssetPerformance":
                return "Performance";

            // facebook
            case "facebook-cost":
                return "Meta Ads - Media Spend";
            case "facebook-impressions":
                return "Meta Ads - Impressions";
            case "facebook-clicks":
                return "Meta Ads - Link Clicks";
            case "facebook-clicksAll":
                return "Meta Ads - Clicks (All)";
            case "facebook-ctr":
                return "Meta Ads - CTR (Link)";
            case "facebook-conversions":
                return "Meta Ads - Conversions";
            case "facebook-cpl":
                return "Meta Ads - CPA";
            case "facebook-cpc":
                return "Meta Ads - CPC";
            case "facebook-conversionRate":
                return "Meta Ads - Conversion Rate";
            case "facebook-purchases":
                return "Meta Ads - Purchases";
            case "facebook-fb_pixel_purchase":
                return "Meta Ads - Website Purchases";
            case "facebook-fb_pixel_add_to_cart":
                return "Meta Ads - Website Adds To Cart";
            case "facebook-fb_pixel_initiate_checkout":
                return "Meta Ads - Initiate Checkoout";
            case "facebook-fb_pixel_purchase_value":
                return "Meta Ads - Website Purchases Conversion Value";
            case "facebook-purchaseValue":
                return "Meta Ads - Purchases Conversion Value";
            case "facebook-landingPageViews":
                return "Meta Ads - Landing Page Views";
            case "facebook-fb_pixel_add_to_cart_value":
                return "Meta Ads - Website Adds To Cart Value";
            case "facebook-fb_pixel_initiate_checkout_value":
                return "Meta Ads - Initiate Checkoout Value";
            case "facebook-cpm":
                return "Meta Ads - CPM";
            case "facebook-outboundClicks":
                return "Meta Ads - Outbound Clicks";
            case "facebook-outboundCtr":
                return "Meta Ads - Outbound Clicks CTR";
            case "facebook-aov":
                return "Meta Ads - Average Order Value";
            case "facebook-cpp":
                return "Meta Ads - Cost Per Purchase";
            case "facebook-websiteCpp":
                return "Meta Ads - Website Cost Per Purchase";
            case "facebook-metaCpp":
                return "Meta Ads - Meta Cost Per Purchase";
            case "facebook-roas":
                return "Meta Ads - ROAS";
            case "facebook-websiteRoas":
                return "Meta Ads - Website ROAS";
            case "facebook-metaRoas":
                return "Meta Ads - Meta ROAS";
            case "facebook-contentViews":
                return "Meta Ads - Views Content";
            case "contacts":
                return "Contacts";
            case "omniAddToCart":
                return "Adds To Cart (All)";
            case "fb_pixel_complete_registration":
                return "Completed Registration";
            case "fb_pixel_complete_registration_value":
                return "Completed Registration Value";
            case "b_pixel_lead":
                return "Leads";
            case "fb_pixel_lead_value":
                return "Leads Value";
            case "outboundClicks":
                return "Outbound Clicks";
            case "outboundCtr":
                return "Outbound Clicks CTR";
            case "cpp":
                return "Cost Per Purchase";
            case "websiteCpp":
                return "Website Cost Per Purchase";
            case "metaCpp":
                return "Meta Cost Per Purchase";
            case "campaignObjective":
                return "Campaign Objective";
            case "videoPlays":
                return "Video Plays";
            case "videoPlayTime":
                return "Video Play Time";
            case "thruplays":
                return "Thruplays";
            case "cpLeadGrouped":
                return "CPL (Cost Per Lead)";

            case "totalOrganicImpressions":
                return "Organic Impressions";
            case "organicEngagements":
                return "Organic Engagements";
            case "organicLinkClicks":
                return "Organic Link Clicks";
            case "organicEngagementRate":
                return "Organic Engagement Rate (per impression)";
            case "pageFollows":
                return "Followers";
            case "netFollowerGrowth":
                return "Net Follower Growth";
            case "pageFans":
                return "Page Fans";
            case "pageFanRemoves":
                return "Page Unlikes";
            case "paidImpressions":
                return "Paid Impressions";
            case "pageImpressions":
                return "Page Impressions";
            case "totalPublishedPosts":
                return "Total Published Posts";
            case "organicFacebook-videoFullViews":
                return "Video Full Views";
            case "organicFacebook-impressions":
                return "Impressions";
            case "organicFacebook-avgDailyImpressions":
                return "Average Daily Impressions";
            case "organicFacebook-reach":
                return "Reach";
            case "organicFacebook-avgDailyReach":
                return "Average Daily Reach";
            case "organicFacebook-fanAgeRanges":
                return "Page Follower by Age";
            case "organicFacebook-pageImpressionsByAgeUnique":
                return "People Reached by Age";
            case "organicFacebook-fanGenders":
                return "Page Follower by Gender";
            case "organicFacebook-pageImpressionsByGenderUnique":
                return "People Reach by Gender";
            case "organicFacebook-pageFansCountry":
                return "Page Follower by Country";
            case "organicFacebook-pageImpressionsByCountryUnique":
                return "People Reach by Country";
            case "organicFacebook-pageImpressionsByCityUnique":
                return "People Reach by City";
            case "organicFacebook-pageEngagements":
                return "Page Engagements";
            case "organicFacebook-totalEngagements":
                return "Total Engagements";
            case "organicFacebook-reactions":
                return "Reactions";
            case "organicFacebook-comments":
                return "Comments";
            case "organicFacebook-shares":
                return "Shares";
            case "organicFacebook-linkClicks":
                return "Post Link Clicks";
            case "organicFacebook-otherClicks":
                return "Other Post Clicks";

            case "linkedin-cost":
                return "LinkedIn Ads - Media Spend";
            case "linkedin-impressions":
                return "LinkedIn Ads - Impressions";
            case "linkedin-clicks":
                return "LinkedIn Ads - Ad Clicks";
            case "linkedin-ctr":
                return "LinkedIn Ads - CTR";
            case "linkedin-conversions":
                return "LinkedIn Ads - Conversions";
            case "linkedin-cpl":
                return "LinkedIn Ads - CPA";
            case "linkedin-cpc":
                return "LinkedIn Ads - CPC";
            case "linkedin-conversionRate":
                return "LinkedIn Ads - Conversion Rate";
            case "linkedin-cpm":
                return "LinkedIn Ads - CPM";
            case "linkedin-oneClickLeads":
                return "LinkedIn Ads - One-Click Leads";

            case "bing-cost":
                return "Microsoft Ads - Media Spend";
            case "bing-impressions":
                return "Microsoft Ads - Impressions";
            case "bing-clicks":
                return "Microsoft Ads - Ad Clicks";
            case "bing-ctr":
                return "Microsoft Ads - CTR";
            case "bing-conversions":
                return "Microsoft Ads - Conversions";
            case "bing-cpl":
                return "Microsoft Ads - CPA";
            case "bing-cpc":
                return "Microsoft Ads - CPC";
            case "bing-conversionRate":
                return "Microsoft Ads - Conversion Rate";
            case "bing-cpm":
                return "Microsoft Ads - CPM";
            case "bing-viewThroughConversions":
                return "Microsoft Ads - View-Through Conversions";
            case "bing-revenue":
                return "Microsoft Ads - Revenue";

            case "shopify-revenue":
                return "Shopify - Sales";
            case "shopify-transactions":
                return "Shopify - Transactions";
            case "shopify-avgOrderValue":
                return "Shopify - Average Order Value";
            case "shopify-orders":
                return "Shopify - Orders";
            case "shopify-items":
                return "Shopify - Items";
            case "shopify-newCustomerOrders":
                return "Shopify - New Customer Orders";
            case "shopify-newCustomerGMV":
                return "Shopify - New Customer GMV";
            case "shopify-newCustomerAOV":
                return "Shopify - New Customer AOV";
            case "shopify-returningCustomerOrders":
                return "Shopify - Returning Customer Orders";
            case "shopify-returningCustomerGMV":
                return "Shopify - Returning Customer GMV";
            case "shopify-returningCustomerAOV":
                return "Shopify - Returning Customer AOV";
            case "shopify-gmv":
                return "Shopify - Gross Merchandise Value (GMV)";
            case "shopify-aov":
                return "Shopify - Average Order Value (AOV)";
            case "shopify-averageSalePrice":
                return "Shopify - Average Sale Price";
            case "shopify-itemsPerTransaction":
                return "Shopify - Items Per Transaction";
            case "shopify-grossSales":
                return "Shopify - Gross Sales";
            case "shopify-discounts":
                return "Shopify - Discounts";
            case "shopify-shipping":
                return "Shopify - Shipping";
            case "shopify-taxes":
                return "Shopify - Tax";
            case "shopify-returns":
                return "Shopify - Returns";
            case "shopify-netSales":
                return "Shopify - Net Sales";
            case "shopify-totalSales":
                return "Shopify - Total Sales";

            // google business profile metrics
            case "callClicks":
                return `Call Clicks`;
            case "websiteClicks":
                return `Website Clicks`;
            case "businessBookings":
                return `Business Bookings`;
            case "businessFoodOrders":
                return `Business Food Orders`;
            case "averageRating":
                return `Average Rating (Date Range)`;
            case "totalReviewCount":
                return "Total Reviews (Date Range)";
            case "averageRatingLifeTime":
                return `Average Rating (All-time)`;
            case "totalReviewCountLifeTime":
                return "Total Reviews (All-time)";
            case "viewsByDesktop":
                return "Views By Desktop";
            case "viewsByMobile":
                return "Views By Mobile";

            // pinterest metrics
            case "totalEngagements":
                return "Engagements";
            case "earnedEngagements":
                return "Earned Engagements";
            case "paidEngagements":
                return "Paid Engagements";
            case "pageVisit":
                return "Page Visit";
            case "checkout":
                return "Checkout";
            case "costPerCheckout":
                return "Cost per Checkout";
            case "totalOrderValue":
                return "Total order Value (Checkout)";
            case "totalROAS":
                return "Total ROAS (Checkout)";

            // tiktok metrics
            case "reach":
                return "Reach";
            case "frequency":
                return "Frequency";
            case "videoViews":
                return "Video Views";
            case "averageWatchTime":
                return "Average Watch Time";
            case "videoViewsAt50":
                return "Video Views at 50%";
            case "watched2sVideo":
                return "2 Second Video Views";
            case "totalPageViewPage":
                return "Page views (Page event)";
            case "costPerPageViewPage":
                return "Cost per Page View (Page event)";
            case "productViewsPage":
                return "Product Page Views (Page event)";
            case "costPerProductViewPage":
                return "Cost Per Product Page View (Page event)";
            case "addToCartPage":
                return "Add to Cart (Page event)";
            case "costPerAddToCartPage":
                return "Cost Per Add to Cart (Page event)";
            case "completePaymentPage":
                return "Purchases (Page event)";
            case "costPerCompletePaymentPage":
                return "Cost Per Purchase (Page event)";
            case "checkoutsPage":
                return "Checkouts (Page event)";
            case "costPerCheckoutPage":
                return "Cost Per Checkout (Page event)";
            case "completePaymentValuePage":
                return "Revenue (Page event)";
            case "roasPage":
                return "ROAS (Page event)";
            case "subscribePage":
                return "Subscribe (Page event)";
            case "salesLead":
                return "Leads";
            case "subscribes":
                return "Subscribes";
            case "pageViews":
                return "Page Views";
            case "costPerPageView":
                return "Cost per Page View";
            case "addToCart":
                return "Add to Cart";
            case "costPerAddToCart":
                return "Cost Per Add to Cart";
            case "completePayments":
                return "Purchases";
            case "costPerCompletePayment":
                return "Cost Per Purchase";
            case "checkouts":
                return "Checkouts";
            case "productViews":
                return "Product Views";
            case "costPerProductView":
                return "Cost Per Product View";

            // StackAdapt metrics
            case "cpe":
                return "CPE";
            case "videoCompletionRate":
                return "Video Completion Rate";
            case "uniqueImpressions":
                return "Unique Impressions";
            case "secondaryConversions":
                return "Secondary Conversions";
            case "conversionRevenue":
                return "Conversion Revenue";
            case "videoStarts":
                return "Video Starts";
            case "videoCompletions":
                return "Video Completions";
            case "revenueFee":
                return "Ad Spend";

            // maropost metrics
            case "ctor":
                return "CTOR";
            case "opened":
                return "Opens";
            case "clicked":
                return "Clicks";
            case "openRate":
                return "Open Rate";
            case "sent":
                return "Delivered";
            case "sendAt":
                return "Date";
            case "bounced":
                return "Bounce";
            case "unsubscribed":
                return "Unsubscribe";
            case "uniqueClicks":
                return "Unique Clicks";
            case "uniqueOpens":
                return "Unique Opens";

            // klaviyo metrics
            case "shopifyOrders":
                return "Shopify Orders";
            case "shopifyRevenue":
                return "Shopify Revenue";
            case "shopifyAov":
                return "Shopify Average Order Value";
            case "woocommerceOrders":
                return "Woocommerce Orders";
            case "woocommerceRevenue":
                return "Woocommerce Revenue";
            case "woocommerceAov":
                return "Woocommerce Average Order Value";
            case "magentoOrders":
                return "Magento Orders";
            case "magentoRevenue":
                return "Magento Revenue";
            case "magentoAov":
                return "Magento Average Order Value";
            case "totalOrders":
                return "Total Orders";
            case "totalRevenue":
                return "Total Revenue";
            case "totalAov":
                return "Average Order Value";
            case "flowOpened":
                return "Flows-Opens";
            case "flowUniqueOpens":
                return "Flows-Unique Opens";
            case "flowClicked":
                return "Flows-Clicks";
            case "flowUniqueClicks":
                return "Flows-Unique Clicks";
            case "flowDelivered":
                return "Flows-Delivered";
            case "flowCtr":
                return "Flows-CTR";
            case "flowUnsubscribed":
                return "Flows-Unsubscribe";
            case "flowBounced":
                return "Flows-Bounce";
            case "flowOpenRate":
                return "Flows-Open Rate";
            case "flowCtor":
                return "Flows-CTOR";
            case "flowShopifyOrders":
                return "Flows-Shopify Orders";
            case "flowShopifyRevenue":
                return "Flows-Shopify Revenue";
            case "flowShopifyAov":
                return "Flows-Shopify Average Order Value";
            case "flowWoocommerceOrders":
                return "Flows-Woocommerce Orders";
            case "flowWoocommerceRevenue":
                return "Flows-Woocommerce Revenue";
            case "flowWoocommerceAov":
                return "Flows-Woocommerce Average Order Value";
            case "flowMagentoOrders":
                return "Flows-Magento Orders";
            case "flowMagentoRevenue":
                return "Flows-Magento Revenue";
            case "flowMagentoAov":
                return "Flows-Magento Average Order Value";
            case "flowTotalOrders":
                return "Flows-Total Orders";
            case "flowTotalRevenue":
                return "Flows-Total Revenue";
            case "flowTotalAov":
                return "Flows-Average Order Value";
            case "listSubscribed":
                return "Lists-Subscribes";
            case "listUnsubscribed":
                return "Lists-Unsubscribes";

            // Ad metrics
            case "friendlyAdType":
                return "Ad Type";
            case "adId":
                return "Ad ID";
            case "adName":
                return "Ad Name";

            // GA4 Event metrics
            case "analyticsV4Event-eventName":
                return "Event Name";
            case "analyticsV4Event-eventCount":
                return "Event Count";
            case "analyticsV4Event-totalUsers":
                return "Total Users";
            case "analyticsV4Event-eventsPerUser":
                return "Event Count Per User";
            case "analyticsV4Event-purchaseRevenue":
                return "Total Revenue";

            // GA4 Landing Page metrics
            case "analyticsV4LandingPage-landingPage":
                return "Landing Page";
            case "analyticsV4LandingPage-sessions":
                return "Sessions";
            case "analyticsV4LandingPage-activeUsers":
                return "Active Users";
            case "analyticsV4LandingPage-bounceRate":
                return "Bounce Rate";
            case "analyticsV4LandingPage-conversions":
                return "Conversions";
            case "analyticsV4LandingPage-sessionKeyEventRate":
                return "Conversion Rate";
            case "analyticsV4LandingPage-totalRevenue":
                return "Revenue";

            // GA4 Devices metrics
            case "analyticsV4Device-deviceCategory":
                return "Device Name";
            case "analyticsV4Device-sessions":
                return "Sessions";
            case "analyticsV4Device-activeUsers":
                return "Active Users";
            case "analyticsV4Device-bounceRate":
                return "Bounce Rate";
            case "analyticsV4Device-conversions":
                return "Conversions";
            case "analyticsV4Device-sessionKeyEventRate":
                return "Conversion Rate";
            case "analyticsV4Device-totalRevenue":
                return "Revenue";

            // GA4 User Age Range metrics
            case "analyticsV4UserAgeRange-userAgeBracket":
                return "User Age Range";
            case "analyticsV4UserAgeRange-sessions":
                return "Sessions";
            case "analyticsV4UserAgeRange-activeUsers":
                return "Active Users";
            case "analyticsV4UserAgeRange-bounceRate":
                return "Bounce Rate";
            case "analyticsV4UserAgeRange-conversions":
                return "Conversions";
            case "analyticsV4UserAgeRange-sessionKeyEventRate":
                return "Conversion Rate";
            case "analyticsV4UserAgeRange-totalRevenue":
                return "Revenue";

            // GA4 E-commerce metrics
            case "analyticsV4Ecommerce-dimension":
                return "Dimension";
            case "analyticsV4Ecommerce-grossItemRevenue":
                return "Gross Item Revenue";
            case "analyticsV4Ecommerce-itemDiscountAmount":
                return "Item Discount Amount";
            case "analyticsV4Ecommerce-itemRefundAmount":
                return "Item Refund Amount";
            case "analyticsV4Ecommerce-itemRevenue":
                return "Item Revenue";
            case "analyticsV4Ecommerce-itemsAddedToCart":
                return "Items Added To Cart";
            case "analyticsV4Ecommerce-itemsCheckedOut":
                return "Items Checked Out";
            case "analyticsV4Ecommerce-itemsPurchased":
                return "Items Purchased";
            case "analyticsV4Ecommerce-itemsViewed":
                return "Items Viewed";

            default:
                return unCamelCase(metric);
        }
    };

    // channel level GA metrics
    if (platform && platform.includes("analyticsV4")) {
        let metricStr = getMetricName(metric);
        if (metric.includes("sessionKeyEventRate:")) {
            metricStr = metric.split(":")[1];
        }
        return metric.includes("-") ? `${channelFriendlyName(platform)} - ${metricStr}` : metricStr;
    }

    // channel level Adwords metrics
    if (platform && platform.includes("adwords") && platform !== "adwords") {
        const metricStr = getMetricName(metric);
        return metric.includes("-") ? `${channelFriendlyName(platform)} - ${metricStr}` : metricStr;
    }

    return getMetricName(metric);
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const PlatformAndChannelLabel = ({ channelKey = "", fallback = "", accountId = "", className }) => {
    if (!channelKey) {
        return fallback;
    }

    const name = channelFriendlyName(channelKey, accountId);
    const [platformName, channelName] = name.split(", ");

    if (!channelName) {
        return name;
    }

    return (
        <span className={classNames(style.platformChannelLabel, { [className]: className })}>
            <span className={style.platform}>{platformName}</span>{" "}
            {channelName && <span className={style.channelName}>{channelName}</span>}
        </span>
    );
};

export const cleanCampaignGroupId = (campaignGroupId) => campaignGroupId.replace(/-/g, "_");
export const revertCampaignGroupId = (campaignGroupId) => campaignGroupId.replace(/_/g, "-");

export const channelFriendlyName = (channel, selectedAccountId) => {
    if (!channel || typeof channel !== "string") return "";

    if (channel.startsWith("bigQuery")) {
        if (channel === "bigQuery") {
            return "BigQuery";
        }
        const [, integrationId] = channel.split(bigQueryHelpers.BIGQUERY_METRIC_SPLITED_BY);
        const { sites = {} } = store.getState();
        const integrationName =
            get(sites, ["selectedSite", "integrations", "bigQuery", integrationId, "integrationName"]) || "";
        return `BigQuery, ${integrationName}`;
    }

    if (channel.includes("analyticsV4")) {
        const channelName = channel.replace("analyticsV4", "");
        if (!channelName) {
            return `Google Analytics 4`;
        }
        let channelBeatyName = "";
        if (channelName === "PaidSearch") {
            channelBeatyName = "Paid Search";
        }
        if (channelName === "OrganicSearch") {
            channelBeatyName = "Organic Search";
        }
        if (channelName === "OrganicSocial") {
            channelBeatyName = "Organic Social";
        }
        if (channelName === "PaidSocial") {
            channelBeatyName = "Paid Social";
        }
        return `Google Analytics 4, ${channelBeatyName || channelName}`;
    }

    if (channel.includes("adwords") && channel !== "adwords") {
        switch (channel) {
            case "adwordsDisplay":
                return `Google Ads, Display Network`;
            case "adwordsSearch":
                return `Google Ads, Search`;
            case "adwordsYouTubeVideos":
                return `Google Ads, YouTube Network`;
            case "adwordsSearchPartners":
                return `Google Ads, Search Partners`;
            case "adwordsCross":
                return `Google Ads, Cross-network`;
        }
    }

    if (channel.startsWith("campaignGroup")) {
        const campaignGroupId = revertCampaignGroupId(channel.replace("campaignGroup", ""));
        const { available: allViews = {} } = selectViews(store.getState()) || {};
        const accountId = selectedAccountId || makeSelectAccountId()(store.getState());
        let { [accountId]: { campaignGroups: { [campaignGroupId]: { name } = {} } = {} } = {} } = allViews || {};
        if (!name) {
            // for sharable link case
            const { [campaignGroupId]: { name: tempName } = {} } = makeSelectCampaignGroups()(store.getState());
            name = tempName;
        }
        return name ? `Campaign Group, ${name}` : `Campaign Groups`;
    }

    switch (channel) {
        case "custom":
            return `Custom Metrics`;
        case "adwords":
            return `Google Ads`;
        case "adwordsCampaigns":
            return `Google Ads Campaign`;
        case "facebookCampaigns":
            return `Meta Ads Campaign`;
        case "facebook":
            return `Meta Ads`;
        case "organicFacebook":
            return `Organic Facebook`;
        case "tiktok":
            return `TikTok Ads`;
        case "pinterest":
            return `Pinterest Ads`;
        case "linkedin":
            return `LinkedIn Ads`;
        case "linkedinCampaigns":
            return `LinkedIn Ads Campaign`;
        case "bing":
            return `Microsoft Advertising`;
        case "bingCampaigns":
            return `Microsoft Advertising Campaign`;
        case "shopify":
            return `Shopify`;
        case "maropost":
            return `Maropost`;
        case "klaviyo":
            return `Klaviyo`;
        case "paidSocial":
            return `Social`;
        case "organic":
            return `Organic Search`;
        case "weekly-kpi":
            return `Weekly KPI`;
        case "googleSearch":
            return `Google Search`;
        case "display":
            return `Display Traffic`;
        case "allTraffic":
            return `All Traffic`;
        case "paidSearch":
            return `Paid Search`;
        case "productCategories":
            return `Product Category`;
        case "searchConsole":
            return `Google Search Console`;
        case "googleBusinessProfile":
            return `Google Business Profile`;
        case "notes":
            return `Notes`;
        case "bigQuery":
            return `BigQuery`;
        case "slack":
            return `Slack`;
        case "googleAnalytics":
            return `Google Analytics`;
        case "stackAdapt":
            return `StackAdapt`;
        default:
            return unCamelCase(channel);
    }
};

const isHex = (str) => {
    if (!str) return false;
    const regex = RegExp("^[0-9A-Fa-f]+$");
    return regex.test(str);
};

export const isAccId = (str) => {
    if (!str) return false;
    if (str.length !== 24) return false;
    if (!isHex(str)) return false;

    return true;
};

const trimAccId = (str) => {
    let newStr = str;

    if (!str) return "";

    if (str[0] === "/") {
        newStr = newStr.substr(1);
    }

    if (str[str.length - 1] === "/") {
        newStr = newStr.slice(0, -1);
    }

    return newStr;
};

export function trackUrls({ pathname, action, search }) {
    const extraVariables = {
        Location: pathname,
        Action: action,
    };

    if (search) {
        extraVariables["Query"] = search;
    }

    mixpanel.register({
        "Page Path": trimAccId(
            pathname
                .split("/")
                .filter((str) => !isAccId(str))
                .join("/"),
        ),
    });

    if (action === "PUSH") {
        window.Intercom("update", {
            last_request_at: parseInt(new Date().getTime() / 1000),
        });
    }

    switch (true) {
        case pathname === "/dashboard":
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Control Panel - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/segments"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Segments - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/anomalies"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Anomalies - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/seo"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "SEO - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/overview"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Overview - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/paid-search"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Paid Search - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/display"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Display - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/video"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Video - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/paid-social"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Paid Social - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/organic"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Organic - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/kpi/ecommerce"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Channel Data - Ecommerce - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/dashboard/user"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "User Settings - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/dashboard/mysettings/profile"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Profile - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/forecast"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Forecast - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/settings"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Settings - Page View",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/pagehealth/ads"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Page Health - Ads",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/pagehealth"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Page Health - Anomalies",
            });
            mixpanel.track("Page View");
            break;
        case pathname.includes("/pagehealth/organic"):
            mixpanel.register({
                ...extraVariables,
                "Page Name": "Page Health - Organic",
            });
            mixpanel.track("Page View");
            break;
        default:
            if (pathname.includes("/dashboard/")) {
                mixpanel.register({
                    ...extraVariables,
                    "Page Name": "Goals - Page View",
                });
                mixpanel.track("Page View");

                break;
            } else {
                mixpanel.register({
                    ...extraVariables,
                    "Page Name": "Undefined Page",
                });
                mixpanel.track("Page View");
                break;
            }
    }

    mixpanel.time_event("Page View");
}

export const trendOptions = (trend, metric, useRed = false) => {
    if (trend === null || isNaN(trend) || trend === 0) {
        return {
            result: "neutral",
            trend: "up",
            color: "#BCCFDC",
            bgColor: "#F8F9FB",
            prefix: "",
        };
    } else if (trend > 0) {
        if (lowerIsBetterMetrics.includes(metric)) {
            return {
                result: "negative",
                trend: "up",
                color: useRed ? "#FB1427" : "#FF9D00",
                bgColor: useRed ? "#FFEEF0" : "#FFEEF0",
                prefix: "+",
            };
        }
        return {
            result: "positive",
            trend: "up",
            color: "#00B178",
            bgColor: "#E6F9F5",
            prefix: "+",
        };
    } else if (trend < 0) {
        if (lowerIsBetterMetrics.includes(metric)) {
            return {
                result: "positive",
                trend: "up",
                color: "#00B178",
                bgColor: "#E6F9F5",
                prefix: "",
            };
        }
        return {
            result: "negative",
            trend: "down",
            color: useRed ? "#FB1427" : "#FF9D00",
            bgColor: useRed ? "#FFEEF0" : "#FFEEF0",
            prefix: "",
        };
    }
    return {};
};

export const goalOpts = ({ flag }) => {
    const type = flag === "positive" ? "ok" : "warning";
    return {
        ok: {
            color: "#00B178",
            colorLight: "#e6f9f5",
            result: "ok",
        },
        warning: {
            color: "#fb1427",
            colorLight: "#ffeef0",
            result: "warning",
        },
    }[type];
};

export const platformMetricInfo = (name, platform) => {
    if (name.includes("custom-") || platform === "custom") {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const id = name.includes("custom-") ? name : `custom-${name}`;
        const { [id]: { name: customName = "Undefined", formula = "", metrics = {} } = {} } = customMetrics;
        const writtenFormula = formula
            .split("")
            .map((char) => {
                if (!metrics[char]) {
                    return char;
                }

                return `[${capitalize(metricFriendlyName(metrics[char]))}]`;
            })
            .join("");

        return {
            label: customName,
            tip: `<span><strong style="display:block;">Defined as:</strong>${writtenFormula}</span>`,
        };
    }

    const info = {
        conversions: {
            label: metricFriendlyName(name),
            tip: "Number of conversions from the connected ad platform.",
        },
        platformConversions: {
            label: metricFriendlyName(name),
            tip: "Number of conversions from the connected ad platform.",
        },
        cpa: {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        cpl: {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        cpc: {
            label: metricFriendlyName(name),
            tip: "<strong>(Cost per click)</strong> Number of ad clicks divided by media spend.",
        },
        ctr: {
            label: metricFriendlyName(name),
            tip: `${
                platform === "maropost"
                    ? "Number of clicks divided by number of successful delivery."
                    : "<strong>(Click-through rate)</strong> Number of ad clicks divided by number of impressions."
            }`,
        },
        clicks: {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks.",
        },
        clicksAll: {
            label: metricFriendlyName(name),
            tip:
                "The total number of clicks on your ads (includes link clicks, clicks to business page/profile, post reactions, comments/shares, expansion of media, other actions).",
        },
        impressions: {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions.",
        },
        cpm: {
            label: metricFriendlyName(name),
            tip: "<strong>(Cost per mille)</strong> Cost per one-thousand ad impressions.",
        },
        cost: {
            label: metricFriendlyName(name),
            tip: "Media spend from the connected ad platform.",
        },
        conversionRate: {
            label: "Conversion Rate",
            tip: "Number of conversions divided by number of ad clicks.",
        },
        engagement: {
            label: "Engagement",
            tip: "User interactions with your ad (e.g. clicks, reactions, shares, comments, etc.).",
        },
        engagementRate: {
            label: "Engagement Rate",
            tip: "Clicks, comments, likes, and shares divided by impressions.",
        },
    };

    return name && info[name] ? info[name] : {};
};

export const metricInfo = (name, platform) => {
    if (!name) {
        return null;
    }
    if (name.startsWith("custom-") || platform === "custom") {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const id = name.includes("custom-") ? name : `custom-${name}`;
        const { [id]: { name: customName = "Undefined", formula = "", metrics = {} } = {} } = customMetrics;
        const writtenFormula = formula
            .split("")
            .map((char) => {
                if (!metrics[char]) {
                    return char;
                }
                return `[${capitalize(metricFriendlyName(metrics[char]))}]`;
            })
            .join("");

        return {
            label: customName,
            tip: `<span><strong style="display:block;">Defined as:</strong>${writtenFormula}</span>`,
        };
    }

    if (name.includes("analyticsV4") && name !== "analyticsV4") {
        const gaInfo = {
            [`sessions`]: {
                label: metricFriendlyName(name),
                tip: `Visits to your website, as reported by Google Analytics 4.`,
            },
            [`conversions`]: {
                label: metricFriendlyName(name),
                tip: `Total number of conversions, as reported by Google Analytics 4.`,
            },
            [`transactions`]: {
                label: metricFriendlyName(name),
                tip: `Total number of transactions, as reported by Google Analytics 4.`,
            },
            [`conversionRate`]: {
                label: metricFriendlyName(name),
                tip: `Total number of conversions divided by session, as reported by Google Analytics 4.`,
            },
            [`transactionRate`]: {
                label: metricFriendlyName(name),
                tip: `Total number of transactions divided by sesions, as reported by Google Analytics 4.`,
            },
            [`engagementRate`]: {
                label: metricFriendlyName(name),
                tip: `Percentage of engaged sessions from all sessions, as reported by Google Analytics 4.`,
            },
            [`engagedSessions`]: {
                label: metricFriendlyName(name),
                tip: `All engaged sessions in which the user viewed a single page, as reported by Google Analytics 4.`,
            },
            [`activeUsers`]: {
                label: metricFriendlyName(name),
                tip: `An active user is any user who has an engaged session, as reported by Google Analytics 4.`,
            },
            [`newUsers`]: {
                label: metricFriendlyName(name),
                tip: `The number of users who interacted with your site or launched your app for the first time, as reported by Google Analytics 4.`,
            },
            [`totalRevenue`]: {
                label: metricFriendlyName(name),
                tip: `Total revenue, as reported by Google Analytics 4.`,
            },
            [`avgOrderValue`]: {
                label: metricFriendlyName(name),
                tip: `Total transaction value divided by quantity of transactions as tracked by Google Analytics 4.`,
            },
            [`orders`]: {
                label: metricFriendlyName(name),
                tip: `The number of orders.`,
            },
            [`items`]: {
                label: metricFriendlyName(name),
                tip: `The number of items.`,
            },
            [`newCustomerOrders`]: {
                label: metricFriendlyName(name),
                tip: `The number of New Customer Orders.`,
            },
            [`newCustomerGMV`]: {
                label: metricFriendlyName(name),
                tip: `New Customer GMV.`,
            },
            [`newCustomerAOV`]: {
                label: metricFriendlyName(name),
                tip: `New Customer AOV.`,
            },
            [`returningCustomerOrders`]: {
                label: metricFriendlyName(name),
                tip: `The number of Returning Customer Orders.`,
            },
            [`returningCustomerGMV`]: {
                label: metricFriendlyName(name),
                tip: `Returning Customer GMV.`,
            },
            [`returningCustomerAOV`]: {
                label: metricFriendlyName(name),
                tip: `Returning Customer AOV.`,
            },
            [`gmv`]: {
                label: metricFriendlyName(name),
                tip: `Gross Merchandise Value (GMV).`,
            },
            [`aov`]: {
                label: metricFriendlyName(name),
                tip: `Average Order Value (AOV).`,
            },
            [`averageSalePrice`]: {
                label: metricFriendlyName(name),
                tip: `Average Sale Price.`,
            },
            [`itemsPerTransaction`]: {
                label: metricFriendlyName(name),
                tip: `Items Per Transaction.`,
            },
            [`grossSales`]: {
                label: metricFriendlyName(name),
                tip: `Gross Sales.`,
            },
            [`discounts`]: {
                label: metricFriendlyName(name),
                tip: `Discounts.`,
            },
            [`shipping`]: {
                label: metricFriendlyName(name),
                tip: `Shipping.`,
            },
            [`taxes`]: {
                label: metricFriendlyName(name),
                tip: `The total amount of taxes based on the orders.`,
            },
            [`returns`]: {
                label: metricFriendlyName(name),
                tip: `The value of goods returned by a customer.`,
            },
            [`netSales`]: {
                label: metricFriendlyName(name),
                tip: `Net Sales`,
            },
            [`totalSales`]: {
                label: metricFriendlyName(name),
                tip: `Total Sales`,
            },
            [`bounces`]: {
                label: metricFriendlyName(name),
                tip: `The total number of people who left your site or app without engaging.`,
            },
            [`averageSessionDuration`]: {
                label: metricFriendlyName(name),
                tip: `The total duration of all engaged sessions (in seconds) divided by the number of sessions.`,
            },
            [`screenPageViewsPerSession`]: {
                label: metricFriendlyName(name),
                tip: `The average number of pages a person views in a given session.`,
            },
            [`avgEngagementTime`]: {
                label: metricFriendlyName(name),
                tip: `The sum of engagement time divided by the number of users in the entire time period.`,
            },
            [`avgEngagementTimePerSession`]: {
                label: metricFriendlyName(name),
                tip: `The sum of engagement time divided by the total number of sessions.`,
            },
            [`totalUsers`]: {
                label: metricFriendlyName(name),
                tip: `The total number of people who visited your site or app.`,
            },
            [`addToCarts`]: {
                label: metricFriendlyName(name),
                tip: `The number of times users added items to their shopping carts.`,
            },
            [`grossItemRevenue`]: {
                label: metricFriendlyName(name),
                tip:
                    "The total revenue from items only. Gross item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level. Gross item revenue does not include refunds.",
            },
            [`itemDiscountAmount`]: {
                label: metricFriendlyName(name),
                tip:
                    "The monetary value of item discounts in eCommerce events. This metric is populated in tagging by the 'discount' item parameter.",
            },
            [`itemRefundAmount`]: {
                label: metricFriendlyName(name),
                tip:
                    "Item refund amount is the total refunded transaction revenue from items only. Item refund amount is the product of price and quantity for the 'refund' event.",
            },
            [`itemRevenue`]: {
                label: metricFriendlyName(name),
                tip:
                    "The total revenue from purchases minus refunded transaction revenue from items only. Item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level.",
            },
            [`itemsAddedToCart`]: {
                label: metricFriendlyName(name),
                tip: "The number of units added to cart for a single item.",
            },
            [`itemsCheckedOut`]: {
                label: metricFriendlyName(name),
                tip: "The number of units checked out for a single item.",
            },
            [`itemsPurchased`]: {
                label: metricFriendlyName(name),
                tip: "The number of units for a single item included in purchase events.",
            },
            [`itemsViewed`]: {
                label: metricFriendlyName(name),
                tip: "The number of units viewed for a single item.",
            },
        };
        const metricName = name
            .split("-")
            .slice(1)
            .join("-");
        return metricName && gaInfo[metricName] ? gaInfo[metricName] : {};
    }

    const info = {
        platformConversions: {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported from ad platforms.",
        },
        cpl: {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        cpa: {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        cplPaid: {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per lead)</strong> Media spend divided by quantity of leads generated from paid tactics.",
        },
        cpc: {
            label: metricFriendlyName(name),
            tip: "<strong>(Cost per click)</strong> Number of ad clicks divided by media spend.",
        },
        ctr: {
            label: metricFriendlyName(name),
            tip: `<strong>(Click-through rate)</strong> Number of ad clicks divided by number of ${
                platform === "maropost" ? "delivered" : "impressions"
            }.`,
        },
        clicks: {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks.",
        },
        impressions: {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions.",
        },
        engagementRate: {
            label: metricFriendlyName(name),
            tip: "Clicks, comments, likes, and shares divided by impressions.",
        },
        orders: {
            label: metricFriendlyName(name),
            tip: `The number of orders.`,
        },
        items: {
            label: metricFriendlyName(name),
            tip: `The number of items.`,
        },
        newCustomerOrders: {
            label: metricFriendlyName(name),
            tip: `The number of New Customer Orders.`,
        },
        newCustomerGMV: {
            label: metricFriendlyName(name),
            tip: `New Customer GMV.`,
        },
        newCustomerAOV: {
            label: metricFriendlyName(name),
            tip: `New Customer AOV.`,
        },
        returningCustomerOrders: {
            label: metricFriendlyName(name),
            tip: `The number of Returning Customer Orders.`,
        },
        returningCustomerGMV: {
            label: metricFriendlyName(name),
            tip: `Returning Customer GMV.`,
        },
        returningCustomerAOV: {
            label: metricFriendlyName(name),
            tip: `Returning Customer AOV.`,
        },
        gmv: {
            label: metricFriendlyName(name),
            tip: `Gross Merchandise Value (GMV).`,
        },
        aov: {
            label: metricFriendlyName(name),
            tip: `Average Order Value (AOV).`,
        },
        averageSalePrice: {
            label: metricFriendlyName(name),
            tip: `Average Sale Price.`,
        },
        itemsPerTransaction: {
            label: metricFriendlyName(name),
            tip: `Items Per Transaction.`,
        },
        grossSales: {
            label: metricFriendlyName(name),
            tip: `Gross Sales.`,
        },
        discounts: {
            label: metricFriendlyName(name),
            tip: `Discounts.`,
        },
        shipping: {
            label: metricFriendlyName(name),
            tip: `Shipping.`,
        },
        taxes: {
            label: metricFriendlyName(name),
            tip: `The total amount of taxes based on the orders.`,
        },
        returns: {
            label: metricFriendlyName(name),
            tip: `The value of goods returned by a customer.`,
        },
        netSales: {
            label: metricFriendlyName(name),
            tip: `Net Sales`,
        },
        totalSales: {
            label: metricFriendlyName(name),
            tip: `Total Sales`,
        },
        cost: {
            label: metricFriendlyName(name),
            tip: "Combined media spend from connected ad platforms.",
        },
        avgTimeOnSite: {
            label: metricFriendlyName(name),
            tip: "The average time users spend on your website.",
        },
        searchRank: {
            label: metricFriendlyName("searchRank"),
            tip: "The number your website ranks in search results.",
        },
        competitorSearchRank: {
            label: metricFriendlyName("competitorSearchRank"),
            tip: "The numbers your competitors’ website ranks in search results.",
        },
        pageHealthMetric: {
            label: "Page Health",
            tip: "A feature that verifies that your paid / organic pages are live and performing properly.",
        },
        normal: { label: "Normal", tip: "No noteworthy changes identified." },
        level1: {
            label: "Level 1 - Slight Changes",
            tip: "Slight changes identified in data.",
        },
        level2: {
            label: "Level 2 - Moderate Changes",
            tip: "Moderate changes identified in data.",
        },
        level3: {
            label: "Level 3 - Severe Changes",
            tip: "Severe changes identified in data.",
        },
        positive: { label: "Positive", tip: "Positive Changes" },
        negative: { label: "Negative", tip: "Negative Changes" },
        weeklyAnomalies: {
            label: "Weekly KPI Performance",
            tip: "Anomalies in your aggregated KPI data.",
        },
        productBasedAnomalies: {
            label: "Ecommerce",
            tip: "Anomalies in your ecommerce Google Analytics V4 data.",
        },
        adwords: {
            label: "Google Ads",
            tip: "Anomalies in your Google Ads data",
        },
        bing: {
            label: "Microsoft Advertising",
            tip: "Anomalies in your Microsoft Advertising data",
        },
        linkedin: {
            label: "LinkedIn Ads",
            tip: "Anomalies in your LinkedIn data",
        },
        googleBusinessProfile: {
            label: "Google Business Profile",
            tip: "Anomalies in your Google Business Profile data",
        },
        facebook: {
            label: "Meta Ads",
            tip: "Anomalies in your Meta data",
        },
        shopify: {
            label: "Shopify",
            tip: "Anomalies in your Shopify data",
        },
        googleSearch: {
            label: "Google Search Rank",
            tip: "Anomalies in your Google Search rank data",
        },
        campaignBasedAnomalies: {
            label: "Campaigns",
            tip: "Platform campaigns data",
        },
        facebookCampaigns: {
            label: "Meta Ads",
            tip: "Anomalies in your Meta Ads data.",
        },
        adwordsCampaigns: {
            label: "Google Ads",
            tip: "Anomalies in your Google Ads data.",
        },
        bingCampaigns: {
            label: "Microsoft Advertising",
            tip: "Anomalies in your Microsoft Advertising data.",
        },
        linkedinCampaigns: {
            label: "LinkedIn Ads",
            tip: "Anomalies in your LinkedIn Ads data.",
        },
        seo: {
            label: "SEO Rank Monitoring",
            tip: "Anomalies in your SEO performance monitoring data.",
        },
        pageHealth: {
            label: "Landing Pages",
            tip: "Anomalies in your landing pages monitoring data.",
        },
        seoAudits: {
            label: "SEO Audits",
            tip: "Anomalies in your SEO Audits monitoring data.",
        },
        conversionRate: {
            label: "Conversion Rate",
            tip: "Number of conversions divided by number of sessions.",
        },
        bounceRate: {
            label: "Bounce Rate",
            tip: "Percentage of all sessions in which the user viewed a single page.",
        },
        roi: {
            label: "ROI",
            tip:
                "<strong>(Return on investment)</strong> Total revenue divided by ad spend and fees displayed as a percentage.",
        },
        roas: {
            label: "ROAS",
            tip: "<strong>(Return on ad spend)</strong> Total revenue divided by ad spend.",
        },
        roasByTime: {
            label: "ROAS (By Time)",
            tip: "<strong>(Return on ad spend)</strong> Total revenue by time divided by ad spend.",
        },
        kpiMonitoring: {
            label: "Goals Monitoring",
            tip: "Anomalies in your goals data.",
        },
        budgetMonitoring: {
            label: "Budget Monitoring",
            tip: "Anomalies in your budget data.",
        },
        seoScore: {
            label: "SEO Score",
            tip: "SEO Score data is calculated based on your valid SEO Auditing vitals devided by all selected vitals.",
        },
        interactions: {
            label: "Interactions",
            tip: "Total number of interactions with ads.",
        },
        friendlyAdType: {
            label: "Ad Type",
            tip: "The type of the ad.",
        },
        adId: {
            label: "Ad ID",
            tip: "The ID of the ad.",
        },
        adName: {
            label: "Ad Name",
            tip: "The name of the ad.",
        },
        "adwords-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in Google Ads.",
        },
        "adwords-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in Google Ads.",
        },
        "adwords-interactions": {
            label: metricFriendlyName(name),
            tip: "Total number of interactions with ads in Google Ads.",
        },
        "adwords-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in Google Ads.",
        },
        "adwords-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in Google Ads.",
        },
        "adwords-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in Google ads.",
        },
        "adwords-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in Google Ads.",
        },
        "adwords-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by Google Ads.",
        },
        "adwords-cpl": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per acquisition) Total media spend from Google Ads divided by the number of conversions from Google Ads.",
        },
        "adwords-conversionRate": {
            label: metricFriendlyName(name),
            tip: "Total number of conversions from Google Ads divided by total number of ad clicks from Google Ads.",
        },
        "adwords-viewThroughConv": {
            label: metricFriendlyName(name),
            tip: "Total number of view-through conversions as reported by Google Ads.",
        },
        "adwords-conversionValue": {
            label: metricFriendlyName(name),
            tip: "Total conversion value as reported by Google Ads.",
        },
        "adwords-conversionsByConversionDate": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by Google Ads based on the day the conversion occurred.",
        },
        "adwords-conversionsValueByConversionDate": {
            label: metricFriendlyName(name),
            tip: "Total conversion value as reported by Google Ads based on the day each conversion occurred.",
        },
        "adwords-conversionRateByConversionDate": {
            label: metricFriendlyName(name),
            tip:
                "Total number of conversions (by conv. time) from Google Ads divided by total number of ad clicks from Google Ads.",
        },
        "adwords-impressionShare": {
            label: metricFriendlyName(name),
            tip: "Search impression share as reported by Google Ads.",
        },
        "adwords-engagements": {
            label: metricFriendlyName(name),
            tip: "The number of engagements as reported by Google Ads.",
        },
        "adwords-videoViews": {
            label: metricFriendlyName(name),
            tip: "The number of times your video ads were viewed as reported by Google Ads.",
        },
        "adwords-interactionRate": {
            label: metricFriendlyName(name),
            tip:
                "How often people interact with your ad after it is shown to them. This is the number of interactions divided by the number of times your ad is shown.",
        },
        "adwords-engagementRate": {
            label: metricFriendlyName(name),
            tip:
                "How often people engage with your ad after it's shown to them. This is the number of ad expansions divided by the number of times your ad is shown.",
        },
        "adwords-videoViewRate": {
            label: metricFriendlyName(name),
            tip:
                "The number of views your TrueView video ad receives divided by its number of impressions, including thumbnail impressions for TrueView in-display ads.",
        },
        "adwords-phoneCalls": {
            label: metricFriendlyName(name),
            tip: "Number of offline phone calls.",
        },
        "adwords-phoneImpressions": {
            label: metricFriendlyName(name),
            tip: "Number of offline phone impressions.",
        },
        "adwords-phoneThroughRate": {
            label: metricFriendlyName(name),
            tip:
                "Number of phone calls received (Phone Calls) divided by the number of times your phone number is shown (Phone Impressions).",
        },
        "adwords-campaignAssetType": {
            label: metricFriendlyName(name),
            tip: "Role that the asset takes under the linked campaign.",
        },
        "adwords-campaignAssetSource": {
            label: metricFriendlyName(name),
            tip: "Source of the campaign asset link.",
        },
        "adwords-assetGroupStatus": {
            label: metricFriendlyName(name),
            tip: "The status of the asset group.",
        },
        "adwords-assetGroupStrength": {
            label: metricFriendlyName(name),
            tip: "Overall ad strength of this asset group.",
        },
        "adwords-assetGroupAssetStatus": {
            label: metricFriendlyName(name),
            tip:
                "Provides the PrimaryStatus of this asset link. Primary status is meant essentially to differentiate between the plain status field, which has advertiser set values of enabled, paused, or removed. The primary status takes into account other signals (for assets its mainly policy and quality approvals) to come up with a more comprehensive status to indicate its serving state.",
        },
        "adwords-assetGroupAssetType": {
            label: metricFriendlyName(name),
            tip: "The description of the placement of the asset within the asset group.",
        },
        "adwords-assetGroupAssetPerformance": {
            label: metricFriendlyName(name),
            tip: "The performance of this asset group asset.",
        },
        "adwords-assetGroupAssetSource": {
            label: metricFriendlyName(name),
            tip: "Source of the asset group asset.",
        },
        "facebook-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in Meta Ads.",
        },
        "facebook-leadGrouped": {
            label: metricFriendlyName(name),
            tip: "Meta Leads.",
        },
        "facebook-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in Meta Ads.",
        },
        "facebook-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in Meta Ads.",
        },
        "facebook-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in Meta Ads.",
        },
        "facebook-clicksAll": {
            label: metricFriendlyName(name),
            tip:
                "The total number of clicks on your ads (includes link clicks, clicks to business page/profile, post reactions, comments/shares, expansion of media, other actions).",
        },
        "facebook-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in Meta ads.",
        },
        "facebook-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in Meta Ads.",
        },
        "facebook-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by Meta Ads.",
        },
        "facebook-cpl": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per acquisition) Total media spend from Meta Ads divided by the number of conversions from Meta Ads.",
        },
        "facebook-conversionRate": {
            label: metricFriendlyName(name),
            tip: "Total number of conversions from Meta Ads divided by total number of ad clicks from Meta Ads.",
        },
        "facebook-fb_pixel_purchase": {
            label: metricFriendlyName(name),
            tip: "Total number of purchases from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-onsite_conversion-purchase": {
            label: metricFriendlyName(name),
            tip: "Total number of purchases on Meta.",
        },
        "facebook-purchases": {
            label: metricFriendlyName(name),
            tip: "Total number of purchases from Meta Ads.",
        },
        "facebook-fb_pixel_add_to_cart": {
            label: metricFriendlyName(name),
            tip: "Total number of add to cart actions from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_initiate_checkout": {
            label: metricFriendlyName(name),
            tip: "Total number of checkouts initiated from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_purchase_value": {
            label: metricFriendlyName(name),
            tip: "Total value of purchases from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-onsite_conversion-purchase_value": {
            label: metricFriendlyName(name),
            tip: "Total value of purchases on Meta.",
        },
        "facebook-purchaseValue": {
            label: metricFriendlyName(name),
            tip: "Total value of purchases from Meta Ads.",
        },
        "facebook-landingPageViews": {
            label: metricFriendlyName(name),
            tip: "Landing Page Views.",
        },
        "facebook-fb_pixel_add_to_cart_value": {
            label: metricFriendlyName(name),
            tip: "Total value of add to cart actions from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_initiate_checkout_value": {
            label: metricFriendlyName(name),
            tip: "Total value of checkouts initiated from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-outboundClicks": {
            label: metricFriendlyName(name),
            tip: "Outbound Clicks.",
        },
        "facebook-outboundCtr": {
            label: metricFriendlyName(name),
            tip: "Outbound Clicks CTR.",
        },
        "facebook-aov": {
            label: metricFriendlyName(name),
            tip: "Average Order Value.",
        },
        "facebook-cpp": {
            label: metricFriendlyName(name),
            tip: "Cost Per Purchase.",
        },
        "facebook-websiteCpp": {
            label: metricFriendlyName(name),
            tip: "Website Cost Per Purchase.",
        },
        "facebook-metaCpp": {
            label: metricFriendlyName(name),
            tip: "Meta Cost Per Purchase.",
        },
        "facebook-roas": {
            label: metricFriendlyName(name),
            tip: "ROAS.",
        },
        "facebook-websiteRoas": {
            label: metricFriendlyName(name),
            tip: "Website ROAS.",
        },
        "facebook-metaRoas": {
            label: metricFriendlyName(name),
            tip: "Meta ROAS.",
        },
        "facebook-contentViews": {
            label: metricFriendlyName(name),
            tip: "Meta Views Content.",
        },
        "facebook-contacts": {
            label: metricFriendlyName(name),
            tip: "Meta Contacts.",
        },
        "facebook-omniAddToCart": {
            label: metricFriendlyName(name),
            tip: "Website + Meta Adds To Cart.",
        },
        "facebook-fb_pixel_complete_registration": {
            label: metricFriendlyName(name),
            tip: "Total number of complete registrations from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_complete_registration_value": {
            label: metricFriendlyName(name),
            tip: "Total value of complete registrations from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_lead": {
            label: metricFriendlyName(name),
            tip: "Total number of leads from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-fb_pixel_lead_value": {
            label: metricFriendlyName(name),
            tip: "Total value of leads from Meta Ads as tracked by Meta pixel.",
        },
        "facebook-campaignObjective": {
            label: metricFriendlyName(name),
            tip: "The objective reflecting the goal you want to achieve with your advertising.",
        },
        "facebook-videoPlays": {
            label: metricFriendlyName(name),
            tip:
                "The number of times your video starts to play. This is counted for each impression of a video, and excludes replays.",
        },
        "facebook-videoPlayTime": {
            label: metricFriendlyName(name),
            tip:
                "The average time a video was played, including any time spent replaying the video for a single impression.",
        },
        "facebook-thruplays": {
            label: metricFriendlyName(name),
            tip: "The number of times that your video was played to completion, or for at least 15 seconds.",
        },
        "facebook-cpLeadGrouped": {
            label: metricFriendlyName(name),
            tip: "Cost Per Lead.",
        },
        "facebook-subscriptions": {
            label: metricFriendlyName(name),
            tip: "Subscriptions.",
        },
        "facebook-subscriptionValue": {
            label: metricFriendlyName(name),
            tip: "Subscribe Conversion Value.",
        },
        "facebook-costPerSubscription": {
            label: metricFriendlyName(name),
            tip: "Cost Per Subscription.",
        },
        "facebook-costPer_fb_pixel_lead": {
            label: metricFriendlyName(name),
            tip: "Cost Per Offsite Lead.",
        },
        "facebook-costPerAddToCart": {
            label: metricFriendlyName(name),
            tip: "(Cost per Add to cart) Media spend divided by the number of add to cart events in Meta Ads.",
        },
        totalOrganicImpressions: {
            label: metricFriendlyName(name),
            tip:
                "The number of times any content from your Page or about your Page entered a person's screen through paid and unpaid distributions.",
        },
        totalPublishedPosts: {
            label: metricFriendlyName(name),
            tip: "Total Published Posts.",
        },
        organicEngagements: {
            label: metricFriendlyName(name),
            tip:
                "The number of times people have engaged with your posts through reactions, comments, shares and more (include paid).",
        },
        pagePostEngagements: {
            label: metricFriendlyName(name),
            tip:
                "The number of times people have engaged with your posts through reactions, comments, shares and more (include paid).",
        },
        organicLinkClicks: {
            label: metricFriendlyName(name),
            tip: "The number of times users clicked on links in your posts (include paid).",
        },
        organicEngagementRate: {
            label: metricFriendlyName(name),
            tip: "Organic Engagement Rate (per impression and include paid).",
        },
        pageFollows: {
            label: metricFriendlyName(name),
            tip: "The number of Facebook users who follow your page as of the last day of the selected time period.",
        },
        netFollowerGrowth: {
            label: metricFriendlyName(name),
            tip: "The net number of followers acquired.",
        },
        pageFans: {
            label: metricFriendlyName(name),
            tip: "The number of Facebook users who liked the page as of the last day of the selected time period.",
        },
        pageFanRemoves: {
            label: metricFriendlyName(name),
            tip: "Unlikes of your Page.",
        },
        paidImpressions: {
            label: metricFriendlyName(name),
            tip:
                "The number of times any post or story content from your Page or about your Page entered a person's screen through paid distribution such as an ad.",
        },
        pageImpressions: {
            label: metricFriendlyName(name),
            tip:
                "The number of times any content from your Page or about your Page entered a person's screen. This includes posts, stories, ads, as well other content or information on your Page.",
        },
        "organicFacebook-videoFullViews": {
            label: metricFriendlyName(name),
            tip:
                "The number of times users viewed your page’s videos for at least 30 seconds or nearly to the end if the video is shorter than 30 seconds.",
        },
        "organicFacebook-impressions": {
            label: metricFriendlyName(name),
            tip:
                "The number of times content associated with your page was displayed to users. For Reels post-level metrics, impressions are mapped to plays on native as Facebook does not provide post-level impressions on Reels.",
        },
        "organicFacebook-avgDailyImpressions": {
            label: metricFriendlyName(name),
            tip: "The average number of times content associated with your page was displayed to users, per day.",
        },
        "organicFacebook-reach": {
            label: metricFriendlyName(name),
            tip: "Reach.",
        },
        "organicFacebook-avgDailyReach": {
            label: metricFriendlyName(name),
            tip: "The average number of unique users who were shown any content associated with your page, per day.",
        },
        "organicFacebook-fanAgeRanges": {
            label: metricFriendlyName(name),
            tip: "The percent of followers in each age group based on the user-provided information in their profile.",
        },
        "organicFacebook-pageImpressionsByAgeUnique": {
            label: metricFriendlyName(name),
            tip:
                "The percent of people reached in each age group based on the user-provided information in their Facebook profile.",
        },
        "organicFacebook-fanGenders": {
            label: metricFriendlyName(name),
            tip: "The percent of followers by gender based on the user-provided information in their profile.",
        },
        "organicFacebook-pageImpressionsByGenderUnique": {
            label: metricFriendlyName(name),
            tip: "The percent of people reached by gender based on the user-provided information in their profile.",
        },
        "organicFacebook-pageFansCountry": {
            label: metricFriendlyName(name),
            tip: "The number of followers located in each country.",
        },
        "organicFacebook-pageImpressionsByCountryUnique": {
            label: metricFriendlyName(name),
            tip: "The average daily number of people reached in each country.",
        },
        "organicFacebook-pageImpressionsByCityUnique": {
            label: metricFriendlyName(name),
            tip: "The average daily number of people reached in each city.",
        },
        "organicFacebook-pageEngagements": {
            label: metricFriendlyName(name),
            tip:
                "The number of times people have engaged with your posts through reactions, comments, shares and more.",
        },
        "linkedin-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in LinkedIn Ads.",
        },
        "linkedin-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in LinkedIn Ads.",
        },
        "linkedin-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in LinkedIn Ads.",
        },
        "linkedin-oneClickLeads": {
            label: metricFriendlyName(name),
            tip: "Leads generated from LinkedIn lead form ads.",
        },
        "linkedin-oneClickLeadsConversionRate": {
            label: metricFriendlyName(name),
            tip: "Percentage of opened lead forms that were submitted.",
        },
        "linkedin-oneClickLeadFormOpens": {
            label: metricFriendlyName(name),
            tip: "Number of times a user opened a lead form.",
        },
        "linkedin-costPerOneClickLeads": {
            label: metricFriendlyName(name),
            tip: "Total media spend divided by the number of leads from lead form ads.",
        },
        "linkedin-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in LinkedIn Ads.",
        },
        "linkedin-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in LinkedIn ads.",
        },
        "linkedin-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in LinkedIn Ads.",
        },
        "linkedin-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by LinkedIn Ads.",
        },
        "linkedin-cpl": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per acquisition) Total media spend from LinkedIn Ads divided by the number of conversions from LinkedIn Ads.",
        },
        "linkedin-conversionRate": {
            label: metricFriendlyName(name),
            tip:
                "Total number of conversions from LinkedIn Ads divided by total number of ad clicks from LinkedIn Ads.",
        },
        "bing-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in Microsoft Advertising.",
        },
        "bing-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in Microsoft Advertising.",
        },
        "bing-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in Microsoft Advertising.",
        },
        "bing-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in Microsoft Advertising.",
        },
        "bing-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in Microsoft Advertising.",
        },
        "bing-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in Microsoft Advertising.",
        },
        "bing-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by Microsoft Advertising.",
        },
        "bing-cpl": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per acquisition) Total media spend from Microsoft Advertising divided by the number of conversions from Microsoft Advertising.",
        },
        "bing-conversionRate": {
            label: metricFriendlyName(name),
            tip:
                "Total number of conversions from Microsoft Advertising divided by total number of ad clicks from Microsoft Advertising.",
        },
        "bing-viewThroughConversions": {
            label: metricFriendlyName(name),
            tip: "Total number of view-through conversions as reported by Microsoft Advertising.",
        },
        "bing-revenue": {
            label: metricFriendlyName(name),
            tip: "Total revenue as reported by Microsoft Advertising.",
        },
        "shopify-transactions": {
            label: metricFriendlyName(name),
            tip: "Total number of transactions, as reported by Shopify.",
        },
        "shopify-revenue": {
            label: metricFriendlyName(name),
            tip: "Total sales (including discounts, excluding tax), as reported by Shopify.",
        },
        "shopify-avgOrderValue": {
            label: metricFriendlyName(name),
            tip: "Total sales divided by total number of transactions, as reported by Shopify.",
        },
        "shopify-orders": {
            label: metricFriendlyName(name),
            tip: `The number of orders.`,
        },
        "shopify-items": {
            label: metricFriendlyName(name),
            tip: `The number of items.`,
        },
        "shopify-newCustomerOrders": {
            label: metricFriendlyName(name),
            tip: `The number of New Customer Orders.`,
        },
        "shopify-newCustomerGMV": {
            label: metricFriendlyName(name),
            tip: `New Customer GMV.`,
        },
        "shopify-newCustomerAOV": {
            label: metricFriendlyName(name),
            tip: `New Customer AOV.`,
        },
        "shopify-returningCustomerOrders": {
            label: metricFriendlyName(name),
            tip: `The number of Returning Customer Orders.`,
        },
        "shopify-returningCustomerGMV": {
            label: metricFriendlyName(name),
            tip: `Returning Customer GMV.`,
        },
        "shopify-returningCustomerAOV": {
            label: metricFriendlyName(name),
            tip: `Returning Customer AOV.`,
        },
        "shopify-gmv": {
            label: metricFriendlyName(name),
            tip: `Gross Merchandise Value (GMV).`,
        },
        "shopify-aov": {
            label: metricFriendlyName(name),
            tip: `Average Order Value (AOV).`,
        },
        "shopify-averageSalePrice": {
            label: metricFriendlyName(name),
            tip: `Average Sale Price.`,
        },
        "shopify-itemsPerTransaction": {
            label: metricFriendlyName(name),
            tip: `Items Per Transaction.`,
        },
        "shopify-grossSales": {
            label: metricFriendlyName(name),
            tip: `Gross Sales.`,
        },
        "shopify-discounts": {
            label: metricFriendlyName(name),
            tip: `Discounts.`,
        },
        "shopify-shipping": {
            label: metricFriendlyName(name),
            tip: `Shipping.`,
        },
        "shopify-taxes": {
            label: metricFriendlyName(name),
            tip: `The total amount of taxes based on the orders.`,
        },
        "shopify-returns": {
            label: metricFriendlyName(name),
            tip: `The value of goods returned by a customer.`,
        },
        "shopify-netSales": {
            label: metricFriendlyName(name),
            tip: `Net Sales`,
        },
        "shopify-totalSales": {
            label: metricFriendlyName(name),
            tip: `Total Sales`,
        },
        custom: {
            label: channelFriendlyName(name),
            tip: "Your own metrics with easy-to-use formulas that pull in data points from connected platforms.",
        },

        // Google Business Profile
        callClicks: {
            label: metricFriendlyName(name),
            tip: "The number of times the business profile call button was clicked.",
        },
        websiteClicks: {
            label: metricFriendlyName(name),
            tip: "The number of times the business profile website was clicked.",
        },
        businessBookings: {
            label: metricFriendlyName(name),
            tip: "The number of bookings received from the business profile.",
        },
        businessFoodOrders: {
            label: metricFriendlyName(name),
            tip: "The number of food orders received from the business profile.",
        },
        averageRating: {
            label: metricFriendlyName(name),
            tip: "The average rating from submitted reviews over the date range selected.",
        },
        totalReviewCount: {
            label: metricFriendlyName(name),
            tip: "The number of reviews submitted over the date range selected.",
        },
        averageRatingLifeTime: {
            label: metricFriendlyName(name),
            tip: "The average rating from submitted reviews all-time.",
        },
        totalReviewCountLifeTime: {
            label: metricFriendlyName(name),
            tip: "The number of reviews submitted all-time.",
        },
        viewsByDesktop: {
            label: metricFriendlyName(name),
            tip:
                "Total business impressions on both Google Maps and Google Search, on Desktop devices. Multiple impressions by a unique user within a single day are counted as a single impression.",
        },
        viewsByMobile: {
            label: metricFriendlyName(name),
            tip:
                "Total business impressions on both Google Maps and Google Search, on Mobile devices. Multiple impressions by a unique user within a single day are counted as a single impression.",
        },

        // Campaign group
        "campaignGroup-conversions": {
            label: metricFriendlyName("conversions"),
            tip: "Conversions as reported from the connected ad platform",
        },
        "campaignGroup-conversionRate": {
            label: metricFriendlyName("conversionRate"),
            tip: "Total number of conversions divided by total number of ad clicks from the connected ad platform.",
        },
        "adwords-ADD_TO_CART": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Add to cart' conversion category in Google Ads.",
        },
        "adwords-BEGIN_CHECKOUT": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Begin checkout' conversion category in Google Ads.",
        },
        "adwords-BOOK_APPOINTMENT": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Book appointment' conversion category in Google Ads.",
        },
        "adwords-CONTACT": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Contact' conversion category in Google Ads.",
        },
        "adwords-DOWNLOAD": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Download' conversion category in Google Ads.",
        },
        "adwords-ENGAGEMENT": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Engagement' conversion category in Google Ads.",
        },
        "adwords-GET_DIRECTIONS": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Get directions' conversion category in Google Ads.",
        },
        "adwords-IMPORTED_LEAD": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Imported Lead' conversion category in Google Ads.",
        },
        "adwords-OUTBOUND_CLICK": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Outbound click' conversion category in Google Ads.",
        },
        "adwords-PAGE_VIEW": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Page view' conversion category in Google Ads.",
        },
        "adwords-PHONE_CALL_LEAD": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Phone call leads' conversion category in Google Ads.",
        },
        "adwords-PURCHASE": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Purchase' conversion category in Google Ads.",
        },
        "adwords-REQUEST_QUOTE": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Request quote' conversion category in Google Ads.",
        },
        "adwords-SIGNUP": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Sign-up' conversion category in Google Ads.",
        },
        "adwords-STORE_SALE": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Store Sale' conversion category in Google Ads.",
        },
        "adwords-STORE_VISIT": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Store Visit' conversion category in Google Ads.",
        },
        "adwords-SUBMIT_LEAD_FORM": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Submit lead form' conversion category in Google Ads.",
        },
        "adwords-SUBSCRIBE_PAID": {
            label: metricFriendlyName(name),
            tip: "Conversions from 'Subscribe' conversion category in Google Ads.",
        },
        displayImpressionShare: {
            label: metricFriendlyName(name),
            tip: "Display impression share as reported by the platform.",
        },
        searchLostIsBudget: {
            label: metricFriendlyName(name),
            tip: "Percentage of search impression share lost due to budget.",
        },
        searchLostIsRank: {
            label: metricFriendlyName(name),
            tip: "Percentage of search impression share lost due to ad rank.",
        },
        displayLostIsBudget: {
            label: metricFriendlyName(name),
            tip: "Percentage of display impression share lost due to budget.",
        },
        displayLostIsRank: {
            label: metricFriendlyName(name),
            tip: "Percentage of display impression share lost due to ad rank.",
        },
        views: {
            label: metricFriendlyName(name),
            tip: "Views as reported by the platform.",
        },
        viewRate: {
            label: metricFriendlyName(name),
            tip: "Views divided by impressions.",
        },
        cpv: {
            label: metricFriendlyName(name),
            tip: "(Cost per View) Views divided by media spend.",
        },
        cpcAll: {
            label: metricFriendlyName(name),
            tip: "(Cost Per Click All) media spend divided by Clicks (All).",
        },
        ctrAll: {
            label: metricFriendlyName(name),
            tip: "(Click Through Rate All) Clicks (All) divided by Impressions.",
        },
        reach: {
            label: metricFriendlyName(name),
            tip: "Number of people who saw your ads at least once.",
        },
        frequency: {
            label: metricFriendlyName(name),
            tip: "Average number of times each person saw your ad.",
        },
        engagement: {
            label: metricFriendlyName(name),
            tip: "Total number of actions taken involving your ads.",
        },
        costPerClickAndView: {
            label: metricFriendlyName(name),
            tip: "Media spend divided by the sum of click conversions and view conversions.",
        },
        viewConversions: {
            label: metricFriendlyName(name),
            tip: "Total number of view conversions as reported by the platform.",
        },
        clicksConversions: {
            label: metricFriendlyName(name),
            tip: "Total number of click conversions as reported by the platform.",
        },
        "tiktok-cpl": {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        "tiktok-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in TikTok Ads.",
        },
        "tiktok-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by TikTok Ads.",
        },
        "tiktok-conversionRate": {
            label: metricFriendlyName(name),
            tip: "Total number of conversions from TikTok Ads divided by total number of ad clicks from TikTok Ads.",
        },
        "tiktok-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in TikTok Ads.",
        },
        "tiktok-videoViews": {
            label: metricFriendlyName(name),
            tip: "Total number of ad video views in TikTok Ads.",
        },
        "tiktok-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in TikTok Ads.",
        },
        "tiktok-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in TikTok Ads.",
        },
        "tiktok-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in TikTok Ads.",
        },
        "tiktok-averageWatchTime": {
            label: metricFriendlyName(name),
            tip: "Total number of ad average watch time in TikTok Ads.",
        },
        "tiktok-videoViewsAt50": {
            label: metricFriendlyName(name),
            tip: "Total number of ad video view at 50% in TikTok Ads.",
        },
        "tiktok-watched2sVideo": {
            label: metricFriendlyName(name),
            tip: "Total number of ad 2 second video views watch time in TikTok Ads.",
        },
        "tiktok-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in TikTok Ads.",
        },
        "tiktok-reach": {
            label: metricFriendlyName(name),
            tip: "Number of people who saw your ads at least once in TikTok Ads.",
        },
        "tiktok-frequency": {
            label: metricFriendlyName(name),
            tip: "Average number of times each person saw your ad in TikTok Ads.",
        },
        "tiktok-totalPageViewPage": {
            label: metricFriendlyName(name),
            tip: "Total number of page view events in TikTok Ads (Page event).",
        },
        "tiktok-costPerPageViewPage": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per page view) Media spend divided by the number of page view events in TikTok Ads (Page event).",
        },
        "tiktok-productViewsPage": {
            label: metricFriendlyName(name),
            tip: "The number of product details page browse events (Page event).",
        },
        "tiktok-costPerProductViewPage": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per product page view) Media spend divided by the number of product page view events in TikTok Ads (Page event).",
        },
        "tiktok-addToCartPage": {
            label: metricFriendlyName(name),
            tip: "Number of add to cart events that are attributed to TikTok Ads (Page event).",
        },
        "tiktok-costPerAddToCartPage": {
            label: metricFriendlyName(name),
            tip:
                "(Cost per Add to cart) Media spend divided by the number of add to cart events in TikTok Ads (Page event).",
        },
        "tiktok-completePaymentPage": {
            label: metricFriendlyName(name),
            tip: "The number of Purchase events that are attributed to TikTok Ads (Page event).",
        },
        "tiktok-costPerCompletePaymentPage": {
            label: metricFriendlyName(name),
            tip: "(Cost per Purchase) Media spend divided by the number of Purchase events in TikTok Ads (Page event).",
        },
        "tiktok-checkoutsPage": {
            label: metricFriendlyName(name),
            tip: "The number of initiate checkout events (Page event).",
        },
        "tiktok-costPerCheckoutPage": {
            label: metricFriendlyName(name),
            tip: "(Cost per Checkout) Media spend divided by the number of Checkout events in TikTok Ads (Page event).",
        },
        "tiktok-completePaymentValuePage": {
            label: metricFriendlyName(name),
            tip: "The total revenue in TikTok Ads (Page event).",
        },
        "tiktok-roasPage": {
            label: metricFriendlyName(name),
            tip: "<strong>(Return on ad spend)</strong> Total Complete Payment value divided by ad spend (Page event).",
        },
        "tiktok-salesLead": {
            label: metricFriendlyName(name),
            tip: "Leads.",
        },
        "tiktok-subscribePage": {
            label: metricFriendlyName(name),
            tip: "Subscribe (Page event).",
        },
        "tiktok-subscribes": {
            label: metricFriendlyName(name),
            tip: "Subscribes.",
        },
        "tiktok-pageViews": {
            label: metricFriendlyName(name),
            tip: "Total number of page view events in TikTok Ads.",
        },
        "tiktok-costPerPageView": {
            label: metricFriendlyName(name),
            tip: "(Cost per page view) Media spend divided by the number of page view events in TikTok Ads.",
        },
        "tiktok-addToCart": {
            label: metricFriendlyName(name),
            tip: "Number of add to cart events that are attributed to TikTok Ads.",
        },
        "tiktok-costPerAddToCart": {
            label: metricFriendlyName(name),
            tip: "(Cost per Add to cart) Media spend divided by the number of add to cart events in TikTok Ads.",
        },
        "tiktok-completePayments": {
            label: metricFriendlyName(name),
            tip: "The number of Purchase events that are attributed to TikTok Ads.",
        },
        "tiktok-costPerCompletePayment": {
            label: metricFriendlyName(name),
            tip: "(Cost per Purchase) Media spend divided by the number of Purchase events in TikTok Ads.",
        },
        "tiktok-checkouts": {
            label: metricFriendlyName(name),
            tip: "The number of initiate checkout events.",
        },
        "tiktok-costPerCheckout": {
            label: metricFriendlyName(name),
            tip: "(Cost per Checkout) Media spend divided by the number of Checkout events in TikTok Ads.",
        },
        "tiktok-productViews": {
            label: metricFriendlyName(name),
            tip: "The number of product details page browse events.",
        },
        "tiktok-costPerProductView": {
            label: metricFriendlyName(name),
            tip: "(Cost per product page view) Media spend divided by the number of product page view events.",
        },
        "tiktok-revenue": {
            label: metricFriendlyName(name),
            tip: "The total revenue in TikTok Ads.",
        },
        "pinterest-cpl": {
            label: metricFriendlyName(name),
            tip:
                "<strong>(Cost per acquisition)</strong> Media spend divided by the number of conversions from the connected ad platform.",
        },
        "pinterest-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in Pinterest Ads.",
        },
        "pinterest-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by Pinterest Ads.",
        },
        "pinterest-conversionRate": {
            label: metricFriendlyName(name),
            tip:
                "Total number of conversions from Pinterest Ads divided by total number of ad clicks from Pinterest Ads.",
        },
        "pinterest-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in Pinterest Ads.",
        },
        "pinterest-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in Pinterest Ads.",
        },
        "pinterest-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in Pinterest Ads.",
        },
        "pinterest-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in Pinterest Ads.",
        },
        "pinterest-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in Pinterest Ads.",
        },
        "pinterest-totalEngagements": {
            label: metricFriendlyName(name),
            tip: "Total Engagements of ad impressions in Pinterest Ads.",
        },
        "pinterest-earnedEngagements": {
            label: metricFriendlyName(name),
            tip: "Earned Engagements of ad impressions in Pinterest Ads.",
        },
        "pinterest-paidEngagements": {
            label: metricFriendlyName(name),
            tip: "Paid Engagements of ad impressions in Pinterest Ads.",
        },
        "pinterest-reach": {
            label: metricFriendlyName(name),
            tip: "Number of people who saw your ads at least once in Pinterest Ads.",
        },
        "pinterest-frequency": {
            label: metricFriendlyName(name),
            tip: "Average number of times each person saw your ad in Pinterest Ads.",
        },
        "pinterest-pageVisit": {
            label: metricFriendlyName(name),
            tip: "Total Conversions (Page Visit) in Pinterest Ads.",
        },
        "pinterest-costPerAddToCart": {
            label: metricFriendlyName(name),
            tip: "(Cost per Add to cart) Media spend divided by total number of add to cart in Pinterest Ads.",
        },
        "pinterest-addToCart": {
            label: metricFriendlyName(name),
            tip: "Number of add to cart events that are attributed to Pinterest Ads.",
        },
        "pinterest-checkout": {
            label: metricFriendlyName(name),
            tip: "Total web Checkout in Pinterest Ads.",
        },
        "pinterest-costPerCheckout": {
            label: metricFriendlyName(name),
            tip: "(Cost per Checkout) Media spend divided by total number of checkout in Pinterest Ads.",
        },
        "pinterest-totalOrderValue": {
            label: metricFriendlyName(name),
            tip: "Total order value (Checkout) in Pinterest Ads.",
        },
        "pinterest-totalROAS": {
            label: metricFriendlyName(name),
            tip: "Total ROAS (Checkout) in Pinterest Ads.",
        },
        "stackAdapt-cost": {
            label: metricFriendlyName(name),
            tip: "Total media spend in StackAdapt.",
        },
        "stackAdapt-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of ad impressions in StackAdapt.",
        },
        "stackAdapt-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of ad clicks in StackAdapt.",
        },
        "stackAdapt-conversions": {
            label: metricFriendlyName(name),
            tip: "Total conversions as reported by StackAdapt.",
        },
        "stackAdapt-engagements": {
            label: metricFriendlyName(name),
            tip: "Number of times 15 seconds was spent on the landing page as reported by StackAdapt.",
        },
        "stackAdapt-secondaryConversions": {
            label: metricFriendlyName(name),
            tip: "Total secondary conversions as reported by StackAdapt.",
        },
        "stackAdapt-conversionRevenue": {
            label: metricFriendlyName(name),
            tip: "Total conversion revenue as reported by StackAdapt.",
        },
        "stackAdapt-videoStarts": {
            label: metricFriendlyName(name),
            tip: "Total video starts as reported by StackAdapt.",
        },
        "stackAdapt-videoCompletions": {
            label: metricFriendlyName(name),
            tip: "Total video completions as reported by StackAdapt.",
        },
        "stackAdapt-uniqueImpressions": {
            label: metricFriendlyName(name),
            tip: "Total unique impressions as reported by StackAdapt.",
        },
        "stackAdapt-revenue": {
            label: metricFriendlyName(name),
            tip: "Total revenue as reported by StackAdapt.",
        },
        "stackAdapt-revenueFee": {
            label: metricFriendlyName(name),
            tip: "Total ad spend as reported by StackAdapt.",
        },
        "stackAdapt-conversionRate": {
            label: metricFriendlyName(name),
            tip: "Total number of conversions from StackAdapt divided by total number of ad clicks from StackAdapt.",
        },
        "stackAdapt-cpm": {
            label: metricFriendlyName(name),
            tip: "(Cost per mille) Cost per one-thousand ad impressions in StackAdapt.",
        },
        "stackAdapt-ctr": {
            label: metricFriendlyName(name),
            tip: "(Click-through rate) Number of ad clicks divided by number of impressions in StackAdapt.",
        },
        "stackAdapt-cpc": {
            label: metricFriendlyName(name),
            tip: "(Cost per click) Media spend divided by the number of ad clicks in StackAdapt.",
        },
        "stackAdapt-cpl": {
            label: metricFriendlyName(name),
            tip: "(Cost per conversion) Media spend divided by the number of conversions in StackAdapt.",
        },
        "stackAdapt-cpe": {
            label: metricFriendlyName(name),
            tip: "(Cost per engagement) Media spend divided by the number of engagements in StackAdapt.",
        },
        "stackAdapt-roas": {
            label: metricFriendlyName(name),
            tip: "Return on ad spend in StackAdapt.",
        },
        "stackAdapt-videoCompletionRate": {
            label: metricFriendlyName(name),
            tip: "Video completions per video start in StackAdapt.",
        },
        "maropost-ctr": {
            label: metricFriendlyName(name),
            tip: "Number of clicks divided by number of successful delivery.",
        },
        "maropost-ctor": {
            label: metricFriendlyName(name),
            tip: "Click To Open Rate.",
        },
        "maropost-sendAt": {
            label: metricFriendlyName(name),
            tip: "The date of which the campaign was sent on.",
        },
        "maropost-sent": {
            label: metricFriendlyName(name),
            tip: "Number of successful deliveries.",
        },
        "maropost-opened": {
            label: metricFriendlyName(name),
            tip: "Number of times the email was opened.",
        },
        "maropost-clicked": {
            label: metricFriendlyName(name),
            tip: "Number of times the contents in the email was clicked on.",
        },
        "maropost-openRate": {
            label: metricFriendlyName(name),
            tip: "Number of opens divided by number of successful delivery.",
        },
        "maropost-bounced": {
            label: metricFriendlyName(name),
            tip: "Number of unsuccessful email deliveries.",
        },
        "maropost-unsubscribed": {
            label: metricFriendlyName(name),
            tip: "Number of users who have opted to unsubscribe.",
        },
        "maropost-subscribers": {
            label: metricFriendlyName(name),
            tip: "Subscribers.",
        },
        "maropost-uniqueClicks": {
            label: metricFriendlyName(name),
            tip: "Unique Clicks.",
        },
        "maropost-uniqueOpens": {
            label: metricFriendlyName(name),
            tip: "Unique Opens.",
        },
        "klaviyo-ctor": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Click To Open Rate.",
        },
        "klaviyo-ctr": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of unique clicks divided by number of successful delivery.",
        },
        "klaviyo-sendAt": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "The date of which the campaign was sent on.",
        },
        "klaviyo-delivered": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of successful deliveries.",
        },
        "klaviyo-opened": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of times the email was opened.",
        },
        "klaviyo-clicked": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of times the contents in the email was clicked on.",
        },
        "klaviyo-openRate": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of unique opens divided by number of successful delivery.",
        },
        "klaviyo-bounced": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of unsuccessful email deliveries.",
        },
        "klaviyo-unsubscribed": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of users who have opted to unsubscribe.",
        },
        "klaviyo-uniqueClicks": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Unique Clicks.",
        },
        "klaviyo-uniqueOpens": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Unique Opens.",
        },
        "klaviyo-shopifyOrders": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Shopify Orders.",
        },
        "klaviyo-shopifyRevenue": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Shopify Revenue.",
        },
        "klaviyo-shopifyAov": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Shopify Average Order Value.",
        },
        "klaviyo-woocommerceOrders": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Woocommerce Orders.",
        },
        "klaviyo-woocommerceRevenue": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Woocommerce Revenue.",
        },
        "klaviyo-woocommerceAov": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Woocommerce Average Order Value.",
        },
        "klaviyo-magentoOrders": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Magento Orders.",
        },
        "klaviyo-magentoRevenue": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Magento Revenue.",
        },
        "klaviyo-magentoAov": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Magento Average Order Value.",
        },
        "klaviyo-totalOrders": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Total Orders.",
        },
        "klaviyo-totalRevenue": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Total Revenue.",
        },
        "klaviyo-totalAov": {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Total Average Order Value.",
        },
        "klaviyo-flowCtor": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Click To Open Rate.",
        },
        "klaviyo-flowCtr": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of unique clicks divided by number of successful delivery.",
        },
        "klaviyo-flowDelivered": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of successful deliveries.",
        },
        "klaviyo-flowOpened": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of times the email was opened.",
        },
        "klaviyo-flowClicked": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of times the contents in the email was clicked on.",
        },
        "klaviyo-flowOpenRate": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of unique opens divided by number of successful delivery.",
        },
        "klaviyo-flowBounced": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of unsuccessful email deliveries.",
        },
        "klaviyo-flowUnsubscribed": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Number of users who have opted to unsubscribe.",
        },
        "klaviyo-flowUniqueClicks": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Unique Clicks.",
        },
        "klaviyo-flowUniqueOpens": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Unique Opens.",
        },
        "klaviyo-flowShopifyOrders": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Shopify Orders.",
        },
        "klaviyo-flowShopifyRevenue": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Shopify Revenue.",
        },
        "klaviyo-flowShopifyAov": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Shopify Average Order Value.",
        },
        "klaviyo-flowWoocommerceOrders": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Woocommerce Orders.",
        },
        "klaviyo-flowWoocommerceRevenue": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Woocommerce Revenue.",
        },
        "klaviyo-flowWoocommerceAov": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Woocommerce Average Order Value.",
        },
        "klaviyo-flowMagentoOrders": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Magento Orders.",
        },
        "klaviyo-flowMagentoRevenue": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Magento Revenue.",
        },
        "klaviyo-flowMagentoAov": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Magento Average Order Value.",
        },
        "klaviyo-flowTotalOrders": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Total Orders.",
        },
        "klaviyo-flowTotalRevenue": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Total Revenue.",
        },
        "klaviyo-flowTotalAov": {
            label: `Flows-${metricFriendlyName(name)}`,
            tip: "Magento Average Order Value.",
        },
        "klaviyo-listSubscribed": {
            label: `Lists-${metricFriendlyName(name)}`,
            tip: "New subscriptions to lists.",
        },
        "klaviyo-listUnsubscribed": {
            label: `Lists-${metricFriendlyName(name)}`,
            tip: "Unsubscribes from lists.",
        },
        "searchConsole-impressions": {
            label: metricFriendlyName(name),
            tip: "Total number of page impressions on Google. Based on data from Google Search Console.",
        },
        "searchConsole-clicks": {
            label: metricFriendlyName(name),
            tip: "Total number of organic search clicks on Google. Based on data from Google Search Console.",
        },
        "searchConsole-position": {
            label: metricFriendlyName(name),
            tip: "Average position based on data from Google Search Console",
        },
        "searchConsole-ctr": {
            label: metricFriendlyName(name),
            tip: "Total number of organic search clicks divided by total number of page impressions.",
        },
        ctor: {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Click To Open Rate.",
        },
        delivered: {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of successful deliveries.",
        },
        opened: {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of times the email was opened.",
        },
        openRate: {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of unique opens divided by number of successful delivery.",
        },
        clicked: {
            label: `Campaigns-${metricFriendlyName(name)}`,
            tip: "Number of times the contents in the email was clicked on.",
        },
    };

    if (platform === "shopify" && !name.includes("shopify-")) {
        name = `shopify-${name}`;
        return name && info[name] ? info[name] : {};
    }

    if (platform && platform.startsWith("campaignGroup")) {
        const [_, metricStr] = name.includes("-") ? name.split("-") : ["", name];
        return info[`campaignGroup-${metricStr}`]
            ? info[`campaignGroup-${metricStr}`]
            : info[metricStr]
            ? info[metricStr]
            : {};
    }

    if (platform === "facebook" && !info[name]) {
        return {
            label: metricFriendlyName(name, platform),
            tip: "Meta Ads conversion",
        };
    }

    let metricName = "";
    if (name && name.includes("-")) {
        metricName = name.split("-")[1];
    }

    if (!name) {
        return {};
    }

    if (info[name]) {
        return info[name];
    }

    if (info[metricName]) {
        return info[metricName];
    }

    return {};
};

export const getTrend = ({ number, metric }) => {
    if (!metric) {
        return "neutral";
    }

    let metricKey = metric;
    if (metric.includes("-")) {
        const [platformKey, ...metricArray] = metric.split("-");
        metricKey = metricArray.join("-");
    }

    let performance;

    if (metric.startsWith("custom-")) {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const { [metric]: { performance: per = "lowerIsBetter" } = {} } = customMetrics;
        performance = per;
    }

    const isLowerBetter = performance === "lowerIsBetter" ? true : lowerIsBetterMetrics.includes(metricKey);

    return number === 0
        ? "neutral"
        : isLowerBetter
        ? number > 0
            ? "negative"
            : "positive"
        : number > 0
        ? "positive"
        : "negative";
};

export const getTrendRgbaColor = (color) => {
    switch (color) {
        case "positive":
            return "0, 177, 120";
        case "negative":
            return "251, 20, 39";
        default:
            return "204, 209, 209";
    }
};

export const GrowthNumber = ({ className, number, metric, styles, dataTip = null, tipPosition = "right", tipId }) => {
    if (number === undefined || !isFinite(number) || isNaN(number)) return null;
    const trend = getTrend({ number, metric });

    return (
        <div
            className={classNames(
                trend === "neutral"
                    ? style.zeroGrowth
                    : trend === "positive"
                    ? style.positiveGrowth
                    : style.negativeGrowth,
                { [className]: className },
            )}
            data-tip={dataTip}
            data-for={tipId}
            style={styles}
        >
            {number > 0 ? "+" : ""}
            {toPrettyNumber(number, "percent")}
            <TooltipDark
                id={tipId}
                proximity={tipPosition === "left" ? 2 : tipPosition === "top" ? 0 : -2}
                position={tipPosition}
            />
        </div>
    );
};

export const industryOptions = [
    {
        value: "arts and entertainment",
        label: "Arts and Entertainment",
    },
    {
        value: "automotive",
        label: "Automotive",
    },
    {
        value: "beauty and fitness",
        label: "Beauty and Fitness",
    },
    {
        value: "books and literature",
        label: "Books and Literature",
    },
    {
        value: "business and industrial markets",
        label: "Business and Industrial Markets",
    },
    {
        value: "computers and electronics",
        label: "Computers and Electronics",
    },
    {
        value: "finance",
        label: "Finance",
    },
    {
        value: "food and drink",
        label: "Food and Drink",
    },
    {
        value: "games",
        label: "Games",
    },
    {
        value: "healthcare",
        label: "Healthcare",
    },
    {
        value: "hobbies and leisure",
        label: "Hobbies and Leisure",
    },
    {
        value: "home and garden",
        label: "Home and Garden",
    },
    {
        value: "internet and telecom",
        label: "Internet and Telecom",
    },
    {
        value: "jobs and education",
        label: "Jobs and Education",
    },
    {
        value: "law and government",
        label: "Law and Government",
    },
    {
        value: "news",
        label: "News",
    },
    {
        value: "online communities",
        label: "Online Communities",
    },
    {
        value: "people and society",
        label: "People and Society",
    },
    {
        value: "pets and animals",
        label: "Pets and Animals",
    },
    {
        value: "real estate",
        label: "Real Estate",
    },
    {
        value: "reference",
        label: "Reference",
    },
    {
        value: "science",
        label: "Science",
    },
    {
        value: "shopping",
        label: "Shopping",
    },
    {
        value: "sports",
        label: "Sports",
    },
    {
        value: "travel",
        label: "Travel",
    },
];

export const businessTypeOptions = [
    {
        value: "ecommerce",
        label: "Ecommerce",
    },
    {
        value: "b2b",
        label: "B2B",
    },
    {
        value: "b2c",
        label: "B2C",
    },
    {
        value: "other",
        label: "Other",
    },
];

export const listOfCountries = countryList()
    .getLabels()
    .map((country) => ({
        value: country,
        label: country,
    }));

export const getPlatformMainKey = (platformKey) => {
    if (!platformKey) return;
    return platformKey.includes("analyticsV4")
        ? "analyticsV4"
        : platformKey.includes("analytics")
        ? "analytics"
        : platformKey.startsWith("campaignGroup")
        ? "campaignGroup"
        : platformKey.includes("adwords")
        ? "adwords"
        : platformKey.includes("bigQuery")
        ? "bigQuery"
        : platformKey;
};

export const platformSortOrder = {
    adwords: 1,
    facebook: 2,
    linkedin: 3,
    bing: 4,
    shopify: 5,
    custom: 6,
    campaignGroup: 7,
    notes: 8,
};

export const isGoalCompletion = (goal) =>
    goal && goal.includes("goal") && goal.includes("Completion") && !goal.includes("goalCompletion");

export const platformGoalsSort = (a = {}, b = {}, path) => {
    const { id: aId = "" } = a;
    const { id: bId = "" } = b;
    const aVal = get(a, path).toLowerCase();
    const bVal = get(b, path).toLowerCase();
    const aPlatform = get(a, "platformKey").toLowerCase();
    const bPlatform = get(b, "platformKey").toLowerCase();
    const aPlatformChannel = get(a, "platformChannelName").toLowerCase();
    const bPlatformChannel = get(b, "platformChannelName").toLowerCase();

    const aPlatformSortOrder = platformSortOrder[aPlatform] || 0;
    const bPlatformSortOrder = platformSortOrder[bPlatform] || 0;

    const aIsGoalCompletion = isGoalCompletion(aId);
    const bIsGoalCompletion = isGoalCompletion(bId);

    // Sort by defined platform order
    if (aPlatformSortOrder > bPlatformSortOrder) {
        return 1;
    }

    if (aPlatformSortOrder < bPlatformSortOrder) {
        return -1;
    }

    // Sort by channel name
    if (aPlatformChannel > bPlatformChannel) {
        return 1;
    }

    if (aPlatformChannel < bPlatformChannel) {
        return -1;
    }

    //Sort by metrics / goal completions
    if (bIsGoalCompletion && !aIsGoalCompletion) {
        return -1;
    }

    if (!bIsGoalCompletion && aIsGoalCompletion) {
        return 1;
    }

    // Sort by metric name
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
};

export const platformGoalsSortByMetricId = (a, b) => {
    const [platformKeyA, ...metricKeyA] = a.split("-");
    const [platformKeyB, ...metricKeyB] = b.split("-");

    const aVal = metricFriendlyName(metricKeyA.join("-"), platformKeyA);
    const bVal = metricFriendlyName(metricKeyB.join("-"), platformKeyB);
    const aPlatform = getPlatformMainKey(platformKeyA);
    const bPlatform = getPlatformMainKey(platformKeyB);
    const aPlatformChannel = channelFriendlyName(platformKeyA);
    const bPlatformChannel = channelFriendlyName(platformKeyB);

    const aPlatformSortOrder = platformSortOrder[aPlatform] || 0;
    const bPlatformSortOrder = platformSortOrder[bPlatform] || 0;

    const aIsGoalCompletion = isGoalCompletion(a);
    const bIsGoalCompletion = isGoalCompletion(b);

    // Sort by defined platform order
    if (aPlatformSortOrder > bPlatformSortOrder) {
        return 1;
    }

    if (aPlatformSortOrder < bPlatformSortOrder) {
        return -1;
    }

    // Sort by channel name
    if (aPlatformChannel > bPlatformChannel) {
        return 1;
    }

    if (aPlatformChannel < bPlatformChannel) {
        return -1;
    }

    //Sort by metrics / goal completions
    if (bIsGoalCompletion && !aIsGoalCompletion) {
        return -1;
    }

    if (!bIsGoalCompletion && aIsGoalCompletion) {
        return 1;
    }

    // Sort by metric name
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
};

export const benchmarkFilter = ({ number, metric }) => {
    const metricKey = metric.includes("-") ? metric.split("-")[1] : metric;
    let performance;
    if (metric.includes("custom-")) {
        const customMetrics = makeSelectCustomMetrics()(store.getState());
        const { [metric]: { performance: per = "lowerIsBetter" } = {} } = customMetrics;
        performance = per;
    }

    const isLowerBetter = metric.includes("custom-")
        ? performance === "lowerIsBetter"
        : lowerIsBetterMetrics.includes(metricKey);

    return isLowerBetter ? (number > 0 ? false : true) : number > 0 ? true : false;
};

export const allPlatforms = [
    "shopify",
    "klaviyo",
    "moropost",
    "facebook",
    "adwords",
    "linkedin",
    "bing",
    "pinterest",
    "tiktok",
    "analyticsV4",
];

export const getMetricPlatform = (metric = "") => {
    const isBigQuery =
        metric.startsWith(bigQueryHelpers.BIGQUERY_METRIC_PREFIX) && bigQueryHelpers.isBigQueryMetric(metric);
    const path = isBigQuery ? bigQueryHelpers.BIGQUERY_METRIC_SPLITED_BY : "-";

    if (isBigQuery) {
        const [platformKey, integrationId] = metric.split(path);

        return [platformKey, integrationId].join(path);
    }

    return metric.split(path)[0];
};

export const getMetricKey = (metric = "") => {
    const isBigQuery =
        metric.startsWith(bigQueryHelpers.BIGQUERY_METRIC_PREFIX) && bigQueryHelpers.isBigQueryMetric(metric);
    const path = isBigQuery ? bigQueryHelpers.BIGQUERY_METRIC_SPLITED_BY : "-";
    const [, ...metricTemp] = metric.split(path);

    return metricTemp.join("-");
};

const INVALID_PLATFORMS = ["analytics"];

export const filterMetrics = (metrics = []) => {
    return metrics.filter((metric = "") => {
        const platformKey = getMetricPlatform(metric);
        const platformMainKey = getPlatformMainKey(platformKey);

        return !INVALID_PLATFORMS.includes(platformMainKey);
    });
};
