import React from "react";

export default () => (
    <svg fill="#333333" xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
        <path
            d="M14.8804853,6.46512901 C14.7603602,6.24386708 14.5364927,6.10723312 14.2939921,6.10723312 L11.0942712,6.10723312 L11.0942712,0.709198615 C11.0942712,0.389645336 10.8907921,0.109569345 10.597433,0.0251777773 C10.3040288,-0.0590719554 9.99316175,0.0730233046 9.83820079,0.347993252 L5.06321601,8.82232495 C4.93971532,9.04155392 4.93759997,9.31349806 5.05768008,9.53480726 C5.1778052,9.75606919 5.4016727,9.89270315 5.64417332,9.89270315 L8.84389425,9.89270315 L8.84389425,15.2907377 C8.84389425,15.6102909 9.04732833,15.8903669 9.34073247,15.9747585 C9.63481173,16.0592919 9.94531873,15.9264402 10.0999646,15.6519903 L14.8749494,7.17761132 C14.9984501,6.95838235 15.0005655,6.68643821 14.8804853,6.46512901 Z"
            transform="translate(-4.97)"
        />
    </svg>
);
