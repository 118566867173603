const GET_ALERTS = "GET_ALERTS";
const TOGGLE_ALERT_VISIBILITY = "TOGGLE_ALERT_VISIBILITY";
const DISMISS_ALERT_REQUEST = "DISMISS_ALERT_REQUEST";
const UPDATE_ALERTS_FILTER = "UPDATE_ALERTS_FILTER";
const UPDATE_ALERTS_FILTERS = "UPDATE_ALERTS_FILTERS";
const UPDATE_DISMISSED_ALERTS_FILTER = "UPDATE_DISMISSED_ALERTS_FILTER";

export default {
    GET_ALERTS,
    TOGGLE_ALERT_VISIBILITY,
    DISMISS_ALERT_REQUEST,
    UPDATE_ALERTS_FILTER,
    UPDATE_ALERTS_FILTERS,
    UPDATE_DISMISSED_ALERTS_FILTER,
};
