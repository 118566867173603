const CREATE_VIEW = "CREATE_VIEW";
const ACCOUNT_FETCH_VIEW = "ACCOUNT_FETCH_VIEW";
const REMOVE_VIEW = "REMOVE_VIEW";
const GET_SITES_REJECTED = "GET_SITES_REJECTED";
const GET_SITES_FUFILLED = "GET_SITES_FUFILLED";
const GET_SITES_PENDING = "GET_SITES_PENDING";
const ACCOUNT_FETCH_DATA = "ACCOUNT_FETCH_DATA";
const UPDATE_NAME = "UPDATE_NAME";
const REMOVE_ACCOUNT_LOGO = "REMOVE_ACCOUNT_LOGO";
const UPDATE_ACCOUNT_LOGO = "UPDATE_ACCOUNT_LOGO";
const TOGGLE_PINNED_VIEW = "TOGGLE_PINNED_VIEW";
const UPDATE_TAGS = "UPDATE_TAGS";
const TOGGLE_METRICS_MANAGER = "TOGGLE_METRICS_MANAGER";
const UPDATE_OVERVIEW_SEARCH = "UPDATE_OVERVIEW_SEARCH";
const UPDATE_OVERVIEW_SORT_BY_DATA_TYPE = "UPDATE_OVERVIEW_SORT_BY_DATA_TYPE";
const UPDATE_OVERVIEW_UI = "UPDATE_OVERVIEW_UI";
const TOGGLE_EXPORT_MANAGER = "TOGGLE_EXPORT_MANAGER";
const LOAD_ANALYTICS_CHANNELS = "LOAD_ANALYTICS_CHANNELS";
const LOAD_ADWORDS_CHANNELS = "LOAD_ADWORDS_CHANNELS";
const UPDATE_OVERVIEW_TAGS_AVAILABLE = "UPDATE_OVERVIEW_TAGS_AVAILABLE";
const ACCOUNT_UPDATE_BUDGET = "ACCOUNT_UPDATE_BUDGET";
const ACCOUNT_UPDATE_GOAL = "ACCOUNT_UPDATE_GOAL";
const ACCOUNT_UPDATE_ALERT = "ACCOUNT_UPDATE_ALERT";
const SET_SHOW_ADD_ACCOUNT_MODAL = "SET_SHOW_ADD_ACCOUNT_MODAL";
const ACCOUNT_UPDATE_INTEGRATIONS = "ACCOUNT_UPDATE_INTEGRATIONS";
const ACCOUNT_FETCH_METRICS = "ACCOUNT_FETCH_METRICS";
const CLEAR_ACCOUNT_DATA = "CLEAR_ACCOUNT_DATA";
const CLEAR_METRICS_DATA = "CLEAR_METRICS_DATA";
const ACCOUNT_FETCH_OPPORTUNITIES = "ACCOUNT_FETCH_OPPORTUNITIES";
const ACCOUNT_FETCH_TOP_WINS = "ACCOUNT_FETCH_TOP_WINS";

export default {
    TOGGLE_PINNED_VIEW,
    ACCOUNT_FETCH_DATA,
    CREATE_VIEW,
    ACCOUNT_FETCH_VIEW,
    REMOVE_VIEW,
    GET_SITES_REJECTED,
    GET_SITES_FUFILLED,
    GET_SITES_PENDING,
    UPDATE_NAME,
    REMOVE_ACCOUNT_LOGO,
    UPDATE_ACCOUNT_LOGO,
    UPDATE_TAGS,
    TOGGLE_METRICS_MANAGER,
    UPDATE_OVERVIEW_SEARCH,
    UPDATE_OVERVIEW_SORT_BY_DATA_TYPE,
    UPDATE_OVERVIEW_UI,
    TOGGLE_EXPORT_MANAGER,
    LOAD_ANALYTICS_CHANNELS,
    LOAD_ADWORDS_CHANNELS,
    UPDATE_OVERVIEW_TAGS_AVAILABLE,
    ACCOUNT_UPDATE_BUDGET,
    ACCOUNT_UPDATE_GOAL,
    ACCOUNT_UPDATE_ALERT,
    SET_SHOW_ADD_ACCOUNT_MODAL,
    ACCOUNT_UPDATE_INTEGRATIONS,
    ACCOUNT_FETCH_METRICS,
    CLEAR_ACCOUNT_DATA,
    CLEAR_METRICS_DATA,
    ACCOUNT_FETCH_OPPORTUNITIES,
    ACCOUNT_FETCH_TOP_WINS,
};
