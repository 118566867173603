import React, { useCallback, useState } from "react";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { actions } from "features/ControlPanel/ducks";
import isEqual from "lodash/isEqual";

import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings.svg";
import DownloadIcon from "assets/images/icons/JSX/Icon-Download";
import { mixpanel } from "components/Mixpanel";

import OptionsIcon from "assets/images/Dashboard/JSX/HorizontalMore";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import classNames from "classnames";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import { TooltipDark } from "components/Tooltip";
import { ExportBudgetData } from "features/ControlPanel/ManageExport";

const { toggleMetricsManager, toggleExportManager } = actions;

const AdditionalMenu = React.memo(({ month, viewType }) => {
    const [menuOpen, toggleMenu] = useState(false);
    const outsideClick = useHandleOutsideClickHook(() => {
        if (menuOpen) {
            toggleMenu(false);
        }
    }, [menuOpen]);

    const dispatch = useDispatch();
    const handleMetricsClick = useCallback(() => {
        mixpanel.track("Overview - Edit Metrics");
        dispatch(toggleMetricsManager());
    }, [dispatch]);

    const handleExportClick = useCallback(() => {
        mixpanel.track("Overview - Edit Export");
        dispatch(toggleExportManager());
    }, [dispatch]);

    return (
        <div className={style.options} ref={outsideClick}>
            <div
                data-tip={"Export, Edit Metrics"}
                data-for={"export-menu"}
                className={classNames(style.menu, { [style.open]: menuOpen })}
                onClick={() => toggleMenu(!menuOpen)}
            >
                <OptionsIcon />
            </div>
            <Container className={style.optionsDropdown} isOpen={menuOpen} dropdownWidth="auto" anchor="right">
                {viewType === "metrics" && (
                    <>
                        {" "}
                        <SimpleListItem
                            className={style.option}
                            onClick={handleExportClick}
                            label={
                                <div className={style.menuLabel}>
                                    <DownloadIcon />
                                    Export Metrics
                                </div>
                            }
                        />
                        <SimpleListItem
                            className={style.option}
                            onClick={handleMetricsClick}
                            label={
                                <div className={style.menuLabel}>
                                    <SettingsIcon /> Edit Metrics Displayed
                                </div>
                            }
                        />
                    </>
                )}
                {viewType === "budget" && <ExportBudgetData month={month} />}
            </Container>
            <TooltipDark id={"export-menu"} proximity={6} position="top" />
        </div>
    );
}, isEqual);

export default AdditionalMenu;
