import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="#333333">
        <path
            d="M16.9570531,19 C17.5327598,19 18,19.42 18,19.9375 L18,22.8125 C18,24.01875 16.9083823,25 15.5664573,25 L2.43354269,25 C1.09161772,25 0,24.01875 0,22.8125 L0,19.9375 C0,19.42 0.467240197,19 1.04294687,19 C1.61865354,19 2,19.42 2,19.9375 L2,22.5 C2,22.6725 2.30809778,23 2.5,23 L15.5,23 C15.6919022,23 15.9918111,22.6725 15.9918111,22.5 L15.9918111,19.9375 C15.9918111,19.42 16.3813465,19 16.9570531,19 Z M9,7 C9.552,7 10,7.43477238 10,7.97047406 L10,16.586 L12.2940073,14.2927606 C12.684291,13.9024131 13.3170035,13.9024131 13.7072872,14.2927606 C14.0975709,14.6831081 14.0975709,15.315924 13.7072872,15.7062714 L9.70730596,19.7069063 C9.51016607,19.9027462 9.25441702,20 8.99866797,20 C8.74291893,20 8.48716988,19.9027462 8.29269404,19.7069063 L4.29271278,15.7062714 C3.90242907,15.315924 3.90242907,14.6831081 4.29271278,14.2927606 C4.68299648,13.9024131 5.31570896,13.9024131 5.70599266,14.2927606 L8,16.588 L8,7.97047406 C8,7.43477238 8.448,7 9,7 Z"
            transform="translate(0 -7)"
        />
    </svg>
);
