import { MorphioAPI } from "lib/api";

export const sendToSlackWebhook = ({ url = "", text = "" }) =>
    MorphioAPI({
        url: "slack/webhook",
        method: "POST",
        data: {
            url,
            text,
        },
    });
