import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import {MAROPOST_CAMPAIGN_REPORT} from "./types";
import {handle} from "redux-pack";

const defaultState = {
    report: [],
    maropostLoading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [MAROPOST_CAMPAIGN_REPORT]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    maropostLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    maropostLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    report: action.payload,
                }),
            });
        },
    })
);
