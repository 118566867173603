import React, { useState, useEffect } from "react";
import MainPanel from "components/MainPanel";
import { SectionHeading, SectionHeadingInfoBar } from "components/SectionHeading";
import style from "./style.module.scss";
import moment from "moment";
import DidYouKnow from "components/Loading/DidYouKnow";
import BudgetPlannerTable from "./BudgetPlannerTable";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";
import { ReactComponent as Insights } from "assets/images/icons/Icon-Suggestions.svg";
import { getTotalRow } from "./BudgetPlannerTable/helpers";
import { getConfigureBudgetPlannerText, getTotalValues } from "./helpers";
import BudgetConfigure from "./BudgetConfigure/container";
import MainFilter from "./MainFilter";
import { isEmpty } from "lodash";
import { AdPlatformZeroState } from "components/PlatformNeeded";
import NewIntegrationAction from "components/NewIntegrationAction";

export default ({
    accountId,
    numberOfMonths,
    metric,
    handleSubmit,
    budgetType,
    budgetPlannerData,
    platforms = {},
    budgets = {},
    availablePlatfoms,
    getBudgetPlannerData,
    isLoading,
    months,
    change,
    pristine,
    metricsAverages = {},
    initPlatforms,
    initBudgets,
    initMetricsAverages,
    hasIntegrations,
}) => {
    const [isBudgetSettings, setIsBudgetSettings] = useState(true);
    const [platformFilter, setPlatformFilter] = useState({});
    const [showOnly, setShowOnly] = useState([]);
    const [monthFilter, setMonthFilter] = useState([]);

    useEffect(() => {
        // making sure all the values are integers
        const newMetricsAverages = Object.keys(metricsAverages).reduce((acc, key) => {
            acc[key] = parseInt(metricsAverages[key]);
            return acc;
        }, {});
        getBudgetPlannerData({
            accountId,
            budgets,
            platforms,
            numberOfMonths,
            metric,
            metricsAverages: newMetricsAverages,
        });
    }, [accountId]);

    useEffect(() => {
        change(`platforms`, platforms);
    }, [platforms]);

    useEffect(() => {
        change(`budgets`, budgets);
    }, [budgets]);

    useEffect(() => {
        change(`metricsAverages`, metricsAverages);
    }, [metricsAverages]);

    useEffect(() => {
        change(`platforms`, initPlatforms);
        change(`budgets`, initBudgets);
        change(`metricsAverages`, initMetricsAverages);
    }, [isLoading]);

    const secondaryMetric = metric === "roas" ? "revenue" : "conversions";
    const totalRow = getTotalRow({ metric, secondaryMetric, months, budgetPlannerData, showOnly });
    const totalValues = getTotalValues({ totalRow, metric });

    // filter out active platforms
    const activePlatforms = Object.keys(platforms).reduce((acc, platform) => {
        if (platforms[platform].active) {
            acc[platform] = platforms[platform];
        }
        return acc;
    }, {});

    const platformsFiltered = isEmpty(platformFilter)
        ? activePlatforms
        : {
              [platformFilter.value]: platforms[platformFilter.value],
          };

    const monthsFiltered = isEmpty(monthFilter) ? months : monthFilter;

    if (!hasIntegrations) return <AdPlatformZeroState accountId={accountId} hasBorder />;

    return (
        <MainPanel fullWidthAndPadding>
            <div className={style.headingWrapper}>
                <SectionHeading
                    className={style.sectionHeading}
                    title="Budget Planner"
                    endTag={"beta"}
                    subtitle={() => (
                        <SectionHeadingInfoBar
                            title="Budget Planner"
                            generated="Daily"
                            updated={moment()}
                            updatedTime={moment()
                                .startOf("day")
                                .add(1, "minute")}
                            updatedShowTooltip={false}
                            description="See how your performance compares to others in your industry across all platforms."
                            video={{
                                title: "Watch Video",
                                id: "#",
                            }}
                        />
                    )}
                />
                <NewIntegrationAction />
            </div>
            {isLoading ? (
                <DidYouKnow />
            ) : (
                <>
                    {isBudgetSettings ? (
                        <BudgetConfigure
                            budgetType={budgetType}
                            onBack={() => setIsBudgetSettings(false)}
                            handleSubmit={handleSubmit}
                            months={months}
                            platforms={platforms}
                            availablePlatfoms={availablePlatfoms}
                            pristine={pristine}
                        />
                    ) : (
                        <div>
                            <MainFilter
                                platformFilter={platformFilter}
                                setPlatformFilter={setPlatformFilter}
                                showOnly={showOnly}
                                setShowOnly={setShowOnly}
                                monthFilter={monthFilter}
                                setMonthFilter={setMonthFilter}
                                months={months}
                                platforms={activePlatforms}
                                metric={metric}
                                secondaryMetric={secondaryMetric}
                            />
                            <div className={style.configureBudget} onClick={() => setIsBudgetSettings(true)}>
                                <div className={style.configureBudgetIcon}>
                                    <Insights />
                                </div>
                                <span>
                                    {getConfigureBudgetPlannerText({
                                        totalValues,
                                        numberOfMonths,
                                        secondaryMetric,
                                        metric,
                                    })}
                                </span>
                                <div className={style.configureBudgetIconEdit}>
                                    <EditIcon />
                                </div>
                                <span>Configure Budget Planner</span>
                            </div>
                            <BudgetPlannerTable
                                months={monthsFiltered}
                                metric={metric}
                                secondaryMetric={secondaryMetric}
                                budgetPlannerData={budgetPlannerData}
                                platforms={platformsFiltered}
                                totalRow={totalRow}
                                showOnly={showOnly}
                                metricsAverages={metricsAverages}
                            />
                        </div>
                    )}
                </>
            )}
        </MainPanel>
    );
};
