import React, { useState } from "react";
import styles from "./style.module.scss";
import sharedStyles from "features/PlanOptions/style.module.scss";
import Button from "components/Button";
import { mixpanel } from "components/Mixpanel";
import ThreeDots from "components/Loading/ThreeDots";

export default ({ toggleDisplay, toggleOpen, downgradeToFree, userId, daysLeftInTrial, forcePlanOptions, isTrial }) => {
    const [processing, updateProcessing] = useState(false);

    return (
        <div>
            <div className={`${sharedStyles.heading} ${styles.downgradeHeading}`}>
                <h2>
                    Do you <strong>REALLY</strong> want to downgrade? 🤔
                </h2>
                <p>You will no longer have access to Pro features.</p>
            </div>
            <div className={styles.content}>
                {processing ? (
                    <ThreeDots color="#6A80A7" />
                ) : (
                    <>
                        <Button
                            onClick={async () => {
                                updateProcessing(true);

                                const datetime = new Date().toISOString();

                                await downgradeToFree(datetime, userId);

                                mixpanel.track("Plan Options - Downgrade Confirm", {
                                    Location: "Downgrade",
                                    "In Freemium": forcePlanOptions,
                                    "In Trial": isTrial,
                                });

                                toggleOpen(false);
                                updateProcessing(false);
                                toggleDisplay("options");
                            }}
                        >
                            Yes, I'm Sure
                        </Button>
                        <Button hollow onClick={() => toggleDisplay("options")}>
                            Cancel
                        </Button>
                        <span
                            className={styles.customPricing}
                            onClick={() => {
                                mixpanel.track("Plan Options - Book Meeting", {
                                    Location: "Downgrade",
                                    "In Freemium": forcePlanOptions,
                                    "In Trial": isTrial,
                                });
                                toggleDisplay("meeting");
                            }}
                        >
                            Actually, I'd like to extend my free trial.
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};
