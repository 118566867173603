import React from "react";
import style from "./style.module.scss";
import { TooltipDark } from "components/Tooltip";

export default ({
    tag,
    bgColor = "#CF1382",
    tip = "",
    tipData: { id = "", position = "top", proximity = 0, arrowPosition = "center" } = {},
    style: styles,
}) => {
    return (
        <>
            <div
                className={style.titleTag}
                style={{ backgroundColor: bgColor, ...styles }}
                data-tip={tip}
                data-for={id || null}
                data-html
            >
                {tag}
            </div>
            {tip && (
                <TooltipDark id={id || null} position={position} proximity={proximity} arrowPosition={arrowPosition} />
            )}
        </>
    );
};
