const GET_SHAREABLE_LINK = "GET_SHAREABLE_LINK";
const DECODE_TOKEN = "DECODE_TOKEN";
const GET_SHAREABLE_PDF = "GET_SHAREABLE_PDF";
const GET_ALL_SHAREABLE_LINK = "GET_ALL_SHAREABLE_LINK";
const REMOVE_SHAREABLE_LINK = "REMOVE_SHAREABLE_LINK";
const UPDATE_SHAREABLE_LINK = "UPDATE_SHAREABLE_LINK";

export default {
    GET_SHAREABLE_LINK,
    DECODE_TOKEN,
    GET_SHAREABLE_PDF,
    GET_ALL_SHAREABLE_LINK,
    REMOVE_SHAREABLE_LINK,
    UPDATE_SHAREABLE_LINK,
};
