import types from "./types";

/**
 * Analytics
 */
const getConversionTypesPending = ({ pending } = {}) => ({
    type: types.GET_CONVERSION_TYPES_PENDING,
    pending,
});

const getConversionTypesFulfilled = ({ payload } = {}) => ({
    type: types.GET_CONVERSION_TYPES_FULFILLED,
    payload,
});

const setInitialConversionTypes = ({ payload } = {}) => ({
    type: types.SET_INITIAL_CONVERSION_TYPES,
    payload,
});

const getConversionTypesRejected = ({ error, payload } = {}) => ({
    type: types.GET_CONVERSION_TYPES_REJECTED,
    error,
    payload,
});

const setSelectedAnalyticsIntegration = (integration) => ({
    type: types.SETTINGS_UPDATE_ANALYTICS_INTEGRATION,
    integration,
});

/**
 * Adwords
 */
const setSelectedAdwordsIntegration = (integration) => ({
    type: types.SETTINGS_UPDATE_ADWORDS_INTEGRATION,
    integration,
});

const setAdwordsBreakdown = (conversions) => ({
    type: types.SETTINGS_UPDATE_ADWORDS_BREAKDOWN,
    payload: conversions,
});

const setInitialAdwordsBreakdown = (payload) => ({
    type: types.SET_INITIAL_ADWORDS_BREAKDOWN,
    payload,
});

/**
 * AnalyticsV4
 */
const setSelectedAnalyticsV4Integration = (integration) => ({
    type: types.SETTINGS_UPDATE_ANALYTICSV4_INTEGRATION,
    integration,
});

const setAnalyticsV4Breakdown = (conversions) => ({
    type: types.SETTINGS_UPDATE_ANALYTICSV4_BREAKDOWN,
    payload: conversions,
});

const setInitialAnalyticsV4Breakdown = (payload) => ({
    type: types.SET_INITIAL_ANALYTICSV4_BREAKDOWN,
    payload,
});

const analyticsV4BreakDownLoading = (isLoading) => ({
    type: types.SETTINGS_ANALYTICSV4_BREAKDOWN_LOADING,
    isLoading,
});

/**
 * Facebook
 */
const setInitialFacebookBreakdown = (payload) => ({
    type: types.SET_INITIAL_FACEBOOK_BREAKDOWN,
    payload,
});

const setFacebookBreakdown = (conversions) => ({
    type: types.SETTINGS_UPDATE_FACEBOOK_BREAKDOWN,
    payload: conversions,
});

const setFacebookAttribution = (attribution) => ({
    type: types.SET_FACEBOOK_ATTRIBUTION,
    payload: attribution,
});

const setInitialFacebookAttribution = (attribution) => ({
    type: types.SET_INITIAL_FACEBOOK_ATTRIBUTION,
    attribution,
});

const facebookBreakDownLoading = (isLoading) => ({
    type: types.SETTINGS_FACEBOOK_BREAKDOWN_LOADING,
    isLoading,
});

/**
 * Linkedin
 */

const linkedinBreakDownLoading = (isLoading) => ({
    type: types.SETTINGS_LINKEDIN_BREAKDOWN_LOADING,
    isLoading,
});

const setLinkedinBreakdown = (conversions) => ({
    type: types.SETTINGS_UPDATE_LINKEDIN_BREAKDOWN,
    payload: conversions,
});

const setInitialLinkedinBreakdown = (payload) => ({
    type: types.SET_INITIAL_LINKEDIN_BREAKDOWN,
    payload,
});

/**
 * Shared
 */
const setConversionsTouched = (touched) => ({
    type: types.SET_CONVERSIONS_TOUCHED,
    touched,
});

const resetConversionSettings = () => ({
    type: types.RESET_CONVERSION_SETTINGS,
});

const toggleConversionsCurrentAndFuture = (selectAll) => ({
    type: types.TOGGLE_CONVERSIONS_CURRENT_AND_FUTURE,
    selectAll,
});

const updateCampaignGroup = ({ groupId, updates = {} }) => ({
    type: types.UPDATE_CAMPAIGN_GROUP,
    groupId,
    updates,
});

/**
 * Pinterest
 */
const setPinterestAttribution = (attribution) => ({
    type: types.SET_PINTEREST_ATTRIBUTION,
    payload: attribution,
});

const setInitialPinterestAttribution = (attribution) => ({
    type: types.SET_INITIAL_PINTEREST_ATTRIBUTION,
    attribution,
});


export default {
    getConversionTypesPending,
    getConversionTypesFulfilled,
    getConversionTypesRejected,
    setSelectedAnalyticsIntegration,
    setInitialConversionTypes,
    setFacebookBreakdown,
    setInitialFacebookBreakdown,
    facebookBreakDownLoading,
    analyticsV4BreakDownLoading,
    linkedinBreakDownLoading,
    setLinkedinBreakdown,
    setInitialLinkedinBreakdown,
    setConversionsTouched,
    setFacebookAttribution,
    setInitialFacebookAttribution,
    setPinterestAttribution,
    setInitialPinterestAttribution,
    resetConversionSettings,
    toggleConversionsCurrentAndFuture,
    setAdwordsBreakdown,
    setInitialAdwordsBreakdown,
    setSelectedAdwordsIntegration,
    setAnalyticsV4Breakdown,
    setInitialAnalyticsV4Breakdown,
    setSelectedAnalyticsV4Integration,
    updateCampaignGroup,
};
