import React from "react";

export default ({ className = "" }) => (
    <svg
        fill="#6A80A7"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
    >
        <path
            fillRule="evenodd"
            d="M28,11 C28.5522847,11 29,11.4477153 29,12 L29.75,12 C31.5449254,12 33,13.4550746 33,15.25 L33,24.75 C33,26.5449254 31.5449254,28 29.75,28 L19.25,28 C17.4550746,28 16,26.5449254 16,24.75 L16,15.25 C16,13.4550746 17.4550746,12 19.25,12 L20,12 C20,11.4477153 20.4477153,11 21,11 C21.5522847,11 22,11.4477153 22,12 L27,12 C27,11.4477153 27.4477153,11 28,11 Z M31.25,17 C31.25,17.5522847 30.8022847,18 30.25,18 L18.75,18 C18.1977153,18 17.75,17.5522847 17.75,17 L17.75,24.5 C17.75,25.4664983 18.5335017,26.25 19.5,26.25 L19.5,26.25 L29.5,26.25 C30.4664983,26.25 31.25,25.4664983 31.25,24.5 L31.25,24.5 L31.25,17 Z M24.5125,18.875 C24.9612314,18.875 25.325,19.2387686 25.325,19.6875 L25.324,21.074 L26.6875,21.075 C27.1362314,21.075 27.5,21.4387686 27.5,21.8875 C27.5,22.3362314 27.1362314,22.7 26.6875,22.7 L25.324,22.699 L25.325,24.0625 C25.325,24.5112314 24.9612314,24.875 24.5125,24.875 C24.0637686,24.875 23.7,24.5112314 23.7,24.0625 L23.699,22.699 L22.3125,22.7 C21.8637686,22.7 21.5,22.3362314 21.5,21.8875 C21.5,21.4387686 21.8637686,21.075 22.3125,21.075 L23.699,21.074 L23.7,19.6875 C23.7,19.2387686 24.0637686,18.875 24.5125,18.875 Z M20,13.75 L19.5,13.75 C18.5335017,13.75 17.75,14.5335017 17.75,15.5 L17.75,15.5 L17.75,16.25 L31.25,16.25 L31.25,15.5 C31.25,14.5335017 30.4664983,13.75 29.5,13.75 L29.5,13.75 L29,13.75 L29,14.5 C29,15.0522847 28.5522847,15.5 28,15.5 C27.4477153,15.5 27,15.0522847 27,14.5 L27,13.75 L22,13.75 L22,14.5 C22,15.0522847 21.5522847,15.5 21,15.5 C20.4477153,15.5 20,15.0522847 20,14.5 L20,13.75 Z"
            transform="translate(-16 -11)"
        />
    </svg>
);
