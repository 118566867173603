import React from "react";

export default ({ styles }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...styles}>
        <path
            d="M5.44012815,1.94039907 C5.5939127,1.5129685 5.80536644,1.119461 6.11934321,0.80058423 C7.3752503,-0.495276685 9.45134161,-0.169615303 10.3035643,1.45869161 C10.9443332,2.69349101 11.6235483,3.90115197 12.2835402,5.12238215 C13.3856628,7.15098118 14.5006007,9.1795802 15.5899079,11.2149638 C16.5062074,12.9179015 15.5130156,15.0686235 13.6932319,15.3603618 C12.578294,15.5367618 11.5338406,14.9939928 10.9571486,13.9355933 C9.98958751,12.1512403 9.01561874,10.3668873 8.04805767,8.58931895 C8.0288346,8.54861127 8.00320384,8.51468821 7.97757309,8.48076515 C7.87505006,8.3925652 7.83019624,8.26365757 7.76611934,8.14831916 C7.33680416,7.34773493 6.89467361,6.55393531 6.46535843,5.76013569 C6.18982779,5.24450517 5.90148178,4.73565926 5.62595114,4.22002874 C5.37605126,3.75867512 5.26071286,3.25661382 5.27352823,2.72741407 C5.2927513,2.45602959 5.32478975,2.1846451 5.44012815,1.94039907"
            id="Path"
            fill="#3C8BD9"
        ></path>
        <path
            d="M5.44012815,1.94039907 C5.38245895,2.1846451 5.33119744,2.42889114 5.31838206,2.6867064 C5.29915899,3.25661382 5.43372046,3.78581356 5.70284341,4.28109025 C6.40768923,5.56338194 7.11253504,6.85245824 7.81097317,8.14153455 C7.87505006,8.25687295 7.92631157,8.37221136 7.99038847,8.48076515 C7.60592711,9.18636481 7.22146576,9.88517986 6.83059672,10.5907795 C6.29235082,11.5745483 5.75410493,12.5651017 5.20945134,13.5488704 C5.18382058,13.5488704 5.1774129,13.5353012 5.17100521,13.5149473 C5.16459752,13.4606705 5.18382058,13.4131782 5.19663596,13.3589013 C5.45935122,12.3412095 5.24148979,11.438856 4.5814978,10.6654103 C4.17781338,10.197272 3.66519824,9.93267215 3.07569083,9.84447219 C2.30676812,9.72913378 1.62755306,9.93945676 1.01882259,10.4483027 C0.90989187,10.5365026 0.839407289,10.6654103 0.711253504,10.7332564 C0.685622747,10.7332564 0.672807369,10.7196872 0.66639968,10.6993333 C0.973968763,10.1362105 1.27513016,9.57308771 1.58269924,9.0099649 C2.85142171,6.67605833 4.12014417,4.34215176 5.39527433,2.0150298 C5.40808971,1.98789135 5.42731278,1.96753752 5.44012815,1.94039907"
            id="Path"
            fill="#FABC04"
        ></path>
        <path
            d="M0.692030437,10.7196872 C0.813776532,10.6043487 0.929114938,10.4822257 1.05726872,10.3736719 C2.6143372,9.07102641 4.95314377,10.0140875 5.2927513,12.0766096 C5.37605126,12.5718863 5.33119744,13.0468091 5.19022827,13.521732 C5.18382058,13.5624396 5.1774129,13.5963627 5.16459752,13.6370704 C5.10692831,13.7456242 5.0556668,13.8609626 4.99158991,13.9695164 C4.42130557,14.9668543 3.58189828,15.462131 2.47977573,15.3875003 C1.21746095,15.2925157 0.224269123,14.2883931 0.0512615138,12.9586092 C-0.0320384461,12.314071 0.0897076492,11.7102405 0.403684421,11.1539023 C0.467761314,11.0317793 0.544653584,10.9232255 0.615138166,10.8011025 C0.647176612,10.7739641 0.634361233,10.7196872 0.692030437,10.7196872"
            id="Path"
            fill="#34A852"
        ></path>
        <path
            d="M0.720549988,10.7196872 C0.68469297,10.7468256 0.68469297,10.7943179 0.62194319,10.8011025 C0.612978936,10.7536102 0.648835953,10.7264718 0.68469297,10.6925487 L0.720549988,10.7196872"
            id="Path"
            fill="#FABC04"
        ></path>
        <path
            d="M5.1711658,13.6370704 C5.1311658,13.5895781 5.1711658,13.555655 5.2111658,13.521732 C5.2211658,13.5285166 5.2411658,13.5420858 5.2511658,13.5488704 L5.1711658,13.6370704"
            id="Path"
            fill="#E1C025"
        ></path>
    </svg>
);
