import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import { get } from "lodash";

export const defaultState = {
    basicBudgets: undefined,
    enhancedBudgets: undefined,
    insights: undefined,
    KPIs: undefined,
    alerts: undefined,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.GET_DASHBOARD_BASIC_BUDGETS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isBasicBudgetsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        basicBudgets: { ...action.payload },
                        basicBudgetsError: false,
                        isBasicBudgetsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    basicBudgets: {},
                    basicBudgetsError: true,
                    isBasicBudgetsLoading: false,
                }),
            });
        },
        [types.RESET_DASHBOARD_BASIC_BUDGETS]: (state, action) => {
            return {
                ...state,
                basicBudgets: undefined,
            };
        },
        [types.GET_DASHBOARD_ENHANCED_BUDGETS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isEnhancedBudgetsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        enhancedBudgets: { ...action.payload },
                        enhancedBudgetsError: false,
                        isEnhancedBudgetsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    enhancedBudgetsError: true,
                    isEnhancedBudgetsLoading: false,
                }),
            });
        },
        [types.RESET_DASHBOARD_ENHANCED_BUDGETS]: (state, action) => {
            return {
                ...state,
                enhancedBudgets: undefined,
            };
        },
        [types.GET_DASHBOARD_INSIGHTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isInsightsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        insights: { ...action.payload },
                        insightsError: false,
                        isInsightsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    insightsError: true,
                    isInsightsLoading: false,
                }),
            });
        },
        [types.DISMISS_DASHBOARD_INSIGHT]: (state, action) => {
            return handle(state, action, {
                success: (prevState) => {
                    const id = get(action, ["payload", "data", "id"]) || "";
                    const oldInsights = prevState.insights || {};
                    const oldInsight = oldInsights[id] || {};
                    return {
                        ...prevState,
                        insights: {
                            ...oldInsights,
                            [id]: { ...oldInsight, dismissed: { value: true } },
                        },
                    };
                },
            });
        },
        [types.GET_KPIS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isKPIsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        KPIs: { ...action.payload },
                        KPIsError: false,
                        isKPIsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    KPIsError: true,
                    isKPIsLoading: false,
                }),
            });
        },
        [types.GET_DASHBOARD_ALERTS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isAlertsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        alerts: [...Object.values(action.payload)].filter(
                            ({ dismissed: { value } = {} }) => !value
                        ),
                        alertsError: false,
                        isAlertsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    alertsError: true,
                    isAlertsLoading: false,
                }),
            });
        },
        [types.RESET_DASHBOARD_ALERTS]: (state, action) => {
            return {
                ...state,
                alerts: undefined,
            };
        },
        [types.DISMISS_DASHBOARD_ALERT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    dismissPending: true,
                }),
                success: (prevState) => {
                    const alertId = action.meta.id;

                    return {
                        ...prevState,
                        dismissPending: false,
                        alerts: state.alerts.filter(({ id }) => alertId !== id),
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    dismissPending: false,
                }),
            }),
    })
);
