import React from "react";

export default ({ className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" className={className}>
        <path
            fill="#333333"
            d="M12.591382,8.27467009 L15.7425541,11.3588912 C16.0858153,11.7251126 16.0858153,12.2746626 15.7425541,12.6408948 L12.591382,15.7557489 C12.4039708,15.908412 12.1856559,16 11.9359749,16 C11.6863107,16 11.4679958,15.9084447 11.2805679,15.725334 C10.9373067,15.3591127 10.9373067,14.8095626 11.2805679,14.4433304 L12.773,13 L5,13 C4.59285508,13 4,12.566621 4,12 C4,11.4666448 4.56746106,11 5,11 L12.773,11 L11.2807908,9.55667368 C10.9064031,9.19045235 10.9064031,8.64090232 11.2807908,8.27467009 C11.6551786,7.90843785 12.2169831,7.90844875 12.591382,8.27467009 Z M4.71169771,0.27466614 C5.08550068,0.64088766 5.08548956,1.19043797 4.71169771,1.55667039 L3.239,3 L11,3 C11.4071449,3 12,3.43337897 12,4 C11.9998225,4.53335516 11.4069586,5 11,5 L3.252,5 L4.74296386,6.44332961 C5.08567871,6.80955113 5.08567871,7.35910144 4.74296386,7.72533386 C4.55606794,7.90844462 4.30680662,8 4.08860004,8 C3.83933315,8 3.6213658,7.90839011 3.43423621,7.72527935 L0.257036136,4.64089311 C-0.0856787119,4.27467159 -0.0856787119,3.72512128 0.257036136,3.35888886 L3.4031926,0.27466614 C3.77698444,-0.09155538 4.33789474,-0.09155538 4.71169771,0.27466614 Z"
            transform="matrix(0 1 1 0 0 0)"
        />
    </svg>
);
