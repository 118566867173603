const NEW_NOTIFICATION = "NEW_NOTIFICATION";
const ADD_COUNT = "ADD_COUNT";
const INITIALIZE_NOTIFICATIONS = "INITIALIZE_NOTIFICATIONS";
const CLEAR_COUNT = "CLEAR_COUNT";
const READ_NOTIFICATION = "READ_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export default {
    NEW_NOTIFICATION,
    ADD_COUNT,
    INITIALIZE_NOTIFICATIONS,
    CLEAR_COUNT,
    READ_NOTIFICATION,
    DELETE_NOTIFICATION
};
