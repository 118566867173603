import React, { lazy, useEffect, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router";
import { protect, Logout, RedirectPath, RedirectLoginPath } from "./lib/authHocs";
import { renderNothing } from "recompose";
import App from "./components/AppContainer";
import { mixpanel } from "./components/Mixpanel";
import { trackUrls } from "lib/utility";
import TitleContainer from "./features/UserPanel/Title/container";
import OffsiteUserRegistrationContainer from "./features/Auth/OffsiteRegistration";
import Loading from "components/Loading";
import AllowedAccount from "lib/AllowedAccount";
import { NoneReportingUserPage } from "lib/allowedUserPages";
import ShopifyInstall from "./features/Integrations/Connections/ShopifyConnect/Install";
import ShopifyAuthorize from "./features/Integrations/Connections/ShopifyConnect/Authorize/container";
import { withPermissions } from "features/Permissions";
import { compose } from "recompose";
import MobileDetection from "components/MobileDetection";
import DesktopIcon from "assets/images/icons/Icon-Desktop.svg";
import componentLoader from "lib/componentLoader";
import { getPathInfo } from "lib/paths";
import { useSelector } from "react-redux";
import { makeSelectUserMetaData } from "_redux/users/selectors";
import { isOnDefaultDomain } from "components/Branding";
import { toCapitalize } from "features/AlertsPage/Alert/helpers";
import BudgetPlannerContainer from "features/BudgetPlanner/container";

const loadComponentTwice = componentLoader(2);

const Page404 = lazy(() => loadComponentTwice(() => import("./features/404")));
const ReportMakerContainer = lazy(() => loadComponentTwice(() => import("./features/ReportMaker/container")));
const KpiGoogleAnalyticsV4Container = lazy(() =>
    loadComponentTwice(() => import("./features/Reports/KpiGoogleAnalyticsV4/container")),
);
const KpiLinkedinContainer = lazy(() => loadComponentTwice(() => import("features/Reports/KpiLinkedin/container")));
const KpiBingContainer = lazy(() => loadComponentTwice(() => import("features/Reports/KpiBing/container")));
const KpiAdwordsContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiAdwords/container")));
const KpiTikTokContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiTikTok/container")));
const KpiPinterestContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiPinterest/container")));
const KpiBigQueryContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiBigQuery/container")));
const KpiOrganicFacebookContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Reports/KpiOrganicFacebook/container")),
);
const KpiMaropostContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiMaropost/container")));
const KpiKlaviyoContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiKlaviyo/container")));
const KpiFacebookContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiFacebook/container")));
const KpiShopifyContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiShopify/container")));
const KpiStackAdaptContainer = lazy(() => loadComponentTwice(() => import("features/Reports/KpiStackAdapt/container")));
const KpiSearchConsoleContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Reports/KpiSearchConsole/container")),
);
const KpiGoogleBusinessProfileContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Reports/KpiGoogleBusinessProfile/container")),
);

const InsightsContainer = lazy(() => loadComponentTwice(() => import("./features/Insights/InsightPage/container")));
const GoalMonitoringContainer = lazy(() =>
    loadComponentTwice(() => import("./features/GoalMonitoring/GoalsDataPage/container")),
);
const AuditReportContainer = lazy(() =>
    loadComponentTwice(() => import("./features/AuditReport/AuditReportPage/container")),
);
const AnomaliesContainer = lazy(() => loadComponentTwice(() => import("./features/Anomalies/container")));
const Competitors = lazy(() => loadComponentTwice(() => import("./features/Competitors/container")));
const UserResetPasswordContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Auth/ResetPassword/ResetPasswordForm/container")),
);
const UserSetNewPasswordContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Auth/ResetPassword/SetNewPasswordForm/container")),
);
const AccountSettingsContainer = lazy(() => loadComponentTwice(() => import("./features/AccountSettings/container")));
const KpiConnectedAccountsContainer = lazy(() => loadComponentTwice(() => import("./features/Settings/container")));
const FinancialsContainer = lazy(() => loadComponentTwice(() => import("./features/Budgets/BasicBudgeting/container")));
const EnhancedBudgetingContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Budgets/EnhancedBudgeting/container")),
);
const KpiSummaryContainer = lazy(() => loadComponentTwice(() => import("./features/Reports/KpiSummary/container")));
const NewKpiSummaryContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Reports/NewKpiSummary/container")),
);
const ViewsContainer = lazy(() => loadComponentTwice(() => import("./features/ControlPanel/container")));
const SharedPage = lazy(() => loadComponentTwice(() => import("./features/SharedPage/container")));
const SharedReportPage = lazy(() => loadComponentTwice(() => import("./features/SharedReport/container")));
const GoogleSignOn = lazy(() => loadComponentTwice(() => import("./features/Auth/Google/Authenticate/container")));

const BenchmarkingContainer = lazy(() => loadComponentTwice(() => import("./features/Benchmarking/container")));
const PpcAuditContainer = lazy(() => loadComponentTwice(() => import("./features/Forecast/BudgetPacer/container")));
const HealthCheckContainer = lazy(() => loadComponentTwice(() => import("features/PageHealth/container")));
const Admin = lazy(() => loadComponentTwice(() => import("features/Admin")));
const DataExtract = lazy(() => loadComponentTwice(() => import("features/Admin/DataExtract")));
const Logs = lazy(() => loadComponentTwice(() => import("features/Admin/Logs")));
const VerifyEmail = lazy(() => loadComponentTwice(() => import("./components/VerifyEmail")));
const ProfileContainer = lazy(() => import("./features/UserSettings/container"));
const AlertSettings = lazy(() => import("./features/AlertSettings/container"));
const PaymentPageContainer = lazy(() =>
    loadComponentTwice(() => import("./features/Onboarding/PaymentPage/container")),
);
const AlertsContainer = lazy(() => loadComponentTwice(() => import("./features/AlertsPage/AlertPage/container")));
const NewDashboardContainer = lazy(() => import("./features/NewDashboard/container"));
const AnalysisContainer = lazy(() => loadComponentTwice(() => import("./features/Analysis/container")));
const ChatBotContainer = lazy(() => loadComponentTwice(() => import("./features/ChatGPT/container")));

const withPermissionsRedirect = (actions) => withPermissions(actions, () => <Redirect to="/404" />);

//Protected Routes - /dashboard/...
const protectedRoutes = [
    {
        path: "/",
        exact: true,
        component: ViewsContainer,
        sidePanel: renderNothing(),
        topPanel: (props) => <TitleContainer displayLogo={true} {...props} />,
    },
    {
        path: `/${getPathInfo("extraPath", "user")}`,
        component: AccountSettingsContainer,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "alertSettings")}`,
        component: NoneReportingUserPage(AlertSettings),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "adminUser")}`,
        component: Admin,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "adminLog")}`,
        component: Logs,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "adminData")}`,
        component: DataExtract,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "shopifyAuthorize")}`,
        component: ShopifyAuthorize,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "profile")}`,
        component: ProfileContainer,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/${getPathInfo("extraPath", "onboarding")}`,
        component: withPermissionsRedirect({
            actions: "ACTIONS_CONTROLPANEL_CREATE_ACCOUNT",
        })(PaymentPageContainer),
        sidePanel: renderNothing(),
        topPanel: renderNothing(),
    },
    {
        path: "/:siteID",
        component: NoneReportingUserPage(NewDashboardContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: "/:siteID/overview",
        component: NoneReportingUserPage(NewDashboardContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("extraPath", "extraSettings")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_ACCOUNT_SETTINGS",
        })(AllowedAccount(KpiConnectedAccountsContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Alerts", "financials")}`,
        component: withPermissionsRedirect({ actions: "ACTION_BUDGETS" })(AllowedAccount(FinancialsContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Alerts", "alertsSummary")}`,
        component: withPermissionsRedirect({ actions: "ACTION_MONITORING" })(AllowedAccount(AlertsContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Alerts", "enhancedBudgeting")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_ENHANCED_BUDGETS",
        })(AllowedAccount(EnhancedBudgetingContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Alerts", "goalMonitoring")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_MONITORING",
        })(AllowedAccount(GoalMonitoringContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "benchmarks")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_BENCHMARKS",
        })(AllowedAccount(BenchmarkingContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "presentationMaker")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(
            AllowedAccount(NoneReportingUserPage(ReportMakerContainer)),
        ),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "adwords")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiAdwordsContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "tiktok")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PLATFORMS",
        })(AllowedAccount(KpiTikTokContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "pinterest")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PLATFORMS",
        })(AllowedAccount(KpiPinterestContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "bigQuery")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PLATFORMS",
        })(AllowedAccount(KpiBigQueryContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "maropost")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PLATFORMS",
        })(AllowedAccount(KpiMaropostContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "klaviyo")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PLATFORMS",
        })(AllowedAccount(KpiKlaviyoContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "linkedin")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiLinkedinContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "shopify")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiShopifyContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "searchConsole")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiSearchConsoleContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "googleBusinessProfile")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(
            AllowedAccount(KpiGoogleBusinessProfileContainer),
        ),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "bing")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiBingContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "stackAdapt")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiStackAdaptContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "facebook")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(AllowedAccount(KpiFacebookContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "organicFacebook")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(
            AllowedAccount(KpiOrganicFacebookContainer),
        ),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "googleAnalyticsV4")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PLATFORMS" })(
            AllowedAccount(KpiGoogleAnalyticsV4Container),
        ),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "reportSummary")}`,
        component: AllowedAccount(KpiSummaryContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Reports", "reportBuilder")}`,
        component: AllowedAccount(NewKpiSummaryContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/analysis`,
        component: compose(
            AllowedAccount,
            withPermissionsRedirect({ actions: "ACTION_PLATFORMS" }),
        )(NoneReportingUserPage(AnalysisContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "insights")}`,
        component: compose(AllowedAccount, withPermissionsRedirect({ actions: "ACTION_PLATFORMS" }))(InsightsContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "auditReport")}`,
        component: compose(
            AllowedAccount,
            withPermissionsRedirect({ actions: "ACTION_AUDIT_REPORT" }),
        )(AuditReportContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "chatGpt")}`,
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        component: compose(
            AllowedAccount,
            withPermissionsRedirect({ actions: "ACTION_AUDIT_REPORT" }),
        )(ChatBotContainer),
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "budgetPlanner")}`,
        component: compose(
            AllowedAccount,
            withPermissionsRedirect({ actions: "ACTION_PPCAUDIT" }),
        )(BudgetPlannerContainer),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "ppcaudit")}`,
        component: withPermissionsRedirect({ actions: "ACTION_PPCAUDIT" })(AllowedAccount(PpcAuditContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
        exact: true,
    },
    {
        path: `/:siteID/${getPathInfo("Alerts", "pagehealth")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_PAGEHEALTH",
        })(AllowedAccount(HealthCheckContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("Insights", "anomalies")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_WEEKLY_ANOMALIES",
        })(AllowedAccount(AnomaliesContainer)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
    {
        path: `/:siteID/${getPathInfo("extraPath", "extraSeo")}`,
        component: withPermissionsRedirect({
            actions: "ACTION_SEOPERFORMANCE",
        })(AllowedAccount(Competitors)),
        sidePanel: renderNothing(),
        topPanel: TitleContainer,
    },
];

const Protected = protect((props) => {
    return (
        <>
            <MobileDetection
                icon={DesktopIcon}
                title={"Made for Desktop"}
                message={"Thank you for checking out us! Please note this application is a desktop tool."}
            >
                <App
                    path={props.match}
                    routes={protectedRoutes.map((routes) => {
                        return {
                            ...routes,
                            topPanel: routes.hasOwnProperty("topPanel") ? routes.topPanel : renderNothing(),
                            path: props.match.path + routes.path,
                        };
                    })}
                />
            </MobileDetection>
        </>
    );
});

export const exposedRoutes = [
    {
        path: getPathInfo("exposedPath", "page404"),
        component: Page404,
    },
    {
        path: getPathInfo("exposedPath", "shopifyInstall"),
        component: ShopifyInstall,
    },
    {
        path: getPathInfo("exposedPath", "login"),
        exact: true,
        component: RedirectLoginPath,
    },
    {
        path: getPathInfo("exposedPath", "loginOffsite"),
        component: OffsiteUserRegistrationContainer,
    },
    {
        path: getPathInfo("exposedPath", "googleLogin"),
        component: GoogleSignOn,
    },
    {
        path: getPathInfo("exposedPath", "forgotPassword"),
        component: UserResetPasswordContainer,
    },
    {
        path: getPathInfo("exposedPath", "passwordReset"),
        component: UserSetNewPasswordContainer,
    },
    {
        path: getPathInfo("exposedPath", "logout"),
        component: (props) => {
            mixpanel.track("Logout");
            mixpanel.reset();
            return <Logout {...props} />;
        },
    },
    {
        path: getPathInfo("exposedPath", "shared"),
        component: SharedPage,
    },
    {
        path: getPathInfo("exposedPath", "sharedReport"),
        component: SharedReportPage,
    },
    {
        path: getPathInfo("exposedPath", "verifyEmail"),
        component: VerifyEmail,
    },
    {
        path: "/",
        exact: true,
        component: RedirectPath,
    },
    //This is the route for protected routes
    {
        path: getPathInfo("exposedPath", "dashboard"),
        component: Protected,
    },
];

const Routes = () => {
    useEffect(() => {
        trackUrls({
            pathname: window.location.pathname,
            action: "ONLOAD",
            search: window.location.search,
        });
    }, []);

    useEffect(() => {
        const clickHandler = (event) => {
            const element = event.target;
            mixpanel.track("Element Clicked", {
                "element name": element.tagName,
                pathname: window.location.pathname,
            });
        };
        document.addEventListener("click", clickHandler);
        return () => {
            document.removeEventListener("click", clickHandler);
        };
    }, []);

    // return (
    //     <Suspense fallback={<Loading />}>
    //         <Switch>
    //             <Route exact path="/maintenance" component={MaintenancePage} />
    //             <Redirect to="/maintenance" />
    //         </Switch>
    //     </Suspense>
    // );

    // whitelabeling
    const { company, logo: faviconURL } = useSelector((state) => makeSelectUserMetaData()(state));
    useEffect(() => {
        if (!isOnDefaultDomain()) {
            // Update page title
            document.title = company || toCapitalize(window.location.hostname);

            // Update favicon
            const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = faviconURL;
            document.getElementsByTagName("head")[0].appendChild(link);
        }
    }, [company, faviconURL]);

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {exposedRoutes.map(({ component: Component, ...rest }, index) => (
                    <Route
                        key={rest.path}
                        exact={rest.exact ? rest.exact : false}
                        path={rest.path}
                        render={(props) => <Component {...props} />}
                    />
                ))}
                <Redirect to="/404" />
            </Switch>
        </Suspense>
    );
};

export default Routes;
