import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

export const defaultState = {
    alertSettingsLoading: false,
    alertRules: [],
    alertSettingsLoadingError: false,
    previewAlert: null,
    previewAlertLoading: false,
    previewAlertError: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.GET_ALERT_SETTINGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    alertSettingsLoading: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        alertRules: action.payload,
                        alertSettingsLoadingError: false,
                        alertSettingsLoading: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    alertSettingsLoadingError: true,
                    alertSettingsLoading: false,
                }),
            });
        },
        [types.DETELE_ALERT_RULES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const { success = [] } = action.payload || {};
                    const newAlerRules = state.alertRules.filter(({ id }) => !success?.includes(id));

                    return {
                        ...prevState,
                        alertRules: newAlerRules,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.CREATE_ALERT_RULES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const { success = [] } = action.payload || {};
                    return {
                        ...prevState,
                        alertRules: [...state.alertRules, ...success],
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.UPDATE_ALERT_RULES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const { success = [] } = action.payload || {};
                    const newAlertRules = state.alertRules.map((alertRule) => {
                        const currentAlertRule = success.find(({ id }) => id === alertRule.id);
                        return {
                            ...alertRule,
                            ...currentAlertRule,
                        };
                    });
                    return {
                        ...prevState,
                        alertRules: newAlertRules,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.PREVIEW_ALERT_RULES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    previewAlertLoading: true,
                    previewAlertError: false,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        previewAlert: action.payload,
                        previewAlertLoading: false,
                        previewAlertError: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    previewAlertLoading: false,
                    previewAlertError: true,
                }),
            });
        },
        [types.RESET_PREVIEW_ALERT_RULES]: (state, action) => {
            return {
                ...state,
                previewAlert: null,
            };
        },
    }),
);
