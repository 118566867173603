import { connect } from "react-redux";
import Component from "./index";
import { operations as connectOperations } from "features/Integrations/_ducks";
import { actions } from "features/Onboarding/ducks";

const { exchangeShopifyCode } = connectOperations;
const { onboardingPlatformConnection } = actions;

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
    exchangeCode: (data) => {
        return dispatch(exchangeShopifyCode(data));
        // .then(({ id }) => {
        //     dispatch(
        //         onboardingPlatformConnection({
        //             platform: "shopify",
        //             connectionId: id
        //         })
        //     );
        // });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
