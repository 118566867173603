import createReducer from "../../../lib/createReducer";
import clearReducer from "../../../lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";
import merge from "lodash/fp/merge";

const defaultState = {
    optimizerData: {},
    loading: false,
    error: false,
    pending: true,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.UPDATE_PENDING](state, action) {
            return {
                ...state,
                pending: action.payload.pending,
            };
        },
        [types.CLEAR_FORECASTING](state, action) {
            return {
                ...state,
                ...defaultState,
            };
        },
        [types.UPDATE_CAMPAIGNS](state, action) {
            return {
                ...state,
                optimizerData: {
                    ...state.optimizerData,
                    campaigns: {
                        ...state.optimizerData.campaigns,
                        ...action.payload.campaignChangedSuccess,
                    },
                },
            };
        },

        [types.OPTIMIZER_FETCH]: (state, action) =>
            handle(state, action, {
                start: (prevState) =>
                    merge(prevState, {
                        optimizerData: {},
                        chartData: [],
                        totals: {},
                        loading: true,
                        error: false,
                    }),
                failure: (prevState) =>
                    merge(prevState, {
                        optimizerData: {},
                        loading: false,
                        error: true,
                    }),
                success: (prevState) =>
                    merge(prevState, {
                        optimizerData: action.payload,
                        loading: false,
                        error: false,
                    }),
            }),
    }),
);
