import React from "react";
import styles from "./style.module.scss";
import sharedStyles from "features/PlanOptions/style.module.scss";
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements";
import BillingEdit from "./CreditCard/container";

const StripeBillingEdit = injectStripe(BillingEdit);

export default ({ plans, toggleDisplay, billing, toggleBilling, isAppSumo = false }) => {
    return (
        <div>
            <div className={`${sharedStyles.heading} ${styles.upgradeHeading}`}>
                <h2>Upgrade your plan</h2>
                <p>Select your plan and add your payment details.</p>
                <p style={{ fontSize: 12, lineHeight: "18px", marginTop: "1rem" }}>
                    An account is any grouping of integrations you want to analyze. <br />
                    Typically for an agency, each client is one account. For a business, one account is often enough.
                </p>
            </div>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                <Elements
                    fonts={[
                        {
                            cssSrc: "https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,800",
                        },
                    ]}
                >
                    <StripeBillingEdit
                        plans={plans}
                        toggleDisplay={toggleDisplay}
                        billing={billing}
                        toggleBilling={toggleBilling}
                        isAppSumo={isAppSumo}
                        onCompletePayment={() => toggleDisplay("success")}
                    />
                </Elements>
            </StripeProvider>
        </div>
    );
};
