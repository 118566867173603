export const prepareData = (data) => {
    const { min, max, value, benchmarkMin, benchmarkMax, ...otherData } =
        data || {};
    return {
        min: 0,
        max: max - min,
        value: value - min,
        benchmarkMin: benchmarkMin - min,
        benchmarkMax: benchmarkMax - min,
        ...otherData,
    };
};
