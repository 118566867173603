import { api } from "lib/bing";
import types from "./types";

const getBingData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: types.BING_REPORT,
        promise: api.getReport({
            dateRange,
            accountId
        })
    });
};

export default {
    getBingData
};
