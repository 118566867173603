import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import * as types from "./types";

const defaultState = {};

export default createReducer(defaultState, {
    [types.ACTION_GET_PLANS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    ...action.payload
                };
            },
            failure: (prevState) => ({
                ...prevState
            }),
            finish: (prevState) => ({
                ...prevState
            })
        })
});
