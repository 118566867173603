import { createSelector } from "reselect";

export const selectbudgetPlanner = (state) => state.budgetPlanner;

export const makeSelectBudgetPlannerData = () =>
    createSelector(selectbudgetPlanner, ({ budgetPlannerData }) => budgetPlannerData || {});

export const makeSelectBudgetPlannerAverageMetrics = () =>
    createSelector(selectbudgetPlanner, ({ budgetPlannerAverageMetrics }) => budgetPlannerAverageMetrics || {});

export const makeSelectBudgetPlannerLoading = () =>
    createSelector(selectbudgetPlanner, ({ budgetPlannerLoading }) => budgetPlannerLoading);

export const makeSelectBudgetPlannerError = () =>
    createSelector(selectbudgetPlanner, ({ budgetPlannerError }) => budgetPlannerError);
