import get from "lodash/get";
import CheckboxItem from "./CheckboxItem";
import React from "react";
import { isEmpty } from "lodash";
import { getPlatformUI } from "features/ControlPanel/LayoutSettings";
import { channelFriendlyName } from "lib/utility";

export function sortAccounts(a, b) {
    const aFav = a.favourite;
    const bFav = b.favourite;
    const aVal = get(a, "name").toLowerCase();
    const bVal = get(b, "name").toLowerCase();

    // If a or b are favourites, move them above
    if (aFav && !bFav) {
        return -1;
    }

    if (bFav && !aFav) {
        return 1;
    }

    // If a and b are both favourites, use alphabetical
    if (aFav && bFav) {
        if (aVal > bVal) {
            return 1;
        }

        if (aVal < bVal) {
            return -1;
        }

        return 0;
    }

    //If a and b are both not favourites, use alphabetical
    if (aVal > bVal) {
        return 1;
    }

    if (aVal < bVal) {
        return -1;
    }

    return 0;
}

export const getTagsOptions = ({ tags, tagsFilter, onChangeCheckbox }) =>
    tags.map(({ name, id, color, hexColor }) => {
        return {
            label: (
                <CheckboxItem
                    tagsFilter={tagsFilter}
                    onChangeCheckbox={onChangeCheckbox}
                    name={name}
                    id={id}
                    color={color}
                    hexColor={hexColor}
                />
            ),
            id,
        };
    });

const ALL_PLATFORM_TEXT = "all";

export const getPlatformFilter = (platforms) => {
    const platformsFiltered = Object.keys(platforms).filter((key) => platforms[key]) || [];
    const isAllSelected = platformsFiltered.length === 1 && platformsFiltered.includes(ALL_PLATFORM_TEXT);

    if (isEmpty(platformsFiltered) || isAllSelected) {
        return {};
    }

    const selectedPlatform = platformsFiltered.find((platform) => platform !== ALL_PLATFORM_TEXT);

    return {
        value: selectedPlatform,
        label: getPlatformUI(selectedPlatform),
        searchText: channelFriendlyName(selectedPlatform),
    };
};

export const getDataUI = ({ listTags, containsValue, platformKey, allPlatforms }) => {
    const tagsFormatted = listTags && { tags: listTags };
    const containsFormatted = containsValue && { contains: containsValue };
    const platformsFormatted = platformKey && {
        platforms: {
            ...allPlatforms.reduce(
                (cache, key) => ({
                    ...cache,
                    [key]: false,
                }),
                {},
            ),
            [platformKey]: true,
        },
    };

    return {
        ...tagsFormatted,
        ...containsFormatted,
        ...platformsFormatted,
    };
};
