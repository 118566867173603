import { api } from "lib/linkedin";
import types from "./types";

const getLinkedinData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: types.LINKEDIN_REPORT,
        promise: api.getReport({
            dateRange,
            accountId
        })
    });
};

export default {
    getLinkedinData
};
