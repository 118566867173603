import { KpiAPI } from "../../../lib/apiKpi";
import types from "./types";

const fetchOptimizerData = async ({ accountId, metric }) =>
    KpiAPI({
        url: `/account/${accountId}/projection?accountId=${accountId}&metric=${metric}`,
        method: "GET",
        bypassCache: true,
    }).then(({ data }) => data);

const updateCampaigns = ({ campaignChangedSuccess }) => (dispatch) => {
    return dispatch({
        type: types.UPDATE_CAMPAIGNS,
        payload: { campaignChangedSuccess },
    });
};

const initOptimizer = ({ accountId, metric }) => (dispatch) =>
    dispatch({
        type: types.OPTIMIZER_FETCH,
        promise: fetchOptimizerData({ accountId, metric }),
    });

export default {
    initOptimizer,
    updateCampaigns,
};
