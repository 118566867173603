import React from "react";
import AnomalyIcon from "assets/images/icons/JSX/Icon-Anomalies";
import { usePermissions } from "features/Permissions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { makeSelectIsGenerating } from "../../../_redux/sites/selectors";
import ListItem from "./ListItem";
import { getPathInfo } from "lib/paths";

const IconAnomalies = ({ selectedSite, active, isLoading, history }) => {
    if (!selectedSite) return null;

    return (
        <>
            <ListItem
                isLoading={isLoading}
                onClick={(e) => history.push(`/dashboard/${selectedSite}/${getPathInfo("Insights", "anomalies")}`)}
                icon={AnomalyIcon}
                label={"Weekly Anomalies"}
                active={active}
            />
        </>
    );
};

export default compose(
    withRouter,
    connect((state) => ({
        isLoading: makeSelectIsGenerating(["daily_anomalies", "weekly_anomalies", "monthly_anomalies"])(state),
    })),
)(IconAnomalies);
