import { toLower } from "lodash";
import style from "./style.module.scss";

export const PAID_PLATFORMS = ["adwords", "linkedin", "bing", "facebook", "tiktok", "pinterest", "stackAdapt"];

export const PLATFORM_STATUSES = {
    adwords: {
        active: true,
        message: "",
    },
    facebook: {
        active: true,
        message: "",
    },
    tiktok: {
        active: true,
        message: "",
    },
    pinterest: {
        active: true,
        message: "",
    },
    linkedin: {
        active: true,
        message: "",
    },
    bing: {
        active: true,
        message: "",
    },
    stackAdapt: {
        active: true,
        message: "",
    },
};

const ACTIVE_STATUSES = ["enabled", "enable", "active", "live"];

const COMPLETE_STATUSES = ["completed", "ended"];

const PAUSED_STATUSES = ["draft", "paused", "approved"];

const INACTIVE_STATUSES = ["inactive", "disabled", "rejected", "error"];

export const infoWrapper = (info) => `<span style="display:block;max-width:14rem;">${info}</span>`;

export const getStatus = (status, platform) => {
    const statusName = toLower(status);

    if (ACTIVE_STATUSES.includes(statusName)) {
        return {
            color: "#00b178",
            order: 0,
        };
    }
    if (PAUSED_STATUSES.includes(statusName)) {
        return {
            color: "#A0B5C4",
            order: 2,
        };
    }
    if (COMPLETE_STATUSES.includes(statusName)) {
        return {
            color: "#FB1427",
            order: 3,
        };
    }
    if (INACTIVE_STATUSES.includes(statusName)) {
        return {
            color: "#050c42",
            order: 4,
        };
    }
    if (platform === "stackAdapt" && ["resumed", "pending"].includes(statusName)) {
        return {
            color: "#ffbc00",
            order: 1,
        };
    }

    return {
        color: "#FB1427",
        order: 10,
    };
};

export const getPlatformsTip = ({ platforms = [] }) => {
    return `<div className="${style.platformTooltip}">
            <span><strong>Platforms</strong></span>
            ${platforms
                .map(
                    ({ name = "", icon = "", count = 0 }) => `<div>
                <img src="${icon}" alt="${name} icon" />
                <strong>${count}</strong>
                <span>${count === 1 ? "Campaign" : "Campaigns"}</span>
            </div>`,
                )
                .join("")}
        </div>`;
};
