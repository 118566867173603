import { api } from "lib/tiktok";
import { TIKTOK_CAMPAIGN_REPORT } from "./types";

const getTikTokCampaignData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: TIKTOK_CAMPAIGN_REPORT,
        promise: api.getCampaignReport({
            dateRange,
            accountId,
        }),
    });
};

export default {
    getTikTokCampaignData,
};
