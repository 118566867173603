import React, { createContext, useContext, useState } from "react";
import Prompt from "../.";

const PromptContext = createContext();

export const usePrompt = () => useContext(PromptContext);

export const PromptProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const setPromptMessage = (msg) => {
        setMessage(msg);
    };

    return (
        <PromptContext.Provider value={{ setPromptMessage }}>
            {children}
            {message && <Prompt message={message} />}
        </PromptContext.Provider>
    );
};
