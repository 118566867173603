import React from "react";
import Calendar from "assets/images/Report_Icons/JSX/Icon-Calendar";
import classNames from "classnames";
import style from "./style.module.scss";

const DateSelectorContent = ({
    title,
    startDateReadable,
    endDateReadable,
    startInput = {},
    endInput = {},
    daysSelected,
    error = {},
    typing = {},
    disabled,
}) => {
    return (
        <div className={classNames(style.dateSelectorContent, { [style.dateCompareSelector]: !disabled })}>
            <p>{title}</p>
            <div className={style.inputs}>
                <div
                    className={classNames(style.dateInput, {
                        [style.inputError]: error.start && !typing.start,
                    })}
                >
                    <span>Start Date</span>
                    <input
                        value={startDateReadable}
                        placeholder="Set Start Date"
                        onChange={startInput.onChange}
                        onKeyDown={(e) => e.stopPropagation()}
                        onBlur={startInput.onBlur}
                        disabled={disabled}
                    />
                </div>
                {error.start && !typing.start && <span className={style.errorMessage}>{error.start}</span>}
                <div
                    className={classNames(style.dateInput, {
                        [style.inputError]: error.end && !typing.end,
                    })}
                >
                    <span>End Date</span>
                    <input
                        value={endDateReadable}
                        placeholder="Set End Date"
                        onChange={endInput.onChange}
                        onKeyDown={(e) => e.stopPropagation()}
                        onBlur={endInput.onBlur}
                        disabled={disabled}
                    />
                </div>
                {error.end && !typing.end && <span className={style.errorMessage}>{error.end}</span>}
            </div>
            <div className={style.selected}>
                <Calendar />
                <span>
                    {daysSelected} {daysSelected === 1 ? "day" : "days"} selected
                </span>
            </div>
        </div>
    );
};

export default DateSelectorContent;
