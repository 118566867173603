import types from "./types";

const onboardingPlatformConnection = ({ platform, connectionId }) => ({
    type: types.ONBOARDING_CONNECTION,
    platform,
    connectionId,
});

const closeOnboardingModal = () => ({
    type: types.CLOSE_ONBOARDING_MODAL,
});

const onboardingLoading = (isLoading) => ({
    type: types.ONBOARDING_LOADING,
    isLoading,
});

const onboardingError = () => ({
    type: types.ONBOARDING_ERROR,
});

const clearOnboarding = () => ({
    type: types.CLEAR_ONBOARDING,
});

const changeStep = (step) => ({
    type: types.CHANGE_STEP,
    step,
});

const dismissOnboarding = () => ({
    type: types.DISMISS_ONBOARDING,
});

export default {
    onboardingPlatformConnection,
    closeOnboardingModal,
    onboardingLoading,
    onboardingError,
    clearOnboarding,
    changeStep,
    dismissOnboarding,
};
