const GET_SHAREABLE_REPORT_LINK = "GET_SHAREABLE_REPORT_LINK";
const DECODE_SHARED_REPORT_TOKEN = "DECODE_SHARED_REPORT_TOKEN";
const GET_SHAREABLE_REPORT_PDF = "GET_SHAREABLE_REPORT_PDF";
const GET_ALL_SHAREABLE_REPORT_LINK = "GET_ALL_SHAREABLE_REPORT_LINK";
const REMOVE_SHAREABLE_REPORT_LINK = "REMOVE_SHAREABLE_REPORT_LINK";
const UPDATE_SHAREABLE_REPORT_LINK = "UPDATE_SHAREABLE_REPORT_LINK";

export default {
    GET_SHAREABLE_REPORT_LINK,
    DECODE_SHARED_REPORT_TOKEN,
    GET_SHAREABLE_REPORT_PDF,
    GET_ALL_SHAREABLE_REPORT_LINK,
    REMOVE_SHAREABLE_REPORT_LINK,
    UPDATE_SHAREABLE_REPORT_LINK,
};
