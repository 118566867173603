import React from "react";
import style from "./style.css";

export default ({
    style: styles,
    color = "#ffffff",
    small = false,
    className
}) => (
    <span
        style={styles && styles}
        className={`${style.ThreeDots} ${small &&
            style.smallDots} ${className}`}
    >
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
        <span style={{ backgroundColor: color }}></span>
    </span>
);
