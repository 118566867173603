import React from "react";
import classNames from "classnames";
import { ReactComponent as IconBell } from "assets/images/icons/Icon-Bell.svg";
import { ReactComponent as IconExclamation } from "assets/images/icons/Icon-Exclamation.svg";
import style from "./style.module.scss";
import { TooltipDark } from "components/Tooltip";
import { connect } from "react-redux";
import { updateSeenIt } from "_redux/users";
import moment from "moment";

const NotificationButton = ({ handleSeenIt, notificationsCount, hasBadge, setHasBadge, isOpen, toggleOpen }) => {
    const toggleAlerts = () => {
        if (hasBadge) {
            handleSeenIt("notifications");
            setHasBadge(false);
        }
        toggleOpen(!isOpen);
    };

    return (
        <>
            <div
                className={classNames(style.notificationIcon, {
                    [style.isOpen]: isOpen,
                })}
                onClick={toggleAlerts}
                data-for="top-bar-notifications"
                data-tip="Alerts"
                data-tip-disable={isOpen}
            >
                <IconBell />
                {notificationsCount > 0 && hasBadge && (
                    <div onClick={toggleAlerts} className={style.counter}>
                        <IconExclamation />
                    </div>
                )}
            </div>
            <TooltipDark id="top-bar-notifications" position="bottom" proximity={-2} />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleSeenIt: (it) => {
        dispatch(
            updateSeenIt({
                it,
                seen: true,
                updated: moment.utc(new Date()).format(),
            }),
        );
    },
});

export default connect(undefined, mapDispatchToProps)(NotificationButton);
