import types from "./types";

const setReportSettings = (payload = {}) => ({
    type: types.REPORT_SETTINGS,
    payload,
});

const setReportBreadcrumb = (payload = {}) => ({
    type: types.REPORT_BREADCRUMB,
    payload,
});

const setCampaigns = (payload = {}) => ({
    type: types.SET_CAMPAIGNS,
    payload,
});

const clearState = () => ({
    type: types.REPORT_CLEAR,
});

const setAdGroups = (payload = {}) => ({
    type: types.SET_ADGROUPS,
    payload,
});

const setAds = (payload = {}) => ({
    type: types.SET_ADS,
    payload,
});

const setCampaignsFilter = (payload = "") => ({
    type: types.SET_CAMPAIGNS_FILTER,
    payload,
});

const resetAdReports = () => ({ type: types.RESET_AD_REPORTS });

export default {
    setReportSettings,
    setReportBreadcrumb,
    setCampaigns,
    clearState,
    setAdGroups,
    setAds,
    resetAdReports,
    setCampaignsFilter,
};
