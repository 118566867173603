import React from "react";

export default ({ className }) => (
    <svg
        fill="#6A80A7"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
    >
        <path
            fillRule="evenodd"
            d="M28,11 C28.5522847,11 29,11.4477153 29,12 L29.75,12 C31.5449254,12 33,13.4550746 33,15.25 L33,24.75 C33,26.5449254 31.5449254,28 29.75,28 L19.25,28 C17.4550746,28 16,26.5449254 16,24.75 L16,15.25 C16,13.4550746 17.4550746,12 19.25,12 L20,12 C20,11.4477153 20.4477153,11 21,11 C21.5522847,11 22,11.4477153 22,12 L27,12 C27,11.4477153 27.4477153,11 28,11 Z M21,20.625 C21.6903559,20.625 22.25,21.1846441 22.25,21.875 C22.25,22.5653559 21.6903559,23.125 21,23.125 C20.3096441,23.125 19.75,22.5653559 19.75,21.875 C19.75,21.1846441 20.3096441,20.625 21,20.625 Z M24.5,20.625 C25.1903559,20.625 25.75,21.1846441 25.75,21.875 C25.75,22.5653559 25.1903559,23.125 24.5,23.125 C23.8096441,23.125 23.25,22.5653559 23.25,21.875 C23.25,21.1846441 23.8096441,20.625 24.5,20.625 Z M28,20.625 C28.6903559,20.625 29.25,21.1846441 29.25,21.875 C29.25,22.5653559 28.6903559,23.125 28,23.125 C27.3096441,23.125 26.75,22.5653559 26.75,21.875 C26.75,21.1846441 27.3096441,20.625 28,20.625 Z M17.75,24.5 C17.75,25.4664983 18.5335017,26.25 19.5,26.25 L19.5,26.25 L29.5,26.25 C30.4664983,26.25 31.25,25.4664983 31.25,24.5 L31.25,24.5 L31.25,17 C31.25,17.5522847 30.8022847,18 30.25,18 L18.75,18 C18.1977153,18 17.75,17.5522847 17.75,17 Z M17.75,16.25 L31.25,16.25 L31.25,15.5 C31.25,14.5335017 30.4664983,13.75 29.5,13.75 L29.5,13.75 L29,13.75 L29,14.5 C29,15.0522847 28.5522847,15.5 28,15.5 C27.4477153,15.5 27,15.0522847 27,14.5 L27,13.75 L22,13.75 L22,14.5 C22,15.0522847 21.5522847,15.5 21,15.5 C20.4477153,15.5 20,15.0522847 20,14.5 L20,13.75 L19.5,13.75 C18.5818266,13.75 17.8288077,14.4571103 17.7558012,15.3564728 L17.75,15.5 L17.75,16.25 Z"
            transform="translate(-16 -11)"
        />
    </svg>
);
