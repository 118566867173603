import createReducer from "../../../lib/createReducer";
import types from "./types";

/**
 * REDUCERS
 */

const defaultState = {
    available: [],
    error: false,
    pending: false
};

export default createReducer(defaultState, {
    [types.CREATE_CAMPAIGN_FUFILLED](state, action) {
        return {
            ...state,
            error: false,
            available: [...state.available, action.payload]
        };
    },
    [types.CREATE_CAMPAIGN_REJECTED](state, action) {
        return {
            ...state,
            error: action.error
        };
    },
    [types.CREATE_CAMPAIGN_PENDING](state, action) {
        return {
            ...state,
            pending: action.pending
        };
    },
    [types.REMOVE_CAMPAIGN_FUFILLED](state, action) {
        return {
            ...state,
            error: false,
            available: state.available.filter(
                (obj) => obj.campaignId !== action.payload
            )
        };
    },
    [types.REMOVE_CAMPAIGN_REJECTED](state, action) {
        return {
            ...state,
            error: action.error
        };
    },
    [types.REMOVE_CAMPAIGN_PENDING](state, action) {
        return {
            ...state,
            pending: action.pending
        };
    },
    [types.GET_CAMPAIGNS_FUFILLED](state, action) {
        return {
            ...state,
            error: false,
            available: action.payload
        };
    },
    [types.GET_CAMPAIGNS_REJECTED](state, action) {
        return {
            ...state,
            error: action.error,
            available: {}
        };
    },
    [types.GET_CAMPAIGNS_PENDING](state, action) {
        return {
            ...state,
            pending: action.pending
        };
    }
});
