import React, { useEffect } from "react";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import isEqual from "lodash/isEqual";

import { actions } from "features/ControlPanel/ducks";

import FilterSearch from "components/SearchField/FilterSearch";
import debounce from "lodash/debounce";

const { updateSearch } = actions;

const Search = React.memo(({ className }) => {
    const dispatch = useDispatch();

    const debounceSearch = debounce(
        (value) => {
            dispatch(updateSearch({ search: value }));
        },
        [500],
    );

    useEffect(() => {
        dispatch(updateSearch({ search: "" }));
    }, []);

    return (
        <FilterSearch
            className={classNames(style.search, { [className]: className })}
            labelText="Search Accounts"
            changeEvent={(e) => {
                const value = e.target.value;
                return debounceSearch(value);
            }}
        />
    );
}, isEqual);

export default Search;
