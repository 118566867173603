import React from "react";
import style from "./style.module.scss";
import ArrowDown from "assets/images/Top_Panel/JSX/Icon-Arrow-Down";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import IconClose from "assets/images/icons/JSX/Icon-Close-Small";
import { TooltipDark } from "components/Tooltip";
import classNames from "classnames";

export default ({
    children,
    isOpen,
    className,
    value,
    handleClick,
    icon: Icon,
    refFunction = () => null,
    styles = {},
    calendarIcon = false,
    clearActive,
    disabled = false,
    iconClassName,
    isBlackIcon = true,
    hasArrow = true,
}) => {
    const ref = useHandleOutsideClickHook(refFunction);

    const inputClasses = classNames(style.selectInput, {
        [style.open]: isOpen,
        [style.calendarIcon]: calendarIcon,
        [style.clearActive]: clearActive,
        [style.disabled]: disabled,
        [style.blackIcon]: isBlackIcon,
        [className]: className,
    });

    return (
        <div ref={ref} className={inputClasses} style={styles}>
            <div onClick={handleClick}>
                {Icon && (
                    <span className={classNames(style.icon, { [iconClassName]: iconClassName })}>
                        <Icon />
                    </span>
                )}
                {value && <span className={style.value}>{value}</span>}
                {clearActive && (
                    <>
                        <div
                            className={style.close}
                            data-tip="Clear"
                            data-for="clear"
                            onClick={(event) => {
                                event.stopPropagation();
                                clearActive(event);
                            }}
                        >
                            <IconClose />
                        </div>
                        <TooltipDark id="clear" proximity={4} />
                    </>
                )}
                {!clearActive && hasArrow && <ArrowDown />}
            </div>
            {children}
        </div>
    );
};
