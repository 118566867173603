import React from "react";
import style from "./style.module.scss";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import MaterialUITable from "components/Table/MaterialUITable";
import { ADD_BUDGET_HEAD_CELLS, getAddBudgetTableRows } from "./helpers";
import { Field } from "redux-form";
import RenderInput from "components/RenderInput";

const AddBudgetTable = ({ budgetType, months }) => {
    if (!budgetType) {
        return null;
    }

    return (
        <div className={style.addBudgetContainer}>
            <div className={style.addBudgetTree}></div>
            <div className={style.addBudgetTable}>
                <span>
                    {budgetType === "monthly" ? "Add Monthly Budgets " : "Add Total Budget"}
                </span>
                {budgetType === "monthly" ? (
                    <TableWrapper className={style.tableWrapper}>
                        <MaterialUITable
                            headCells={ADD_BUDGET_HEAD_CELLS}
                            rows={getAddBudgetTableRows(months)}
                        />
                    </TableWrapper>
                ) : (
                    <Field
                        component={RenderInput}
                        labelHidden
                        name={`budgets.total`}
                        type='number'
                        filledPrefix
                        prefix='$'
                    />
                )}
            </div>
        </div>
    );
};

export default AddBudgetTable;
