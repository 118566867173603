import moment from "moment";
import { KpiAPI } from "../../../lib/apiKpi";
import { store } from "../../../_redux";
import Creators from "./actions";
import isEqual from "lodash/fp/isEqual";
import types from "./types";
import merge from "lodash/fp/merge";

const fetchAnomaliesByDate = ({ accountId, endDate, startDate }) =>
    KpiAPI({
        url: "/anomalies",
        query: {
            accountId,
            endDate,
            startDate,
        },
    }).then(({ data }) => {
        return data;
    });

const fetchAllAnomalies = (args) => {
    return fetchAnomaliesByDate(args);
};

const readAnomalyRequest = ({ read, accountId, id }) => {
    const [date, index, type] = id.split("_");

    return KpiAPI({
        method: "PUT",
        url: `/anomalies/${date}/${type}/${index}/read`,
        data: {
            accountId,
            read,
        },
    }).then(({ data }) => data);
};

const getYesterdayAnomaliesData = () => {
    const date = moment()
        .add(-1, "days")
        .format("YYYY-MM-DD");
    const accountId = store.getState().sites.selectedSite._id;
    return KpiAPI({
        method: "get",
        url: `/anomalies`,
        query: {
            accountId,
            date,
        },
    }).then((res) => {
        return res.data;
    });
};

const updateAnomalyNote = ({ date, anomalyType, anomalyId, note }) => {
    const accountId = store.getState().sites.selectedSite._id;
    return KpiAPI({
        method: "put",
        url: `/anomalies`,
        data: {
            accountId,
            date,
            anomalyType,
            anomalyId: anomalyId.toString(),
            note,
        },
    }).then((res) => {
        return res.data;
    });
};

const updateComments = ({ comments = [], accountId, id, date } = {}) => (dispatch, getState) => {
    const {
        anomalies: {
            anomalies: { [accountId]: { [date]: { [id]: { comments: currentComments = {} } = {} } = {} } = {} },
        } = {},
    } = getState();

    if (isEqual(currentComments, comments)) return;

    return dispatch({
        type: types.UPDATE_ANOMALY_COMMENTS,
        comments,
        accountId,
        id,
        date,
    });
};

const deleteComment = ({ anomalyId, accountId, commentId }) => (dispatch) =>
    dispatch({
        type: types.ANOMALY_DELETE_COMMENT,
        data: {
            anomalyId,
            accountId,
            commentId,
        },
        meta: {
            sync: true,
        },
    });

/**
 * Produces anomaly visibility toggle action given the correct type
 * @param {string} type anomaly enumerable: marketing | health | seo
 *
 * @return {function} return anomaly redux visibility action
 */

function anomalyCategoryVisibilityFn(type) {
    return Creators.toggleAnomalyVisibility;
}

const updateAnomalyVisibility = ({ category, accountId, anomalyId, userId }) => (dispatch) => {
    dispatch(anomalyCategoryVisibilityFn(category)({ accountId, anomalyId, userId }));

    return KpiAPI({
        url: "anomalies/dismiss",
        method: "PUT",
        data: {
            anomalyId,
            accountId,
            category,
            userId,
        },
    }).catch((error) => console.log(error));
};

const getAnomaliesByDate = ({ accountId, endDate, startDate }) => (dispatch) =>
    dispatch({
        type: types.ANOMALIES_FETCH,
        promise: fetchAllAnomalies({ accountId, endDate, startDate }),
        meta: {
            accountId,
            endDate,
            startDate,
        },
    });

const getYesterdayAnomalies = () => (dispatch, getState) => {
    dispatch(Creators.getYesterdayAnomaliesPending({ pending: true }));
    dispatch(Creators.getYesterdayAnomaliesRejected({ error: false, payload: {} }));
    return getYesterdayAnomaliesData()
        .then((data) => {
            dispatch(Creators.getYesterdayAnomaliesFufilled({ payload: data }));
            dispatch(Creators.getYesterdayAnomaliesPending({ pending: false }));
            return data;
        })
        .catch((error) => {
            console.warn("getYesterdayAnomalies has failed", error);
            dispatch(Creators.getYesterdayAnomaliesPending({ pending: false }));
            dispatch(
                Creators.getYesterdayAnomaliesRejected({
                    error: true,
                    payload: error,
                }),
            );
        });
};

const updateAnomalyNoteData = ({ date, anomalyType, anomalyId }) => (dispatch, getState) => {
    const state = getState();
    const formData = state.form.anomalyNoteForm.values;
    const note = formData ? formData.note : null;

    let anomalies = state.anomalies.monthlyAnomalies;
    anomalies[date][anomalyType][anomalyId].note = note || "";

    dispatch(Creators.updateAnomalyNoteFufilled({ payload: anomalies }));
    dispatch(Creators.updateAnomalyNotePending({ pending: true }));
    dispatch(Creators.updateAnomalyNoteRejected({ error: false, payload: {} }));
    return updateAnomalyNote({ date, anomalyType, anomalyId, note })
        .then((data) => {
            dispatch(Creators.updateAnomalyNotePending({ pending: false }));
            return data;
        })
        .catch((error) => {
            console.warn("updateAnomalyNote has failed", error);
            dispatch(Creators.updateAnomalyNotePending({ pending: false }));
            dispatch(
                Creators.updateAnomalyNoteRejected({
                    error: true,
                    payload: error,
                }),
            );
        });
};

const commentAnomaly = ({ message, anomalyId, accountId, user, userId, mentions, plainTextMessage }) => (dispatch) =>
    dispatch({
        type: types.ANOMALY_COMMENT,
        data: {
            mentions,
            plainTextMessage,
            message,
            anomalyId,
            accountId,
            user,
            userId,
        },
        meta: {
            sync: true,
        },
    });

const editComment = ({ message, commentId, anomalyId, accountId, mentions, plainTextMessage }) => (dispatch) =>
    dispatch({
        type: types.ANOMALY_EDIT_COMMENT,
        data: {
            message,
            anomalyId,
            accountId,
            commentId,
            mentions,
            plainTextMessage,
        },
        meta: {
            sync: true,
        },
    });

const hasReadAnomaly = ({ read, accountId, id }) => (dispatch, getState) =>
    dispatch({
        type: types.READ_ANOMALY,
        promise: readAnomalyRequest({ read, accountId, id }),
        meta: {
            accountId,
        },
    });

const getInvestigateOptions = ({ accountId, anomalyId, date, channel: filteringChannel = "" }) => (
    dispatch,
    getState,
) => {
    const { anomalies: { anomalies: { [accountId]: { [date]: anomalies = {} } = {} } = {} } = {} } = getState();

    const {
        [anomalyId]: { campaignId = null, campaignName = null, channel, metric: { flag, anomaly } = {} } = {},
    } = anomalies;

    let changes = [];
    if (campaignId) {
        const { [anomalyId]: { metric: { campaignChanges = [] } = {} } = {} } = anomalies;
        changes = [
            {
                campaignName,
                channel,
                anomaly,
                flag,
                campaignChanges,
                date,
            },
        ];
    } else {
        const relatedChanges = Object.keys(anomalies)
            .filter((id) => {
                const { metric: { campaignChanges: { advertisingChannelType = "social" } = {} } = {} } = anomalies[id];

                const filteringChannelName = filteringChannel.toLowerCase() || "";
                const advertisingChannelName = advertisingChannelType.toLowerCase() || "";

                const sameFlag = (flag1, flag2, metric) => {
                    if (metric === "cost") {
                        return flag1 !== flag2;
                    }
                    return flag1 === flag2;
                };

                const sameChannel = (a, b) => {
                    return a === "alltraffic" || a.indexOf(b) !== -1;
                };

                return (
                    sameFlag(anomalies[id].metric.flag, flag, anomalies[id].metric.name) &&
                    anomalies[id].metric.campaignChanges &&
                    sameChannel(filteringChannelName, advertisingChannelName)
                );
            })
            .reduce(
                (cache, id) => ({
                    ...cache,
                    [anomalies[id].campaignId]: {
                        campaignName: anomalies[id].campaignName,
                        channel: anomalies[id].channel,
                        anomaly: anomalies[id].metric.anomaly,
                        flag: anomalies[id].metric.flag,
                        campaignChanges: anomalies[id].metric.campaignChanges,
                        date,
                    },
                }),
                {},
            );

        const uniqueChanges = Object.keys(relatedChanges).map((id) => relatedChanges[id]);
        changes = [].concat(...uniqueChanges);
    }

    return dispatch({
        type: types.INVESTIGATE_ANOMALY,
        meta: {
            accountId,
            anomalyId,
            date,
        },
        data: changes,
    });
};

/**
 * SET ADDITIONAL FILTERS
 */
const setAdditionalAnomalyFilters = (settings) => (dispatch, getState) =>
    dispatch({
        type: types.SET_ADDITIONAL_FILTERS,
        meta: {
            settings,
        },
    });

/**
 * SET DISMISS FILTERS
 */
const setDismissMetricFilters = (metricFilters) => (dispatch, getState) =>
    dispatch({
        type: types.SET_DISMISS_METRIC_FILTERS,
        meta: {
            metricFilters,
        },
    });
const setDismissTypeFilters = (typeFilters) => (dispatch, getState) =>
    dispatch({
        type: types.SET_DISMISS_TYPE_FILTERS,
        meta: {
            typeFilters,
        },
    });

export default {
    fetchAnomaliesByDate,
    readAnomalyRequest,
    getYesterdayAnomaliesData,
    updateAnomalyNote,
    updateComments,
    deleteComment,
    getAnomaliesByDate,
    getYesterdayAnomalies,
    updateAnomalyNoteData,
    commentAnomaly,
    editComment,
    hasReadAnomaly,
    getInvestigateOptions,
    setAdditionalAnomalyFilters,
    updateAnomalyVisibility,
    setDismissTypeFilters,
    setDismissMetricFilters,
};
