export const ADD_BUDGET = "BUDGETS/V2/ADD_BUDGET";
export const EDIT_BUDGET = "BUDGETS/V2/EDIT_BUDGET";
export const DELETE_BUDGET = "BUDGETS/V2/DELETE_BUDGET";
export const UPDATE_BUDGET_ID = "BUDGETS/V2/UPDATE_BUDGET_ID";
export const GET_BUDGETS = "BUDGETS/V2/GET_BUDGETS";
export const GET_BUDGETS_CAMPAIGNS = "BUDGETS/V2/GET_BUDGETS_CAMPAIGNS";
export const TOGGLE_BUDGETS_LOADING = "BUDGETS/V2/TOGGLE_BUDGETS_LOADING";
export const TOGGLE_BUDGET_CAMPAIGNS_LOADING = "BUDGETS/V2/TOGGLE_BUDGET_CAMPAIGNS_LOADING";
export const UPDATE_DATE_RANGES = "BUDGETS/V2/UPDATE_DATE_RANGES";
export const GET_BUDGETS_ANOMALIES = "BUDGETS/V2/GET_BUDGETS_ANOMALIES";
export const TOGGLE_SINGLE_BUDGET_LOADING = "BUDGETS/V2/TOGGLE_SINGLE_BUDGET_LOADING";
export const UPDATE_BUDGETS = "UPDATE_BUDGETS";
