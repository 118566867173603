const REQUEST_BUDGET = "REQUEST_BUDGET";
const REQUEST_LAST_MONTH_BUDGET = "REQUEST_LAST_MONTH_BUDGET";
const GET_GOAL_DATA = "GET_GOAL_DATA";
const GET_KPI_SUMMARY_GA4_DATA = "GET_KPI_SUMMARY_GA4_DATA";
const GET_KPI_SUMMARY_ADWORDS_TOP_TERMS_DATA = "GET_KPI_SUMMARY_ADWORDS_TOP_TERMS_DATA";
const LOADING_SUGGESTION = "LOADING_SUGGESTION";
const GET_KPI_SUMMARY_SHOPIFY_TOP_PRODUCTS_DATA = "GET_KPI_SUMMARY_SHOPIFY_TOP_PRODUCTS_DATA";
const TOGGLE_GOAL_SETTINGS_DRAWER = "TOGGLE_GOAL_SETTINGS_DRAWER";
const UPDATE_REPORT_UI = "UPDATE_REPORT_UI";
const SAVE_REPORT_UI = "SAVE_REPORT_UI";
const GET_REPORT_UI = "GET_REPORT_UI";
const UPDATE_REPORT_UI_ORDER = "UPDATE_REPORT_UI_ORDER";
const SAVE_GOALS = "SAVE_GOALS";
const LOADING_RECENTLY_SELECTED = "LOADING_RECENTLY_SELECTED";
const CLEAR_REPORT_UI_FILTER = "CLEAR_REPORT_UI_FILTER";
const CLEAR_GOAL_DATA = "CLEAR_GOAL_DATA";
const GET_CAMPAIGN_GROUPS_PERFORMANCE = "GET_CAMPAIGN_GROUPS_PERFORMANCE";
const TOGGLE_METRIC_SELECTION = "TOGGLE_METRIC_SELECTION";
const SAVE_KPI_SUMMARY_METRICS = "SAVE_KPI_SUMMARY_METRICS";
const SAVE_GOAL_VALUES = "SAVE_GOAL_VALUES";
const GET_LAST_YEAR_GOAL_DATA = "GET_LAST_YEAR_GOAL_DATA";
const GET_NOTES = "GET_NOTES";
const CREATE_NOTE = "CREATE_NOTE";
const UPDATE_NOTE = "UPDATE_NOTE";
const DELETE_NOTE = "DELETE_NOTE";
const SET_SELECTED_NOTE_ID = "SET_SELECTED_NOTE_ID";
const SET_CREATE_NOTE_DIALOG_VISIBLE = "SET_CREATE_NOTE_DIALOG_VISIBLE";
const SET_REPORTING_UI_PENDING = "SET_REPORTING_UI_PENDING";
const GET_EXTRA_CAMPAIGN_GROUPS_INFO = "GET_CAMPAIGN_EXTRA_GROUPS_INFO";
const SET_CAMPAIGN_GROUP_PERFORMANCE = "SET_CAMPAIGN_GROUP_PERFORMANCE";
const SET_EDITING_CAMPAIGN = "SET_EDITING_CAMPAIGN";
const SET_SHOW_EDIT_CAMPAIGN_MODAL = "SET_EDIT_CAMPAIGN_MODAL";
const UPDATE_PLATFORM_CAMPAIGN = "UPDATE_PLATFORM_CAMPAIGN";
const UPDATE_DISABLE_IMPROVE_BENCHMARK = "UPDATE_DISABLE_IMPROVE_BENCHMARK";

export default {
    UPDATE_REPORT_UI_ORDER,
    REQUEST_BUDGET,
    REQUEST_LAST_MONTH_BUDGET,
    GET_GOAL_DATA,
    GET_KPI_SUMMARY_GA4_DATA,
    GET_KPI_SUMMARY_ADWORDS_TOP_TERMS_DATA,
    GET_KPI_SUMMARY_SHOPIFY_TOP_PRODUCTS_DATA,
    GET_LAST_YEAR_GOAL_DATA,
    SAVE_GOALS,
    LOADING_SUGGESTION,
    TOGGLE_GOAL_SETTINGS_DRAWER,
    UPDATE_REPORT_UI,
    SAVE_REPORT_UI,
    GET_REPORT_UI,
    LOADING_RECENTLY_SELECTED,
    CLEAR_REPORT_UI_FILTER,
    CLEAR_GOAL_DATA,
    GET_CAMPAIGN_GROUPS_PERFORMANCE,
    TOGGLE_METRIC_SELECTION,
    SAVE_KPI_SUMMARY_METRICS,
    SAVE_GOAL_VALUES,
    GET_NOTES,
    CREATE_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,
    SET_SELECTED_NOTE_ID,
    SET_CREATE_NOTE_DIALOG_VISIBLE,
    SET_REPORTING_UI_PENDING,
    GET_EXTRA_CAMPAIGN_GROUPS_INFO,
    SET_CAMPAIGN_GROUP_PERFORMANCE,
    SET_EDITING_CAMPAIGN,
    SET_SHOW_EDIT_CAMPAIGN_MODAL,
    UPDATE_PLATFORM_CAMPAIGN,
    UPDATE_DISABLE_IMPROVE_BENCHMARK,
};
