import React from "react";
import { branch, renderNothing } from "recompose";
import Carousel from "react-slick";
import style from "./style.module.scss";
import carretRight from "../../assets/images/icons/right-carret.png";
import Button from "components/Button";

const DismissButton = branch(
    ({ onDismiss }) => typeof onDismiss !== "function",
    renderNothing,
)(({ onDismiss }) => (
    <button className={style.btnDismiss} onClick={onDismiss}>
        <span>Dismiss</span>
    </button>
));

export const StandardNotification = ({ children, onDismiss }) => (
    <div className={style.notificationContainer}>
        <p className={style.standardNotification}>{children}</p>
        <DismissButton onDismiss={onDismiss} />
    </div>
);

export class NotificationBar extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.state = {
            count: 1,
            show: true,
        };
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const { children } = this.props;

        const { innerSlider: { state: { slideCount } = {} } = {} } = this.slider || {};

        if (!this.state.show) {
            return null;
        }

        return (
            <div className={style.sliderContainer}>
                <Carousel
                    ref={(c) => (this.slider = c)}
                    className={style.container}
                    dots={false}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={false}
                    afterChange={(index) => {
                        this.setState({
                            count: index + 1,
                        });
                    }}
                >
                    {children}
                </Carousel>

                {children.length > 0 && (
                    <Button
                        onClick={() => {
                            this.setState({
                                show: false,
                            });
                        }}
                        className={style.button}
                        small
                        hallow
                    >
                        Hide
                    </Button>
                )}

                {slideCount > 1 && (
                    <div className={style.carouselControls}>
                        <p>
                            <span onClick={this.previous}>
                                {" "}
                                <img alt="previous" src={carretRight} />{" "}
                            </span>{" "}
                            {this.state.count} of {slideCount}{" "}
                            <span onClick={this.next}>
                                {" "}
                                <img alt="next" src={carretRight} />{" "}
                            </span>
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
