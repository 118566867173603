import { MorphioAPI } from "../../lib/api";
import { KpiAPI } from "../../lib/apiKpi";
import { store } from "..";

export const sendSlackMessage = ({ accountId, messages, connectionId }) => {
    return KpiAPI({
        method: "POST",
        url: "/slack/message/test",
        data: {
            messages,
            accountId,
            connectionId,
        },
    }).then(({ data }) => {
        if (data.error) {
            throw new Error(data.messages);
        }

        return data;
    });
};

export const saveMarginRuleRequest = ({ accountId, rule }) => {
    return KpiAPI({
        method: "POST",
        url: `/account/${accountId}/margin`,
        data: rule,
    });
};

export const deleteMarginRuleRequest = ({ accountId, platform }) => {
    return KpiAPI({
        method: "DELETE",
        url: `/account/${accountId}/margin`,
        data: {
            platform,
        },
    });
};

export const testAnomalyEmailRequest = ({ accountId, email }) => {
    return MorphioAPI({
        method: "POST",
        url: `/user/email/anomalies/test`,
        data: {
            accountId,
            email,
        },
    }).then(({ data }) => data);
};

export const testWeeklyEmailRequest = ({ userId, email }) => {
    return MorphioAPI({
        method: "POST",
        url: `/user/email/weeklyNotification/test`,
        data: {
            email,
            userId,
        },
    }).then(({ data }) => data);
};

export const updateAccountStatus = ({ accountId, status }) =>
    MorphioAPI({
        url: `/account/${accountId}/status`,
        method: "PUT",
        data: {
            data: {
                ...status,
            },
        },
    }).then(({ data }) => data);

export const updateDomainData = ({ accountId, domain, location }) =>
    MorphioAPI({
        url: `/account/${accountId}/domain`,
        method: "PUT",
        data: {
            domain,
            city: location,
        },
    }).then(({ data }) => data);

export const updateDashboardKpiCards = ({ accountId, metrics }) =>
    MorphioAPI({
        url: `/account/${accountId}/dashboardmetrics`,
        method: "PUT",
        data: { accountId, metrics },
    }).then(({ data }) => data);

export const updateSeoVitalsData = ({ accountId, seoAuditSettings }) =>
    MorphioAPI({
        url: `/account/${accountId}/seovitals`,
        method: "PUT",
        data: {
            seoAuditSettings,
        },
    }).then(({ data }) => data);

export const updatePageHealthPlatformData = ({ accountId, pageHealthSettings }) =>
    MorphioAPI({
        url: `/account/${accountId}/pagehealth`,
        method: "PUT",
        data: {
            pageHealthSettings,
        },
    }).then(({ data }) => data);

export const updateAccountKeywords = ({ keywords, accountId: acId }) => {
    const accountId = acId ? acId : store.getState().sites.selectedSite._id;

    const params = {
        keywords,
    };

    return MorphioAPI({
        url: `/account/${accountId}/keywords`,
        method: "PUT",
        data: {
            ...params,
        },
    }).then(({ data }) => data);
};

export const updateAccountCompetitors = ({ competitors, accountId: acId }) => {
    const accountId = acId ? acId : store.getState().sites.selectedSite._id;
    return MorphioAPI({
        url: `/account/${accountId}/competitors`,
        method: "PUT",
        data: {
            competitors,
        },
    }).then(({ data }) => data);
};

export const generateCompetitorsReport = ({ accountId }) =>
    MorphioAPI({
        url: `/account/${accountId}/competitors/generateReport`,
        method: "POST",
    }).then(({ data }) => data);

export const fetchSite = (siteId) => {
    return MorphioAPI({
        url: `account?account_id=${siteId}`,
    }).then(({ data }) => {
        return data;
    });
};

export const fetchSiteSettings = (siteID) => {
    return MorphioAPI({
        url: "account?account_id=" + siteID,
        method: "get",
    });
};

export const updateSiteSettings = (data) => {
    return MorphioAPI({
        url: "account",
        method: "PUT",
        data: data,
    }).then(({ data }) => {
        return data;
    });
};

export const generatePlacementAuditing = ({ accountId }) =>
    MorphioAPI({
        url: `/account/${accountId}/placements`,
        method: "POST",
    }).then(({ data }) => data);
