import React from "react";

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1280" height="1280" viewBox="0 0 2048 2048">
            <path
                fill="#045FFE"
                d="M855 275h567l-2 5-15 32-26 56-9 20-8 16-12 26-28 60-17 37-15 32-12 26v4l8 9 9 11 11 13 9 11 12 14 9 11 11 13 12 14 9 11 22 26 9 11 11 13 9 11 11 13 9 10 7 9 9 10 11 14 12 14 9 11 11 13 10 12 9 11 8 9 7 9 9 10 9 11 11 13 13 17 14 19 11 17 9 15 10 18 10 19 13 30 10 27 8 26 8 32 6 34 2 15 3 33v57l-4 41-7 39-9 36-12 36-8 20-12 27-13 25-12 20-12 18-13 19-8 10-7 9-9 10-8 10-38 38-14 11-9 8-16 12-11 8-18 12-22 13-26 14-19 9-27 11-26 9-27 8-36 8-26 4-36 4h-15l-1 1H626l1-5 13-27 15-33 14-29 12-26 10-22 7-15 13-28 7-15 13-28 7-15 17-36 12-26 3-6-2-5-6-8-9-10-9-11-10-12-7-8-14-17-7-8-8-10-9-10-10-13-10-11-7-9-10-11-7-9-12-14-8-10-11-13-7-8-9-11-8-10-8-9-9-11-7-8-8-10-8-9-8-10-11-13-9-11-8-9-7-9-9-10-9-11-13-16-13-18-10-15-12-19-13-23-8-16-15-35-7-19-10-31-8-33-4-21-3-20-3-30-1-25v-20l1-29 4-35 4-23 6-29 10-35 10-28 11-26 15-31 14-24 11-18 9-13 12-16 11-14 9-10 7-8 9-11 20-20 11-9 12-11 14-11 13-10 20-14 18-11 15-9 33-17 25-11 21-8 21-7 26-7 26-6 29-5 26-3zM662 590l-17 2-18 6-16 9-11 9-5 4-11 14-6 10-6 15-4 16-2 12v7l4 24 6 15 6 11 8 11 12 14 9 11 12 14 9 11 6 7 9 11 8 9 9 11 12 14 9 11 22 26 9 11 9 10 8 10 11 13 9 11 11 13 14 17 9 10 8 10 12 14 8 10 8 9 8 10 12 14 8 10 8 9 8 10 9 10 9 11 10 12 9 11 11 13 7 8 13 16 9 10 7 9 9 10 11 14 7 8 2 1-1 5-7 16-16 34-13 28-16 34-13 28-14 30-15 32-12 26-3 6v2h522l21-5 13-6 14-8 12-11 7-7 10-16 5-10 4-13 2-11v-27l-2-12-8-20-8-13-18-22-8-9-8-10-7-8-9-11-12-14-8-10-7-8-9-11-8-9-9-11-11-13-9-11-14-17-9-10-7-9-9-10-8-10-8-9-8-10-11-13-9-11-8-9-7-9-9-10-8-10-9-10-7-9-11-13-9-11-9-10-7-9-9-10-7-9-9-10-6-8-10-11-7-9-12-14-8-10-9-10-7-9-9-10-7-9-5-7 8-16 19-41 9-19 7-16 8-16 13-29 8-16 12-26 16-34 6-14 5-10-1-1z"
            ></path>
        </svg>
    );
}

export default Icon;
