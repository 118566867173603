import React from "react";
import { withPermissions } from "features/Permissions";
import { Icon } from "components/Icon/PlatformIcon";
import style from "./style.module.scss";
import Button from "components/Button";
import { history } from "../../_redux";
import ZeroState from "components/ZeroStates/Icon";
import Integrations from "assets/images/Menu/JSX/Integrations";
import AddIcon from "assets/images/Dashboard/JSX/Add";
import { mixpanel } from "components/Mixpanel";
import ChatGPTIcon from "assets/images/icons/JSX/Icon-ChatGPT";

export const ChatGptNeeded = ({ message }) => (
    <ZeroState
        title="You don't have the ChatGPT feature enabled."
        message={message}
        icon={ChatGPTIcon}
        containerClass={style.chatGpt}
        hasBorder={true}
    >
        <Button medium onClick={() => (window.location = `mailto:support@hawke.ai`)}>
            Contact Us
        </Button>
    </ZeroState>
);

export const AdPlatformZeroState = ({ accountId, hasBorder = false }) => (
    <ZeroState
        title="Integrate an Advertising Platform"
        message="To use this feature, please connect your ad platforms to Hawke.ai. If you have already integrated your ad platforms and still do not see the report, please allow some time for the data to sync or contact our support team for assistance."
        icon={() => <Integrations fill="#0671EE" />}
        containerClass={style.zeroStatePlatforms}
        hasBorder={hasBorder}
    >
        <Button
            medium
            onClick={() => {
                mixpanel.track("Button Click", {
                    "Button Name": "Zero State Ad Platforms",
                    "Button Text": "Zero State Ad Platforms",
                });
                history.push(`/dashboard/${accountId}/settings/integrations`);
            }}
        >
            Integrate Ad Platforms
        </Button>
    </ZeroState>
);

export const NoIntegrationsZeroState = ({
    accountId,
    hasBorder = false,
    message = "To use this feature, please connect your ad platforms to Hawke.ai. If you have already integrated your ad platforms and still do not see the report, please allow some time for the data to sync or contact our support team for assistance.",
}) => (
    <ZeroState
        title="Integration Required"
        message={message}
        icon={() => <Integrations fill="#0671EE" />}
        containerClass={style.noIntegrations}
        hasBorder={hasBorder}
    >
        <Button icon={AddIcon} medium onClick={() => history.push(`/dashboard/${accountId}/settings/integrations`)}>
            Add Integration
        </Button>
    </ZeroState>
);
