import React from "react";

export default ({ className = "" }) => (
    <svg
        className={className && className}
        fill="#A0B5C4"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="16"
        viewBox="0 0 10 16"
    >
        <path
            d="M4.36294509,16.3927891 L0.263838194,12.1690086 C-0.0879460648,11.8110704 -0.0879460648,11.2305666 0.263838194,10.8726284 C0.615694526,10.5146168 1.18607367,10.5146168 1.53793001,10.8726284 L4.09908887,13.5315566 L4.09909788,1.57648187 C4.09909788,1.07026438 4.50248581,0.659819746 5,0.659819746 C5.49744211,0.659819746 5.90090212,1.07026438 5.90090212,1.57648187 L5.90089311,13.5315566 L8.46205198,10.8727751 C8.81390831,10.5147635 9.38428746,10.5147635 9.73614379,10.8727751 C9.91192781,11.0517075 10,11.286373 10,11.5209652 C10,11.7555573 9.91192781,11.9901495 9.73614379,12.1691553 L5.6370369,16.3927891 C5.28518057,16.7508007 4.71480142,16.7508007 4.36294509,16.3927891 Z"
            transform="translate(0 -.66)"
        />
    </svg>
);
