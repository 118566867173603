import React from "react";

export default ({ className }) => (
    <svg
        className={className}
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="0.1-Icons" transform="translate(-482.000000, -608.000000)" fill="#6A80A7" fillRule="nonzero">
            <g id="Framework" transform="translate(140.000000, 60.000000)">
                <g id="Features-Copy" transform="translate(0.000000, 480.000000)">
                    <g id="3-copy-2" transform="translate(322.000000, 48.000000)">
                        <path
                            d="M29.9491952,22.6701939 L33.2039571,25.940865 L24.9652559,34.2199138 L21.7123393,30.9492427 L29.9491952,22.6701939 Z M35.6736919,21.881383 L34.2221933,20.4227759 C33.6612392,19.8590747 32.7503657,19.8590747 32.1875058,20.4227759 L30.7971153,21.8199756 L34.0518772,25.0906772 L35.6736919,23.4609199 C36.1087694,23.0236813 36.1087694,22.3185912 35.6736919,21.881383 Z M20.0090572,35.5464342 C19.9498247,35.8143162 20.1905059,36.0543521 20.4571126,35.9892056 L24.0839986,35.1055173 L20.831082,31.8348461 L20.0090572,35.5464342 Z"
                            id="Icon-Edit"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
