import React, { useEffect, useState } from "react";
import qs from "querystring";
import HelpCard from "components/HelpCard";
import WarningCard from "components/WarningCard";

export default ({ exchangeCode }) => {
    const { code, hmac, shop, state, timestamp } =
        qs.parse(window.location.search.substr(1)) || {};

    const [loading, toggleLoading] = useState(false);
    const [error, toggleError] = useState(false);

    if (!code || !hmac || !timestamp || !state) {
        return null;
    }

    useEffect(() => {
        toggleError(false);
        toggleLoading(true);
        exchangeCode({
            code,
            hmac,
            shop,
            state,
            timestamp
        })
            .catch((error) => {
                toggleError(true);
            })
            .finally(() => {
                toggleLoading(false);
            });
    }, []);

    return (
        <div>
            {!error ? (
                <HelpCard
                    title={
                        loading ? `Shopify Installing...` : "Shopify Installed!"
                    }
                    subtitle={
                        loading
                            ? `Shopify is installing please wait a moment`
                            : `Your shop ${shop} has installed successfully!`
                    }
                />
            ) : (
                <WarningCard
                    title="Shopify Install failed"
                    subtitle="Shopify has failed to install. Please try again. If your problem persists please contact support."
                />
            )}
        </div>
    );
};
