import { connect } from "react-redux";
import { withRouter } from "react-router";
import { matchPath } from "react-router-dom";
import { compose } from "recompose";
import { preloadData } from "../_redux/preload";
import App from "./App";
import { makeSelectAccounts } from "features/ControlPanel/ducks/selectors";
import { makeSelectPreloadPending, makeSelectPreloadError } from "_redux/preload/selectors";
import { makeSelectAccountId } from "_redux/sites/selectors";

const mapStateToProps = (
    state,
    {
        history: {
            location: { pathname },
        },
    },
) => {
    const available = makeSelectAccounts()(state);

    const { params: { siteId: accountId } = {} } =
        matchPath(pathname, {
            path: "/dashboard/:siteId",
        }) || {};

    const hasAccountId = /^[a-f0-9]{24}$/.exec(accountId);

    return {
        //.. do not pass anything down to APP container to keep it from repainting the dom
        stateAccountId: makeSelectAccountId()(state),
        urlAccountId: hasAccountId ? accountId : false,
        hasSites: (available[0] || {})._id || available.length > 0,
        pendingState: makeSelectPreloadPending()(state),
        errorState: makeSelectPreloadError()(state),
    };
};

const mapDispatchToProps = (
    dispatch,
    {
        history: {
            location: { pathname },
        },
    },
) => ({
    preloadData: () => {
        const { params: { siteId: accountId } = {} } =
            matchPath(pathname, {
                path: "/dashboard/:siteId",
            }) || {};
        const hasAccountId = /^[a-f0-9]{24}$/.exec(accountId);

        if (hasAccountId) {
            return dispatch(preloadData(accountId));
        }

        return dispatch(preloadData());
    },
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
