import React from "react";
import PermissionsContext from "./";

export default class PremissionsProvider extends React.Component {
    isAllowed(requestedPlatforms) {
        //Memoize?
        if (!requestedPlatforms) {
            return true;
        }

        const { platforms } = this.props;
        if (typeof requestedPlatforms === "string") {
            if (requestedPlatforms.includes("analyticsV4")) {
                return Boolean(platforms.analyticsV4);
            }
            if (requestedPlatforms.includes("adwords")) {
                return Boolean(platforms.adwords);
            }
            if (requestedPlatforms.includes("googleBusinessProfile")) {
                return Boolean(platforms.googleBusinessProfile);
            }
            return Boolean(platforms[requestedPlatforms]);
        }

        return requestedPlatforms.every((platform) => {
            if (platform.includes("analyticsV4")) {
                return Boolean(platforms.analyticsV4);
            }
            if (platform.includes("adwords")) {
                return Boolean(platforms.adwords);
            }
            if (platform.includes("googleBusinessProfile")) {
                return Boolean(platforms.googleBusinessProfile);
            }
            return Boolean(platforms[platform]);
        });
    }

    isAuthorized(actions) {
        if (!actions) {
            return true;
        }

        const { permissions } = this.props;

        if (!permissions) {
            return false;
        }

        if (typeof actions === "function") {
            return this.validate(actions(permissions));
        }

        return this.validate(actions);
    }

    authorizationMessage(actions) {
        if (!actions) {
            return null;
        }

        const { reasons } = this.props;

        if (typeof actions === "string") {
            return { [actions]: reasons[actions] };
        }

        return actions.reduce((obj, action) => {
            return {
                ...obj,
                [action]: reasons[action],
            };
        }, {});
    }

    validate(actions) {
        const { permissions } = this.props;

        if (typeof actions === "string") {
            return permissions.hasOwnProperty(actions);
        }

        return actions.every((action) => {
            return permissions.hasOwnProperty(action) && permissions[action] === action;
        });
    }

    render() {
        const { platforms, children, isEcommerce } = this.props;
        return (
            <PermissionsContext.Provider
                value={{
                    isAllowed: this.isAllowed.bind(this),
                    isAuthorized: this.isAuthorized.bind(this),
                    message: this.authorizationMessage.bind(this),
                    platforms,
                    isEcommerce,
                }}
            >
                {children}
            </PermissionsContext.Provider>
        );
    }
}
