import types from "./types";
import moment from "moment";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { KpiAPI } from "lib/apiKpi";
import { divide } from "lib/metricCalculations";

const getReport = ({ queryParams, startDate, endDate, accountId, channel, mappingResult }) => {
    return KpiAPI({
        method: "GET",
        url: `/analyticsV4/report`,
        query: {
            ...queryParams,
            startDate,
            endDate,
            accountId,
            channel,
        },
    }).then(({ data = [] } = {}) =>
        data.map((item) => {
            return {
                startDate,
                endDate,
                ...(mappingResult ? mappingResult(item) : item),
            };
        }),
    );
};

// GET CHANNELS DATA
const getChannelData = ({ dateRange, channel }) => async (dispatch, getState) => {
    const accountId = makeSelectAccountId()(getState());
    return dispatch({
        type: types.GET_GA4_DATA,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                getChannels({
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    accountId,
                    channel,
                }),
            ),
        ),
    });
};

const mappingChannelData = (item) => ({
    bounces: parseFloat(item.sessions) - parseFloat(item.engagedSessions),
    screenPageViews: parseFloat(item.screenPageViewsPerSession) * parseFloat(item.sessions),
    ...item,
});

export const getChannels = ({ startDate, endDate, accountId, channel }) => {
    return getReport({
        queryParams: {
            dimensions: "sessionDefaultChannelGrouping",
            metrics: [
                "sessions",
                "conversions",
                "screenPageViewsPerSession",
                "engagedSessions",
                "activeUsers",
                "totalUsers",
                "newUsers",
                "transactions",
                "totalRevenue",
                "averageSessionDuration",
            ].join(","),
        },
        startDate,
        endDate,
        accountId,
        channel,
        mappingResult: mappingChannelData,
    });
};

// GET EVENTS DATA
export const getEventsData = ({ dateRange, channel }) => async (dispatch, getState) => {
    const accountId = makeSelectAccountId()(getState());
    return dispatch({
        type: types.GET_GA4_EVENTS_DATA,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                getEvents({
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    accountId,
                    channel,
                }),
            ),
        ),
    });
};

const mappingEventData = (item) => ({
    eventsPerUser: divide(parseInt(item.eventCount), parseInt(item.totalUsers)),
    ...item,
});

export const getEvents = ({ startDate, endDate, accountId, channel }) => {
    return getReport({
        queryParams: {
            dimensions: "eventName",
            metrics: ["eventCount", "totalUsers", "purchaseRevenue"].join(","),
        },
        startDate,
        endDate,
        accountId,
        channel,
        mappingResult: mappingEventData,
    });
};

// GET LANDING PAGE REPORT DATA
export const getLandingPageData = ({ dateRange, channel }) => async (dispatch, getState) => {
    const accountId = makeSelectAccountId()(getState());
    return dispatch({
        type: types.GET_GA4_LANDING_PAGE_DATA,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                getLandingPage({
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    accountId,
                    channel,
                }),
            ),
        ),
    });
};

const mappingLandingPageData = (item) => ({
    sessionConversions: Math.ceil(parseFloat(item.sessionKeyEventRate) * parseInt(item.sessions)),
    ...item,
});

export const getLandingPage = ({ startDate, endDate, accountId, channel }) => {
    return getReport({
        queryParams: {
            dimensions: "landingPage",
            metrics: [
                "sessions",
                "activeUsers",
                "totalUsers",
                "conversions",
                "totalRevenue",
                "sessionKeyEventRate",
                "engagedSessions",
            ].join(","),
        },
        startDate,
        endDate,
        accountId,
        channel,
        mappingResult: mappingLandingPageData,
    });
};

// GET DEVICES REPORT DATA
export const getDevicesData = ({ dateRange, channel }) => async (dispatch, getState) => {
    const accountId = makeSelectAccountId()(getState());
    return dispatch({
        type: types.GET_GA4_DEVICES_DATA,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                getDevices({
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    accountId,
                    channel,
                }),
            ),
        ),
    });
};

const mappingDeviceData = (item) => ({
    sessionConversions: Math.ceil(parseFloat(item.sessionKeyEventRate) * parseInt(item.sessions)),
    ...item,
});

export const getDevices = ({ startDate, endDate, accountId, channel }) => {
    return getReport({
        queryParams: {
            dimensions: "deviceCategory",
            metrics: [
                "sessions",
                "totalUsers",
                "activeUsers",
                "conversions",
                "totalRevenue",
                "sessionKeyEventRate",
                "engagedSessions",
            ].join(","),
        },
        startDate,
        endDate,
        accountId,
        channel,
        mappingResult: mappingDeviceData,
    });
};

// GET USER AGE RANGE REPORT DATA
export const getUserAgeRangeData = ({ dateRange, channel }) => async (dispatch, getState) => {
    const accountId = makeSelectAccountId()(getState());
    return dispatch({
        type: types.GET_GA4_USER_AGE_RANGE_DATA,
        promise: Promise.all(
            dateRange.map(({ start, end }) =>
                getUserAgeRange({
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    accountId,
                    channel,
                }),
            ),
        ),
    });
};

const mappingUserAgeRangeData = (item) => ({
    sessionConversions: Math.ceil(parseFloat(item.sessionKeyEventRate) * parseInt(item.sessions)),
    ...item,
});

export const getUserAgeRange = ({ startDate, endDate, accountId, channel }) => {
    return getReport({
        queryParams: {
            dimensions: "userAgeBracket",
            metrics: [
                "sessions",
                "totalUsers",
                "activeUsers",
                "conversions",
                "totalRevenue",
                "sessionKeyEventRate",
                "engagedSessions",
            ].join(","),
        },
        startDate,
        endDate,
        accountId,
        channel,
        mappingResult: mappingUserAgeRangeData,
    });
};

export default {
    getChannels,
    getChannelData,
    getEvents,
    getEventsData,
    getLandingPage,
    getLandingPageData,
    getDevices,
    getDevicesData,
    getUserAgeRange,
    getUserAgeRangeData,
};
