import React from "react";
import style from "./style.module.scss";
import UpgradeIcon from "assets/images/icons/JSX/Icon-Upgrade-Circle";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import PageLoadingSpinner from "./PageLoadingSpinner";
import { TooltipDark } from "components/Tooltip";
import { IconImage } from "components/Icon";
import NavArrow from "assets/images/icons/Arrow-Nav.svg";

export const FremiumIcon = ({ icon: Icon, onClick, active, label = "NO LABEL SET", isNavOpen = false }) => {
    return (
        <div
            onClick={onClick}
            className={`${style.customIconButton} ${active && style.active} ${active && style.featured}`}
        >
            <div className={style.icon}>
                <IconImage icon={Icon} alt={label} />
            </div>

            <SwitchTransition mode="out-in">
                <CSSTransition
                    in={isNavOpen}
                    key={isNavOpen}
                    classNames={{
                        enter: style.fremiumEnter,
                        enterActive: style.fremiumEnterActive,
                        exit: style.fremiumExit,
                        exitActive: style.fremiumExitActive,
                    }}
                    timeout={600}
                >
                    <UpgradeIcon />
                </CSSTransition>
            </SwitchTransition>

            <div className={style.label}>{label}</div>
        </div>
    );
};

export default ({
    icon: Icon,
    onClick,
    active,
    isLoading,
    label = "NO LABEL SET",
    isNavOpen = false,
    dot,
    count = 0,
    hasSubNav = false,
}) => {
    return (
        <div
            onClick={onClick}
            className={`${style.customIconButton} ${active && style.active} ${active && style.featured}`}
        >
            <div className={style.icon}>
                <IconImage icon={Icon} alt={label} />
                {isLoading && <PageLoadingSpinner key="spinner" />}
            </div>

            {Boolean(dot) && (
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        in={isNavOpen}
                        key={isNavOpen}
                        classNames={{
                            enter: style.contentEnter,
                            enterActive: style.contentEnterActive,
                            exit: style.contentExit,
                            exitActive: style.contentExitActive,
                        }}
                        timeout={600}
                    >
                        <div className={style.countDotWrapper}>
                            {isNavOpen && count > 0 && (
                                <span
                                    className={style.competitorCount}
                                    data-tip="Negative Changes"
                                    data-for={`tip-${label.replace(/\W/g, "_")}`}
                                >
                                    {count}
                                </span>
                            )}
                            {(!isNavOpen || !count) && <div className={style.redDot}></div>}
                            <TooltipDark id={`tip-${label.replace(/\W/g, "_")}`} position="right" delay />
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            )}
            <div className={style.label}>{label}</div>
            {hasSubNav && isNavOpen && <img className={style.navArrow} src={NavArrow} alt="Nav arrow" />}
        </div>
    );
};
