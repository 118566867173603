import types from "./types";

const getYesterdayAnomaliesFufilled = ({ payload } = {}) => ({
    type: types.GET_YESTERDAY_ANOMALIES_FULFILLED,
    payload,
});

const toggleAnomalyVisibility = ({ accountId, anomalyId, userId }) => ({
    type: types.TOGGLE_MARKETING_ANOMALY_VISIBILITY,
    payload: {
        anomalyId,
        accountId,
        userId,
    },
});

const getYesterdayAnomaliesRejected = ({ error, payload } = {}) => ({
    type: types.GET_YESTERDAY_ANOMALIES_REJECTED,
    error,
    payload,
});

const getYesterdayAnomaliesPending = ({ pending } = {}) => ({
    type: types.GET_YESTERDAY_ANOMALIES_PENDING,
    pending,
});

const updateAnomalyNoteFufilled = ({ payload } = {}) => ({
    type: types.UPDATE_ANOMALY_NOTE_FULFILLED,
    payload,
});

const updateAnomalyNoteRejected = ({ error, payload } = {}) => ({
    type: types.UPDATE_ANOMALY_NOTE_REJECTED,
    error,
    payload,
});

const updateAnomalyNotePending = ({ pending } = {}) => ({
    type: types.UPDATE_ANOMALY_NOTE_PENDING,
    pending,
});

const toggleDatesChanging = (datesChanging) => ({
    type: types.TOGGLE_DATES_CHANGING,
    datesChanging,
});

export default {
    getYesterdayAnomaliesFufilled,
    getYesterdayAnomaliesRejected,
    getYesterdayAnomaliesPending,
    updateAnomalyNoteFufilled,
    updateAnomalyNoteRejected,
    updateAnomalyNotePending,
    toggleAnomalyVisibility,
    toggleDatesChanging,
};
