import React from "react";
import defaultImage from "assets/images/icon-placeholder.png";
import adWordsImage from "assets/images/Integrations/Logo-Ads.svg";
import facebookImage from "assets/images/Integrations/Logo-Facebook.svg";
import DownArrow from "assets/images/Dashboard/Icon-Arrow-Down-Small.svg";
import DownArrowTiny from "assets/images/icons/Icon-Arrow-Tiny.svg";
import IconDash from "assets/images/icons/Icon-Dash.svg";
import style from "./styles.module.scss";
import PropTypes from "prop-types";

export const DefaultImage = ({ image = defaultImage }) => (
    <span className={style.icon}>
        <img src={image} role="presentation" />
    </span>
);

export const IconImage = ({ icon: Icon, alt, className = "" }) => {
    //Render a component with a symbol ex <Icon/>
    if (typeof Icon === "object") {
        if (React.isValidElement(Icon)) {
            return <div className={`${style.objectSymbol} ${className}`}>{Icon}</div>;
        }
        return <div className={`${style.objectSymbol} ${className}`}>{<Icon />}</div>;
    }

    //Render a component with a componenet ex () => <Icon />
    if (typeof Icon === "function") {
        return (
            <div className={`${style.objectSymbol} ${className}`}>
                <Icon />
            </div>
        );
    }

    //Render a image with a string ex "{pathToFile}.img"
    if (typeof Icon === "string") {
        return <img alt={alt} src={Icon} className={className} />;
    }

    return null;
};

PropTypes.IconImage = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    label: PropTypes.string,
    className: PropTypes.string,
};

export const ArrowIcon = ({ value = "0", metric = "", noMargin = false }) => {
    const getSign = (value, metric) => {
        // clean up value
        value = Number(value.replace(/[^0-9\-]/g, ""));
        const reverseMetrics = [
            "cpa",
            "cpl",
            "cost",
            "spend",
            "cpa paid",
            "cpa overall",
            "cpl paid",
            "cpl overall",
            "searchlostisrank",
            "searchlostisbudget",
            "displaylostisrank",
            "displaylostisbudget",
        ];

        if (isNaN(value) || value === 0) {
            return { icon: <img alt="dash" src={IconDash} width={6} height="auto" />, style: style.ArrowIconBlank };
        } else if (value > 0) {
            if (reverseMetrics.includes(metric)) {
                return {
                    icon: <img alt="uparrow" src={DownArrowTiny} width={6} height={8} />,
                    style: `${style.ArrowIconRed}`,
                };
            }
            return {
                icon: <img alt="uparrow" src={DownArrowTiny} width={6} height={8} />,
                style: "",
            };
        } else if (value < 0) {
            if (reverseMetrics.includes(metric)) {
                return {
                    icon: <img alt="down arrow" src={DownArrowTiny} width={6} height={8} />,
                    style: `${style.ArrowIconDown}`,
                };
            }
            return {
                icon: <img alt="down arrow" src={DownArrowTiny} width={6} height={8} />,
                style: `${style.ArrowIconRed} ${style.ArrowIconDown}`,
            };
        }
    };

    const signObject = getSign(value, metric);

    return (
        <span className={`${style.ArrowIcon} ${signObject.style} ${noMargin && style.noMargin}`}>
            {signObject.icon}
        </span>
    );
};

export const UpDownIcon = () => (
    <span className={style.icon}>
        <img alt="down arrow" src={DownArrow} />
    </span>
);

export const AdWordsImage = ({ image = adWordsImage }) => (
    <span className={style.icon}>
        <img src={image} role="presentation" />
    </span>
);

export const FacebookImage = ({ image = facebookImage }) => (
    <span className={style.icon}>
        <img src={image} role="presentation" />
    </span>
);

export const IconCircle = ({ image = false, children }) => (
    <span className={style.iconCircle}>
        {image && <img src={image} role="presentation" />}
        {children}
    </span>
);
