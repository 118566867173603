import types from "./types";
import { MorphioAPI } from "lib/api";

export const getUserPermissions = (userId, planId) => ({
    type: types.POPULATE_PERMISSIONS,
    promise: MorphioAPI({
        method: "GET",
        url: `permissions/user/${userId}`,
        query: {
            planId,
        },
    }).then(({ data }) => {
        return data;
    }),
});
