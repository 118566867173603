import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";

const defaultState = {
    budgetPlannerData: {},
    budgetPlannerAverageMetrics: {},
    budgetPlannerLoading: false,
    budgetPlannerError: false,
};

export default createReducer(defaultState, {
    [types.GET_BUDGET_PLANNER_DATA]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                budgetPlannerLoading: true,
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    budgetPlannerData: action?.payload?.data?.optimized,
                    budgetPlannerAverageMetrics: action?.payload?.data?.metricsAverages,
                    budgetPlannerLoading: false,
                };
            },
            failure: (prevState) => ({
                ...prevState,
                budgetPlannerLoading: false,
                budgetPlannerError: true,
                budgetPlannerData: {},
            }),
        }),
});
