import React from "react";
import style from "./style.module.scss";
import TabNavigation from "../TabNavigation";
import { compose, withState, withHandlers, withProps } from "recompose";
import Tab from "..";

const tabs = compose(
    withState("tabType", "changeTabType", ({ initialTab }) => initialTab || 0),
    withHandlers({
        changeType: ({ changeTabType }) => (type) => changeTabType(type),
    }),
    withProps(({ changeType, tabType, onChange }) => ({
        onChange: (tabType) => (typeof onChange === "function" ? onChange(tabType, changeType) : changeType(tabType)),
    })),
);

export default tabs(
    ({ children, className, tabType, customTabType, tabs, onChange, staticComponent = null, tipProximity }) => {
        return (
            <div className={style.smallTabs}>
                <TabNavigation
                    className={`${style.tabNavigation} ${className}`}
                    tabType={customTabType ? customTabType : tabType}
                    tabs={tabs}
                    changeTabType={onChange}
                    customStyles={{
                        activeTab: style.activeTab,
                        inactiveTab: style.inactiveTab,
                    }}
                    tipProximity={tipProximity}
                />
                <Tab
                    tabType={customTabType ? customTabType : tabType}
                    onChange={onChange}
                    tabs={tabs}
                    staticComponent={staticComponent}
                >
                    {children}
                </Tab>
            </div>
        );
    },
);
