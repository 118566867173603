import createReducer from "../../lib/createReducer";
import { requestCampaignPerformance, requestAllChannelData } from "./async";
import moment from "moment";
import _ from "lodash/fp";
import { getSelectedCampaignRegex } from "../../lib/apiKpi";

/**
 *  ACTION TYPES
 */

const GET_GROUP_PERFORMANCE_REJECTED = "GET_GROUP_PERFORMANCE_REJECTED";
const GET_GROUP_PERFORMANCE_FUFILLED = "GET_GROUP_PERFORMANCE_FUFILLED";
const GET_GROUP_PERFORMANCE_PENDING = "GET_GROUP_PERFORMANCE_PENDING";
const CLEAR_GROUP_PERFORMANCE = "CLEAR_GROUP_PERFORMANCE";

/**
 *  ACTIONS
 */

export const clearGroupPerformance = () => ({
    type: CLEAR_GROUP_PERFORMANCE
});

export const getGroupPerformancePending = ({ pending } = {}) => ({
    type: GET_GROUP_PERFORMANCE_PENDING,
    pending
});

export const getGroupPerformanceFufilled = ({ payload } = {}) => ({
    type: GET_GROUP_PERFORMANCE_FUFILLED,
    payload
});

export const getGroupPerformanceRejected = ({ error, payload } = {}) => ({
    type: GET_GROUP_PERFORMANCE_REJECTED,
    error,
    payload
});

export const getGroupPerformance = ({ dateRange }) => {
    return (dispatch, getState) => {
        const state = getState();
        const campaignRegex = getSelectedCampaignRegex(state);
        dispatch(getGroupPerformancePending({ pending: true }));
        dispatch(getGroupPerformanceRejected({ error: false, payload: {} }));

        return Promise.all([
            requestAllChannelData({
                scope: ["adwords"],
                dateRange,
                state
            }),
            requestCampaignPerformance({
                dateRange: [
                    {
                        start: moment(dateRange.start).format("YYYY-MM-DD"),
                        end: moment(dateRange.end).format("YYYY-MM-DD")
                    }
                ],
                campaignRegex
            })
        ])
            .then(([channelData, campaignData]) => {
                const {
                    adwordsDisplay,
                    adwordsSearch,
                    adwordsVideo
                } = channelData;

                dispatch(
                    getGroupPerformanceFufilled({
                        payload: {
                            campaign: campaignData,
                            video: adwordsVideo,
                            search: adwordsSearch,
                            display: adwordsDisplay
                        }
                    })
                );
                dispatch(getGroupPerformancePending({ pending: false }));
            })
            .catch((err) => {
                console.log(err);
                dispatch(
                    getGroupPerformanceRejected({ error: true, payload: err })
                );
                dispatch(getGroupPerformancePending({ pending: false }));
                throw new Error(
                    "Something went wrong with getting the user's group performance reports"
                );
            });
    };
};

/**
 * REDUCERS
 */

const defaultState = {
    groupPerformance: [],
    display: {
        byDate: [],
        byDevice: []
    },
    search: {
        byDate: [],
        byDevice: []
    },
    video: {
        byDate: [],
        byDevice: []
    },
    month: moment(),
    dateRange: {
        start: moment().subtract(7, "days")["_d"],
        end: moment().subtract(1, "days")["_d"],
        compareToStart: null,
        compareToEnd: null
    },
    groupPerformanceError: false,
    groupPerformancePending: false
};

export const kpi = createReducer(defaultState, {
    [CLEAR_GROUP_PERFORMANCE](state, action) {
        return {
            ...state,
            groupPerformance: [],
            display: {
                byDate: [],
                byDevice: []
            },
            search: {
                byDate: [],
                byDevice: []
            },
            video: {
                byDate: [],
                byDevice: []
            }
        };
    },
    [GET_GROUP_PERFORMANCE_FUFILLED](state, action) {
        return {
            ...state,
            error: false,
            groupPerformance: action.payload.campaign,
            display: action.payload.display,
            search: action.payload.search,
            video: action.payload.video
        };
    },
    [GET_GROUP_PERFORMANCE_REJECTED](state, action) {
        return {
            ...state,
            groupPerformanceError: action.error
        };
    },
    [GET_GROUP_PERFORMANCE_PENDING](state, action) {
        return {
            ...state,
            groupPerformancePending: action.pending
        };
    }
});
