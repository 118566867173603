import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    report: [],
    linkedinLoading: true
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.LINKEDIN_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    linkedinLoading: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    linkedinLoading: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    report: action.payload
                })
            })
    })
);
