import { createSelector } from "reselect";
import { makeSelectAccountId } from "_redux/sites/selectors";
import { makeSelectNumberOfAllowedAccounts, makeSelectSubscriptionPlanId } from "_redux/users/selectors";
import { makeSelectIsGodUser } from "_redux/users/selectors";
import { tagColors, hexToRGB } from "lib/accountTags";
import isEmpty from "lodash/isEmpty";

const selectViews = (state) => state.views || {};

const makeSelectAccounts = () =>
    createSelector(selectViews, ({ available = {} }) =>
        Object.keys(available)
            .filter((key) => !available[key].isDemo)
            .reduce(
                (cache, key) => ({
                    ...cache,
                    [key]: available[key],
                }),
                {},
            ),
    );

export const makeSelectAccountById = (accountId) =>
    createSelector(makeSelectAccounts(), ({ [accountId]: account }) => account);

export const makeSelectAnalyticsChannelsByAccount = (accountId) =>
    createSelector(
        makeSelectAccountById(accountId),
        ({ googleAnalyticsChannels = [] } = {}) => googleAnalyticsChannels,
    );

const selectSearch = createSelector(selectViews, ({ search = "" }) => search);

const selectSortByDataType = createSelector(selectViews, ({ sortByDataType = "" }) => sortByDataType);

const selectOverviewUi = createSelector(selectViews, ({ overviewUi = {} }) => overviewUi);

export const selectOverviewTagsAvailable = createSelector(selectViews, ({ overviewTagsAvailable: tags = [] }) => {
    let colorCount = 0;

    return Object.keys(tags).map((tag, index) => {
        const tagColor = tagColors[colorCount];
        const tagName = tags[tag].name;
        const tagId = tags[tag].id;
        colorCount = colorCount + 1 >= tagColors.length ? 0 : colorCount + 1;

        return {
            id: tagId,
            name: tagName,
            hexColor: tagColor,
            color: hexToRGB({ hex: tagColor, opacity: 0.12 }),
            index,
        };
    });
});

export const filterAccounts = ({ accounts, overviewUi, search, availableTags }) => {
    const { tags: unfilteredUiTags = [], contains = "any", platforms = {} } = overviewUi || {};
    const tags = unfilteredUiTags.filter((tag) => availableTags.find((t) => tag === t.id));

    return Object.keys(accounts)
        .filter((account) => {
            const { integrations = {}, tags: unFilteredAccountTags = [], name = "" } = accounts[account] || {};
            const selectedPlatforms = Object.keys(platforms).filter((key) => platforms[key]) || [];
            const isAllSelected = selectedPlatforms.length === 1 && selectedPlatforms.includes("all");
            const accountTags = unFilteredAccountTags.filter((tag) => availableTags.find((t) => tag === t.id));

            if (search && !name.toLowerCase().includes(search.toLowerCase())) {
                return false;
            }

            if (selectedPlatforms.length > 0 && !isAllSelected) {
                if (!Object.keys(integrations).some((p) => platforms[p])) {
                    return false;
                }
            }

            if (tags.length && availableTags.length) {
                const matchesAnyTag = accountTags.some((tag) => tags.includes(tag));
                const matchesAllTags = tags.every((tag) => accountTags.includes(tag));
                const matchesNoTag = !matchesAnyTag;

                if (contains === "any" && !matchesAnyTag) return false;
                if (contains === "all" && (!accountTags.length || !matchesAllTags)) return false;
                if (contains === "none" && !matchesNoTag) return false;
            }

            return true;
        })
        .map((account) => ({
            id: account,
            ...accounts[account],
        }));
};

export const selectFilteredAccountArray = createSelector(
    [makeSelectAccounts(), selectOverviewUi, selectSearch, selectOverviewTagsAvailable],
    (accounts, overviewUi, search, availableTags) => filterAccounts({ accounts, overviewUi, search, availableTags }),
);

const showPaymentPageSelector = createSelector(
    [makeSelectSubscriptionPlanId(), (state) => state.user],
    (planId, user) => {
        const { user: { type = "regular", app_metadata: { role } = {} } = {}, paymentSource = {} } = user;
        const isSuperAdmin = role === "super-admin";
        const isAppSumoUser = !type.includes("appSumoUser");

        // hiding payment page for audit report users
        if (["price_1NpbFHGsiKnzl2efAB6Kl3vh"].includes(planId)) {
            return false;
        }

        return isSuperAdmin && isEmpty(paymentSource) && isAppSumoUser;
    },
);

export const selectRandomAccountsForTeaser = createSelector([makeSelectAccounts()], (accounts) => {
    const accountIds = Object.keys(accounts).map((key) => key);

    const randomizeAccounts = accountIds.sort(() => Math.random() - 0.5);
    let showNum = accountIds.length;

    if (accountIds.length > 2 && accountIds.length <= 5) {
        showNum = 2;
    }

    if (accountIds.length > 5) {
        showNum = Math.round(accountIds.length / 3);
    }

    return randomizeAccounts.slice(0, showNum);
});

const makeSelectAccountsLoading = () => createSelector(selectViews, ({ isLoading }) => isLoading);
const makeSelectAccountsPerformanceLoading = () =>
    createSelector(selectViews, ({ isLoadingPerformanceData = true }) => isLoadingPerformanceData);

export const makeSelectAccountIsOwned = () =>
    createSelector(
        [makeSelectAccounts(), makeSelectIsGodUser(), (_, accountId) => accountId],
        (available, isGodUser, siteID) => {
            return available.hasOwnProperty(siteID) || isGodUser;
        },
    );

export const makeSelectNumberOfAccounts = () =>
    createSelector(makeSelectAccounts(), (accounts) => {
        return Object.keys(accounts).filter((key) => !accounts[key].isDemo).length;
    });

const makeSelectDemoAccountId = () =>
    createSelector(makeSelectAccounts(), (accounts) => Object.keys(accounts).find((id) => accounts[id].isDemo));

const makeSelectAccountsPerformance = () => createSelector(selectViews, ({ performanceData = {} }) => performanceData);
const makeSelectAccountsMetrics = () =>
    createSelector(selectViews, ({ performanceMetricsData = {} }) => performanceMetricsData);

/**
 * Get Accounts Left/Available for the subscription
 * Requires users + views reducers
 * @return {Number} Number of accounts available for subscription
 */

export const makeSelectHasAvailableAccountsOnSubscription = () =>
    createSelector(
        [makeSelectNumberOfAccounts(), makeSelectNumberOfAllowedAccounts()],
        (numOfAccounts, allowedAccounts) => {
            return parseInt(allowedAccounts) > numOfAccounts;
        },
    );

/**
 *
 * @returns Has to be reimplemented
 */
const makeSelectAnomalyIssues = () =>
    createSelector(makeSelectAccountsPerformance(), makeSelectAccountId(), (performanceData, accountId) => {
        const { [accountId]: { anomalyIssues: { total = 0 } = {} } = {} } = performanceData || {};
        return total;
    });
const makeSelectBudgetIssues = () =>
    createSelector(makeSelectAccountsPerformance(), makeSelectAccountId(), (performanceData, accountId) => {
        const { [accountId]: { budgetIssues: { total = 0 } = {} } = {} } = performanceData || {};
        return total;
    });
const makeSelectSeoIssues = () =>
    createSelector(makeSelectAccountsPerformance(), makeSelectAccountId(), (performanceData, accountId) => {
        const { [accountId]: { seoIssues = 0 } = {} } = performanceData || {};
        return isNaN(seoIssues) ? 0 : seoIssues;
    });

const selectShowMetricsManager = createSelector(selectViews, ({ showMetricsManager = false }) => showMetricsManager);

const selectShowExportManager = createSelector(selectViews, ({ showExportManager = false }) => showExportManager);

const makeSelectIntegrationIds = (accountId) =>
    createSelector(makeSelectAccounts(), (accounts) => {
        const { integrations = {} } = accounts[accountId] || {};
        return integrations;
    });

export const selectIsloadingAnalyticsGoals = createSelector(
    selectViews,
    ({ isloadingAnalyticsGoals = false }) => isloadingAnalyticsGoals,
);

export const makeSelectOpportunities = () => createSelector(selectViews, ({ opportunityData = [] }) => opportunityData);
export const makeSelectOpportunitiesLoading = () =>
    createSelector(selectViews, ({ isLoadingOpportunities = false }) => isLoadingOpportunities);
export const makeSelectTopWins = () =>
    createSelector(selectViews, ({ topWins = [] }) => topWins.sort((a, b) => b.growth - a.growth));

export {
    makeSelectBudgetIssues,
    makeSelectAnomalyIssues,
    makeSelectAccountsLoading,
    selectViews,
    makeSelectDemoAccountId,
    makeSelectAccounts,
    makeSelectSeoIssues,
    makeSelectAccountsPerformance,
    makeSelectAccountsPerformanceLoading,
    showPaymentPageSelector,
    selectShowMetricsManager,
    selectShowExportManager,
    selectSearch,
    selectSortByDataType,
    selectOverviewUi,
    makeSelectIntegrationIds,
    makeSelectAccountsMetrics,
};
