import React from "react";
import { PAID_PLATFORMS } from "lib/paidPlatforms";
import moment from "moment";
import { mixpanel } from "components/Mixpanel";
import { ReactComponent as InspectIcon } from "assets/images/icons/Icon-Inspect.svg";
import CustomMetricsIcon from "assets/images/Integrations/JSX/Logo-CustomMetrics";

const getKpiUrl = ({ accountId, platform, startDate, endDate }) => {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    if (PAID_PLATFORMS.includes(platform)) {
        return `/dashboard/${accountId}/analysis/?platform=${platform}&settings[start]=${formattedStartDate}&settings[end]=${formattedEndDate}`;
    }

    return `/dashboard/${accountId}/analysis/?settings[start]=${formattedStartDate}&settings[end]=${formattedEndDate}`;
};

export const getPlatformId = (platformKey) => {
    switch (platformKey) {
        case "analyticsV4":
            return "google-analytics-v4";
        case "adwords":
            return "google-ads";
        case "facebook":
            return "facebook-ads";
        case "tiktok":
            return "tiktok-ads";
        case "pinterest":
            return "pinterest-ads";
        case "bing":
            return "microsoft-advertising";
        case "linkedin":
            return "linkedin-ads";
        case "shopify":
            return "shopify";
        case "searchConsole":
            return "google-search-console";
        case "googleBusinessProfile":
            return "google-business-profile";
        case "maropost":
            return "maropost";
        case "klaviyo":
            return "klaviyo";
        case "bigQuery":
            return "bigQuery";
        default:
            return "";
    }
};

const getInspectMenuItem = ({ showInspect, history, style, accountId, platformMainKey, startDate, endDate }) => {
    return showInspect
        ? [
              {
                  id: "inspect",
                  label: (
                      <span className={style.menuLabel}>
                          <InspectIcon /> Inspect
                      </span>
                  ),
                  onClick: () => {
                      mixpanel.track("Goal Card Menu Click", {
                          Label: "Inspect",
                      });
                      return history.push(
                          getKpiUrl({
                              accountId,
                              platform: platformMainKey,
                              startDate,
                              endDate,
                          }),
                      );
                  },
              },
          ]
        : [];
};

const getCustomMetricSettingMenuItem = ({ showCustomMetrics, style, history }) => {
    return showCustomMetrics
        ? [
              {
                  id: "customMetricSettings",
                  label: (
                      <span className={style.menuLabel}>
                          <CustomMetricsIcon /> Custom Metric Settings
                      </span>
                  ),
                  icon: "",
                  onClick: () => {
                      mixpanel.track("Goal Card Menu Click", {
                          Label: "Custom Metric Settings",
                      });
                      history.push("/dashboard/user/custom_metrics");
                  },
              },
          ]
        : [];
};

export const getMenuOptions = ({
    showInspect,
    showCustomMetrics,
    history,
    style,
    accountId,
    platformMainKey,
    startDate,
    endDate,
}) => [
    ...getInspectMenuItem({
        showInspect,
        history,
        style,
        accountId,
        platformMainKey,
        startDate,
        endDate,
    }),
    ...getCustomMetricSettingMenuItem({ showCustomMetrics, style, history }),
];
