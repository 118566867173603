import React from "react";
import { useSelector } from "react-redux";
import style from "./style.module.scss";
import isEqual from "lodash/isEqual";
import { selectOverviewUi, selectOverviewTagsAvailable } from "features/ControlPanel/ducks/selectors";

import { AccountTag } from "features/ControlPanel/AccountTags";

const TagsApplied = React.memo(() => {
    const availableTags = useSelector((state) => selectOverviewTagsAvailable(state));
    const { tags: unfilteredTags = [] } = useSelector((state) => selectOverviewUi(state));

    // Need to also filter for duplicates - was having an issue here
    const tags = unfilteredTags.filter(
        (tag, index) => availableTags.find((t) => tag === t.id) && unfilteredTags.indexOf(tag) === index,
    );

    if (!tags.length || !availableTags.length) {
        return null;
    }

    return (
        <div className={style.tagsApplied}>
            {tags
                .sort((tagA, tagB) => {
                    const aVal = availableTags.find((t) => t.id === tagA)?.index;
                    const bVal = availableTags.find((t) => t.id === tagB)?.index;
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
                })
                .map((tag) => {
                    const tagInfo = availableTags.find((t) => tag === t.id);
                    return (
                        <AccountTag
                            key={tag}
                            className={style.accountTag}
                            color={tagInfo?.color}
                            hexColor={tagInfo?.hexColor}
                            tag={tagInfo?.name}
                            id={tag}
                            isLarge
                        />
                    );
                })}
        </div>
    );
}, isEqual);

export default TagsApplied;
