const GET_ALERT_SETTINGS = "GET_ALERT_SETTINGS";
const DETELE_ALERT_RULES = "DETELE_ALERT_RULES";
const CREATE_ALERT_RULES = "CREATE_ALERT_RULES";
const UPDATE_ALERT_RULES = "UPDATE_ALERT_RULES";
const PREVIEW_ALERT_RULES = "PREVIEW_ALERT_RULES";
const RESET_PREVIEW_ALERT_RULES = "RESET_PREVIEW_ALERT_RULES";

export default {
    GET_ALERT_SETTINGS,
    DETELE_ALERT_RULES,
    CREATE_ALERT_RULES,
    UPDATE_ALERT_RULES,
    PREVIEW_ALERT_RULES,
    RESET_PREVIEW_ALERT_RULES,
};
