import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";
import set from "lodash/fp/set";
import merge from "lodash/fp/merge";

const defaultState = {
    organicUrls: {},
    platformUrls: {},
    anomalies: {},
};

export default createReducer(defaultState, {
    [types.ADD_IGNORE_PAGE]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updatingIgnoreList: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatingIgnoreList: false,
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    organicUrls: {
                        ...prevState.organicUrls,
                        data: Object.keys(prevState.organicUrls.data || {}).reduce((cache, key) => {
                            if (key === action.meta.pageKey) {
                                return cache;
                            }
                            return {
                                ...cache,
                                [key]: prevState.organicUrls.data[key],
                            };
                        }, {}),
                    },
                };
            },
        }),
    [types.TOGGLE_HEALTH_ANOMALY_VISIBILITY]: (state, action) => {
        const anomalyId = action.payload.anomalyId;
        const id = anomalyId.split("_")[1];
        return merge(state, {
            anomalies: {
                [id]: {
                    ...state.anomalies[id],
                    dismissed: {
                        value: !state.anomalies[id]?.dismissed?.value,
                        userId: action.payload.userId,
                        updated: new Date().toISOString(),
                    },
                },
            },
        });
    },
    [types.GET_ORGANIC_DATA]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                organicUrls: action.payload.data,
            }),
        }),
    [types.GET_PLATFORM_DATA]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                platformUrls: action.payload.data,
            }),
        }),
    [types.GET_ANOMALY_DATA]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                anomalies: action.payload.data,
            }),
        }),
    [types.PAGEHEALTH_UPDATE_COMMENT](state, action) {
        return {
            ...state,
            anomalies: set(`${action.id}.comments`, action.comments)(state.anomalies),
        };
    },
});
