import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import { handle } from "redux-pack";
import set from "lodash/fp/set";
import types from "./types";
import merge from "lodash/fp/merge";
/**
 * REDUCERS
 */

export const defaultState = {
    competitors: [],
    performance: [],
    anomalies: [],
    suggestions: {
        keywords: [],
        competitors: [],
    },
    keywordsCompetitorsLoading: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ACCOUNT_SUGGEST_KEYWORDS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    suggestions: {
                        ...prevState.suggestions,
                        keyword: {},
                    },
                    suggestKeywordsPending: true,
                    suggestKeywordsError: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    suggestKeywordsPending: false,
                    suggestKeywordsError: true,
                }),
                success: (prevState, { payload: { keywords = [] } }) => ({
                    ...prevState,
                    suggestions: {
                        ...prevState.suggestions,
                        keywords,
                    },
                    suggestKeywordsPending: false,
                    suggestKeywordsError: false,
                }),
            }),
        [types.TOGGLE_SEO_ANOMALY_VISIBILITY]: (state, action) => {
            const anomalyId = action.payload.anomalyId;
            const id = anomalyId.split("_")[1];

            return merge(state, {
                anomalies: {
                    [id]: {
                        ...state.anomalies[id],
                        dismissed: {
                            value: !state.anomalies[id]?.dismissed?.value,
                            userId: action.payload.userId,
                            updated: new Date().toISOString(),
                        },
                    },
                },
            });
        },
        [types.ACCOUNT_SUGGEST_COMPETITORS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    suggestions: {
                        ...prevState.suggestions,
                        competitors: {},
                    },
                    suggestCompetitorsPending: true,
                    suggestCompetitorsError: false,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    suggestCompetitorsPending: false,
                    suggestCompetitorsError: true,
                }),
                success: (prevState, { payload: { competitors = [] } }) => ({
                    ...prevState,
                    suggestions: {
                        ...prevState.suggestions,
                        competitors,
                    },
                    suggestCompetitorsPending: false,
                    suggestCompetitorsError: false,
                }),
            }),
        [types.COMPETITOR_SEO_DATA](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    competitorsSeoLoading: true,
                    competitorsSeoError: false,
                }),
                success: (prevState, { payload: { competitors = [], performance = [] } }) => ({
                    ...prevState,
                    competitors,
                    performance,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    competitorsSeoError: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    competitorsSeoLoading: false,
                }),
            });
        },
        [types.GENERATE_COMPETITORS](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    generateCompetitorsPending: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    generateCompetitorsPending: false,
                }),
            });
        },
        [types.COMPETITOR_ANOMALIES_DATA](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    competitorsAnomaliesLoading: true,
                    competitorsAnomaliesError: false,
                }),
                success: (prevState, { payload: { anomalies = [] } }) => ({
                    ...prevState,
                    anomalies,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    competitorsAnomaliesError: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    competitorsAnomaliesLoading: false,
                }),
            });
        },
        [types.UPDATE_COMPETITOR_COMMENTS](state, action) {
            return {
                ...state,
                anomalies: set(`${action.id}.comments`, action.comments)(state.anomalies),
            };
        },
        [types.CONTENT_SUGGESTIONS_DATA](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    contentSuggestionsLoading: true,
                }),
                success: (prevState, { payload: { suggestions = [] } }) => ({
                    ...prevState,
                    contentSuggestions: suggestions,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    contentSuggestionsLoading: false,
                }),
            });
        },
        [types.CONTENT_SUGGESTIONS_REMOVE](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    contentSuggestionsLoading: true,
                }),
                success: (prevState, { payload: { suggestionId } }) => ({
                    ...prevState,
                    contentSuggestions: prevState.contentSuggestions.filter(({ id }) => id !== suggestionId),
                }),
                finish: (prevState) => ({
                    ...prevState,
                    contentSuggestionsLoading: false,
                }),
            });
        },
        [types.COMPETITOR_LOCATIONS_DATA](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    loadingLocationOptions: true,
                }),
                success: (prevState, { payload: locationOptions }) => ({
                    ...prevState,
                    locationOptions,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    loadingLocationOptions: false,
                }),
            });
        },
    }),
);
