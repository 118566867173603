import { omit, isEmpty } from "lodash";
import { defaultState as alertFilterNames } from "../../../features/AlertsPage/_ducks/reducers";
import moment from "moment";

export const withBadge = (lastSeen) => (lastSeen ? moment().diff(moment(lastSeen), "hours") >= 24 : true);

export const getNotificationText = (notification) => {
    const data = omit(notification, ["accountId", "accountName"]) || {};
    if (isEmpty(data)) return "";

    const channelTypes = alertFilterNames.filterNames.types;
    return Object.keys(channelTypes).reduce((cache, channelType) => {
        const count = channelTypes[channelType].channels.reduce((cache, channelKey) => {
            const channelCount = data[channelKey] || 0;
            return channelCount ? cache + channelCount : cache;
        }, 0);
        const comma = isEmpty(cache) ? "" : ", ";
        return count ? `${cache}${comma}${count} ${channelTypes[channelType].name}` : cache;
    }, "");
};

export const ALERT_STATUSES = {
    RED: "red",
    YELLOW: "yellow",
    GREEN: "green",
};

const ALERT_COLORS = {
    [ALERT_STATUSES.RED]: "#FB1427",
    [ALERT_STATUSES.YELLOW]: "#FF9900",
    [ALERT_STATUSES.GREEN]: "#00B178",
};

export const getAlertColor = (status) => {
    return ALERT_COLORS[status];
};
