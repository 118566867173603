// Lazy loading if chunk fails. retry component a couple times.

export default function componentLoader(attemptsLeft) {
    return (lazyComponent) => {
        return new Promise((resolve, reject) => {
            lazyComponent()
                .then(resolve)
                .catch((error) => {
                    // let us retry after 1500 ms
                    console.error("error", error);
                    setTimeout(() => {
                        if (attemptsLeft === 1) {
                            reject(error);
                            return;
                        }
                        componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                    }, 1500);
                });
        });
    };
}
