import React from "react";
import Button from "components/Button";
import ZeroState from "components/ZeroStates/Icon";
import style from "../style.module.scss";
import NoAccountFoundIcon from "./NoAccountFoundIcon";

const SearchZeroState = ({ onClick }) => {
    return (
        <ZeroState
            icon={NoAccountFoundIcon}
            containerClass={style.zeroState}
            title="No Results Found"
            message="Try updating or changing some filters."
        >
            <Button onClick={onClick} secondary>
                Clear Filters
            </Button>
        </ZeroState>
    );
};

export default SearchZeroState;
