import { api } from "lib/maropost";
import { MAROPOST_CAMPAIGN_REPORT } from "./types";

const getMaropostCampaignData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: MAROPOST_CAMPAIGN_REPORT,
        promise: api.getCampaignReport({
            dateRange,
            accountId,
        }),
    });
};

export default {
    getMaropostCampaignData,
};
