import { capitalize } from "lib/utility";
import { isEmpty } from "lodash";

export const GA4_CHANNELS = [
    "analyticsV4",
    "analyticsV4Direct",
    "analyticsV4OrganicSearch",
    "analyticsV4OrganicSocial",
    "analyticsV4PaidSocial",
    "analyticsV4Email",
    "analyticsV4Affiliates",
    "analyticsV4Referral",
    "analyticsV4PaidSearch",
    "analyticsV4Display",
    "analyticsV4Video",
];

export const GA4_REPORT_BUILDER_REPORT_TYPES = {
    topCountries: {
        label: "Top Countries - Users",
        hasConsistentDimension: false,
        keyMetric: "activeUsers",
    },
    topCities: {
        label: "Top Cities - Users",
        hasConsistentDimension: false,
        keyMetric: "activeUsers",
    },
    userGenders: {
        label: "User Genders",
        hasConsistentDimension: true,
        keyMetric: "activeUsers",
    },
    userAgeBrackets: {
        label: "User Age Brackets",
        hasConsistentDimension: true,
        keyMetric: "activeUsers",
    },
    topProducts: {
        label: "Top Products",
        hasConsistentDimension: false,
    },
    topProductCategories: {
        label: "Top Product Categories",
        hasConsistentDimension: false,
    },
};

export const GA4_TOP_PRODUCTS_METRICS = [
    "grossItemRevenue",
    "itemDiscountAmount",
    "itemRefundAmount",
    "itemRevenue",
    "itemsAddedToCart",
    "itemsCheckedOut",
    "itemsPurchased",
    "itemsViewed",
];

export const TABLE_REPORT_TYPES = ["topProducts", "topProductCategories"];

export const getCardChartType = (reportType) => {
    return TABLE_REPORT_TYPES.includes(reportType) ? "table" : "verticalBar";
};

const getCompareValue = ({ value, actualDimension, index, showCustomLabel }) => {
    if (showCustomLabel) {
        return value[index];
    }

    return value.find(({ dimension }) => dimension === actualDimension.toLowerCase());
};

export const formatBarChartData = ({ data = {}, cardReportType }) => {
    if (isEmpty(data)) {
        return [];
    }

    const { actual = [], compare = [] } = data || {};

    return actual.map((actualData, index) => {
        const { keyMetric, hasConsistentDimension, label } = GA4_REPORT_BUILDER_REPORT_TYPES[cardReportType];
        const actualValue = actualData[keyMetric];
        const actualDimension = capitalize(actualData.dimension);
        const compareDimension = capitalize(compare[index]?.dimension);
        const showCustomLabel = !hasConsistentDimension && !isEmpty(compare);
        const compareValue = getCompareValue({ value: compare, actualDimension, index, showCustomLabel })?.[keyMetric];

        return {
            actual: actualValue,
            oneMonth: compareValue,
            valueLabel: actualValue,
            oneMonthLabel: compareValue,
            barLabel: showCustomLabel ? index + 1 : actualDimension,
            metricIndex: index,
            metricKeyLabel: showCustomLabel ? label : actualDimension,
            metricKey: keyMetric,
            platformKey: "analyticsV4",
            customActualTooltip: showCustomLabel && actualDimension,
            customComparisonTooltip: showCustomLabel && compareDimension,
        };
    });
};

export const formatTableChartData = ({ data = {}, cardReportType }) => {
    if (isEmpty(data)) {
        return [];
    }

    const { actual = [], compare = [] } = data || {};
    return actual.map((item) => {
        const dimensionValue = item.dimension;
        const prev =
            compare.find(({ dimension: comparedDimension } = {}) => dimensionValue === comparedDimension) || {};
        return {
            id: dimensionValue,
            ...GA4_TOP_PRODUCTS_METRICS.reduce((cache, metric) => {
                return {
                    ...cache,
                    [metric]: {
                        metric,
                        actualValue: item[metric],
                        previousValue: prev[metric],
                    },
                };
            }, {}),
        };
    });
};

export const formatReportBuilderGA4Reports = (reportData = {}) => {
    if (isEmpty(reportData)) {
        return {};
    }

    return Object.keys(reportData).reduce((cache, reportDataKey) => {
        const data = reportData[reportDataKey];
        const reportType = reportDataKey.split("-")[0] || "";
        const chartType = getCardChartType(reportType);

        if (chartType === "verticalBar") {
            return { ...cache, [reportDataKey]: formatBarChartData({ data, cardReportType: reportType }) };
        }
        if (chartType === "table") {
            return { ...cache, [reportDataKey]: formatTableChartData({ data, cardReportType: reportType }) };
        }

        return { ...cache, [reportDataKey]: data };
    }, {});
};
