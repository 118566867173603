import React, { useEffect, useState } from "react";
import DropDownContainer from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import moment from "moment";
import styles from "../../sharedStyles.module.scss";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";
import CompareIcon from "assets/images/icons/JSX/Icon-Compare";
import Close from "assets/images/icons/Icon-Close-Small.svg";
import SelectInput from "components/DropDown/Select";
import { TooltipDark } from "components/Tooltip";
import { COMPARISONS, COMPARISONS_LABELS } from "features/Analysis/helpers";
import { isEmpty } from "lodash";
import { getDateRangeValue, getDateRangeLabel, isPresetActive } from "./helpers";

export default ({
    toggleOpen,
    from,
    to,
    togglePresetOpen,
    isOpen,
    handlePresetClick,
    isPresetOpen,
    dateRange,
    clearDate,
    additionalOptions = [],
    defaultActive,
}) => {
    const hasAdditionalOptions = !isEmpty(additionalOptions);
    const defaultAdditionalOptionActive = hasAdditionalOptions ? COMPARISONS_LABELS[COMPARISONS.NONE] : null;
    const [additionalActive, toggleAdditionalActive] = useState(defaultAdditionalOptionActive);

    useEffect(() => {
        defaultActive && toggleAdditionalActive(defaultActive);
    }, [defaultActive]);

    useEffect(() => {
        if (!additionalActive) {
            return toggleAdditionalActive(null);
        }

        if (!to && !from) {
            return toggleAdditionalActive(defaultAdditionalOptionActive);
        }
    }, [from, to]);

    const daysInRange = moment(dateRange.end).diff(dateRange.start, "days");

    const label = getDateRangeLabel({ from, to });
    const presetActive = isPresetActive({ dateRange, daysInRange, from, to, additionalActive });
    const customActive =
        label && !presetActive["Previous Period"] && !presetActive["Previous Month"] && !presetActive["Previous Year"];

    return (
        <SelectInput
            icon={CompareIcon}
            refFunction={() => togglePresetOpen(false)}
            handleClick={() => {
                toggleOpen(false);
                if (!isOpen) {
                    togglePresetOpen(!isPresetOpen);
                }
            }}
            value={getDateRangeValue({ additionalActive, label })}
            isOpen={isOpen || isPresetOpen}
            styles={{ minWidth: "11rem", width: "auto" }}
            calendarIcon
            clearActive={!!label && clearDate}
        >
            <DropDownContainer key="selector" className={styles.presetSelector} isOpen={isPresetOpen} triangle={false}>
                {hasAdditionalOptions && (
                    <div className={styles.additionalOptions}>
                        {additionalOptions.map(
                            ({
                                start,
                                end,
                                label,
                                onClick = () => null,
                                deactive,
                                deactiveMessage,
                                icon,
                                tip,
                                id,
                            } = {}) => (
                                <div key={id}>
                                    <div data-tip={deactiveMessage} data-for={`deactive_${id}`}>
                                        <SimpleListItem
                                            onClick={() => {
                                                handlePresetClick(moment(start).toDate(), moment(end).toDate());
                                                onClick();
                                                toggleAdditionalActive(label);
                                            }}
                                            isActive={presetActive[label] || defaultActive === label}
                                            label={
                                                <>
                                                    <div data-for={label} data-tip={tip}>
                                                        {icon && icon}
                                                        {label}
                                                    </div>
                                                    <TooltipDark id={label} delay proximity={4} />
                                                </>
                                            }
                                            disabled={deactive}
                                        />
                                    </div>
                                    <TooltipDark id={`deactive_${id}`} delay proximity={4} />
                                </div>
                            ),
                        )}
                    </div>
                )}
                <SimpleListItem
                    onClick={() => {
                        handlePresetClick(
                            moment(dateRange.start)
                                .subtract(daysInRange + 1, "days")
                                .toDate(),
                            moment(dateRange.start)
                                .subtract(1, "days")
                                .toDate(),
                        );
                        toggleAdditionalActive(null);
                    }}
                    isActive={presetActive["Previous Period"]}
                    label="Previous Period"
                />
                <SimpleListItem
                    onClick={() => {
                        handlePresetClick(
                            moment(dateRange.start)
                                .subtract(1, "month")
                                .toDate(),
                            moment(dateRange.end)
                                .subtract(1, "month")
                                .toDate(),
                        );
                        toggleAdditionalActive(null);
                    }}
                    isActive={presetActive["Previous Month"]}
                    label="Previous Period Last Month"
                />
                <SimpleListItem
                    onClick={() => {
                        handlePresetClick(
                            moment(dateRange.start)
                                .subtract(1, "years")
                                .toDate(),
                            moment(dateRange.end)
                                .subtract(1, "years")
                                .toDate(),
                        );
                        toggleAdditionalActive(null);
                    }}
                    isActive={presetActive["Previous Year"]}
                    label="Previous Period Last Year"
                />
                <div className={styles.customDateRange}>
                    <SimpleListItem
                        onClick={() => {
                            toggleOpen(true);
                            togglePresetOpen(false);
                            toggleAdditionalActive(null);
                        }}
                        isActive={customActive}
                    >
                        <span className={styles.customLabel}>
                            <EditIcon /> Custom Date Range
                        </span>
                    </SimpleListItem>
                </div>
            </DropDownContainer>
        </SelectInput>
    );
};
