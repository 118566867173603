import React from "react";
import { Bar, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "./CustomTooltip";
import style from "./style.module.scss";
import LineBarCustom from "./LineBarCustom";
import { prepareData } from "./helpers";
import PropTypes from "prop-types";
import { toPrettyNumber } from "lib/metricCalculations";

const CustomDot = ({ payload: { value, max }, width }) => {
    return (
        <circle
            r="6"
            stroke="#0671EE"
            strokeWidth="4"
            fill="#fff"
            width="525"
            height="10"
            cx={(value / max) * width + 10}
            cy="25"
        ></circle>
    );
};

const BenchmarkingChart = (props) => {
    const { lessIsBetter = false, min, max, metric, benchmarkMax, benchmarkMin, positionY = -50 } = props;
    const benchmark = (benchmarkMax + benchmarkMin) / 2;
    const perecent = (100 * (benchmark - min)) / (max - min);
    const middleOfGredien = perecent ? `${parseInt(perecent)}%` : "50%";
    return (
        <div className={style.chartWrapper}>
            <p className={style.siteLabel}>{toPrettyNumber(min, metric)}</p>
            <ResponsiveContainer height={50}>
                <ComposedChart
                    layout="vertical"
                    data={[prepareData(props)]}
                    margin={{
                        top: 20,
                        right: 10,
                        bottom: 20,
                        left: 10,
                    }}
                >
                    <defs>
                        <linearGradient id="gyr" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#00b178" />
                            <stop offset={middleOfGredien} stopColor="#ff9d00" />
                            <stop offset="100%" stopColor="#fb1427" />
                        </linearGradient>
                        <linearGradient id="ryg" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#fb1427" />
                            <stop offset={middleOfGredien} stopColor="#ff9d00" />
                            <stop offset="100%" stopColor="#00b178" />
                        </linearGradient>
                    </defs>
                    <XAxis type="number" hide />
                    <YAxis type="category" hide />
                    <Tooltip
                        content={<CustomTooltip {...props} />}
                        cursor={false}
                        offset={20}
                        allowEscapeViewBox={{ x: false, y: false }}
                        wrapperStyle={{ zIndex: 1 }}
                        position={{ y: positionY }}
                    />
                    <Bar
                        dataKey="max"
                        barSize={6}
                        fill={`url(${lessIsBetter ? "#gyr" : "#ryg"})`}
                        shape={<LineBarCustom />}
                    />
                    <Line dataKey="value" stroke="#ff7300" dot={<CustomDot />} activeDot={false} />
                </ComposedChart>
            </ResponsiveContainer>
            <p className={style.siteLabel}>{toPrettyNumber(max, metric)}</p>
        </div>
    );
};

export default BenchmarkingChart;

BenchmarkingChart.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    benchmarkMin: PropTypes.number.isRequired,
    benchmarkMax: PropTypes.number.isRequired,
    lessIsBetter: PropTypes.bool,
    metric: PropTypes.string,
};
