import React from "react";
import PropTypes from "prop-types";
import { IconImage } from "components/Icon";
import { TooltipDark } from "components/Tooltip";
import style from "./style.module.scss";

function Tag({ children, icon, className, tip, tipId }) {
    return (
        <>
            <div className={`${style.tag} ${className ? className : ""}`} data-tip={tip} data-for={tipId}>
                <IconImage icon={icon} />
                {children && <p>{children}</p>}
            </div>
            {tipId && <TooltipDark id={tipId} proximity={4} />}
        </>
    );
}

Tag.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
    className: PropTypes.string,
    tip: PropTypes.string,
    tipId: PropTypes.string,
};

export default Tag;
