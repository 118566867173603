import React, { useState, useCallback } from "react";
import style from "./style.module.scss";
import DropDownContainer from "components/DropDown/Container";
import SelectInput from "components/DropDown/Select";
import { SimpleListItem } from "components/DropDown/ListItems";
import classNames from "classnames";

export default ({
    handleSelectedValue,
    selectedValue,
    options = [],
    type,
    icon,
    styles,
    dropdownWidth,
    footerComponents,
    selectedLabel,
    className,
	listAnchor,
    isBlackIcon,
    disabled = false,
    openProp = false,
}) => {
    const [isOpen, toggleOpen] = useState(false);
    const classes = classNames(style.compareSelect, {
        [className]: className,
    });

    const onClick = useCallback(
        (val) => {
            handleSelectedValue({ [type]: val });
            toggleOpen(false);
        },
        [handleSelectedValue],
    );

    return (
        <SelectInput
            className={classes}
            disabled={disabled}
            refFunction={() => toggleOpen(false)}
            handleClick={() => toggleOpen(!isOpen)}
            value={selectedLabel || options.find(({ value } = {}) => selectedValue === value)?.label}
            isOpen={isOpen || openProp}
            styles={{
                minWidth: "12.5rem",
                width: "auto",
                marginLeft: "0.5rem",
                ...styles,
            }}
            icon={icon}
            isBlackIcon={isBlackIcon}
        >
            <DropDownContainer
                className={style.compareDropDown}
                isOpen={isOpen || openProp}
                dropdownWidth={dropdownWidth}
                anchor={listAnchor}
            >
                {options.map(({ label, value, disabled = false }, index) => (
                    <SimpleListItem
                        key={`select-${index}-${value}`}
                        isActive={value === selectedValue}
                        onClick={() => onClick(value)}
                        label={label}
                        disabled={disabled}
                    />
                ))}
                {footerComponents}
            </DropDownContainer>
        </SelectInput>
    );
};
