export const planList = (userType) => {
    if (userType === "reporting") {
        return {
            regularMonthly: [
                {
                    plan: process.env.REACT_APP_PLAN_REPORTING_1_MONTHLY,
                },
            ],
        };
    }
    return {
        regularMonthly: [
            {
                plan: process.env.REACT_APP_PLAN_PRO_1_MONTHLY,
            },
            {
                plan: process.env.REACT_APP_PLAN_PRO_2_MONTHLY,
            },
            {
                plan: process.env.REACT_APP_PLAN_PRO_3_MONTHLY,
            },
            {
                plan: process.env.REACT_APP_PLAN_PRO_4_MONTHLY,
            },
            {
                plan: process.env.REACT_APP_PLAN_PRO_5_MONTHLY,
            },
        ],
    };
};
