import IconNoSearch from "assets/images/icons/Icon-NoSearch.svg";
import { default as classnames, default as classNames } from "classnames";
import ArrowButton from "components/Button/ArrowButton";
import DropDownContainer from "components/DropDown/Container";
import { IconImage } from "components/Icon";
import Search from "components/SearchBox";
import ZeroState from "components/ZeroStates/Icon";
import { usePermissions } from "features/Permissions";
import { SIDEBAR_PATH, DASHBOARD_PATH, ANALYSIS_PATH } from "lib/paths";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import { history } from "_redux";
import FoundListFeature from "./FoundListFeature";
import style from "./style.module.scss";

function SearchZeroState() {
    return (
        <ZeroState
            icon={IconNoSearch}
            containerClass={style.zeroState}
            title="No Features"
            message="There are currently no results that match your search."
        />
    );
}

function DropDownButton({ title, dropDownIsOpen, toggleDropDown }) {
    const classes = classNames(style.selectionArrow, {
        [style.open]: dropDownIsOpen,
    });
    return (
        <div onClick={toggleDropDown} className={classes}>
            <h1 className={classnames(style.title)}>{title}</h1>
            <ArrowButton className={style.selectionArrowButton} />
        </div>
    );
}

function RenderTitle(currentFeature) {
    const { parentName, name, icon } = currentFeature;
    return (
        <div className={style.dropDownTitle}>
            <div className={style.iconTitle}>
                {(parentName || icon) && (
                    <IconImage
                        icon={SIDEBAR_PATH[parentName]?.icon || icon}
                        alt={name}
                    />
                )}
            </div>
            <span>{parentName ? `${parentName} / ${name}` : name}</span>
        </div>
    );
}

export default ({ accounts, selectedSite, userRole }) => {
    const pathOptions = Object.keys(SIDEBAR_PATH).filter((key) =>
        usePermissions({ actions: SIDEBAR_PATH[key].actions })
    );
    const singlePaths =
        userRole === "reporting" ? [] : [DASHBOARD_PATH, ANALYSIS_PATH];

    const features = singlePaths.concat(
        pathOptions.reduce((cache, parent) => {
            const { children = [] } = SIDEBAR_PATH[parent];
            const listChidren = Object.values(children)
                .filter(
                    ({ actions, platforms, hideOnUser }) =>
                        usePermissions({ actions, platforms }) &&
                        (isEmpty(hideOnUser) || !hideOnUser.includes(userRole))
                )
                .map((item) => {
                    return { ...item, parentName: parent };
                });
            return [...cache, ...listChidren];
        }, [])
    );

    const currentPathArray = history.location.pathname.split("/");
    const currentPath = currentPathArray.slice(3).join("/");

    const currentFeature =
        features.find(
            ({ path }) => path === currentPath || path === currentPathArray[3]
        ) || {};

    const [dropDownIsOpen, toggleDropDown] = useState(false);
    const reference = useHandleOutsideClickHook(() => {
        toggleDropDown(false);
    });
    const ref = React.useRef();

    const toggleDropDownCb = useCallback(() => {
        if (!dropDownIsOpen) {
            //Wait until animation is finished and component is loaded
            setTimeout(() => ref.current && ref.current.focus(), 100);
        }
        toggleDropDown(!dropDownIsOpen);
    }, [dropDownIsOpen, ref]);

    if (isEmpty(currentFeature) || isEmpty(currentFeature.name)) return null;

    return (
        <>
            <span className={style.slash}>/</span>
            <div ref={reference} className={style.dropDownContainer}>
                <DropDownButton
                    title={RenderTitle(currentFeature)}
                    dropDownIsOpen={dropDownIsOpen}
                    toggleDropDown={toggleDropDownCb}
                />

                <DropDownContainer
                    className={style.selectionDropDown}
                    isOpen={dropDownIsOpen}
                    anchor={"left"}
                    dropdownWidth="auto"
                >
                    <Search
                        ref={ref}
                        foundListContainerStyle={style.listContainer}
                        className={style.search}
                        sortFn={() => {}}
                        pathOfFilterKey="name"
                        list={features}
                        placeholder={"Search Features"}
                        zeroState={SearchZeroState}
                    >
                        {({ foundList }) => (
                            <FoundListFeature
                                toggleDropDown={toggleDropDownCb}
                                foundList={foundList}
                                currentPath={currentPath}
                            />
                        )}
                    </Search>
                </DropDownContainer>
            </div>
        </>
    );
};
