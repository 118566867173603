import { api } from "lib/pinterest";
import { PINTEREST_CAMPAIGN_REPORT } from "./types";

const getPinterestCampaignData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: PINTEREST_CAMPAIGN_REPORT,
        promise: api.getCampaignReport({
            dateRange,
            accountId,
        }),
    });
};

export default {
    getPinterestCampaignData,
};
