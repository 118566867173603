import { sortBy } from "lodash";
import moment from "moment";
import { toPrettyNumber } from "lib/metricCalculations";
import qs from "qs";

const getDiffValue = (primary, secondary) =>
    secondary ? (100 * (primary - secondary)) / secondary : primary ? 100 : 0;

const getChartData = ({ primaryValue = [], primaryValues = [], secondaryValue = [], secondaryValues = [] }) => {
    const mappedSecondaryValues = secondaryValues.map((data) => ({
        ...data,
        value: data.value || 0,
        secondaryValue,
    }));
    const mappedPrimaryValues = primaryValues.map((data) => ({
        ...data,
        value: data.value || 0,
        primaryValue,
    }));
    const fullData = sortBy([...mappedSecondaryValues, ...mappedPrimaryValues], "month");
    return fullData.map((data) => ({
        ...data,
        month: moment(data.month).format("MMM YYYY"),
    }));
};

const getSlug = (platform) => {
    if (platform.startsWith("analyticsV4")) {
        return `kpi/google-analytics-v4?`;
    }
    switch (platform) {
        case "adwords":
            return `kpi/google-ads?`;
        case "adwordsDisplay":
            return `kpi/google-ads?network=Display%20Network&`;
        case "adwordsSearch":
            return `kpi/google-ads?network=Search%20Network&`;
        case "adwordsYouTubeVideos":
            return `kpi/google-ads?network=YouTube%20Videos&`;
        case "adwordsSearchPartners":
            return `kpi/google-ads?network=Search%20Partners&`;
        case "adwordsCross":
            return `kpi/google-ads?network=Cross-network&`;
        case "facebook":
            return `kpi/facebook-ads?`;
        case "linkedin":
            return `kpi/linkedin-ads?`;
        case "bing":
            return `kpi/microsoft-advertising?`;
        case "shopify":
            return `kpi/shopify?`;
        case "tiktok":
            return `kpi/tiktok-ads?`;
        case "pinterest":
            return `kpi/pinterest-ads?`;
        default:
            return `kpi/summary?`;
    }
};

const getDates = (period = []) => {
    const ordered = period.sort((a, b) => new Date(a) - new Date(b));
    const [start] = ordered;
    const [end] = ordered.reverse();
    return { start, end };
};

const getPlatformUrl = ({ platform, accountId, primaryPeriod, secondaryPeriod }) => {
    if (!platform) return `/dashboard/${accountId}/analysis`;
    const { start, end } = getDates(primaryPeriod);
    const { start: compareToStart, end: compareToEnd } = getDates(secondaryPeriod);
    const datestring = qs.stringify(
        Object.assign(
            {},
            start && { start: moment(start).format("YYYY-MM-DD") },
            end && {
                end: moment(end)
                    .endOf("month")
                    .format("YYYY-MM-DD"),
            },
            compareToStart && { compareToStart: moment(compareToStart).format("YYYY-MM-DD") },
            compareToEnd && {
                compareToEnd: moment(compareToEnd)
                    .endOf("month")
                    .format("YYYY-MM-DD"),
            },
        ),
    );

    const slug = getSlug(platform);
    return `/dashboard/${accountId}/${slug}${datestring}`;
};

export const prepareMetricData = (insight) => {
    const {
        platform,
        metric,
        metricName,
        primaryMetricValue,
        primaryMetricValues,
        secondaryMetricValue,
        secondaryMetricValues,
        primaryPeriod,
        secondaryPeriod,
        accountId,
    } = insight;

    return {
        platform,
        metric,
        title: "Account",
        metricName,
        diffFrom: toPrettyNumber(secondaryMetricValue, metric),
        diffTo: toPrettyNumber(primaryMetricValue, metric),
        diffValue: getDiffValue(primaryMetricValue, secondaryMetricValue),
        chartData: getChartData({
            primaryValue: primaryMetricValue,
            primaryValues: primaryMetricValues,
            secondaryValue: secondaryMetricValue,
            secondaryValues: secondaryMetricValues,
        }),
        url: getPlatformUrl({
            primaryPeriod,
            secondaryPeriod,
            accountId,
            platform,
        }),
    };
};

export const prepareBenchmarkData = (insight) => {
    const {
        platform,
        metric,
        platformToCompare,
        metricToCompare,
        metricName,
        primaryBenchmarkValue,
        primaryBenchmarkValues,
        secondaryBenchmarkValue,
        secondaryBenchmarkValues,
        primaryAttributionValue,
        primaryAttributionValues,
        secondaryAttributionValue,
        secondaryAttributionValues,
        type = "attribution_rules",
        metricToCompareName,
        primaryPeriod,
        secondaryPeriod,
        accountId,
    } = insight;

    if (type === "attribution_rules") {
        return {
            platform: platformToCompare,
            metric: metricToCompare,
            title: "Account",
            subTitlte: "",
            metricName: metricToCompareName,
            diffFrom: toPrettyNumber(secondaryAttributionValue, metricToCompare),
            diffTo: toPrettyNumber(primaryAttributionValue, metricToCompare),
            diffValue: getDiffValue(primaryAttributionValue, secondaryAttributionValue),
            chartData: getChartData({
                primaryValue: primaryAttributionValue,
                primaryValues: primaryAttributionValues,
                secondaryValue: secondaryAttributionValue,
                secondaryValues: secondaryAttributionValues,
            }),
            primaryPeriod,
            secondaryPeriod,
            url: getPlatformUrl({
                primaryPeriod,
                secondaryPeriod,
                accountId,
                platform: platformToCompare,
            }),
        };
    }

    if (type === "benchmark_rules") {
        return {
            platform,
            metric,
            title: "Benchmark",
            metricName,
            diffFrom: toPrettyNumber(secondaryBenchmarkValue, metric),
            diffTo: toPrettyNumber(primaryBenchmarkValue, metric),
            diffValue: getDiffValue(primaryBenchmarkValue, secondaryBenchmarkValue),
            chartData: getChartData({
                primaryValue: primaryBenchmarkValue,
                primaryValues: primaryBenchmarkValues,
                secondaryValue: secondaryBenchmarkValue,
                secondaryValues: secondaryBenchmarkValues,
            }),
            primaryPeriod,
            secondaryPeriod,
            url: getPlatformUrl({
                primaryPeriod,
                secondaryPeriod,
                accountId,
                platform,
            }),
        };
    }

    return {
        title: "Generic",
        primaryPeriod,
        secondaryPeriod,
        url: getPlatformUrl({
            primaryPeriod,
            secondaryPeriod,
            accountId,
            platform,
        }),
    };
};
