import _ from "lodash/fp";
import {
    KpiAPI,
    userRequest,
    getSelectedCampaignRegex
} from "../../lib/apiKpi";
import { grouper, grouperSum } from "../../lib/utility";
import moment from "moment";

export const sumMetrics = (elements) => (el, value) => {
    let obj = {
        ...el
    };

    elements.forEach((element) => {
        if (el.hasOwnProperty(element)) {
            obj[element] = parseFloat(el[element]) + parseFloat(value[element]);
        }
    });

    return obj;
};

function groupByDevice(data) {
    return grouper(["device"], grouperSum("viewThroughConv"))(data);
}

function groupByDate(data) {
    return grouper(
        ["date"],
        sumMetrics([
            "clicks",
            "cost",
            "impressions",
            "averagePosition",
            "viewThroughConv"
        ])
    )(data);
}

export const requestAllChannelData = ({
    dateRange: { start, end } = {},
    scope,
    state
}) => {
    const campaignRegex = getSelectedCampaignRegex(state);

    return userRequest().then(({ accountId, availableScope }) => {
        const scopes = scope
            .filter((scope) => {
                return availableScope.includes(scope);
            })
            .join(":");
        // TODO: We change aggregation to day from month make sure it does not break anything
        return Promise.all([
            KpiAPI({
                method: "get",
                url: `/channels`,
                query: {
                    accountId,
                    startDate: moment(start)
                        .subtract(1, "d")
                        .format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    scope: scopes,
                    aggregation: "day",
                    metrics:
                        "campaign,clicks,cost,impressions,averagePosition,viewThroughConv,date"
                }
            }),
            KpiAPI({
                method: "get",
                url: `/channels`,
                query: {
                    accountId,
                    startDate: moment(start)
                        .subtract(1, "d")
                        .format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                    scope: scopes,
                    aggregation: "day",
                    metrics: "campaign,viewThroughConv,device"
                }
            })
        ]).then(([{ data: date }, { data: device }]) => {
            const channels = [...Object.keys(device), ...Object.keys(date)];

            return channels.reduce(
                (prev, channel) => ({
                    ...prev,
                    [channel]: {
                        byDevice: groupByDevice(
                            device[channel].filter(
                                (item) =>
                                    !campaignRegex ||
                                    item.campaign.match(
                                        new RegExp(campaignRegex)
                                    )
                            )
                        ),
                        byDate: groupByDate(
                            date[channel].filter(
                                (item) =>
                                    !campaignRegex ||
                                    item.campaign.match(
                                        new RegExp(campaignRegex)
                                    )
                            )
                        )
                    }
                }),
                {}
            );
        });
    });
};

export const requestMetricData = ({
    startDate,
    endDate,
    aggregation,
    metrics,
    scope,
    dateRange = [],
    campaignRegex,
    abortController
}) => {
    const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD");

    const containsAdwords = scope.some(
        (scopes) => scopes.indexOf("adwords") > -1
    );

    const requestScopes = containsAdwords
        ? [
              ...scope.filter((scopes) => scopes.indexOf("adwords") === -1),
              "adwords"
          ]
        : scope;

    let params = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
        metrics: ["campaign", ...metrics].join(",")
    };

    if (dateRange.length) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD")
                };
            });

        params.dateRange = JSON.stringify(validatedDateRange);
    }

    return userRequest()
        .then(({ accountId, availableScope }) => {
            const scopes = requestScopes
                .filter((scope) => {
                    return availableScope.includes(scope);
                })
                .join(":");

            params.scope = scopes;
            params.accountId = accountId;

            const payload = {
                method: "get",
                url: `/channels`,
                query: params,
                abortController
            };

            if (aggregation) {
                payload.query.aggregation = aggregation;
            }

            return KpiAPI(payload);
        })
        .then((result) => {
            let filterResult = !campaignRegex
                ? result.data
                : Object.keys(result.data).reduce((prev, next) => {
                      return {
                          ...prev,
                          [next]: result.data[next].filter((item) =>
                              item.campaign.match(new RegExp(campaignRegex))
                          )
                      };
                  }, {});

            const toArray = (result) => (key) => result[key];

            const filterScope = () => (key) => {
                if (!scope || scope.length === 0) return true;
                if (scope.includes("adwords")) {
                    return [
                        "adwordsSearch",
                        "adwordsDisplay",
                        "adwordsVideo"
                    ].includes(key);
                }
                return scope.includes(key);
            };

            const oneDimentionalArray = _.flow(
                Object.keys,
                _.filter(filterScope()),
                _.map(toArray(filterResult)),
                _.flatten
            );

            return scope ? oneDimentionalArray(filterResult) : filterResult;
        });
};

export const requestCampaignPerformance = ({ dateRange, campaignRegex }) => {
    const scopesRequired = ["adwords", "facebook"];

    return userRequest()
        .then(({ accountId, availableScope }) => {
            const scopes = scopesRequired
                .filter((scope) => {
                    return availableScope.includes(scope);
                })
                .join(":");

            let params = {
                accountId,
                scope: scopes,
                aggregation: "day",
                metrics: [
                    "campaign",
                    "campaignId",
                    "cost",
                    "conversions",
                    "allConversions",
                    "device",
                    "clicks",
                    "impressions",
                    "date",
                    "adGroup",
                    "adGroupId",
                    "averagePosition",
                    "viewThroughConv",
                    "adsetId",
                    "adsetName",
                    "network"
                ].join(",")
            };

            if (dateRange) {
                const validatedDateRange = dateRange
                    .filter((obj) => {
                        return (
                            obj.hasOwnProperty("start") &&
                            obj.hasOwnProperty("end")
                        );
                    })
                    .map(({ start, end }) => {
                        return {
                            start: moment(start).format("YYYY-MM-DD"),
                            end: moment(end).format("YYYY-MM-DD")
                        };
                    });

                params.dateRange = JSON.stringify(validatedDateRange);
            }

            return KpiAPI({
                method: "GET",
                url: "/channels/campaign",
                query: params
            });
        })
        .then((result) => {
            if (!campaignRegex) {
                return result.data;
            }
            return Object.keys(result.data).reduce((prev, next) => {
                return {
                    ...prev,
                    [next]: result.data[next].filter((item) =>
                        item.campaign.match(new RegExp(campaignRegex))
                    )
                };
            }, {});
        });
};
