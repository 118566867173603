import moment from "moment";
import { grouper, getTotals } from "lib/utility";
import { formatSearchTermMetrics } from "./metrics";

const SEARCH_TERM_UNGROUPPED_METRICS = ["searchTerm", "adGroup", "adGroupID", "campaignId", "campaign"];

export const groupSearchTerms = ({ searchTerms = [], dateRange = {}, network, adGroup, campaign }) => {
    const { start, end } = dateRange;

    const [current, previous] = searchTerms
        .filter(
            ({ network: searchTermNetwork, adGroup: searchTermAdGroup, campaign: searchTermCampaign }) =>
                (!network || searchTermNetwork === network) &&
                (!adGroup || searchTermAdGroup === adGroup) &&
                (!campaign || searchTermCampaign === campaign),
        )
        .reduce(
            (array, item) => {
                const startDate = moment(item.date);
                if (moment(startDate).isBetween(start, end, "day", "[]")) {
                    return [[...array[0], item], array[1]];
                }
                return [array[0], [...array[1], item]];
            },
            [[], []],
        );

    const currentAdjusted = current.map((item) => formatSearchTermMetrics(item));
    const previousAdjusted = previous.map((item) => formatSearchTermMetrics(item));

    return {
        actual: grouper(
            ["searchTerm", "campaignId", "adGroupID"],
            getTotals(SEARCH_TERM_UNGROUPPED_METRICS),
        )(currentAdjusted).map(formatSearchTermMetrics || []),
        compare: grouper(
            ["searchTerm", "campaignId", "adGroupID"],
            getTotals(SEARCH_TERM_UNGROUPPED_METRICS),
        )(previousAdjusted).map(formatSearchTermMetrics || []),
        totalActual: formatSearchTermMetrics(grouper([], getTotals([]))(currentAdjusted)[0] || {}),
        totalCompare: formatSearchTermMetrics(grouper([], getTotals([]))(previousAdjusted)[0] || {}),
    };
};
