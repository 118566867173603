import FilterIcon from "assets/images/icons/JSX/Icon-Filter";
import style from "../style.module.scss";
import React from "react";

const NoAccountFoundIcon = () => {
    return (
        <div className={style.noAccountFoundIcon}>
            <FilterIcon />
        </div>
    );
};

export default NoAccountFoundIcon;
