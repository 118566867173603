import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

export const defaultState = {
    connections: {},
    integrations: {},
    campaigns: {},
    conversionTypes: {},
    isLoading: false,
    errors: {},
    step: 0,
    dismissed: false,
    campaignsLoading: null,
    bigQueryTables: [],
    bigQueryFields: [],
};

export default clearReducer(
    defaultState,
    types.CLEAR_ONBOARDING
)(
    createReducer(defaultState, {
        [types.ONBOARDING_LOADING]: (state, action) => ({
            ...state,
            isLoading: action.isLoading,
        }),
        [types.ONBOARDING_ERROR]: (state, action) => ({
            ...state,
            error: action.payload.error,
        }),
        [types.CLOSE_ONBOARDING_MODAL]: (state, action) => ({
            ...state,
            isOpen: false,
        }),
        [types.GET_CONVERSION_TYPES]: (state, action) =>
            handle(state, action, {
                success: () => {
                    return {
                        ...state,
                        conversionTypes: {
                            ...action.payload,
                        },
                    };
                },
            }),
        [types.ONBOARDING_CONNECTION]: (state, action) => {
            return {
                ...state,
                connections: {
                    ...state.connections,
                    [action.platform]: action.connectionId,
                },
            };
        },
        [types.GET_CONNECTION_CAMPAIGNS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignsLoading: true,
                }),
                success: () => {
                    return {
                        ...state,
                        campaignsLoading: false,
                        campaigns: {
                            ...state.campaigns,
                            [action.payload.integrationId]: {
                                ...action.payload.campaigns,
                            },
                        },
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    campaignsLoading: false,
                }),
            }),
        [types.GET_CONNECTION_ACCOUNTS]: (state, action) =>
            handle(state, action, {
                success: () => {
                    return {
                        ...state,
                        integrations: {
                            ...state.integrations,
                            [`${action.payload.connectionId}_${action.payload.platform}`]: {
                                ...action.payload.integrations,
                            },
                        },
                    };
                },
            }),
        [types.CHANGE_STEP]: (state, action) => ({
            ...state,
            step: action.step,
        }),
        [types.DISMISS_ONBOARDING]: (state, action) => ({
            ...state,
            dismissed: true,
        }),
        [types.GET_BIGQUERY_TABLES]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    bigQueryTablesLoading: true,
                }),
                success: () => {
                    return {
                        ...state,
                        bigQueryTablesLoading: false,
                        bigQueryTables: action.payload,
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    bigQueryTablesLoading: false,
                }),
            }),
        [types.GET_BIGQUERY_FIELDS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    bigQueryFieldsLoading: true,
                }),
                success: () => {
                    return {
                        ...state,
                        bigQueryFieldsLoading: false,
                        bigQueryFields: action.payload,
                    };
                },
                finish: (prevState) => ({
                    ...prevState,
                    bigQueryFieldsLoading: false,
                }),
            }),
    })
);
