import { KpiAPI } from "../apiKpi";

function getReport({ accountId, dimensions, dateRange, domain }) {
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/searchConsole`,
                query: {
                    accountId,
                    dimensions: JSON.stringify(dimensions),
                    dateRange: JSON.stringify([{ start, end }]),
                    domain
                }
            })
        )
    )
        .then(([result = {}, prevResult = {}]) => {
            return {
                current: result.data || [],
                previous: prevResult.data || []
            };
        })
        .catch((error) => {
            console.log("search console", error);
            return [];
        });
}

export default {
    getReport
};
