import React from "react";
import style from "./style.module.scss";
import Toggle from "components/Toggle";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import IconTime from "assets/images/icons/JSX/Icon-Time";
import classNames from "classnames";

const SelectAllCurrentAndFuture = ({ itemType = "", helpTip = "", active = false, onChange, className }) => {
    const classes = classNames(style.selectAllCurrentAndFuture, {
        [style.active]: active,
        [className]: className,
    });

    return (
        <div className={classes} onClick={onChange}>
            <IconTime />
            <span>Select All Current and Future {itemType}</span>
            {helpTip && (
                <div>
                    <HelpIcon />
                </div>
            )}
            <Toggle className={style.toggle} active={active} />
        </div>
    );
};

export default SelectAllCurrentAndFuture;
