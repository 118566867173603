import types from "./types";
import { KpiAPI } from "lib/apiKpi";

const getBenchmarkCategoriesRequest = async ({ accountId }) => {
    return KpiAPI({
        url: `aggregatedKpi/allCategories`,
        method: "GET",
        query: { accountId },
    });
};

const getBenchmarkCategoriesData = ({ accountId }) => {
    return {
        type: types.GET_BENCHMARK_CATEGORIES,
        promise: getBenchmarkCategoriesRequest({ accountId }),
    };
};

const getBenchmarkComparingRequest = async ({ accountId, similarityType, startDate, endDate, abortController }) => {
    return KpiAPI({
        url: `aggregatedKpi/comparedMetrics`,
        method: "GET",
        query: { accountId, similarityType, startDate, endDate },
        bypassCache: true,
        abortController,
    });
};

const getBenchmarkComparingData = ({ accountId, similarityType, startDate, endDate, abortController }) => {
    return {
        type: types.GET_BENCHMARK_COMPARING,
        promise: getBenchmarkComparingRequest({
            accountId,
            abortController,
            similarityType,
            startDate,
            endDate,
        }),
    };
};

const getTopWinsRequest = async ({ accountId, startDate, endDate, similarityType }) => {
    return KpiAPI({
        url: `aggregatedKpi/topWins`,
        method: "GET",
        query: { accountId, startDate, endDate, similarityType },
    });
};

export const getTopWinsData = ({ accountId, startDate, endDate, similarityType }) => {
    return {
        type: types.GET_TOP_WINS,
        promise: getTopWinsRequest({ accountId, startDate, endDate, similarityType }),
    };
};

export default {
    getBenchmarkCategoriesData,
    getBenchmarkComparingData,
};
