import React, { useRef, useEffect } from "react";

export default (onOutsideClick) => (Component) => {
    return class HandleOutsideClickComponent extends React.Component {
        constructor(props) {
            super(props);
            this.setWrapperRef = this.setWrapperRef.bind(this);
            this.handleClickOutside = this.handleClickOutside.bind(this);
        }

        setWrapperRef(node) {
            this.wrapperRef = node;
        }

        componentDidMount() {
            document.addEventListener("mousedown", this.handleClickOutside);
        }

        componentWillUnmount() {
            document.removeEventListener("mousedown", this.handleClickOutside);
        }

        handleClickOutside(event) {
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
                onOutsideClick(this.props, event);
            }
        }

        render() {
            return (
                <Component {...this.props} setWrapperRef={this.setWrapperRef} />
            );
        }
    };
};

export const useHandleOutsideClickHook = (onOutsideClick) => {
    const container = useRef(null);

    const handleClickOutside = (event) => {
        if (container.current && !container.current.contains(event.target)) {
            onOutsideClick(container, event);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return container;
};
