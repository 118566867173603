import { KpiAPI, getSelectedCampaignRegex } from "../apiKpi";
import { requestMetricData } from "../../_redux/kpi/async";
import moment from "moment";
import { store } from "../../_redux";

function getAccounts({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/facebook/accounts`,
        query: {
            userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "facebook",
                integrations: {
                    facebook: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {},
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getCampaigns({ userId, connectionId, facebookId }) {
    return KpiAPI({
        method: "GET",
        url: `/facebook/campaigns`,
        query: {
            userId,
            connectionId,
            facebookId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                facebookId,
                campaigns: {
                    facebook: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getReport({ dateRange, accountId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/channels/campaign",
        query: {
            accountId,
            dateRange,
            aggregation: "day",
            scope: "facebook",
            startDate: moment(dateRange.start).format("YYYY-MM-DD"),
            endDate: moment(dateRange.end).format("YYYY-MM-DD"),
            metrics: [
                "campaign",
                "campaignId",
                "cost",
                "conversions",
                "device",
                "clicks",
                "clicksAll",
                "impressions",
                "date",
                "engagement",
                "network",
                "outboundClicks",
            ].join(","),
        },
    }).then(({ data: { facebook } = {} } = {}) => {
        return facebook;
    });
}

export function getAds({ dateRange, accountId }) {
    const campaignRegex = getSelectedCampaignRegex(store.getState());
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/ads`,
                query: {
                    accountId,
                    dateRange: JSON.stringify([dateRange]),
                    aggregation: "day",
                    scope: "facebook",
                    startDate: moment(start).format("YYYY-MM-DD"),
                    endDate: moment(end).format("YYYY-MM-DD"),
                },
            }),
        ),
    )
        .then(([result, prevResult]) => {
            return {
                current: result.data.facebook.filter(
                    (item) => !campaignRegex || item.campaign.match(new RegExp(campaignRegex)),
                ),
                previous: prevResult.data.facebook.filter(
                    (item) => !campaignRegex || item.campaign.match(new RegExp(campaignRegex)),
                ),
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export default {
    getAccounts,
    getCampaigns,
    getReport,
    getAds,
};
