import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { SimpleListItem } from "components/DropDown/ListItems";
import style from "./style.module.scss";
import { history } from "_redux";
import { IconImage } from "components/Icon";
import { groupBy, isEmpty } from "lodash";
import { SIDEBAR_PATH } from "lib/paths";

function FoundListFeature({ toggleDropDown, foundList, selectedSite, currentPath }) {
    const selectAccountCb = useCallback(
        (path) => (e) => {
            toggleDropDown();
            history.push(`/dashboard/${selectedSite}/${path}`);
        },
        [toggleDropDown]
    );

    const pathGrouped = groupBy(foundList, "parentName");

    const foundListFortmatted = Object.keys(pathGrouped).reduce((cache, parent) => {
        if (isEmpty(SIDEBAR_PATH[parent])) return [...cache, ...pathGrouped[parent]];

        const { icon, path } = SIDEBAR_PATH[parent];

        return [...cache, { name: parent, icon, path }, ...pathGrouped[parent]];
    }, []);

    return foundListFortmatted.map(({ name, path, icon, parentName }) => (
        <SimpleListItem
            wrap={true}
            className={style.listFeatureItem}
            isActive={currentPath === "" ? path === currentPath : parentName && path === currentPath}
            onClick={selectAccountCb(path)}
            key={name}
        >
            <div className={style.iconItem}>
                <IconImage icon={icon} alt={name} />
            </div>
            {<span>{`${parentName ? "/" : ""} ${name}`}</span>}
        </SimpleListItem>
    ));
}

const mapStateToProps = ({ sites }) => ({
    selectedSite: sites.selectedSite._id,
});

export default connect(mapStateToProps, undefined)(withRouter(FoundListFeature));
