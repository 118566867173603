import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { makeSelectAccountIsOwned } from "features/ControlPanel/ducks/selectors";

const mapStateToProps = (state, { match: { params: { siteID } = {} } = {} }) => {
    return {
        isOwnAccount: makeSelectAccountIsOwned()(state, siteID),
    };
};

export default (Component) =>
    connect(mapStateToProps)(({ isOwnAccount, ...props }) => {
        if (!isOwnAccount) {
            return <Redirect to="/404" />;
        }

        return <Component {...props} />;
    });
