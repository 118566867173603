import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="evenodd">
            <rect width="5" height="14" fill="#BCCFDC" rx="1.75" />
            <rect width="8" height="6.5" x="6" fill="#BCCFDC" rx="1.75" />
            <rect width="8" height="6.5" x="6" y="7.5" fill="#BCCFDC" rx="1.75" />
        </g>
    </svg>
);
