import React from "react";

const LineBarCustom = ({ fill, x, y, height, payload, background }) => {
    const { max, benchmarkMin, benchmarkMax } = payload || {};
    if (!max) return null;

    const benchmarkMinX = (benchmarkMin / max) * background.width + x;
    const benchmarkMaxX =
        benchmarkMinX +
        ((benchmarkMax - benchmarkMin) / max) * background.width;
    const benchmarkY = y + 15;
    const benchmarkOffsetHeight = 5;
    return (
        <g>
            <rect
                x={x}
                y={y}
                rx="5"
                ry="5"
                width={background.width}
                height={height}
                fill={fill}
            />
            <path
                stroke="#6a80a7"
                strokeWidth={2}
                fill="none"
                d={`M${benchmarkMinX} ${benchmarkY} V${benchmarkY +
                    benchmarkOffsetHeight} H${benchmarkMaxX} V${benchmarkY +
                    benchmarkOffsetHeight} ${benchmarkY}`}
            />
        </g>
    );
};

export default LineBarCustom;
