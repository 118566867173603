import React, { useCallback, useState } from "react";
import style from "./style.module.scss";
import TabNavigation from "../TabNavigation";
import Tab from "../";
import classNames from "classnames";

function RenderStaticComponent({ component: Component, tabType }) {
    if (!Component) return null;

    if (typeof Component === "object") {
        return Component;
    }

    return <Component tabType={tabType} />;
}

export default ({
    children,
    className,
    tabContainerClass,
    customTabType,
    tabs,
    onChange: userOnChange,
    containerClass,
    staticComponent = null,
    isFullWidth = false,
    initialTab,
}) => {
    const classes = classNames(style.containerTabs, {
        [containerClass]: containerClass,
        [style.fullWidth]: isFullWidth,
    });

    const [tabType, changeTabType] = useState(initialTab);

    const onChange = useCallback(
        (tabType) => {
            if (typeof userOnChange === "function") {
                changeTabType(tabType);
                userOnChange(tabType, changeTabType);
            }
            changeTabType(tabType);
        },
        [userOnChange],
    );

    const RenderComponent = useCallback(() => RenderStaticComponent({ component: staticComponent, tabType }), [
        tabType,
        staticComponent,
    ]);

    return (
        <div className={classes}>
            <TabNavigation
                className={`${style.tabNavigation} ${className}`}
                tabType={customTabType ? customTabType : tabType}
                tabs={tabs}
                changeTabType={onChange}
                customStyles={{
                    activeTab: style.activeTab,
                    inactiveTab: style.inactiveTab,
                    alignRight: style.alignRight,
                }}
            />
            {RenderComponent()}
            <Tab className={tabContainerClass} tabType={customTabType ? customTabType : tabType} tabs={tabs}>
                {children}
            </Tab>
        </div>
    );
};
