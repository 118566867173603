import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

export default ({ title, message, icon: Icon, children, containerClass, hasBorder = false }) => (
    <div
        className={classNames(style.zeroStateContainer, {
            [containerClass]: containerClass,
            [style.hasBorder]: hasBorder,
        })}
    >
        {Icon && typeof Icon === "string" && <img src={Icon} alt={title} />}
        {Icon && typeof Icon !== "string" && <Icon />}
        {title && <h4>{title}</h4>}
        {message && <p>{message}</p>}
        {children}
    </div>
);
