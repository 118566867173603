import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Permissions } from "features/Permissions";
import style from "./style.module.scss";
import CustomIconButton from "./CustomIcon";
import { ReactComponent as LandingPages } from "assets/images/icons/Icon-LandingPages.svg";
import { ReactComponent as BasicBudgetingIcon } from "assets/images/icons/Icon-BudgetMonitoring.svg";
import { ReactComponent as EnhancedBudgetingIcon } from "assets/images/icons/Icon-EnhancedBudgets.svg";
import { ReactComponent as SeoRankTrackerIcon } from "assets/images/icons/Icon-SEORankTracker.svg";
import { makeSelectIsGenerating } from "_redux/sites/selectors";
import { makeSelectNavState } from "_redux/users/selectors";
import { ReactComponent as MonitorIcon } from "assets/images/Menu/Icon-Alerts.svg";
import { ReactComponent as AlertIcon } from "assets/images/Menu/Icon-AlertsSummary.svg";
import { ReactComponent as GoalMonitoringIcon } from "assets/images/Menu/Icon-Goals.svg";
import ListItem from "./ListItem";
import FreemiumListItem from "./FreemiumListItem";
import { getPathInfo } from "lib/paths";

const IconMonitor = ({ active, isLoading, dot, isNavOpen } = {}) => (
    <>
        <CustomIconButton
            key="CompetiorIcon"
            isNavOpen={isNavOpen}
            active={active}
            icon={MonitorIcon}
            label={"Alerts"}
            isLoading={isLoading}
            dot={dot}
            hasSubNav
        />
    </>
);

function AlertsDropdown({
    upgradeMessage,
    toggleModalFeature,
    selectedSite,
    setWrapperRef,
    history,
    isLoading,
    isNavOpen,
    showLegacyFeatures,
}) {
    if (!selectedSite) return null;

    const seoRankTrackerActive = history.location.pathname.split("/")[3] === "seo";
    const pageHealthActive = history.location.pathname.split("/")[3] === "pagehealth";
    const goalsActive = history.location.pathname.split("/")[3] === "goals";
    const alertsActive = history.location.pathname.split("/")[3] === "alerts";
    const budgetsActive = history.location.pathname.split("/")[3] === "financials";
    const enhancedBudgetsActive = history.location.pathname.split("/")[3] === "enhanced-budgeting";

    const active =
        seoRankTrackerActive ||
        pageHealthActive ||
        budgetsActive ||
        enhancedBudgetsActive ||
        alertsActive ||
        goalsActive;

    return (
        <div className={style.dropdownContainer}>
            <IconMonitor isLoading={isLoading} isNavOpen={isNavOpen} active={active} selectedSite={selectedSite} />

            <div ref={setWrapperRef} className={`${style.subNavigation}`} style={{ minWidth: "18.5rem" }}>
                <ListItem
                    onClick={() => history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "alertsSummary")}`)}
                    icon={AlertIcon}
                    label={"Alerts Summary"}
                    active={alertsActive}
                />
                <ListItem
                    onClick={() => history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "pagehealth")}`)}
                    icon={LandingPages}
                    label={"Landing Page Monitoring"}
                    active={pageHealthActive}
                />
                <ListItem
                    onClick={() =>
                        history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "goalMonitoring")}`)
                    }
                    icon={GoalMonitoringIcon}
                    label={"Goal Monitoring"}
                    active={goalsActive}
                />

                <ListItem
                    onClick={() => history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "financials")}`)}
                    icon={BasicBudgetingIcon}
                    label={"Budget Monitoring"}
                    active={budgetsActive}
                />
                <Permissions
                    actions={"ACTION_ENHANCED_BUDGETS"}
                    fallback={({ ACTION_ENHANCED_BUDGETS } = {}) => {
                        if (ACTION_ENHANCED_BUDGETS === upgradeMessage) {
                            return (
                                <FreemiumListItem
                                    onClick={() =>
                                        toggleModalFeature({
                                            isOpen: true,
                                            feature: "Enhanced Budget Monitoring",
                                            video: "kYEgJfAZyVo",
                                        })
                                    }
                                    icon={EnhancedBudgetingIcon}
                                    label={"Enhanced Budget Monitoring"}
                                    active={enhancedBudgetsActive}
                                />
                            );
                        }
                    }}
                >
                    <ListItem
                        onClick={() =>
                            history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "enhancedBudgeting")}`)
                        }
                        icon={EnhancedBudgetingIcon}
                        label={"Enhanced Budget Monitoring"}
                        active={enhancedBudgetsActive}
                    />
                </Permissions>
                {/* {showLegacyFeatures && (
                    <Permissions
                        actions={"ACTION_SEOPERFORMANCE"}
                        fallback={({ ACTION_SEOPERFORMANCE } = {}) => {
                            if (ACTION_SEOPERFORMANCE === upgradeMessage) {
                                return (
                                    <FreemiumListItem
                                        onClick={() =>
                                            toggleModalFeature({
                                                isOpen: true,
                                                feature: "SEO Rank Monitoring",
                                                video: "GAxm6SOdnDg",
                                            })
                                        }
                                        icon={SeoRankTrackerIcon}
                                        label={"SEO Rank Monitoring"}
                                        active={seoRankTrackerActive}
                                    />
                                );
                            }
                        }}
                    >
                        <ListItem
                            onClick={() => history.push(`/dashboard/${selectedSite}/${getPathInfo("Alerts", "seo")}`)}
                            icon={SeoRankTrackerIcon}
                            label={"SEO Rank Monitoring"}
                            active={seoRankTrackerActive}
                        />
                    </Permissions>
                )} */}
            </div>
        </div>
    );
}

export default compose(
    withRouter,
    connect((state) => ({
        isNavOpen: makeSelectNavState()(state),
        isLoading: makeSelectIsGenerating(["seo_anomalies", "daily_anomalies", "competitors", "health_checks"])(state),
    })),
)(AlertsDropdown);
