import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import isArray from "lodash/fp/isArray";

import moment from "moment";
import { handle } from "redux-pack";
import types from "./types";

/**
 * REDUCERS
 */

export const defaultState = {
    goals: [],
    startMonth: moment(),
    endMonth: moment(),
    facebookBreakdown: {},
    adwordsBreakdown: {},
    initAdwordsBreakdown: {},
    initFacebookBreakdown: {},
    facebookAttribution: {},
    initFacebookAttribution: {},
    pinterestAttribution: {},
    initPinterestAttribution: {},
    linkedinBreakdown: {},
    initLinkedinBreakdown: {},
    facebookBreakdownLoading: false,
    linkedinBreakdownLoading: false,
    channels: [],
    error: false,
    pending: false,
    conversionTypes: [],
    initConversionTypes: [],
    conversionTypesSelectAllCurrentAndFuture: false,
    availableChannels: [],
    conversionTypesPending: false,
    selectedAnalyticsIntegration: "",
    selectedAdwordsIntegration: "",
    selectedAnalyticsV4Integration: "",
    conversionsTouched: false,
    campaignGroups: {},
    campaignGroupsLoading: false,
    changeLogs: [],
    changeLogsPending: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        /**
         * Facebook
         */
        [types.SETTINGS_FACEBOOK_BREAKDOWN](state, action) {
            return {
                ...state,
                facebookBreakdown: action.payload,
                initFacebookBreakdown: action.payload,
            };
        },
        [types.SETTINGS_FACEBOOK_BREAKDOWN_LOADING](state, action) {
            return {
                ...state,
                facebookBreakdownLoading: action.isLoading,
            };
        },
        [types.SET_INITIAL_FACEBOOK_BREAKDOWN](state, action) {
            return {
                ...state,
                initFacebookBreakdown: action.payload,
            };
        },
        [types.SETTINGS_UPDATE_FACEBOOK_BREAKDOWN](state, action) {
            return {
                ...state,
                facebookBreakdown: {
                    ...state.facebookBreakdown,
                    ...action.payload,
                },
            };
        },
        [types.GET_FACEBOOK_ATTRIBUTION](state, action) {
            return {
                ...state,
                facebookAttribution: action.attribution,
                initFacebookAttribution: action.attribution,
            };
        },
        [types.SET_FACEBOOK_ATTRIBUTION](state, action) {
            return {
                ...state,
                facebookAttribution: {
                    ...state.facebookAttribution,
                    ...action.payload,
                },
            };
        },
        [types.GET_PINTEREST_ATTRIBUTION](state, action) {
            return {
                ...state,
                pinterestAttribution: action.attribution,
                initPinterestAttribution: action.attribution,
            };
        },
        [types.SET_PINTEREST_ATTRIBUTION](state, action) {
            return {
                ...state,
                pinterestAttribution: {
                    ...state.pinterestAttribution,
                    ...action.payload,
                },
            };
        },
        [types.SET_INITIAL_FACEBOOK_ATTRIBUTION](state, action) {
            return {
                ...state,
                initFacebookAttribution: action.attribution,
            };
        },
        [types.SET_INITIAL_PINTEREST_ATTRIBUTION](state, action) {
            return {
                ...state,
                initPinterestAttribution: action.attribution,
            };
        },
        /**
         * GA4
         */
        [types.SETTINGS_ANALYTICSV4_BREAKDOWN](state, action) {
            return {
                ...state,
                analyticsV4Breakdown: action.payload,
                initanalyticsV4Breakdown: action.payload,
            };
        },
        [types.SETTINGS_ANALYTICSV4_BREAKDOWN_LOADING](state, action) {
            return {
                ...state,
                analyticsV4BreakdownLoading: action.isLoading,
            };
        },
        [types.SET_INITIAL_ANALYTICSV4_BREAKDOWN](state, action) {
            return {
                ...state,
                initAnalyticsV4Breakdown: action.payload,
            };
        },
        [types.SETTINGS_UPDATE_ANALYTICSV4_BREAKDOWN](state, action) {
            return {
                ...state,
                analyticsV4Breakdown: {
                    ...state.analyticsV4Breakdown,
                    ...action.payload,
                },
            };
        },
        [types.SETTINGS_UPDATE_ANALYTICSV4_INTEGRATION](state, action) {
            return {
                ...state,
                selectedAnalyticsV4Integration: action.integration,
            };
        },
        /**
         * Adwords
         */
        [types.SET_INITIAL_ADWORDS_BREAKDOWN](state, action) {
            return {
                ...state,
                initAdwordsBreakdown: action.payload,
            };
        },
        [types.SETTINGS_UPDATE_ADWORDS_BREAKDOWN](state, action) {
            return {
                ...state,
                adwordsBreakdown: {
                    ...state.adwordsBreakdown,
                    ...action.payload,
                },
            };
        },
        [types.GET_ADWORDS_CONVERSIONS](state, action) {
            return {
                ...state,
                adwordsBreakdown: action.conversions,
                initAdwordsBreakdown: action.conversions,
            };
        },
        [types.SETTINGS_UPDATE_ADWORDS_INTEGRATION](state, action) {
            return {
                ...state,
                selectedAdwordsIntegration: action.integration,
            };
        },
        /**
         * Linkedin
         */
        [types.SETTINGS_LINKEDIN_BREAKDOWN](state, action) {
            return {
                ...state,
                linkedinBreakdown: action.payload,
                initLinkedinBreakdown: action.payload,
            };
        },
        [types.SETTINGS_LINKEDIN_BREAKDOWN_LOADING](state, action) {
            return {
                ...state,
                linkedinBreakdownLoading: action.isLoading,
            };
        },
        [types.SETTINGS_UPDATE_LINKEDIN_BREAKDOWN](state, action) {
            return {
                ...state,
                linkedinBreakdown: {
                    ...state.linkedinBreakdown,
                    ...action.payload,
                },
            };
        },
        [types.SET_INITIAL_LINKEDIN_BREAKDOWN](state, action) {
            return {
                ...state,
                initLinkedinBreakdown: action.payload,
            };
        },
        /**
         * Shared
         */
        [types.GET_SLACK_CHANNELS](state, action) {
            return handle(state, action, {
                finish: (prevState) => ({
                    ...prevState,
                    availableChannels: isArray(action.payload) ? action.payload : [],
                }),
            });
        },
        [types.SET_CONVERSIONS_TOUCHED](state, action) {
            return {
                ...state,
                conversionsTouched: action.touched,
            };
        },
        [types.RESET_CONVERSION_SETTINGS](state, action) {
            return {
                ...state,
                facebookAttribution: state.initFacebookAttribution,
                pinterestAttribution: state.initPinterestAttribution,
                facebookBreakdown: state.initFacebookBreakdown,
                linkedinBreakdown: state.initLinkedinBreakdown,
                conversionTypes: state.initConversionTypes,
            };
        },
        [types.TOGGLE_CONVERSIONS_CURRENT_AND_FUTURE](state, action) {
            return {
                ...state,
                conversionTypesSelectAllCurrentAndFuture: action.selectAll,
            };
        },
        /**
         * Analytics
         *  */
        [types.GET_CONVERSION_TYPES_FULFILLED](state, action) {
            return {
                ...state,
                conversionTypesError: false,
                conversionTypes: action.payload,
            };
        },
        [types.SET_INITIAL_CONVERSION_TYPES](state, action) {
            return {
                ...state,
                initConversionTypes: action.payload,
            };
        },
        [types.GET_CONVERSION_TYPES_REJECTED](state, action) {
            return {
                ...state,
                conversionTypesError: action.error,
            };
        },
        [types.GET_CONVERSION_TYPES_PENDING](state, action) {
            return {
                ...state,
                conversionTypesPending: action.pending,
            };
        },
        [types.SETTINGS_UPDATE_ANALYTICS_INTEGRATION](state, action) {
            return {
                ...state,
                selectedAnalyticsIntegration: action.integration,
            };
        },
        /**
         * Campaign Groups
         *  */

        [types.UPDATE_CAMPAIGN_GROUP](state, action) {
            const { [action.groupId]: group } = state.campaignGroups;
            return {
                ...state,
                campaignGroups: {
                    ...state.campaignGroups,
                    [action.groupId]: {
                        ...group,
                        ...action.updates,
                    },
                },
            };
        },
        [types.GET_CAMPAIGN_GROUPS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignGroupsLoading: true,
                }),
                success: (prevState) => ({
                    ...prevState,
                    campaignGroups: {
                        ...state.campaignGroups,
                        ...action.payload.data,
                    },
                    campaignGroupsLoading: false,
                }),
            });
        },
        [types.DELETE_CAMPAIGN_GROUP]: (state, action) => {
            const { [action.groupId]: _, ...remainingGroups } = state.campaignGroups || {};

            return {
                ...state,
                campaignGroups: remainingGroups,
            };
        },
        [types.ADD_ACCOUNT_CHANGE_LOGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    changeLogsPending: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        changeLogs: [...prevState.changeLogs, { id: action.payload.id, ...action.meta }],
                        changeLogsPending: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    error: true,
                    changeLogsPending: false,
                }),
            });
        },
        [types.UPDATE_ACCOUNT_CHANGE_LOGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    changeLogsPending: true,
                }),
                success: (prevState) => {
                    const { id: idEdited } = action.payload;

                    const newChangeLog = prevState.changeLogs.map((changeLog) => {
                        if (changeLog.id === idEdited) return action.meta;
                        return changeLog;
                    });

                    return {
                        ...prevState,
                        changeLogs: newChangeLog,
                        changeLogsPending: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    error: true,
                    changeLogsPending: false,
                }),
            });
        },
        [types.DELETE_ACCOUNT_CHANGE_LOGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    changeLogsPending: true,
                }),
                success: (prevState) => {
                    const { id, date } = action.meta;

                    const newChangeLog = prevState.changeLogs.filter((changeLog) => changeLog.id !== id);

                    return {
                        ...prevState,
                        changeLogs: newChangeLog,
                        changeLogsPending: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    error: true,
                    changeLogsPending: false,
                }),
            });
        },
        [types.GET_ACCOUNT_CHANGE_LOGS]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    changeLogsPending: true,
                    changeLogs: [],
                }),
                success: (prevState) => ({
                    ...prevState,
                    changeLogs: action.payload,
                    changeLogsPending: false,
                }),
                failure: (preState) => ({
                    ...preState,
                    changeLogsPending: false,
                }),
            });
        },
    }),
);
