import React from "react";
import { pure } from "recompose";
import style from "./style.module.scss";
import classNames from "classnames";

export default pure(
    ({
        children,
        onClick,
        disabled = false,
        className = "",
        icon: Icon,
        iconFullColor = false,
        iconSuffix: IconSuffix,
        suffixOnClick = () => null,
        type = "button",
        secondary = false,
        tertiary = false,
        green = false,
        slate = false,
        hasMargin = false,
        small = false,
        medium = false,
        headerHeight = false,
        download = false,
        hollow = false,
        hollowPurple = false,
        hollowGreen = false,
        hollowWhite = false,
        emptyBg = false,
        blue = false,
        lightBlue = false,
        red = false,
        vibrantBlue = false,
        violet,
        darkerPurple = false,
        redLight = false,
        orange = false,
        ...props
    }) => {
        const classes = classNames(style.button, {
            [className]: className,
            [style.secondaryButton]: secondary,
            [style.tertiaryButton]: tertiary,
            [style.greenButton]: green,
            [style.margin]: hasMargin,
            [style.smallButton]: small,
            [style.mediumButton]: medium,
            [style.downloadButton]: download,
            [style.hollowButton]: hollow,
            [style.hollowGreenButton]: hollowGreen,
            [style.hollowPurpleButton]: hollowPurple,
            [style.hollowWhiteButton]: hollowWhite,
            [style.emptyBgButton]: emptyBg,
            [style.headerHeight]: headerHeight,
            [style.slateButton]: slate,
            [style.blueButton]: blue,
            [style.lightBlueButton]: lightBlue,
            [style.redButton]: red,
            [style.redLightButton]: redLight,
            [style.vibrantBlueButton]: vibrantBlue,
            [style.violetButton]: violet,
            [style.darkerPurpleButton]: darkerPurple,
            [style.iconOnly]: !children,
            [style.iconFullColor]: iconFullColor, // only implemented this for hollowbutton for now should add it to others too
            [style.orangeButton]: orange,
        });

        return (
            <button type={type} disabled={disabled} className={classes} onClick={onClick} {...props}>
                {Icon && (
                    <span className={style.buttonIcon}>
                        <Icon />
                    </span>
                )}

                {children && <span>{children}</span>}

                {IconSuffix && (
                    <span
                        className={`${style.buttonIconSuffix} ${typeof suffixOnClick === "function" &&
                            style.buttonSuffixHasOnClick}`}
                    >
                        <IconSuffix onClick={suffixOnClick} />
                    </span>
                )}
            </button>
        );
    },
);
