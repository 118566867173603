import { api } from "lib/shopify";
import types from "./types";
import moment from "moment";

const getShopifyData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    return dispatch({
        type: types.SHOPIFY_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
            abortController,
        }),
    });
};

const getShopifyLtvData = ({ start, end, accountId, key }) => (dispatch) => {
    return dispatch({
        type: types.SHOPIFY_LTV,
        promise: api.getLtv({
            start,
            end,
            accountId,
        }),
        meta: {
            key,
        },
    });
};

const getShopifyOrdersData = ({ dateRange, accountId, abortController }) => (dispatch) => {
    const params = {
        accountId,
        abortController,
    };

    if (dateRange.length) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: moment(start).format("YYYY-MM-DD"),
                    end: moment(end).format("YYYY-MM-DD"),
                };
            });

        params.dateRange = validatedDateRange;
    }

    return dispatch({
        type: types.SHOPIFY_ORDERS,
        promise: api.getOrders(params),
    });
};

export default {
    getShopifyData,
    getShopifyOrdersData,
    getShopifyLtvData,
};
