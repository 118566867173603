import React from "react";

export default () => (
    <svg width="14px" height="11px" viewBox="0 0 14 11" version="1.1">
        <g id="⚙️-Settings" stroke="none" strokeWidth="1">
            <g
                id="5.4-Settings-(Goals)"
                transform="translate(-344.000000, -451.000000)"
            >
                <g id="Framework">
                    <g
                        id="Content"
                        transform="translate(262.000000, 136.000000)"
                    >
                        <g
                            id="Component---Conversion"
                            transform="translate(10.000000, 176.000000)"
                        >
                            <g
                                id="1"
                                transform="translate(31.000000, 112.000000)"
                            >
                                <path
                                    d="M54.6247454,27.3748957 C54.1239522,26.8750348 53.3125538,26.8750348 52.8117834,27.3748957 L45.1869257,34.9858839 L43.1594819,33.2474566 C42.6655514,32.7544002 41.864813,32.7544002 41.3704479,33.2474566 C40.8765174,33.7404901 40.8765174,34.5401854 41.3704479,35.0332189 L44.3994552,37.6302077 C44.8933857,38.1232641 45.6941241,38.1232641 46.1884892,37.6302077 C46.2380607,37.5807491 46.2795113,37.525719 46.3192463,37.4707117 C46.3264979,37.4638843 46.3346187,37.4591804 46.3418932,37.4523531 L54.6247683,29.1841086 C55.1250811,28.6846815 55.1250811,27.8743228 54.6247454,27.3748957 Z"
                                    id="Shape-Copy-2"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
