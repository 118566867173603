import React, { useState } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import VideoModal from "components/YoutubeVideo/VideoModal";
import IconPlay from "assets/images/icons/JSX/Play-Small";
import { mixpanel } from "components/Mixpanel";
import isEmpty from "lodash/isEmpty";

export default ({ videoId, title, className, tracking = {}, light = false }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModalOpen = async () => {
        await mixpanel.track("Watch Video Button", {
            "Video ID": videoId,
            "Video Title": title,
        });
        if (!isEmpty(tracking)) {
            const { title: trackingTitle = "", properties: trackingProps = {} } = tracking;
            await mixpanel.track(trackingTitle, trackingProps);
        }
        setModalOpen(!modalOpen);
    };

    return (
        <>
            <span
                className={classNames(style.watchButton, { [className]: className, [style.lightTheme]: light })}
                onClick={toggleModalOpen}
            >
                <IconPlay />
                <span>{title}</span>
            </span>
            <VideoModal videoId={videoId} open={modalOpen} toggleModal={toggleModalOpen} />
        </>
    );
};
