import React from "react";

export default ({ styles }) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 180 180"
  {...styles}
>
  <path
    style={{
      opacity: 0.998,
    }}
    fill="#a0b5c4"
    d="M9.5-.5h160c4.333 2.333 7.667 5.667 10 10v160c-2.333 4.333-5.667 7.667-10 10H9.5c-4.333-2.333-7.667-5.667-10-10V9.5c2.333-4.333 5.667-7.667 10-10Z"
  />
  <path
    style={{
      opacity: 1,
    }}
    fill="#fafdff"
    d="M54.5 68.5c-1.209 4.118-1.876 8.451-2 13v3c-1.31 5.788-1.977 11.788-2 18a260.162 260.162 0 0 1-5 30h-10v-5a1249.882 1249.882 0 0 1 10-82.5 49.248 49.248 0 0 1 14 0 1320.447 1320.447 0 0 1 28 71.5 3058.263 3058.263 0 0 1 28-71.5 49.248 49.248 0 0 1 14 0l1.5 1.5c3.004 28.7 6.171 57.366 9.5 86a60.931 60.931 0 0 1-11-.5 9.454 9.454 0 0 1-1.5-2.5 1246.573 1246.573 0 0 0-8-66l-25 67-1.5 1.5c-4.255.796-8.421.629-12.5-.5-8.676-22.7-17.343-45.367-26-68-.493 1.634-.66 3.3-.5 5Z"
  />
  <path
    style={{
      opacity: 1,
    }}
    fill="#a0b5c4"
    d="M54.5 68.5c.22 4.6-.446 8.933-2 13 .124-4.549.791-8.882 2-13Z"
  />
  <path
    style={{
      opacity: 1,
    }}
    fill="#a0b5c4"
    d="M52.5 84.5c.112 6.226-.554 12.226-2 18 .023-6.212.69-12.212 2-18Z"
  />
  <path
    style={{
      opacity: 1,
    }}
    fill="#a0b5c4"
    d="M35.5 127.5v5h10c-3.462.982-7.129 1.315-11 1-.295-2.235.039-4.235 1-6Z"
  />
</svg>
  );

