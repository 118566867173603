import { toPrettyNumber } from "lib/metricCalculations";
import { channelFriendlyName, metricFriendlyName } from "lib/utility";

/**
 * Produces copy for alerts
 * @param {object} params
 * @param {string} params.url enum of feature identifier
 * @returns {string} url based on feature identifier
 */

export const getAlertCopies = ({ alert: item, accountId, customMetrics }) => {
    if (["goalZeroState", "budgetZeroState", "enhancedBudgetZeroState"].includes(item?.channel)) {
        try {
            const { metric: { interval, recentValue, anomaly, flag, name = "" } = {}, channel, type, id } = item || {};

            const [platform, ...met] = name.includes("-") ? name.split("-") : [, name];
            const metricName = met.join("-");

            return {
                title: getZeroStateTitle({
                    metricName,
                    platform,
                    interval,
                    accountId,
                    ...item,
                }),
                text: getZeroStateText({
                    metricName,
                    platform,
                    interval,
                    accountId,
                    ...item,
                }),
                fields: [
                    {
                        id: `${id}ToDate`,
                        title: "To Date:",
                        value: getFieldValue({
                            customMetrics,
                            recentValue,
                            channel,
                            metricName,
                            platform,
                            accountId,
                            type,
                        }),
                    },
                    {
                        id: `${id}Expected`,
                        title: "Expected:",
                        value: getAnomalyExpectedRange({
                            customMetrics,
                            metricName,
                            channel,
                            accountId,
                            platform,
                            ...item,
                        }),
                    },
                ],
                flag: flag,
                anomaly: anomaly,
                channel: channel,
                metric: metricName,
                url: getAnomalyUrl({ accountId, channel, type }),
                type: type,
            };
        } catch (error) {
            console.log({ error, item });
            return null;
        }
    }

    if (["budgetReminders", "enhancedBudgetReminders"].includes(item?.channel)) {
        try {
            const { metric: { anomaly, flag, name = "", recentValue } = {}, channel, type, id } = item || {};

            const [platform, ...met] = name.includes("-") ? name.split("-") : [, name];
            const metricName = met.join("-");

            return {
                title: getReminderTitle({
                    accountId,
                    ...item,
                }),
                text: getReminderText({ accountId, ...item }),
                fields: [
                    {
                        id: `${id}ToDate`,
                        title: "To Date",
                        value: getFieldValue({
                            customMetrics,
                            recentValue,
                            channel,
                            metricName,
                            platform,
                            accountId,
                            type,
                        }),
                    },
                    {
                        id: `${id}Expected`,
                        title: "Expected",
                        value: getAnomalyExpectedRange({
                            customMetrics,
                            metricName,
                            channel,
                            accountId,
                            platform,
                            ...item,
                        }),
                    },
                ],
                flag: flag,
                anomaly: anomaly,
                channel: channel,
                metric: metricName,
                type: type,
                url: getAnomalyUrl({ accountId, channel, type }),
            };
        } catch (error) {
            console.log({ error, item });
            return null;
        }
    }

    if (["budget", "goal", "enhancedBudgetAnomalies"].includes(item?.channel)) {
        try {
            const { metric: { flag, anomaly, recentValue, name } = {}, channel, type, id } = item || {};
            const [platform, ...met] = name.includes("-") ? name.split("-") : [, name];
            const metricName = met.join("-");

            return {
                title: getAnomalyTitle({
                    metricName,
                    platform,
                    accountId,
                    ...item,
                }),
                text: getAnomalyText({
                    metricName,
                    platform,
                    accountId,
                    ...item,
                }),
                fields: [
                    {
                        id: `${id}ToDate`,
                        title: "To Date",
                        value: getFieldValue({
                            customMetrics,
                            recentValue,
                            channel,
                            metricName,
                            platform,
                            accountId,
                            type,
                        }),
                    },
                    {
                        id: `${id}Expected`,
                        title: "Expected",
                        value: getAnomalyExpectedRange({
                            customMetrics,
                            metricName,
                            channel,
                            accountId,
                            platform,
                            ...item,
                        }),
                    },
                ],
                flag: flag,
                anomaly: anomaly,
                channel: channel,
                metric: metricName,
                type: type,
                url: getAnomalyUrl({ accountId, channel }),
            };
        } catch (error) {
            console.log({ error, item });
            return null;
        }
    }

    if (["seo"].includes(item?.channel)) {
        try {
            const {
                metric: { recentGrowth, oldRank, newRank, url, flag, keyword, anomaly, self, thr } = {},
                channel,
                id,
            } = item;

            const title = self
                ? `Change in SEO rank detected for your domain!`
                : `Change in SEO rank detected for competitor domain!`;

            const text = `The search rank for the keyword "${keyword}" has ${
                recentGrowth > 0 ? "increased" : "decreased"
            } by more than ${thr} position(s) for the following page URL: ${url}`;

            return {
                title: title,
                text: text,
                fields: [
                    {
                        id: `${id}SEO`,
                        title: "",
                        value: `From ${oldRank} to ${newRank}`,
                    },
                ],
                url: getAnomalyUrl({ accountId, channel }),
                anomaly: anomaly,
                flag: flag,
                type: "seo",
            };
        } catch (error) {
            console.log({ error, item });
            return null;
        }
    }

    if (["page_health_hourly"].includes(item?.channel)) {
        try {
            const {
                url,
                channel,
                metric: { name, anomaly, flag, platforms, recentValue, oldValue, datetime },
                id,
            } = item;

            const getText = ({ name, flag }) => {
                switch (name) {
                    case "SEO_HEALTH_CHANGE":
                        return flag === "positive" ? "Your SEO Score has improved." : "Your SEO Score has gone down.";
                    case "SSL_EXPIRED":
                        return flag === "positive"
                            ? "Your SSL certificate issue has been fixed."
                            : "Your SSL certificate is expired.";
                    case "SSL_EXPIRE_SOON":
                        return flag === "positive"
                            ? "Your SSL certificate has been updated."
                            : "Your SSL certificate is expiring soon.";
                    case "SSL_FAIL":
                        return flag === "positive"
                            ? "Your SSL certificate issue has been fixed."
                            : "Your SSL verification has failed.";
                    case "PAGE_FAIL":
                        return flag === "positive" ? "Your page is live again." : "Your page has failed to load.";
                    case "PAGE_REDIRECT":
                        return flag === "positive"
                            ? "Your redirect issue has been fixed."
                            : "Your page has redirected. Cross-domain redirects are not allowed and can result in disapproval.";
                    default:
                        return "Not availiable.";
                }
            };

            const getPlatformStr = ({ platforms }) => {
                if (!platforms || !platforms.length) {
                    return "";
                }

                return `This page has been used in your ${platforms
                    .map((a) => channelFriendlyName(a, accountId))
                    .join(", ")
                    .replace(/, ([^,]*)$/, " and $1")} ${platforms.length > 1 ? "platforms" : "platform"}.`;
            };

            const title = `We have detected an update on one of your advertising landing pages.`;
            const text = getText({ name, flag });
            const platformStr = getPlatformStr({ platforms });

            const format = (v) => {
                if (isNaN(v)) return v;
                if (v <= 1) return Math.floor(parseFloat(v) * 100) + "%";
                return v;
            };

            return {
                title,
                text: `${text} ${platformStr} URL: ${url}`,
                fields: [
                    {
                        id: `${id}pageHealth`,
                        title: "",
                        value: `From ${format(oldValue)} to ${format(recentValue)}`,
                    },
                ],
                url: getAnomalyUrl({ accountId, channel }),
                anomaly: anomaly,
                flag: flag,
                type: "pageHealth",
                metric: "pageHealth",
            };
        } catch (error) {
            console.log({ error, item });
            return null;
        }
    }

    // new alert format
    if (["budget_pacing", "budget_milestone", "goal_pacing", "goal_milestone"].includes(item?.type)) {
        const {
            dailyData = [],
            content: { alertType, dateRange, expectedValue, link, metric, name, platforms, property, value } = {},
            status,
            id,
        } = item;
        return {
            title: property,
            name,
            status,
            text: `Alert: ${name}`,
            descriptions: [
                {
                    id: `${id}AlertName`,
                    title: "Alert Name",
                    value: name,
                },
                {
                    id: `${id}AlertType`,
                    title: "Alert Type",
                    value: alertType,
                },
                {
                    id: `${id}Metric`,
                    title: "Metric",
                    value: metric,
                },
            ],
            fields: [
                {
                    id: `${id}AlertType`,
                    title: "Alert Type",
                    value: alertType,
                },
                {
                    id: `${id}Metric`,
                    title: "Metric",
                    value: metric,
                },
                {
                    id: `${id}Value`,
                    title: "Value",
                    value: value,
                    status,
                },
                {
                    id: `${id}ExpectedValue`,
                    title: "Expected Value",
                    value: expectedValue,
                },
            ],
            url: link,
            dateRange,
            platforms,
            type: item?.type,
            dailyData,
            alertType,
        };
    }

    return null;
};

const getAnomalyUrl = ({ channel, accountId }) => {
    switch (channel) {
        case "budget":
            return `/dashboard/${accountId}/financials`;
        case "budgetZeroState":
            return `/dashboard/${accountId}/financials`;
        case "budgetReminders":
            return `/dashboard/${accountId}/financials`;
        case "goal":
            return `/dashboard/${accountId}/goals-settings`;
        case "goalZeroState":
            return `/dashboard/${accountId}/goals-settings`;
        case "enhancedBudgetAnomalies":
            return `/dashboard/${accountId}/enhanced-budgeting`;
        case "enhancedBudgetReminders":
            return `/dashboard/${accountId}/enhanced-budgeting`;
        case "enhancedBudgetZeroState":
            return `/dashboard/${accountId}/enhanced-budgeting`;
        case "seo":
            return `/dashboard/${accountId}/seo/keywords`;
        case "page_health_hourly":
            return `/dashboard/${accountId}/pagehealth/ads`;
        default:
            return `/dashboard/${accountId}/analysis`;
    }
};

const getAnomalyExpectedRange = ({ metricName, channel, metric, platform, customMetrics, accountId }) => {
    if (platform === "custom") {
        const { [`custom-${metricName}`]: { format, changeInMonth = "stable" } = {} } = customMetrics || {};
        return changeInMonth !== "stable"
            ? `${toPrettyNumber(metric.goalToDateTarget, format, accountId)} (of ${toPrettyNumber(
                  metric.monthlyTarget,
                  format,
                  accountId,
              )})`
            : `${toPrettyNumber(metric.goalToDateTarget, format, accountId)}`;
    }

    if (channel.includes("enhancedBudget")) {
        return `${toPrettyNumber(metric.goalToDateTarget, "cost", accountId)} (of ${toPrettyNumber(
            metric.budget,
            "cost",
            accountId,
        )})`;
    }

    switch (channel) {
        case "budget":
            return `${toPrettyNumber(metric.goalToDateTarget, "cost", accountId)} (of ${toPrettyNumber(
                metric.monthlyTarget,
                "cost",
                accountId,
            )})`;
        case "goal":
            return metric.isIncreasingInMonth
                ? `${toPrettyNumber(metric.goalToDateTarget, metricName, accountId)} (of ${toPrettyNumber(
                      metric.monthlyTarget,
                      metricName,
                      accountId,
                  )})`
                : `${toPrettyNumber(metric.goalToDateTarget, metricName, accountId)}`;
        default:
            return null;
    }
};

const getAnomalyTitle = ({ metric, channel, platform, metricName, accountId }) => {
    if (channel.includes("enhancedBudget")) {
        return metric.type === "overBudget"
            ? `Your budget group, ${metric.name}, has exceeded 100% of the total budget set.`
            : `Your budget group, ${metric.name}, is currently off pace compared to the budget initially set.`;
    }
    const metricStr = metricFriendlyName(metricName, platform, accountId);
    switch (channel) {
        case "budget":
            const name = metric.recentValue > metric.goalToDateTarget ? "overbudget" : "underbudget";
            return metric.type === "overTotalBudget"
                ? `Your ${channelFriendlyName(
                      metric.name,
                      accountId,
                  )} media spend has exceeded 100% of the total budget set.`
                : `${channelFriendlyName(metric.name, accountId)} is ${name}!`;
        case "goal":
            const isOrAre = metricStr[metricStr.length - 1] === "s" ? "are" : "is";
            const higherOrLower = metric.recentValue > metric.goalToDateTarget ? "higher" : "lower";
            return `${toCapitalize(metricStr)} in ${channelFriendlyName(
                platform,
                accountId,
            )} ${isOrAre} ${higherOrLower} than expected!`;
        default:
            const channelStr =
                channel == "allTraffic" ? `` : ` from ${channelFriendlyName(channel, accountId)} traffic`;
            return `You should take a look at the ${metricStr}${channelStr}!`;
    }
};

const getZeroStateTitle = ({ metric, channel, platform, metricName, accountId }) => {
    if (channel.includes("enhancedBudget")) {
        return `No media spend has been detected in your budget group, ${metric.name}!`;
    }
    const metricStr = metricFriendlyName(metricName, platform, accountId);
    switch (channel) {
        case "budgetZeroState":
            return `No media spend has been detected in your ${channelFriendlyName(metric.name, accountId)} account!`;
        case "goalZeroState":
            return `Your ${metricStr} in ${channelFriendlyName(platform, accountId)} is currently zero!`;
        default:
            return `Your ${metricStr} in ${channelFriendlyName(platform, accountId)} is currently zero!`;
    }
};

const getReminderTitle = ({ metric, accountId, channel }) => {
    if (channel.includes("enhancedBudget")) {
        return `Your budget group, ${metric.name}, has exceeded ${metric.budgetReminderPercent}% of the total budget set.`;
    }
    return `Your ${channelFriendlyName(metric.name, accountId)} media spend has exceeded ${
        metric.budgetReminderPercent
    }% of the total budget set.`;
};

const getFieldValue = ({ recentValue, channel, metricName, accountId, platform, customMetrics }) => {
    if (channel.includes("enhancedBudget")) {
        return toPrettyNumber(recentValue, "cost", accountId);
    }
    if (platform === "custom") {
        const { [`custom-${metricName}`]: { format } = {} } = customMetrics || {};
        return toPrettyNumber(recentValue, format, accountId);
    }
    return channel === "budget"
        ? toPrettyNumber(recentValue, "cost", accountId)
        : toPrettyNumber(recentValue, metricName, accountId);
};

const getAnomalyText = ({ channel, metric, accountId, metricName, platform }) => {
    const {
        predictedValue = 0,
        recentValue = 0,
        stat: { mean: meanValue = 0 } = {},
        goalToDateTarget = 0,
        budget = 0,
        type: subType,
        deviation = 0,
        isIncreasingInMonth,
    } = metric || {};

    if (channel.includes("enhancedBudget")) {
        if (subType === "overBudget") {
            return `Your budget group has spent ${toPrettyNumber(
                recentValue,
                "cost",
                accountId,
            )} which is more than the budget initially set, ${toPrettyNumber(budget, "cost", accountId)}.`;
        }
        // off track
        return recentValue > goalToDateTarget
            ? // overbudget
              `Your budget group, ${metric.name}, has spent ${toPrettyNumber(
                  recentValue,
                  "cost",
                  accountId,
              )} which is more than ${deviation}% of the expected media spend-to-date of ${toPrettyNumber(
                  goalToDateTarget,
                  "cost",
                  accountId,
              )}.`
            : // underbudget
              `Your budget group, ${metric.name}, has spent ${toPrettyNumber(
                  recentValue,
                  "cost",
                  accountId,
              )} which is less than ${deviation}% of the expected media spend-to-date of ${toPrettyNumber(
                  goalToDateTarget,
                  "cost",
                  accountId,
              )}.`;
    }

    const percent = goalToDateTarget
        ? Math.abs(Math.round(((recentValue - goalToDateTarget) / goalToDateTarget) * 100)) + "%"
        : "much";
    const lessOrMore = recentValue > goalToDateTarget ? "more" : "less";
    const metricStr = metricFriendlyName(metricName, platform, accountId);
    const isOrAre = metricStr[metricStr.length - 1] === "s" ? "are" : "is";
    const mean = predictedValue || meanValue;
    const growth = recentValue > mean ? "higher" : "lower";
    const channelStr =
        channel == "allTraffic" || channel == "shopify"
            ? ``
            : ` from ${channelFriendlyName(channel, accountId)} traffic`;

    switch (channel) {
        case "budget":
            return subType === "overTotalBudget"
                ? `${channelFriendlyName(metric.name, accountId)} has spent ${toPrettyNumber(
                      recentValue,
                      "cost",
                      accountId,
                  )}, which is more than the budget initially set, ${toPrettyNumber(
                      metric.monthlyTarget,
                      "cost",
                      accountId,
                  )}.`
                : `You have spent ${percent} ${lessOrMore} than your expected media spend-to-date on ${channelFriendlyName(
                      metric.name,
                      accountId,
                  )}.`;
        case "goal":
            return !isIncreasingInMonth
                ? `Your ${metricStr} in ${channelFriendlyName(
                      platform,
                      accountId,
                  )} ${isOrAre} ${percent} ${lessOrMore} than your expected goal.`
                : `Your ${metricStr} in ${channelFriendlyName(
                      platform,
                      accountId,
                  )} ${isOrAre} ${percent} ${lessOrMore} than your expected goal-to-date.`;
        default:
            return `${toCapitalize(metricStr)} ${getHasOrHave(metricStr)} been ${growth} than normal${channelStr}.`;
    }
};

const getZeroStateText = ({ channel, metric, metricName, platform, type, interval, accountId }) => {
    if (type === "enhancedBudgetAnomalies") {
        return `No media spend has been detected in your budget group, ${metric.name}, in the past ${interval} day${
            interval > 1 ? "s" : ""
        }.`;
    }
    const metricStr = metricFriendlyName(metricName, platform, accountId);
    switch (channel) {
        case "budgetZeroState":
            return `There has been no media spend in your ${channelFriendlyName(
                metric.name,
                accountId,
            )} account in the past ${interval} day${interval > 1 ? "s" : ""}.`;
        case "goalZeroState":
            return `Your ${metricStr} in ${channelFriendlyName(
                platform,
                accountId,
            )} has been zero in the past ${interval} day${interval > 1 ? "s" : ""}.`;
        default:
            return `Your ${metricStr} in ${channelFriendlyName(
                platform,
                accountId,
            )} has been zero in the past ${interval} day${interval > 1 ? "s" : ""}.`;
    }
};

const getReminderText = ({ metric, channel, accountId }) => {
    if (channel.includes("enhancedBudget")) {
        return `Your budget group has spent ${toPrettyNumber(
            metric.recentValue,
            "cost",
            accountId,
        )}, which is more than ${metric.budgetReminderPercent}% of the budget initially set, ${toPrettyNumber(
            metric.budget,
            "cost",
            accountId,
        )}.`;
    }
    return `${channelFriendlyName(metric.name, accountId)} has spent ${toPrettyNumber(
        metric.recentValue,
        "cost",
        accountId,
    )}, which is more than ${metric.budgetReminderPercent}% of the budget initially set, ${toPrettyNumber(
        metric.budget,
        "cost",
        accountId,
    )}.`;
};

export const toCapitalize = (str) => (str ? str.replace(/^\w/, (c) => c.toUpperCase()) : null);

const getHasOrHave = (metric) => {
    return metric.substr(metric.length - 1) === "s" ? "have" : "has";
};
