import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    totals: [],
    pages: [],
    queries: [],
    totalsLoading: true,
    pagesLoading: true,
    queriesLoading: true
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.SEARCH_CONSOLE_TOTALS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    totalsLoading: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    totalsLoading: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    totals: action.payload
                })
            }),
        [types.SEARCH_CONSOLE_PAGES]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pagesLoading: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    pagesLoading: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    pages: action.payload
                })
            }),
        [types.SEARCH_CONSOLE_QUERIES]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    queriesLoading: true
                }),
                finish: (prevState) => ({
                    ...prevState,
                    queriesLoading: false
                }),
                success: (prevState) => ({
                    ...prevState,
                    queries: action.payload
                })
            })
    })
);
