import types from "./types";
import { store } from "../../../_redux";
import isEqual from "lodash/fp/isEqual";
import { KpiAPI } from "../../../lib/apiKpi";
import { MorphioAPI } from "../../../lib/api";
import { reloadUserUsage } from "../../../_redux/users";
import moment from "moment";

const fetchCompetitors = ({ startDate, endDate, accountId }) =>
    KpiAPI({
        url: "/seo",
        method: "get",
        query: {
            startDate,
            endDate,
            accountId,
        },
        bypassCache: true,
    }).then(({ data }) => data);

const fetchCompetitorsAnomalies = ({ startDate, endDate, accountId }) =>
    KpiAPI({
        url: "/seo/anomalies",
        method: "get",
        query: {
            startDate,
            endDate,
            accountId,
        },
        timeout: 100000,
    }).then(({ data }) => data);

const removeContentSuggestionData = ({ accountId, suggestionId, date }) =>
    KpiAPI({
        url: "/seo/suggestions",
        method: "delete",
        query: {
            accountId,
            suggestionId,
            date,
        },
        timeout: 100000,
    }).then(({ data }) => data);

const generateCompetitorsData = ({ accountId }) =>
    MorphioAPI({
        url: `/account/${accountId}/competitors`,
        method: "post",
        data: {
            accountId,
        },
        timeout: 100000,
    });

const suggestKeywordsData = ({ competitor, accountId: acId }) => {
    const accountId = acId ? acId : store.getState().sites.selectedSite._id;
    return MorphioAPI({
        url: `/account/${accountId}/competitors/generateKeywords`,
        method: "POST",
        data: {
            competitor,
        },
    }).then(({ data }) => data);
};

const suggestCompetitorsData = ({ keywords, accountId: acId }) => {
    const accountId = acId ? acId : store.getState().sites.selectedSite._id;

    const params = {
        keywords,
    };

    return MorphioAPI({
        url: `/account/${accountId}/competitors/generateCompetitors`,
        method: "POST",
        data: {
            ...params,
        },
    }).then(({ data }) => data);
};

const getLocationOptionsData = () => {
    const accountId = store.getState().sites.selectedSite._id;
    return MorphioAPI({
        url: `/account/${accountId}/competitors/locations`,
        method: "GET",
    }).then(({ data }) => data);
};

const getLocationOptions = () => (dispatch) =>
    dispatch({
        type: types.COMPETITOR_LOCATIONS_DATA,
        promise: getLocationOptionsData(),
    });

const getCompetitorSeo = ({ startDate, endDate, accountId }) => (dispatch) =>
    dispatch({
        type: types.COMPETITOR_SEO_DATA,
        promise: fetchCompetitors({
            accountId,
            endDate: moment().format("YYYY-MM-DD"),
            startDate: moment()
                .subtract(2, "months")
                .format("YYYY-MM-DD"),
        }),
    }).then(async (result) => {
        await dispatch(reloadUserUsage());
        return result;
    });

const getCompetitorAnomalies = ({ startDate, endDate, accountId }) => (dispatch) =>
    dispatch({
        type: types.COMPETITOR_ANOMALIES_DATA,
        promise: fetchCompetitorsAnomalies({
            accountId,
            endDate: moment().format("YYYY-MM-DD"),
            startDate: moment()
                .subtract(6, "months")
                .format("YYYY-MM-DD"),
        }),
    });

const removeContentSuggestion = ({ accountId, suggestionId, date }) => (dispatch) =>
    dispatch({
        type: types.CONTENT_SUGGESTIONS_REMOVE,
        promise: removeContentSuggestionData({
            accountId,
            suggestionId,
            date,
        }),
    });

const updateComments = ({ comments = {}, accountId, id, date } = {}) => (dispatch, getState) => {
    const { competitors: { anomalies: { [id]: { comments: currentComments = {} } = {} } } = {} } = getState();

    if (isEqual(currentComments, comments)) return;

    return dispatch({
        type: types.UPDATE_COMPETITOR_COMMENTS,
        comments,
        accountId,
        id,
        date,
    });
};

const deleteComment = ({ competitorId, accountId, commentId, who, date }) => (dispatch) =>
    dispatch({
        type: types.COMPETITOR_DELETE_COMMENT,
        data: {
            competitorId,
            accountId,
            commentId,
            who,
            date,
        },
        meta: {
            sync: true,
        },
    });

const editComment = ({ message, commentId, competitorId, accountId, mentions, who, date, plainTextMessage }) => (
    dispatch,
) =>
    dispatch({
        type: types.COMPETITOR_EDIT_COMMENT,
        data: {
            message,
            who,
            date,
            competitorId,
            accountId,
            commentId,
            mentions,
            plainTextMessage,
        },
        meta: {
            sync: true,
        },
    });

const commentCompetitor = ({
    message,
    competitorId,
    accountId,
    who,
    date,
    user,
    userId,
    mentions,
    plainTextMessage,
}) => (dispatch) =>
    dispatch({
        type: types.COMPETITOR_COMMENT,
        data: {
            mentions,
            plainTextMessage,
            message,
            competitorId,
            who,
            date,
            accountId,
            user,
            userId,
        },
        meta: {
            sync: true,
        },
    });

const generateCompetitors = ({ accountId }) => (dispatch) =>
    dispatch({
        type: types.GENERATE_COMPETITORS,
        promise: generateCompetitorsData({
            accountId,
        }),
    });

const suggestKeywords = ({ competitor, accountId }) => (dispatch) =>
    dispatch({
        type: types.ACCOUNT_SUGGEST_KEYWORDS,
        promise: suggestKeywordsData({ competitor, accountId }),
    });

const suggestCompetitors = ({ accountId, keywords } = {}) => (dispatch) =>
    dispatch({
        type: types.ACCOUNT_SUGGEST_COMPETITORS,
        promise: suggestCompetitorsData({ accountId, keywords }),
    });

export default {
    suggestKeywordsData,
    suggestCompetitorsData,
    getCompetitorSeo,
    getCompetitorAnomalies,
    removeContentSuggestion,
    updateComments,
    deleteComment,
    editComment,
    commentCompetitor,
    generateCompetitors,
    suggestKeywords,
    suggestCompetitors,
    getLocationOptions,
};
