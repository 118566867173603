import React from "react";
import { ReactComponent as IconPlatform } from "assets/images/icons/Icon-Platform.svg";
import FilterIcon from "assets/images/icons/JSX/Icon-Filter";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { getPlatformUI } from "features/ControlPanel/LayoutSettings";
import style from "./style.module.scss";
import {
    getCheckboxOptions,
    getMonthsOptions,
    getShowOnlyOptions,
    onChangeCheckbox,
} from "./helpers";
import { ReactComponent as IconShowOnly } from "assets/images/icons/Icon-Show.svg";
import IconMonths from "assets/images/icons/JSX/Icon-Compare.jsx";

const MainFilter = ({
    platformFilter,
    setPlatformFilter,
    showOnly,
    setShowOnly,
    monthFilter,
    setMonthFilter,
    months,
    platforms,
    metric,
    secondaryMetric,
}) => {
    const monthsOptions = getMonthsOptions(months);
    const showOnlyOptions = getShowOnlyOptions({ metric, secondaryMetric });

    const showOnlyFilterOptions = getCheckboxOptions({
        optionValue: showOnly,
        onChangeCheckbox,
        options: showOnlyOptions,
        setOptionsValue: setShowOnly,
    });

    const monthsFilterOptions = getCheckboxOptions({
        optionValue: monthFilter,
        onChangeCheckbox,
        options: monthsOptions,
        setOptionsValue: setMonthFilter,
    });

    return (
        <div className={style.mainFilterContainer}>
            <div className={style.filterIcon}>
                <FilterIcon />
                &nbsp;Filter
            </div>
            <div className={style.filterDropDown}>
                <FilterDropdown
                    name='Platform'
                    onClickHandler={(props) => {
                        setPlatformFilter(props);
                    }}
                    itemSelected={platformFilter}
                    icon={<IconPlatform />}
                    options={Object.keys(platforms).map((id) => {
                        return {
                            label: getPlatformUI(id),
                            value: id,
                        };
                    })}
                />
            </div>
            <div className={style.filterDropDown}>
                <FilterDropdown
                    name='Months'
                    className={style.filterDropdown}
                    itemSelected={{
                        value: monthFilter,
                        label: monthFilter.map((value) => monthsOptions[value]).join(" + "),
                    }}
                    onClearAll={() => {
                        setMonthFilter([]);
                    }}
                    icon={<IconMonths />}
                    options={monthsFilterOptions}
                />
            </div>
            <div className={style.filterDropDown}>
                <FilterDropdown
                    name='Show only'
                    className={style.filterDropdown}
                    itemSelected={{
                        value: showOnly,
                        label: showOnly.map((value) => showOnlyOptions[value]).join(" + "),
                    }}
                    onClearAll={() => {
                        setShowOnly([]);
                    }}
                    icon={<IconShowOnly />}
                    options={showOnlyFilterOptions}
                />
            </div>
        </div>
    );
};

export default MainFilter;
