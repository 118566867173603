import { KpiAPI } from "../apiKpi";

function getReport({ accountId, dateRange }) {
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/googleBusinessProfile`,
                query: {
                    accountId,
                    dateRange: JSON.stringify([{ start, end }]),
                },
            }),
        ),
    )
        .then(([result = {}, prevResult = {}]) => {
            return {
                current: result.data || [],
                previous: prevResult.data || [],
            };
        })
        .catch((error) => {
            console.log("search console", error);
            return [];
        });
}

export default {
    getReport,
};
