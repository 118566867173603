import { handle } from "redux-pack";
import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import merge from "lodash/fp/merge";
import set from "lodash/fp/set";
import types from "./types";
import { convert as convertRedeuce } from "lodash/fp/reduce";

const reduce = convertRedeuce({
    cap: false,
});

function toObj(array) {
    if (!array) return {};

    return array.reduce((obj, next) => {
        return {
            ...obj,
            [next._id]: next,
        };
    }, {});
}

const defaultState = {
    monthlyAnomalies: [],
    yesterdayAnomalies: {},
    pending: null,
    error: null,
    notePending: null,
    noteError: null,
    anomalies: {},
    campaignChanges: [],
    additionalFilters: {
        withComments: false,
        withInsights: false,
    },
    dismissTypeFilters: { types: {} },
    dismissMetricFilters: { metrics: {} },
    datesChanging: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.UPDATE_ANOMALY_COMMENTS](state, action) {
            return {
                ...state,
                anomalies: set(
                    `${action.accountId}.${action.date}.${action.id}.comments`,
                    action.comments,
                )(state.anomalies),
            };
        },
        [types.TOGGLE_MARKETING_ANOMALY_VISIBILITY]: (state, action) => {
            const anomalyId = action.payload.anomalyId;
            const accountId = action.payload.accountId;
            const [date, id, type] = anomalyId.split("_");

            return merge(state, {
                anomalies: {
                    [accountId]: {
                        [date]: {
                            [anomalyId]: {
                                ...state.anomalies[accountId][date][anomalyId],
                                dismissed: {
                                    value: !state.anomalies[accountId][date][anomalyId]?.dismissed?.value,
                                    userId: action.payload.userId,
                                    updated: new Date().toISOString(),
                                },
                            },
                        },
                    },
                },
            });
        },
        [types.READ_ANOMALY]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    pending: true,
                }),
                success: (prevState) => {
                    const date = action.payload.data.date;
                    const accountId = action.meta.accountId;
                    const type = action.payload.data.type;
                    const id = action.payload.data.id;
                    const anomalyId = `${date}_${id}_${type}`;

                    return merge(prevState, {
                        anomalies: {
                            [accountId]: {
                                [date]: {
                                    [anomalyId]: {
                                        ...prevState.anomalies[accountId][date][anomalyId],
                                        read: action.payload.data.read,
                                    },
                                },
                            },
                        },
                    });
                },
                finish: (prevState) => ({
                    ...prevState,
                    pending: false,
                }),
            }),
        [types.ANOMALIES_FETCH]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoading: true,
                }),
                success: (prevState) =>
                    merge(prevState, {
                        anomalies: {
                            [action.meta.accountId]: reduce(
                                (cache, { weeklyAnomalies: weeklyAnomalies = [] }, date) => ({
                                    ...cache,
                                    [date]: {
                                        ...toObj(weeklyAnomalies),
                                    },
                                }),
                                {},
                            )(action.payload),
                        },
                    }),
                finish: (prevState) => ({
                    ...prevState,
                    isLoading: false,
                }),
            }),
        [types.GET_YESTERDAY_ANOMALIES_FULFILLED](state, action) {
            return {
                ...state,
                yesterdayAnomalies: action.payload,
            };
        },
        [types.GET_YESTERDAY_ANOMALIES_REJECTED](state, action) {
            return {
                ...state,
                error: action.error,
            };
        },
        [types.GET_YESTERDAY_ANOMALIES_PENDING](state, action) {
            return {
                ...state,
                pending: action.pending,
            };
        },
        [types.UPDATE_ANOMALY_NOTE_FULFILLED](state, action) {
            return {
                ...state,
                monthlyAnomalies: action.payload,
            };
        },
        [types.UPDATE_ANOMALY_NOTE_REJECTED](state, action) {
            return {
                ...state,
                noteError: action.error,
            };
        },
        [types.UPDATE_ANOMALY_NOTE_PENDING](state, action) {
            return {
                ...state,
                notePending: action.pending,
            };
        },
        [types.INVESTIGATE_ANOMALY]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    campaignChanges: [],
                }),
                success: (prevState) =>
                    merge(prevState, {
                        ...prevState,
                        campaignChanges: action.payload,
                    }),
            }),
        [types.INVESTIGATE_ANOMALY](state, action) {
            return {
                ...state,
                campaignChanges: action.data,
            };
        },
        /**
         * SET ADDITIONAL ANOMALY FILTERS
         */
        [types.SET_ADDITIONAL_FILTERS](state, action) {
            return {
                ...state,
                additionalFilters: action.meta.settings,
            };
        },
        /**
         * SET DISMISS ANOMALY FILTERS
         */
        [types.SET_DISMISS_TYPE_FILTERS](state, action) {
            return {
                ...state,
                dismissTypeFilters: action.meta.typeFilters,
            };
        },
        [types.SET_DISMISS_METRIC_FILTERS](state, action) {
            return {
                ...state,
                dismissMetricFilters: action.meta.metricFilters,
            };
        },
        [types.TOGGLE_DATES_CHANGING](state, action) {
            return {
                ...state,
                datesChanging: action.datesChanging,
            };
        },
    }),
);
