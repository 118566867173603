import React from "react";
import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings-Small.svg";
import Button from "components/Button";
import MultiSelector from "components/MultiSelector";
import RenderInput from "components/RenderInput";
import { SectionHeading } from "components/SectionHeading";
import ExtraLabel from "features/AlertSettings/ExtraLabel";
import { Field } from "redux-form";
import AddBudgetTable from "./AddBudgetTable";
import BudgetThresholdTable from "./BudgetThresholdTable";
import { BUDGET_TYPE_OPTIONS, METRIC_OPTIONS } from "./helpers";
import style from "./style.module.scss";

const BudgetConfigure = ({
    budgetType,
    onBack,
    handleSubmit,
    months,
    availablePlatfoms,
    platforms,
    numberOfMonths,
    metric,
    pristine,
    initMetricsAverages,
}) => {
    return (
        <div className={style.configureBudgetContainer}>
            <div className={style.configureBudgetHeader}>
                <SectionHeading
                    smallHeader
                    className={style.headerDesc}
                    icon={() => (
                        <div className={style.settingsIcon}>
                            <SettingsIcon />
                        </div>
                    )}
                    iconBackgroundColor={style.iconBackgroundColor}
                    title="Configure Budget Planner"
                    subtitle="Configure Budget Planner to generate a budget plan for your account."
                />
            </div>
            <div className={style.configureBudgetContent}>
                <div className={style.budgetSetup}>
                    <div className={style.mainTitle}>Budget Setup</div>
                    <Field
                        component={MultiSelector}
                        className={style.selectedInput}
                        label={
                            <ExtraLabel
                                subLabel="Select if you optimize for ROAS or CPL"
                                label="Optimize For"
                                haveMargin
                            />
                        }
                        selectValue
                        name="metric"
                        options={METRIC_OPTIONS}
                    />
                    <Field
                        component={RenderInput}
                        className={style.formInput}
                        label={
                            <ExtraLabel
                                subLabel="Indicate how many months the budget is for"
                                label="Budget Timeframe"
                                haveMargin
                            />
                        }
                        name="numberOfMonths"
                        type="number"
                        filledPrefix
                        endPrefix
                        prefix="Months"
                    />
                    <Field
                        component={MultiSelector}
                        className={style.selectedInput}
                        label={
                            <ExtraLabel
                                subLabel="Input as a total or prescribe a monthly amount."
                                label="Budget Availability"
                                haveMargin
                            />
                        }
                        selectValue
                        placeholder="Select Title"
                        name="budgetType"
                        options={BUDGET_TYPE_OPTIONS}
                    />
                    <AddBudgetTable budgetType={budgetType} months={months} />
                </div>
                <BudgetThresholdTable
                    availablePlatfoms={availablePlatfoms}
                    platforms={platforms}
                    initMetricsAverages={initMetricsAverages}
                />
            </div>
            <div className={style.configureBudgetFooter}>
                <Button secondary medium onClick={onBack}>
                    Cancel
                </Button>
                <Button
                    lightBlue
                    medium
                    onClick={() => {
                        handleSubmit();
                        onBack();
                    }}
                    disabled={!numberOfMonths || !metric || pristine}
                >
                    Generate Plan
                </Button>
            </div>
        </div>
    );
};

export default BudgetConfigure;
