import React from "react";

export default () => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="📂-Account"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="9.X.1-Account-(Edit-Account-Name)"
                transform="translate(-1310.000000, -46.000000)"
                fill="#FFFFFF"
                fillRule="nonzero"
            >
                <g id="Overlay">
                    <g
                        id="Header"
                        transform="translate(1040.000000, 16.000000)"
                    >
                        <path
                            d="M282.436494,33.3377423 L286.504946,37.4260813 L276.20657,47.7748923 L272.140424,43.6865533 L282.436494,33.3377423 Z M289.592115,32.3517287 L287.777742,30.5284699 C287.076549,29.8238434 285.937957,29.8238434 285.234382,30.5284699 L283.496394,32.2749695 L287.564847,36.3633465 L289.592115,34.3261499 C290.135962,33.7796017 290.135962,32.898239 289.592115,32.3517287 Z M270.011322,49.4330428 C269.937281,49.7678953 270.238132,50.0679401 270.571391,49.986507 L275.104998,48.8818966 L271.038853,44.7935577 L270.011322,49.4330428 Z"
                            id="Icon-Pencil"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
