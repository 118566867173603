import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

export const defaultState = {
    templates: [],
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.FETCH_TEMPLATES]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    isLoadingTemplates: true,
                }),
                success: (prevState) => {
                    return {
                        ...prevState,
                        templates: action.payload,
                        isLoadingTemplates: false,
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                    loadingTemplateError: true,
                    isLoadingTemplates: false,
                }),
            });
        },
        [types.DELETE_TEMPLATE]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const deletedId = action.payload;
                    return {
                        ...prevState,
                        templates: prevState.templates.filter(
                            (template) => template.id !== deletedId
                        ),
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
        [types.CREATE_TEMPLATE]: (state, action) => {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => {
                    const newTemplate = action.payload;
                    return {
                        ...prevState,
                        templates: [newTemplate, ...prevState.templates],
                    };
                },
                failure: (prevState) => ({
                    ...prevState,
                }),
            });
        },
    })
);
