import React from "react";

export default ({ styles }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" {...styles}>
        <path
            fill="#0081fb"
            d="M47 29.36l-2.193 1.663L42.62 29.5c0-.16 0-.33-.01-.5 0-.16 0-.33-.01-.5-.14-3.94-1.14-8.16-3.14-11.25-1.54-2.37-3.51-3.5-5.71-3.5-2.31 0-4.19 1.38-6.27 4.38-.06.09-.13.18-.19.28-.04.05-.07.1-.11.16-.1.15-.2.3-.3.46-.9 1.4-1.84 3.03-2.86 4.83-.09.17-.19.34-.28.51-.03.04-.06.09-.08.13l-.21.37-1.24 2.19c-2.91 5.15-3.65 6.33-5.1 8.26-2.55 3.39-4.73 4.68-7.6 4.68-3.4 0-5.56-1.47-6.89-3.69C1.53 34.51 1 32.14 1 29.44l4.97.17c0 1.76.38 3.1.89 3.92C7.52 34.59 8.49 35 9.5 35c1.29 0 2.49-.27 4.77-3.43 1.83-2.53 3.99-6.07 5.44-8.3l1.37-2.09.29-.46.3-.45.5-.77c.76-1.16 1.58-2.39 2.46-3.57.1-.14.2-.28.31-.42.1-.14.21-.28.31-.41.9-1.15 1.85-2.22 2.87-3.1 1.85-1.61 3.84-2.5 5.85-2.5 3.37 0 6.58 1.95 9.04 5.61 2.51 3.74 3.82 8.4 3.97 13.25.01.16.01.33.01.5.01.17.01.33.01.5z"
        ></path>
        <linearGradient
            id="wSMw7pqi7WIWHewz2_TZXa"
            x1="42.304"
            x2="13.533"
            y1="24.75"
            y2="24.75"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#0081fb"></stop>
            <stop offset="0.995" stopColor="#0064e1"></stop>
        </linearGradient>
        <path
            fill="url(#wSMw7pqi7WIWHewz2_TZXa)"
            d="M4.918 15.456C7.195 11.951 10.483 9.5 14.253 9.5c2.184 0 4.354.645 6.621 2.493 2.479 2.02 5.122 5.346 8.419 10.828l1.182 1.967c2.854 4.746 4.477 7.187 5.428 8.339C37.125 34.606 37.888 35 39 35c2.82 0 3.617-2.54 3.617-5.501L47 29.362c0 3.095-.611 5.369-1.651 7.165C44.345 38.264 42.387 40 39.093 40c-2.048 0-3.862-.444-5.868-2.333-1.542-1.45-3.345-4.026-4.732-6.341l-4.126-6.879c-2.07-3.452-3.969-6.027-5.068-7.192-1.182-1.254-2.642-2.754-5.067-2.754-1.963 0-3.689 1.362-5.084 3.465l-4.23-2.51z"
        ></path>
        <linearGradient
            id="wSMw7pqi7WIWHewz2_TZXb"
            x1="7.635"
            x2="7.635"
            y1="32.87"
            y2="13.012"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#0081fb"></stop>
            <stop offset="0.995" stopColor="#0064e1"></stop>
        </linearGradient>
        <path
            fill="url(#wSMw7pqi7WIWHewz2_TZXb)"
            d="M14.25 14.5c-1.959 0-3.683 1.362-5.075 3.465C7.206 20.937 6 25.363 6 29.614c0 1.753-.003 3.072.5 3.886l-3.84 2.813C1.574 34.507 1 32.2 1 29.5c0-4.91 1.355-10.091 3.918-14.044C7.192 11.951 10.507 9.5 14.27 9.5l-.02 5z"
        ></path>
        <path
            d="M21.67 20.27l-.3.45-.29.46a68.65 68.65 0 012.37 3.69l.21-.37c.02-.04.05-.09.08-.13.09-.17.19-.34.28-.51-.83-1.36-1.63-2.57-2.35-3.59zm3.27-4.76c-.11.14-.21.28-.31.42.73.91 1.47 1.94 2.25 3.1.1-.16.2-.31.3-.46.04-.06.07-.11.11-.16.06-.1.13-.19.19-.28a44.36 44.36 0 00-2.23-3.03c-.1.13-.21.27-.31.41z"
            opacity="0.05"
        ></path>
        <path
            d="M21.67 20.27l-.3.45a72.57 72.57 0 012.37 3.65c.09-.17.19-.34.28-.51-.83-1.36-1.63-2.57-2.35-3.59zm2.96-4.34c.73.91 1.47 1.94 2.25 3.1.1-.16.2-.31.3-.46-.77-1.14-1.52-2.16-2.24-3.06-.11.14-.21.28-.31.42z"
            opacity="0.07"
        ></path>
    </svg>
);
