import {KpiAPI} from "../apiKpi";
import {formatDateToString} from "lib/dateUtils";

function getAccounts({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/pinterest/accounts`,
        query: {
            userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "pinterest",
                integrations: {
                    pinterest: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {}
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getCampaigns({ userId, connectionId, advertiserId }) {
    return KpiAPI({
        method: "GET",
        url: `/pinterest/campaigns`,
        query: {
            userId,
            connectionId,
            adAccountId: advertiserId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                advertiserId,
                campaigns: {
                    pinterest: data,
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getCampaignReport({ dateRange, accountId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: formatDateToString(start),
                    end: formatDateToString(end),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/pinterest/reports",
        query: {
            accountId,
            dateRange,
            startDate: formatDateToString(dateRange.start),
            endDate: formatDateToString(dateRange.end),
        },
    }).then(({ data = [] } = {}) => {
        return data;
    });
}

export default {
    getAccounts,
    getCampaigns,
    getCampaignReport,
};
