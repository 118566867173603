import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import styleMobile from "./styleMobile.module.scss";
import TagFilter from "./TagFilter";
import Search from "./Search";
import AdditionalMenu from "./AdditionalMenu";
import { isEmpty } from "lodash";
import ToggleMulti from "components/ToggleMulti";
import { channelFriendlyName } from "lib/utility";
import PlatformIcon from "components/Icon/PlatformIcon";
import platformInfo from "lib/platformInfo";
import MonthSelector from "features/Dashboard/MonthSelector";
import { getOptions } from "./helpers";
import MonthPicker from "components/DatePicker/MonthPicker";
import CompareToMonthPicker from "components/DatePicker/CompareToMonthPicker";
import moment from "moment";
import ContainerTabs from "components/Tabs/ContainerTabs";
import TabHeaderWithIcon from "components/Tabs/TabHeaderWithIcon";
import { ReactComponent as IconPlatform } from "assets/images/icons/Icon-Platform.svg";
import FilterIcon from "assets/images/icons/JSX/Icon-Filter";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import classNames from "classnames";
import CloseIcon from "assets/images/icons/JSX/Icon-Close-Small";
import { clearMetricsData, clearAccountData } from "features/ControlPanel/ducks/actions.js";
import { useDispatch } from "react-redux";
import SortToggle from "./SortToggle";

export const getPlatformUI = (platform) => {
    if (platform === "all") {
        return "All Platforms";
    }
    return (
        <div className={style.platform}>
            <PlatformIcon platform={platform} backgroundColor={"transparent"} className={style.platformIcon} />
            <p>{channelFriendlyName(platform)}</p>
        </div>
    );
};

const LayoutSettings = ({
    viewType,
    toggleViewType,
    month,
    toggleMonth,
    isMobile,
    metricDates,
    changeMetricDates,
    userId,
    views,
    tags,
    platforms,
    contains,
    updateOverviewUi,
    userRole,
}) => {
    const [selectedFilterPlatform, setSelectedFilterPlatform] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        const selectedPlatforms = Object.keys(platforms).filter((key) => platforms[key]) || [];
        const isAllSelected = selectedPlatforms.length === 1 && selectedPlatforms.includes("all");

        if (isEmpty(selectedPlatforms) || isAllSelected) {
            setSelectedFilterPlatform({});
        } else {
            setSelectedFilterPlatform({
                label: getPlatformUI(selectedPlatforms.find((platform) => platform !== "all")),
            });
        }
    }, [platforms]);

    const allPlatforms = Object.values(views).reduce(
        (cache, { integrations = {} } = {}) => ({
            ...cache,
            ...integrations,
        }),
        {},
    );
    const selectedPlatforms = [...Object.keys(platformInfo), "slack"].filter((p) => allPlatforms[p]);
    const clearFilterActive = !isEmpty(tags) || !isEmpty(selectedFilterPlatform);

    if (isMobile) {
        return (
            <div className={styleMobile.layoutSettings}>
                <ToggleMulti
                    className={styleMobile.toggleView}
                    options={getOptions({ isMobile, toggleViewType, userRole })}
                    active={viewType}
                />
                <div className={styleMobile.sectionWrapper}>
                    <Search className={styleMobile.settingsItem} />
                    <MonthSelector
                        className={styleMobile.monthSelector}
                        date={month}
                        changeDate={(props) => {
                            //clear data
                            dispatch(clearAccountData());

                            toggleMonth(props);
                        }}
                    />
                </div>
            </div>
        );
    }

    const dispatchUpdateOverviewUi = ({ platformKey, listTags, replace = false }) => {
        updateOverviewUi({
            ui: {
                tags: listTags || tags,
                contains,
                platforms: {
                    ...selectedPlatforms.reduce(
                        (cache, key) => ({
                            ...cache,
                            [key]: false,
                        }),
                        {},
                    ),
                    [platformKey]: true,
                },
            },
            userId,
            replace,
        });
    };

    const clearAllFilter = () => {
        dispatchUpdateOverviewUi({ platformKey: "all", listTags: [], replace: true });
    };

    return (
        <div className={style.layoutSettings}>
            <div className={style.tabsSectionContainer}>
                <ContainerTabs
                    initialTab={viewType}
                    customTabType={viewType}
                    tabContainerClass={style.tabContentContainer}
                    containerClass={style.tabContainer}
                    className={style.tabNavContainer}
                    tabs={getOptions({ isMobile, toggleViewType, userRole }).map(({ label, id, tip, icon }) => ({
                        name: <TabHeaderWithIcon icon={icon} title={label} color="transparent" />,
                        url: id,
                    }))}
                    onChange={(tabtype, changeType) => {
                        toggleViewType(tabtype);
                        return changeType(tabtype);
                    }}
                />
                <div className={style.settingsContainer}>
                    {["budget"].includes(viewType) && (
                        <MonthSelector
                            date={month}
                            changeDate={(props) => {
                                //clear data
                                dispatch(clearAccountData());

                                toggleMonth(props);
                            }}
                        />
                    )}
                    {["metrics"].includes(viewType) && (
                        <>
                            <MonthPicker
                                disabledDays={{
                                    after: moment()
                                        .subtract(1, "day")
                                        .toDate(),
                                }}
                                presets={["month", "week", "day", "this month", "last month"]}
                                maxDaysSelected={365}
                                dateRange={{
                                    start: moment(metricDates.startDate, "YYYY-MM-DD").toDate(),
                                    end: moment(metricDates.endDate, "YYYY-MM-DD").toDate(),
                                    compareToStart: metricDates.compareToStart
                                        ? moment(metricDates.compareToStart, "YYYY-MM-DD").toDate()
                                        : null,
                                    compareToEnd: metricDates.compareToEnd
                                        ? moment(metricDates.compareToEnd, "YYYY-MM-DD").toDate()
                                        : null,
                                }}
                                onDateSelect={({
                                    dateRange: { start = moment().subtract(1, "month"), end = moment() } = {},
                                }) => {
                                    // clear data
                                    dispatch(clearMetricsData());

                                    const daysBetween = moment(end).diff(moment(start), "days") + 1;
                                    changeMetricDates({
                                        startDate: moment(start).format("YYYY-MM-DD"),
                                        endDate: moment(end).format("YYYY-MM-DD"),
                                        compareToStart: moment(start)
                                            .subtract(daysBetween, "days")
                                            .format("YYYY-MM-DD"),
                                        compareToEnd: moment(start)
                                            .add(-1, "days")
                                            .format("YYYY-MM-DD"),
                                    });
                                }}
                            />
                            <CompareToMonthPicker
                                className={style.datePicker}
                                maxDaysSelected={365}
                                additionalOptions={[
                                    {
                                        start: null,
                                        end: null,
                                        label: "No Comparison",
                                        onClick: () => {
                                            changeMetricDates({
                                                startDate: moment(metricDates.startDate, "YYYY-MM-DD").toDate(),
                                                endDate: moment(metricDates.endDate, "YYYY-MM-DD").toDate(),
                                                compareToStart: null,
                                                compareToEnd: null,
                                            });
                                        },
                                        deactive: false,
                                        id: "noComparison",
                                    },
                                ]}
                                dateRange={{
                                    start: moment(metricDates.startDate, "YYYY-MM-DD").toDate(),
                                    end: moment(metricDates.endDate, "YYYY-MM-DD").toDate(),
                                    compareToStart: metricDates.compareToStart
                                        ? moment(metricDates.compareToStart, "YYYY-MM-DD").toDate()
                                        : null,
                                    compareToEnd: metricDates.compareToEnd
                                        ? moment(metricDates.compareToEnd, "YYYY-MM-DD").toDate()
                                        : null,
                                }}
                                onDateSelect={({
                                    dateRange: {
                                        start = moment().subtract(1, "month"),
                                        end = moment(),
                                        compareToStart,
                                        compareToEnd,
                                    } = {},
                                }) => {
                                    // clear data
                                    dispatch(clearMetricsData());

                                    changeMetricDates({
                                        startDate: moment(start).format("YYYY-MM-DD"),
                                        endDate: moment(end).format("YYYY-MM-DD"),
                                        compareToStart: moment(compareToStart).format("YYYY-MM-DD"),
                                        compareToEnd: moment(compareToEnd).format("YYYY-MM-DD"),
                                    });
                                }}
                            />
                        </>
                    )}
                    {["metrics", "budget"].includes(viewType) && <AdditionalMenu viewType={viewType} month={month} />}
                </div>
            </div>

            <div className={style.mainTableFilter}>
                <div className={style.filterIcon}>
                    <FilterIcon />
                    &nbsp;Filter
                </div>
                <FilterDropdown
                    name="Platform"
                    className={style.filterDropdown}
                    allowSearch
                    onClickHandler={(props) => {
                        setSelectedFilterPlatform(props);
                        dispatchUpdateOverviewUi({ platformKey: props.value || "all" });
                    }}
                    itemSelected={selectedFilterPlatform}
                    icon={<IconPlatform />}
                    options={[...selectedPlatforms].map((id) => {
                        return {
                            label: getPlatformUI(id),
                            value: id,
                            searchText: channelFriendlyName(id),
                        };
                    })}
                />
                <TagFilter className={style.settingsTagFilter} contains={contains} tags={tags} />
                {clearFilterActive && (
                    <div className={style.clearFilter} onClick={() => clearFilterActive && clearAllFilter()}>
                        <CloseIcon />
                        <span>Clear All</span>
                    </div>
                )}
                <SortToggle />
                <Search className={style.settingsItem} />
            </div>
        </div>
    );
};
export default LayoutSettings;
