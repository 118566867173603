import React, { Suspense, useEffect } from "react";
import Loading from "components/Loading";
import NavigationToggle from "../features/Navigation/NavigationToggle/container";
import { Route, Switch } from "react-router";
import { lifecycle, compose, pure } from "recompose";
import RequestStates from "./RequestStates";
import PlatformNotificationBar from "../features/NotificationBar/container";
import qs from "querystring";
import PlanOptions from "features/PlanOptions/container";
import withErrorBoundary from "components/ErrorBoundary";
import { useDispatch } from "react-redux";
import { changeAccount } from "_redux/sites";
import { mixpanel } from "components/Mixpanel";
import { PromptProvider } from "components/Prompt/ShortAnnouncement/PromptContext";

import "react-select/scss/default.scss";
import "react-month-picker/scss/pack-month-picker.scss";
import "react-dates/lib/css/_datepicker.css";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "../assets/styles/daypickerDefault.scss";
import "../assets/styles/typography.scss";
import "../assets/styles/body.scss";
import "../assets/styles/select.scss";
import "../assets/styles/daypicker.scss";
import "../assets/styles/monthPicker.scss";
import "../assets/styles/daterangepicker.scss";
import "../assets/styles/daterangepickerCompare.scss";
import "../assets/styles/daySelectInput.scss";
import "../assets/styles/selectInput.scss";
import "../assets/styles/tooltip.scss";
import "../assets/styles/close.scss";
import "../assets/styles/recharts.scss";
import "../assets/styles/table.scss";
import "../assets/styles/slider.scss";
import "../assets/styles/intercom.scss";
import "../assets/styles/backdrop.scss";

// Preloaded data into app
const preloadDataCycle = lifecycle({
    componentWillMount() {
        if (window.location.search && window.location.pathname === "/dashboard/account/connect") {
            const params = qs.parse(window.location.search.slice(1));

            if (!params.hasOwnProperty("state") || !params.state) return;
            window.opener.postMessage({ payload: params }, window.opener.location);
        }
    },
    componentDidMount() {
        const { preloadData, hasSites } = this.props;

        if (!hasSites) {
            preloadData();
        }
    },
});

/**
 * Changes to the appropriate account based on URL and clears data
 * @param {func} dispatch
 * @param {string | false} urlAccountId
 * @param {string} stateAccountId
 * @returns {void}
 */

function loadAccount(dispatch, urlAccountId, stateAccountId) {
    //If no account id then do nothing
    if (!urlAccountId) {
        return;
    }

    //If going to an account id, check if accountId is already loaded in
    //If it is the same just keep data
    if (stateAccountId === urlAccountId) {
        return;
    }

    //diff account - load site + clear data
    mixpanel.register({
        "Account ID": urlAccountId,
    });
    dispatch({ type: "CLEAR_DATA" });
    dispatch(changeAccount(urlAccountId));
}

const enhance = compose(withErrorBoundary(), preloadDataCycle, RequestStates, pure);

export default enhance(({ routes, path, urlAccountId, stateAccountId }) => {
    const dispatch = useDispatch();

    // Load account
    useEffect(() => {
        loadAccount(dispatch, urlAccountId, stateAccountId);
    }, [urlAccountId, stateAccountId, dispatch]);

    return (
        <>
            <PlatformNotificationBar key="notification" />
            <PromptProvider>
                <PlanOptions />
                <NavigationToggle key="navigation" path={path} routes={routes}>
                    <Switch>
                        {routes.map(({ sidePanel: SidePanel, ...rest }) => (
                            <Route
                                key={rest.path}
                                exact={rest.exact ? rest.exact : false}
                                path={rest.path}
                                render={(props) => <SidePanel {...props} />}
                            />
                        ))}
                    </Switch>
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            {routes.map(({ component: Component, ...rest }) => (
                                <Route
                                    key={rest.path}
                                    exact={rest.exact ? rest.exact : false}
                                    path={rest.path}
                                    render={(props) => <Component {...props} />}
                                />
                            ))}
                        </Switch>
                    </Suspense>
                </NavigationToggle>
            </PromptProvider>
        </>
    );
});
