import React, { useState } from "react";
import style from "./style.module.scss";
import { sendToSlackWebhook } from "lib/slack";

import Button from "components/Button";
import TextArea from "components/RenderInput/TextArea";

const PopUp = ({ handleClose, togglePopUp, toggleDisplay, userId, userEmail, userName }) => {
    const [message, changeMessage] = useState("");
    const [submitting, toggleSubmitting] = useState(false);

    return (
        <div className={style.popUp}>
            <div className={style.top}>
                <h2>Didn't see an option that meets your needs?</h2>
                <p>
                    Schedule a meeting with one of our product specialists to discuss options or fill in the form below
                    to let us know what you're looking for.
                </p>
                <Button
                    onClick={() => {
                        togglePopUp(false);
                        window.open("https://meetings.hubspot.com/arjun64", "_blank");
                        changeMessage("");
                    }}
                >
                    Schedule a Meeting
                </Button>
            </div>
            <div className={style.bottom}>
                <TextArea
                    input={{ value: message, onChange: (e) => changeMessage(e.target.value) }}
                    minRows={3}
                    placeholder="I'm looking for..."
                />
                <div className={style.buttons}>
                    <Button
                        disabled={!message || submitting}
                        onClick={async () => {
                            toggleSubmitting(true);
                            await sendToSlackWebhook({
                                url: "https://hooks.slack.com/services/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",
                                text: `*Name:* ${userName} \n*User ID:* ${userId} \n*Email:* ${userEmail} \n>${message}`,
                            });
                            togglePopUp(false);
                            handleClose();
                            toggleSubmitting(false);
                            changeMessage("");
                        }}
                        small
                    >
                        {submitting ? "Sending..." : "Submit"}
                    </Button>
                    <Button
                        onClick={() => {
                            togglePopUp(false);
                            handleClose();
                            changeMessage("");
                        }}
                        small
                        hollow
                    >
                        No, I'm good
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PopUp;
