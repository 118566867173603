const GET_INSIGHTS = "GET_INSIGHTS";
const TOGGLE_INSIGHT_VISIBILITY = "TOGGLE_INSIGHT_VISIBILITY";
const DISMISS_INSIGHT_REQUEST = "DISMISS_INSIGHT_REQUEST";
const UPDATE_INSIGHTS_FILTER = "UPDATE_INSIGHTS_FILTER";
const UPDATE_INSIGHTS_FILTERS = "UPDATE_INSIGHTS_FILTERS";
const UPDATE_DISMISSED_INSIGHTS_FILTER = "UPDATE_DISMISSED_INSIGHTS_FILTER";

export default {
    GET_INSIGHTS,
    TOGGLE_INSIGHT_VISIBILITY,
    DISMISS_INSIGHT_REQUEST,
    UPDATE_INSIGHTS_FILTER,
    UPDATE_INSIGHTS_FILTERS,
    UPDATE_DISMISSED_INSIGHTS_FILTER,
};
