import React from "react";
import styles from "./style.module.scss";
import Tooltip from "components/Tooltip";

const isCheckbox = (type) => type === "checkbox";

const determineContainer = (submitFailed, error, type) => {
    if (submitFailed && error) {
        return isCheckbox(type) ? styles.inputErrorCheckbox : styles.inputError;
    }
    return isCheckbox(type) ? styles.containerCheckbox : styles.container;
};

export default React.forwardRef(
    (
        {
            input,
            meta = {},
            type = "text",
            name = input ? input.name : "input",
            label = input ? input.name : "input",
            labelHidden = false,
            placeholder = false,
            autoComplete = "off",
            icon = false,
            min = null,
            max = null,
            disabled = false,
            smallInputBox = false,
            className = null,
            large,
            extraLarge,
            prefix = null,
            endPrefix = false,
            darkBorder = false,
            sideText,
            validateWhenActive = false,
            filledPrefix = false,
            tip = "",
        } = {},
        ref,
    ) => {
        const { error, submitFailed, warning, touched, active } = meta || {};
        return (
            <div
                className={`${className} ${large && styles.large} ${extraLarge && styles.extraLarge} ${darkBorder &&
                    styles.darkBorder} ${(touched || (validateWhenActive && active)) &&
                    error &&
                    styles.inputError} ${smallInputBox && styles.smallInputContainer} ${determineContainer(
                    submitFailed,
                    error,
                    type,
                )}`}
            >
                <label htmlFor={name} className={labelHidden ? styles.labelHidden : styles.label}>
                    {label}
                    {tip && <Tooltip id={`input-${name}`} message={tip} iconSize="0.75rem" proximity={2} />}
                </label>
                {sideText && <p className={styles.sideText}>{sideText}</p>}
                <div className={`${styles.inputContainer} ${endPrefix && styles.endPrefix}`}>
                    {icon && <span className={styles.icon}>{icon}</span>}
                    {prefix && (
                        <span className={`${styles.prefix} ${filledPrefix && styles.filledPrefix}`}>{prefix}</span>
                    )}
                    <input
                        {...input}
                        ref={ref}
                        id={name}
                        type={type}
                        placeholder={placeholder !== false ? placeholder : null}
                        className={styles.input}
                        autoComplete={autoComplete}
                        min={min}
                        max={max}
                        disabled={disabled}
                    />
                    {isCheckbox(type) && <span className={styles.checkbox} />}
                </div>
                {(touched || (validateWhenActive && active)) &&
                    ((error && (
                        <div className={`${styles.inlineErrorMessage}`}>
                            <span>{Array.isArray(error) ? error.join(" ") : error}</span>
                        </div>
                    )) ||
                        (warning && <span>{warning}</span>))}
            </div>
        );
    },
);
