import qs from "qs";
import { compose } from "recompose";
import { connect } from "react-redux";
import { reduxForm, SubmissionError } from "redux-form";
import { withRouter } from "react-router-dom";
import { signin } from "../../../_redux/users";
import LoginForm from "./";
import v from "validate.js";

const mapStateToProps = (_, { location: { search = "" } }) => {
    const { linkSuccess = false, linkFail = false } = qs.parse(
        search.substring(1)
    );

    return {
        successMessage: linkSuccess,
        failMessage: linkFail
    };
};
const mapDispatchToProps = (dispatch, { history, ...props }) => ({});

const validate = (values) =>
    v(values, {
        email: {
            presence: {
                message: "^Required"
            }
        },
        password: {
            presence: {
                message: "^Required"
            }
        }
    });

const submit = async (
    { email, password } = {},
    dispatch,
    { history, location: { search = "" } }
) => {
    const {
        error,
        payload: { response: { data: { message } = {} } = {} } = {}
    } = await dispatch(
        signin({
            email,
            password
        })
    );

    if (undefined !== error) {
        throw new SubmissionError({
            _error: message
        });
    }

    const { redirect = "/dashboard", ...params } = qs.parse(
        search
            .substr(1)
            .split("?")
            .join("&")
    );

    return history.push(
        `${redirect}${params ? `?${qs.stringify(params)}` : ""}`
    );
};

const withReduxForm = reduxForm({
    form: "signin",
    onSubmit: submit,
    validate
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withReduxForm
)(LoginForm);
