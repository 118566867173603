import { flatten } from "lodash";
import moment from "moment";

export const formatNotificationAlerts = (notifications) => {
    const notificationsFormatted = (notifications || []).map((notification) => {
        const { accountName, accountId, alerts = [] } = notification || {};
        return alerts.map((alert = {}) => ({ ...alert, accountId, accountName }));
    });

    return flatten(notificationsFormatted).sort(({ date: dateA }, { date: dateB }) => moment(dateB).diff(dateA));
};
