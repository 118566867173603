import createReducer from "../../../../lib/createReducer";
import { handle } from "redux-pack";
import merge from "lodash/fp/merge";
import omit from "lodash/omit";
import types from "./types";

export const defaultState = {
    notifications: [],
    lastSeen: "",
};

export default createReducer(defaultState, {
    [types.INITIALIZE_NOTIFICATIONS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
            }),
            finish: (prevState) => ({
                ...prevState,
            }),
            success: (prevState) => ({
                ...prevState,
                notifications: action.payload.alerts,
                lastSeen: action.payload.lastSeen,
            }),
        }),
    [types.READ_NOTIFICATION]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
            }),
            finish: (prevState) => ({
                ...prevState,
            }),
            success: (prevState) => {
                return merge(prevState, {
                    notifications: {
                        [action.payload.data.id]: {
                            notification: {
                                read: true,
                            },
                        },
                    },
                });
            },
        }),
    [types.DELETE_NOTIFICATION]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
            }),
            finish: (prevState) => ({
                ...prevState,
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    notifications: omit(prevState.notifications, action.payload.data.id),
                };
            },
        }),
    [types.NEW_NOTIFICATION]: (state, action) => {
        return {
            ...state,
            notifications: {
                ...state.notifications,
                ...action.payload.notifications,
            },
        };
    },
    [types.ADD_COUNT]: (state, action) => {
        return {
            ...state,
            count: state.count + 1,
        };
    },
    [types.CLEAR_COUNT]: (state, action) => {
        return {
            ...state,
            count: 0,
        };
    },
});