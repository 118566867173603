import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { NotificationBar, StandardNotification } from "./";
import moment from "moment";
import { channelFriendlyName } from "lib/utility";

const Notification = ({ platform, name } = {}) => (
    <StandardNotification>
        A {platform} connection, "{name}", seems to have been disconnected. Please{" "}
        <Link to={`/dashboard/user/connections`}> click here</Link> to fix this connection.
    </StandardNotification>
);

const mapStateToProps = ({
    sites: { selectedSite: { _id: accountId, goals: { values: goals = {} } = {} } = {} },
    user: {
        paymentSource: paymentSource = {},
        subscription: { isTrial = false, isFree = false, trialExpiry = 0, exists } = {},
        user: { created_at: signupDate, connections = {} } = {},
    } = {},
}) => {
    const candidChannels = ["analyticsV4", "adwords", "facebook", "bing", "linkedin"];
    const channels = Object.keys(connections).filter((value) => candidChannels.indexOf(value) !== -1);

    const notActiveConnections = [].concat(
        ...channels.map((channel) =>
            Object.keys(connections[channel])
                .map((connectionId) => ({
                    connectionId,
                    channel,
                    ...connections[channel][connectionId],
                }))
                .filter(({ active }) => !active),
        ),
    );

    const {
        [moment()
            .add(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD")]: isNextMonthGoalsExists = false,
    } = goals;

    const promotNextMonthGoalsReminder =
        accountId &&
        !isNextMonthGoalsExists &&
        moment()
            .endOf("month")
            .add(-5, "days")
            .isBefore(moment());

    return {
        accountId,
        subscriptionExists: exists,
        daysRemainingInTrial:
            !isFree && isTrial && Math.ceil(moment.duration(moment(trialExpiry).diff(moment())).asDays()),
        notActiveConnections,
        promotNextMonthGoalsReminder,
        showBillingPrompt:
            !isFree && !Object.keys(paymentSource).length && moment().diff(moment(signupDate), "days") >= 1,
    };
};

export default compose(connect(mapStateToProps))(({ notActiveConnections = [] }) => (
    <NotificationBar>
        {notActiveConnections.map((platform) => (
            <Notification {...platform} key={platform.channel} platform={channelFriendlyName(platform.channel)} />
        ))}
    </NotificationBar>
));
