import { connect } from "react-redux";
import UserPanel from "./";
import {
    makeSelectUserId,
    makeSelectRole,
    makeSelectIsTrial,
    makeSelectDaysRemainingInTrial,
    makeSelectSubscriptionPlanId,
    makeSelectIsFree,
    makeSelectUserMetaData,
    makeSelectEmail,
    makeSelectUserPicture,
    selectPaymentSource,
    makeSelectType,
    makeSelectSubscriptionType,
} from "_redux/users/selectors";
import { actions as planOptionActions } from "features/PlanOptions/_ducks";
import isEmpty from "lodash/isEmpty";

const { togglePlanOptions } = planOptionActions;

const NOT_SUPPORT_PANEL_PATH = ["onboarding", "add-account"];

const mapStateToProps = (state) => {
    const { nickname: name, isNavOpen = true } = makeSelectUserMetaData()(state);
    const planId = makeSelectSubscriptionPlanId()(state);
    const isTrial = makeSelectIsTrial()(state);
    const isFree = makeSelectIsFree()(state); // This is NOT Freemium
    const userType = makeSelectType()(state);
    const role = makeSelectRole()(state);
    const paymentSource = selectPaymentSource(state);

    const {
        router: { location: { pathname = "" } = {} },
    } = state;

    const accountId = pathname.split("/")[2];

    return {
        id: makeSelectUserId()(state),
        name,
        email: makeSelectEmail()(state),
        role,
        picture: makeSelectUserPicture()(state),
        isTrial,
        planId,
        isNavOpen,
        showNavigation:
            accountId && !["user", "profile", "alert-settings"].includes(accountId) ? true : false,
        showUpgrade:
            (planId === "freemium" ||
                (userType === "appSumoUser" && isEmpty(paymentSource)) ||
                (userType === "appSumoUserAnnual" && isEmpty(paymentSource)) ||
                (isTrial && (isEmpty(paymentSource) || !planId))) &&
            !isFree &&
            role === "super-admin",
        hidePanel: accountId && NOT_SUPPORT_PANEL_PATH.includes(accountId),
        subscriptionType: makeSelectSubscriptionType()(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    togglePlanOptions: (isOpen) => dispatch(togglePlanOptions({ isOpen })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
