import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Design-Library"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g id="0.1-Icons" transform="translate(-482.000000, -1088.000000)">
                <g id="Framework" transform="translate(140.000000, 60.000000)">
                    <g
                        id="Features-Copy-3"
                        transform="translate(0.000000, 880.000000)"
                    >
                        <g
                            id="4-copy"
                            transform="translate(322.000000, 128.000000)"
                        >
                            <g
                                id="Icon-Microsoft-Grey"
                                transform="translate(20.000000, 20.000000)"
                            >
                                <path
                                    d="M0,0 L7.5,0 C7.49954955,2.5 7.5,5 7.49954955,7.5 C5,7.5 2.5,7.5 0,7.5 L0,0"
                                    id="Fill-1"
                                    fill="#BCCFDC"
                                ></path>
                                <path
                                    d="M8.50011261,-1.77635684e-15 L15.9998874,-1.77635684e-15 C15.9998874,2.5 16.0003378,5 15.999437,7.5 C13.4999625,7.49954955 11.0000375,7.5 8.50056305,7.5 C8.49966217,5 8.50011261,2.5 8.50011261,-1.77635684e-15"
                                    id="Fill-3"
                                    fill="#BCCFDC"
                                ></path>
                                <path
                                    d="M5.68434189e-14,8.5 C2.49993327,8.50090085 4.99986654,8.49954958 7.49979981,8.50090085 C7.50025024,11.0007507 7.49979981,13.5001501 7.49979981,16 L5.68434189e-14,16 L5.68434189e-14,8.5"
                                    id="Fill-16"
                                    fill="#BCCFDC"
                                ></path>
                                <path
                                    d="M8.50045045,8.50056305 C11,8.49966217 13.5,8.50011261 16,8.50011261 C16,11.0000751 16,13.5000375 16,16 L8.5,16 C8.50045045,13.5000375 8.49954955,11.0000751 8.50045045,8.50056305"
                                    id="Fill-17"
                                    fill="#D5E0EC"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
