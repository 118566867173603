import { createSelector } from "reselect";

const selectPermissions = (state) => state.permissions;

export const makeSelectPermitted = () =>
    createSelector(selectPermissions, ({ permitted }) => permitted);

export const makeSelectIntersected = () =>
    createSelector(selectPermissions, ({ intersected }) => intersected);

export const makeSelectReasons = () =>
    createSelector(selectPermissions, ({ reasons }) => reasons);
