import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import moment from "moment";

export const defaultState = {
    startDate: null,
    endDate: null,
    compareToEnd: null,
    compareToStart: null,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.REPORTS_DATE]: (state, action) => {
            const daysetween = moment(action.payload.end).diff(moment(action.payload.start), "days") + 1;
            return {
                ...state,
                startDate: action.payload.start,
                endDate: action.payload.end,
                compareToEnd: moment(action.payload.start)
                    .add(-1, "days")
                    .format("YYYY-MM-DD"),
                compareToStart: moment(action.payload.start)
                    .add(-1 * daysetween, "days")
                    .format("YYYY-MM-DD"),
            };
        },
    }),
);
