import { api } from "lib/facebook";
import types from "./types";

const getFacebookData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: types.FACEBOOK_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
        }),
    });
};

export default {
    getFacebookData,
};
