import React from "react";
import ToggleMulti from "components/ToggleMulti";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "features/ControlPanel/ducks";
import { selectSortByDataType } from "features/ControlPanel/ducks/selectors";
import style from "./style.module.scss";

const { updateSortByDataType } = actions;

const SORT_TYPES = {
    actual: {
        label: "Actual",
    },
    growth: {
        label: "Growth",
    },
};

export default () => {
    const dispatch = useDispatch();
    const selectedSortType = useSelector((state) => selectSortByDataType(state)) || "actual";

    const onChangeSortType = (sortTypeId) => {
        dispatch(updateSortByDataType({ sortByDataType: sortTypeId }));
    };

    return (
        <div className={style.sortToggle}>
            <span className={style.sortToggleTitle}>Sort By:</span>
            <ToggleMulti
                options={Object.keys(SORT_TYPES).map((id) => {
                    return {
                        id,
                        label: SORT_TYPES[id].label,
                        onClick: () => onChangeSortType(id),
                    };
                })}
                active={selectedSortType}
            />
        </div>
    );
};
