import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";
import merge from "lodash/fp/merge";
import { dispatch } from "rxjs/internal/observable/pairs";

export const filters = {
    platform: "analyticsV4",
};

export const defaultState = {
    list: {},
    filters: filters,
    pending: {},
};

export default createReducer(defaultState, {
    [types.CLEAR_CONTENT]: (state, action) => {
        return {
            filters: state.filters,
            list: {},
            pending: { "custom-summary": true },
        };
    },
    [types.CLEAR_STATE]: (state, action) => {
        return merge(state, {
            ...state,
            list: {
                ...state.list,
                "custom-summary": null,
            },
            pending: {
                ...state.pending,
                "custom-summary": true,
            },
        });
    },
    [types.UPDATE_INSIGHTS_FILTERS]: (state, action) => {
        return merge(state, {
            ...state,
            filters: action.payload.filters,
        });
    },
    [types.GET_AUDITREPORT]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                pending: {
                    ...prevState.pending,
                    [action.meta.id]: true,
                },
            }),
            success: (prevState) => {
                return {
                    ...prevState,
                    list: {
                        ...prevState.list,
                        [action.meta.id]: action.payload.data,
                    },
                };
            },
            finish: (prevState) => ({
                ...prevState,
                pending: {
                    ...prevState.pending,
                    [action.meta.id]: false,
                },
            }),
        }),
});
