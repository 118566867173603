import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { mixpanel } from "components/Mixpanel";
import BookDemo from "./BookDemo";
import Modal from "components/ModalFullScreen";
import Upgrade from "./Upgrade";
import Downgrade from "./Downgrade";
import DowngradeAppSumo from "./DowngradeAppSumo";
import Success from "./Success";
import Options from "./Options";
import Loading from "components/Loading";
import Button from "components/Button";
import { DialogVFour, CloseDialog } from "components/Dialog";
import PopUp from "./PopUp";
import { BrandLogo } from "components/Branding";

export default ({
    downgradeToFree,
    downgradeAppsumoUser,
    userId,
    userEmail,
    userName,
    daysLeftInTrial,
    isTrial,
    isDowngraded,
    dismissedTrialWall,
    dismissTrialWall,
    forcePlanOptions,
    isOpen,
    toggleOpen,
    plans = {},
    getPlans,
    userType = "regular",
    step = "options",
    isFreemium = false,
}) => {
    const [display, toggleDisplay] = useState("options");
    const [billing, toggleBilling] = useState("yearly");
    const [isLoading, toggleLoading] = useState(false);
    const [popUpOpen, togglePopUp] = useState(false);

    useEffect(() => {
        toggleDisplay(step);
        if (forcePlanOptions) {
            toggleOpen(true);
            mixpanel.track("Plan Options - Wall View", {
                "In Freemium": forcePlanOptions,
                "In Trial": isTrial,
            });
        }
        if (!Object.keys(plans).length) {
            toggleLoading(true);
            getPlans().then(() => toggleLoading(false));
        }
    }, [step]);

    const handleClose = () => {
        if (!forcePlanOptions && display === "options") {
            return toggleOpen(false);
        }
        if (step) {
            toggleOpen(false);
        }
        if (display !== "options") {
            toggleDisplay("options");
        }
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                hideCloseButton={(forcePlanOptions && display === "options") || display === "success"}
                closeButton={
                    display === "upgrade" && (userType.includes("appSumoUser") || isFreemium)
                        ? () => {
                              return (
                                  <Button hollow className={styles.backbutton} onClick={() => toggleDisplay("options")}>
                                      Back
                                  </Button>
                              );
                          }
                        : null
                }
                close={() => {
                    // "Are you sure?" Logic
                    const showPopUp = display === "upgrade" || display === "options";

                    if (showPopUp) {
                        togglePopUp(true);
                        return;
                    }

                    handleClose();
                }}
                closeTip={["upgrade", "meeting", "downgrade"].includes(display) ? "Back" : "Close"}
            >
                <div className={styles.logo}>
                    <BrandLogo width={48} height={48} />
                </div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className={styles.modalWrapper}>
                        {display === "options" && (
                            <Options
                                toggleDisplay={toggleDisplay}
                                daysLeftInTrial={daysLeftInTrial}
                                isTrial={isTrial}
                                downgraded={isDowngraded}
                                forcePlanOptions={forcePlanOptions}
                                dismissedTrialWall={dismissedTrialWall}
                                dismissTrialWall={dismissTrialWall}
                                toggleOpen={toggleOpen}
                                userId={userId}
                                toggleBilling={toggleBilling}
                                billing={billing}
                            />
                        )}
                        {display === "downgrade" && (
                            <Downgrade
                                downgradeToFree={downgradeToFree}
                                toggleOpen={toggleOpen}
                                toggleDisplay={toggleDisplay}
                                userId={userId}
                                isTrial={isTrial}
                                daysLeftInTrial={daysLeftInTrial}
                                forcePlanOptions={forcePlanOptions}
                            />
                        )}
                        {display === "appSumoDowngrade" && (
                            <DowngradeAppSumo
                                downgradeAppsumoUser={downgradeAppsumoUser}
                                toggleOpen={toggleOpen}
                                toggleDisplay={toggleDisplay}
                                userId={userId}
                                isTrial={isTrial}
                                daysLeftInTrial={daysLeftInTrial}
                                forcePlanOptions={forcePlanOptions}
                            />
                        )}
                        {display === "upgrade" && (
                            <Upgrade
                                userId={userId}
                                plans={plans}
                                toggleDisplay={toggleDisplay}
                                billing={billing}
                                toggleBilling={toggleBilling}
                                isAppSumo={userType.includes("appSumoUser")}
                            />
                        )}
                        {display === "meeting" && <BookDemo />}
                        {display === "success" && <Success />}
                    </div>
                )}
            </Modal>
            <DialogVFour
                open={popUpOpen}
                classes={{
                    rootClass: styles.popUpRoot,
                    paperClass: styles.popUpPaper,
                }}
            >
                <CloseDialog
                    closeActions={() => {
                        handleClose();
                        togglePopUp(false);
                    }}
                />
                <PopUp
                    handleClose={handleClose}
                    togglePopUp={togglePopUp}
                    toggleDisplay={toggleDisplay}
                    userId={userId}
                    userEmail={userEmail}
                    userName={userName}
                />
            </DialogVFour>
        </>
    );
};
