import React, { useState, useCallback, useEffect } from "react";
import style from "./style.module.scss";
import moment from "moment";
import Button from "components/Button";
import debounce from "lodash/debounce";
import { checkStartValidity, checkEndValidity, getErrorMessage } from "components/DatePicker/MonthPicker/DateSelector";
import { getDateReadable, getDateSelectedLength } from "components/DatePicker/CompareToMonthPicker/helpers.js";
import DateSelectorContent from "./DateSelectorContent";

export default ({
    from,
    to,
    onCancel = () => null,
    onApply = () => null,
    onInputChange,
    maxDaysSelected,
    initialStart,
    initialEnd,
    className,
    isOpen = false,
    startDateReadable = "",
    endDateReadable = "",
    updateStartDate = () => null,
    updateEndDate = () => null,
    error = {},
    updateErrors = () => null,
    actualStart,
    actualEnd,
}) => {
    const [typing, updateTyping] = useState({ start: false, end: false });

    const daysSelected = getDateSelectedLength({ from, to });
    const actualDaysSelected = getDateSelectedLength({ from: actualStart, to: actualEnd });

    const onStartChange = useCallback(
        debounce((value) => {
            const startError = checkStartValidity({ value, end: endDateReadable, maxDaysSelected });
            const endError = checkEndValidity({ value: endDateReadable, start: value, maxDaysSelected });

            updateTyping({ start: false, end: false });

            if (startError) {
                onInputChange({}, true);
                return updateErrors({
                    ...error,
                    start: getErrorMessage({ error: startError, meta: { maxDaysSelected } }),
                });
            }

            updateErrors({
                end: endError ? getErrorMessage({ error: endError, meta: { maxDaysSelected } }) : "",
                start: "",
            });
            onInputChange({ start: value, end: endDateReadable });
        }, 500),
        [maxDaysSelected, endDateReadable, error, onInputChange],
    );

    const onEndChange = useCallback(
        debounce((value) => {
            const endError = checkEndValidity({ value, start: startDateReadable, maxDaysSelected });
            const startError = checkStartValidity({ value: startDateReadable, end: value, maxDaysSelected });

            updateTyping({ start: false, end: false });

            if (endError) {
                onInputChange({}, true);
                return updateErrors({
                    ...error,
                    end: getErrorMessage({ error: endError, meta: { maxDaysSelected } }),
                });
            }

            updateErrors({
                start: startError ? getErrorMessage({ error: startError, meta: { maxDaysSelected } }) : "",
                end: "",
            });
            onInputChange({ end: value, start: startDateReadable });
        }, 500),
        [maxDaysSelected, startDateReadable, error, onInputChange],
    );

    const resetInputs = useCallback(() => {
        if (from) {
            updateStartDate(moment(from).format("MMM D, YYYY"));
        } else {
            updateStartDate("");
        }

        if (to) {
            updateEndDate(moment(to).format("MMM D, YYYY"));
        } else {
            updateEndDate("");
        }

        if (!isOpen && (error.start || error.end)) {
            updateErrors({ start: "", end: "" });
        }
    }, [from, to, error.end, error.start, isOpen, updateEndDate, updateStartDate]);

    useEffect(() => {
        if (!isOpen && (error.start || error.end)) {
            resetInputs();
        }
    }, [isOpen, error.start, error.end, resetInputs]);

    const handleStartChange = (e) => {
        e.persist();
        updateTyping({ start: true, end: false });
        updateStartDate(e.target.value);
        onStartChange(e.target.value);
    };

    const handleStartBlur = (e) => {
        if (moment(e.target.value).isValid()) {
            updateStartDate(moment(e.target.value).format("MMM D, YYYY"));
        }
    };

    const handleEndChange = (e) => {
        e.persist();
        updateTyping({ start: false, end: true });
        updateEndDate(e.target.value);
        onEndChange(e.target.value);
    };

    const handleEndBlur = (e) => {
        if (moment(e.target.value).isValid()) {
            updateEndDate(moment(e.target.value).format("MMM D, YYYY"));
        }
    };

    return (
        <div className={`${style.dateSelector} ${className ? className : ""}`}>
            <DateSelectorContent
                title="Custom Date Range"
                startDateReadable={getDateReadable(actualStart)}
                endDateReadable={getDateReadable(actualEnd)}
                daysSelected={actualDaysSelected}
                disabled
            />
            <DateSelectorContent
                title="Compare With"
                startDateReadable={startDateReadable}
                endDateReadable={endDateReadable}
                startInput={{
                    onChange: handleStartChange,
                    onBlur: handleStartBlur,
                }}
                endInput={{
                    onChange: handleEndChange,
                    onBlur: handleEndBlur,
                }}
                daysSelected={daysSelected}
                error={error}
                typing={typing}
            />
            <div className={style.optionsContainer}>
                <Button small secondary onClick={() => onCancel()}>
                    Cancel
                </Button>
                <Button
                    small
                    onClick={() => onApply(from, to)}
                    disabled={
                        error.start ||
                        error.end ||
                        (startDateReadable === moment(initialStart).format("MMM D, YYYY") &&
                            endDateReadable === moment(initialEnd).format("MMM D, YYYY"))
                    }
                >
                    Apply
                </Button>
            </div>
        </div>
    );
};
