import classNames from "classnames";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import CloseIcon from "assets/images/icons/JSX/Icon-Close-Small";
import Searchbox from "components/SearchBox";
import { TooltipDark } from "components/Tooltip";

const FilterDropdown = ({
    name,
    options,
    itemSelected,
    icon,
    onClickHandler = () => {},
    allowSearch,
    searchBoxPlaceholder,
    className,
    Component,
    isOpen = false,
    toggleOpen,
    onClearAll,
    isButton,
    hideLabel = false,
}) => {
    const [menuOpen, toggleMenu] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [optionsFiltered, setOptionsFiltered] = useState([]);

    const outsideClick = useHandleOutsideClickHook(() => {
        if (menuOpen) {
            toggleMenu(false);
        }
    }, [menuOpen]);

    useEffect(() => {
        const newOptions = options?.filter(({ searchText = "" }) =>
            searchText.toLowerCase().includes(searchInput?.toLowerCase())
        );
        setOptionsFiltered(searchInput ? newOptions : options);
    }, [searchInput]);

    useEffect(() => {
        setOptionsFiltered(options);
    }, [options]);

    const onToggleOpen = (isOpen) => (toggleOpen ? toggleOpen(isOpen) : toggleMenu(isOpen));

    return (
        <div
            className={classNames(style.filterDropdown, {
                [className]: className,
                [style.active]: !!itemSelected.label,
                [style.open]: menuOpen || isOpen,
            })}
            ref={outsideClick}
            onClick={() => {
                isButton ? onClickHandler() : onToggleOpen(true);
            }}
        >
            {icon}&ensp;
            <span>{name}</span>
            {itemSelected.label && (
                <div className={classNames(style.filterDropdownMenu, { [style.hideLabel]: hideLabel })}>
                    <span>:&ensp;</span>
                    <span>{hideLabel ? "" : itemSelected.label}</span>
                    <div
                        className={style.closeIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClearAll ? onClearAll() : onClickHandler({});
                        }}
                        data-tip='Clear'
                        data-for='clear'
                    >
                        <CloseIcon />
                    </div>
                    <TooltipDark id='clear' proximity={4} />
                </div>
            )}
            {Component ? (
                <>{Component}</>
            ) : (
                <Container
                    className={style.optionsDropdown}
                    isOpen={menuOpen || isOpen}
                    dropdownWidth='auto'
                    anchor='left'
                >
                    {allowSearch && (
                        <Searchbox
                            className={style.searchAlert}
                            placeholder={searchBoxPlaceholder}
                            onChange={setSearchInput}
                            input={searchInput}
                        />
                    )}
                    {optionsFiltered?.map(({ value, label, labelText }) => (
                        <SimpleListItem
                            className={classNames(style.option)}
                            key={value}
                            isActive={value === itemSelected.value}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickHandler({ value, label: labelText || label });
                                onToggleOpen(false);
                            }}
                            label={label}
                        />
                    ))}
                </Container>
            )}
        </div>
    );
};

export default FilterDropdown;
