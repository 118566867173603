import React from "react";
import styles from "./style.module.scss";
import sharedStyles from "features/PlanOptions/style.module.scss";
import Button from "components/Button";

export default () => {
    return (
        <div>
            <div className={sharedStyles.heading}>
                <h2 className={styles.header}>Your payment was successful!</h2>
                <p>You can now continue using this app.</p>
            </div>
            <div className={styles.content}>
                <Button onClick={() => (window.location = "/dashboard")}>Go to Control Panel</Button>
            </div>
        </div>
    );
};
