import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

const Toggle = ({ onClick = () => null, className, active = false, small = false }) => {
    const classes = classNames(style.toggle, {
        [className]: className,
        [style.active]: active,
        [style.small]: small,
    });
    return (
        <div className={classes} onClick={onClick}>
            <div className={style.circle}></div>
        </div>
    );
};

export default Toggle;
