import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="#6A80A7">
        <path
            fillRule="evenodd"
            d="M27,23 C27.5522847,23 28,23.4477153 28,24 C28,24.5522847 27.5522847,25 27,25 L21,25 C20.4477153,25 20,24.5522847 20,24 C20,23.4477153 20.4477153,23 21,23 L27,23 Z M29,19 C29.5522847,19 30,19.4477153 30,20 C30,20.5522847 29.5522847,21 29,21 L19,21 C18.4477153,21 18,20.5522847 18,20 C18,19.4477153 18.4477153,19 19,19 L29,19 Z M31,15 C31.5522847,15 32,15.4477153 32,16 C32,16.5522847 31.5522847,17 31,17 L17,17 C16.4477153,17 16,16.5522847 16,16 C16,15.4477153 16.4477153,15 17,15 L31,15 Z"
            transform="translate(-16 -15)"
        />
    </svg>
);
