import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import * as types from "./types";
import moment from "moment";
import { handle } from "redux-pack";
import { get } from "lodash";

const defaultState = {
    list: {},
    tags: [],
    spend: {},
    singleBudgetCampaignsLoading: "",
    dateRanges: {},
    anomalyIssues: 0,
    updateBudgetsError: false,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ADD_BUDGET](state, action) {
            const { id, createdBy, campaigns, name, start, end, budget, tags = [], regex, pauseOnOverspend } = action;

            return {
                ...state,
                list: {
                    ...state.list,
                    [id]: {
                        createdBy,
                        created: moment.utc().format(),
                        campaigns,
                        name,
                        budget,
                        start,
                        end,
                        tags,
                        regex,
                        pauseOnOverspend,
                    },
                },
            };
        },
        [types.EDIT_BUDGET](state, action) {
            const {
                campaigns,
                name,
                start,
                end,
                budget,
                budgetId,
                lastUpdated,
                tags = [],
                regex,
                pauseOnOverspend,
            } = action;

            return {
                ...state,
                list: {
                    ...state.list,
                    [budgetId]: {
                        ...state.list[budgetId],
                        updated: Date.now(),
                        lastUpdated,
                        campaigns,
                        name,
                        budget,
                        start,
                        end,
                        tags,
                        regex,
                        pauseOnOverspend,
                    },
                },
            };
        },
        [types.DELETE_BUDGET](state, action) {
            const { budgetIds } = action;
            const newList = { ...state.list };

            budgetIds.forEach((currentValue) => {
                delete newList[currentValue];
            });

            return {
                ...state,
                list: newList,
            };
        },
        [types.TOGGLE_BUDGETS_LOADING](state, action) {
            const { isLoading } = action;

            return {
                ...state,
                budgetsLoading: isLoading,
            };
        },
        [types.TOGGLE_BUDGET_CAMPAIGNS_LOADING](state, action) {
            const { isLoading } = action;

            return {
                ...state,
                budgetCampaignsLoading: isLoading,
            };
        },
        [types.TOGGLE_SINGLE_BUDGET_LOADING](state, action) {
            const { budgetId } = action;

            return {
                ...state,
                singleBudgetCampaignsLoading: budgetId,
            };
        },
        [types.UPDATE_BUDGET_ID](state, action) {
            const { newId, oldId } = action;
            const stringifyOldId = oldId.toString();

            const { [stringifyOldId]: budget, ...budgets } = state?.list || {};

            return {
                ...state,
                list: {
                    [newId]: budget,
                    ...budgets,
                },
            };
        },
        [types.GET_BUDGETS]: (state, action) =>
            handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    list: {
                        ...state.list,
                        ...action.payload.data,
                    },
                }),
            }),
        [types.UPDATE_BUDGETS]: (state, action) =>
            handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    updateBudgetsLoading: true,
                }),
                failure: (prevState) => ({
                    ...prevState,
                    updateBudgetsError: true,
                    updateBudgetsLoading: false,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    updateBudgetsLoading: false,
                    updateBudgetsError: get(action, ["payload", "data", "error"]) || false,
                }),
            }),
        [types.GET_BUDGETS_CAMPAIGNS]: (state, action) =>
            handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    spend: {
                        ...state.spend,
                        ...action.payload.data,
                    },
                }),
            }),
        [types.UPDATE_DATE_RANGES]: (state, action) => ({
            ...state,
            dateRanges: {
                ...state.dateRanges,
                ...action.dateRanges,
            },
        }),
        // [types.GET_BUDGETS_ANOMALIES]: (state, action) => ({
        //     ...state,
        //     anomalyIssues: action.payload.data,
        // }),
        // [types.LOAD_BUDGET]:(state, action) => handle(state, action, {
        //     start: prevState => ({
        //         ...prevState
        //     }),
        //     success:prevState => {
        //         return {
        //             ...prevState
        //         }
        //     },
        //     finish: prevState => ({
        //         ...prevState
        //     }),
        // }),
    }),
);
