import React from "react";
import styleSheet from "./style.module.scss";
import Icon from "assets/images/icons/JSX/Icon-Search.jsx";

export default ({ className = "", labelText = "Search", changeEvent }) => (
    <div className={`${className} ${styleSheet.searchWrapper}`}>
        <Icon />
        <input
            className={styleSheet.filterSearch}
            type="text"
            placeholder={labelText}
            onChange={(e) => changeEvent(e)}
        />
    </div>
);
