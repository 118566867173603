import React from "react";

export default ({ styles }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" {...styles}>
        <g fill="none">
            <path
                fill="#BCCFDC"
                d="M8.99995764,10 C8.18361307,10 7.36753821,9.82040261 6.62337775,9.46120784 L1.25741165,6.87086678 C0.47930004,6.49528705 -0.0024218154,5.76894777 9.69134828e-05,4.97533912 C0.00266917005,4.18173046 0.489077429,3.45793996 1.26958281,3.08636544 L6.64906868,0.525547645 C8.12113892,-0.175182548 9.87877636,-0.175182548 11.3508466,0.525547645 L16.7303325,3.08636544 C17.5108379,3.45790961 17.9972461,4.18170012 17.9998184,4.97533912 C18.0023371,5.76894777 17.5206152,6.49528705 16.7425373,6.87086678 L11.3765038,9.46120784 C10.6325794,9.82040261 9.81613364,10 8.99995764,10 Z"
            />
            <path
                fill="#BCCFDC"
                d="M17.6290768,9.48425006 C17.9712855,9.31619408 18.0999087,8.92593625 17.9163635,8.61263719 C17.7328183,8.29927639 17.3066253,7.6819749 16.9644167,7.85 L10.7118436,10.85 C9.63997206,11.3764869 8.3601118,11.3777526 7.28540814,10.85 L1.03286885,7.85 C0.68978363,7.6833023 0.264197476,8.3332642 0.0822031745,8.64730414 C-0.0998585568,8.96137495 0.0306190173,9.35104625 0.373636805,9.51774395 L6.62341146,12.5548525 C7.36757193,12.9203218 8.18364678,13.1030101 9,13.1030101 C9.81613364,13.1030101 10.6325794,12.9202909 11.3765375,12.5548834 L17.6290768,9.48425006 Z"
            />
            <path
                fill="#BCCFDC"
                d="M17.6290768,12.574105 C17.9712855,12.4150057 18.0999087,12.045547 17.9163635,11.7489455 C17.7328183,11.4522856 17.3066253,10.8678837 16.9644167,11.0269538 L10.7118436,13.8670661 C9.63997206,14.3654934 8.3601118,14.3666916 7.28540814,13.8670661 L1.03286885,11.0269538 C0.68978363,10.8691404 0.264197476,11.484462 0.0822031745,11.7817649 C-0.0998585568,12.079097 0.0306190173,12.4480004 0.373636805,12.6058138 L6.62341146,15.481057 C7.36757193,15.8270482 8.18364678,16 9,16 C9.81613364,16 10.6325794,15.827019 11.3765375,15.4810862 L17.6290768,12.574105 Z"
            />
        </g>
    </svg>
);
