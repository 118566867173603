const TEAMMEMBER_SYNC = "TEAMMEMBER_SYNC";
const UPDATE_TEAMMEMBER = "UPDATE_TEAMMEMBER";
const RESEND_INVITATION = "RESEND_INVITATION";
const DELETE_TEAMMEMBER = "DELETE_TEAMMEMBER";
const GET_TEAM = "GET_TEAM";
const INVITE_TEAMMEMBER = "INVITE_TEAMMEMBER";

export default {
    TEAMMEMBER_SYNC,
    UPDATE_TEAMMEMBER,
    RESEND_INVITATION,
    DELETE_TEAMMEMBER,
    GET_TEAM,
    INVITE_TEAMMEMBER
};
