import { handle } from "redux-pack";
import createReducer from "../../lib/createReducer";
import {
    fetchSite,
    updateSiteSettings,
    updateAccountStatus,
    sendSlackMessage,
    updateAccountKeywords,
    updateAccountCompetitors,
    generateCompetitorsReport,
    updateDomainData,
    updateSeoVitalsData,
    updatePageHealthPlatformData,
    testWeeklyEmailRequest,
    saveMarginRuleRequest,
    deleteMarginRuleRequest,
    updateDashboardKpiCards,
} from "./async";
import { isAccId } from "lib/utility";
import { KpiAPI } from "../../lib/apiKpi";
import { getFacebookConversionTypes } from "features/Settings/_ducks/operations";
import { setCampaignGroupPerformance } from "features/Dashboard/_ducks/actions";
import { PAID_PLATFORMS } from "features/Dashboard/CampaignGroups/helpers";
import { isEmpty } from "lodash";

/**
 *  ACTION TYPES
 */

const GET_SITE_REJECTED = "GET_SITE_REJECTED";
const GET_SITE_FUFILLED = "GET_SITE_FUFILLED";
const GET_SITE_PENDING = "GET_SITE_PENDING";

const SET_SELECTED_SITE = "SET_SELECTED_SITE";
const CLEAR_SELECTED_SITE = "CLEAR_SELECTED_SITE";

const UPDATE_SITE_GOALS = "UPDATE_SITE_GOALS";
const UPDATE_SITE_MEASUREMENT_SETTING = "UPDATE_SITE_MEASUREMENT_SETTING";
const UPDATE_SITE_CONVERSION_TYPE_SETTING = "UPDATE_SITE_CONVERSION_TYPE_SETTING";
const UPDATE_SITE_CONVERSION_GOAL_TYPE_SETTING = "UPDATE_SITE_CONVERSION_GOAL_TYPE_SETTING";

const SAVE_CONNECTED_ACCOUNTS_REJECTED = "SAVE_CONNECTED_ACCOUNTS_REJECTED";
const SAVE_CONNECTED_ACCOUNTS_FULFILLED = "SAVE_CONNECTED_ACCOUNTS_FULFILLED";
const SAVE_CONNECTED_ACCOUNTS_PENDING = "SAVE_CONNECTED_ACCOUNTS_PENDING";

/**
 *  ACTIONS
 */

export const clearSelectedSite = () => ({
    type: CLEAR_SELECTED_SITE,
});

export const setSelectedSite = (selectedSite = {}) => ({
    type: SET_SELECTED_SITE,
    payload: selectedSite,
});

export const getSitePending = ({ pending } = {}) => ({
    type: GET_SITE_PENDING,
    pending,
});

export const getSiteFufilled = ({ payload } = {}) => ({
    type: GET_SITE_FUFILLED,
    payload,
});

export const getSiteRejected = ({ error, payload } = {}) => ({
    type: GET_SITE_REJECTED,
    error,
    payload,
});

export const updateSiteGoals = ({ goal, date, metric } = {}) => ({
    type: UPDATE_SITE_GOALS,
    goal,
    date,
    metric,
});

export const updateSiteMeasurementSetting = ({ measurement } = {}) => ({
    type: UPDATE_SITE_MEASUREMENT_SETTING,
    measurement,
});

export const updateSiteConversionTypeSetting = ({ conversionType } = {}) => ({
    type: UPDATE_SITE_CONVERSION_TYPE_SETTING,
    conversionType,
});

export const updateSiteConversionGoalTypeSetting = ({ conversionGoalTypes } = {}) => ({
    type: UPDATE_SITE_CONVERSION_GOAL_TYPE_SETTING,
    conversionGoalTypes,
});

const NOTIFICATION_BING_DISMISS = "NOTIFICATION_BING_DISMISS";
export const dismissBingNotification = (accountId) => ({
    type: NOTIFICATION_BING_DISMISS,
    accountId,
});

const NOTIFICATION_WEBMASTER_DISMISS = "NOTIFICATION_WEBMASTER_DISMISS";
export const dismissWebmasterNotification = (accountId) => ({
    type: NOTIFICATION_WEBMASTER_DISMISS,
    accountId,
});

const NOTIFICATION_GOOGLEADS_DISMISS = "NOTIFICATION_GOOGLEADS_DISMISS";
export const dismissGoogleAdsNotification = (accountId) => ({
    type: NOTIFICATION_GOOGLEADS_DISMISS,
    accountId,
});

const NOTIFICATION_FACEBOOK_DISMISS = "NOTIFICATION_FACEBOOK_DISMISS";
export const dismissFacebookNotification = (accountId) => ({
    type: NOTIFICATION_FACEBOOK_DISMISS,
    accountId,
});

const NOTIFICATION_SLACK_DISMISS = "NOTIFICATION_SLACK_DISMISS";
export const dismissSlackNotification = (accountId) => ({
    type: NOTIFICATION_SLACK_DISMISS,
    accountId,
});

const ACCOUNT_UPDATE_DATA = "ACCOUNT_UPDATE_DATA";
export const updateAccountData = (accountId, data) => ({
    type: ACCOUNT_UPDATE_DATA,
    accountId,
    data,
    meta: {
        sync: true,
    },
});

export const getSite = ({ id }) => {
    return (dispatch) => {
        dispatch(getSitePending({ pending: true }));
        dispatch(getSiteRejected({ error: false, payload: {} }));

        return fetchSite(id)
            .then((payload) => {
                dispatch(getSiteFufilled({ payload }));
                dispatch(getSitePending({ pending: false }));

                //get account permissions and update permission object?

                // Update Campaign Groups Performance
                const { integrations = {}, campaignGroups: groups = {} } = payload;
                const platformGroups = Object.keys(integrations)
                    .filter((key) => PAID_PLATFORMS.includes(key))
                    .reduce(
                        (cache, platformKey) => ({
                            ...cache,
                            [platformKey]: { info: { name: platformKey }, campaigns: [] },
                        }),
                        {},
                    );
                const campaignGroups = Object.keys(groups).reduce(
                    (cache, key) => ({
                        ...cache,
                        [key]: { info: { name: groups[key].name }, campaigns: [] },
                    }),
                    {},
                );

                dispatch(setCampaignGroupPerformance({ ...platformGroups, ...campaignGroups }));

                return payload;
            })
            .catch((err) => {
                dispatch(getSiteRejected({ error: true, payload: err }));
                dispatch(getSitePending({ pending: false }));
                throw new Error("Something went wrong with getting the user's sites");
            });
    };
};

export const changeAccount = (accountId) => {
    return async (dispatch, getState) => {
        if (!accountId) return;

        dispatch(getSitePending({ pending: true }));
        const site = await dispatch(getSite({ id: accountId }));
        const { integrations: { facebook } = {} } = site;

        await Promise.all([...(facebook ? [dispatch(getFacebookConversionTypes(accountId)).catch(() => null)] : [])]);

        dispatch(getSitePending({ pending: false }));
        return;
    };
};

const DISMISS_TOURING = "DISMISS_TOURING";
export const dismissTouring = ({ accountId }) => (dispatch) =>
    dispatch({
        type: DISMISS_TOURING,
        promise: updateAccountStatus({
            accountId,
            status: {
                run_touring: false,
            },
        }),
        meta: {
            accountId,
        },
    });

const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const updateCurrency = ({ accountId, currency }) => (dispatch) =>
    dispatch({
        type: UPDATE_CURRENCY,
        promise: updateSiteSettings({
            account_id: accountId,
            data: {
                currency,
            },
        }),
        meta: {
            accountId,
            currency,
        },
    });

const UPDATE_FACEBOOK_ATTRIBUTION = "UPDATE_FACEBOOK_ATTRIBUTION";
export const updateFacebookAttribution = ({ accountId, attribution }) => (dispatch) => {
    return dispatch({
        type: UPDATE_FACEBOOK_ATTRIBUTION,
        promise: KpiAPI({
            method: "PUT",
            url: `/facebook/attribution`,
            data: {
                attribution,
                accountId,
            },
        }),
        meta: {
            accountId,
            attribution,
        },
    });
};

const UPDATE_PINTEREST_ATTRIBUTION = "UPDATE_PINTEREST_ATTRIBUTION";
export const updatePinterestAttribution = ({ accountId, attribution }) => (dispatch) => {
    return dispatch({
        type: UPDATE_PINTEREST_ATTRIBUTION,
        promise: KpiAPI({
            method: "PUT",
            url: `/pinterest/attribution`,
            data: {
                attribution,
                accountId,
            },
        }),
        meta: {
            accountId,
            attribution,
        },
    });
};

const UPDATE_SITE_METADATA = "UPDATE_SITE_METADATA";
export const updateMetadata = ({ accountId, metadata }) => (dispatch, getState) => {
    const { selectedSite: { metadata: stateMetadata = {} } = {} } = getState().sites;

    return dispatch({
        type: UPDATE_SITE_METADATA,
        promise: updateSiteSettings({
            account_id: accountId,
            data: {
                metadata: {
                    ...stateMetadata,
                    ...metadata,
                },
            },
        }),
        meta: {
            accountId,
            metadata: {
                ...stateMetadata,
                ...metadata,
            },
        },
    });
};

const ACCOUNT_UPDATE_KEYWORDS = "ACCOUNT_UPDATE_KEYWORDS";
export const setAccountKeywords = ({ keywords, accountId }) => (dispatch) =>
    dispatch({
        type: ACCOUNT_UPDATE_KEYWORDS,
        promise: updateAccountKeywords({ keywords, accountId }),
        meta: {
            keywords,
        },
    });

const ACCOUNT_UPDATE_COMPETITORS_DATA = "ACCOUNT_UPDATE_COMPETITORS_DATA";
export const generateCompetitorsData = ({ accountId }) => (dispatch) =>
    dispatch({
        type: ACCOUNT_UPDATE_COMPETITORS_DATA,
        promise: generateCompetitorsReport({ accountId }),
    });

const ACCOUNT_UPDATE_DOMAIN = "ACCOUNT_UPDATE_DOMAIN";
export const updateDomain = ({ accountId, domain, location }) => (dispatch) =>
    dispatch({
        type: ACCOUNT_UPDATE_DOMAIN,
        promise: updateDomainData({ accountId, domain, location }),
    });

const ACCOUNT_UPDATE_COMPETITORS = "ACCOUNT_UPDATE_COMPETITORS";
export const setAccountCompetitors = ({ competitors, accountId }) => (dispatch) =>
    dispatch({
        type: ACCOUNT_UPDATE_COMPETITORS,
        promise: updateAccountCompetitors({ competitors, accountId }),
        meta: {
            competitors,
        },
    });

/**
 *  UPDATE DASHBOARD METRICS
 */
const ACCOUNT_UPDATE_DASHBOARD_METRICS = "ACCOUNT_UPDATE_DASHBOARD_METRICS";
export const updateDashboardKpiCardsData = ({ metrics, accountId }) => (dispatch) =>
    dispatch({
        type: ACCOUNT_UPDATE_DASHBOARD_METRICS,
        promise: updateDashboardKpiCards({ metrics, accountId }),
        meta: {
            metrics,
        },
    });

/**
 *  SEO AUDIT SETTINGS
 */

const SET_SEO_AUDIT_SETTINGS = "SET_SEO_AUDIT_SETTINGS";
export const setSeoAuditSettings = ({ seoAuditSettings }) => (dispatch, getState) => {
    const { selectedSite: { _id: accountId } = {} } = getState().sites;
    return dispatch({
        type: SET_SEO_AUDIT_SETTINGS,
        promise: updateSeoVitalsData({
            accountId,
            seoAuditSettings,
        }),
        meta: {
            seoAuditSettings,
        },
    });
};

/**
 *  PAGE HEALTH SETTINGS
 */

const SET_PAGE_HEALTH_SETTINGS = "SET_PAGE_HEALTH_SETTINGS";
export const setPageHealthSettings = ({ pageHealthSettings }) => (dispatch, getState) => {
    const { selectedSite: { _id: accountId } = {} } = getState().sites;
    return dispatch({
        type: SET_PAGE_HEALTH_SETTINGS,
        promise: updatePageHealthPlatformData({
            accountId,
            pageHealthSettings,
        }),
        meta: {
            pageHealthSettings,
        },
    });
};

/**
 *  EMAIL SETTINGS
 */

const ADD_EXTERNAL_EMAIL = "ADD_EXTERNAL_EMAIL";
export const addExternalEmail = ({ email, name } = {}) => ({
    type: ADD_EXTERNAL_EMAIL,
    email,
    emailObject: { email, name, weeklyKpiSettings: {} },
});

const DELETE_EXTERNAL_EMAIL = "DELETE_EXTERNAL_EMAIL";
export const deleteExternalEmail = ({ emailId } = {}) => ({
    type: DELETE_EXTERNAL_EMAIL,
    emailId,
});

const SET_EXTERNAL_EMAIL_SETTINGS = "SET_EXTERNAL_EMAIL_SETTINGS";
export const setWeeklyKpiFilters = ({ emailId, weeklyKpiSettings } = {}) => {
    return {
        type: SET_EXTERNAL_EMAIL_SETTINGS,
        emailId,
        weeklyKpiSettings,
    };
};

const TEST_EMAIL = "TEST_EMAIL";
export const testWeeklyEmail = (props) => (dispatch) =>
    dispatch({
        type: TEST_EMAIL,
        promise: testWeeklyEmailRequest(props),
        meta: props,
    });

/**
 *  SLACK SETTINGS
 */

const SAVE_NOTIFICATION_CHANNELS = "SAVE_NOTIFICATION_CHANNELS";
export const saveNotificationChannels = ({ accountId }) => (dispatch, getState) => {
    const { selectedSite: { notifications = {} } = {} } = getState().sites;
    return dispatch({
        type: SAVE_NOTIFICATION_CHANNELS,
        promise: updateSiteSettings({
            account_id: accountId,
            data: {
                notifications,
            },
        }),
    });
};

const SAVE_KPI_SUMMARY_SETTINGS = "SAVE_KPI_SUMMARY_SETTINGS";
export const saveKpiSummarySettings = ({ kpiSummarySettings, accountId }) => (dispatch, getState) => {
    return dispatch({
        type: SAVE_KPI_SUMMARY_SETTINGS,
        promise: KpiAPI({
            method: "PUT",
            url: `/kpiSummarySettings`,
            data: {
                accountId,
                data: kpiSummarySettings,
            },
        }).then(({ data } = {}) => data || {}),
    });
};

const SAVE_MARGIN_RULE = "SAVE_MARGIN_RULE";
export const saveMarginRule = ({ accountId, rule }) => async (dispatch, getState) => {
    saveMarginRuleRequest({ accountId, rule });

    return dispatch({
        type: SAVE_MARGIN_RULE,
        rule,
    });
};

const DELETE_MARGIN_RULE = "DELETE_MARGIN_RULE";
export const deleteMarginRule = ({ accountId, platform }) => async (dispatch, getState) => {
    deleteMarginRuleRequest({ accountId, platform });

    return dispatch({
        type: DELETE_MARGIN_RULE,
        platform,
    });
};

const ADD_SLACK_CHANNEL = "ADD_SLACK_CHANNEL";
export const addSlackChannel = ({ channel, integrationId } = {}) => ({
    type: ADD_SLACK_CHANNEL,
    channel,
    integrationId,
});

const DELETE_SLACK_CHANNEL = "DELETE_SLACK_CHANNEL";
export const deleteSlackChannel = ({ channel, integrationId } = {}) => ({
    type: DELETE_SLACK_CHANNEL,
    channel,
    integrationId,
});

const SET_SLACK_ANOMALY_FILTERS = "SET_SLACK_ANOMALY_FILTERS";
export const setSlackAnomalyFilters = ({ anomalySettings, channel }) => {
    return {
        type: SET_SLACK_ANOMALY_FILTERS,
        value: { channel, anomalySettings },
    };
};

const SET_SLACK_INSIGHTS_FILTERS = "SET_SLACK_INSIGHTS_FILTERS";
export const setSlackInsightsFilters = ({ insightsSettings, channel }) => {
    return {
        type: SET_SLACK_INSIGHTS_FILTERS,
        value: { channel, insightsSettings },
    };
};

const UPDATE_INTEGRATIONS = "UPDATE_INTEGRATIONS";
export const updateIntegrations = (integrations) => {
    return {
        type: UPDATE_INTEGRATIONS,
        integrations,
    };
};

const slackMessage = ({ accountId, accountName, channel, connectionId }) => {
    const messages = [
        {
            channel,
            attachments: [
                {
                    title: `You have successfully connected your account, ${accountName}, to this Slack channel!`,
                    text:
                        "To create alerts, please visit: https://app.hawke.ai/dashboard/alert-settings \n\n" +
                        "For alerts to appear in this channel, please ensure you check the box in the distribution section of the alert builder: \n\n",
                    image_url:
                        "https://firebasestorage.googleapis.com/v0/b/morphio-kpi-171314.appspot.com/o/Hawke_images%2FApp%2FAlerts-Settings.png?alt=media&token=13cf63f3-f1b2-473d-b895-59541161a6d3",
                    mrkdwn: true,
                },
                {
                    title: `\nAlert Volume\n`,
                    image_url:
                        "https://firebasestorage.googleapis.com/v0/b/morphio-kpi-171314.appspot.com/o/Hawke_images%2FApp%2FAlerts-Threshold.png?alt=media&token=114e1cec-d62f-4930-9058-ee2f56a3eff3",
                    text:
                        "If at any point you’re concerned that you’re receiving too many or too few alerts, please review your change % threshold in your Alerts Settings. \n\n" +
                        "Setting it too low or too high can result in false alarms or no alarms. With a little experimentation you’ll find out what’s right for the account. \n\n",
                    mrkdwn: true,
                },
            ],
        },
    ];

    return sendSlackMessage({ accountId, messages, connectionId });
};

const TEST_SLACK_MESSAGE = "TEST_SLACK_MESSAGE";
export const testSlackMessage = ({ accountId, accountName, channel, connectionId }) => (dispatch) =>
    dispatch({
        type: TEST_SLACK_MESSAGE,
        promise: slackMessage({ accountId, accountName, channel, connectionId }),
    });

const SET_ANALYZING_DATA = "SET_ANALYZING_DATA";
export const setAnalyzingData = (analyzingData) => ({
    type: SET_ANALYZING_DATA,
    analyzingData: {
        active: analyzingData,
        lastRun: analyzingData ? new Date() : null,
    },
});

/**
 * REDUCERS
 */

const defaultSite = {
    _id: null,
    isDemo: false,
    goals: {
        conversion_goal_types: [],
        values: {},
        metrics: {},
    },
    notifications: {
        slackChannels: {},
        externalEmails: {},
    },
    facebook: {
        conversions: {},
    },
    linkedin: {
        conversions: {},
    },
    tiktok: {
        conversions: {},
    },
    pinterest: {
        conversions: {},
    },
    dashboards: {
        default: { _id: "default", name: "default", campaignRegex: "" },
    },
    selectedDashboard: "default",
    selectedDashboardKpiCards: undefined,
    integrations: {
        adwords: {},
        facebook: {},
        analyticsV4: {},
        bing: {},
        linkedin: {},
        tiktok: {},
        pinterest: {},
        maropost: {},
        klaviyo: {},
        bigQuery: {},
        organicFacebook: {},
        stackAdapt: {},
    },
    seo: {
        keywords: [],
        competitors: [],
        seoAuditSettings: {},
    },
    autoPlacementExclusion: {
        autoPilot: false,
        percentage: 0.4,
    },
    pagehealth: {
        pageHealthSettings: {},
    },
    connections: {},
    is_anomaly_active: false,
    key_metrics: {},
    pinned: false,
    active: false,
    analyzingData: {
        active: false,
        lastRun: null,
    },
};

export const defaultState = {
    channelTestError: false,
    channelTestSuccess: false,
    isRemovingView: false,
    isAddingView: false,
    sitePending: false,
    updatingDashboardAnomalyFilters: false,
    errorMessage: "",
    updatingMetaData: false,
    selectedSite: {
        ...defaultSite,
    },
};

export const UPDATE_AUTO_PLACEMENT_EXCLUSION = "UPDATE_AUTO_PLACEMENT_EXCLUSION";

export const sites = createReducer(defaultState, {
    "@@router/LOCATION_CHANGE": (state, { payload } = {}) => {
        const { location: { pathname = "" } = {} } = payload || {};

        const accountIdSlug = pathname.split("/")[2];

        if (!accountIdSlug) {
            return {
                ...state,
                lastLink: null,
            };
        }

        if (accountIdSlug && isAccId(accountIdSlug)) {
            return {
                ...state,
                lastLink: pathname,
            };
        }

        return {
            ...state,
        };
    },
    [ACCOUNT_UPDATE_DASHBOARD_METRICS]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    selectedDashboardKpiCards: action.meta.metrics,
                },
            }),
        }),
    [ADD_EXTERNAL_EMAIL](state, action) {
        const { notifications: { externalEmails = {}, ...notifications } = {} } = state.selectedSite || {};
        const emailId = action.email.replace(/[\W_]+/g, "_");
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...notifications,
                    externalEmails: {
                        ...externalEmails,
                        [emailId]: { emailId, ...action.emailObject },
                    },
                },
            },
        };
    },
    [UPDATE_INTEGRATIONS]: (state, action) => ({
        ...state,
        selectedSite: {
            ...state.selectedSite,
            integrations: { ...state.selectedSite.integrations, ...action.integrations },
        },
    }),
    [UPDATE_FACEBOOK_ATTRIBUTION]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    facebook: {
                        ...prevState?.selectedSite?.facebook,
                        attribution: {
                            ...prevState?.selectedSite?.facebook?.attribution,
                            ...action.meta.attribution,
                        },
                    },
                },
            }),
        }),
    [UPDATE_PINTEREST_ATTRIBUTION]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    pinterest: {
                        ...prevState?.selectedSite?.pinterest,
                        attribution: {
                            ...prevState?.selectedSite?.pinterest?.attribution,
                            ...action.meta.attribution,
                        },
                    },
                },
            }),
        }),
    [SAVE_KPI_SUMMARY_SETTINGS]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    kpiSummarySettings: action.payload,
                },
            }),
        }),
    [ACCOUNT_UPDATE_DOMAIN]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateDomainPending: true,
                updateDomainError: false,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateDomainPending: false,
                updateDomainError: true,
            }),
            success: (prevState) => ({
                ...prevState,
                updateDomainPending: false,
                updateDomainError: false,
            }),
        }),
    [SAVE_NOTIFICATION_CHANNELS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                saveNotificationsPending: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                saveNotificationsPending: false,
            }),
        }),
    [UPDATE_CURRENCY]: (state, action) => ({
        ...state,
        selectedSite: {
            ...state.selectedSite,
            currency: action.meta.currency,
        },
    }),
    [CLEAR_SELECTED_SITE]: (state, action) => ({
        ...state,
        selectedSite: {
            ...defaultState.selectedSite,
        },
    }),
    [SET_SELECTED_SITE]: (state, action) => ({
        ...state,
        selectedSite: isEmpty(action.payload) ? { ...defaultSite } : { ...action.payload },
    }),
    [UPDATE_SITE_METADATA]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updatingMetaData: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatingMetaData: false,
                selectedSite: {
                    ...prevState.selectedSite,
                    metadata: {
                        ...prevState.selectedSite.metadata,
                        ...action.meta.metadata,
                    },
                },
            }),
        }),
    [UPDATE_AUTO_PLACEMENT_EXCLUSION]: (state, action) =>
        handle(state, action, {
            finish: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    autoPlacementExclusion: {
                        ...prevState.selectedSite.autoPlacementExclusion,
                        ...action.meta.autoPlacementExclusion,
                    },
                },
            }),
        }),
    [ACCOUNT_UPDATE_COMPETITORS]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    seo: {
                        ...prevState.selectedSite.seo,
                        competitors: action.meta.competitors,
                    },
                },
            }),
            start: (prevState) => ({
                ...prevState,
                updatingCompetitorsSettings: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatingCompetitorsSettings: false,
            }),
        }),
    [ACCOUNT_UPDATE_KEYWORDS]: (state, action) =>
        handle(state, action, {
            success: (prevState) => ({
                ...prevState,
                selectedSite: {
                    ...prevState.selectedSite,
                    seo: {
                        ...prevState.selectedSite.seo,
                        keywords: action.meta.keywords,
                    },
                },
            }),
            start: (prevState) => ({
                ...prevState,
                updatingKeywordsSettings: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatingKeywordsSettings: false,
            }),
        }),
    [TEST_EMAIL]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                emailTestPending: action.meta.email,
                emailTestSuccess: false,
            }),
            failure: (prevState) => ({
                ...prevState,
                emailTestPending: false,
                emailTestSuccess: false,
            }),
            success: (prevState) => ({
                ...prevState,
                emailTestPending: false,
                emailTestSuccess: action.meta.email,
            }),
        }),
    [DELETE_EXTERNAL_EMAIL](state, action) {
        const { notifications: { externalEmails = {}, ...notifications } = {} } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...notifications,
                    externalEmails: {
                        ...externalEmails,
                        [action.emailId]: null,
                    },
                },
            },
        };
    },
    [ADD_EXTERNAL_EMAIL](state, action) {
        const { notifications: { externalEmails = {}, ...notifications } = {} } = state.selectedSite || {};
        const emailId = action.email.replace(/[\W_]+/g, "_");
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...notifications,
                    externalEmails: {
                        ...externalEmails,
                        [emailId]: { emailId, ...action.emailObject },
                    },
                },
            },
        };
    },
    [SET_EXTERNAL_EMAIL_SETTINGS](state, action) {
        const {
            notifications: {
                externalEmails: { [action.emailId]: { ...emailSettings }, ...externalEmails } = {},
                ...notifications
            } = {},
        } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...notifications,
                    externalEmails: {
                        ...externalEmails,
                        [action.emailId]: {
                            ...emailSettings,
                            weeklyKpiSettings: action.weeklyKpiSettings,
                        },
                    },
                },
            },
        };
    },
    [TEST_SLACK_MESSAGE]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                channelTestError: false,
                channelTestSuccess: false,
            }),
            failure: (prevState) => ({
                ...prevState,
                channelTestError: true,
                channelTestSuccess: false,
            }),
            success: (prevState) => ({
                ...prevState,
                channelTestError: false,
                channelTestSuccess: true,
            }),
        }),
    [SET_SLACK_ANOMALY_FILTERS](state, action) {
        const { channel, anomalySettings } = action.value;
        const {
            notifications: {
                slackChannels: {
                    [channel]: { anomalySettings: oldAnomalySettings = {}, ...settings } = {},
                    ...slackChannels
                } = {},
            } = {},
        } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...state.selectedSite.notifications,
                    slackChannels: {
                        ...slackChannels,
                        [channel]: {
                            ...settings,
                            anomalySettings: {
                                ...oldAnomalySettings,
                                ...anomalySettings,
                            },
                        },
                    },
                },
            },
        };
    },
    [SET_SLACK_INSIGHTS_FILTERS](state, action) {
        const { channel, insightsSettings } = action.value;
        const {
            notifications: {
                slackChannels: {
                    [channel]: { insightsSettings: oldInsightsSettings = {}, ...settings } = {},
                    ...slackChannels
                } = {},
            } = {},
        } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                notifications: {
                    ...state.selectedSite.notifications,
                    slackChannels: {
                        ...slackChannels,
                        [channel]: {
                            ...settings,
                            insightsSettings: {
                                ...oldInsightsSettings,
                                ...insightsSettings,
                            },
                        },
                    },
                },
            },
        };
    },
    [ADD_SLACK_CHANNEL](state, action) {
        const { integrations: { slack = {} } = {} } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                integrations: {
                    ...state.selectedSite?.integrations,
                    slack: {
                        ...slack,
                        [action.integrationId]: {
                            ...slack[action.integrationId],
                            channels: {
                                ...(slack[action.integrationId]?.channels || {}),
                                [action.channel]: {
                                    channel: action.channel,
                                },
                            },
                        },
                    },
                },
            },
            channelTestError: false,
            channelTestSuccess: false,
        };
    },
    [DELETE_SLACK_CHANNEL](state, action) {
        const { integrations: { slack = {} } = {} } = state.selectedSite || {};
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                integrations: {
                    ...state.selectedSite?.integrations,
                    slack: {
                        ...slack,
                        [action.integrationId]: {
                            ...slack[action.integrationId],
                            channels: {
                                ...(slack[action.integrationId]?.channels || {}),
                                [action.channel]: null,
                            },
                        },
                    },
                },
            },
        };
    },
    [SAVE_CONNECTED_ACCOUNTS_FULFILLED](state, action) {
        return {
            ...state,
            saveError: false,
            selectedSite: {
                ...state.selectedSite,
                is_anomaly_active: action.payload.is_anomaly_active,
                integrations: {
                    ...state.selectedSite.integrations,
                    ...action.payload.integrations,
                },
                status: {
                    ...action.payload.status,
                },
            },
        };
    },
    [SAVE_CONNECTED_ACCOUNTS_REJECTED](state, action) {
        return {
            ...state,
            saveError: action.error,
        };
    },
    [SAVE_CONNECTED_ACCOUNTS_PENDING](state, action) {
        return {
            ...state,
            savePending: action.pending,
        };
    },
    [UPDATE_SITE_CONVERSION_GOAL_TYPE_SETTING](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                goals: {
                    ...state.selectedSite.goals,
                    conversion_goal_types: action.conversionGoalTypes,
                },
            },
        };
    },
    [UPDATE_SITE_CONVERSION_TYPE_SETTING](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                goals: {
                    ...state.selectedSite.goals,
                    selected_types: action.conversionType,
                },
            },
        };
    },
    [UPDATE_SITE_MEASUREMENT_SETTING](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                goals: {
                    ...state.selectedSite.goals,
                    conversion_measurement: action.measurement,
                },
            },
        };
    },
    [SAVE_MARGIN_RULE](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                margin_rules: {
                    ...state.selectedSite.margin_rules,
                    [action.rule.platform]: action.rule,
                },
            },
        };
    },
    [DELETE_MARGIN_RULE](state, action) {
        const { [action.platform]: deletion, ...rest } = state.selectedSite.margin_rules;

        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                margin_rules: rest,
                margin_rules_history: {
                    ...state.selectedSite.margin_rules_history,
                    [Date.now()]: {
                        platform: action.platform,
                        ...deletion,
                    },
                },
            },
        };
    },
    [UPDATE_SITE_GOALS](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                goals: {
                    ...state.selectedSite.goals,
                    values: {
                        ...state.selectedSite.goals.values,
                        [action.date]: {
                            ...state.selectedSite.goals.values[action.date],
                            [action.metric]: action.goal,
                        },
                    },
                },
            },
        };
    },
    [GET_SITE_FUFILLED](state, action) {
        return {
            ...state,
            siteError: false,
            selectedSite: {
                ...defaultSite,
                ...action.payload,
            },
        };
    },
    [GET_SITE_REJECTED](state, action) {
        return {
            ...state,
            siteError: action.error,
        };
    },
    [GET_SITE_PENDING](state, action) {
        return {
            ...state,
            sitePending: action.pending,
        };
    },
    [SET_SEO_AUDIT_SETTINGS](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                seo: {
                    ...state.seo,
                    seoAuditSettings: action.meta.seoAuditSettings,
                },
            },
        };
    },
    [SET_PAGE_HEALTH_SETTINGS](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                pagehealth: {
                    ...state.pagehealth,
                    pageHealthSettings: action.meta.pageHealthSettings,
                },
            },
        };
    },
    [SET_ANALYZING_DATA](state, action) {
        return {
            ...state,
            selectedSite: {
                ...state.selectedSite,
                analyzingData: action.analyzingData,
            },
        };
    },
});
