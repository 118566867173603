import io from "socket.io-client/dist/socket.io";
import wildcard from "socketio-wildcard";
import qs from "qs";

let socket;
export default ({ host } = {}) => ({ dispatch }) => (next) => (action) => {
    const { meta = {}, type, ...payload } = action;
    if (type === "GET_SITE_FUFILLED" && socket) {
        const { roomId } = qs.parse(socket.query);

        if (roomId !== action.payload._id) {
            console.log("disconnected from: ", roomId);
            socket.disconnect();
            socket = undefined;
        }
    }

    if (type === "GET_SITE_FUFILLED" && typeof socket === "undefined") {
        socket = io(host, {
            query: qs.stringify({
                accountId: action.payload._id,
                roomId: action.payload._id,
                event: "ACCOUNT_SYNC",
            }),
            transports: ["websocket"],
        });
        wildcard(io.Manager)(socket);

        socket.on("connect", () => console.log("Socket connected!"));
        socket.on("disconnect", () => console.log("Socket disconnected!"));
        socket.on("ACCOUNT_SYNC", (account) => {
            //@@WISH this is bad. fix this
            // need to remove sockets for only relevant parts of the application or just remove it all for site updates.
            return dispatch({
                type: "GET_SITE_FUFILLED",
                payload: account,
            });
        });
    }

    if (typeof socket === "undefined") {
        return next(action);
    }

    if (meta.sync) {
        socket.emit(type, payload);
    }

    // TODO: Remove in favour of `meta.sync`
    if (typeof type === "string" && type.match(/^NOTIFICATION_[A-Z]+_DISMISS/)) {
        socket.emit(type, payload);
    }

    return next(action);
};
