import styleMobile from "./styleMobile.module.scss";
import { ReactComponent as InsightIcon } from "assets/images/icons/Icon-Insights-List.svg";
import { ReactComponent as BudgetIcon } from "assets/images/icons/Icon-EnhancedBudgets.svg";
import { ReactComponent as MetricIcon } from "assets/images/icons/Icon-Reporting.svg";
import React from "react";

const DESKTOP_OPTIONS = {
    REPORTING_ROLE: [
        {
            id: "metrics",
            tip: "Metrics View",
            label: "Metrics",
            icon: <MetricIcon />,
        },
    ],
    OTHER_ROLES: [
        {
            id: "budget",
            tip: "Budgets View",
            label: "Budgets",
            icon: <BudgetIcon />,
        },
        {
            id: "metrics",
            tip: "Metrics View",
            label: "Metrics",
            icon: <MetricIcon />,
        },
    ],
};

const MOBILE_OPTIONS = {
    REPORTING_ROLE: [
        {
            id: "tracker",
            label: "Tracker",
        },
    ],
    OTHER_ROLES: [
        {
            id: "tracker",
            label: "Tracker",
        },
        {
            id: "budget",
            tip: "Budgets View",
            label: "Budgets",
        },
    ],
};

export const getOptions = ({ isMobile, toggleViewType, userRole = "" }) => {
    const deviceOptions = isMobile ? MOBILE_OPTIONS : DESKTOP_OPTIONS;
    const userRoleOptions = userRole === "reporting" ? deviceOptions.REPORTING_ROLE : deviceOptions.OTHER_ROLES;
    return userRoleOptions.map((option) => {
        return {
            ...option,
            onClick: () => toggleViewType(option.id),
            className: isMobile && styleMobile.toggleViewItem,
            tip: isMobile ? "" : option.tip,
        };
    });
};

export const getDefaultViewType = ({ isMobile, userRole }) => {
    if (isMobile) {
        return "tracker";
    }

    if (userRole === "reporting") {
        return "metrics";
    }

    return "budget";
};
