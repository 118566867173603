import React, { useEffect } from "react";
import Loading from "components/Loading";
import { cookieLogin } from "lib/auth";

export default ({ history }) => {
    useEffect(() => {
        cookieLogin()
            .then(() => {
                return history.push("/dashboard");
            })
            .catch(() => {
                history.push("/login");
                return null;
            });
    }, []);

    return <Loading message="⏳ Re-Loading your login details..." />;
};
