import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./style.module.scss";
import classNames from "classnames";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import isEqual from "lodash/isEqual";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { mixpanel } from "components/Mixpanel";
import { makeSelectUserId } from "_redux/users/selectors";
import { selectOverviewTagsAvailable } from "features/ControlPanel/ducks/selectors";
import { updateOverviewUi, updateUserControlPanelSettings } from "_redux/users";
import { createOverviewTag, editOverviewTag, deleteOverviewTag } from "features/ControlPanel/ducks/operations";
import EditIcon from "assets/images/icons/JSX/Icon-Edit";
import CheckboxSearch from "components/SearchBox/CheckboxSearchWithComponent";
import Button from "components/Button";
import { AccountTag, TagAdd, TagEdit } from "features/ControlPanel/AccountTags";
import DropDownContainer from "components/DropDown/Container";
import SelectInput from "components/DropDown/Select";
import { SimpleListItem } from "components/DropDown/ListItems";
import { TooltipDark } from "components/Tooltip";
import get from "lodash/get";
import { generateTagId } from "lib/accountTags";
import { ReactComponent as TagIcon } from "assets/images/icons/Icon-Tag.svg";
import TagsApplied from "features/ControlPanel/TagsApplied";
import { isEmpty } from "lodash";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";

const containsLabels = {
    any: "Contains any",
    all: "Contains all",
    none: "Contains none",
};

const TagFilter = React.memo(({ className, tags: unfilteredTags = [], contains = "any" }) => {
    const dispatch = useDispatch();
    const availableTags = useSelector((state) => selectOverviewTagsAvailable(state));
    const userMemoSelector = useMemo(makeSelectUserId)
    const userId = useSelector((state) => userMemoSelector(state));

    const tags = unfilteredTags.filter((tagId) => availableTags.find((t) => tagId === t.id));

    const [selectedContains, updateContains] = useState("");
    const [selectedTags, updateTags] = useState([]);
    const [isOpen, toggleOpen] = useState(false);
    const [view, toggleView] = useState("select");
    const [editTag, toggleEditTag] = useState("");

    const ref = useHandleOutsideClickHook(() => {
        toggleOpen(false);
        updateTags(tags);
        updateContains(contains);
    });

    // Change edit/create
    // - Update redux
    // - Update parent tags with separate endpoint - don't want to get into messing with parent metadata
    //    - Need to make sure we are simply adding / removing / editing a tag because we don't want to pass the whole tags array and risk messing up what another user is doing

    const createTag = useCallback(
        (newTag) => {
            dispatch(createOverviewTag({ tagName: newTag, tagId: `${generateTagId()}-${userId}` }));
            mixpanel.track("Overview - Add Tag", {
                Tag: newTag,
            });
            toggleView("select");
        },
        [toggleView, dispatch, userId],
    );

    const handleTagSave = useCallback(
        ({ id, newName }) => {
            dispatch(editOverviewTag({ tagId: id, tagName: newName }));
            mixpanel.track("Overview - Edit Tag", {
                Tag: newName,
            });
            toggleView("select");
        },
        [dispatch],
    );

    const handleTagDelete = useCallback(
        (tag) => {
            dispatch(deleteOverviewTag({ tagId: tag }));
            mixpanel.track("Overview - Delete Tag", {
                Tag: tag,
            });
            toggleView("select");
        },
        [dispatch],
    );

    useEffect(() => {
        updateTags(tags);
    }, [unfilteredTags]);

    useEffect(() => {
        updateContains(contains);
    }, [contains]);

    useEffect(() => {
        if (availableTags.length === 0) {
            toggleView("add");
        } else {
            toggleView("select");
        }
    }, [availableTags.length]);

    return (
        <FilterDropdown
            className={style.filterDropdown}
            name='Tags'
            onClearAll={() =>
                dispatch(updateOverviewUi({ userId, ui: { tags: [] }, replace: true }))
            }
            itemSelected={{ label: !isEmpty(tags) && <TagsApplied /> }}
            icon={<TagIcon />}
            isOpen={isOpen}
            toggleOpen={toggleOpen}
            Component={
                <div ref={ref} className={classNames(style.tagFilter, { [className]: className })}>
                    <div className={classNames(style.tagSearchWrapper, { [style.isOpen]: isOpen })}>
                        <SwitchTransition mode='out-in'>
                            <CSSTransition
                                key={view}
                                classNames={{
                                    enter: style.contentEnter,
                                    enterActive: style.contentEnterActive,
                                    exit: style.contentExit,
                                    exitActive: style.contentExitActive,
                                }}
                                timeout={500}
                            >
                                <div className={style.switchContainer}>
                                    {view === "select" && (
                                        <TagSelect
                                            availableTags={availableTags}
                                            selectedTags={selectedTags}
                                            userId={userId}
                                            updateTags={updateTags}
                                            selectedContains={selectedContains}
                                            updateContains={updateContains}
                                            toggleOpen={toggleOpen}
                                            isOpen={isOpen}
                                            toggleView={toggleView}
                                            toggleEditTag={toggleEditTag}
                                        />
                                    )}
                                    {view === "add" && (
                                        <TagAdd
                                            availableTags={availableTags}
                                            toggleView={toggleView}
                                            toggleManage={toggleOpen}
                                            createTag={createTag}
                                        />
                                    )}
                                    {view === "edit" && (
                                        <TagEdit
                                            availableTags={availableTags}
                                            toggleView={toggleView}
                                            toggleManage={toggleOpen}
                                            tagId={editTag}
                                            tag={
                                                availableTags.find(({ id }) => id === editTag)?.name
                                            }
                                            saveTag={(tag) =>
                                                handleTagSave({ id: editTag, newName: tag })
                                            }
                                            deleteTag={() => handleTagDelete(editTag)}
                                        />
                                    )}
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                </div>
            }
        />
    );
}, isEqual);

export const TagSelect = ({
    availableTags,
    selectedTags,
    userId,
    updateTags,
    selectedContains,
    updateContains,
    toggleOpen,
    toggleView,
    toggleEditTag,
    isOpen,
}) => {
    const dispatch = useDispatch();

    const [isContainsOpen, toggleContainsOpen] = useState(false);

    const applyTags = useCallback((e) => {
        e.stopPropagation();
        mixpanel.track("Overview - Apply Tag Filters", {
            Contains: selectedContains,
            Tags: selectedTags.map((tagId) => availableTags.find((t) => t.id === tagId)?.name).join(", "),
        });
        dispatch(updateOverviewUi({ ui: { tags: selectedTags, contains: selectedContains }, userId }));
        toggleOpen(false);
    }, [selectedTags, selectedContains, userId, dispatch, toggleOpen, availableTags]);

    const toggleTags = useCallback(
        ({ id, checked }) => {
            if (checked) {
                updateTags((selected) => [...selected, id]);
            } else {
                updateTags((selected) => selected.filter((tagId) => tagId !== id));
            }
        },
        [updateTags],
    );

    const toggleContains = useCallback(
        (value) => {
            updateContains(value);
            toggleContainsOpen(false);
        },
        [updateContains],
    );

    return (
        <>
            <SelectInput
                className={style.containsSelect}
                handleClick={() => toggleContainsOpen(!isContainsOpen)}
                refFunction={() => toggleContainsOpen(false)}
                value={containsLabels[selectedContains]}
                isOpen={isContainsOpen}
            >
                <DropDownContainer isOpen={isContainsOpen}>
                    <SimpleListItem
                        isActive={selectedContains === "any"}
                        onClick={() => toggleContains("any")}
                        label="Contains any"
                    />
                    <SimpleListItem
                        isActive={selectedContains === "all"}
                        onClick={() => toggleContains("all")}
                        label="Contains all"
                    />
                    <SimpleListItem
                        isActive={selectedContains === "none"}
                        onClick={() => toggleContains("none")}
                        label="Contains none"
                    />
                </DropDownContainer>
            </SelectInput>
            <CheckboxSearch
                className={style.tagSearch}
                toggleFocus={isOpen}
                search={{
                    placeholder: `Search Tags`,
                    accessor: "id",
                    large: true,
                    zeroState: () =>
                        availableTags.length > 0 ? (
                            <div className={style.emptySearch}>There are no tags that match your search.</div>
                        ) : (
                            <div className={style.emptySearch}>
                                Start by adding a tag to one of your client accounts.
                            </div>
                        ),
                }}
                options={availableTags.map(({ name, color, hexColor, id }, index) => ({
                    label: (
                        <>
                            <AccountTag className={style.accountTag} color={color} hexColor={hexColor} tag={name} />
                            {toggleEditTag && (
                                <>
                                    <div
                                        className={style.editTag}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            toggleEditTag(id);
                                            toggleView("edit");
                                        }}
                                        data-for={`edit-${index}`}
                                        data-tip="Edit Tag"
                                    >
                                        <EditIcon />
                                    </div>
                                    <TooltipDark id={`edit-${index}`} proximity={2} delay />
                                </>
                            )}
                        </>
                    ),
                    id,
                    checked: selectedTags.includes(id),
                    className: style.tagCheckbox,
                }))}
                itemType="Tags"
                onChange={toggleTags}
                hideSelectAll
                bottomBar={() => (
                    <>
                        {toggleView && (
                            <div className={style.searchFooter}>
                                <span className={style.addNew} onClick={() => toggleView("add")}>
                                    + Add New Tag
                                </span>
                                <Button small onClick={applyTags}>
                                    Apply
                                </Button>
                            </div>
                        )}
                    </>
                )}
                extraContent={toggleView ? <span className={style.tagsTitle}>Tags</span> : null}
                sortFn={(a, b, path) => {
                    const tagA = get(a, path);
                    const tagB = get(b, path);

                    const aVal = availableTags.find((t) => t.name === tagA)?.index;
                    const bVal = availableTags.find((t) => t.name === tagB)?.index;
                    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
                }}
            />
        </>
    );
};

export default TagFilter;
