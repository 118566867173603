import React from "react";

export default ({ styles, className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="14"
        viewBox="0 0 16 14"
        {...styles}
    >
        <g fill="none">
            <path
                fill="#242423"
                d="M7.99996235,8.75 C7.27432273,8.75 6.54892286,8.59285229 5.88744688,8.27855686 L1.11769924,6.01200843 C0.42604448,5.68337617 -0.0021527248,5.0478293 8.61453176e-05,4.35342173 C0.0023725956,3.65901416 0.434735492,3.02569746 1.12851806,2.70056976 L5.91028327,0.459854189 C7.21879015,-0.15328473 8.78113454,-0.15328473 10.0896414,0.459854189 L14.8714066,2.70056976 C15.5651892,3.02567091 15.9975521,3.65898761 15.9998386,4.35342173 C16.0020774,5.0478293 15.5738802,5.68337617 14.8822554,6.01200843 L10.1124478,8.27855686 C9.45118165,8.59285229 8.72545213,8.75 7.99996235,8.75 Z"
            />
            <path
                fill="#A7B4CB"
                d="M15.6702905,8.2987188 C15.974476,8.15166982 16.0888077,7.81019422 15.9256564,7.53605754 C15.7625052,7.26186684 15.3836669,6.72172803 15.0794815,6.86875 L9.52163879,9.49375 C8.56886405,9.95442603 7.43121049,9.95553349 6.47591835,9.49375 L0.918105643,6.86875 C0.613141005,6.72288951 0.234842201,7.29160617 0.0730694885,7.56639112 C-0.0887631616,7.84120308 0.0272169043,8.18216547 0.332121605,8.32802595 L5.88747685,10.9854959 C6.54895283,11.3052816 7.2743527,11.4651338 8,11.4651338 C8.72545213,11.4651338 9.45118165,11.3052545 10.1124778,10.985523 L15.6702905,8.2987188 Z"
            />
            <path
                fill="#A7B4CB"
                d="M15.6702905,11.0023419 C15.974476,10.86313 16.0888077,10.5398537 15.9256564,10.2803273 C15.7625052,10.0207499 15.3836669,9.50939826 15.0794815,9.64858456 L9.52163879,12.1336828 C8.56886405,12.5698067 7.43121049,12.5708552 6.47591835,12.1336828 L0.918105643,9.64858456 C0.613141005,9.51049784 0.234842201,10.0489042 0.0730694885,10.3090443 C-0.0887631616,10.5692099 0.0272169043,10.8920004 0.332121605,11.0300871 L5.88747685,13.5459248 C6.54895283,13.8486672 7.2743527,14 8,14 C8.72545213,14 9.45118165,13.8486416 10.1124778,13.5459504 L15.6702905,11.0023419 Z"
            />
        </g>
    </svg>
);
