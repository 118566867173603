import React from "react";
import ListItem, { propTypes } from "./ListItem";
import UpgradeIcon from "assets/images/icons/JSX/Icon-Upgrade-Circle";
import style from "./style.module.scss";
import PropTypes from "prop-types";

function FreemiumListItem({ iconClass, ...props }) {
    return (
        <ListItem {...props}>
            <div className={`${style.freemiumListItemIcon} ${iconClass && iconClass}`}>
                <UpgradeIcon />
            </div>
        </ListItem>
    );
}

PropTypes.FreemiumListItem = propTypes;

export default FreemiumListItem;
