import Checkbox from "components/Checkbox";
import PlatformIcon from "components/Icon/PlatformIcon";
import RenderInput from "components/RenderInput";
import ExtraLabel from "features/AlertSettings/ExtraLabel";
import { channelFriendlyName, metricFriendlyName } from "lib/utility";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { Field } from "redux-form";
import style from "./style.module.scss";
import { toPrettyNumber } from "lib/metricCalculations";

const BUDGET_TYPE_TOTAL = "total";

export const budgetPlannerDataFomatter = ({ budgetType, months, budgetPlannerData, budgets, platforms }) => {
    const budgetsFormatted = budgetsFomatter({ budgetType, months, budgetPlannerData, budgets });
    const platformsFomatted = platformsFomatter(platforms);
    return {
        budgets: budgetsFormatted,
        platforms: platformsFomatted,
    };
};

const calculateMonthlyBudgets = ({ months, budgetPlannerData, budgets }) => {
    const totalForecastedBudget = months.reduce((acc, month) => {
        return (
            acc +
            budgetPlannerData[month].reduce((acc, item) => {
                return acc + (item?.forecasted?.cost || 0);
            }, 0)
        );
    }, 0);

    return months.reduce((acc, month) => {
        const currentMonthBudget = budgetPlannerData[month].reduce((acc, item) => {
            return acc + (item?.forecasted?.cost || 0);
        }, 0);

        return {
            ...acc,
            [month]: (budgets.total / totalForecastedBudget) * currentMonthBudget,
        };
    }, {});
};

const budgetsFomatter = ({ budgetType, months, budgetPlannerData, budgets }) => {
    if (!budgetType || isEmpty(budgets)) {
        return {};
    }
    if (budgetType === BUDGET_TYPE_TOTAL) {
        return calculateMonthlyBudgets({ months, budgetPlannerData, budgets });
    }

    return Object.keys(budgets).reduce((cache, month) => {
        return month === "total"
            ? cache
            : {
                  ...cache,
                  [month]: parseFloat(budgets[month]),
              };
    }, {});
};

const platformsFomatter = (platforms) => {
    if (isEmpty(platforms)) {
        return {};
    }
    return Object.keys(platforms).reduce((cache, platform) => {
        const { threshold = 0, active } = platforms[platform] || {};

        return {
            ...cache,
            [platform]: { threshold: active ? parseFloat(threshold) / 100 : 0 },
        };
    }, {});
};

export const BUDGET_THRESHOLD_HEAD_CELLS = [
    {
        id: "platform",
        label: "Platform",
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "0.625rem 1rem 0.625rem 1rem",
        width: "200px",
        hasDivider: true,
    },
    {
        id: "metrics",
        label: "Metrics",
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "0.625rem 1rem 0 1.5rem",
        width: "400px",
    },
];

export const ADD_BUDGET_HEAD_CELLS = [
    {
        id: "month",
        label: "Month",
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "0.625rem 1rem 0.625rem 1rem",
        width: "192px",
        hasDivider: true,
    },
    {
        id: "budget",
        label: "Budget",
        headPadding: "1rem 0.625rem 1rem 1.25rem",
        padding: "0.625rem 1rem 0 1.5rem",
        width: "311px",
    },
];

export const BUDGET_TYPE_OPTIONS = [
    {
        label: (
            <ExtraLabel subLabel="Input a total and the tool will handle allocation per month" label="Total Budget" />
        ),
        value: BUDGET_TYPE_TOTAL,
    },
    {
        label: <ExtraLabel subLabel="Input a budget for each month in your range" label="Monthly Budget(s)" />,
        value: "monthly",
    },
];

export const METRIC_OPTIONS = [
    {
        label: "ROAS",
        value: "roas",
    },
    {
        label: "CPA",
        value: "cpl",
    },
];

export const getAddBudgetTableRows = (months) => [
    ...months.map((month) => {
        return {
            id: month,
            month: {
                content: <strong>{moment(month).format("MMMM")}</strong>,
                value: month,
                verticalAlign: "middle",
            },
            budget: {
                content: (
                    <Field
                        component={RenderInput}
                        labelHidden
                        name={`budgets.${month}`}
                        type="number"
                        filledPrefix
                        prefix="$"
                    />
                ),
                value: "budget",
                verticalAlign: "top",
            },
        };
    }),
];

export const getBudgetThresholdTableRows = ({ availablePlatfoms, metricsAverages } = {}) => [
    ...availablePlatfoms.map((platform) => {
        const [platformLabel, channelLabel] = channelFriendlyName(platform).split(", ");

        return {
            id: platform,
            platform: {
                content: (
                    <div className={style.platformCellContainer}>
                        <Field name={`platforms.${platform}.active`} component={Checkbox} type="checkbox" />
                        <div className={style.platformLabelContainer}>
                            <PlatformIcon className={style.platformIcon} platform={platform} />
                            <div className={style.headingText}>
                                <div className={style.title}>
                                    <span className={style.titleText}>{platformLabel}</span>
                                </div>
                                {channelLabel && <div className={style.subTitle}>{channelLabel}</div>}
                            </div>
                        </div>
                    </div>
                ),
                value: platform,
                verticalAlign: "top",
            },
            metrics: {
                content: (
                    <div className={style.metricsContainer}>
                        {Object.keys(metricsAverages).map((metric) => {
                            const [platformKey, metricKey] = metric.split("-");
                            if (platformKey !== platform) return null;
                            return (
                                <div className={style.metric}>
                                    <div className={style.metricLabel}>{metricFriendlyName(metricKey)}</div>
                                    <div className={style.metricValue}>
                                        {toPrettyNumber(metricsAverages?.[metric], metric)}
                                    </div>
                                    <div className={style.metricValue}>
                                        <Field
                                            component={RenderInput}
                                            labelHidden
                                            name={`metricsAverages.${metric}`}
                                            type="number"
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ),
                value: null,
                verticalAlign: "top",
            },
        };
    }),
];
