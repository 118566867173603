import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";

const ExtraLabel = ({ subLabel, label, haveMargin = false }) => {
    return (
        <div className={style.extraLabelContainer}>
            <div className={style.mainLabel}>{label}</div>
            {subLabel && (
                <div
                    className={classNames(style.subLabel, {
                        [style.haveMargin]: haveMargin,
                    })}
                >
                    {subLabel}
                </div>
            )}
        </div>
    );
};

export default ExtraLabel;
