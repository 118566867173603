import React from "react";
import { find, get, isEmpty, startsWith } from "lodash";
import {
    capitalize,
    channelFriendlyName,
    PlatformAndChannelLabel,
} from "lib/utility";
import { store } from "../../_redux";
import getSymbolFromCurrency from "currency-symbol-map";

export const BIGQUERY_METRIC_SPLITED_BY = "%";
export const BIGQUERY_METRIC_PREFIX = `bigQuery`;
export const ADVANCED_METRIC_PREFIX = "advancedMetric_";

const NUMERIC_TYPES = [
    "NUMERIC",
    "DECIMAL",
    "BIGNUMERIC",
    "BIGDECIMAL",
    "INT64",
    "INT",
    "SMALLINT",
    "INTEGER",
    "BIGINT",
    "TINYINT",
    "TINYINT",
    "FLOAT64",
];

export const formatIntegrationKey = (key) =>
    key ? key.replace(/\./g, "&") : "";
export const formatStoredIntegrationKey = (key) =>
    key ? key.replace(/&/g, ".") : "";

export const getBigQueryKpiMetrics = (bigQueryIntegrations = {}) => {
    return Object.keys(bigQueryIntegrations).reduce((cache, integrationId) => {
        const {
            bigQueryBasicMetrics: basicMetrics = [],
            bigQueryAdvancedMetrics = [],
        } = bigQueryIntegrations[integrationId];
        const advancedMetrics = bigQueryAdvancedMetrics.filter(
            (metric) => metric.query && metric.isSelected
        );
        const integrationMetrics = [...basicMetrics, ...advancedMetrics].reduce(
            (metricsCache, metric) => {
                if (!NUMERIC_TYPES.includes(metric.type)) {
                    // We skip non-numeric fields because we couldn't get the total value
                    return metricsCache;
                }

                return {
                    ...metricsCache,
                    [`bigQuery${BIGQUERY_METRIC_SPLITED_BY}${integrationId}${BIGQUERY_METRIC_SPLITED_BY}${metric.id}`]: {},
                    // bigQuery%[PROJECT_ID]&[DATASET_ID]&[TABLE_ID]%[METRIC_NAME]
                };
            },
            {}
        );
        return { ...cache, ...integrationMetrics };
    }, {});
};

export const isBigQueryMetric = (metricId) =>
    startsWith(metricId, BIGQUERY_METRIC_PREFIX);

export const isAvailableMetric = ({ metricId, bigQueryIntegrations = {} }) => {
    const [, integrationId] = metricId.split(BIGQUERY_METRIC_SPLITED_BY);
    return bigQueryIntegrations.hasOwnProperty(integrationId);
};

export const isBigQueryAdvancedMetric = (metricKey) =>
    startsWith(metricKey, ADVANCED_METRIC_PREFIX);

const getAdvancedMetricName = ({ integration, metricKey }) => {
    const { bigQueryAdvancedMetrics: advancedMetrics = [] } = integration;
    const metric = find(advancedMetrics, { id: metricKey }) || {};
    return get(metric, "name") || "";
};

export const getBigQueryKpiMetricItem = ({
    metricId,
    platformInfo,
    style,
    bigQueryIntegrations,
}) => {
    const [platformKey, integrationId, metricKey] = metricId.split(
        BIGQUERY_METRIC_SPLITED_BY
    );

    const integration = bigQueryIntegrations[integrationId] || {};
    if (isEmpty(integration)) return undefined;

    const PlatformIcon = platformInfo[platformKey]?.icon;
    const channel = platformInfo[platformKey]?.name;
    const metricLabel = isBigQueryAdvancedMetric(metricKey)
        ? getAdvancedMetricName({ integration, metricKey })
        : metricKey;
    const channelKey = `${platformKey}${BIGQUERY_METRIC_SPLITED_BY}${integrationId}`;

    return {
        label: metricLabel,
        labelDetails: (
            <span>
                <span className={style.formatChannel}>
                    {PlatformIcon && <PlatformIcon />}{" "}
                    <PlatformAndChannelLabel
                        channelKey={channelKey}
                        fallback={channel}
                        className={style.channelLabel}
                    />
                </span>
            </span>
        ),
        searchString: `${capitalize(metricLabel)} ${channelFriendlyName(
            platformKey
        )} ${integrationId}`,
        name: metricId,
        id: metricId,
        tip: "",
        platformChannel: channel,
        platformKey: platformKey,
        platformChannelName: channelFriendlyName(platformKey),
        channelKey,
    };
};

const getBigQueryMetric = (metricFullId) => {
    const [, integrationId, metricKey] = metricFullId.split(
        BIGQUERY_METRIC_SPLITED_BY
    );
    const { sites = {} } = store.getState();
    const integration =
        get(sites, [
            "selectedSite",
            "integrations",
            "bigQuery",
            integrationId,
        ]) || {};
    const {
        bigQueryBasicMetrics = [],
        bigQueryAdvancedMetrics = [],
    } = integration;
    const metric =
        find([...bigQueryBasicMetrics, ...bigQueryAdvancedMetrics], {
            id: metricKey,
        }) || {};

    return isEmpty(metric)
        ? { id: metricKey }
        : { ...metric, currency: get(integration, ["currency"]) };
};

export const getBigQueryMetricName = (metricFullId) => {
    const metric = getBigQueryMetric(metricFullId);
    return metric.name || metric.id || "";
};

export const getBigQueryMetricFormat = (metricFullId) => {
    const metric = getBigQueryMetric(metricFullId);
    const { format = "", currency = "USD" } = metric;

    if (format === "number") {
        return { unit: "", decimals: 2 };
    }
    if (format === "percentage") {
        return { unit: "%", decimals: 2 };
    }
    if (format === "currency") {
        return { unit: getSymbolFromCurrency(currency), decimals: 2 };
    }

    return { unit: "", decimals: "" };
};

export const parseToBigQueryMetricKey = ({ integrationId, metricKey }) =>
    `bigQuery${BIGQUERY_METRIC_SPLITED_BY}${formatIntegrationKey(
        integrationId
    )}${BIGQUERY_METRIC_SPLITED_BY}${metricKey}`;

export const formatAdvancedMetricsToSave = (advancedMetrics) =>
    advancedMetrics.reduce((cache, metric) => {
        if (!metric.query) {
            return cache;
        }

        const { format, name } = metric;
        return [
            ...cache,
            {
                ...metric,
                value: name,
                type: format === "string" ? "STRING" : "NUMERIC",
            },
        ];
    }, []);
