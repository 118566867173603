import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

/**
 * Produces a css module style based on anchor position or null if not provided
 * @param {string} position anchor position of dropdown
 * @returns {string | null} css module of style or null if anchor does not exist or is not provided
 */

function anchorPosition(position) {
    if (position === "top") {
        return style.top;
    }
    if (position === "right") {
        return style.right;
    }
    if (position === "left") {
        return style.left;
    }
    if (position === "bottom") {
        return style.bottom;
    }
    return null;
}

/**
 *
 * @param {object} props
 * @param {Array<JSX.Element> | JSX.Element} props.children
 * @param {boolean} props.isOpen determines whether or not the container is open
 * @param {string} props.anchor enum of top | right | left | bottom
 * @param {string} props.className optional className of component
 * @returns {JSX.Element}
 */

const Container = React.forwardRef(
    (
        {
            children,
            isOpen,
            anchor = "right",
            className,
            dropdownWidth = "100%",
            hideBorder = false,
            small = false,
            triangle,
            ...props
        },
        ref,
    ) => {
        const classes = classNames(anchorPosition(anchor), style.container, {
            [style.open]: isOpen,
            [className]: className,
            [style.hideBorder]: hideBorder,
            [style.small]: small,
        });
        return (
            <ul ref={ref} style={{ width: dropdownWidth }} className={classes} {...props}>
                {children}
            </ul>
        );
    },
);

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    anchor: PropTypes.string,
    className: PropTypes.string,
};

export default Container;
