const GET_CAMPAIGNS_REJECTED = "GET_CAMPAIGNS_REJECTED";
const GET_CAMPAIGNS_FUFILLED = "GET_CAMPAIGNS_FUFILLED";
const GET_CAMPAIGNS_PENDING = "GET_CAMPAIGNS_PENDING";

const CREATE_CAMPAIGN_REJECTED = "CREATE_CAMPAIGN_REJECTED";
const CREATE_CAMPAIGN_FUFILLED = "CREATE_CAMPAIGN_FUFILLED";
const CREATE_CAMPAIGN_PENDING = "CREATE_CAMPAIGN_PENDING";

const REMOVE_CAMPAIGN_REJECTED = "REMOVE_CAMPAIGN_REJECTED";
const REMOVE_CAMPAIGN_FUFILLED = "REMOVE_CAMPAIGN_FUFILLED";
const REMOVE_CAMPAIGN_PENDING = "REMOVE_CAMPAIGN_PENDING";

export default {
    GET_CAMPAIGNS_REJECTED,
    GET_CAMPAIGNS_FUFILLED,
    GET_CAMPAIGNS_PENDING,
    CREATE_CAMPAIGN_REJECTED,
    CREATE_CAMPAIGN_FUFILLED,
    CREATE_CAMPAIGN_PENDING,
    REMOVE_CAMPAIGN_REJECTED,
    REMOVE_CAMPAIGN_FUFILLED,
    REMOVE_CAMPAIGN_PENDING
};
