import React, { useState, useEffect } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { useUpdatedRef } from "lib/hooks";
import { TooltipDark } from "components/Tooltip";
import { isEqual } from "lodash";
import Button from "components/Button";
import CarretDown from "assets/images/icons/JSX/Down-arrow-icon";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";

import CheckboxSearch from "components/SearchBox/CheckboxSearchWithComponent";
import { useCallback } from "react";

import IconClose from "assets/images/icons/JSX/Icon-Close-Small";

export const SubFilterDropdown = React.memo(
    ({
        subFilters,
        subFilterApply,
        subFilterClear,
        disabled,
        subFilterSort,
        isActive = false,
        subFilterTitle = "",
        toggleHasChecked,
    }) => {
        const [open, toggleOpen] = useState(false);
        const [checked, updateChecked] = useState({});

        const dropdownRef = useHandleOutsideClickHook(() => toggleOpen(false));

        // Applied checked values
        const hasChecked = Object.values(checked).some((v) => v);

        const checkedSubfilters = subFilters.reduce(
            (cache, { id, checked = false }) => ({
                ...cache,
                [id]: checked,
            }),
            {},
        );

        // Not yet applied checked values
        const noneSelected = Object.keys(checked).every((key) => !checked[key]);

        const handleApply = useCallback(() => {
            const filterChecked = Object.keys(checked).filter((key) => checked[key]);

            if (filterChecked.length === 0) {
                subFilterClear();
                toggleHasChecked(false);
            } else {
                subFilterApply(checked);
                toggleHasChecked(true);
            }
            toggleOpen(false);
        }, [checked, subFilterApply, subFilterClear]);

        const handleClearSelected = (e) => {
            if (!isActive || noneSelected) {
                return;
            }
            e.stopPropagation();
            updateChecked({});
        };

        const handleClear = (e) => {
            if (!isActive) {
                return;
            }
            e.stopPropagation();
            updateChecked({});
            subFilterClear();
            toggleOpen(false);
            toggleHasChecked(false);
        };

        const BottomBar = () => (
            <div className={style.toggleBottom}>
                <span
                    className={classNames(style.clear, {
                        [style.disabled]: noneSelected,
                    })}
                    onClick={handleClearSelected}
                >
                    Clear Selected
                </span>
                <Button onClick={handleApply} className={style.apply} disabled={isEqual(checkedSubfilters, checked)}>
                    Apply
                </Button>
            </div>
        );

        useEffect(() => {
            updateChecked(checkedSubfilters);
        }, []);

        return (
            <>
                <div className={style.subFilter} ref={dropdownRef}>
                    <div
                        className={classNames(style.arrowDown, { [style.open]: open, [style.active]: isActive })}
                        onClick={(e) => {
                            if (disabled || !isActive) {
                                return;
                            }
                            e.stopPropagation();
                            toggleOpen(!open);
                        }}
                    >
                        <CarretDown />
                    </div>
                    <div className={classNames(style.dropdown, { [style.open]: open })}>
                        <CheckboxSearch
                            className={style.filterCheckboxes}
                            extraContent={
                                subFilterTitle ? <h4 className={style.subFilterTitle}>{subFilterTitle}</h4> : ""
                            }
                            search={{
                                placeholder: `Search`,
                                accessor: "label",
                            }}
                            options={subFilters.map(({ id, label }) => ({
                                id,
                                label,
                                name: id,
                                checked: checked[id],
                            }))}
                            onChange={({ id, checked }) => {
                                updateChecked((current) => ({ ...current, [id]: checked }));
                            }}
                            hideSelectAll
                            bottomBar={BottomBar}
                            sortFn={subFilterSort}
                        />
                    </div>
                </div>
                {hasChecked && (
                    <div
                        className={classNames(style.clearSelected, { [style.active]: isActive })}
                        onClick={handleClear}
                    >
                        <IconClose />
                    </div>
                )}
            </>
        );
    },
    isEqual,
);

const ToggleMulti = React.memo(({ options = [], active = "", className, disabled: componentDisabled, ref }) => {
    const [hasChecked, toggleHasChecked] = useState(false);
    const [activeRef, updateActiveRef] = useUpdatedRef();
    const { offsetWidth: activeWidth = 32, offsetLeft: activeLeft = 4 } = activeRef || {};

    const classes = classNames(style.toggleMulti, {
        [className]: className,
        [style.disabled]: componentDisabled,
    });

    return (
        <div className={classes} ref={ref}>
            <div
                className={style.switch}
                style={{ left: hasChecked ? activeLeft : activeLeft, width: hasChecked ? activeWidth : activeWidth }}
            ></div>
            {options.map(
                ({
                    id,
                    label,
                    icon: Icon,
                    iconStyleClass,
                    iconStyleObj,
                    tip,
                    onClick,
                    disabled,
                    className,
                    activeClass,
                    subFilters = [],
                    subFilterApply,
                    subFilterClear,
                    subFilterSort,
                    subFilterTitle,
                }) => {
                    const isActive = active === id;
                    const optionClasses = classNames(style.toggleOption, {
                        [style.active]: isActive,
                        [style.disabled]: disabled || componentDisabled,
                        [className]: className,
                        [activeClass]: activeClass && isActive,
                        [style.hasSubFilters]: subFilters.length > 0,
                    });
                    return (
                        <div
                            key={`${id}-${hasChecked}`}
                            ref={isActive ? updateActiveRef : null}
                            className={optionClasses}
                            onClick={disabled ? () => {} : onClick}
                            data-for={`toggleTip-${id}`}
                            data-tip={tip}
                            data-html
                        >
                            {Icon && (
                                <div className={`${style.icon} ${iconStyleClass}`} style={iconStyleObj}>
                                    <Icon />
                                </div>
                            )}
                            {typeof label === "string"
                                ? label && <span className={style.labelText}>{label}</span>
                                : label}
                            {tip && <TooltipDark id={`toggleTip-${id}`} proximity={8} delay />}
                            {subFilters.length > 0 && (
                                <SubFilterDropdown
                                    subFilters={subFilters}
                                    subFilterApply={subFilterApply}
                                    subFilterClear={subFilterClear}
                                    disabled={disabled}
                                    subFilterSort={subFilterSort}
                                    isActive={isActive}
                                    subFilterTitle={subFilterTitle}
                                    toggleHasChecked={toggleHasChecked}
                                />
                            )}
                        </div>
                    );
                },
            )}
        </div>
    );
}, isEqual);

export default ToggleMulti;
