import React from "react";
import style from "./style.module.scss";
import Checkmark from "../../assets/images/Form/JSX/checkmark";
import classnames from "classnames";
import { TooltipDark } from "components/Tooltip";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";

export default ({
    label,
    hideLabel = false,
    input,
    flipped,
    disabled,
    className = false,
    activatedContainerClassName = false,
    labelClassName = false,
    checkContainerClassName = false,
    checkClassName = false,
    large = false,
    autoChecked = false,
    subLabel,
    labelDetails,
    id,
    indent = false,
    tip = "",
    tipPosition = "top",
    checked = false,
    withCheckmark = true,
    checkedColor,
    withHelpIcon = true,
}) => {
    const isChecked = input.checked || autoChecked || checked;
    const checkedBackgroundColor =
        isChecked && !withCheckmark && checkedColor ? checkedColor : "";

    return (
        <label
            className={classnames(style.checkboxContainer, {
                [style.disabled]: disabled,
                [style.autoChecked]: autoChecked,
                [style.checked]: input.checked || checked,
                [style.flipped]: flipped,
                [style.large]: large,
                [style.indent]: indent,
                [className]: className,
                [activatedContainerClassName]: activatedContainerClassName,
            })}
        >
            <div
                className={classnames(style.checkbox, {
                    [style.centerAlign]: !!labelDetails,
                })}
            >
                <div
                    className={classnames(style.checkBoxCheckContainer, {
                        [checkContainerClassName]: checkContainerClassName,
                    })}
                >
                    <div
                        className={classnames(style.checkBox, {
                            [checkClassName]: checkClassName,
                        })}
                        style={
                            checkedBackgroundColor
                                ? { backgroundColor: checkedBackgroundColor }
                                : {}
                        }
                    >
                        {isChecked && withCheckmark && <Checkmark />}
                    </div>
                </div>
                <input
                    disabled={disabled}
                    className={style.checkboxInput}
                    type="checkbox"
                    {...input}
                    checked={input.checked || false}
                    {...(id ? { id } : {})}
                />
                <div
                    className={classnames(style.checkboxLabel, {
                        [style.hideLabel]: hideLabel,
                        [style.labelDetails]: labelDetails,
                        [labelClassName]: labelClassName,
                    })}
                >
                    {label}{" "}
                    {labelDetails && (
                        <span className={style.details}>{labelDetails}</span>
                    )}
                </div>
                {tip && id && withHelpIcon && (
                    <>
                        <div
                            data-tip={tip}
                            data-html
                            data-for={`checkboxTip-${id}`}
                            className={style.tooltip}
                        >
                            <HelpIcon />
                        </div>
                        <TooltipDark
                            id={`checkboxTip-${id}`}
                            position={tipPosition}
                        />
                    </>
                )}
            </div>
            {subLabel && subLabel}
        </label>
    );
};
