import types from "./types";
import { KpiAPI } from "lib/apiKpi";
import { MorphioAPI } from "../../../lib/api";

const decodeToken = (code) => (dispatch) => {
    return dispatch({
        type: types.DECODE_TOKEN,
        promise: MorphioAPI({
            method: "GET",
            url: `/user/share/dashboardLink/decode`,
            query: {
                code: code,
            },
        }),
    });
};

const getShareableLink = ({ accountId, startDate, endDate, userId, metrics, goals, include, linkName }) => (
    dispatch,
) => {
    return dispatch({
        type: types.GET_SHAREABLE_LINK,
        promise: MorphioAPI({
            method: "POST",
            url: `/user/share/dashboardLink`,
            data: {
                accountId,
                startDate,
                endDate,
                userId,
                metrics,
                goals,
                include,
                linkName,
            },
        }),
        meta: {
            goals,
            include,
            metrics,
            accountId,
            userId,
            linkName,
        },
    });
};

const getAllShareableLinks = ({ accountId, userId }) => (dispatch) => {
    return dispatch({
        type: types.GET_ALL_SHAREABLE_LINK,
        promise: MorphioAPI({
            method: "GET",
            url: `/user/share/dashboardLink`,
            query: {
                accountId,
                userId,
            },
        }),
    });
};

const removeShareableLink = ({ id }) => (dispatch) => {
    return dispatch({
        type: types.REMOVE_SHAREABLE_LINK,
        promise: MorphioAPI({
            method: "DELETE",
            url: `/user/share/dashboardLink`,
            data: {
                id,
            },
        }),
        meta: {
            id,
        },
    });
};

export const saveSharedLinkData = ({ id, scheduler, emails, subject, content, fromEmail }) => (
    dispatch
) => {
    return dispatch({
        type: types.UPDATE_SHAREABLE_LINK,
        promise: MorphioAPI({
            method: "PUT",
            url: `/user/share/dashboardLink`,
            data: {
                id,
                scheduler,
                emails,
                subject,
                content,
                fromEmail,
            },
        }),
        meta: {
            id,
            scheduler,
            emails,
            subject,
            content,
        },
    });
};

const getShareablePdf = ({ accountId, sharedUrl }) => {
    return {
        type: types.GET_SHAREABLE_PDF,
        promise: KpiAPI({
            method: "GET",
            url: `/account/${accountId}/reports`,
            isBlob: true,
            query: {
                sharedUrl,
            },
        }),
    };
};

export default {
    decodeToken,
    getShareableLink,
    getShareablePdf,
    removeShareableLink,
    getAllShareableLinks,
    saveSharedLinkData,
};
