import { divide } from "lib/metricCalculations";

export const adwordsMetrics = (metrics) => {
    const {
        cost = 0,
        impressions = 0,
        conversions = 0,
        clicks = 0,
        impressionShareOpportunities = 0,
        searchLostIsRankOpportunities = 0,
        searchLostIsBudgetOpportunities = 0,
        displayImpressionShareOpportunities = 0,
        displayLostIsRankOpportunities = 0,
        displayLostIsBudgetOpportunities = 0,
        displayNetworkImpressions = 0,
        searchNetworkImpressions = 0,
        views = 0,
        network,
        conversionValue = 0,
        conversionsValueByConversionDate = 0,
        phoneCalls = 0,
        phoneImpressions = 0,
        ...props
    } = metrics;

    const adjustedReport = {
        ...props,
        cost: parseFloat(cost),
        impressions: parseFloat(impressions),
        clicks: parseFloat(clicks),
        conversions: parseFloat(conversions),
        network,
        views: parseFloat(views),
        phoneCalls: parseInt(phoneCalls),
        phoneImpressions: parseInt(phoneImpressions),
        impressionShareOpportunities: parseFloat(impressionShareOpportunities),
        searchLostIsRankOpportunities: parseFloat(searchLostIsRankOpportunities),
        searchLostIsBudgetOpportunities: parseFloat(searchLostIsBudgetOpportunities),
        displayImpressionShareOpportunities: parseFloat(displayImpressionShareOpportunities),
        displayLostIsRankOpportunities: parseFloat(displayLostIsRankOpportunities),
        displayLostIsBudgetOpportunities: parseFloat(displayLostIsBudgetOpportunities),
        searchNetworkImpressions: parseFloat(searchNetworkImpressions),
        displayNetworkImpressions: parseFloat(displayNetworkImpressions),
        conversionValue: parseFloat(conversionValue),
        conversionsValueByConversionDate: parseFloat(conversionsValueByConversionDate),
    };

    adjustedReport.conversionRate = (adjustedReport.conversions / adjustedReport.clicks) * 100;
    adjustedReport.viewRate = (adjustedReport.views / adjustedReport.impressions) * 100;
    adjustedReport.ctr = (adjustedReport.clicks / adjustedReport.impressions) * 100;
    adjustedReport.cpl = adjustedReport.cost / adjustedReport.conversions;
    adjustedReport.cpv = adjustedReport.cost / adjustedReport.views;
    adjustedReport.cpc = adjustedReport.cost / adjustedReport.clicks;
    adjustedReport.cpm = (1000 * adjustedReport.cost) / adjustedReport.impressions;
    adjustedReport.impressionShare = divide(100 * searchNetworkImpressions, impressionShareOpportunities, 0);
    adjustedReport.displayImpressionShare = divide(
        100 * displayNetworkImpressions,
        displayImpressionShareOpportunities,
        0,
    );
    adjustedReport.searchLostIsRank = divide(100 * searchNetworkImpressions, searchLostIsRankOpportunities, 0);
    adjustedReport.searchLostIsBudget = divide(100 * searchNetworkImpressions, searchLostIsBudgetOpportunities, 0);
    adjustedReport.displayLostIsRank = divide(100 * displayNetworkImpressions, displayLostIsRankOpportunities, 0);
    adjustedReport.displayLostIsBudget = divide(100 * displayNetworkImpressions, displayLostIsBudgetOpportunities, 0);
    adjustedReport.roas = divide(adjustedReport.conversionValue, adjustedReport.cost, 0);
    adjustedReport.roasByTime = divide(adjustedReport.conversionsValueByConversionDate, adjustedReport.cost, 0);
    adjustedReport.phoneThroughRate = 100 * divide(adjustedReport.phoneCalls, adjustedReport.phoneImpressions, 0);

    return adjustedReport;
};

export const addImpressionShareOpp = (array) => {
    return array.map((item) => {
        return {
            ...item,
            searchNetworkImpressions:
                parseFloat(item.impressions) && item.network === "Search Network" ? parseFloat(item.impressions) : 0,
            displayNetworkImpressions:
                parseFloat(item.impressions) && item.network === "Display Network" ? parseFloat(item.impressions) : 0,
            impressionShareOpportunities:
                parseFloat(item.impressions) && item.network === "Search Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.impressionShare), 0)
                    : 0,
            searchLostIsRankOpportunities:
                parseFloat(item.impressions) && item.network === "Search Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.searchLostIsRank), 0)
                    : 0,
            searchLostIsBudgetOpportunities:
                parseFloat(item.impressions) && item.network === "Search Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.searchLostIsBudget), 0)
                    : 0,
            displayImpressionShareOpportunities:
                parseFloat(item.impressions) && item.network === "Display Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.displayImpressionShare), 0)
                    : 0,
            displayLostIsRankOpportunities:
                parseFloat(item.impressions) && item.network === "Display Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.displayLostIsRank), 0)
                    : 0,
            displayLostIsBudgetOpportunities:
                parseFloat(item.impressions) && item.network === "Display Network"
                    ? divide(parseFloat(item.impressions), parseFloat(item.displayLostIsBudget), 0)
                    : 0,
        };
    });
};

export const formatSearchTermMetrics = (metrics) => {
    const { cost = 0, impressions = 0, conversions = 0, clicks = 0, ...props } = metrics;

    const adjustedReport = {
        ...props,
        cost: parseFloat(cost),
        impressions: parseInt(impressions),
        clicks: parseInt(clicks),
        conversions: parseFloat(conversions),
    };
    adjustedReport.ctr = 100 * divide(adjustedReport.clicks, adjustedReport.impressions, 0);
    adjustedReport.cpl = divide(adjustedReport.cost, adjustedReport.conversions, 0);

    return adjustedReport;
};
