import React, { useContext } from "react";

const Context = React.createContext();

/**
 * Permissions hook
 * @param {object} permissions
 * @key platforms - Any platforms that are permitted
 * @key actions - Any actions that are permitted
 * @key message - Returns messages for permissions
 *
 * @returns {boolean} {boolean} Boolean - If permitted
 * @returns {array} {array} Boolean + Object if message is required
 *
 * @example
 * const isAuthorized = usePermissions({actions:["MY_ACTION"], platform:"platform"})
 *
 * const [isAuthorized, message] = usePermissions({actions:["MY_ACTION"], platform:"platform", message:true})
 */

export const usePermissions = ({ platforms, actions, message: getMessage = false }) => {
    const { isAllowed, isAuthorized, message } = useContext(Context);

    if (isAllowed(platforms) && isAuthorized(actions)) {
        return !getMessage ? true : [true, message(actions)];
    }

    return !getMessage ? false : [false, message(actions)];
};

/**
 * Permissions HOC
 * @param {Object} permissions
 * @key platforms - Any platforms that are permitted
 * @key actions - Any actions that are permitted
 * @param {Function} fallback component
 *
 * @returns {Boolean} Boolean - If permitted
 *
 * @example
 * withPermissions(
 *  {
 *   actions:"MY_ACTION",
 *   platform:"platform"
 *  },
 *  <Fallback />
 * )(Component)
 */
export const withPermissions = ({ platforms, actions }, UnauthorizedComponent = () => null) => (
    Component = () => null,
) => (props = {}) => (
    <Context.Consumer>
        {({ isAllowed, isAuthorized, message }) => {
            if (isAllowed(platforms) && isAuthorized(actions)) {
                return <Component {...props} />;
            }

            if (typeof UnauthorizedComponent === "object") {
                return UnauthorizedComponent;
            }

            if (typeof UnauthorizedComponent === "function") {
                return UnauthorizedComponent({
                    message: message(actions),
                    ...props,
                });
            }

            return null;
        }}
    </Context.Consumer>
);

/**
 * Permissions Component
 *
 * @param {Object} permissions
 * @key {object} platforms - Any platforms that are permitted
 * @key {object} actions - Any actions that are permitted
 * @key {function} fallback - Fallback component (optional)
 *
 * @returns {Boolean} {boolean} - If permitted
 *
 * @example
 * <Permissions
 *  actions={"MY_ACTION"}
 *  platforms={"platform"}
 *  fallback={()=><Fallback/>}
 * >
 *     <Component />
 * </Permissions>
 */
export const Permissions = ({ children, actions, platforms, fallback: Fallback }) => {
    return (
        <Context.Consumer>
            {({ isAuthorized, isAllowed, message }) => {
                if (isAllowed(platforms) && isAuthorized(actions)) {
                    return children;
                }

                if (typeof Fallback === "object") {
                    return Fallback;
                }

                if (typeof Fallback === "function") {
                    return Fallback(message(actions));
                }

                return null;
            }}
        </Context.Consumer>
    );
};

export default Context;
