import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import merge from "lodash/fp/merge";
import types from "./types";

export const defaultState = {
    members: {},
    membersLoading: false,
    deletePending: false,
};

export default createReducer(defaultState, {
    [types.TEAMMEMBER_SYNC]: (state, action) => ({
        ...state,
        members: {
            ...state.members,
            [action.payload.memberId]: {
                ...state.members[action.payload.memberId],
                ...action.payload.value,
            },
        },
    }),
    [types.INVITE_TEAMMEMBER](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                createLoading: true,
            }),
            success: (
                prevState,
                {
                    payload: {
                        user_id,
                        picture,
                        nickname,
                        loginCount,
                        lastLogin,
                        email,
                        app_metadata: { role, permissions } = {},
                    } = {},
                },
            ) => ({
                ...prevState,
                members: merge(prevState.members, {
                    [user_id]: {
                        loginCount: loginCount,
                        lastLogin,
                        email,
                        role,
                        picture,
                        nickname,
                        permissions,
                    },
                }),
            }),
            failure: (prevState, { payload: { response: { data: error } = {} } = {}, ...props }) => ({
                ...prevState,
                createError: String(error),
            }),
            finish: (prevState) => ({
                ...prevState,
                createLoading: false,
            }),
        });
    },
    [types.GET_TEAM](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                membersLoading: true,
            }),
            success: (prevState, { payload: members = [] }) => {
                let membersObj = {};
                members.forEach(
                    (
                        {
                            user_id,
                            loginCount,
                            lastLogin,
                            email,
                            picture,
                            app_metadata: { role, permissions } = {},
                            user_metadata: { nickname } = {},
                        },
                        index,
                    ) => {
                        membersObj[user_id] = {
                            loginCount: loginCount,
                            lastLogin,
                            email,
                            picture,
                            role,
                            permissions,
                            nickname,
                        };
                    },
                );

                return {
                    ...prevState,
                    members: membersObj,
                    membersLoading: false,
                };
            },
            failure: (prevState) => ({
                ...prevState,
                members: [],
                membersLoading: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                membersLoading: false,
            }),
        });
    },
    [types.RESEND_INVITATION](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                invitationPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                invitationPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                invitationPending: false,
            }),
        });
    },
    [types.UPDATE_TEAMMEMBER](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updatePending: true,
            }),
            success: (
                prevState,
                {
                    payload: {
                        user_id,
                        loginCount: loginCount,
                        lastLogin,
                        email,
                        app_metadata: { role, permissions } = {},
                    } = {},
                },
            ) => ({
                ...prevState,
                members: {
                    ...prevState.members,
                    [user_id]: {
                        ...prevState.members[user_id],
                        loginCount: loginCount,
                        email,
                        role,
                        permissions,
                        lastLogin,
                    },
                },
            }),
            failure: (prevState) => ({
                ...prevState,
                updatePending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatePending: false,
            }),
        });
    },
    [types.DELETE_TEAMMEMBER](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                deletePending: true,
            }),
            success: (prevState, { payload: { userId } = {} }) => {
                const { [userId]: _, ...currentMembers } = prevState.members;

                return {
                    ...prevState,
                    members: currentMembers,
                };
            },
            failure: (prevState) => ({
                ...prevState,
                deletePending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                deletePending: false,
            }),
        });
    },
});
