import { MorphioAPI } from "lib/api";
import * as types from "./types";

export const getPlans = () => ({
    type: types.ACTION_GET_PLANS,
    promise: MorphioAPI({
        method: "GET",
        url: "/plans"
    }).then(({ data }) => data)
});
