import { api } from "lib/bigQuery";
import { BIGQUERY_REPORT } from "./types";

const getBigQueryData = ({ dateRange, accountId, integrationId }) => (
    dispatch
) => {
    return dispatch({
        type: BIGQUERY_REPORT,
        promise: api.getReport({
            dateRange,
            accountId,
            integrationId,
        }),
    });
};

export default {
    getBigQueryData,
};
