import { default as classnames } from "classnames";
import DropDownContainer from "components/DropDown/Container";
import ThreeDots from "components/Loading/ThreeDots";
import Prompt from "components/Prompt";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import React, { useCallback, useState } from "react";
import FoundList from "../FoundList";
import style from "../style.module.scss";
import FilterContainer from "./FilterContainer";
import { getDataUI, getPlatformFilter, sortAccounts } from "./helpers";
import SearchBox from "components/SearchBox";
import { filterAccounts } from "features/ControlPanel/ducks/selectors";
import SearchZeroState from "./SearchZeroState";
import DropDownButton from "./DropDownButton";
import platformInfo from "lib/platformInfo";

const AccountSelector = ({
    selectedAccountLoading,
    accounts,
    currentAccountTitle,
    currentAccountLogo,
    availableTags,
    tags,
    platforms,
    contains,
    updateOverviewUi,
    userId,
}) => {
    const [dropDownIsOpen, toggleDropDown] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const ref = React.useRef();
    const selectedPlatform = getPlatformFilter(platforms);
    const allPlatforms = [...Object.keys(platformInfo), "slack"];

    const accountsFiltered = filterAccounts({
        accounts,
        overviewUi: {
            tags: tags,
            contains: contains,
            platforms: { [selectedPlatform.value || "all"]: true },
        },
        availableTags,
        search: searchInput,
    });

    const dispatchUpdateOverviewUi = ({ platformKey, listTags, containsValue, replace = false }) =>
        updateOverviewUi({
            ui: getDataUI({ listTags, containsValue, platformKey, allPlatforms }),
            userId,
            replace,
        });

    const onClearFilter = () => {
        setSearchInput("");
        dispatchUpdateOverviewUi({ listTags: [], containsValue: "any", platformKey: "all", replace: true });
    };

    const reference = useHandleOutsideClickHook(() => {
        toggleDropDown(false);
    });

    const handleToggleDropDown = useCallback(() => {
        if (!dropDownIsOpen) {
            //Wait until animation is finished and component is loaded
            setTimeout(() => ref.current && ref.current.focus(), 100);
        }

        toggleDropDown(!dropDownIsOpen);
    }, [dropDownIsOpen, ref]);

    return (
        <div className={style.accountSelectorContainer}>
            <div ref={reference} className={style.dropDownContainer}>
                <DropDownButton
                    title={currentAccountTitle}
                    img={currentAccountLogo}
                    dropDownIsOpen={dropDownIsOpen}
                    toggleDropDown={handleToggleDropDown}
                    isLoading={selectedAccountLoading}
                />

                <DropDownContainer
                    className={classnames(style.selectionDropDown, style.selectionAccount)}
                    isOpen={dropDownIsOpen}
                    anchor={"left"}
                    dropdownWidth="auto"
                >
                    <div className={style.listAccountContainer}>
                        <SearchBox
                            ref={ref}
                            className={style.searchTags}
                            placeholder={"Search Accounts"}
                            onChange={setSearchInput}
                            input={searchInput}
                        />

                        <div className={style.listContainer}>
                            {accountsFiltered.length > 0 ? (
                                <FoundList
                                    toggleDropDown={handleToggleDropDown}
                                    foundList={accountsFiltered.sort(sortAccounts)}
                                />
                            ) : (
                                <SearchZeroState onClick={onClearFilter} />
                            )}
                        </div>
                    </div>

                    <FilterContainer
                        availableTags={availableTags}
                        dispatchUpdateOverviewUi={dispatchUpdateOverviewUi}
                        tags={tags}
                        platforms={platforms}
                        contains={contains}
                        selectedPlatform={selectedPlatform}
                        allPlatforms={allPlatforms}
                    />
                </DropDownContainer>

                <Prompt
                    icon={ThreeDots}
                    className={style.loadingPrompt}
                    iconColor="green"
                    key="prompt"
                    width="auto"
                    message="Loading Account, One Moment..."
                    when={selectedAccountLoading}
                />
            </div>
        </div>
    );
};

export default AccountSelector;
