import { api } from "lib/searchConsole";
import types from "./types";

const getSearchConsoleTotalData = ({ dateRange, accountId, domain }) => (
    dispatch
) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_TOTALS,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["date"],
            domain
        })
    });
};

const getSearchConsoleQueryData = ({ dateRange, accountId, domain }) => (
    dispatch
) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_QUERIES,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["query"],
            domain
        })
    });
};

const getSearchConsolePagesData = ({ dateRange, accountId, domain }) => (
    dispatch
) => {
    return dispatch({
        type: types.SEARCH_CONSOLE_PAGES,
        promise: api.getReport({
            dateRange,
            accountId,
            dimensions: ["page"],
            domain
        })
    });
};

export default {
    getSearchConsoleTotalData,
    getSearchConsoleQueryData,
    getSearchConsolePagesData
};
