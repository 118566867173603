import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getSitePending } from "_redux/sites";
import { SimpleListItem } from "components/DropDown/ListItems";
import StarIcon from "assets/images/icons/JSX/Icon-Star";
import PropTypes from "prop-types";
import style from "./style.module.scss";

function FoundList({ toggleDropDown, foundList, history }) {
    const { location: { pathname } = {} } = history;
    const currentPath = pathname
        .split("/")
        .slice(3)
        .join("/");

    const selectAccountCb = useCallback(
        (accountId) => (e) => {
            getSitePending({ pending: true });
            toggleDropDown();
            history.push(`/dashboard/${accountId}/${currentPath}`);
        },
        [toggleDropDown, history],
    );

    return foundList.map(({ name, _id, favourite, isActive }) => (
        <SimpleListItem
            wrap={true}
            className={style.listItem}
            isActive={isActive}
            onClick={selectAccountCb(_id)}
            key={_id}
        >
            <span>{name}</span>
            {favourite && <StarIcon />}
        </SimpleListItem>
    ));
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({});

FoundList.propTypes = {
    selectAccount: PropTypes.func,
    toggleDropDown: PropTypes.func,
    foundList: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FoundList));
