import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path
            fill="#FFBC00"
            fillRule="evenodd"
            d="M7,0 C10.8659932,0 14,3.13400675 14,7 C14,10.8659932 10.8659932,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 Z M5.95815793,4.36815566 C5.54394437,4.36815566 5.20815793,4.70394209 5.20815793,5.11815566 L5.20815793,5.11815566 L5.20815793,8.86712572 C5.20815793,9.00453971 5.24591017,9.13931267 5.31729314,9.25673106 C5.53246622,9.61067091 5.99382342,9.7231636 6.34776327,9.50799051 L6.34776327,9.50799051 L9.43111799,7.63350548 C9.53378868,7.57108818 9.61996015,7.48491671 9.68237744,7.38224602 C9.89755053,7.02830617 9.78505784,6.56694898 9.43111799,6.3517759 L9.43111799,6.3517759 L6.34776327,4.47729086 C6.23034488,4.4059079 6.09557192,4.36815566 5.95815793,4.36815566 Z"
        />
    </svg>
);
