const GET_YESTERDAY_ANOMALIES_REJECTED = "GET_YESTERDAY_ANOMALIES_REJECTED";
const GET_YESTERDAY_ANOMALIES_FULFILLED = "GET_YESTERDAY_ANOMALIES_FULFILLED";
const GET_YESTERDAY_ANOMALIES_PENDING = "GET_YESTERDAY_ANOMALIES_PENDING";
const UPDATE_ANOMALY_NOTE_REJECTED = "UPDATE_ANOMALY_NOTE_REJECTED";
const UPDATE_ANOMALY_NOTE_FULFILLED = "UPDATE_ANOMALY_NOTE_FULFILLED";
const UPDATE_ANOMALY_NOTE_PENDING = "UPDATE_ANOMALY_NOTE_PENDING";
const UPDATE_ANOMALY_COMMENTS = "UPDATE_ANOMALY_COMMENTS";
const ANOMALY_DELETE_COMMENT = "ANOMALY_DELETE_COMMENT";
const ANOMALIES_FETCH = "ANOMALIES_FETCH";
const ANOMALY_COMMENT = "ANOMALY_COMMENT";
const ANOMALY_EDIT_COMMENT = "ANOMALY_EDIT_COMMENT";
const READ_ANOMALY = "READ_ANOMALY";
const INVESTIGATE_ANOMALY = "INVESTIGATE_ANOMALY";
const SET_ADDITIONAL_FILTERS = "SET_ADDITIONAL_FILTERS";
const TOGGLE_MARKETING_ANOMALY_VISIBILITY = "TOGGLE_MARKETING_ANOMALY_VISIBILITY";
const SET_DISMISS_TYPE_FILTERS = "SET_DISMISS_TYPE_FILTERS";
const SET_DISMISS_METRIC_FILTERS = "SET_DISMISS_METRIC_FILTERS";
const TOGGLE_DATES_CHANGING = "TOGGLE_DATES_CHANGING";

export default {
    GET_YESTERDAY_ANOMALIES_REJECTED,
    GET_YESTERDAY_ANOMALIES_FULFILLED,
    GET_YESTERDAY_ANOMALIES_PENDING,
    UPDATE_ANOMALY_NOTE_REJECTED,
    UPDATE_ANOMALY_NOTE_FULFILLED,
    UPDATE_ANOMALY_NOTE_PENDING,
    UPDATE_ANOMALY_COMMENTS,
    ANOMALY_DELETE_COMMENT,
    ANOMALIES_FETCH,
    ANOMALY_COMMENT,
    ANOMALY_EDIT_COMMENT,
    READ_ANOMALY,
    INVESTIGATE_ANOMALY,
    SET_ADDITIONAL_FILTERS,
    TOGGLE_MARKETING_ANOMALY_VISIBILITY,
    SET_DISMISS_TYPE_FILTERS,
    SET_DISMISS_METRIC_FILTERS,
    TOGGLE_DATES_CHANGING,
};
