import types from "./types";
import { KpiAPI } from "lib/apiKpi";
import moment from "moment";
import isEqual from "lodash/fp/isEqual";

const getOrganicRequest = async ({ accountId }) => {
    return KpiAPI({
        url: `account/${accountId}/urls/check`,
        method: "GET",
    });
};

const getOrganicUrlData = (accountId) => {
    return {
        type: types.GET_ORGANIC_DATA,
        promise: getOrganicRequest({ accountId }),
    };
};

const updateIgnorePage = async ({ accountId, pageKey, value, type }) => {
    return KpiAPI({
        url: `account/${accountId}/urls/check/ignore`,
        method: "PUT",
        data: {
            pageKey,
            value,
            type,
        },
    });
};

const ignorePage = ({ accountId, pageKey, value, type }) => {
    return {
        type: types.ADD_IGNORE_PAGE,
        promise: updateIgnorePage({ accountId, pageKey, value, type }),
        meta: {
            pageKey,
        },
    };
};

const getPlatformUrlRequest = async ({ accountId }) => {
    return KpiAPI({
        url: `account/${accountId}/urls/check`,
        query: {
            interval: "hourly",
        },
        method: "GET",
    });
};

const getPlatformUrlData = (accountId) => {
    return {
        type: types.GET_PLATFORM_DATA,
        promise: getPlatformUrlRequest({ accountId }),
    };
};

const getAnomalyRequest = async ({ accountId, startDate, endDate }) => {
    return KpiAPI({
        url: `account/${accountId}/urls/anomalies`,
        method: "GET",
        query: {
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
        },
    });
};

const getPageHealthAnomalyData = ({ accountId, startDate, endDate }) => {
    return {
        type: types.GET_ANOMALY_DATA,
        promise: getAnomalyRequest({ accountId, startDate, endDate }),
    };
};

const updateComments = ({ comments = [], accountId, id, date } = {}) => (dispatch, getState) => {
    const { healthcheck: { anomalies: { [id]: { comments: currentComments = {} } = {} } } = {} } = getState();

    if (isEqual(currentComments, comments)) return;

    return dispatch({
        type: types.PAGEHEALTH_UPDATE_COMMENT,
        comments,
        accountId,
        id,
        date,
    });
};

const deleteComment = ({ anomalyId, accountId, commentId, date, who }) => (dispatch) =>
    dispatch({
        type: types.PAGEHEALTH_DELETE_COMMENT,
        data: {
            anomalyId,
            who,
            date,
            accountId,
            commentId,
        },
        meta: {
            sync: true,
        },
    });

const editComment = ({ message, commentId, pagehealthId, accountId, mentions, who, date, plainTextMessage }) => (
    dispatch,
) =>
    dispatch({
        type: types.PAGEHEALTH_EDIT_COMMENT,
        data: {
            message,
            who,
            date,
            pagehealthId,
            accountId,
            commentId,
            mentions,
            plainTextMessage,
        },
        meta: {
            sync: true,
        },
    });

const comment = ({ message, pagehealthId, accountId, who, date, user, userId, mentions, plainTextMessage }) => (
    dispatch,
) =>
    dispatch({
        type: types.PAGEHEALTH_COMMENT,
        data: {
            mentions,
            plainTextMessage,
            message,
            pagehealthId,
            who,
            date,
            accountId,
            user,
            userId,
        },
        meta: {
            sync: true,
        },
    });

export default {
    getOrganicUrlData,
    getPlatformUrlData,
    getPageHealthAnomalyData,
    updateComments,
    deleteComment,
    comment,
    editComment,
    ignorePage,
};
