import React from "react";

export default ({ styles }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...styles}>
        <g
            id="Onboarding-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="3.0-Step-3-()"
                transform="translate(-1050.000000, -436.000000)"
                fill="#0366C1"
                fillRule="nonzero"
            >
                <g id="List" transform="translate(446.000000, 400.000000)">
                    <g id="2-copy" transform="translate(540.000000, 0.000000)">
                        <path
                            d="M100.381675,36 L67.6183246,36 C65.62,36 64,37.62 64,39.6183246 L64,72.3815707 C64,74.38 65.62,76 67.6183246,76 L100.381571,76 C102.38,76 104,74.38 104,72.3815707 L104,39.6183246 C104,37.62 102.38,36 100.381675,36 Z M76.3776963,70.5386387 C76.3776963,71.1202094 75.9062827,71.591623 75.324712,71.591623 L70.8424084,71.591623 C70.2608377,71.591623 69.7894241,71.1202094 69.7894241,70.5386387 L69.7894241,51.7490052 C69.7894241,51.1674346 70.2608377,50.6960209 70.8424084,50.6960209 L75.324712,50.6960209 C75.9062827,50.6960209 76.3776963,51.1674346 76.3776963,51.7490052 L76.3776963,70.5386387 Z M73.0835602,48.9248168 C70.7318325,48.9248168 68.8253403,47.0183246 68.8253403,44.6665969 C68.8253403,42.3148691 70.7318325,40.408377 73.0835602,40.408377 C75.435288,40.408377 77.3417801,42.3148691 77.3417801,44.6665969 C77.3417801,47.0183246 75.4353927,48.9248168 73.0835602,48.9248168 Z M99.8020971,70.6234555 C99.8020971,71.1581152 99.3685864,71.591623 98.8339267,71.591623 L94.0240838,71.591623 C93.4894241,71.591623 93.0559162,71.1581152 93.0559162,70.6234555 L93.0559162,61.8100524 C93.0559162,60.495288 93.4415707,56.0486911 89.62,56.0486911 C86.6557068,56.0486911 86.0544503,59.0922513 85.9337173,60.4581152 L85.9337173,70.6234555 C85.9337173,71.1581152 85.5003141,71.591623 84.9655497,71.591623 L80.3136126,71.591623 C79.7789529,71.591623 79.345445,71.1581152 79.345445,70.6234555 L79.345445,51.6641885 C79.345445,51.1295288 79.7789529,50.6960209 80.3136126,50.6960209 L84.9655497,50.6960209 C85.5002094,50.6960209 85.9337173,51.1295288 85.9337173,51.6641885 L85.9337173,53.3034555 C87.0328796,51.6539267 88.6663874,50.380733 92.1443979,50.380733 C99.846178,50.380733 99.8020971,57.5761257 99.8020971,61.5296335 L99.8020971,70.6234555 L99.8020971,70.6234555 Z"
                            id="Icon-LinkedIn"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
