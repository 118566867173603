const ADWORDS_ADGROUP_REPORT = "ADWORDS_ADGROUP_REPORT";
const ADWORDS_ADS_REPORT = "ADWORDS_ADS_REPORT";
const ADWORDS_CAMPAIGN_REPORT = "ADWORDS_CAMPAIGN_REPORT";
const ADWORDS_SEARCH_TERMS_REPORT = "ADWORDS_SEARCH_TERMS_REPORT";
const SET_SEARCH_TERMS_ADGROUP_FILTER = "SET_SEARCH_TERMS_ADGROUP_FILTER";
const SET_SEARCH_TERMS_CAMPAIGN_FILTER = "SET_SEARCH_TERMS_CAMPAIGN_FILTER";
const ADWORDS_CAMPAIGN_ASSET_REPORT = "ADWORDS_CAMPAIGN_ASSET_REPORT";
const RESET_CAMPAIGN_ASSET_REPORTS = "RESET_CAMPAIGN_ASSET_REPORTS";
const ADWORDS_ASSET_GROUP_REPORTS = "ADWORDS_ASSET_GROUP_REPORTS";
const RESET_ADWORDS_ASSET_GROUP_REPORTS = "RESET_ADWORDS_ASSET_GROUP_REPORTS";
const ADWORDS_ASSET_GROUP_ASSETS = "ADWORDS_ASSET_GROUP_ASSETS";
const RESET_ADWORDS_ASSET_GROUP_ASSETS = "RESET_ADWORDS_ASSET_GROUP_ASSETS";

export default {
    ADWORDS_ADGROUP_REPORT,
    ADWORDS_ADS_REPORT,
    ADWORDS_CAMPAIGN_REPORT,
    ADWORDS_SEARCH_TERMS_REPORT,
    SET_SEARCH_TERMS_ADGROUP_FILTER,
    SET_SEARCH_TERMS_CAMPAIGN_FILTER,
    ADWORDS_CAMPAIGN_ASSET_REPORT,
    RESET_CAMPAIGN_ASSET_REPORTS,
    ADWORDS_ASSET_GROUP_REPORTS,
    RESET_ADWORDS_ASSET_GROUP_REPORTS,
    ADWORDS_ASSET_GROUP_ASSETS,
    RESET_ADWORDS_ASSET_GROUP_ASSETS,
};
