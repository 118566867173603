import style from "./style.module.scss";
import { toPrettyNumber } from "lib/metricCalculations";
import React from "react";

const CustomTooltip = ({ value, benchmarkMin, benchmarkMax, metric }) => {
    return (
        <div className={style.toolTipContainer}>
            <div className={style.info}>
                <div className={style.numberContainer}>
                    <p
                        className={style.circle}
                        style={{
                            borderColor: "#0671EE",
                        }}
                    />
                    <p className={style.number}>
                        {" "}
                        {toPrettyNumber(value, metric)}
                    </p>
                </div>
                <p className={style.accountName}>Your Account</p>
                <div className={style.numberContainer}>
                    <p
                        className={style.circle}
                        style={{
                            borderColor: "#6A80A7",
                        }}
                    />
                    <p className={style.number}>
                        {toPrettyNumber(benchmarkMin, metric)}&nbsp;-&nbsp;
                        {toPrettyNumber(benchmarkMax, metric)}
                    </p>
                </div>
                <p className={style.accountName}>Benchmark</p>
            </div>
        </div>
    );
};

export default CustomTooltip;
