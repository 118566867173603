import React, { useState } from "react";
import styles from "../style.module.scss";

const determineContainer = (submitFailed, error, type) => {
    if (submitFailed && error) {
        return styles.inputError;
    }
    return styles.container;
};

export default React.forwardRef(
    (
        {
            input = {},
            meta: { error, submitFailed, warning, touched, active } = {},
            type = "text",
            name = input ? input.name : "input",
            label = input ? input.name : "input",
            labelHidden = false,
            placeholder = false,
            disabled = false,
            className = null,
            prefix = null,
            darkBorder = false,
            maxRows = 8,
            minRows = 1,
            autoSize = true,
        } = {},
        ref,
    ) => {
        const [rows, changeRows] = useState(minRows);
        const { onChange, ...inputRest } = input;

        const handleChange = (e) => {
            const lineHeight = 18;
            const verticalPadding = 20;
            const currentRows = Math.round((e.target.scrollHeight - verticalPadding) / lineHeight);

            if (currentRows >= maxRows) {
                e.target.rows = maxRows;
                e.target.scrollTop = e.target.scrollHeight;
            }

            changeRows(currentRows < maxRows ? currentRows : maxRows);
            onChange(e);
        };

        return (
            <div
                className={`${className} ${touched && error && styles.inputError} ${determineContainer(
                    submitFailed,
                    error,
                    type,
                )}`}
            >
                <label htmlFor={name} className={labelHidden ? styles.labelHidden : styles.label}>
                    {label}
                </label>
                <div className={styles.inputContainer}>
                    <textarea
                        {...inputRest}
                        rows={rows}
                        onChange={handleChange}
                        ref={ref}
                        id={name}
                        placeholder={placeholder !== false ? placeholder : null}
                        className={`${styles.input} ${styles.textarea}`}
                        disabled={disabled}
                        style={{ resize: !autoSize ? "none" : "vertical" }}
                    ></textarea>
                </div>
                {touched &&
                    ((error && (
                        <div className={`${styles.inlineErrorMessage}`}>
                            <span>{Array.isArray(error) ? error.join(" ") : error}</span>
                        </div>
                    )) ||
                        (warning && <span>{warning}</span>))}
            </div>
        );
    },
);
