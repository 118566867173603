import React from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { noop } from "../../lib/utility";
import Button from "../Button";
import merge from "lodash/fp/merge";
import NewIcon from "assets/images/Dashboard/New.svg";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import { TooltipDark } from "components/Tooltip";
import TitleTag from "components/TitleTag";
import WatchButton from "components/YoutubeVideo/WatchButton";
import moment from "moment";
import { isOnDefaultDomain } from "components/Branding";

const sectionOptions = {
    headingContainer: {
        style: style.heading,
    },
    headingTextContainer: {
        style: style.headingTextContainer,
    },
    titleContainer: {
        style: style.title,
    },
    titleContainerSmall: {
        style: style.titleSmall,
    },
    iconContainer: {
        style: style.icon,
        onClick: noop,
    },
    demo: {
        style: style.demo,
    },
    tooltip: {
        style: style.tooltip,
    },
    subtiteAndVideoWrapper: {
        style: style.subtiteAndVideoWrapper,
    },
    watchWrapper: {
        style: style.watchWrapper,
    },
    subtitle: {
        style: style.subtitle,
    },
    lightTheme: {
        style: style.lightTheme,
    },
    title: {
        style: style.title,
    },
    childrenContainer: {
        style: style.childrenContainer,
    },
    autoWrap: {
        style: style.autoWrap,
    },
    subtitleContainer: {
        style: style.subtitleContainer,
    },
};

export const SectionHeading = ({
    title,
    subtitle: Subtitle,
    icon: Icon,
    iconBackgroundColor = "",
    smallHeader = false,
    children,
    options = {},
    style = {},
    isNew,
    tooltip: TooltipObj = {},
    endTag,
    lightTitle,
    video,
    className,
    childrenClassName,
    titleWidth = "auto",
    SubtitleComponent,
    light = false,
    autoWrap = false,
    ...props
}) => {
    const {
        tip: tooltip = "",
        position: tooltipPosition = "top",
        id: tooltipId = "sectionHeadingTooltip",
        proximity = 8,
    } = TooltipObj;

    const opts = merge(sectionOptions, options);
    return (
        <div
            className={classNames(opts.headingContainer.style, {
                [className]: className,
                [opts.lightTheme.style]: light,
            })}
            style={style}
            {...props}
        >
            {Icon && (
                <div
                    className={`${opts.iconContainer.style} ${iconBackgroundColor}`}
                    onClick={opts.iconContainer.onClick}
                >
                    <Icon />
                </div>
            )}
            {isNew && <img src={NewIcon} style={{ marginRight: 8 }} />}
            <div
                className={classNames(opts.headingTextContainer.style, {
                    [opts.titleContainerSmall.style]: smallHeader,
                })}
            >
                <div className={opts.titleContainer.style}>
                    <h2 className={opts.title.style}>
                        {title} {TooltipObj && typeof TooltipObj === "function" && <TooltipObj />}
                        {tooltip && (
                            <span
                                className={opts.tooltip.style}
                                data-html
                                data-for={tooltipId}
                                data-tip={`<span style="display:block;max-width:18rem;">${tooltip}</span>`}
                            >
                                <HelpIcon />
                            </span>
                        )}
                        {endTag && <TitleTag tag={endTag} />}
                        {lightTitle}
                    </h2>
                    {typeof Subtitle === "function" ? (
                        <Subtitle />
                    ) : (
                        <div className={opts.subtiteAndVideoWrapper.style}>
                            <p className={opts.subtitle.style} style={{ width: titleWidth }}>
                                {Subtitle}
                                {video && (
                                    <span className={opts.watchWrapper.style}>
                                        <WatchButton title={video.title} videoId={video.videoId} />
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </div>

                <div
                    className={classNames(opts.childrenContainer.style, {
                        [opts.autoWrap.style]: autoWrap,
                        [childrenClassName]: childrenClassName,
                    })}
                >
                    {children}
                </div>
            </div>

            {tooltip && <TooltipDark id={tooltipId} proximity={proximity} position={tooltipPosition} />}
        </div>
    );
};

export const SectionHeadingButton = ({ title = "", subtitle = "", onClick, buttonText }) => (
    <SectionHeading
        title={title}
        subtitle={subtitle}
        options={{
            headingTextContainer: {
                style: style.headingTextButtonContainer,
            },
            headingContainer: {
                style: style.headingButtonContainer,
            },
        }}
    >
        <Button className={style.button} onClick={onClick}>
            {buttonText}
        </Button>
    </SectionHeading>
);

export const InfoTooltip = ({ additional, title, description, resourceLink }) => (
    <div className={style.infoTooltip}>
        {additional}
        <HelpIcon />
        <div className={style.infoTooltipBox}>
            {title && <h4>{title}</h4>}
            <p>{description}</p>
            {resourceLink && (
                <div className={style.resources}>
                    <p>
                        <strong>Visit our resource guide to learn more.</strong>
                    </p>
                    <Button small onClick={() => window.open(resourceLink, "_blank")}>
                        Resource Guide
                    </Button>
                </div>
            )}
        </div>
    </div>
);

export const SectionHeadingInfoBar = ({
    updated,
    updatedShowTooltip = true,
    updatedTime,
    generated,
    generatedList = [],
    nextUpdate,
    description,
    subTitle,
    title,
    resourceLink,
    light = false,
    video: { title: videoTitle = "", id: videoId = "" } = {},
}) => {
    const updatedDiff = Math.abs(moment(updated).diff(moment(), "days"));

    return (
        <div className={classNames(style.infoBar, { [style.lightTheme]: light })}>
            {subTitle && <div>{subTitle}</div>}
            {updated && (
                <React.Fragment>
                    <div className={style.infoBarItem}>
                        <div
                            data-for="infoBar-updated"
                            data-tip={
                                updatedShowTooltip
                                    ? moment(updated).isSame(moment(), "day") || !moment(updated).isValid()
                                        ? "Updated today"
                                        : `${updatedDiff} ${updatedDiff === 1 ? "day" : "days"} ago`
                                    : null
                            }
                        >
                            <strong>Updated:</strong>{" "}
                            {moment(updated).isValid() ? moment(updated).format("MMM D, YYYY") : updated}
                            {updatedTime && ` at ${moment(updatedTime).format("h:mm a")}`}
                        </div>
                    </div>
                    <TooltipDark proximity={4} id="infoBar-updated" />
                </React.Fragment>
            )}
            {!generatedList.length && generated && (
                <React.Fragment>
                    <div className={style.infoBarItem}>
                        <div
                            data-for="infoBar-generated"
                            data-tip={
                                nextUpdate && moment(nextUpdate).isValid()
                                    ? `Next update on ${moment(nextUpdate).format("MMM D, YYYY")}`
                                    : nextUpdate || ""
                            }
                        >
                            <strong>Generated:</strong> {generated}
                        </div>
                    </div>
                    {nextUpdate && <TooltipDark proximity={4} id="infoBar-generated" />}
                </React.Fragment>
            )}
            {generatedList.length > 0 && (
                <React.Fragment>
                    <div className={style.infoBarItem}>
                        <strong>Generated:</strong>{" "}
                        <div className={style.generatedList}>
                            {generatedList.map(({ period = "", tip = "" }, index) => {
                                return (
                                    <React.Fragment key={`${period}_${index}`}>
                                        <span data-html data-for={`infoBar-generatedList`} data-tip={tip}>
                                            {index !== 0 && ", "}
                                            {period}
                                        </span>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                    <TooltipDark position="top" proximity={4} id={`infoBar-generatedList`} />
                </React.Fragment>
            )}
            {isOnDefaultDomain() && description && (
                <div className={`${style.infoBarItem} ${style.tooltipItem}`}>
                    <InfoTooltip
                        additional={<strong className={style.infoText}>Info</strong>}
                        title={title}
                        description={description}
                        resourceLink={resourceLink}
                    />
                </div>
            )}
            {isOnDefaultDomain() && videoId && videoTitle && (
                <span className={style.infoBarItem}>
                    <WatchButton light={light} title={videoTitle} videoId={videoId} />
                </span>
            )}
        </div>
    );
};
