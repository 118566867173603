import { createSelector } from "reselect";
import { makeSelectNumberOfAccounts } from "features/ControlPanel/ducks/selectors";
import { selectPlans } from "features/AccountSettings/PlansAndBilling/_ducks/selectors";
import {
    makeSelectType,
    makeSelectSubscription,
    selectPaymentSource,
    makeSelectSubscriptionType,
} from "_redux/users/selectors";
import { planList } from "lib/plans";
import isEmpty from "lodash/isEmpty";

export const selectPlanOptions = (state) => state.planOptions;

export const makeSelectPlanOptionsOpen = () => createSelector(selectPlanOptions, ({ isOpen }) => isOpen);
export const makeSelectPlanOptionsStep = () => createSelector(selectPlanOptions, ({ step }) => step);

export const selectCouponCode = createSelector(selectPlanOptions, ({ couponCode = {} }) => couponCode);

export const selectPlanInfo = createSelector(
    [
        selectPlans,
        makeSelectNumberOfAccounts(),
        makeSelectSubscriptionType(),
        makeSelectType(),
        makeSelectSubscription(),
        selectPaymentSource,
    ],
    (plans, numberOfAccounts, subscriptionType) => {
        const { regularMonthly = [] } = planList(subscriptionType);
        const planInfo = {
            monthly: regularMonthly.reduce((cache, { plan, ...props }) => {
                const planData = plans[plan] || {};
                const { numberOfViews: viewNum = 0, price } = planData;
                const viewNumber = parseFloat(viewNum);
                return {
                    ...cache,
                    [plan]: {
                        ...planData,
                        ...props,
                        numberOfViews: viewNumber,
                        disabled: numberOfAccounts > viewNumber,
                        originalPrice: price,
                        price: price * 1,
                        saving: 0,
                    },
                };
            }, {}),
            yearly: regularMonthly.reduce((cache, { plan, ...props }) => {
                const planData = plans[plan] || {};
                const { numberOfViews: viewNum = 0, price } = planData;
                const viewNumber = parseFloat(viewNum);

                return {
                    ...cache,
                    [plan]: {
                        ...planData,
                        ...props,
                        numberOfViews: viewNumber,
                        disabled: numberOfAccounts > viewNumber,
                        originalPrice: price,
                        price: parseInt(price * 0.7),
                        saving: parseInt(price * 0.3),
                    },
                };
            }, {}),
        };

        return planInfo;
    },
);

export const selectHasMaxPlan = createSelector(
    [makeSelectSubscription(), selectPaymentSource],
    ({ exists: planExists, numberOfViews: numberOfViewsString }, paymentSource) => {
        return !isEmpty(paymentSource) && planExists && parseFloat(numberOfViewsString) >= 20;
    },
);
