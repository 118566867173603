import React from "react";
import ColourCard from "../ColourCard";
import Avatar from "material-ui/Avatar";
import Button from "../Button";
import style from "./style.module.scss";
import TipIcon from "../../assets/images/icons/Icon-Tip.svg";
import FontIcon from "material-ui/FontIcon";
import { compose, withHandlers, withState, pure } from "recompose";

const enhance = compose(
    withState("prompt", "togglePrompt", { isOpen: true }),
    withHandlers({
        handlePrompt: ({ togglePrompt }) => (value) => togglePrompt(value),
    }),
    pure,
);

export default enhance(
    ({ title, subtitle, buttons = [], handlePrompt, prompt, className }) =>
        prompt.isOpen && (
            <ColourCard className={`${style.competitorsHelp} ${className && className}`}>
                <Avatar className={style.competitorsHelpAvatar} size={48} src={TipIcon} />
                <h3>{title}</h3>
                <p>{subtitle}</p>
                <div className={style.buttons}>
                    {buttons.map(({ label, onClick }, index) => (
                        <Button medium key={index} onClick={onClick} className={style.competitorsHelpButton}>
                            {label}
                        </Button>
                    ))}
                </div>
                <Button onClick={() => handlePrompt({ isOpen: false })} className={style.closeButton}>
                    <FontIcon className="material-icons">close</FontIcon>
                </Button>
            </ColourCard>
        ),
);
