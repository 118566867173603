import { createSelector } from "reselect";
import { defaultState } from "./reducers";
import { formatNotificationAlerts } from "./helpers";

const selectNotifications = ({ notifications = defaultState } = {}) => notifications;

export const selectNotificationAlerts = createSelector([selectNotifications], ({ notifications }) =>
    formatNotificationAlerts(notifications),
);

export const selectLastSeen = createSelector([selectNotifications], ({ lastSeen }) => lastSeen);
