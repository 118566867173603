export default (defaultState, type = "CLEAR_DATA") => (reducer) => (
    state,
    action
) => {
    switch (action.type) {
        case type:
            return { ...state, ...defaultState };

        default:
            return reducer(state, action);
    }
};
