import _ from "lodash/fp";
import moment from "moment";

export const withDates = _.curry(({ start, end }, o) => {
    const month = moment(end, "YYYY-MM-DD");
    const currentDate = moment();

    const endOfMonth = moment(month).endOf("month");
    const startOfMonth = moment(month).startOf("month");
    const daysInMonth = month.daysInMonth();
    const isCurrentMonth =
        moment(month).isSame(currentDate, "year") &&
        moment(month).isSame(currentDate, "month");

    const startDate = moment(start).startOf("day");
    const endDate = moment(end).endOf("day");

    const daysIntoMonth = isCurrentMonth
        ? currentDate.diff(startOfMonth, "days") + 1
        : daysInMonth;

    const numberOfDays = moment(endDate).diff(startDate, "days");

    const prevPeriodEndDate = moment(startDate).subtract(1, "days");
    const prevPeriodStartDate = moment(prevPeriodEndDate).subtract(
        numberOfDays,
        "days"
    );

    const startDateYearTwo = moment(startDate, "YYYY-MM-DD").subtract(2, "y");
    const endDateYearTwo = moment(endDate).subtract(2, "y");
    const startDateYearOne = moment(startDate).subtract(1, "y");
    const endDateYearOne = moment(endDate).subtract(1, "y");
    const startDateMonthOne = moment(startDate).subtract(1, "months");
    const endDateMonthOne = moment(endDate)
        .subtract(1, "months")
        .endOf("month");

    return {
        ...o,
        date: {
            numberOfDays,
            prevPeriodEndDate,
            prevPeriodStartDate,
            endOfMonth,
            startOfMonth,
            daysInMonth,
            daysIntoMonth,
            startDate,
            endDate,
            startDateYearTwo,
            endDateYearTwo,
            startDateYearOne,
            endDateYearOne,
            startDateMonthOne,
            endDateMonthOne
        }
    };
});
