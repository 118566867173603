import React, { useEffect } from "react";
import { history } from "_redux";
import style from "./style.module.scss";
import AccountSelection from "./AccountSelection/container";
import AccountTags from "features/ControlPanel/AccountTags";
import { getParentMetadata } from "_redux/users/async";
import { ReactComponent as ClientOverviewIcon } from "assets/images/icons/Icon-PerformanceOverview.svg";
import { IconImage } from "components/Icon";
import { TooltipDark } from "components/Tooltip";
import FeatureSelection from "./FeatureSelection";
import { BrandLogo } from "components/Branding";

export default ({
    hasProperAccountId,
    displayLogo,
    accountId,
    tags,
    updateOverviewTagsAvailable,
    parentUserId,
    userRole,
    userNickName = "",
} = {}) => {
    if (!hasProperAccountId || displayLogo) {
        const [first = "dear user", last] = (userNickName + "").split(" ");
        return (
            <div onClick={() => history.push("/dashboard")} className={style.titleContainer}>
                <BrandLogo />
                <div className={style.greetingTitle}>
                    {new Date().getHours() < 12
                        ? `Good Morning, ${first}!`
                        : new Date().getHours() < 18
                        ? `Good Afternoon, ${first}!`
                        : `Good Evening, ${first}!`}
                </div>
            </div>
        );
    }

    const getOverViewTag = async () => {
        const { controlPanelSettings: { tags = {} } = {} } = await getParentMetadata({ parentUserId });
        updateOverviewTagsAvailable(tags);
    };

    useEffect(() => {
        getOverViewTag();
    }, []);

    const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);

    return (
        <div className={style.titleContainer}>
            <div
                className={style.icon}
                data-tip="Performance Overview"
                data-for={`tip-performance-overview-breadcrumb`}
                onClick={() => history.push(`/dashboard`)}
            >
                <IconImage icon={ClientOverviewIcon} alt={"Performance Overview Icon"} />
                <TooltipDark id={`tip-performance-overview-breadcrumb`} position="bottom" delay />
            </div>
            <span className={style.slash}>/</span>
            <AccountSelection />
            {accountId && !isMobile && <FeatureSelection selectedSite={accountId} userRole={userRole} />}
            {(tags || []).length && !isMobile ? (
                <div className={style.accountTagsWrapper}>
                    <AccountTags
                        className={style.tags}
                        manageButtonClassName={style.manageIcon}
                        tags={tags}
                        accountId={accountId}
                        tooltipPosition="bottom"
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
