import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { SavePrompt } from "components/Prompt";
import Checkmark from "assets/images/Form/JSX/checkmark";
import { usePrompt } from "./PromptContext";

const Prompt = ({ message }) => {
    const [visible, setVisible] = useState(true);
    const { setPromptMessage } = usePrompt();

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            setPromptMessage(null); // Reset the message after timeout
        }, 2000);

        return () => clearTimeout(timer);
    }, [setPromptMessage]);

    if (!visible) {
        return null;
    }

    return (
        <SavePrompt when={visible} width="340px" offSet className={style.saveBar}>
            <div className={style.icon}>
                <Checkmark />
            </div>
            <p className={style.savedText}>{message}</p>
        </SavePrompt>
    );
};

export default Prompt;
