import { KpiAPI } from "../apiKpi";
import { requestMetricData } from "../../_redux/kpi/async";
import { withDates } from "lib/tableSchema";

export function getAccounts({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/linkedin/accounts`,
        query: {
            userId,
            connectionId
        }
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "linkedin",
                integrations: {
                    linkedin: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next
                        }),
                        {}
                    )
                }
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getCampaigns({ userId, connectionId, linkedinId }) {
    return KpiAPI({
        method: "GET",
        url: `/linkedin/campaigns`,
        query: {
            userId,
            connectionId,
            linkedinId
        }
    })
        .then(({ data }) => {
            return {
                connectionId,
                linkedinId,
                campaigns: {
                    linkedin: data
                }
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getReport({ dateRange, accountId }) {
    return requestMetricData({
        scope: ["linkedin"],
        accountId,
        metrics: [],
        dateRange: dateRange
    });
}

export default {
    getAccounts,
    getCampaigns,
    getReport
};
