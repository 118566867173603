import { connect } from "react-redux";
import Provider from "./Provider";
import {
    makeSelectPermitted,
    makeSelectIntersected,
    makeSelectReasons
} from "./selectors";
import { makeSelectRole } from "_redux/users/selectors";
import {
    makeSelectIsConversionMeasurement,
    makeSelectHasPlatforms
} from "_redux/sites/selectors";
import { createStructuredSelector } from "reselect";

const mapStateToProps = (state) =>
    createStructuredSelector({
        role: makeSelectRole(),
        permissions: makeSelectPermitted(),
        intersection: makeSelectIntersected(),
        reasons: makeSelectReasons(),
        platforms: makeSelectHasPlatforms(),
        isEcommerce: makeSelectIsConversionMeasurement("ecommerce")
    });

export default connect(mapStateToProps)(Provider);
