import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    isOpen: false,
    step: "options",
    couponCode: {},
};

export default clearReducer(
    defaultState,
    types.CLEAR_PLAN_OPTIONS,
)(
    createReducer(defaultState, {
        [types.TOGGLE_PLAN_OPTIONS](state, action) {
            return {
                ...state,
                isOpen: action.isOpen,
                step: action.step || "options",
            };
        },
        [types.FETCH_PROMO_DETAILS](state, action) {
            return handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    couponCode: {
                        loading: true,
                    },
                }),
                success: (prevState, { payload = {} }) => ({
                    ...prevState,
                    couponCode: {
                        loading: false,
                        ...payload?.data,
                    },
                }),
                failure: (prevState, { payload = {} }) => ({
                    ...prevState,
                    couponCode: {
                        loading: false,
                        error: payload?.response?.data?.message,
                    },
                }),
            });
        },
        [types.SET_PROMO_DETAILS](state, action) {
            return {
                ...state,
                couponCode: action.details,
            };
        },
    }),
);
