import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import Component from ".";
import { makeSelectBudgetPlannerAverageMetrics } from "../ducks/selectors";

export const BUDGET_PLANNER_FORM = "BudgetPlanner";

const mapStateToProps = (state) => {
    const { budgetType, numberOfMonths, metric } = getFormValues(BUDGET_PLANNER_FORM)(state) || {};
    const initMetricsAverages = makeSelectBudgetPlannerAverageMetrics()(state);
    return {
        budgetType,
        numberOfMonths,
        metric,
        initMetricsAverages,
    };
};

export default connect(mapStateToProps, null)(Component);
