import React from "react";
import Button from "components/Button";
import style from "./style.module.scss";
import classNames from "classnames";
import PencilIcon from "assets/images/icons/JSX/Icon-Pencil";
import { CSSTransition } from "react-transition-group";
import { IconImage } from "components/Icon";

const iconClasses = (color) =>
    ({
        red: style.red,
        green: style.green,
        default: "",
    }[color || "default"]);

const PromptWrapper = ({ when, children, className, width, offSet = true }) => {
    if (!when) {
        return null;
    }

    return (
        <div
            style={{ width }}
            className={classNames(style.promptContainer, { [className]: className, [style.offSet]: offSet })}
        >
            {children}
        </div>
    );
};

export const SavePrompt = ({ when, className, width = "584px", children, offSet = true }) => (
    <PromptWrapper when={when} width={width} offSet={offSet}>
        <div className={classNames(style.savePrompt, { [className]: className })}>{children}</div>
    </PromptWrapper>
);

export default ({
    message,
    buttonText = "Save",
    onClick,
    when,
    className,
    iconColor,
    width = "584px",
    icon: Icon = PencilIcon,
}) => (
    <PromptWrapper when={when} width={width} className={className}>
        <div className={style.defaultPrompt}>
            {Icon && (
                <div className={`${style.icon} ${iconColor ? iconClasses(iconColor) : ""}`}>
                    <IconImage icon={Icon} />
                </div>
            )}
            <p>{message}</p>
            {onClick && (
                <Button onClick={onClick} secondary medium>
                    {buttonText}
                </Button>
            )}
        </div>
    </PromptWrapper>
);
