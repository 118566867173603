import React, { useState } from "react";
import style from "./style.module.scss";
import YouTube from "react-youtube";
import Button from "components/Button";
import Upgrade from "assets/images/Menu/Icon-Upgrade.svg";
import { ReactComponent as UpgradeIcon } from "assets/images/Menu/Icon-Upgrade.svg";
import { mixpanel } from "components/Mixpanel";

export default ({ videoId, open, toggleModal, feature, togglePlanOptions }) => {
    return (
        open && (
            <div className={style.videoModal}>
                <div className={`Close-Large ${style.close}`} onClick={toggleModal}>
                    <div>
                        <span>close</span>
                    </div>
                </div>
                <UpgradeContainer
                    feature={feature}
                    togglePlanOptions={togglePlanOptions}
                    videoId={videoId}
                    toggleModal={toggleModal}
                />
            </div>
        )
    );
};

export const UpgradeContainer = ({ videoId, feature, togglePlanOptions, toggleModal }) => {
    const [player, updatePlayer] = useState(null);

    const playerOpts = {
        modestbranding: 1,
        disablekb: 1,
        playerVars: { rel: 0, showinfo: 0, ecver: 2 },
    };

    return (
        <div className={style.modalContent}>
            {videoId && (
                <div className={style.iframeWrapper}>
                    <YouTube className={style.modalIframe} videoId={videoId} opts={playerOpts} />
                </div>
            )}
            <div className={style.modalContentText}>
                <h1>{feature}</h1>
                <p>
                    You do not have access to this feature under your plan. Please upgrade to one of the{" "}
                    <span>Pro Plans</span> in order to activate this feature.
                </p>
                <Button
                    icon={UpgradeIcon}
                    vibrantBlue
                    onClick={() => {
                        if (player) {
                            player.pauseVideo();
                        }
                        mixpanel.track("Upgrade", {
                            Location: "Upgrade Modal",
                            "Is Freemium": true,
                        });
                        toggleModal();
                        togglePlanOptions(true);
                    }}
                >
                    Upgrade Now
                </Button>
            </div>
        </div>
    );
};
