import React, { useState, useReducer, useCallback, useEffect } from "react";
import style from "./style.module.scss";
import RenderInput from "components/RenderInput";
import SearchIcon from "assets/images/icons/JSX/Icon-Search";
import CloseIcon from "assets/images/icons/JSX/Icon-Close-Small";
import get from "lodash/get";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { useMemoCompare } from "./helpers.js";
import isEmpty from "lodash/isEmpty";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import CarretDown from "assets/images/icons/JSX/Down-arrow-icon";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";

const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_FILTER_INPUT":
            return {
                ...state,
                input: action.input ? action.input : "",
            };
        case "CHANGE_FILTER":
            return {
                ...state,
                filter: action.filter,
            };
        case "INIT_FILTERS":
            return {
                ...state,
                filters: action.filters,
                filter: Object.keys(action.filters)[0],
            };
        case "CLEAR_FILTER_INPUT":
            return {
                ...state,
                input: "",
            };
        case "FILTER_LIST":
            return {
                ...state,
                list: state.initialList.filter((item) => action.filterFn(item, state.input, state.path)),
            };
        case "SATURATE_INITIAL_LIST":
            const list = [...action.list].sort((a, b) => action.sortFn(a, b, state.path));

            return {
                ...state,
                initialList: list,
                list: list,
            };
        default:
            return state;
    }
};

/**
 * Default search function. Returns true if string is included in input
 * @param {object} item to filter
 * @param {string} input input field string
 * @param {string} path key path in item object ex: your.name || name
 * @returns {boolean}
 */

function defaultFilterFn(item, input = "", path) {
    const foundKeyValue = get(item, path) || "";
    return foundKeyValue.toLowerCase().includes(input.toLowerCase());
}

/**
 * Default sort function. Sorts alphabetically
 *
 * @param {string} a a comparator
 * @param {string} b b comparator
 * @param {string} path key path in item object ex: your.name || name
 * @returns {interger}
 */

export function defaultSortFn(a, b, path) {
    const aVal = get(a, path)?.toLowerCase();
    const bVal = get(b, path)?.toLowerCase();

    if (aVal > bVal) {
        return 1;
    }
    if (aVal < bVal) {
        return -1;
    }

    return 0;
}

function IconInput({ input, clearFilterInput, changeFilterInput }) {
    return input ? (
        <div onClick={clearFilterInput} className={`${style.indicator} ${style.close}`}>
            <CloseIcon />
        </div>
    ) : (
        <div onClick={changeFilterInput} className={style.indicator}>
            <SearchIcon />
        </div>
    );
}

IconInput.propTypes = {
    input: PropTypes.string.isRequired,
    clearFilterInput: PropTypes.func.isRequired,
    changeFilterInput: PropTypes.func.isRequired,
};

function HasChildren({ children, foundListContainerStyle, inZeroState, zeroState: ZeroState }) {
    if (!children) return null;
    if (inZeroState && ZeroState) {
        return <ZeroState />;
    }
    if (inZeroState)
        return (
            <div className={`${style.emptyFilter} ${foundListContainerStyle}`}>
                <span>There are currently no options that match your search.</span>
            </div>
        );

    return <div className={foundListContainerStyle}> {children} </div>;
}

HasChildren.propTypes = {
    children: PropTypes.object,
    inZeroState: PropTypes.bool,
    foundListContainerStyle: PropTypes.string,
};

/**
 * Produce filter type information
 * @param {string} filterType default | tags
 */

function getFilterFn(filters, filter, defaultFn, customFn) {
    if (!isEmpty(filters) && Object.keys(filters).length > 1) {
        if (!filters[filter]) {
            throw new Error("This is not a filter");
        }
        if (typeof filters[filter].filter !== "function") {
            throw new Error(`Filters ${filter} filter should be a function`);
        }
        return filters[filter].filter;
    }

    return typeof customFn === "function" ? customFn : defaultFn;
}

const Searchbox = React.forwardRef(
    (
        {
            children,
            pathOfFilterKey = "name",
            foundListContainerStyle,
            filterFn: customFilterFn,
            sortFn: customSortFn,
            placeholder = "Search",
            onChange = () => null,
            isLarge = false,
            disabled = false,
            list = [],
            input = "",
            className,
            zeroState,
            filters,
            changeFilteredOpts,
            isLoadingData = false,
        },
        ref,
    ) => {
        const initialState = {
            initialList: [],
            list: [],
            filters: {},
            filter: "",
            input: input,
            path: pathOfFilterKey,
        };

        const [state, dispatch] = useReducer(reducer, initialState);

        const filterFn = getFilterFn(state.filters, state.filter, defaultFilterFn, customFilterFn);

        const sortFn = customSortFn ? customSortFn : defaultSortFn;
        if (!isEmpty(filters)) {
            useEffect(() => {
                dispatch({ type: "INIT_FILTERS", filters });
            }, [filters]);
        }

        if (Array.isArray(list)) {
            const isListEqual = useMemoCompare(list, isEqual);

            //onstart if list changes
            useEffect(() => {
                dispatch({ type: "SATURATE_INITIAL_LIST", list: isListEqual, sortFn });
                dispatch({ type: "FILTER_LIST", filterFn });
            }, [isListEqual, filterFn, sortFn]);
        }

        useEffect(() => {
            dispatch({ type: "CHANGE_FILTER_INPUT", input });
            dispatch({ type: "FILTER_LIST", filterFn });
        }, [input, filterFn]);

        useEffect(() => {
            changeFilteredOpts && changeFilteredOpts(state.list);
        }, [state.list]);

        const changeFilterInput = useCallback(
            (e) => {
                dispatch({ type: "CHANGE_FILTER_INPUT", input: e.target.value });
                dispatch({ type: "FILTER_LIST", filterFn });
                onChange(e.target.value);
            },
            [filterFn, onChange],
        );

        const clearFilterInput = useCallback(
            (e) => {
                dispatch({ type: "CLEAR_FILTER_INPUT" });
                dispatch({ type: "FILTER_LIST", filterFn });
                onChange("");
            },
            [filterFn, onChange],
        );

        const changeFilter = useCallback((filter) => {
            dispatch({ type: "CHANGE_FILTER", filter });
        }, []);

        const hasFilters = !isEmpty(filters);

        return (
            <>
                <div className={`${className ? className : ""} ${style.search}`}>
                    <RenderInput
                        ref={ref}
                        labelHidden
                        disabled={disabled}
                        input={{
                            value: state.input,
                            onChange: changeFilterInput,
                        }}
                        large={isLarge}
                        placeholder={placeholder}
                        className={`${hasFilters && style.inputFilters} ${style.inputWrapper}`}
                    />
                    <Filters
                        disabled={disabled}
                        onChange={changeFilter}
                        filters={state.filters}
                        current={state.filter}
                    />
                    <IconInput
                        input={state.input}
                        clearFilterInput={clearFilterInput}
                        changeFilterInput={changeFilterInput}
                    />
                </div>
                <HasChildren
                    zeroState={zeroState}
                    inZeroState={isLoadingData === false && state.list.length === 0}
                    foundListContainerStyle={foundListContainerStyle ? foundListContainerStyle : ""}
                    children={typeof children === "function" ? children({ foundList: state.list }) : null}
                />
            </>
        );
    },
);

function Filters({ filters, current, onChange, disabled }) {
    const [isOpen, toggleOpen] = useState(false);

    const ref = useHandleOutsideClickHook(() => {
        toggleOpen(false);
    });

    if (isEmpty(filters)) return null;

    const filterKeys = Object.keys(filters);
    return (
        <div ref={ref} className={style.filters}>
            <button
                type="button"
                className={`${isOpen && style.open} ${disabled ? style.disabled : ""}`}
                onClick={() => toggleOpen(!isOpen)}
            >
                {filters[current].name}
                <CarretDown />
            </button>
            <Container dropdownWidth={"120px"} anchor="left" isOpen={isOpen}>
                {filterKeys.map((key) => {
                    const { onChange: onFilterChange } = filters[key];
                    return (
                        <SimpleListItem
                            key={key}
                            onClick={() => {
                                toggleOpen(false);
                                onChange(key);
                                onFilterChange(key);
                            }}
                            label={filters[key].name}
                            isActive={current === key}
                        />
                    );
                })}
            </Container>
        </div>
    );
}

Searchbox.propTypes = {
    list: PropTypes.arrayOf(PropTypes.object),
    pathOfFilterKey: PropTypes.string,
    children: PropTypes.func,
    foundListContainerStyle: PropTypes.string,
    filterFn: PropTypes.func,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    isLarge: PropTypes.bool,
    input: PropTypes.string,
    zeroState: PropTypes.func,
    isLoadingData: PropTypes.bool,
};

export default Searchbox;
