import { COMPARISONS, COMPARISONS_LABELS } from "features/Analysis/helpers";
import moment from "moment";

export const getDateRangeLabel = ({ from, to }) => {
    if ((!from && !to) || (!moment(from).isValid() && !moment(to).isValid())) {
        return null;
    }

    return `${moment(from).isValid() ? moment(from).format("MMM D, YYYY") : "From "}
    - 
    ${moment(to).isValid() ? moment(to).format("MMM D, YYYY") : "To"}`;
};

export const getDateRangeValue = ({ additionalActive, label }) => {
    const noneLabel = COMPARISONS_LABELS[COMPARISONS.NONE];

    if ((additionalActive === noneLabel || !additionalActive) && label) {
        return "Compare " + label;
    }

    return additionalActive || noneLabel;
};

export const isPresetActive = ({ dateRange, from, to, daysInRange, additionalActive }) => {
    if (additionalActive) {
        return {
            [additionalActive]: true,
        };
    }
    return {
        "Previous Period":
            moment(from).format("YYYYMMDD") ===
                moment(dateRange.start)
                    .subtract(daysInRange + 1, "days")
                    .format("YYYYMMDD") &&
            moment(to).format("YYYYMMDD") ===
                moment(dateRange.start)
                    .subtract(1, "days")
                    .format("YYYYMMDD"),
        "Previous Month":
            moment(from).format("YYYYMMDD") ===
                moment(dateRange.start)
                    .subtract(1, "month")
                    .format("YYYYMMDD") &&
            moment(to).format("YYYYMMDD") ===
                moment(dateRange.end)
                    .subtract(1, "month")
                    .format("YYYYMMDD"),
        "Previous Year":
            moment(from).format("YYYYMMDD") ===
                moment(dateRange.start)
                    .subtract(1, "years")
                    .format("YYYYMMDD") &&
            moment(to).format("YYYYMMDD") ===
                moment(dateRange.end)
                    .subtract(1, "years")
                    .format("YYYYMMDD"),
    };
};
