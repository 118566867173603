import React from "react";

export default ({ fill = "#BCCFDC" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            fill={fill}
            d="M16.0940827,4 C17.0762256,4 17.9690827,4.76909091 17.9690827,6 L17.9690827,6 L17.9690827,17.9090909 C17.9690827,19.3636364 16.9869399,20 16.0940827,20 C15.129797,20 14.2190827,19.2518182 14.2190827,17.9090909 L14.2190827,17.9090909 L14.2190827,5.90909091 C14.2190827,4.84818182 15.1119399,4 16.0940827,4 Z M9.96908271,10.25 C10.9512256,10.25 11.8440827,11.0067431 11.8440827,12.2178899 L11.8440827,12.2178899 L11.8440827,17.9426606 C11.8440827,19.3738532 10.8619399,20 9.96908271,20 C9.004797,20 8.09408271,19.2638303 8.09408271,17.9426606 L8.09408271,17.9426606 L8.09408271,12.1284404 C8.09408271,11.0845642 8.98693986,10.25 9.96908271,10.25 Z M3.84408271,16.25 C4.87961662,16.25 5.71908271,17.0894661 5.71908271,18.125 C5.71908271,19.1605339 4.87961662,20 3.84408271,20 C2.80854881,20 1.96908271,19.1605339 1.96908271,18.125 C1.96908271,17.0894661 2.80854881,16.25 3.84408271,16.25 Z"
            transform="translate(-1.97 -4)"
        />
    </svg>
);
