import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";

const defaultState = {
    title: "",
    contentId: "",
    meta: {}
};

export default clearReducer(
    defaultState,
    types.CLEAR_ASSISTANT
)(
    createReducer(defaultState, {
        [types.UPDATE_ASSISTANT](state, action) {
            return {
                ...state,
                title: action.title ? action.title : state.title,
                contentId: action.contentId
                    ? action.contentId
                    : state.contentId,
                meta: action.meta ? action.meta : state.meta,
                icon: action.icon ? action.icon : state.icon
            };
        }
    })
);
