import React from "react";
import styles from "./style.module.scss";

export default ({ title = "", message = "", className = null }) => (
    <div className={`${styles.loadingWrapper} ${className}`}>
        <LoadingCircles />
        {title && <h3 className={styles.loadingTitle}>{title}</h3>}
        {message && <div className={styles.loadingMessage}>{message}</div>}
    </div>
);

export const LoadingCircles = () => (
    <div className={styles.preloader}>
        <div className={styles.loader}></div>
    </div>
);
