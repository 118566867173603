import createReducer from "lib/createReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    intersected: [],
    permitted: {}
};

export default createReducer(defaultState, {
    [types.POPULATE_PERMISSIONS]: (state, action) =>
        handle(state, action, {
            finish: (
                prevState,
                { payload: { permissions, intersected, reasons } }
            ) => ({
                ...prevState,
                reasons,
                intersected,
                permitted: permissions
            })
        })
});
