import { KpiAPI } from "../apiKpi";
import { formatDateToString } from "lib/dateUtils";

function getDatasets({ userId, connectionId }) {
    return KpiAPI({
        method: "GET",
        url: `/bigQuery/datasets`,
        query: {
            userId,
            connectionId,
        },
    })
        .then(({ data }) => {
            return {
                connectionId,
                platform: "bigQuery",
                integrations: {
                    bigQuery: data.reduce(
                        (obj, next) => ({
                            ...obj,
                            [next.id]: next,
                        }),
                        {}
                    ),
                },
            };
        })
        .catch((error) => ({ data: { error: true } }));
}

function getTables({ userId, connectionId, projectId, datasetId }) {
    return KpiAPI({
        method: "GET",
        url: `/bigQuery/tables`,
        query: {
            userId,
            connectionId,
            projectId,
            datasetId,
        },
    })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => ({ data: { error: true } }));
}

function getTableFields({
    userId,
    connectionId,
    projectId,
    datasetId,
    tableId,
}) {
    return KpiAPI({
        method: "GET",
        url: `/bigQuery/tableFields`,
        query: {
            userId,
            connectionId,
            projectId,
            datasetId,
            tableId,
        },
    })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => ({ data: { error: true } }));
}

export function getReport({ dateRange, accountId, integrationId }) {
    if (dateRange) {
        const validatedDateRange = dateRange
            .filter((obj) => {
                return obj.hasOwnProperty("start") && obj.hasOwnProperty("end");
            })
            .map(({ start, end }) => {
                return {
                    start: formatDateToString(start),
                    end: formatDateToString(end),
                };
            });

        dateRange = JSON.stringify(validatedDateRange);
    }

    return KpiAPI({
        method: "GET",
        url: "/bigQuery/reports",
        query: {
            accountId,
            integrationId,
            dateRange,
            startDate: formatDateToString(dateRange.start),
            endDate: formatDateToString(dateRange.end),
        },
    }).then(({ data = [] } = {}) => {
        return data;
    });
}

export default {
    getDatasets,
    getTables,
    getTableFields,
    getReport,
};
