import React from "react";
import style from "./style.module.scss";

export default ({}) => {
    return (
        <div className={style.mainWrapper}>
            <div className={style.rightContainer}>
                <div onClick={() => window.open("https://meetings.hubspot.com/arjun64", "_blank")}></div>
            </div>
        </div>
    );
};
