const GET_ORGANIC_DATA = "GET_ORGANIC_DATA";
const GET_PLATFORM_DATA = "GET_PLATFORM_DATA";
const GET_ANOMALY_DATA = "GET_ANOMALY_DATA";

const PAGEHEALTH_COMMENT = "PAGEHEALTH_COMMENT";
const PAGEHEALTH_EDIT_COMMENT = "PAGEHEALTH_EDIT_COMMENT";
const PAGEHEALTH_DELETE_COMMENT = "PAGEHEALTH_DELETE_COMMENT";
const PAGEHEALTH_UPDATE_COMMENT = "PAGEHEALTH_UPDATE_COMMENT";

const ADD_IGNORE_PAGE = "ADD_IGNORE_PAGE";
const TOGGLE_HEALTH_ANOMALY_VISIBILITY = "TOGGLE_HEALTH_ANOMALY_VISIBILITY";

export default {
    GET_ORGANIC_DATA,
    GET_PLATFORM_DATA,
    GET_ANOMALY_DATA,
    PAGEHEALTH_COMMENT,
    PAGEHEALTH_EDIT_COMMENT,
    PAGEHEALTH_DELETE_COMMENT,
    PAGEHEALTH_UPDATE_COMMENT,
    ADD_IGNORE_PAGE,
    TOGGLE_HEALTH_ANOMALY_VISIBILITY,
};
