import React from "react";
import styles from "./style.css";

const getTabStyles = (type, index) => {
    if (type === index) {
        return styles.open;
    } else {
        return styles.closed;
    }
};

export default ({ children, tabType, className, onChange, tabs = [] }) => (
    <>
        <div className={`${styles.tabContainer} ${className && className}`}>
            {React.Children.map(children, (child, index) => {
                const { url } = tabs[index] || {};

                const activeTab = typeof url === "string" ? url : index;

                const tabStyles = getTabStyles(tabType, activeTab);
                const isActive = activeTab === tabType;
                const childProps = child.type !== "div" && {
                    tabStyles: tabStyles,
                    changeType: onChange,
                };

                if (!isActive) {
                    return null;
                }

                return <React.Fragment key={activeTab}>{React.cloneElement(child, childProps)}</React.Fragment>;
            })}
        </div>
    </>
);
