import React, { useState } from "react";
import style from "./style.module.scss";
import TableWrapper from "components/Table/MaterialUITable/TableWrapper";
import MaterialUITable from "components/Table/MaterialUITable";
import { channelFriendlyName, metricFriendlyName } from "lib/utility";
import { subTableHeadCells } from "./helpers";
import PlatformIcon from "components/Icon/PlatformIcon";
import classNames from "classnames";
import { isEmpty } from "lodash";

const PlatfomTableCell = ({ platform, metric, secondaryMetric, costMax, costMin, showOnly }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? `${platform}_ChartPopover` : undefined;
    const [platformLabel, channelLabel] = channelFriendlyName(platform).split(", ");

    return (
        <div className={style.rowPlatformContent}>
            <div className={style.rowPlatformContentLabel}>
                <div className={style.platformLabelContainer}>
                    <PlatformIcon className={style.platformIcon} platform={platform} />
                    <div className={style.headingText}>
                        <div className={style.title}>
                            <span className={style.titleText}>{platformLabel}</span>
                        </div>
                        {channelLabel && <div className={style.subTitle}>{channelLabel}</div>}
                    </div>
                </div>

                <div className={style.viewChartContainer}>
                    <div styles={{ float: "left" }}>
                        Benchmark Allocations:
                        <br />
                        <strong> {`${costMin}% - ${costMax}%`}</strong>
                    </div>
                </div>
            </div>
            <TableWrapper className={style.subTableContainer}>
                <MaterialUITable
                    headCells={subTableHeadCells()}
                    hideHeadCells
                    rowClass={style.rowClassSubTable}
                    rows={[
                        {
                            id: "recommendedBudget",
                            platform: {
                                content: (
                                    <div
                                        className={classNames(
                                            style.subTablePlatformCellLabel,
                                            style.isHeadCell,
                                            style.budget,
                                        )}
                                    >
                                        <strong>Recommended Budget</strong>
                                    </div>
                                ),
                                value: "recommendedBudget",
                                verticalAlign: "top",
                            },
                        },
                        {
                            id: "metricValue",
                            platform: {
                                content: (
                                    <div className={style.subTablePlatformCellLabel}>
                                        Predicted {metricFriendlyName(metric)}
                                    </div>
                                ),
                                value: "predictedMetric",
                                verticalAlign: "top",
                            },
                        },
                        {
                            id: "secondaryMetricValue",
                            platform: {
                                content: (
                                    <div className={style.subTablePlatformCellLabel}>
                                        Predicted {metricFriendlyName(secondaryMetric)}
                                    </div>
                                ),
                                value: "predictedSecondaryMetric",
                                verticalAlign: "top",
                            },
                        },
                        {
                            id: "benchmarkingChart",
                            platform: {
                                content: (
                                    <div className={style.subTablePlatformCellLabel}>
                                        {metricFriendlyName(metric)} Benchmark
                                    </div>
                                ),
                                value: "metricBenchmark",
                                verticalAlign: "top",
                            },
                        },
                    ].filter(({ id }) => isEmpty(showOnly) || showOnly.includes(id))}
                />
            </TableWrapper>
        </div>
    );
};

export default PlatfomTableCell;
