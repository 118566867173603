const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
const CHANGE_ACCOUNT_CONNECTION = "CHANGE_ACCOUNT_CONNECTION";
const DELETE_CONNECTION = "DELETE_CONNECTION";
const UPDATE_CONNECTION_NAME = "UPDATE_CONNECTION_NAME";
const GET_USER_CAMPAIGNS = "GET_USER_CAMPAIGNS";
const SAVE_REGEX = "SAVE_REGEX";
const GET_SEARCHCONSOLE_ACCOUNTS = "GET_SEARCHCONSOLE_ACCOUNTS";
const DELETE_PLATFORM_CACHE_DATA = "DELETE_PLATFORM_CACHE_DATA";
const GET_GOOGLE_BUSINESS_PROFILE_ACCOUNTS = "GET_GOOGLE_BUSINESS_PROFILE_ACCOUNTS";
const GET_GOOGLE_BUSINESS_PROFILE_LOCATIONS = "GET_GOOGLE_BUSINESS_PROFILE_LOCATIONS";

export default {
    SAVE_REGEX,
    GET_ACCOUNTS_REQUEST,
    FETCH_CAMPAIGNS,
    CHANGE_ACCOUNT_CONNECTION,
    DELETE_CONNECTION,
    UPDATE_CONNECTION_NAME,
    GET_USER_CAMPAIGNS,
    GET_SEARCHCONSOLE_ACCOUNTS,
    DELETE_PLATFORM_CACHE_DATA,
    GET_GOOGLE_BUSINESS_PROFILE_ACCOUNTS,
    GET_GOOGLE_BUSINESS_PROFILE_LOCATIONS,
};
