import React, { Component, useState } from "react";
import style from "./style.module.scss";
import classNames from "classnames";
import { UserDropdown, HelpDropdown } from "components/DropDown";
import Avatar from "material-ui/Avatar";
import ArrowDown from "assets/images/Top_Panel/JSX/Icon-Arrow-Down";
import HelpIcon from "assets/images/Top_Panel/JSX/Icon-Help";
import { branch, renderComponent } from "recompose";
import { history } from "../../_redux";
import Notifications from "./Notifications";
import { mixpanel } from "components/Mixpanel";
import { Permissions } from "features/Permissions";
import Button from "components/Button";
import UpgradeIcon from "assets/images/icons/JSX/Icon-Upgrade-Circle";
import { TooltipDark } from "components/Tooltip";
import { DialogVFour } from "components/Dialog";
import { CloseDialog } from "components/Dialog";
import { AvatarHeading } from "components/DropDown/Headings";
import { BrandLogo, isOnDefaultDomain } from "components/Branding";

const AvatarImage = branch(
    ({ avatarImage }) => {
        return avatarImage;
    },
    renderComponent(({ avatarImage, style }) => (
        <Avatar src={avatarImage} style={{ background: "#FFCA28", ...style }} className={style.avatar} />
    )),
    renderComponent(({ avatarImage, style, title = "" }) => (
        <Avatar style={{ background: "#FFCA28", ...style }} className={style.avatar}>
            {title.substring(0, 1).toUpperCase()}
        </Avatar>
    )),
)();

class UserOptions extends Component {
    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggleOpen(isOpen) {
        this.setState({
            isOpen,
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpen) {
            this.toggleOpen(false);
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        const { name, email, role, picture } = this.props;
        const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent);
        return (
            <div
                ref={this.setWrapperRef}
                onClick={() => this.toggleOpen(!this.state.isOpen)}
                className={`${style.userOptions} ${style.userMenuOptions}`}
            >
                <div
                    className={classNames(style.userTag, {
                        [style.open]: this.state.isOpen,
                    })}
                    data-for="user-options"
                    data-tip="Profile &amp; Platform Settings"
                >
                    <div className={style.ring}></div>
                    <AvatarImage
                        avatarImage={picture}
                        style={{
                            height: "34px",
                            width: "34px",
                            fontSize: "16px",
                        }}
                        title={email}
                    />
                    <div className={style.userArrowIcon}>
                        <ArrowDown />
                    </div>
                </div>
                <UserDropdown
                    avatarImage={picture}
                    toggleOpen={this.toggleOpen}
                    role={role}
                    username={name || email}
                    isOpen={this.state.isOpen}
                />

                {isMobile && (
                    <DialogVFour
                        open={this.state.isOpen}
                        onClick={(e) => e.stopPropagation()}
                        classes={{
                            rootClass: style.userMenuRoot,
                            paperClass: style.userMenuPaper,
                        }}
                    >
                        <CloseDialog
                            closeActions={() => {
                                this.toggleOpen(false);
                            }}
                        />
                        <div className={style.userMenuHeading}>Profile</div>
                        <AvatarHeading username={name || email} subtitle={role} avatarImage={picture} />
                        <Button
                            className={style.buttonLogOut}
                            onClick={() => {
                                history.push("/logout");
                            }}
                        >
                            Log Out
                        </Button>
                    </DialogVFour>
                )}
                {!isMobile && <TooltipDark id="user-options" delay position="left" proximity={-2} />}
            </div>
        );
    }
}

export class HelpOptions extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggleOpen(isOpen) {
        this.setState({
            isOpen,
        });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.isOpen) {
            this.toggleOpen(false);
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    render() {
        return (
            <div
                ref={this.setWrapperRef}
                onClick={() => {
                    mixpanel.track("Button Click", {
                        "Button Text": "Support",
                        "Button Name": "Support Dropdown",
                    });
                    this.toggleOpen(!this.state.isOpen);
                }}
                className={style.userOptions}
            >
                <div
                    className={classNames(style.helpIcon, {
                        [style.isOpen]: this.state.isOpen,
                    })}
                    data-for="top-bar-help"
                    data-tip="Help &amp; Support"
                    data-tip-disable={this.state.isOpen}
                >
                    <HelpIcon />
                </div>
                <HelpDropdown toggleOpen={this.toggleOpen} isOpen={this.state.isOpen} />
                <TooltipDark id="top-bar-help" position="bottom" proximity={-2} />
            </div>
        );
    }
}

const UserPanel = ({
    children,
    permissions,
    name,
    email,
    picture,
    role,
    id,
    isTrial,
    planId,
    isNavOpen,
    showNavigation,
    showUpgrade,
    togglePlanOptions,
    hidePanel = false,
    subscriptionType,
} = {}) => {
    if (hidePanel) {
        return <></>;
    }

    const handleClick = (event) => {
        // If the user clicked with the middle button or holding ctrl/cmd (for opening in a new tab), allow default behavior
        if (event.metaKey || event.ctrlKey || event.button === 1) {
            return;
        }

        // Prevent default behavior and use React Router's navigation to avoid hard refresh
        event.preventDefault();
        history.push("/dashboard");
    };

    return (
        <div className={style.userPanelContainer}>
            {showNavigation && (
                <a
                    className={`${style.logo} ${isNavOpen && showNavigation && style.logoExpanded}`}
                    href={"/dashboard"}
                    id="helpLogo"
                    onClick={handleClick}
                >
                    <div className={style.logoIcon}>
                        <BrandLogo />
                    </div>
                </a>
            )}
            <div className={style.userPanelContainerChildren}>
                {children}
                <Permissions>
                    {showUpgrade && (
                        <Button
                            className={style.upgradeButton}
                            icon={UpgradeIcon}
                            emptyBg
                            small
                            onClick={() => {
                                mixpanel.track("Upgrade", {
                                    Location: "User Panel",
                                    "Is Trial": isTrial,
                                    "Is Freemium": planId === "freemium",
                                });
                                togglePlanOptions(true);
                            }}
                        >
                            Upgrade Now
                        </Button>
                    )}
                </Permissions>
                {role !== "reporting" && (
                    <>
                        <Permissions action={"ACTION_USER_NOTIFICATIONS"}>
                            <Notifications id={id} />
                        </Permissions>
                        {isOnDefaultDomain() && <HelpOptions />}
                    </>
                )}
                <UserOptions name={name} email={email} picture={picture} role={role} />
            </div>
        </div>
    );
};

export default UserPanel;
