import React from "react";
import { noop } from "../../../../lib/utility";

export default ({ onClick = noop, className = "", style = {} }) => (
    <svg
        className={className}
        style={style}
        onClick={onClick}
        width="12px"
        height="14px"
        viewBox="0 0 12 14"
    >
        <path
            d="M11,1.5 L8,1.5 L8,0.79245283 C8,0.35554717 7.6635,0 7.25,0 L4.75,0 C4.3365,0 4,0.35554717 4,0.79245283 L4,1.5 L1,1.5 C0.50975,1.5 0,2.00649057 0,2.52449057 L0,4 L12,4 L12,2.52449057 C12,2.00649057 11.49025,1.5 11,1.5 Z M7,1.5 L5,1.5 L5,1 C5,0.854188679 5.11225,0.75 5.25,0.75 L6.75,0.75 C6.88775,0.75 7,0.854188679 7,1 L7,1.5 Z M3,14 C2.486,14 2.00025,13.5580755 2,13.0149811 L1,5 L11,5 L10,13.0149811 C10,13.5580755 9.51425,14 9,14 L3,14 Z"
            id="path-1"
        ></path>
    </svg>
);
