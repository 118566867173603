import io from "socket.io-client/dist/socket.io";
import wildcard from "socketio-wildcard";
import qs from "qs";
import { get } from "lodash";

let socket;
export default ({ host } = {}) => ({ dispatch }) => (next) => (action) => {
    if (window.location.pathname === "/dashboard/account/connect") {
        return next(action);
    }

    const { meta: { "redux-pack/LIFECYCLE": lifecycle } = {}, type, ...payload } = action;

    if (type === "LOGOUT" && socket) {
        socket.disconnect();
        socket = undefined;
    }

    if (type === "SIGNIN_USER" && lifecycle === "success" && socket) {
        const { payload: { sub } = {} } = payload || {};

        const { roomId } = qs.parse(socket.query);

        if (roomId !== sub) {
            socket.disconnect();
            socket = undefined;
        }
    }

    if (
        ((type === "SIGNIN_USER" && lifecycle === "success") || type === "INITIALIZE_USER") &&
        typeof socket === "undefined"
    ) {
        const alternateSub = get(payload, ["user", "sub"]) || "";
        const sub = get(payload, ["payload"]) || "";

        socket = io(host, {
            query: qs.stringify({
                userId: sub || alternateSub,
                roomId: sub || alternateSub,
            }),
            transports: ["websocket"],
        });

        socket.emit("USER_SYNC", { userId: sub || alternateSub });

        socket.emit("NOTIFICATION_NEW", { userId: sub || alternateSub });

        socket.emit("TEAMMEMBERS_SYNC", { userId: sub || alternateSub });

        wildcard(io.Manager)(socket);

        socket.on("connect", () => console.log("User Socket connected!"));
        socket.on("disconnect", () => console.log("User Socket disconnected!"));

        socket.on("USER_SYNC", (val) => {
            dispatch({
                type: "USER_SYNC",
                payload: val,
            });
        });

        socket.on("TEAMMEMBERS_SYNC", (val) => {
            dispatch({
                type: "TEAMMEMBER_SYNC",
                payload: val,
            });
        });

        socket.on("NOTIFICATION_NEW", (notification) => {
            dispatch({
                type: "NEW_NOTIFICATION",
                payload: notification,
            });
            dispatch({
                type: "ADD_COUNT",
            });
        });
    }

    if (typeof socket === "undefined") {
        return next(action);
    }

    return next(action);
};
