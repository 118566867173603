import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";
import classNames from "classnames";
import { IconImage } from "components/Icon";
import Tooltip, { TooltipDark } from "components/Tooltip";

/**
 * Tab header with icon design for container tabs
 * @param {props} props
 * @param {object} props.inlineStyles inline custom styles
 * @param {JSX.Element | string} props.icon tab icon
 * @param {boolean} props.disabled is tab disabled
 * @param {boolean} props.disableIconColor disable component default icon color of white
 * @param {string} props.color colour behind icon
 * @param {string} props.title main title of tab
 * @param {string} props.subTitle sub-title of tab
 * @param {string} props.infoTip tooltip that appears with a help icon
 * @param {string} props.disabledTip tooltip that appears when tab is disabled
 * @param {string} props.tipId unique ID for showing tooltips in a tab
 * @param {string} props.className classname for tab with icon
 */

const TabWithIcon = ({
    style: inlineStyles,
    icon: Icon,
    className,
    disableIconColor,
    disabled,
    color = "#333333",
    title,
    subTitle,
    infoTip,
    disabledTip,
    tipId,
    tipWidth = "auto",
}) => {
    const classes = classNames(style.tab, {
        [className]: className,
        [style.disabled]: disabled,
        [style.hasIconColor]: !disableIconColor,
    });

    return (
        <>
            <div
                className={classes}
                style={inlineStyles}
                data-tip={disabledTip}
                data-for={tipId}
                data-tip-disable={!disabled || !tipId}
            >
                <div
                    className={style.icon}
                    style={{
                        backgroundColor: disabled ? "#BCCFDC" : color,
                    }}
                >
                    <IconImage icon={Icon} />
                </div>
                <div className={style.text}>
                    <h3>{title}</h3>
                    <span>{subTitle}</span>
                </div>
                {infoTip && tipId && !disabled && (
                    <Tooltip
                        width={tipWidth}
                        className={style.infoTip}
                        message={infoTip}
                        id={`info-${tipId}`}
                        iconSize="0.75rem"
                    />
                )}
            </div>
            {tipId && <TooltipDark id={tipId} proximity={18} />}
        </>
    );
};

TabWithIcon.propTypes = {
    style: PropTypes.object,
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.func]).isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    infoTip: PropTypes.string,
    disabledTip: PropTypes.string,
    className: PropTypes.string,
    disableIconColor: PropTypes.bool,
    tipId: function(props, propName) {
        if ((props["infoTip"] || props["disabledTip"]) && !props[propName]) {
            return new Error("Please provide a unique id for tipId property");
        }
    },
};

export default TabWithIcon;
