import types from "./types";

const resetDashboardBasicBudgets = () => ({
    type: types.RESET_DASHBOARD_BASIC_BUDGETS,
});
const resetDashboardEnhancedBudgets = () => ({
    type: types.RESET_DASHBOARD_ENHANCED_BUDGETS,
});
const resetDashboardAlerts = () => ({
    type: types.RESET_DASHBOARD_ALERTS,
});

export default {
    resetDashboardBasicBudgets,
    resetDashboardEnhancedBudgets,
    resetDashboardAlerts,
};
