import Checkbox from "components/Checkbox";
import { divide, toPrettyNumber } from "lib/metricCalculations";
import { metricFriendlyName } from "lib/utility";
import moment from "moment";
import React from "react";
import style from "./style.module.scss";

export const getTotalValues = ({ totalRow, metric }) =>
    Object.keys(totalRow).reduce((cache, key) => {
        if (["id", "platform"].includes(key)) {
            return { ...cache };
        }
        const { totalBudget, totalSecondaryValue } = totalRow[key].value || {};
        const totalSpend = (cache.totalSpend || 0) + totalBudget;
        const totalSecondaryMetricValue = (cache.totalSecondaryMetricValue || 0) + totalSecondaryValue;
        return {
            totalSpend,
            totalSecondaryMetricValue,
            totalMetricValue: ["roas"].includes(metric)
                ? divide(100 * totalSecondaryMetricValue, totalSpend, 0)
                : divide(totalSpend, totalSecondaryMetricValue, 0),
        };
    }, {});

export const getConfigureBudgetPlannerText = ({ totalValues, numberOfMonths, secondaryMetric, metric }) => {
    const spendValue = toPrettyNumber(totalValues.totalSpend, "budget");
    const metricvalue = toPrettyNumber(totalValues.totalMetricValue, metric);
    const secondaryMetricvalue = toPrettyNumber(totalValues.totalSecondaryMetricValue, secondaryMetric);

    return `A total spend of ${spendValue} over ${numberOfMonths} months, yielding ${secondaryMetricvalue} at a blended
    ${metricFriendlyName(metric)} of ${metricvalue}`;
};

export const getInitValues = (data) => {
    const initBudgets = Object.keys(data).reduce((acc, month) => {
        return {
            ...acc,
            [month]: data[month].reduce((acc, item) => {
                return acc + item?.forecasted?.cost;
            }, 0),
        };
    }, {});

    const initPlatforms = (
        data?.[
            moment()
                .add(1, "month")
                .format("YYYY-MM")
        ] || []
    ).reduce(
        (acc, item) => ({
            ...acc,
            [item.platform]: {
                threshold: item.threshold * 100,
                active: true,
            },
        }),
        {},
    );

    return { initBudgets, initPlatforms };
};

export const getCheckboxOptions = ({ optionValue, onChangeCheckbox, options, setOptionsValue }) =>
    Object.keys(options).map((value) => {
        return {
            label: (
                <div className={style.checkboxItem} onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                        label={options[value]}
                        checked={optionValue.includes(value)}
                        input={{
                            onChange: ({ target: { checked: isTargetChecked } }) =>
                                onChangeCheckbox({
                                    isTargetChecked,
                                    value,
                                    optionValue,
                                    setOptionsValue,
                                }),
                            checked: optionValue.includes(value),
                        }}
                    />
                </div>
            ),
            value,
        };
    });

export const getMonthsOptions = (months) =>
    months.reduce((cache, month) => {
        return {
            ...cache,
            [month]: moment(month).format("MMMM YYYY"),
        };
    }, {});

export const getShowOnlyOptions = ({ metric, secondaryMetric }) => ({
    recommendedBudget: "Recommended Budget",
    metricValue: `Predicted ${metricFriendlyName(metric)}`,
    secondaryMetricValue: `Predicted ${metricFriendlyName(secondaryMetric)}`,
    benchmarkingChart: `${metricFriendlyName(metric)} Benchmark`,
});

export const onChangeCheckbox = ({ isTargetChecked, value, optionValue, setOptionsValue }) => {
    const optionValueData = isTargetChecked ? [...optionValue, value] : optionValue.filter((key) => key !== value);

    setOptionsValue(optionValueData);
};
