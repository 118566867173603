import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { makeSelectRole } from "_redux/users/selectors";

const mapStateToProps = (
    state,
    { match: { params: { siteID } = {} } = {} }
) => {
    const userRole = makeSelectRole()(state);
    return {
        userRole,
        accountId: siteID,
    };
};

export const NoneReportingUserPage = (Component) =>
    connect(mapStateToProps)(({ userRole, accountId, ...props }) => {
        if (userRole === "reporting") {
            return (
                <Redirect
                    to={accountId ? `/dashboard/${accountId}/kpi/summary` : "/"}
                />
            );
        }

        return <Component {...props} />;
    });
