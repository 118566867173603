import { KpiAPI } from "../apiKpi";
import { requestMetricData } from "../../_redux/kpi/async";
import moment from "moment";

export function getReport({ dateRange, accountId, abortController }) {
    return requestMetricData({
        scope: ["shopify"],
        accountId,
        metrics: ["revenue", "transactions", "avgOrderValue", "orders", "items", "newCustomerOrders", "newCustomerGMV", "returningCustomerOrders", "returningCustomerGMV", "grossSales", "discounts", "shipping", "taxes", "returns", "netSales", "totalSales"],
        dateRange: dateRange,
        abortController,
    });
}

export function getOrders({ dateRange = [], accountId, abortController }) {
    return Promise.all(
        dateRange.map(({ start, end }) =>
            KpiAPI({
                method: "GET",
                url: `/shopify/orders`,
                query: {
                    accountId,
                    startDate: start,
                    endDate: end,
                },
                abortController,
            }),
        ),
    )
        .then(([result = {}, prevResult = {}]) => {
            return {
                current: result.data || [],
                previous: prevResult.data || [],
            };
        })
        .catch((error) => []);
}

export function getLtv({ start, end, accountId }) {
    return KpiAPI({
        method: "GET",
        url: `/shopify/ltv`,
        query: {
            accountId,
            fromComparingMonthsAgo: start,
            fromMonthsAgo: end,
            toComparingMonthsAgo: end,
        },
    }).catch((error) => ({}));
}

export default {
    getReport,
    getOrders,
    getLtv,
};
