import { MorphioAPI, getProfile } from "lib/api";
import { USER_METADATA } from "lib/auth";
import types from "./types";
import { reloadUserUsage } from "_redux/users";

export const inviteTeamRequest = ({ name, projectName, email, role, permissions = [] }) => {
    const parentUserId = getProfile()?.sub;
    // TODO: Add a better varification on form
    return MorphioAPI({
        url: "user/invitation",
        method: "post",
        data: {
            name,
            email,
            permissions,
            role,
            projectName,
            parentUserId,
        },
    }).then((res) => res.data);
};

export const resendInvitationRequest = ({ userId }) => {
    return MorphioAPI({
        url: "user/email/invitation",
        method: "post",
        data: {
            userId,
        },
    }).then(({ data }) => data);
};

export const getTeamData = ({ userId }) => {
    const parentUserId = userId ? userId : getProfile()?.sub;
    return MorphioAPI({
        url: `user/children?parent_user_id=${parentUserId}`,
        method: "get",
    }).then(({ data }) => {
        return data;
    });
};

export const deleteTeamMemberRequest = ({ userId }) => {
    const parentUserId = getProfile()?.sub;
    return MorphioAPI({
        url: `user`,
        data: {
            userId,
            parentUserId,
        },
        method: "delete",
    }).then((res) => ({ userId }));
};

export const updateTeamMemberData = ({ userId, permissions, role, nickname, replace }) => {
    let params = {
        userId,
        permissions,
        replace,
    };

    if (role) {
        params.role = role;
    }

    if (nickname) {
        params.nickname = nickname;
    }

    return MorphioAPI({
        url: `team`,
        method: "put",
        data: params,
    }).then(({ data }) => data);
};

const mapPermissions = (permissions) => {
    if (!permissions || !permissions.length) return { permissions: {} };
    return {
        permissions: permissions.reduce((cache, object) => {
            const accountId = typeof object === "object" ? object.value : object;
            return {
                ...cache,
                [accountId]: ["default"],
            };
        }, {}),
    };
};

export const updateTeamMember = ({ userId, role, permissions, nickname, replace }) => (dispatch, getState) => {
    return dispatch({
        type: types.UPDATE_TEAMMEMBER,
        promise: updateTeamMemberData({
            userId,
            role,
            permissions: mapPermissions(permissions).permissions,
            nickname,
            replace,
        }),
    });
};

export const updateTeamMembers = ({ members = [], replace }) => (dispatch, getState) => {
    return Promise.all(
        members.map(({ userId, role, permissions, nickname }) => {
            return dispatch(
                updateTeamMember({
                    replace,
                    userId,
                    role,
                    permissions,
                    nickname,
                }),
            );
        }),
    );
};

export const resendInvitation = (userId) => (dispatch, getState) => {
    return dispatch({
        type: types.RESEND_INVITATION,
        promise: resendInvitationRequest({
            userId,
        }),
    });
};

export const deleteTeamMember = ({ userId }) => (dispatch, getState) => {
    return dispatch({
        type: types.DELETE_TEAMMEMBER,
        promise: deleteTeamMemberRequest({
            userId,
        }),
    }).then(async (result) => {
        await dispatch(reloadUserUsage());
        return result;
    });
};

export const getTeam = ({ userId } = {}) => (dispatch, getState) => {
    return dispatch({
        type: types.GET_TEAM,
        promise: getTeamData({ userId }),
    });
};

export const inviteTeamMember = ({ name, email, role, permissions = [] }) => (dispatch, getState) => {
    const { [USER_METADATA]: { company: projectName = "" } = {} } = getState().user.user;

    return dispatch({
        type: types.INVITE_TEAMMEMBER,
        promise: inviteTeamRequest({
            email,
            name,
            role,
            projectName,
            ...mapPermissions(permissions),
        }),
    })
        .then(async (result) => {
            if (result.error) {
                throw new Error(result?.payload?.response?.data?.message);
            }
            await dispatch(reloadUserUsage());
            return result;
        })
        .catch((error) => {
            throw new Error(error.message);
        });
};

export default {
    inviteTeamRequest,
    resendInvitationRequest,
    getTeamData,
    deleteTeamMemberRequest,
    updateTeamMemberData,
    updateTeamMember,
    resendInvitation,
    deleteTeamMember,
    getTeam,
    inviteTeamMember,
    updateTeamMembers,
};
