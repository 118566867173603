import React from "react";

export default ({ styles }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...styles}>
        <g fill="none" fillRule="evenodd">
            <rect width="24" height="24" fill="#000" rx="3" />
            <polygon fill="#FFF" fillRule="nonzero" points="5.25 16.75 5.25 7.25 19.25 7.25 14.897 12 19.25 16.75" />
        </g>
    </svg>
);
