import moment from "moment";

export const tagColors = [
    "#0671EE",
    "#2B9EB3",
    "#E29C2A",
    "#ED8FFF",
    "#13C07E",
    "#56CBF9",
    "#3F7DEA",
    "#F0C920",
    "#F8333C",
    "#9FD12C",
    "#613DC1",
    "#4C212A",
    "#FF65DB",
    "#073B3A",
    "#54A0FF",
    "#700353",
    "#5C7687",
    "#AE8E1C",
];

export const hexToRGB = ({ hex, opacity = 1 }) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + `,${opacity})`;
    }
};

export const isNewAccount = ( {"created_at": createdAt } = {}) => !!createdAt && moment().diff(moment(createdAt), "hours") <= 24;

export const tagSort = (a, b) => {
    const aVal = a.index;
    const bVal = b.index;

    if (aVal > bVal) {
        return 1;
    }
    if (aVal < bVal) {
        return -1;
    }

    return 0;
};

export const getNextTagColor = (availableTags) => {
    const prevTagColor = availableTags[availableTags.length - 1]?.hexColor;
    const prevTagColorIndex = tagColors.indexOf(prevTagColor);

    if (availableTags.length === 0 || prevTagColorIndex + 1 === tagColors.length) {
        return tagColors[0];
    }

    return tagColors[prevTagColorIndex + 1];
};

export const generateTagId = () => moment(new Date()).format("YYYYMMDDHHmmssSS");

export const sortDefault = (a, b) => {

    if(a.pinned && b.pinned){
        return sortAlphabetical(a, b);
    }

    if(!a.pinned && !b.pinned){
        const aIsNew = isNewAccount(a);
        const bIsNew = isNewAccount(b);

        if(aIsNew && bIsNew){
            return sortByTime(a, b);
        }

        if(aIsNew !== bIsNew){
            return aIsNew ? -1 : 1;
        }

        return sortAlphabetical(a, b);
    }

    return a.pinned ? -1 : 1

}

export const sortAlphabetical = (a, b) => {
    const aVal = a?.name.toLowerCase();
    const bVal = b?.name.toLowerCase();

    return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
};

export const sortByTime = (a , b ) => {
    const aCreatedAt = a?.["created_at"];
    const bCreatedAt = b?.["created_at"];

    return moment(bCreatedAt) - moment(aCreatedAt)
}

export const sortByMetric = (metric) => (a, b) => {
    const { performance: { metrics: { data: { [metric]: { actual: actualA = 0 } = {} } = {} } = {} } = {} } = a;
    const { performance: { metrics: { data: { [metric]: { actual: actualB = 0 } = {} } = {} } = {} } = {} } = b;

    return actualA === actualB ? 0 : actualA < actualB ? 1 : -1;
};

const sortFuncMap = (sort) => {
    if (sort.includes("-")) {
        // Metric sort
        return sortByMetric(sort);
    }

    const sortOptions = {
        asc: sortAlphabetical,
    };

    return sortOptions[sort];
};

export const accountSort = ({ sort = "default", sortOrder = "asc", accounts = [] }) => {
    const sortFunc = sortFuncMap(sort) || sortDefault;
    const sortedAccounts = accounts.sort(sortFunc);

    if (sortOrder === "desc") {
        sortedAccounts.reverse();
    }

    return sortedAccounts;
};
