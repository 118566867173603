import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g id="Onboarding-1" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g
                id="3.1-Step-3.1-()"
                transform="translate(-1068.000000, -626.000000)"
                fillRule="nonzero"
            >
                <g id="Framework-Copy">
                    <g
                        id="Row-Copy"
                        transform="translate(500.000000, 576.000000)"
                    >
                        <g
                            id="Inputs"
                            transform="translate(0.000000, 36.000000)"
                        >
                            <path
                                d="M583.799167,28.425004 L579.724731,24.3592118 C579.545262,24.6053261 579.331753,24.8526355 579.084204,25.1011401 C578.836656,25.3496447 578.578692,25.5687735 578.310311,25.7585264 L582.340175,29.794207 C582.506276,29.9607488 582.785385,29.9968829 583.024792,29.9968829 C583.251399,29.9968829 583.512529,29.8673125 583.675721,29.7156555 C584.022467,29.3935249 584.132823,28.7600536 583.799167,28.425004 Z M574.499467,14 C578.083349,14 580.998934,16.9155844 580.998934,20.4994668 C580.998934,24.0833493 578.083349,26.9989337 574.499467,26.9989337 C570.915584,26.9989337 568,24.0833493 568,20.4994668 C568,16.9155844 570.915584,14 574.499467,14 Z M574.499467,15.9998359 C572.018317,15.9998359 569.999836,18.0183174 569.999836,20.4994668 C569.999836,22.9806162 572.018317,24.9990977 574.499467,24.9990977 C576.980616,24.9990977 578.999098,22.9806162 578.999098,20.4994668 C578.999098,18.0183174 576.980616,15.9998359 574.499467,15.9998359 Z"
                                id="Mask"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
