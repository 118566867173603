import { connect } from "react-redux";
import { compose } from "recompose";
import Title from "./";
import { makeSelectAccountName, makeSelectIsDemo } from "_redux/sites/selectors";
import { selectFilteredAccountArray } from "features/ControlPanel/ducks/selectors";
import Creators from "features/ControlPanel/ducks/actions";
import { selectParentUserId, makeSelectRole, makeSelectUserName } from "_redux/users/selectors";

const mapStateToProps = (state) => {
    const {
        router: { location: { pathname = "" } = {} },
    } = state;
    const accountId = pathname.split("/")[2];
    const hasProperAccountId = accountId && !["user", "profile", "alert-settings"].includes(accountId) ? true : false;
    const views = selectFilteredAccountArray(state);
    const enhancedViews = views.map((view) => ({
        ...view,
    }));

    const accountDetail = enhancedViews.find((view) => view.id === accountId);
    const parentUserId = selectParentUserId(state);
    const userRole = makeSelectRole()(state);
    const userNickName = makeSelectUserName()(state);

    return {
        hasProperAccountId: hasProperAccountId,
        title: makeSelectAccountName()(state),
        isDemo: Boolean(makeSelectIsDemo()(state)),
        accountId,
        tags: accountDetail ? accountDetail.tags : [],
        parentUserId,
        userRole,
        userNickName,
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateOverviewTagsAvailable: (tags) => dispatch(Creators.updateOverviewTagsAvailable(tags)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Title);
