const ONBOARDING_CONNECTION = "ONBOARDING_CONNECTION";
const GET_CONNECTION_ACCOUNTS = "GET_CONNECTION_ACCOUNTS";
const GET_CONNECTION_CAMPAIGNS = "GET_CONNECTION_CAMPAIGNS";
const GET_CONVERSION_TYPES = "GET_CONVERSION_TYPES";
const UPDATE_INTEGRATIONS = "UPDATE_INTEGRATIONS";
const UPDATE_CAMPAIGNS = "UPDATE_CAMPAIGNS";
const CLOSE_ONBOARDING_MODAL = "CLOSE_ONBOARDING_MODAL";
const ONBOARDING_LOADING = "ONBOARDING_LOADING";
const ONBOARDING_ERROR = "ONBOARDING_ERROR";
const CLEAR_ONBOARDING = "CLEAR_ONBOARDING";
const CHANGE_STEP = "CHANGE_STEP";
const DISMISS_ONBOARDING = "DISMISS_ONBOARDING";
const GET_BIGQUERY_TABLES = "GET_BIGQUERY_TABLES";
const GET_BIGQUERY_FIELDS = "GET_BIGQUERY_FIELDS";

export default {
    UPDATE_CAMPAIGNS,
    UPDATE_INTEGRATIONS,
    GET_CONNECTION_CAMPAIGNS,
    GET_CONNECTION_ACCOUNTS,
    ONBOARDING_CONNECTION,
    GET_CONVERSION_TYPES,
    CLOSE_ONBOARDING_MODAL,
    ONBOARDING_LOADING,
    ONBOARDING_ERROR,
    CLEAR_ONBOARDING,
    CHANGE_STEP,
    DISMISS_ONBOARDING,
    GET_BIGQUERY_TABLES,
    GET_BIGQUERY_FIELDS,
};
