import React from "react";

export default ({ styles }) => (
    <svg width="47" height="48" viewBox="0 0 47 48" version="1.1" {...styles}>
        <g fill="none">
            <path
                fill="#BCCFDC"
                d="M46.989011,24.5626374 C46.989011,22.9362637 46.8571429,21.3010989 46.5758242,19.7010989 L24.0087912,19.7010989 L24.0087912,28.9142857 L36.9318681,28.9142857 C36.3956044,31.8857143 34.6725275,34.5142857 32.1494505,36.1846154 L32.1494505,42.1626374 L39.8593407,42.1626374 C44.3868132,37.9956044 46.989011,31.8417582 46.989011,24.5626374 Z"
            />
            <path
                fill="#BCCFDC"
                d="M24.0087912,47.9384615 C30.4615385,47.9384615 35.9032967,45.8197802 39.8681319,42.1626374 L32.1582418,36.1846154 C30.0131868,37.643956 27.243956,38.4703297 24.0175824,38.4703297 C17.7758242,38.4703297 12.4835165,34.2593407 10.5846154,28.5978022 L2.62857143,28.5978022 L2.62857143,34.7604396 C6.69010989,42.8395604 14.9626374,47.9384615 24.0087912,47.9384615 Z"
            />
            <path
                fill="#BCCFDC"
                d="M10.5758242,28.5978022 C9.57362637,25.6263736 9.57362637,22.4087912 10.5758242,19.4373626 L10.5758242,13.2747253 L2.62857143,13.2747253 C-0.764835165,20.0351648 -0.764835165,28 2.62857143,34.7604396 L10.5758242,28.5978022 L10.5758242,28.5978022 Z"
            />
            <path
                fill="#BCCFDC"
                d="M24.0087912,9.55604396 C27.4197802,9.5032967 30.7164835,10.7868132 33.1868132,13.1428571 L40.0175824,6.31208791 C35.6923077,2.25054945 29.9516484,0.0175824176 24.0087912,0.0879120879 C14.9626374,0.0879120879 6.69010989,5.18681319 2.62857143,13.2747253 L10.5758242,19.4373626 C12.4659341,13.767033 17.767033,9.55604396 24.0087912,9.55604396 Z"
            />
        </g>
    </svg>
);
