import React from "react";

export default () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path
            d="M37.7291462,145.472134 C37.9748142,145.222254 38.061544,144.855523 37.9555498,144.515059 C37.8495291,144.174596 37.5727782,143.931131 37.2332788,143.879673 L34.2646462,143.429547 C34.2063759,143.420727 34.1560021,143.382541 34.1299276,143.327405 L32.8023237,140.520442 C32.6505135,140.199417 32.3430773,140 32,140 C31.6569238,140 31.3494877,140.199417 31.1976775,140.520442 L29.8700736,143.327433 C29.8439725,143.382541 29.7936253,143.420727 29.7353285,143.429575 L26.7667489,143.879673 C26.4272494,143.931131 26.1505251,144.174596 26.0444779,144.515059 C25.9384307,144.855523 26.0251869,145.222254 26.2708815,145.472134 L28.4189865,147.657066 C28.4611457,147.69998 28.4804101,147.76178 28.4704467,147.822363 L27.9633439,150.90752 C27.9053651,151.260371 28.0417266,151.610235 28.3192724,151.820657 C28.5968182,152.031135 28.9579405,152.058315 29.2615609,151.891775 L31.9167421,150.435162 C31.9688913,150.406544 32.0311364,150.406544 32.083312,150.435162 L34.7385198,151.891775 C34.8705885,151.96422 35.0134952,152 35.1557129,152 C35.3404078,152 35.5239368,151.939611 35.6807287,151.82063 C35.9582745,151.61018 36.0946626,151.260288 36.0366572,150.907492 L35.5295545,147.822335 C35.5196175,147.761752 35.5388555,147.699953 35.5810147,147.657039 L37.7291462,145.472134 Z"
            transform="translate(-26 -140)"
        />
    </svg>
);
