import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TablePagination,
    TableFooter,
    Collapse,
} from "@material-ui/core";
import ArrowSort from "assets/images/icons/JSX/Icon-Arrow-Sort";
import HelpIcon from "assets/images/icons/JSX/CircleHelpIcon";
import style from "./style.module.scss";
import { TooltipDark } from "components/Tooltip";
import Carret from "assets/images/icons/JSX/Down-arrow-icon";
import throttle from "lodash/throttle";
import classNames from "classnames";
import { getTrend, getTrendRgbaColor } from "lib/utility";
import ThreeDots from "components/Loading/ThreeDots";
import Checkbox from "components/Checkbox";
import { LoadingCircles } from "components/Loading";
import { get } from "lodash";
import { mainPanelRef } from "components/MainPanel";

const comparator = (dataKey, order) => (a, b) => {
    const av = get(a, [dataKey], null);
    const bv = get(b, [dataKey], null);

    if (av === null) {
        return 1;
    }

    if (bv === null) {
        return -1;
    }

    if (av === bv) {
        return 0;
    }

    // high to low
    if (order === 1) {
        return bv < av ? -1 : 1;
    }

    // low to high
    return av < bv ? -1 : 1;
};

const getComparator = (order, orderBy, orderByDataType) => {
    const comparisonDatakey = orderByDataType === "growth" ? "growth" : "value";
    return (a, b) => comparator(comparisonDatakey, order)(a[orderBy], b[orderBy]);
};

const stableSort = (array, comparator) => {
    return array
        .map((el, index) => [el, index])
        .sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        })
        .map((el) => el[0]);
};

const EnhancedTableHead = ({
    headCells,
    order,
    orderBy,
    onRequestSort,
    dividers,
    isScrolled = false,
    onSelectAllClick,
    numSelected,
    rowCount,
    showSelection,
    headerOffset,
}) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow
                style={{
                    transform: `translateY(${headerOffset}px)`,
                    position: "sticky",
                    backgroundColor: "white", // Ensure the background is set to avoid transparency issues
                    zIndex: 3,
                    boxShadow: headerOffset > 0 ? "0px 4px 4px rgba(0, 0, 0, 0.05)" : "none",
                }}
            >
                {showSelection && (
                    <TableCell
                        style={{
                            padding: "0 0.875rem",
                            width: "3.125rem",
                        }}
                    >
                        <Checkbox
                            className={style.checkbox}
                            hideLabel
                            input={{
                                checked: rowCount > 0 && numSelected === rowCount,
                                onChange: onSelectAllClick,
                                inputprops: { "aria-label": "select all desserts" },
                            }}
                        />
                    </TableCell>
                )}
                {Object.keys(headCells).map((key) => {
                    const { headPadding, className, align = "left", ...headCell } = headCells[key];

                    const setPadding =
                        headPadding ||
                        (align === "right" ? "1rem 0.875rem 1rem 0.625rem" : "1rem 0.625rem 1rem 0.875rem");

                    return (
                        <TableCell
                            width={headCell.width ? headCell.width : "auto"}
                            padding="none"
                            key={headCell.id}
                            align={align}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classNames("", {
                                emptyCell: !headCell.label,
                                noDividers: !dividers,
                                freezeCell: headCell.freeze,
                                isScrolled: headCell.freeze && isScrolled,
                                [className]: className,
                            })}
                        >
                            {headCell.sortable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                    style={{
                                        padding: setPadding,
                                        minWidth: headCell.minWidth || 0,
                                    }}
                                >
                                    {headCell.label}
                                    {headCell.info && (
                                        <div
                                            className={style.headTooltip}
                                            data-html={true}
                                            data-tip={headCell.info}
                                            data-for="headTooltip"
                                        >
                                            <HelpIcon />
                                        </div>
                                    )}
                                    <ArrowSort className="arrowSort" />
                                </TableSortLabel>
                            ) : (
                                <span
                                    style={{
                                        padding: setPadding ? setPadding : "1.25rem 1rem 1.125rem",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: align === "left" ? "row" : "row-reverse",
                                        minWidth: headCell.minWidth || 0,
                                        minHeight: "3.5rem",
                                    }}
                                >
                                    {headCell.label}
                                    {headCell.info && (
                                        <div
                                            className={style.headTooltip}
                                            data-html={true}
                                            data-tip={headCell.info}
                                            data-for="headTooltip"
                                        >
                                            <HelpIcon />
                                        </div>
                                    )}
                                </span>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

const Row = React.memo(
    ({
        row,
        headCells,
        hasHover,
        rowClass,
        verticalAlign: overallVerticalAlign,
        isScrolled = false,
        parentScrolled = false,
        orderBy,
        order,
        isLastRow = false,
        index,
        activeMetricFilter,
        heatMapSettings = {},
        id,
        selected = [],
        handleClick,
        isItemSelected,
        showSelection,
    }) => {
        const [open, setOpen] = React.useState(false);
        const CollapsedComponent = row._collapsedComponent || null;

        return (
            <>
                <TableRow
                    selected={isItemSelected}
                    hover={hasHover}
                    tabIndex={-1}
                    className={classNames(rowClass, {
                        hasCollapsedComponent: CollapsedComponent,
                        collapsedOpen: open,
                        isLastRow: isLastRow,
                    })}
                >
                    {showSelection && (
                        <TableCell
                            style={{
                                borderRight: "1px solid #eaf0f7",
                                padding: "0 0.875rem",
                                minWidth: "3.125rem",
                            }}
                            className={`${row?.className || ""}`}
                        >
                            <Checkbox
                                input={{
                                    onChange: (event) => handleClick(event, id),
                                    checked: isItemSelected,
                                    inputprops: { "aria-labelledby": id },
                                }}
                                hideLabel
                                className={style.checkbox}
                            />
                        </TableCell>
                    )}
                    {Object.keys(headCells).map((key) => {
                        const filterCells = headCells[key];

                        const {
                            width = "auto",
                            minWidth = 0,
                            expandContentHeight = false,
                            align = "left",
                            padding = "0.75rem 0.875rem",
                            hasDivider = false,
                            freeze = false,
                        } = filterCells || {};

                        const { color, opacity } = activeMetricFilter || {};
                        const heatmap = heatMapSettings[key] || {};
                        const rowContent =
                            row[key]?.isCellLoading === true ? <ThreeDots color="#1b264f" /> : row[key]?.content;

                        return (
                            <TableCell
                                width={width}
                                align={align}
                                padding="none"
                                style={{
                                    transition: "background-color 0.5s ease",
                                    maxWidth: width,
                                    minWidth,
                                    backgroundColor: row[key]?.analysisTrendColor || row[key]?.backgroundColor || "",
                                    padding: padding,
                                    borderRight: hasDivider ? "1px solid #eaf0f7" : "none",
                                    verticalAlign: row[key]?.verticalAlign || overallVerticalAlign,
                                    ...(key === activeMetricFilter?.id
                                        ? { backgroundColor: `rgba(${color}, ${opacity})` }
                                        : {}),
                                    ...(Object.keys(heatMapSettings).length > 0
                                        ? { backgroundColor: `rgba(${heatmap?.color}, ${heatmap?.opacity})` }
                                        : {}),
                                }}
                                className={`${freeze && "freezeCell"} ${freeze &&
                                    (isScrolled || parentScrolled) &&
                                    "isScrolled"} ${hasDivider && "hasDivider"} ${expandContentHeight &&
                                    "expandContentHeight"} ${row?.className || ""}`}
                                key={key}
                            >
                                {CollapsedComponent && row[key]?.hasDropDown ? (
                                    <div className={`collapseToggle ${open && "open"}`} onClick={() => setOpen(!open)}>
                                        <Carret />
                                        <span>{rowContent}</span>
                                    </div>
                                ) : (
                                    rowContent
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
                {CollapsedComponent && (
                    <TableRow>
                        <TableCell className="collapsedCell" padding="none" colSpan={Object.keys(headCells).length}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <CollapsedComponent
                                    parentScrolled={isScrolled}
                                    defaultSortProp={orderBy}
                                    defaultSortOrder={order}
                                />
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )}
            </>
        );
    },
);

const TableWrapper = ({ allowScroll, children, handleScroll, scrollRef, rowLength = 0, isPaginated = false }) => {
    if (!allowScroll) {
        return children;
    }

    return (
        <>
            <div
                ref={scrollRef}
                className={`TableScrollWrapper ${rowLength === 0 && "emptyWrapper"}`}
                onScroll={handleScroll}
            >
                {children}
            </div>
            {rowLength > 0 && <div className={`ScrollSpacer ${isPaginated && "isPaginated"}`}></div>}
        </>
    );
};

export default ({
    headCells: unformattedHeadCells,
    hideHeadCells = false,
    rows = [],
    className,
    rowClass,
    hover = true,
    dividers = true,
    pagination = false,
    shortHeader = false,
    verticalAlign = "center",
    totalsRow,
    defaultSortProp = "",
    defaultSortOrder = null,
    defaultRowsPerPage = 10,
    allowScroll = false,
    rowsPerPageOptions = [10, 25, 50, 100],
    parentScrolled = false,
    activeMetricFilter,
    heatMapColumns = [],
    selected = [],
    setSelected,
    showSelection = false,
    alwaysShowTotalRow = false,
    orderByDataType = "",
    disableLoadingCircles = false,
    stickyHeader = true,
}) => {
    const [order, setOrder] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [orderPending, setOrderPending] = useState(false);
    const [paginatedRows, setPaginatedRows] = useState([]);
    const [totalActiveMetricDiff, setTotalActiveMetricDiff] = useState(0);
    const [isScrolled, toggleScrolled] = useState(false);

    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const scrollRef = useRef();
    const tableRef = useRef();

    const [headerOffset, setHeaderOffset] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (tableRef.current && stickyHeader) {
                const { top } = tableRef.current.getBoundingClientRect();
                setHeaderOffset(top < 0 ? Math.abs(top) + 64 : 0);
            }
        };

        const panel = mainPanelRef.current;

        if (panel) {
            panel.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (panel) {
                panel.removeEventListener("scroll", handleScroll);
            }
        };
    }, [mainPanelRef]);

    // turn array into object
    const headCells = Array.isArray(unformattedHeadCells)
        ? unformattedHeadCells.reduce((obj, cell) => {
              const { id } = cell;
              return {
                  ...obj,
                  [id]: cell,
              };
          }, {})
        : unformattedHeadCells;

    useEffect(() => {
        const newOrder = defaultSortOrder || 1;
        setOrder(newOrder);
    }, [defaultSortOrder]);

    useEffect(() => {
        if (defaultSortProp) {
            setOrderBy(defaultSortProp);
        }
    }, [defaultSortProp]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 1;
        setOrder(isAsc ? -1 : 1);
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        switch (true) {
            case selectedIndex === -1:
                newSelected = newSelected.concat(selected, id);
                break;
            case selectedIndex === 0:
                newSelected = newSelected.concat(selected.slice(1));
                break;
            case selectedIndex === selected.length - 1:
                newSelected = newSelected.concat(selected.slice(0, -1));
                break;
            case selectedIndex > 0:
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
                break;
            default:
                newSelected = selected;
                break;
        }

        setSelected(newSelected);
    };

    const alignClass = {
        center: "verticalAlignCenter",
        top: "verticalAlignTop",
    };

    const handleScroll = useCallback(
        throttle(
            () => {
                const tableLeftPos = tableRef.current?.getBoundingClientRect().left;
                const scrollWrapperLeftPos = scrollRef.current?.getBoundingClientRect().left;

                if (tableLeftPos < scrollWrapperLeftPos) {
                    toggleScrolled(true);
                } else {
                    toggleScrolled(false);
                }
            },
            500,
            { leading: true },
        ),
        [tableRef, scrollRef],
    );

    useEffect(() => {
        if (scrollRef && activeMetricFilter?.id) {
            try {
                const index = unformattedHeadCells.findIndex(({ id } = {}) => id === activeMetricFilter?.id);
                const percentage = index / unformattedHeadCells.length;
                const newScrollPosition = percentage * scrollRef.current.scrollWidth - 250 - 150; // 250 is the campaign name width plus a column offset
                scrollRef.current.scrollLeft = newScrollPosition;
            } catch (e) {
                console.log(e);
            }
        }
    }, [activeMetricFilter?.id]);

    useEffect(() => {
        setOrderPending(true);
        setTimeout(() => {
            const orderFunc =
                headCells[orderBy] && headCells[orderBy].sort
                    ? headCells[orderBy].sort(order, orderBy)
                    : getComparator(order, orderBy, orderByDataType);

            const sortedRows = stableSort(rows, orderFunc);

            setPaginatedRows(
                pagination && rowsPerPage > 0
                    ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : sortedRows,
            );

            setTotalActiveMetricDiff(
                activeMetricFilter?.id
                    ? paginatedRows.reduce(
                          (sum, { [activeMetricFilter?.id]: { value = 0, oldValue = 0 } = {} } = {}) => {
                              const clean = (v) => (isNaN(v) ? 0 : v);
                              return sum + clean(value) - clean(oldValue);
                          },
                          0,
                      )
                    : 0,
            );
            setOrderPending(false);
        }, 0);
    }, [rows, order, orderBy, orderByDataType, page, rowsPerPage, activeMetricFilter?.id]);

    if (rows.length > 100 && orderPending && !disableLoadingCircles) {
        return (
            <div className={style.loadingWrapper}>
                <LoadingCircles />
            </div>
        );
    }

    return (
        <>
            <TableWrapper
                allowScroll={allowScroll}
                scrollRef={scrollRef}
                handleScroll={handleScroll}
                rowLength={paginatedRows.length}
                isPaginated={pagination && rows.length > defaultRowsPerPage}
            >
                <Table
                    ref={tableRef}
                    className={classNames("MorphioTable", {
                        [alignClass[verticalAlign]]: verticalAlign,
                        allowScroll: allowScroll,
                        paginated: pagination,
                        emptyTable: !paginatedRows.length,
                        [className]: className,
                    })}
                    style={{ position: "relative", overflow: "visible" }}
                >
                    {!hideHeadCells && (
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order === 1 ? "asc" : "desc"}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            dividers={dividers}
                            shortHeader={shortHeader}
                            isScrolled={isScrolled}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                            numSelected={selected.length}
                            showSelection={showSelection}
                            headerOffset={headerOffset}
                        />
                    )}
                    <TableBody>
                        {paginatedRows.map(({ id, ...row }, index) => {
                            // Logic for activated column
                            const { [activeMetricFilter?.id]: { value = 0, oldValue = 0 } = {} } = row || {};
                            const trend = getTrend({ number: value - oldValue, metric: activeMetricFilter?.id });
                            const color = getTrendRgbaColor(trend);
                            const opacity =
                                0.8 * (0.2 + (0.9 * Math.abs(value - oldValue)) / Math.abs(totalActiveMetricDiff));
                            const isSelected = (name) => selected.indexOf(name) !== -1;
                            // Logic for heatmap columns
                            let heatMapSettings = {};
                            if (heatMapColumns.length > 0) {
                                heatMapSettings = heatMapColumns.reduce(
                                    (cache, { id: columnId, threshold = 0, trend = "moreBetter" }) => {
                                        const total = paginatedRows.reduce(
                                            (sum, item) => sum + item[columnId]?.value,
                                            0,
                                        );
                                        const opacity = row[columnId]?.value / total;
                                        const color =
                                            row[columnId]?.value >= threshold
                                                ? trend === "moreBetter"
                                                    ? getTrendRgbaColor("positive")
                                                    : getTrendRgbaColor("negative")
                                                : trend === "moreBetter"
                                                ? getTrendRgbaColor("negative")
                                                : getTrendRgbaColor("positive");

                                        return {
                                            ...cache,
                                            [columnId]: { color, opacity },
                                        };
                                    },
                                    {},
                                );
                            }
                            return (
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <Row
                                        id={id}
                                        isItemSelected={isSelected(id)}
                                        handleClick={handleClick}
                                        key={id || index}
                                        row={row}
                                        headCells={headCells}
                                        hasHover={hover}
                                        rowClass={rowClass}
                                        verticalAlign={verticalAlign}
                                        isScrolled={isScrolled}
                                        parentScrolled={parentScrolled}
                                        orderBy={orderBy}
                                        order={order}
                                        isLastRow={paginatedRows.length === index + 1}
                                        index={index}
                                        activeMetricFilter={{ ...activeMetricFilter, color, opacity }}
                                        heatMapSettings={heatMapSettings}
                                        selected={selected}
                                        showSelection={showSelection}
                                    />
                                </React.Suspense>
                            );
                        })}
                        {totalsRow && (alwaysShowTotalRow || rows.length > 1) && (
                            <React.Suspense fallback={<div>Loading...</div>}>
                                <Row
                                    row={totalsRow}
                                    headCells={headCells}
                                    hasHover={false}
                                    rowClass={`${rowClass} totalsRow`}
                                    verticalAlign={verticalAlign}
                                    isScrolled={isScrolled}
                                    index={0}
                                />
                            </React.Suspense>
                        )}
                    </TableBody>
                </Table>
                <TooltipDark id="headTooltip" />
                <TooltipDark id="rowTooltip" proximity={2} />
            </TableWrapper>
            <Table className={classNames("PaginationTable", { allowScroll: allowScroll })}>
                {pagination && rows.length > defaultRowsPerPage && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                }}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </>
    );
};
