const GET_CONVERSION_TYPES_REJECTED = "GET_CONVERSION_TYPES_REJECTED";
const GET_CONVERSION_TYPES_FULFILLED = "GET_CONVERSION_TYPES_FULFILLED";
const GET_CONVERSION_TYPES_PENDING = "GET_CONVERSION_TYPES_PENDING";
const GET_SLACK_CHANNELS = "GET_SLACK_CHANNELS";
const GET_FACEBOOK_ATTRIBUTION = "GET_FACEBOOK_ATTRIBUTION";
const GET_PINTEREST_ATTRIBUTION = "GET_PINTEREST_ATTRIBUTION";
const SETTINGS_FACEBOOK_BREAKDOWN = "SETTINGS_FACEBOOK_BREAKDOWN";
const SETTINGS_LINKEDIN_BREAKDOWN = "SETTINGS_LINKEDIN_BREAKDOWN";
const SETTINGS_UPDATE_LINKEDIN_BREAKDOWN = "SETTINGS_UPDATE_LINKEDIN_BREAKDOWN";
const SETTINGS_UPDATE_FACEBOOK_BREAKDOWN = "SETTINGS_UPDATE_FACEBOOK_BREAKDOWN";
const SETTINGS_UPDATE_ANALYTICS_INTEGRATION = "SETTINGS_UPDATE_ANALYTICS_INTEGRATION";
const SET_INITIAL_CONVERSION_TYPES = "SET_INITIAL_CONVERSION_TYPES";
const SET_INITIAL_FACEBOOK_BREAKDOWN = "SET_INITIAL_FACEBOOK_BREAKDOWN";
const SETTINGS_FACEBOOK_BREAKDOWN_LOADING = "SETTINGS_FACEBOOK_BREAKDOWN_LOADING";
const SETTINGS_LINKEDIN_BREAKDOWN_LOADING = "SETTINGS_LINKEDIN_BREAKDOWN_LOADING";
const SET_INITIAL_LINKEDIN_BREAKDOWN = "SET_INITIAL_LINKEDIN_BREAKDOWN";
const SET_CONVERSIONS_TOUCHED = "SET_CONVERSIONS_TOUCHED";
const SET_FACEBOOK_ATTRIBUTION = "SET_FACEBOOK_ATTRIBUTION";
const SET_PINTEREST_ATTRIBUTION = "SET_PINTEREST_ATTRIBUTION";
const SET_INITIAL_FACEBOOK_ATTRIBUTION = "SET_INITIAL_FACEBOOK_ATTRIBUTION";
const SET_INITIAL_PINTEREST_ATTRIBUTION = "SET_INITIAL_PINTEREST_ATTRIBUTION";
const RESET_CONVERSION_SETTINGS = "RESET_CONVERSION_SETTINGS";
const TOGGLE_CONVERSIONS_CURRENT_AND_FUTURE = "TOGGLE_CONVERSIONS_CURRENT_AND_FUTURE";
const SETTINGS_UPDATE_ADWORDS_BREAKDOWN = "SETTINGS_UPDATE_ADWORDS_BREAKDOWN";
const SET_INITIAL_ADWORDS_BREAKDOWN = "SET_INITIAL_ADWORDS_BREAKDOWN";
const GET_ADWORDS_CONVERSIONS = "GET_ADWORDS_CONVERSIONS";
const SETTINGS_UPDATE_ADWORDS_INTEGRATION = "SETTINGS_UPDATE_ADWORDS_INTEGRATION";
const UPDATE_CAMPAIGN_GROUP = "UPDATE_CAMPAIGN_GROUP";
const GET_CAMPAIGN_GROUPS = "GET_CAMPAIGN_GROUPS";
const DELETE_CAMPAIGN_GROUP = "DELETE_CAMPAIGN_GROUP";
const SETTINGS_ANALYTICSV4_BREAKDOWN_LOADING = "SETTINGS_ANALYTICSV4_BREAKDOWN_LOADING";
const SETTINGS_UPDATE_ANALYTICSV4_BREAKDOWN = "SETTINGS_UPDATE_ANALYTICSV4_BREAKDOWN";
const SET_INITIAL_ANALYTICSV4_BREAKDOWN = "SET_INITIAL_ANALYTICSV4_BREAKDOWN";
const SETTINGS_ANALYTICSV4_BREAKDOWN = "SETTINGS_ANALYTICSV4_BREAKDOWN";
const SETTINGS_UPDATE_ANALYTICSV4_INTEGRATION = "SETTINGS_UPDATE_ANALYTICSV4_INTEGRATION";
const GET_ACCOUNT_CHANGE_LOGS = "GET_ACCOUNT_CHANGE_LOGS";
const ADD_ACCOUNT_CHANGE_LOGS = "ADD_ACCOUNT_CHANGE_LOGS";
const UPDATE_ACCOUNT_CHANGE_LOGS = "UPDATE_ACCOUNT_CHANGE_LOGS";
const DELETE_ACCOUNT_CHANGE_LOGS = "DELETE_ACCOUNT_CHANGE_LOGS";

export default {
    GET_SLACK_CHANNELS,
    GET_CONVERSION_TYPES_REJECTED,
    GET_CONVERSION_TYPES_FULFILLED,
    GET_CONVERSION_TYPES_PENDING,
    SETTINGS_LINKEDIN_BREAKDOWN,
    SETTINGS_FACEBOOK_BREAKDOWN,
    SETTINGS_UPDATE_FACEBOOK_BREAKDOWN,
    SETTINGS_UPDATE_LINKEDIN_BREAKDOWN,
    SETTINGS_UPDATE_ANALYTICS_INTEGRATION,
    SET_INITIAL_CONVERSION_TYPES,
    SET_INITIAL_FACEBOOK_BREAKDOWN,
    SETTINGS_FACEBOOK_BREAKDOWN_LOADING,
    SETTINGS_LINKEDIN_BREAKDOWN_LOADING,
    SET_INITIAL_LINKEDIN_BREAKDOWN,
    SET_CONVERSIONS_TOUCHED,
    GET_FACEBOOK_ATTRIBUTION,
    GET_PINTEREST_ATTRIBUTION,
    SET_FACEBOOK_ATTRIBUTION,
    SET_PINTEREST_ATTRIBUTION,
    SET_INITIAL_FACEBOOK_ATTRIBUTION,
    SET_INITIAL_PINTEREST_ATTRIBUTION,
    RESET_CONVERSION_SETTINGS,
    TOGGLE_CONVERSIONS_CURRENT_AND_FUTURE,
    SETTINGS_UPDATE_ADWORDS_BREAKDOWN,
    SET_INITIAL_ADWORDS_BREAKDOWN,
    GET_ADWORDS_CONVERSIONS,
    SETTINGS_UPDATE_ADWORDS_INTEGRATION,
    UPDATE_CAMPAIGN_GROUP,
    GET_CAMPAIGN_GROUPS,
    DELETE_CAMPAIGN_GROUP,
    SETTINGS_ANALYTICSV4_BREAKDOWN_LOADING,
    SETTINGS_UPDATE_ANALYTICSV4_BREAKDOWN,
    SET_INITIAL_ANALYTICSV4_BREAKDOWN,
    SETTINGS_ANALYTICSV4_BREAKDOWN,
    SETTINGS_UPDATE_ANALYTICSV4_INTEGRATION,
    GET_ACCOUNT_CHANGE_LOGS,
    ADD_ACCOUNT_CHANGE_LOGS,
    UPDATE_ACCOUNT_CHANGE_LOGS,
    DELETE_ACCOUNT_CHANGE_LOGS,
};
