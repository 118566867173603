const GET_GA4_DATA = "GET_GA4_DATA";
const CLEAR_GA4_DATA = "CLEAR_GA4_DATA";
const GET_GA4_EVENTS_DATA = "GET_GA4_EVENTS_DATA";
const GET_GA4_LANDING_PAGE_DATA = "GET_GA4_LANDING_PAGE_DATA";
const GET_GA4_DEVICES_DATA = "GET_GA4_DEVICES_DATA";
const GET_GA4_USER_AGE_RANGE_DATA = "GET_GA4_USER_AGE_RANGE_DATA";

export default {
    GET_GA4_DATA,
    CLEAR_GA4_DATA,
    GET_GA4_EVENTS_DATA,
    GET_GA4_LANDING_PAGE_DATA,
    GET_GA4_DEVICES_DATA,
    GET_GA4_USER_AGE_RANGE_DATA,
};
