import React, { useState } from "react";
import moment from "moment";
import style from "./style.module.scss";
import CalendarIcon from "assets/images/Report_Icons/JSX/Icon-Calendar";
import Container from "components/DropDown/Container";
import { SimpleListItem } from "components/DropDown/ListItems";
import SelectInput from "components/DropDown/Select";

const DropDownMonth = ({ isOpen, changeDate, toggleMonthSelector, hideCurrent = false, value }) => {
    const url = window.location.pathname;

    return (
        <Container className={style.dateDropdownContainer} isOpen={isOpen}>
            {[0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11].map((toAdd, index) => {
                if (hideCurrent && index === 0) {
                    return null;
                }

                return (
                    <SimpleListItem
                        key={index}
                        isActive={
                            value ===
                            moment()
                                .add(toAdd, "M")
                                .format("MMMM YYYY")
                        }
                        onClick={() => {
                            if (moment().isSame(moment().add(toAdd, "M"), "month")) {
                                changeDate(moment().format("YYYY-MM-DD"));
                            } else {
                                changeDate(
                                    moment()
                                        .add(toAdd, "M")
                                        .endOf("month")
                                        .format("YYYY-MM-DD"),
                                );
                            }
                            return toggleMonthSelector(false);
                        }}
                        label={moment()
                            .add(toAdd, "M")
                            .format("MMMM YYYY")}
                    />
                );
            })}
        </Container>
    );
};

export default ({ className, date, changeDate, hideCurrent, width = "13rem" }) => {
    const [monthIsOpen, toggleMonthSelector] = useState(false);

    return (
        <div className={className}>
            <SelectInput
                refFunction={() => toggleMonthSelector(false)}
                handleClick={() => toggleMonthSelector(!monthIsOpen)}
                value={moment(date).format("MMMM YYYY")}
                icon={CalendarIcon}
                isOpen={monthIsOpen}
                styles={{ width }}
                calendarIcon
            >
                <DropDownMonth
                    changeDate={changeDate}
                    isOpen={monthIsOpen}
                    toggleMonthSelector={toggleMonthSelector}
                    hideCurrent={hideCurrent}
                    value={moment(date).format("MMMM YYYY")}
                />
            </SelectInput>
        </div>
    );
};
