import { api } from "lib/klaviyo";
import { KLAVIYO_CAMPAIGN_REPORT } from "./types";

const getKlaviyoCampaignData = ({ dateRange, accountId }) => (dispatch) => {
    return dispatch({
        type: KLAVIYO_CAMPAIGN_REPORT,
        promise: api.getCampaignReport({
            dateRange,
            accountId,
        }),
    });
};

export default {
    getKlaviyoCampaignData,
};
