import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";
import PageLoadingSpinner from "./PageLoadingSpinner";
import { IconImage } from "components/Icon";

function ListItem({ isLoading, className, onClick, icon: Icon, label, active, children }) {
    return (
        <div
            onClick={onClick}
            className={`
                    ${style.dropdownLink}
                    ${active && style.active}
                    ${className && className}
                `}
        >
            <div className={style.icon}>
                <IconImage icon={Icon} alt={label} />
                {isLoading && <PageLoadingSpinner color="#000000" key="spinner" />}
            </div>
            <span className={style.labelText}>{label}</span>
            {children}
        </div>
    );
}

export const propTypes = {
    className: PropTypes.string,
    history: PropTypes.object,
    icon: PropTypes.symbol,
    label: PropTypes.string,
    active: PropTypes.boolean,
    onClick: PropTypes.function,
};

PropTypes.ListItem = propTypes;

export default ListItem;
