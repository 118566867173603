import { useRef, useEffect } from "react";

/**
 * https://gist.github.com/gragland/ca6806dbb849efa32be8a6919e281d09#file-use-memo-compare-jsx-L34
 * Produce true if next function is true within provided compare function
 * @param {any} next
 * @param {func} compare
 * @returns {boolean}
 */

export function useMemoCompare(next, compare) {
    // Ref for storing previous value
    const previousRef = useRef();
    const previous = previousRef.current;

    // Pass previous and next value to compare function
    // to determine whether to consider them equal.
    const isEqual = compare(previous, next);

    // If not equal update previousRef to next value.
    // We only update if not equal so that this hook continues to return
    // the same old value if compare keeps returning true.
    useEffect(() => {
        if (!isEqual) {
            previousRef.current = next;
        }
    });

    // Finally, if equal then return the previous value
    return isEqual ? previous : next;
}
