import { api } from "lib/stackAdapt";
import types from "./types";

const getStackAdaptData =
    ({ dateRange, accountId }) =>
    (dispatch) => {
        return dispatch({
            type: types.STACKADAPT_CAMPAIGN_REPORT,
            promise: api.getCampaignsReport({
                dateRange,
                accountId,
            }),
        });
    };

export default {
    getStackAdaptData,
};
