import types from "./types";
import { MorphioAPI } from "lib/api";

const fetchPromo = (coupon) =>
    MorphioAPI({
        url: "stripe/promo",
        method: "GET",
        query: {
            coupon,
        },
    });

const togglePlanOptions = ({ isOpen, step }) => ({
    type: types.TOGGLE_PLAN_OPTIONS,
    isOpen,
    step,
});

const clearPlanOptions = () => ({
    type: types.CLEAR_PLAN_OPTIONS,
});

const fetchPromoDetails = (coupon) => (dispatch) =>
    dispatch({
        type: types.FETCH_PROMO_DETAILS,
        promise: fetchPromo(coupon),
    });

const setPromoDetails = (details) => ({
    type: types.SET_PROMO_DETAILS,
    details,
});

export default {
    togglePlanOptions,
    clearPlanOptions,
    fetchPromoDetails,
    setPromoDetails,
};
