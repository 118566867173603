const GET_DASHBOARD_BASIC_BUDGETS = "GET_DASHBOARD_BASIC_BUDGETS";
const RESET_DASHBOARD_BASIC_BUDGETS = "RESET_DASHBOARD_BASIC_BUDGETS";
const GET_DASHBOARD_ENHANCED_BUDGETS = "GET_DASHBOARD_ENHANCED_BUDGETS";
const RESET_DASHBOARD_ENHANCED_BUDGETS = "RESET_DASHBOARD_ENHANCED_BUDGETS";
const GET_DASHBOARD_INSIGHTS = "GET_DASHBOARD_INSIGHTS";
const DISMISS_DASHBOARD_INSIGHT = "DISMISS_DASHBOARD_INSIGHT";
const GET_KPIS = "GET_KPIS";
const GET_DASHBOARD_ALERTS = "GET_DASHBOARD_ALERTS";
const RESET_DASHBOARD_ALERTS = "RESET_DASHBOARD_ALERTS";
const DISMISS_DASHBOARD_ALERT = "DISMISS_DASHBOARD_ALERT";

export default {
    GET_DASHBOARD_BASIC_BUDGETS,
    RESET_DASHBOARD_BASIC_BUDGETS,
    GET_DASHBOARD_ENHANCED_BUDGETS,
    RESET_DASHBOARD_ENHANCED_BUDGETS,
    GET_DASHBOARD_INSIGHTS,
    DISMISS_DASHBOARD_INSIGHT,
    GET_KPIS,
    GET_DASHBOARD_ALERTS,
    RESET_DASHBOARD_ALERTS,
    DISMISS_DASHBOARD_ALERT,
};
