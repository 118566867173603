import createReducer from "lib/createReducer";
import { handle } from "redux-pack";
import types from "./types";

const defaultState = {
    benchmarksCategories: {},
    benchmarksComparing: {},
};

export default createReducer(defaultState, {
    [types.GET_BENCHMARK_CATEGORIES]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                return {
                    ...prevState,
                    benchmarksCategories: action?.payload?.data,
                };
            },
        }),
    [types.GET_BENCHMARK_COMPARING]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                return {
                    ...prevState,
                    benchmarksComparing: action?.payload?.data,
                };
            },
        }),
});
