import { startsWith } from "lodash";

export const isSearchConsoleMetric = (metricKey) =>
    startsWith(metricKey, "searchConsole");

export const getSearchConsoleMetricName = (metricKey) => {
    switch (metricKey) {
        case "impressions":
        case "searchConsole-impressions":
            return "Impressions";
        case "clicks":
        case "searchConsole-clicks":
            return "Clicks";
        case "ctr":
        case "searchConsole-ctr":
            return "CTR";
        case "position":
        case "searchConsole-position":
            return "Avg. Position";
        default:
            return metricKey;
    }
};
