import React from "react";

export default () => (
    <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            id="Design-Library"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="0.1-Icons"
                transform="translate(-402.000000, -1088.000000)"
                fill="#BCCFDC"
                fillRule="nonzero"
            >
                <g id="Framework" transform="translate(140.000000, 60.000000)">
                    <g
                        id="Features-Copy-3"
                        transform="translate(0.000000, 880.000000)"
                    >
                        <g
                            id="3-copy-4"
                            transform="translate(242.000000, 128.000000)"
                        >
                            <path
                                d="M34.5526702,20 L21.4473298,20 C20.648,20 20,20.648 20,21.4473298 L20,34.5526283 C20,35.352 20.648,36 21.4473298,36 L34.5526283,36 C35.352,36 36,35.352 36,34.5526283 L36,21.4473298 C36,20.648 35.352,20 34.5526702,20 Z M24.9510785,33.8154555 C24.9510785,34.0480838 24.7625131,34.2366492 24.5298848,34.2366492 L22.7369634,34.2366492 C22.5043351,34.2366492 22.3157696,34.0480838 22.3157696,33.8154555 L22.3157696,26.2996021 C22.3157696,26.0669738 22.5043351,25.8784084 22.7369634,25.8784084 L24.5298848,25.8784084 C24.7625131,25.8784084 24.9510785,26.0669738 24.9510785,26.2996021 L24.9510785,33.8154555 Z M23.6334241,25.1699267 C22.692733,25.1699267 21.9301361,24.4073298 21.9301361,23.4666387 C21.9301361,22.5259476 22.692733,21.7633508 23.6334241,21.7633508 C24.5741152,21.7633508 25.336712,22.5259476 25.336712,23.4666387 C25.336712,24.4073298 24.5741571,25.1699267 23.6334241,25.1699267 Z M34.3208388,33.8493822 C34.3208388,34.0632461 34.1474346,34.2366492 33.9335707,34.2366492 L32.0096335,34.2366492 C31.7957696,34.2366492 31.6223665,34.0632461 31.6223665,33.8493822 L31.6223665,30.3240209 C31.6223665,29.7981152 31.7766283,28.0194764 30.248,28.0194764 C29.0622827,28.0194764 28.8217801,29.2369005 28.7734869,29.7832461 L28.7734869,33.8493822 C28.7734869,34.0632461 28.6001257,34.2366492 28.3862199,34.2366492 L26.525445,34.2366492 C26.3115812,34.2366492 26.138178,34.0632461 26.138178,33.8493822 L26.138178,26.2656754 C26.138178,26.0518115 26.3115812,25.8784084 26.525445,25.8784084 L28.3862199,25.8784084 C28.6000838,25.8784084 28.7734869,26.0518115 28.7734869,26.2656754 L28.7734869,26.9213822 C29.2131518,26.2615707 29.866555,25.7522932 31.2577592,25.7522932 C34.3384712,25.7522932 34.3208388,28.6304503 34.3208388,30.2118534 L34.3208388,33.8493822 L34.3208388,33.8493822 Z"
                                id="Icon-LinkedIn-Grey"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
