import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    report: [],
    orders: [],
    ltv: {},
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.SHOPIFY_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => ({
                    ...prevState,
                    report: action.payload,
                }),
            }),
        [types.SHOPIFY_LTV]: (state, action) =>
            handle(state, action, {
                success: (prevState) => ({
                    ...prevState,
                    ltv: {
                        ...prevState.ltv,
                        [action.meta.key]: action.payload?.data,
                    },
                }),
            }),
        [types.SHOPIFY_ORDERS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                }),
                finish: (prevState) => ({
                    ...prevState,
                }),
                success: (prevState) => ({
                    ...prevState,
                    orders: action.payload,
                }),
            }),
    }),
);
