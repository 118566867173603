import React, { useState, useEffect, useCallback } from "react";
import Container from "../../../components/DropDown/Container";
import IconSleep from "../../../assets/images/icons/JSX/Icon-Sleep";
import { useHandleOutsideClickHook } from "../../../lib/handleOutsideClick";
import style from "./style.module.scss";
import Notification from "./Notification";
import { withBadge } from "./utility";
import NotificationButton from "./NotificationButton";
import { debounce, isEmpty } from "lodash";
import { ReactComponent as SettingsIcon } from "assets/images/icons/Icon-Settings-Small.svg";
import { history } from "_redux";
import FilterSearch from "components/SearchField/FilterSearch";
import { getNotifications } from "./ducks/operations";
import { useDispatch, useSelector } from "react-redux";
import { selectLastSeen, selectNotificationAlerts } from "./ducks/selector";
import LazilyRender from "react-lazily-render";

const Notifications = ({ id }) => {
    const notifications = useSelector((state) => selectNotificationAlerts(state));
    const lastSeen = useSelector((state) => selectLastSeen(state));
    const [notificationsFiltered, setNotificationsFiltered] = useState(notifications);
    const [isOpen, toggleOpen] = useState(false);
    const [hasBadge, setHasBadge] = useState(false);
    const [searchAlertsInput, setSearchAlertsInput] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        const abortController = new AbortController();

        dispatch(
            getNotifications({
                userId: id,
                abortController,
            }),
        );

        return () => {
            // cancel request
            abortController.abort();
        };
    }, [id]);

    useEffect(() => {
        setHasBadge(!isEmpty(notifications) && withBadge(lastSeen));
    }, [notifications, lastSeen]);

    const debouncedFilter = useCallback(
        debounce((input) => {
            if (isEmpty(input)) {
                setNotificationsFiltered(notifications);
            }

            const inputLower = input.toLowerCase();
            const alertsFiltered = notifications.filter(({ accountName, content: { property } }) => {
                return `${accountName} ${property}`.toLowerCase().includes(inputLower);
            });

            setNotificationsFiltered(alertsFiltered);
        }, 500),
        [notifications],
    );

    useEffect(() => {
        debouncedFilter(searchAlertsInput);
        return () => {
            debouncedFilter.cancel();
        };
    }, [searchAlertsInput, debouncedFilter]);

    const handleClickSettings = () => history.push(`/dashboard/alert-settings`);

    const wrapperRef = useHandleOutsideClickHook(() => toggleOpen(false));

    const isArray = Array.isArray(notificationsFiltered);
    const count = notifications.length;
    const buttonProps = {
        notificationsCount: count,
        hasBadge,
        setHasBadge,
        isOpen,
        toggleOpen,
    };

    return (
        <div ref={wrapperRef} className={style.notificationsContainer}>
            <Container className={style.notificationDropdown} isOpen={isOpen} dropdownWidth="25rem">
                <div className={style.notificationHeader}>
                    <span>Alerts</span>
                    <span className={style.alertSettingsButton} onClick={handleClickSettings}>
                        <SettingsIcon />
                    </span>
                </div>
                {count === 0 ? (
                    <div className={style.noNotifications}>
                        <IconSleep />
                        <h2>No Alerts</h2>
                        <p>
                            Way to go, you’re all up-to-date on everything! We’ll alert you here if anything comes up.
                        </p>
                    </div>
                ) : (
                    <div className={style.notificationsCollection}>
                        <FilterSearch
                            className={style.searchAlerts}
                            labelText="Search Alerts"
                            changeEvent={(e) => {
                                setSearchAlertsInput(e.target.value);
                            }}
                        />
                        {isArray &&
                            notificationsFiltered.map((notification, index) => (
                                <LazilyRender
                                    key={`${notification.id}_${index}`}
                                    content={<Notification notification={notification} />}
                                    offset={200}
                                />
                            ))}
                    </div>
                )}
                <div className={style.notificationFooter}>
                    <span>Alerts are from the past 24 hours</span>
                </div>
            </Container>
            <NotificationButton {...buttonProps} />
        </div>
    );
};

export default Notifications;
