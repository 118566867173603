import React from "react";
import style from "./style.module.scss";

export default ({ colour = false, lightText = false, className, onClick, children, id = null, ...props }) => (
    <div
        id={id}
        onClick={onClick}
        className={`${style.cardContainer} ${className}`}
        style={{
            backgroundColor: colour ? colour : "#FFFFFF",
            color: lightText ? "#FFFFFF" : "inherit",
        }}
        {...props}
    >
        {children}
    </div>
);
