import { channelFriendlyName } from "lib/utility";
import BingColorIcon from "assets/images/Integrations/Icon-Bing.svg";
import FacebookColorIcon from "assets/images/Integrations/Icon-Facebook.svg";
import AdwordsColorIcon from "assets/images/Integrations/Icon-GoogleAds.svg";
import LinkedinColorIcon from "assets/images/Integrations/Icon-LinkedIn.svg";
import PinterestColorIcon from "assets/images/Integrations/Icon-Pinterest.svg";
import TikTokColorIcon from "assets/images/Integrations/Icon-TikTok.svg";
import AdwordsIcon from "assets/images/icons/JSX/Icon-Adwords";
import BingIcon from "assets/images/icons/JSX/Icon-Bing";
import FacebookIcon from "assets/images/icons/JSX/Icon-Facebook";
import PinterestIcon from "assets/images/icons/JSX/Icon-Pinterest";
import TikTokIcon from "assets/images/icons/JSX/Icon-TikTok";
import StackAdaptIcon from "assets/images/Integrations/Icon-StackAdapt.svg";

export const platforms = {
    adwords: {
        name: channelFriendlyName("adwords"),
        icon: AdwordsIcon,
        colorIcon: AdwordsColorIcon,
    },
    facebook: {
        name: channelFriendlyName("facebook"),
        icon: FacebookIcon,
        colorIcon: FacebookColorIcon,
    },
    linkedin: {
        name: channelFriendlyName("linkedin"),
        icon: LinkedinColorIcon,
        colorIcon: LinkedinColorIcon,
    },
    bing: {
        name: channelFriendlyName("bing"),
        icon: BingIcon,
        colorIcon: BingColorIcon,
    },
    tiktok: {
        name: channelFriendlyName("tiktok"),
        icon: TikTokIcon,
        colorIcon: TikTokColorIcon,
    },
    pinterest: {
        name: channelFriendlyName("pinterest"),
        icon: PinterestIcon,
        colorIcon: PinterestColorIcon,
    },
    stackAdapt: {
        name: channelFriendlyName("stackAdapt"),
        icon: StackAdaptIcon,
        colorIcon: StackAdaptIcon,
    },
};

export const PAID_PLATFORMS = Object.keys(platforms);
