import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

/**
 * Code Id for search box component
 * @param {object} props
 * @param {string} id id of code block
 */

function Codeid({ id }) {
    return (
        <span className={style.codeblock}>
            api:[<span>{id}</span>]
        </span>
    );
}

export default Codeid;
