import style from "./style.module.scss";
import React from "react";
import { ReactComponent as SmallPlusIcon } from "assets/images/icons/Icon-Add-Small.svg";

const AddButton = ({
    type = "",
    onClick,
    className = "",
    justIcon = false,
    ...otherProps
}) => {
    return (
        <div
            className={`${style.emptyButton} ${className} ${
                justIcon ? style.justIcon : ""
            }`}
            onClick={(event) => {
                onClick(event);
            }}
            {...otherProps}
        >
            <SmallPlusIcon />
            {justIcon || <span>Add {type}</span>}
        </div>
    );
};
export default AddButton;
