import createReducer from "lib/createReducer";
import clearReducer from "lib/clearReducer";
import types from "./types";
import { handle } from "redux-pack";

const defaultState = {
    report: [],
    organicFacebookLoading: true,
    topPosts: [],
    organicFacebookTopPostsLoading: true,
};

export default clearReducer(defaultState)(
    createReducer(defaultState, {
        [types.ORGANIC_FACEBOOK_REPORT]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicFacebookLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicFacebookLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    report: action.payload,
                }),
            }),
        [types.ORGANIC_FACEBOOK_TOP_POSTS]: (state, action) =>
            handle(state, action, {
                start: (prevState) => ({
                    ...prevState,
                    organicFacebookTopPostsLoading: true,
                }),
                finish: (prevState) => ({
                    ...prevState,
                    organicFacebookTopPostsLoading: false,
                }),
                success: (prevState) => ({
                    ...prevState,
                    topPosts: action.payload,
                }),
            }),
    }),
);
