import React, { useEffect, useCallback, useReducer } from "react";
import styles from "../sharedStyles.module.scss";
import moment from "moment";
import DayPicker, { DateUtils, LocaleUtils } from "react-day-picker";
import Presets from "./Presets";
import DateSelector from "./DateSelector";
import { useHandleOutsideClickHook } from "lib/handleOutsideClick";
import classNames from "classnames";

const reducer = (state, action) => {
    switch (action.type) {
        case "HANDLE_PRESET_CLICK":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                isPresetOpen: false,
            };
        case "CANCEL_DATE_SELECTION":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                enteredTo: action.end,
                isOpen: false,
                isPresetOpen: false,
                startDateReadable: moment(action.start).format("MMM D, YYYY"),
                endDateReadable: moment(action.end).format("MMM D, YYYY"),
            };
        case "APPLY_DATE_SELECTION":
            return {
                ...state,
                appliedFrom: action.start,
                appliedTo: action.end,
                isOpen: false,
            };
        case "HANDLE_START_DATE_SELECTION":
            return {
                ...state,
                from: action.day,
                to: null,
                enteredTo: null,
                startDateReadable: moment(action.day).format("MMM D, YYYY"),
            };
        case "HANDLE_END_DATE_SELECTION":
            return {
                ...state,
                to: action.day,
                enteredTo: action.day,
                endDateReadable: moment(action.day).format("MMM D, YYYY"),
            };
        case "UPDATE_DATE_SELECTION":
            return {
                ...state,
                from: action.start,
                to: action.end,
                appliedFrom: action.start,
                appliedTo: action.end,
                enteredTo: action.end,
            };
        case "UPDATE_ENTERED_TO":
            return {
                ...state,
                enteredTo: action.day,
            };
        case "UPDATE_INPUT_START_DATE":
            return {
                ...state,
                startDateReadable: action.day,
            };
        case "UPDATE_INPUT_END_DATE":
            return {
                ...state,
                endDateReadable: action.day,
            };
        case "HANDLE_INPUT_CHANGE":
            return {
                ...state,
                from: action.start,
                to: action.end,
                enteredTo: action.end,
            };
        case "TOGGLE_PRESETS":
            return {
                ...state,
                isPresetOpen: action.isPresetOpen,
            };
        case "TOGGLE_OPEN":
            return {
                ...state,
                isOpen: action.isOpen,
            };
        case "UPDATE_ERRORS":
            return {
                ...state,
                error: {
                    start: action.start,
                    end: action.end,
                },
            };
        default:
            return state;
    }
};

const formatWeekdayShort = (day) =>
    ({
        0: "Su",
        1: "Mo",
        2: "Tu",
        3: "We",
        4: "Th",
        5: "Fr",
        6: "Sa",
    }[day]);

const containsDay = (contains) => (day) => {
    return moment(contains).isSame(day, "day");
};

const daysWithAnomalies = (available) => (day) => {
    const formattedDay = moment(day).format("YYYY-MM-DD");
    return available.includes(formattedDay);
};

const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
};

export const cannotSelectAfter = (maxDaysSelected, from) => {
    //Disable Days during selection
    const daysAfterEnd = new Date(
        moment(from)
            .add(maxDaysSelected - 1, "days")
            .format(),
    );
    const today = new Date();
    return today < daysAfterEnd ? today : daysAfterEnd;
};

export const cannotSelectAfterMonth = (maxMonthsSelected, from) => {
    //Disable Days during selection
    const daysAfterEnd = new Date(
        moment(from)
            .add(maxMonthsSelected, "months")
            .add(-1, "days")
            .format(),
    );
    const today = new Date();
    return today < daysAfterEnd ? today : daysAfterEnd;
};

export default ({
    anomalies = [],
    className = null,
    dateRange,
    disabledDays = { after: new Date() },
    onDateSelect,
    alignLeft,
    rangeClass,
    onDayClick = () => null,
    onFirstDayClick = () => null,
    onLastDayClick = () => null,
    maxDaysSelected,
    maxMonthsSelected,
    presets,
    presetOptions,
    hasCustomDateRange = true,
}) => {
    const initialState = {
        isOpen: false,
        from: null,
        to: null,
        appliedFrom: null,
        appliedTo: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
        isPresetOpen: false,
        startDateReadable: moment(dateRange.start).format("MMM D, YYYY"),
        endDateReadable: moment(dateRange.end).format("MMM D, YYYY"),
        error: { start: "", end: "" },
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "UPDATE_DATE_SELECTION", start: dateRange.start, end: dateRange.end });
    }, [dateRange]);

    const setWrapperRef = useHandleOutsideClickHook(() => {
        dispatch({ type: "CANCEL_DATE_SELECTION", start: dateRange.start, end: dateRange.end });
    });

    const onCancel = useCallback(() => {
        dispatch({ type: "CANCEL_DATE_SELECTION", start: dateRange.start, end: dateRange.end });
    }, [dateRange]);

    const handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            return;
        }

        onDayClick(day, modifiers, state.from, state.to);

        if (isSelectingFirstDay(state.from, state.to, day)) {
            // Select First Day
            onFirstDayClick(day, modifiers, state.from, state.to);
            dispatch({ type: "HANDLE_START_DATE_SELECTION", day });
            dispatch({ type: "UPDATE_ERRORS", start: "", end: state.error.end });
        } else {
            // Select Second Day
            onLastDayClick(day, modifiers, state.from, state.to);
            dispatch({ type: "HANDLE_END_DATE_SELECTION", day });
            dispatch({ type: "UPDATE_ERRORS", start: state.error.start, end: "" });
        }
    };

    const handleDayMouseEnter = (day) => {
        if (!isSelectingFirstDay(state.from, state.to, day)) {
            dispatch({ type: "UPDATE_ENTERED_TO", day });
        }
    };

    const handlePresetClick = (start, end) => {
        onDateSelect({ dateRange: { start, end } });
        dispatch({ type: "HANDLE_PRESET_CLICK", start, end });
    };

    const selectedDays = [state.from, { from: state.from, to: state.enteredTo }];

    const onApply = useCallback(() => {
        const end = state.to || state.from; // Set end to the start date if not selected
        onDateSelect({ dateRange: { start: state.from, end } });
        dispatch({ type: "APPLY_DATE_SELECTION", start: state.from, end });
    }, [state.from, state.to, onDateSelect]);

    const onInputChange = useCallback(({ start, end } = {}, error) => {
        if (error) return;
        dispatch({ type: "HANDLE_INPUT_CHANGE", start: moment(start).toDate(), end: moment(end).toDate() });
    }, []);

    return (
        <div
            ref={setWrapperRef}
            className={`dateRangeContainer ${styles.calendarContainer} ${state.isOpen &&
                styles.calendarOpen} ${className && className}`}
        >
            <Presets
                presetOptions={presetOptions}
                dateRange={dateRange}
                toggleOpen={(isOpen) => dispatch({ type: "TOGGLE_OPEN", isOpen })}
                presets={presets}
                togglePresetOpen={(isPresetOpen) => dispatch({ type: "TOGGLE_PRESETS", isPresetOpen })}
                isOpen={state.isOpen}
                from={state.appliedFrom}
                to={state.appliedTo}
                isPresetOpen={state.isPresetOpen}
                handlePresetClick={handlePresetClick}
                hasCustomDateRange={hasCustomDateRange}
            />
            <div className={classNames(styles.monthPicker, styles.dayPickerWrapper)}>
                <DayPicker
                    initialMonth={state.to ? state.to : new Date()}
                    localeUtils={{ ...LocaleUtils, formatWeekdayShort }}
                    className={`dateRange ${state.isOpen && "dateRangeOpen"} ${alignLeft &&
                        styles.rangePickerAlignLeft} ${rangeClass && rangeClass}`}
                    numberOfMonths={2}
                    toMonth={new Date()}
                    selectedDays={selectedDays}
                    disabledDays={
                        maxDaysSelected && !state.to
                            ? { after: cannotSelectAfter(maxDaysSelected, state.from) }
                            : maxMonthsSelected && !state.to
                            ? { after: cannotSelectAfterMonth(maxMonthsSelected, state.from) }
                            : disabledDays
                    }
                    modifiers={{
                        from: containsDay(state.from),
                        to: containsDay(state.to),
                        enteredTo: containsDay(state.enteredTo),
                        daysWithAnomalies: daysWithAnomalies(anomalies),
                    }}
                    onDayClick={handleDayClick}
                    onDayMouseEnter={handleDayMouseEnter}
                    modifiersStyles={{
                        today: {
                            fontWeight: "800",
                        },
                        daysWithAnomalies: {
                            cursor: "pointer",
                        },
                        disabled: {
                            color: "#BCCFDC",
                        },
                    }}
                    isOpen={state.isOpen}
                    styles={{ width: "13rem", height: "2.75rem" }}
                />
                <DateSelector
                    isOpen={state.isOpen}
                    maxDaysSelected={maxDaysSelected}
                    onInputChange={onInputChange}
                    onCancel={onCancel}
                    onApply={onApply}
                    from={state.from}
                    to={state.to}
                    initialStart={moment(dateRange.start).format("MMM D, YYYY")}
                    initialEnd={moment(dateRange.end).format("MMM D, YYYY")}
                    className={styles.dateSelector}
                    startDateReadable={state.startDateReadable}
                    endDateReadable={state.endDateReadable}
                    updateStartDate={(day) => dispatch({ type: "UPDATE_INPUT_START_DATE", day })}
                    updateEndDate={(day) => dispatch({ type: "UPDATE_INPUT_END_DATE", day })}
                    error={state.error}
                    updateErrors={(error) => dispatch({ type: "UPDATE_ERRORS", ...error })}
                />
            </div>
        </div>
    );
};
