const GET_AUDITREPORT = "GET_AUDITREPORT";
const UPDATE_CONTENT = "UPDATE_CONTENT";
const CLEAR_CONTENT = "CLEAR_CONTENT";
const CLEAR_STATE = "CLEAR_STATE";

export default {
    GET_AUDITREPORT,
    UPDATE_CONTENT,
    CLEAR_CONTENT,
    CLEAR_STATE,
};
