import React from "react";
import qs from "qs";
const {
    REACT_APP_AUTH0_URL,
    REACT_APP_AUTH0_CUSTOM_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ":" + window.location.port : ""
}/google/login`;

export default class GoogleAuthorize extends React.Component {
    buildUrl() {
        // add extra props like company name??? for extra information passing
        const redirectParams =
            qs.parse(
                window.location.search
                    .substr(1)
                    .split("?")
                    .join("&"),
            ) || {};

        const params = {
            response_type: "code",
            client_id: REACT_APP_AUTH0_CLIENT_ID,
            audience: `${REACT_APP_AUTH0_URL}${REACT_APP_AUTH0_AUDIENCE}`,
            connection: "google-oauth2",
            redirect_uri: `${redirectUrl}`,
            scope: "openid",
            state: JSON.stringify({ ...redirectParams }),
        };

        return `https://${REACT_APP_AUTH0_CUSTOM_DOMAIN}/authorize?${qs.stringify(params)}`;
    }

    render() {
        const { component: Component } = this.props;

        return Component ? <Component authUrl={this.buildUrl()} /> : <a href={this.buildUrl()}>Register With Google</a>;
    }
}
